import React from "react";
import usePaymentService from "./PaymentService";
import { Stack, Button, Box, Typography, Grid } from "@mui/material";
import CustomInput from "../../../../MUICustomComponents/CustomInput";
import { SellerPayment } from "../../../../domain/schemas/stores/SellerPayment";
import { SellerView } from "../../../../domain/views/stores/SellerView";
import PaymentSkeleton from "./PaymentSkeleton";

type PaymentTypes = {
	sellerId: string;
	sellerData: SellerView;
	updatePaymentData: (
		sellerId: string,
		sellerData: SellerView,
		paymentData: SellerPayment
	) => Promise<boolean>;
};

export default function Payment({
	sellerId,
	sellerData,
	updatePaymentData,
}: PaymentTypes): JSX.Element {
	const {
		//Props
		loading,
		sellerPayment,
		// Functions
		onInputChange,
		updateSeller,
	} = usePaymentService(sellerData, updatePaymentData);

	return (
		<Box
			m={4}
			minHeight={335}
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
		>
			<Box>
				<Typography variant="h5" pb={3} pl={0} sx={{ fontWeight: "bold" }}>
					Pagamento
				</Typography>
				{loading ? (
					PaymentSkeleton()
				) : (
					<Grid container spacing={2}>
						<Grid item xs={12} pb={2}>
							<CustomInput
								title="Id no gateway de pagamento"
								name="gatewayId"
								value={sellerPayment.gatewayId}
								onChange={onInputChange}
							/>
						</Grid>
						<Grid item xs={12} pb={2}>
							<CustomInput
								title="String de autenticação"
								name="credentialsString"
								value={sellerPayment.credentialsString}
								onChange={onInputChange}
							/>
						</Grid>
					</Grid>
				)}
			</Box>
			<Stack direction="row" justifyContent="end">
				<Button
					color="success"
					variant="contained"
					disabled={loading}
					onClick={() => updateSeller(sellerId, sellerData, sellerPayment)}
				>
					Salvar
				</Button>
			</Stack>
		</Box>
	);
}
