import React, { ChangeEvent, useState } from "react";
import GeneralCatalog from "./GeneralCatalog";
import { SelectChangeEvent, Stack } from "@mui/material";
import GeneralOrder from "./GeneralOrder";
import { RetailerConfiguration } from "../../../../domain/dtos/retailerManagement/retailerConfigurations/RetailerConfiguration";
import RetailerUpdateButton from "../components/RetailerUpdateButton";
import { GeneralConfig } from "../types/GeneralConfig";
import { LoriOrderCodeType } from "../../../../domain/dtos/retailerManagement/retailerConfigurations/LoriOrderCodeType";
import { handleGenericChange } from "../../../../infrastructure/utils/handleGenericChange";
import { ShowOrderCodeType } from "../../../../domain/enums/ShowOrderCodeType";

type Props = {
	retailerConfigurations: RetailerConfiguration;
	isLoadingUpdate: boolean;
	updateRetailerConfig: (configPart: GeneralConfig) => Promise<void>;
};

export default function GeneralConfigurations({
	retailerConfigurations,
	isLoadingUpdate,
	updateRetailerConfig,
}: Props) {
	const [generalConfig, setGeneralConfig] = useState<GeneralConfig>({
		// General - Catalog
		minStock: retailerConfigurations.minStock || 0,
		isSinglePrice: retailerConfigurations.isSinglePrice,
		enableWholesalePrice: retailerConfigurations.enableWholesalePrice,
		// General - Order
		loriOrderCodeType:
			retailerConfigurations.loriOrderCodeType ||
			LoriOrderCodeType.AlphaNumeric,
		orderPrefix: retailerConfigurations.orderPrefix || "PD",
		loriOrderCodeOffset: retailerConfigurations.loriOrderCodeOffset || 0,
		showOrderCodeType:
			retailerConfigurations.showOrderCodeType || ShowOrderCodeType.ExternalId,
		returnExchangedSystem: retailerConfigurations.returnExchangedSystem,
		enableChangeSeller: retailerConfigurations.enableChangeSeller,
		enableOrderPicking: retailerConfigurations.enableOrderPicking,
	});

	const handleChange = (
		event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>,
		checked?: boolean
	) => {
		handleGenericChange<GeneralConfig>(setGeneralConfig, event, checked);
	};

	const handleSubmit = (): void => {
		const newConfig: GeneralConfig = {
			...generalConfig,
			minStock: Number(generalConfig.minStock) || 0,
			orderPrefix: generalConfig.orderPrefix?.trim() || "PD",
			loriOrderCodeOffset: Number(generalConfig.loriOrderCodeOffset) || 0,
		};
		setGeneralConfig(newConfig);
		updateRetailerConfig(newConfig);
	};

	return (
		<>
			<Stack gap={2}>
				<GeneralCatalog generalConfig={generalConfig} onChange={handleChange} />
				<GeneralOrder generalConfig={generalConfig} onChange={handleChange} />
			</Stack>
			<RetailerUpdateButton
				isLoadingUpdate={isLoadingUpdate}
				onSubmit={handleSubmit}
			/>
		</>
	);
}
