export enum DealerInputs {
	DealerCode = "dealerCode",
	FullName = "fullName",
	DocumentType = "documentType",
	SalesmanCnpj = "salesmanCnpj",
	SalesmanCpf = "salesmanCpf",
	Role = "role",
	StoreCnpj = "storeCnpj",
	GatewayId = "gatewayId",
	UseDefaultCommission = "useDefaultCommission",
	CommissionPercentage = "commissionPercentage",
}
