import { MuiCheckbox } from "./checkbox";
import { MuiButton } from "./button";
import { MuiAutocomplete } from "./autocomplete";
import { MuiOutlinedInput } from "./outlineInput";
import { MuiInputLabel } from "./inputLabel";
import { MuiTableCell } from "./tableCell";
import { MuiMenuItem } from "./menuItem";
import { MuiPaper } from "./paper";
import { MuiIconButton } from "./iconButton";
import { MuiChip } from "./chip";
import { MuiFormControl } from "./formControl";
import { MuiButtonGroup } from "./buttonGroup";

export const components: {} = {
	MuiButton,
	MuiCheckbox,
	MuiAutocomplete,
	MuiOutlinedInput,
	MuiInputLabel,
	MuiTableCell,
	MuiMenuItem,
	MuiPaper,
	MuiIconButton,
	MuiChip,
	MuiFormControl,
	MuiButtonGroup,
};
