import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import "react-widgets/dist/css/react-widgets.css";
import Modal from "../../../../components/Modal";
import { APIV2 } from "../../../../API";
import Reload from "./Reload.jsx";
import ContentLoader from "react-content-loader";
import Button from "../../../../components/Button";

export default function CharacteristicsProducts(props) {
	const [characteristics, setCharacteristics] = useState([]);
	const [deleteId, setDeleteId] = useState("");
	const [loading, setLoading] = useState(true);
	const [reloadCharacteristics, setReloadCharacteristics] = useState(true);
	const [newCharacteristic, setNewCharacteristic] = useState({
		key: "",
		value: "",
	});

	useEffect(() => {
		getCharacteristics();
	}, []);

	const getCharacteristics = () => {
		setLoading(true);
		APIV2.get("catalog/products/" + props.id + "/characteristics")
			.then(async (response) => {
				setReloadCharacteristics(false);
				setLoading(false);
				setCharacteristics(response.data);
			})
			.catch((error) => {
				setReloadCharacteristics(true);
				console.log(error);
				setLoading(false);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde.",
					{
						position: "bottom-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						preventDuplicate: true,
					}
				);
			});
	};

	const create = (e) => {
		var button = document.getElementById("create-carac");
		button.setAttribute("disabled", "disabled");
		button.removeAttribute("disabled");
		setLoading(true);

		APIV2.post(
			"catalog/products/" + props.id + "/characteristics",
			newCharacteristic
		)
			.then(async (response) => {
				setLoading(false);
				getCharacteristics();
				setNewCharacteristic({ value: "", key: "" });
				toast.success("Característica criada com sucesso!😃");
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const onChange = (e) => {
		let { name, value } = e.target;
		setNewCharacteristic((prevState) => ({ ...prevState, [name]: value }));
	};

	const setDelete = (id) => {
		setDeleteId(id);
	};

	const deleteCharacteristic = () => {
		setLoading(true);
		APIV2.delete(`catalog/products/${props.id}/characteristics/${deleteId}`)
			.then(() => {
				setLoading(false);
				getCharacteristics();
				toast.success("Característica deletada com sucesso!😃", {
					position: "bottom-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					preventDuplicate: true,
				});
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const skeleton = () => {
		const skele = [0, 1, 2];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="16" y="25" rx="3" ry="3" width="115" height="10" />
				<rect x="450" y="26" rx="3" ry="3" width="60" height="10" />
				<rect x="750" y="26" rx="3" ry="3" width="52" height="10" />
				<rect x="970" y="17" rx="3" ry="3" width="100" height="35" />
			</ContentLoader>
		));
	};

	const renderList = () => {
		return (
			<table className="table">
				<thead>
					<tr>
						<th scope="col">Título</th>
						<th scope="col">Texto</th>
						<th scope="col"></th>
					</tr>
				</thead>
				<tbody>
					{characteristics.map((item, i) => (
						<tr
							key={item.id ? item.id : item.productCode}
							id={item.id ? item.id : item.productCode}
						>
							<td>{item.key}</td>
							<td>{item.value}</td>
							<td className="text-right">
								<button
									type="button"
									className="btn btn-light outline btn-sm btn-icon text-danger"
									data-toggle="modal"
									data-target="#deleteFeature"
									onClick={() => setDelete(!item.id ? item.key : item.id)}
								>
									<i className="material-icons">delete_outline</i>
								</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	};

	return (
		<div className="card mt-3">
			<div className="card-header d-flex justify-content-between align-items-center">
				<h4 className="card-title">Características do Produto</h4>
				<div className="card-actions text-right">
					<Button
						type="button"
						className="btn btn-grey-80 text-right "
						dataToggle="modal"
						onClick={() => document.getElementById("createCharact").reset()}
						dataTarget="#featureModal"
					>
						Criar nova característica
					</Button>
				</div>
			</div>
			<div className="card-content">
				<div className="card-body pt-0">
					{loading ? (
						skeleton()
					) : reloadCharacteristics ? (
						<Reload func={() => getCharacteristics()} />
					) : (
						renderList()
					)}
				</div>
			</div>
			<Modal
				id={"featureModal"}
				title={"Criar característica"}
				create={true}
				removeCloseButton={true}
				content={
					<form className="row" id="createCharact">
						<div className="form-group col-12">
							<label htmlFor="key">
								<strong>Título</strong>
							</label>
							<input
								type="text"
								className="form-control"
								id="key"
								name="key"
								onChange={onChange}
								required
							/>
						</div>
						<div className="form-group col-12">
							<label htmlFor="value">
								<strong>Texto</strong>
							</label>
							<input
								type="text"
								className="form-control"
								id="value"
								name="value"
								onChange={onChange}
								required
							/>
						</div>
					</form>
				}
				disable={!newCharacteristic.key || !newCharacteristic.value}
				buttonId={"create-carac"}
				actionCreate={create}
			/>
			<Modal
				id={"deleteFeature"}
				title={"Certeza que deseja excluir este item?"}
				delete={true}
				actionDelete={deleteCharacteristic}
			/>
		</div>
	);
}
