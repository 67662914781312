import React, { useState } from "react";
import { TCOrder } from "../../../../domain/models/troquecommerce/TCOrder";
import { Button, Stack } from "@mui/material";
import { i18n } from "../../../../translate/i18n";
import { formatCurrency } from "../../../../infrastructure/utils/FormatUtils";
import LoadingButtonIcon from "../../../../components/LoadingButtonIcon";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import OrderCheckDialog from "../../../../components/OrderCheckDialog/OrderCheckDialog";
import { OrderModel } from "../../../../domain/models/commerce/OrderModel";
import NewToastComponent from "../../../../components/NewToastComponent";
import { troqueCommerceApi } from "../../../../infrastructure/api/external/TroqueCommerce";
import { TCReceiveItems } from "../../../../domain/models/troquecommerce/Receiveitems/TCReceiveItems";
import { ItemReceived } from "../../../../domain/models/troquecommerce/Receiveitems/ItemReceived";
import { TCItem } from "../../../../domain/models/troquecommerce/TCItem";
import { TCReverseType } from "../../../../domain/models/troquecommerce/enums/TCReverseType";
import AttentionDialog from "../../../../components/AttentionDialog";
import InvoiceDialog from "./InvoiceDialog";
import { NFDReceived } from "../../../../domain/models/troquecommerce/Receiveitems/NFDReceived";

type Props = {
	data: TCOrder;
	simplifiedOrderModel: TCOrderAsSimplifiedOrderModel;
	isLoading: boolean;
};

export type TCOrderAsSimplifiedOrderModel = Pick<
	OrderModel,
	| "orderCode"
	| "isDelivery"
	| "estimateDate"
	| "buyer"
	| "itemQuantity"
	| "totalAmount"
	| "items"
>;

export type TCOrderCheckOptions = {
	reverse_type: TCReverseType;
	notificate_client: boolean;
};

export default function TCItems({
	data,
	simplifiedOrderModel,
	isLoading,
}: Props) {
	const [isTCCheckOpen, setIsTCCheckOpen] = useState<boolean>(false);
	const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
	const [isNfdAlertOpen, setIsNfdAlertOpen] = useState<boolean>(false);
	const [isNFDDialogOpen, setIsNFDDialogOpen] = useState<boolean>(false);
	const [isPartialAlertOpen, setIsPartialAlertOpen] = useState<boolean>(false);
	const [troqueCommerceOptions, setTroqueCommerceOptions] =
		useState<TCOrderCheckOptions>({
			reverse_type: data.reverse_type,
			notificate_client: true,
		});

	const postReceiveItems = async (nfdReceived?: NFDReceived) => {
		setIsLoadingSubmit(true);
		try {
			const mappedReceiveItems: ItemReceived[] = mapTCItemToItemReceived(
				data.items
			);
			const receiveItemsObject: TCReceiveItems = {
				id: data.id,
				notificate_client: troqueCommerceOptions.notificate_client,
				items: mappedReceiveItems,
				...(nfdReceived && {
					invoices: [
						{
							reference: nfdReceived.reference,
							serie: nfdReceived.serie,
							danfe: nfdReceived.danfe,
							date: nfdReceived.date,
						},
					],
				}),
			};
			const success = await troqueCommerceApi.postReceiveItems(
				receiveItemsObject
			);
			if (!success) {
				NewToastComponent({
					status: "error",
					title: i18n.t("errorMessages.Default"),
					message: i18n.t("errorMessages.TryAgainLater"),
				});
				setIsLoadingSubmit(false);
				return;
			}

			NewToastComponent({
				status: "success",
				title: "Recebimento dos produtos realizado com sucesso!",
			});
			setIsLoadingSubmit(false);
			setIsTCCheckOpen(false);
		} catch (error) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			setIsLoadingSubmit(false);
		}
	};

	const mapTCItemToItemReceived = (items: TCItem[]): ItemReceived[] => {
		const mappedItems: ItemReceived[] = items.map(
			(item: TCItem): ItemReceived => ({
				id_item: item.ecommerce_product_id,
				sku: item.sku,
				quantity: item.quantity,
				is_exchange: item.is_exchange,
			})
		);
		return mappedItems;
	};

	const validateIfPartialCheck = (isPartialCheck: boolean) => {
		if (isPartialCheck) {
			setIsPartialAlertOpen(true);
			return;
		}
		setIsNfdAlertOpen(true);
	};

	const renderTable = () => {
		return (
			<table className="table history-import border-0">
				<thead className="">
					<tr>
						<th className="text-left border-bottom border-top pl-0">
							Produtos
						</th>
						<th className="text-center border-bottom border-top">
							Quant. solicitada
						</th>
						<th className="text-center border-bottom border-top">
							Quant. original
						</th>
						<th className="text-center border-bottom border-top">Tipo</th>
						<th className="text-center border-bottom border-top">Razão</th>
						<th className="text-right border-bottom border-top mr-0 pr-0">
							Preço
						</th>
					</tr>
				</thead>
				<tbody>
					{data.items.map((item) => (
						<tr key={item.ecommerce_product_id} id={item.ecommerce_product_id}>
							<td className="py-2 d-flex flex-row border-bottom border-top-0 pl-0 align-items-center">
								<div className="pr-3">
									<img
										src={item.image_url}
										alt="Produto"
										width="40px"
										className="shadow-sm mx-auto d-block"
									/>
								</div>
								<div className="d-flex flex-column">
									<label className="m-0 p-0">{item.description}</label>
									<em style={{ fontSize: "12px" }} className="p-0 m-0">
										<em className="mr-2">
											Cód. produto: {item.ecommerce_product_id}
										</em>
										<em className="mr-2">
											{" | "}Cód sku: {item.sku}
										</em>
										{item.ean && (
											<em style={{ fontSize: "12px", color: "#BCBEC0" }}>
												{" | "}EAN: {item.ean}
											</em>
										)}
									</em>
								</div>
							</td>
							<td className="pt-3 text-center border-bottom border-top-0 align-middle">
								{item.quantity}
							</td>
							<td className="pt-3 text-center border-bottom border-top-0 align-middle">
								{item.original_quantity}
							</td>
							<td className="pt-3 text-center border-bottom border-top-0 align-middle">
								{item.is_exchange ? "Troca" : "Devolução"}
							</td>
							<td className="pt-3 text-center border-bottom border-top-0 align-middle">
								{item.reason.description}
							</td>
							<td className="pt-3 text-right border-bottom border-top-0 mr-0 pr-0 align-middle">
								{formatCurrency(item.price)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	};

	return (
		<>
			<Stack direction="row" justifyContent="space-between" mb={2}>
				<CustomTypography variant="secondaryTitle" isBold>
					Items
				</CustomTypography>
				<Button
					variant="contained"
					color="success"
					onClick={() => setIsTCCheckOpen(true)}
					disabled={isLoading}
				>
					{isLoading ? <LoadingButtonIcon /> : "Conferir produtos"}
				</Button>
			</Stack>
			{renderTable()}
			<OrderCheckDialog
				orderModel={simplifiedOrderModel}
				isOrderCheckDialogOpen={isTCCheckOpen}
				setIsOrderCheckDialogOpen={setIsTCCheckOpen}
				isLoadingSubmit={isLoadingSubmit}
				onSubmitCallback={validateIfPartialCheck}
				exchangeIntegrationOptions={troqueCommerceOptions}
				setExchangeIntegrationOptions={setTroqueCommerceOptions}
			/>
			<AttentionDialog
				isAttentionDialogOpen={isPartialAlertOpen}
				setIsAttentionDialogOpen={setIsPartialAlertOpen}
				closeCallback={() => setIsPartialAlertOpen(false)}
				showCancelButton={true}
				actionButton={{
					text: "Continuar",
					variant: "contained",
					color: "success",
					onClick: () => {
						setIsPartialAlertOpen(false);
						setIsNfdAlertOpen(true);
					},
				}}
			>
				<CustomTypography variant="default" sx={{ textAlign: "center" }}>
					Sua conferência está incompleta, deseja continuar?
				</CustomTypography>
			</AttentionDialog>
			<AttentionDialog
				isAttentionDialogOpen={isNfdAlertOpen}
				setIsAttentionDialogOpen={setIsNfdAlertOpen}
				closeCallback={postReceiveItems}
				showCancelButton={true}
				actionButton={{
					text: "Inserir",
					variant: "contained",
					color: "success",
					onClick: () => {
						setIsNfdAlertOpen(false);
						setIsNFDDialogOpen(true);
					},
				}}
			>
				<CustomTypography variant="default" sx={{ textAlign: "center" }}>
					Deseja inserir uma Nota Fiscal de Devolução?
				</CustomTypography>
			</AttentionDialog>
			<InvoiceDialog
				isOpen={isNFDDialogOpen}
				setIsOpen={setIsNFDDialogOpen}
				onSubmitCallback={postReceiveItems}
			/>
		</>
	);
}
