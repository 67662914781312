import React, { Dispatch, SetStateAction } from "react";
import { Box } from "@mui/material";
import {
	IndeterminateCheckBoxOutlined,
	AddBoxOutlined,
	KeyboardArrowDownOutlined,
	KeyboardArrowUpOutlined,
} from "@mui/icons-material";
import { CategoryView } from "../../../../domain/views/categories/CategoryView";
import CustomIcon from "../../../../MUICustomComponents/CustomIcon";
import Dropdown from "./../Dropdown";
import TreeViewService from "./TreeViewService";

import * as Styled from "../../styles/TreeViewStyles";

type TreeViewProps = {
	visibleCategories: CategoryView[];
};

export default function TreeView({ visibleCategories }: TreeViewProps) {
	const {
		sortCategory,
		minOrder,
		maxOrder,
		treeViewRef,
		mousePosition,
		nodeStates,
		setNodeStates,
		isReorderMode,
		isUpsertMode,
	} = TreeViewService();

	const recursiveTreeView = (categories: CategoryView[]) => {
		return (
			<Styled.List>
				{categories.map((node: CategoryView) =>
					treeNode(node, nodeStates, setNodeStates)
				)}
			</Styled.List>
		);
	};

	const showNodeIcon = (node: CategoryView, isOpen: boolean) => {
		if (node.children.length > 0) {
			return (
				<>
					{node.parentId && <Styled.HorizontalNode width={15} />}
					<Box mr={0.5} mb={0.5}>
						{isOpen ? (
							<CustomIcon
								icon={<IndeterminateCheckBoxOutlined />}
								variant="small"
							/>
						) : (
							<CustomIcon icon={<AddBoxOutlined />} variant="small" />
						)}
					</Box>
				</>
			);
		}
		return (
			<>
				{node.parentId && <Styled.HorizontalNode width={35} />}
				{!node.parentId && <Styled.EmptyDiv />}
			</>
		);
	};

	const treeNode = (
		node: CategoryView,
		nodeStates: { [key: string]: boolean },
		setNodeStates: Dispatch<SetStateAction<{ [key: string]: boolean }>>
	) => {
		const { children, name, isActive, id, parentId, order } = node;

		const isOpen = nodeStates[id ?? ""] || false;

		const handleCollapseNode = () => {
			if (children.length > 0 && id !== undefined) {
				setNodeStates((prevState) => ({
					...prevState,
					[id]: !isOpen,
				}));
			}
		};

		return (
			<>
				<Styled.TreeNode key={node.id}>
					<Styled.ColapseNode onClick={handleCollapseNode}>
						{showNodeIcon(node, isOpen)}

						<Styled.IsActive isActive={isActive} />
						<Styled.TextOverFlow>{name}</Styled.TextOverFlow>
					</Styled.ColapseNode>
					<Styled.GroupButtons>
						<div className="group-btn">
							<Box display={"flex"} justifyContent={"flex-end"}>
								{isReorderMode && (
									<>
										<Styled.ButtonIcon
											disabled={minOrder(order)}
											onClick={() =>
												sortCategory(parentId ?? "", id ?? "", order - 1)
											}
										>
											<CustomIcon
												icon={<KeyboardArrowUpOutlined />}
												variant="small"
											/>
										</Styled.ButtonIcon>
										<Styled.ButtonIcon
											disabled={maxOrder(order, parentId ?? "")}
											onClick={() =>
												sortCategory(parentId ?? "", id ?? "", order + 1)
											}
										>
											<CustomIcon
												icon={<KeyboardArrowDownOutlined />}
												variant="small"
											/>
										</Styled.ButtonIcon>
									</>
								)}

								{!isUpsertMode && (
									<Styled.DropdownWrapper>
										<Dropdown
											id={id ?? ""}
											children={children}
											positionScrollbar={mousePosition}
										/>
									</Styled.DropdownWrapper>
								)}
							</Box>
						</div>
					</Styled.GroupButtons>
				</Styled.TreeNode>
				<Box display={"flex"} width={"100%"}>
					{isOpen && (
						<>
							<Styled.VerticalNode />
							{recursiveTreeView(children)}
						</>
					)}
				</Box>
			</>
		);
	};

	return <div ref={treeViewRef}>{recursiveTreeView(visibleCategories)}</div>;
}
