import React from "react";

export default function Reload(props) {
	return (
		<div className="card-actions text-center pb-3">
			<p className="text-muted">
				Tivemos um problema para carregar esses dados. Clique em recarregar para
				tentar novamente.
			</p>
			<button
				type="button"
				className="btn btn-grey-80 text-center"
				onClick={() => props.func()}
			>
				Recarregar
			</button>
		</div>
	);
}
