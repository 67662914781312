import React, { useState, useEffect } from "react";
import { APIV2 } from "../../../API";
import Loading from "../../../components/Loading";
import RetailersList from "./components/RetailersList";
import { toast } from "react-toastify";

import "./Retailers.scss";

export default function Index() {
	const [loading, setLoading] = useState(true);
	const [retailers, setRetailers] = useState([]);
	const [clientId, setClientId] = useState("");

	useEffect(() => {
		APIV2.get("management/retailer/configurations/internal/all")
			.then(async (response) => {
				if (response.status != 204) {
					const retailers = response.data;
					const clientId = retailers.map((retailer) => {
						return retailer.clientId;
					});
					setRetailers(response.data);
					setClientId(clientId);
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	}, []);
	const renderScreen = () => {
		return <RetailersList retailers={retailers} clientId={clientId} />;
	};
	return (
		<>
			{loading ? (
				<div className="container dash-content">
					<div className="card">
						<Loading />
					</div>
				</div>
			) : (
				renderScreen()
			)}
		</>
	);
}
