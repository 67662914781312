export enum AddresseeInputs {
	FullName = "fullName",
	Street = "street",
	Number = "number",
	Complement = "complement",
	Neighborhood = "neighborhood",
	City = "city",
	State = "state",
	ZipCode = "zipCode",
}
