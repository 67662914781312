import { ChangeEvent, useEffect, useState } from "react";
import { CommercialConditionDto } from "../../../../../domain/dtos/MarketingManagement/CommercialConditionDto";
import NewToastComponent from "../../../../../components/NewToastComponent";
import { marketingManagementApi } from "../../../../../infrastructure/api/MarketingManagementApi";
import { i18n } from "../../../../../translate/i18n";
import { useHistory, useParams } from "react-router-dom";
import { ConditionInput } from "../../enums/ConditionInput";
import {
	FormErrors,
	FormValidations,
	FormValidator,
	InputValidator,
} from "../../../../../infrastructure/utils/FormUtils";
import {
	formatCurrency,
	formatCurrencyInputValue,
} from "../../../../../infrastructure/utils/FormatUtils";
import {
	percentageMultiplication,
	percentageDivision,
} from "../../../../../infrastructure/utils/MathOperations";
import { percentageRulesCommercialConditions } from "../../utils/PercentageRulesCommercialConditions";

type ConditionView = Omit<
	CommercialConditionDto,
	"clampPercentage" | "minInstallmentValue"
> & {
	clampPercentage: string;
	minInstallmentValue: string;
};

export default function ConditionFormService() {
	const history = useHistory();
	const { id } = useParams();
	const [condition, setCondition] = useState<ConditionView>({
		slug: "",
		clampPercentage: "0",
		isActive: true,
		isDefault: false,
		minInstallmentValue: "0,00",
	});
	const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(false);
	const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
	const [isDefaultConditionCreation, setIsDefaultConditionCreation] =
		useState<boolean>(false);
	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const formValidationMapper: FormValidations = {
		[ConditionInput.Slug]: InputValidator.isRequired,
		[ConditionInput.ClampPercentage]: InputValidator.isPercentageValid,
		[ConditionInput.MinInstallmentValue]: InputValidator.isRequired,
	};

	useEffect(() => {
		if (history.location.state?.isDefaultConditionCreation)
			setIsDefaultConditionCreation(true);
	}, [history]);

	useEffect(() => {
		if (id) {
			setIsLoadingScreen(true);
			getConditionById(id);
		}
	}, []);

	const changeCondition = (e: ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = e.target;

		let formattedValue = value;

		if (name === ConditionInput.ClampPercentage) {
			formattedValue = percentageRulesCommercialConditions(formattedValue);
		}

		if (formValidationMapper[name] !== undefined) {
			const newErrors: string[] = formValidationMapper[name](formattedValue);
			setFormErrors((prevState) => ({ ...prevState, [name]: newErrors }));
		}

		if (name === ConditionInput.MinInstallmentValue) {
			const formattedCurrency = formatCurrencyInputValue(value);
			setCondition((prevState: ConditionView) => ({
				...prevState,
				[name]: formattedCurrency,
			}));
			return;
		}

		if (name === ConditionInput.IsActive) {
			const isChecked = e.target.checked;
			setCondition((prevState: ConditionView) => ({
				...prevState,
				[name]: isChecked,
			}));
			return;
		}

		setCondition((prevState: ConditionView) => ({
			...prevState,
			[name]: formattedValue,
		}));
	};

	const handleSubmit = (data: ConditionView): void => {
		let errors: FormErrors = {};
		for (const [key, value] of Object.entries(data)) {
			if (formValidationMapper[key] === undefined) continue;
			const errorMessages: string[] = formValidationMapper[key](value);
			errors[key] = errorMessages;
		}
		setFormErrors(errors);
		if (FormValidator.hasError(errors)) return;

		setIsLoadingSubmit(true);
		const dtoData: CommercialConditionDto =
			mapConditionViewToCommerceConditionDto(data);
		if (!dtoData.id) {
			createCondition(dtoData);
			return;
		}
		updateCondition(dtoData.id, dtoData);
	};

	const mapConditionViewToCommerceConditionDto = (
		data: ConditionView
	): CommercialConditionDto => {
		const dtoObject: CommercialConditionDto = {
			...(data.id && { id: data.id }),
			slug: data.slug,
			isActive: data.isActive,
			isDefault: data.isDefault,
			clampPercentage: parseFloat(percentageDivision(data.clampPercentage)),
			minInstallmentValue:
				parseFloat(
					data.minInstallmentValue.replaceAll(".", "").replace(",", ".")
				) ?? 0,
		};
		return dtoObject;
	};

	const mapCommerceConditionDtoToConditionView = (
		data: CommercialConditionDto
	): ConditionView => {
		const viewObject: ConditionView = {
			...(data.id && { id: data.id }),
			slug: data.slug,
			isActive: data.isActive,
			isDefault: data.isDefault,
			clampPercentage: percentageMultiplication(
				data.clampPercentage.toString()
			),
			minInstallmentValue: formatCurrency(data.minInstallmentValue, false),
		};
		return viewObject;
	};

	const getConditionById = async (conditionId: string): Promise<void> => {
		try {
			const data = await marketingManagementApi.getConditionById(conditionId);
			const viewData = mapCommerceConditionDtoToConditionView(data);
			setCondition(viewData);
		} catch {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.ERR.REQUEST"),
			});
		}
		setIsLoadingScreen(false);
	};

	const createCondition = async (
		data: CommercialConditionDto
	): Promise<void> => {
		try {
			const { id } = await marketingManagementApi.createCondition(data);
			NewToastComponent({
				status: "success",
				title: i18n.t("paymentConfig.ConditionCreatedSuccessfully"),
			});
			history.push(`/configurations/payment/commercial-conditions/${id}`);
		} catch {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.ERR.REQUEST"),
			});
		}
		setIsLoadingSubmit(false);
	};

	const updateCondition = async (
		conditionId: string,
		data: CommercialConditionDto
	): Promise<void> => {
		const success = await marketingManagementApi.updateCondition(
			conditionId,
			data
		);
		setIsLoadingSubmit(false);
		if (!success) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.ERR.REQUEST"),
			});
			return;
		}
		NewToastComponent({
			status: "success",
			title: i18n.t("paymentConfig.ConditionUpdatedSuccessfully"),
		});
		const updatedViewData = mapCommerceConditionDtoToConditionView(data);
		setCondition(updatedViewData);
	};

	return {
		id,
		condition,
		formErrors,
		isLoadingScreen,
		isLoadingSubmit,
		isDefaultConditionCreation,
		// functions
		changeCondition,
		handleSubmit,
	};
}
