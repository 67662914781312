import React, { useState, useEffect } from "react";
import { useOrders } from "../../providers/OrdersContext";
import Skeleton from "./Skeleton";
import { i18n } from "../../../../translate/i18n";

export default function Payments() {
	const { order, loading } = useOrders();
	const [count, setCount] = useState(0);
	const [show, setShow] = useState(true);
	const [disableCarousel, setDisableCarousel] = useState({
		prev: true,
		next: false,
	});

	useEffect(() => {
		if (count === 0) {
			setDisableCarousel({ prev: true, next: false });
		} else if (count === order?.Payments?.length - 1) {
			setDisableCarousel({ prev: false, next: true });
		} else {
			setDisableCarousel({ prev: false, next: false });
		}
	}, [count]);

	const getTypePayment = (paymentStatus) => {
		const typePaymentDict = {
			Pending: i18n.t(`orderDetails.Payments.Pending`),
			PreApproved: i18n.t(`orderDetails.Payments.PreApproved`),
			FraudAnalysis: i18n.t(`orderDetails.Payments.InAnalysis`),
			WaitingCapture: i18n.t(`orderDetails.Payments.WaitingCapture`),
			Paid: i18n.t(`orderDetails.Payments.Paid`),
			WaitingRefund: i18n.t(`orderDetails.Payments.WaitingRefund`),
			Refunded: i18n.t(`orderDetails.Payments.Refunded`),
			Error: i18n.t(`orderDetails.Payments.Error`),
		};
		return (
			typePaymentDict[paymentStatus] ||
			i18n.t(`orderDetails.OrderFlowInfo.Uninformed`)
		);
	};

	const getTypePaymentColor = (paymentStatus) => {
		const typePaymentColorDict = {
			Pending: "grey-60",
			PreApproved: "grey-60",
			FraudAnalysis: "grey-60",
			WaitingCapture: "grey-60",
			Paid: "success",
			WaitingRefund: "grey-60",
			Refunded: "info",
			Error: "danger",
		};
		return (
			typePaymentColorDict[paymentStatus] ||
			i18n.t(`orderDetails.OrderFlowInfo.Uninformed`)
		);
	};

	const getPaymentMethodType = (paymentType) => {
		let paymentMethodList = [
			"CreditCard",
			"DebitCard",
			"PickupStore",
			"Bankslip",
			"Voucher",
			"Cashback",
			"Pix",
			"InstallmentPix",
			"PaymentLink",
			"TerminalCreditCard",
			"TerminalDebitCard",
		];
		return paymentMethodList?.some((method) => method === paymentType)
			? i18n.t(`orderDetails.Payments.${paymentType}`)
			: i18n.t(`orderDetails.Payments.NotFound`);
	};

	const paymentInformation = (paymentObject, colorClass) => {
		return (
			<>
				{paymentObject?.PaymentMethod !== null && (
					<div className={`text-${colorClass}`}>
						{paymentObject?.PaymentMethod?.Info}
					</div>
				)}
				{paymentObject?.AcquirerApprovalCode && (
					<div className={`text-${colorClass}`}>
						<strong>
							{i18n.t(`orderDetails.Payments.AcquirerApprovalCode`)}:{" "}
						</strong>
						{paymentObject?.AcquirerApprovalCode}
					</div>
				)}
				{paymentObject?.ExternalTransactionId && (
					<div className={`text-${colorClass}`}>
						<strong>
							{i18n.t(`orderDetails.Payments.ExternalTransactionId`)}:{" "}
						</strong>
						{paymentObject?.ExternalTransactionId}
					</div>
				)}
				{paymentObject?.Amount !== null && (
					<div className={`text-${colorClass}`}>
						<strong>{i18n.t(`orderDetails.Payments.PaymentValue`)}: </strong>
						{`${paymentObject?.Amount?.toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						})}`}
					</div>
				)}
				{paymentObject?.Installments !== null && (
					<div className={`text-${colorClass}`}>
						<strong>
							{i18n.t(`orderDetails.Payments.PaymentInstallments`)}:{" "}
						</strong>
						{`${paymentObject?.Installments}x de ${(
							paymentObject?.Amount / paymentObject?.Installments
						)?.toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						})}`}
					</div>
				)}
			</>
		);
	};

	const showManyPaymentMethods = () => {
		return (
			<>
				<p className="text-blueish m-0">
					<strong>{i18n.t(`orderDetails.Payments.PaymentMethod`)}: </strong>
					{getPaymentMethodType(order?.Payments[count]?.PaymentMethod?.Type)}
				</p>
				<div
					id="carouselPaymentControls"
					className="carousel slide double-cards-carousel"
					data-ride="carousel"
					data-interval="false"
					data-wrap="false"
				>
					<ol className="carousel-indicators mb-3">
						{order?.Payments?.map((value, index) => (
							<li
								key={value.id}
								data-target="#carouselPaymentControls"
								data-slide-to={index}
								onClick={() => setCount(index)}
								className={
									index === 0
										? "carousel-indicators-item active"
										: "carousel-indicators-item"
								}
							></li>
						))}
						<button
							className={`carousel-control-prev ${
								!disableCarousel.prev && "text-secondary"
							}`}
							type="button"
							data-target="#carouselPaymentControls"
							data-slide="prev"
							onClick={() => setCount(count - 1)}
							disabled={disableCarousel.prev}
						>
							<i className="material-icons-outlined" aria-hidden="true">
								navigate_before
							</i>
						</button>
						<button
							className={`carousel-control-next ${
								!disableCarousel.next && "text-secondary"
							}`}
							type="button"
							data-target="#carouselPaymentControls"
							data-slide="next"
							onClick={() => setCount(count + 1)}
							disabled={disableCarousel.next}
						>
							<i className="material-icons-outlined" aria-hidden="true">
								navigate_next
							</i>
						</button>
					</ol>

					<div className="carousel-inner">
						{order?.Payments?.map((value, index) => {
							return (
								<div
									key={value.id}
									className={
										index === 0 ? "carousel-item active" : "carousel-item"
									}
								>
									{paymentInformation(value, "orders-carousel")}
								</div>
							);
						})}
					</div>
				</div>
			</>
		);
	};

	const showMessageFraudStatus = (objectPayments) => {
		const fraudStatus = objectPayments?.find((payments) => {
			if (payments?.Status === "Error") {
				return payments?.Message;
			}
		});
		return (
			!!fraudStatus && (
				<div className="box-error-message mt-3">
					<button
						className="btn-error-message"
						type="button"
						onClick={showMessageError}
					>
						<i className="material-icons">error_outline</i>
						<strong>{i18n.t(`orderDetails.Payments.Message`)}: </strong>
						{i18n.t(`orderDetails.Payments.FraudAnalysis`)}
						<i className="material-icons">
							{show ? "keyboard_arrow_right" : "keyboard_arrow_down"}
						</i>
					</button>
					<div className="expand-error-message" id="messageError">
						{fraudStatus?.Message}
					</div>
				</div>
			)
		);
	};

	const showMessageError = () => {
		if (show) {
			document.querySelector(`#messageError`).style.display = "block";
			setShow(false);
		} else {
			document.querySelector(`#messageError`).style.display = "none";
			setShow(true);
		}
	};

	return (
		<div className="order-main-box">
			{loading ? (
				<Skeleton hasBadge={true} />
			) : (
				<>
					<div className="order-header-box">
						<h2>{i18n.t(`orderDetails.Payments.Payment`)}</h2>
						<span
							className={`badge badge-${getTypePaymentColor(
								order?.PaymentStatus
							)} pb-1`}
						>
							{getTypePayment(order?.PaymentStatus).toUpperCase()}
						</span>
					</div>

					<div className="order-body-box m-0 p-0 pt-2">
						{order?.Payments?.length >= 2 ? (
							showManyPaymentMethods()
						) : (
							<>
								<p className="text-blueish">
									<strong>
										{i18n.t(`orderDetails.Payments.PaymentMethod`)}:{" "}
									</strong>
									{getPaymentMethodType(
										order?.Payments[0]?.PaymentMethod?.Type
									)}
								</p>
								{paymentInformation(order?.Payments[0], "blueish")}
							</>
						)}
						{showMessageFraudStatus(order?.Payments)}
					</div>
				</>
			)}
		</div>
	);
}
