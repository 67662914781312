import React, { useState, useEffect } from "react";
import { API, APIV2 } from "../../../API";
import Pagination from "react-js-pagination";
import Modal from "../../../components/Modal";
import List from "../../../components/List/List";
import { toast } from "react-toastify";
import { fromEvent } from "rxjs";
import { map, debounceTime } from "rxjs/operators";
import ContentLoader from "react-content-loader";
import SkuFilter from "./FilterSkus.jsx";
import { useHistory } from "react-router-dom";

export default function SkuList(props) {
	const history = useHistory();
	const [load, setLoad] = useState(false);
	const [products, setProducts] = useState([]);
	const [deleteId, setDeleteId] = useState(null);
	const [totalProducts, setTotalProducts] = useState(null);
	const [page, setPage] = useState(props.page);
	const [query, setQuery] = useState(props.query === null ? "" : props.query);
	const [filterBackground, setFilterBackGround] = useState(false);
	const [sortField, setSortField] = useState("name");
	const [sortAscending, setSortAscending] = useState(true);
	const [filterData, setFilterData] = useState({
		isActive: props.isActive === null ? "" : props.isActive,
		isAvailable: props.isAvailable === null ? "" : props.isAvailable,
		hasStock: props.hasStock === null ? "" : props.hasStock,
		hasPrice: props.hasPrice === null ? "" : props.hasPrice,
	});
	const loads = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
	const [rowsPerPage, setRowsPerPage] = useState(20);

	const columsList = [
		{
			name: "Código do Produto",
			selector: "name",
			sortable: true,
			width: "200px",
			style: {
				overflow: "visible",
				color: "white",
			},
			cell: (row) => (
				<div>
					<small className="text-muted">{row.code}</small>
				</div>
			),
		},

		{
			name: "Nome do SKU",
			selector: "name",
			sortable: true,
			width: "200px",
			style: {
				overflow: "visible",
				color: "white",
			},
			cell: (row) => (
				<div>
					<small className="text-muted">{row.code}</small>
				</div>
			),
		},

		{
			name: "Código do SKU",
			selector: "name",
			sortable: true,
			width: "200px",
			style: {
				overflow: "visible",
				color: "white",
			},
			cell: (row) => (
				<div>
					<small className="text-muted">{row.code}</small>
				</div>
			),
		},

		{
			name: "Estoque total",
			selector: "name",
			sortable: true,
			width: "200px",
			style: {
				overflow: "visible",
				color: "white",
			},
			cell: (row) => (
				<div>
					<small className="text-muted">{row.code}</small>
				</div>
			),
		},
		{
			sortable: true,
			name: "Status",
			selector: "isActive",
			cell: (row) =>
				row.isActive ? (
					<span className="badge badge-success stadium">ATIVO</span>
				) : (
					<span className="badge badge-grey-60 stadium">INATIVO</span>
				),
		},
		{
			name: "Ações",
			sortable: true,
			width: "150px",
			center: true,
			cell: (row) => (
				<div className="actions d-flex align-items-center justify-content-end">
					<div className="btn-group" role="group" aria-label="Basic example">
						<button
							type="button"
							className="btn btn-light btn-sm outline btn-icon"
							style={{ border: "none" }}
							onClick={() =>
								(window.location.href = `/sku/${page}/edit/${
									!row.id ? row.code : row.id
								}`)
							}
						>
							<i className="material-icons">edit</i>
						</button>
						<button
							type="button"
							className="btn btn-light btn-sm outline btn-icon"
							style={{ border: "none" }}
							data-toggle="modal"
							data-target="#deleteProduct"
							onClick={() => setDeleteId(!row.id ? row.code : row.id)}
						>
							<i className="material-icons text-danger">delete_outline</i>
						</button>
					</div>
				</div>
			),
		},
	];

	useEffect(() => {
		history.push(
			`${props.page}?query=${query === null ? "" : query}&hasStock=${
				filterData.hasStock == null ? "" : filterData.hasStock
			}&hasPrice=${
				filterData.hasPrice == null ? "" : filterData.hasPrice
			}&isAvailable=${
				filterData.isAvailable == null ? "" : filterData.isAvailable
			}&isActive=${filterData.isActive == null ? "" : filterData.isActive}`
		);
		getProducts();
	}, [query, sortAscending, filterData, page, rowsPerPage]);

	useEffect(() => {
		setPage(props.page);
	}, []);

	// useEffect(() => {
	// 	getProducts();
	// }, [sortAscending, filterData, page, rowsPerPage]);

	useEffect(() => {
		const inputSearch = document.querySelector("#search");
		const searchProd = fromEvent(inputSearch, "input")
			.pipe(
				debounceTime(500),
				map((event) => event.target.value)
			)
			.subscribe((text) => search(text));
		return searchProd.unsubscribe;
	}, []);
	const getProducts = async () => {
		setLoad(true);
		await APIV2.get(
			`catalog/products?query=${query}&page=${page}&perPage=${rowsPerPage}&SortAscending=${sortAscending}&sortField=${sortField}&isActive=${
				props.isActive == null ? filterData.isActive : props.isActive
			}&isAvailable=${
				props.isAvailable == null ? filterData.isAvailable : props.isAvailable
			}&hasStock=${
				props.hasStock == null ? filterData.hasStock : props.hasStock
			}&hasPrice=${
				props.hasPrice == null ? filterData.hasPrice : props.hasPrice
			}`
		)
			.then((response) => {
				setProducts(response.data.products);
				setTotalProducts(response.data.maxCount);
				setLoad(false);
			})
			.catch((error) => {
				setLoad(false);
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};
	const filter = async (val) => {
		if (
			val.hasStock == "" &&
			val.hasPrice == "" &&
			val.isAvailable == "" &&
			val.isActive == ""
		) {
			history.push("/products/1");
		} else {
			history.push(
				`${page}?query=${query}&hasStock=${
					val.hasStock == null ? "" : val.hasStock
				}&hasPrice=${val.hasPrice == null ? "" : val.hasPrice}&isAvailable=${
					val.isAvailable == null ? "" : val.isAvailable
				}&isActive=${val.isActive == null ? "" : val.isActive}`
			);
		}

		try {
			setFilterData(val);
			setLoad(false);
		} catch (error) {
			console.log(error);
			setLoad(false);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};
	const search = (query) => {
		setQuery(query);
		setLoad(true);
	};
	const changePage = (e) => {
		setLoad(true);
		setPage(e);
		const stateObj = { page: props.page };
		if (
			props.hasStock == null ||
			props.hasPrice == null ||
			props.isAvailable == null ||
			props.isActive == null
		) {
			window.history.pushState(stateObj, "", `${e}`);
		} else {
			window.history.pushState(
				stateObj,
				"",
				`${e}?query=${props.query}&hasStock=${props.hasStock}&hasPrice=${props.hasPrice}&isAvailable=${props.isAvailable}&isActive=${props.isActive}`
			);
		}
	};

	const changeRowsPerPage = (e) => {
		setLoad(true);
		setRowsPerPage(e);
	};

	const sortBy = async (column, sortDirection, event) => {
		try {
			setSortAscending(() => !sortAscending);
			await setSortField(() => column.selector);
		} catch (error) {
			console.log(error);
		}
	};
	const deleteProduct = () => {
		APIV2.delete("catalog/products/" + deleteId)
			.then(() => {
				getProducts();
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};
	const renderLoading = () => {
		return (
			<>
				<div className="container dash-content">
					<div
						className=""
						style={{ overflow: "hidden", paddingBottom: "1em" }}
					>
						{loads.map(() => (
							<ContentLoader
								width={1100}
								height={100}
								backgroundColor="#e6e6e6"
								foregroundColor="#fafafa"
								// viewBox="0 0 100 100"
							>
								<rect x="120" y="40" rx="5" ry="5" width="400" height="10" />
								<rect x="120" y="59" rx="5" ry="5" width="100" height="5" />
								<rect x="690" y="50" rx="5" ry="5" width="55" height="18" />
								<rect x="950" y="45" rx="3" ry="5" width="90" height="40" />
								<rect x="20" y="10" rx="0" ry="0" width="80" height="190" />
							</ContentLoader>
						))}

						{/* <Loading /> */}
					</div>
				</div>
			</>
		);
	};

	return (
		<>
			<div className="container dash-content">
				<div className="card">
					<div className="card-header d-flex justify-content-between align-items-center p-3">
						<h4 className="card-title">Painel do SKUs</h4>
						<div className="">
							<button
								className="btn btn-info light btn-icon mr-2"
								style={{ padding: "10px" }}
								onClick={() => (window.location.href = "/import")}
							>
								<i className="material-icons">publish</i>&nbsp;&nbsp;Importar
								Arquivos
							</button>
							<button
								className="btn btn-grey-80 btn-icon"
								style={{ padding: "10px" }}
								onClick={() =>
									(window.location.href = `/sku/${props.page}/new`)
								}
							>
								<i className="material-icons">add</i>
							</button>
						</div>
					</div>
					<SkuFilter
						searchFilter={filter}
						totalProducts={totalProducts}
						isAvailable={props.isAvailable}
						hasStock={props.hasStock}
						hasPrice={props.hasPrice}
						isActive={props.isActive}
						query={query}
						load={load}
					/>
					<div className="card-content pt-1">
						<div className="card-body no-padding">
							{totalProducts === 0 ? (
								<div className="empty">
									<div className="col-12 text-center">
										<img src={"/images/empty_products.svg"} width="20%" />
										<h3>
											<b>Ops! Não tem nada por aqui...</b>
										</h3>
									</div>
								</div>
							) : load ? (
								renderLoading()
							) : (
								<List
									data={products}
									page={parseInt(page)}
									colums={columsList}
									totalRows={totalProducts}
									handlePageChange={changePage}
									paginationServer={true}
									sortedReturn={sortBy}
									sortServer={true}
									defaultSortAsc={sortAscending}
									rowsPerPage={rowsPerPage}
									handleRowsPerPageChange={changeRowsPerPage}
								/>
							)}
						</div>
					</div>
				</div>

				<Modal
					id={"deleteProduct"}
					title={"Deseja excluir o SKU?"}
					delete={true}
					content={
						<div className="d-flex flex-column">
							<span className="text-muted mb-2">
								Atenção! Essa ação não poderá ser revertida!
							</span>
							<span className="text-muted mb-2">
								<strong>Nome do SKU: </strong>nome do sku
							</span>
							<span className="text-muted">
								<strong>Código: </strong>codigo do sku
							</span>
						</div>
					}
					actionDelete={deleteProduct}
				/>
			</div>
		</>
	);
}
