import { BaseOverrideColors } from "./baseOverrideColors";

declare module "@mui/material/Chip" {
	interface ChipPropsColorOverrides extends BaseOverrideColors {}
}

export const MuiChip = {
	styleOverrides: {
		root: {
			fontSize: "12px",
		},
	},
};
