import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import InputMask from "../../../../components/InputMask";
import { i18n } from "../../../../translate/i18n";
import { useOrders } from "../../providers/OrdersContext";
import {
	formatPhoneNumber,
	formatDocument,
} from "../../../../infrastructure/utils/FormatMask";

export default function ChangeDataPickupInStoreModal() {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();
	const { order, handleChangeTrackings, setLoadingStatus, orderId } =
		useOrders();
	useEffect(() => {
		reset({
			name: order?.Buyer?.FullName,
			phoneNumber: formatPhoneNumber(order?.Buyer?.PhoneNumber),
			email: order?.Buyer?.Email,
			buyerDoc: formatDocument(
				order?.Buyer?.DocumentType,
				order?.Buyer?.Document
			),
			carrierRequired: order?.ShippingData?.Shipment?.CarrierName,
			shippingType: order?.ShippingData?.Shipment?.ShippingReference,
			deliveryDate: order?.ShippingData?.Shipment?.EstimatedDeliveryDate,
			invoiceNumber: order?.Invoices[0]?.Number,
		});
	}, [order]);

	const handleFormSubmit = (data) => {
		setLoadingStatus(true);
		const formTracking = [
			{
				addresseeName: data?.name,
				addresseePhoneNumber: data?.phoneNumber,
				addresseeEmail: data?.email,
				documentPickup: data?.buyerDoc,
				invoiceNumber: data?.invoiceNumber,
				carrierName: data?.carrierRequired,
				shippingReference: data?.shippingType,
				deliveryDate: new Date().toISOString(),
			},
		];

		const button = document.getElementById("changeDataWithdrawall");
		button.click();
		handleChangeTrackings(orderId, formTracking);
	};

	return (
		<div
			className="modal fade"
			id="changeDataWithdrawall"
			tabIndex="-1"
			aria-labelledby="changeDataWithdrawall"
			aria-hidden="true"
		>
			<div className="modal-dialog">
				<div
					className="modal-content tracking-modal scroll-list"
					style={{ padding: "32px 32px 0 32px" }}
				>
					<div className="modal-header tracking-modal-header d-flex flex-column">
						<h5 className="modal-title">
							{i18n.t("orderDetails.Trackings.DataWithdrawal")}
						</h5>
					</div>
					<div className="modal-body tracking-modal-body m-0 p-0">
						<form
							className="neo-form m-0 p-0"
							onKeyPress={(e) => {
								e.key === "Enter" && e.preventDefault();
							}}
							onSubmit={handleSubmit(handleFormSubmit)}
						>
							<h6
								className="tracking-form-title m-0"
								style={{ padding: "16px 0 16px 0" }}
							>
								{i18n.t("orderDetails.General.CustomerData")} (
								{i18n.t("orderDetails.General.Customer").toLowerCase()})
							</h6>
							<div className="order-body-box m-0 p-0">
								<p className="text-blueish m-0">
									<strong>{order?.Buyer?.FullName}</strong>
								</p>
								<p className="text-blueish m-0">
									<strong>{i18n.t("orderDetails.General.BuyerDoc")}:</strong>{" "}
									{formatDocument(
										order?.Buyer?.DocumentType,
										order?.Buyer?.Document
									)}
								</p>
								<p className="text-blueish m-0">
									<strong>{i18n.t("orderDetails.General.Email")}:</strong>{" "}
									{order?.Buyer?.Email}
								</p>

								<p className="text-blueish m-0">
									<strong>{i18n.t("orderDetails.General.PhoneNumber")}:</strong>{" "}
									{formatPhoneNumber(order?.Buyer?.PhoneNumber)}
								</p>
							</div>

							<h6
								className="tracking-form-title m-0"
								style={{ padding: "32px 0 8px 0" }}
							>
								{i18n.t("orderDetails.Trackings.DataWithdrawal")}
							</h6>

							<InputMask
								required={true}
								errors={errors}
								register={register}
								isInputText={true}
								isEnabled={true}
								inputName={"name"}
							/>
							<InputMask
								required={true}
								errors={errors}
								register={register}
								isInputText={true}
								isEnabled={true}
								inputName={"email"}
							/>

							<div className="d-flex m-0 p-0">
								<div className="w-100 m-0" style={{ paddingRight: "8px" }}>
									<InputMask
										required={true}
										errors={errors}
										register={register}
										isInputText={false}
										isEnabled={true}
										inputName={"buyerDoc"}
									/>
								</div>
								<div className="w-100 m-0" style={{ paddingLeft: "8px" }}>
									<InputMask
										required={true}
										errors={errors}
										register={register}
										isInputText={false}
										isEnabled={true}
										inputName={"phoneNumber"}
									/>
								</div>
							</div>

							<div
								className="modal-footer tracking-modal-footer d-flex justify-content-end"
								style={{ padding: "32px 0 0 0" }}
							>
								<button
									type="button"
									className="btn btn-light outline mr-2"
									data-dismiss="modal"
									style={{
										padding: "10px 20px",
										width: "190px",
										marginBottom: "32px",
										color: "#5A5E61",
										border: "1px solid #8D8D8D",
										backgroundColor: "transparent",
									}}
								>
									{i18n.t("orderDetails.TypeButtom.BtnCancel")}
								</button>
								<button
									type="submit"
									className="btn btn-success confirm-button"
									style={{
										marginBottom: "32px",
									}}
								>
									{i18n.t("orderDetails.TypeButtom.BtnSaveChanges")}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
