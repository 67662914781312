import React, { useRef, useEffect, useState } from "react";
import { useProductManagement } from "../../providers/ProductManagementContext";
import List from "../../../../../components/List/List";
import { i18n } from "../../../../../translate/i18n";
import NeoTooltipAnimated from "../../../../../components/NeoTooltipAnimated";

export default function InventoryExpandedSkuList({ data }) {
	const {
		overwriteCustomStyles,
		toggleRender,
		setToggleRender,
		updateSkuInvetory,
	} = useProductManagement();
	const newInventoryRef = useRef("");
	const [invalidSku, setInvalidSku] = useState({
		skuId: "",
		value: false,
	});
	const [loadingUpdatedSku, setLoadingUpdatedSku] = useState(null);

	const inventoryStatusDict = {
		Available: i18n.t("productManagement.Available"),
		LowOfStock: i18n.t("productManagement.Low"),
		OutOfStock: i18n.t("productManagement.OutOfStock"),
	};

	const conditionalRowStyles = [
		{
			when: (row) => row?.id,
			style: {
				margin: "0px 32px 0 32px",
				backgroundColor: "transparent",
				width: "1046px",
				"&:hover": {
					margin: "0px",
					padding: "0px 32px 0 32px",
					width: "100%",
				},
			},
		},
		{
			when: (row) => row?.editing === true,
			style: {
				padding: "0px 32px 0 32px",
				backgroundColor: "#F5F5F5",
			},
		},
	];

	useEffect(() => {
		if (loadingUpdatedSku !== null) {
			setTimeout(() => {
				setLoadingUpdatedSku(null);
				setToggleRender(!toggleRender);
			}, 500);
		}
	}, [loadingUpdatedSku]);

	const toggleEditing = (row) => {
		setInvalidSku({ skuId: "", value: false });
		newInventoryRef.current = row?.stockQuantity;
		if (data?.skus.some((skuRow) => skuRow?.editing === true)) {
			let skuIndexEditing = data?.skus?.findIndex(
				(sku) => sku?.editing === true
			);
			data.skus[skuIndexEditing].editing = false;
		}
		if (
			data?.skus?.some((skuRow) => skuRow?.id === row?.id && !skuRow?.editing)
		) {
			let skuIndex = data?.skus?.findIndex((sku) => sku?.id === row?.id);
			data.skus[skuIndex].editing = true;
		}
		setToggleRender(!toggleRender);
	};

	const cancelEditing = (row) => {
		newInventoryRef.current = "";
		setInvalidSku({ skuId: "", value: false });
		if (data?.skus?.some((skuRow) => skuRow?.id === row?.id)) {
			let skuIndex = data?.skus?.findIndex((sku) => sku?.id === row?.id);
			data.skus[skuIndex].editing = false;
		}
		setToggleRender(!toggleRender);
	};

	const handleUpdateSku = (row) => {
		if (newInventoryRef.current === "") {
			setInvalidSku({ skuId: row.id, value: true });
			setToggleRender(!toggleRender);
			return;
		}
		let skuIndex = data?.skus?.findIndex((sku) => sku?.id === row?.id);
		data.skus[skuIndex].editing = false;
		data.skus[skuIndex].stockQuantity = newInventoryRef.current;
		updateSkuInvetory(data, row, newInventoryRef.current);
		setLoadingUpdatedSku(row);
		newInventoryRef.current = "";
	};

	const inventoryEditInput = (row) => (
		<div
			className={`product-table-inventory-edit${
				invalidSku.value ? " invalid-sku" : ""
			} position-relative`}
		>
			<input
				className="edit-sku w-100"
				type="number"
				autoFocus={invalidSku.skuId === row.id && invalidSku.value === true}
				defaultValue={newInventoryRef.current}
				onKeyPress={(e) => {
					if (!/\d/.test(e.key)) {
						e.preventDefault();
					}
					if (e.key === "Enter") {
						handleUpdateSku(row);
					}
				}}
				onChange={(e) => {
					let newValue = e.target.value;
					newInventoryRef.current = newValue;
				}}
			/>
			<div className="edit-buttons">
				<button
					className="edit-btn-confirm material-icons"
					onClick={() => handleUpdateSku(row)}
				>
					check
				</button>
				<button
					className="edit-btn-cancel material-icons"
					onClick={() => cancelEditing(row)}
				>
					close
				</button>
			</div>
		</div>
	);

	const columsList = [
		{
			name: i18n.t("productManagement.ProductAndSKUs"),
			sortable: false,
			cell: (row) => (
				<div className="product-table-sku d-flex align-items-center ml-4 pl-2">
					<img
						className="product-table-sku-image rounded-circle"
						alt="Product image"
						src={row?.imageUrl ? row?.imageUrl : "/images/placeholder.jpg"}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src = "/images/placeholder.jpg";
						}}
					/>
					<div className="sku-info ml-3">
						<h6 className="sku-info-name mb-1">{row?.name}</h6>
						<p className="m-0">
							{row?.code &&
								`${i18n.t("productManagement.SkuCode")} ${row?.code}`}
							{row?.auxCode &&
								` | ${i18n.t("productManagement.ReferenceCode")} ${
									row?.auxCode
								}`}
							{row?.barcode &&
								` | ${i18n.t("productManagement.EanCode")} ${row?.barcode}`}
						</p>
					</div>
				</div>
			),
		},
		{
			name: i18n.t("productManagement.LastUpdate"),
			sortable: false,
			width: "134.5px",
			center: true,
			cell: (row) => (
				<div className="product-table-lastUpdate text-center">
					{row?.lastUpdate ? (
						<>
							<p className="lastUpdate-date m-0 mb-1">
								{new Date(row?.lastUpdate).toLocaleDateString("pt-Br")}
							</p>
							<p className="lastUpdate-hour m-0">
								{new Date(row?.lastUpdate).toLocaleTimeString("pt-Br")}
							</p>
						</>
					) : (
						<span className="lastUpdate-date">–</span>
					)}
				</div>
			),
		},
		{
			name: i18n.t("productManagement.Inventory"),
			sortable: false,
			width: "134.5px",
			center: true,
			cell: (row) => (
				<>
					{row?.editing &&
						(invalidSku.skuId === row.id && invalidSku.value === true ? (
							<NeoTooltipAnimated
								tooltipContent={i18n.t("productManagement.InvalidSkuAmount")}
								tooltipWidth={165}
							>
								{inventoryEditInput(row)}
							</NeoTooltipAnimated>
						) : (
							inventoryEditInput(row)
						))}
					{!row?.editing && loadingUpdatedSku === row && (
						<div className="product-table-inventory-edit">
							<i className="LoadingAnimated"></i>
						</div>
					)}
					{!row?.editing && loadingUpdatedSku !== row && (
						<div
							className={`product-table-inventory ${row?.status} badge-pill py-1 px-3`}
						>
							{row?.stockQuantity}
						</div>
					)}
				</>
			),
		},
		{
			name: i18n.t("productManagement.Status"),
			sortable: false,
			width: "134.5px",
			center: true,
			cell: (row) =>
				!row?.editing &&
				loadingUpdatedSku !== row && (
					<div
						className={`product-table-status-sku ${row?.status} badge-pill d-flex align-items-center`}
					>
						{row?.status !== "Available" && (
							<i className="product-table-status material-icons-round mr-1">
								warning_amber
							</i>
						)}
						{inventoryStatusDict[row?.status]}
					</div>
				),
		},
		{
			name: i18n.t("productManagement.Actions"),
			sortable: false,
			width: "134.5px",
			right: true,
			cell: (row) => (
				<div className="product-table-actions">
					<button
						className="material-icons-outlined"
						disabled={row?.editing}
						onClick={() => toggleEditing(row)}
					>
						edit
					</button>
				</div>
			),
		},
	];

	return (
		<>
			{data?.skuMaxCount !== data?.skuCount && (
				<div className="filtered-sku-info d-flex align-items-center px-3 py-2">
					<i className="filtered-sku-info-icon material-icons-outlined">info</i>
					<span className="filtered-sku-info-text ml-3">
						{i18n
							.t("productManagement.ShowingFilteredResults")
							.replace("%X", data?.skuCount)
							.replace("%Y", data?.skuMaxCount)}
					</span>
				</div>
			)}
			{toggleRender ? (
				<List
					highlightOnHover={true}
					overwriteCustomStyles={overwriteCustomStyles}
					data={data?.skus}
					colums={columsList}
					noTableHead={true}
					pagination={false}
					conditionalRowStyles={conditionalRowStyles}
				/>
			) : (
				<>
					<List
						highlightOnHover={true}
						overwriteCustomStyles={overwriteCustomStyles}
						data={data?.skus}
						colums={columsList}
						noTableHead={true}
						pagination={false}
						conditionalRowStyles={conditionalRowStyles}
					/>
				</>
			)}
		</>
	);
}
