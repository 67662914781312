import { Box, Stack } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import { TCOrder } from "../../../../domain/models/troquecommerce/TCOrder";
import CustomSkeleton from "../../../../MUICustomComponents/CustomSkeleton";
import {
	applyMask,
	formatUnknownDocument,
} from "../../../../infrastructure/utils/FormatUtils";
import { InputMasks } from "../../../../infrastructure/utils/InputMasks";
import { countryMaskRules } from "../../../../components/InternationalPhoneInput/CountryMaskRules";

type Props = { data: TCOrder | null; isLoading: boolean };

export default function TCClient({ data, isLoading }: Props) {
	return (
		<Box className="card" p={3} position="relative">
			<div className={`icon-card-detail ${"person_outline"}`}>
				<i className="material-icons-outlined">person_outline</i>
			</div>
			<CustomTypography variant="secondaryTitle" isBold marginBottom={1}>
				Cliente
			</CustomTypography>
			{isLoading || !data ? (
				<CustomSkeleton quantity={5} height={20} />
			) : (
				<>
					<Stack direction="column" spacing={0.5}>
						<CustomTypography variant="default" color="grey60">
							<strong>Nome: </strong>
							{data.client.name}
						</CustomTypography>
						<CustomTypography variant="default" color="grey60">
							<strong>Documento: </strong>
							{formatUnknownDocument(data.client.document)}
						</CustomTypography>
						<CustomTypography variant="default" color="grey60">
							<strong>E-mail: </strong>
							{data.client.email}
						</CustomTypography>
						<CustomTypography variant="default" color="grey60">
							<strong>Telefone: </strong>
							{applyMask(
								data.client.phone,
								countryMaskRules.BR.maskByLengthRule(data.client.phone.length)
							)}
						</CustomTypography>
					</Stack>
					<CustomTypography variant="secondaryTitle" isBold sx={{ my: 1 }}>
						Endereço
					</CustomTypography>
					<Stack direction="column" spacing={0.5}>
						<CustomTypography variant="default" color="grey60">
							{data.client.address.street}, {data.client.address.number},{" "}
							{data.client.address.description}
						</CustomTypography>
						<CustomTypography variant="default" color="grey60">
							{data.client.address.neighborhood}, {data.client.address.city},{" "}
							{data.client.address.state}
						</CustomTypography>
						<CustomTypography variant="default" color="grey60">
							<strong>CEP: </strong>
							{applyMask(data.client.address.zipcode, InputMasks.ZipCode)}
						</CustomTypography>
					</Stack>
				</>
			)}
		</Box>
	);
}
