import { BaseOverrideColors } from "./baseOverrideColors";

declare module "@mui/material/IconButton" {
	interface IconButtonPropsColorOverrides extends BaseOverrideColors {}
}

export const MuiIconButton = {
	styleOverrides: {
		root: {
			outline: "none !important",
		},
	},
};
