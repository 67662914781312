import { Box, Grid, Tab, Tabs } from "@mui/material";
import React from "react";
import TCDetailsService from "./TCDetailsService";
import GeneralInfo from "./components/GeneralInfo";
import TCClient from "./components/TCClient";
import TCItems from "./components/TCItems";
import TCData from "./components/TCData";
import CustomSkeleton from "../../../MUICustomComponents/CustomSkeleton";
import { Switch, Route, Link } from "react-router-dom";
import TCHistory from "./TCHistory";

export default function TCDetails() {
	const {
		isLoading,
		tCOrder,
		simplifiedOrderModel,
		routeParams,
		currentTab,
		handleTabChange,
	} = TCDetailsService();

	const renderDetailsTab = (): JSX.Element => {
		return (
			<Grid
				container
				spacing={2}
				sx={{
					".card": { height: "100%" },
					".MuiTypography-body1": {
						lineHeight: "19px",
					},
				}}
			>
				<Grid item xs={4}>
					<GeneralInfo data={tCOrder} isLoading={isLoading} />
				</Grid>
				<Grid item xs={4}>
					<TCData data={tCOrder} isLoading={isLoading} />
				</Grid>
				<Grid item xs={4}>
					<TCClient data={tCOrder} isLoading={isLoading} />
				</Grid>
				<Grid item xs={12}>
					<Box className="card" p={3}>
						{isLoading || !tCOrder || !simplifiedOrderModel ? (
							<CustomSkeleton quantity={6} height={30} />
						) : (
							<TCItems
								data={tCOrder}
								simplifiedOrderModel={simplifiedOrderModel}
								isLoading={isLoading}
							/>
						)}
					</Box>
				</Grid>
			</Grid>
		);
	};

	return (
		<>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }} mb={4}>
				<Tabs
					value={currentTab}
					onChange={handleTabChange}
					role="navigation"
					sx={{ ".MuiTab-root": { textTransform: "none", color: "inherit" } }}
				>
					<Tab
						label="Detalhes"
						component={Link}
						to={`/troque-commerce/${routeParams.page}/${routeParams.id}`}
					/>
					<Tab
						label="Histórico"
						component={Link}
						to={`/troque-commerce/${routeParams.page}/${routeParams.id}/history`}
					/>
				</Tabs>
			</Box>
			<Switch>
				<Route
					path="/troque-commerce/:page/:id"
					exact
					children={renderDetailsTab}
				/>
				<Route
					path="/troque-commerce/:page/:id/history"
					exact
					children={<TCHistory data={tCOrder} isLoading={isLoading} />}
				/>
			</Switch>
		</>
	);
}
