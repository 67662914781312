import React, { Dispatch, SetStateAction } from "react";
import ContentLoader from "react-content-loader";
import ProgressOrdersService from "./ProgressOrdersService";
import { LegacyOrderStatus } from "../../../../domain/enums/LegacyOrderStatus";
import { i18n } from "../../../../translate/i18n";

type Props = {
	setIsDeliveryReceiptDialogOpen: Dispatch<SetStateAction<boolean>>;
	setIsOrderCheckDialogOpen: Dispatch<SetStateAction<boolean>>;
	setIsChangeOrderOpen: Dispatch<SetStateAction<boolean>>;
	setStatusToChangeTo: Dispatch<SetStateAction<string>>;
	historic: Function;
};

export default function ProgressOrders({
	setIsDeliveryReceiptDialogOpen,
	setIsOrderCheckDialogOpen,
	setIsChangeOrderOpen,
	setStatusToChangeTo,
	historic,
}: Props) {
	const {
		orderModel,
		statusLabel,
		onActionButtonClick,
		handleHistoric,
		reprocessOrder,
		// From useOrders context
		loading,
		actionsButtons,
		positions,
		descriptions,
		levels,
		role,
		freight,
	} = ProgressOrdersService({
		setIsDeliveryReceiptDialogOpen,
		setIsOrderCheckDialogOpen,
		setIsChangeOrderOpen,
		setStatusToChangeTo,
		historic,
	});

	const skeleton = () => {
		return (
			<ContentLoader
				speed={2}
				width={1100}
				height={200}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1800 500"
				style={{ width: "100%", height: "100%" }}
			>
				<rect
					x="30"
					y="100"
					rx="10"
					ry="10"
					width="400"
					height="60"
					className="bg-danger"
				/>
				<rect
					x="1240"
					y="100"
					rx="10"
					ry="10"
					width="500"
					height="60"
					className="bg-danger"
				/>
				<circle cx="220" cy="350" r="25" />
				<rect x="220" y="345" rx="10" ry="10" width="350" height="10" />
				<circle cx="570" cy="350" r="25" />
				<rect x="550" y="345" rx="10" ry="10" width="350" height="10" />
				<circle cx="900" cy="350" r="25" />
				<rect x="880" y="345" rx="10" ry="10" width="350" height="10" />
				<circle cx="1250" cy="350" r="25" />
				<rect x="1220" y="345" rx="10" ry="10" width="350" height="10" />
				<circle cx="1565" cy="350" r="25" />
			</ContentLoader>
		);
	};

	const showReprocessBtnGroup = () => {
		// Temporary Code - will be changed when backend finish their changes on "button" from GetOrder response
		// This Function will probably be deleted when endpoint response is adequate
		return (
			<div className="btn-group dropdown">
				<button
					type="button"
					className={
						"btn btn-blueish-80 d-flex justify-content-start border border-white"
					}
					style={{ width: "220px", height: "40px" }}
					onClick={() => reprocessOrder()}
				>
					{i18n.t("legacyOrderProgress.ReprocessOrder").toString()}
				</button>
				<button
					type="button"
					className={
						"btn btn-blueish-80 dropdown-toggle dropdown-toggle-split border border-white"
					}
					data-toggle={"dropdown"}
					aria-haspopup="true"
					aria-expanded="false"
					style={{ height: "40px" }}
				>
					<span className="sr-only">Toggle Dropdown</span>
				</button>
				<div
					className="dropdown-menu p-0"
					style={{ marginLeft: "-218px", height: "40px" }}
				>
					<button
						className="dropdown-item text-danger d-flex justify-content-start"
						onClick={() => onActionButtonClick("CanceledBySeller")}
						style={{
							paddingLeft: "15px",
							width: "262px",
							height: "40px",
							backgroundColor: "#ffffff",
							cursor: "pointer",
						}}
					>
						{i18n.t("legacyOrderProgress.CancelOrder").toString()}
					</button>
				</div>
			</div>
		);
	};

	const showDeliveryOrModalitySubtitle = () => {
		if (role === 8) {
			return (
				<span className="card-subtitle mt-1">
					<strong>
						{i18n.t("legacyOrderProgress.ShippingType").toString()}{" "}
					</strong>
					{freight.description}
				</span>
			);
		}
		return (
			<span className="card-subtitle mt-1">
				<strong>{i18n.t("legacyOrderProgress.Modality").toString()} </strong>
				{orderModel?.isDelivery
					? i18n.t("legacyOrderProgress.ReceiveAtHome").toString()
					: i18n.t("legacyOrderProgress.PickupInStore").toString()}
			</span>
		);
	};

	const getFirstElementClassName = () => {
		const isValidatedPosition = positions[0] === 1;
		if (isValidatedPosition && levels[0] === 1) return "started";
		if (isValidatedPosition && levels[0] === 3) return "validated";
		return "";
	};
	const getSecondElementClassName = () => {
		if (
			levels[0] === 1 &&
			levels[1] === 1 &&
			levels[2] === 1 &&
			levels[3] === 1 &&
			levels[4] === 1
		) {
			return "";
		}

		const isValidatedPosition = positions[1] === 2;
		if (
			(positions[1] === 100 && levels[1] === 4) ||
			(isValidatedPosition && levels[1] === 4)
		)
			return "err";
		if (isValidatedPosition && levels[1] === 1) return "waiting";
		if (isValidatedPosition && levels[1] === 2) return "warning";
		if (isValidatedPosition && levels[1] === 3) return "success";
		return "";
	};

	const getThirdElementClassName = () => {
		if (
			levels[1] === 1 &&
			levels[2] === 1 &&
			levels[3] === 1 &&
			levels[4] === 1
		) {
			return "";
		}

		const isValidatedPosition = positions[2] === 3;
		if (
			(positions[2] === 100 && levels[1] === 4) ||
			(isValidatedPosition && levels[2] === 4)
		)
			return "err";
		if (isValidatedPosition && levels[2] === 1) return "waiting";
		if (isValidatedPosition && levels[2] === 2) return "warning";
		if (isValidatedPosition && levels[2] === 3) return "success";
		return "";
	};

	const getFourthElementClassName = () => {
		if (levels[2] === 1 && levels[3] === 1 && levels[4] === 1) return "";

		const isValidatedPosition = positions[3] === 4;
		if (
			(positions[3] === 100 && levels[1] === 4) ||
			(isValidatedPosition && levels[3] === 4)
		)
			return "err";
		if (isValidatedPosition && levels[3] === 1) return "waiting";
		if (isValidatedPosition && levels[3] === 2) return "warning";
		if (isValidatedPosition && levels[3] === 3) return "success";
		return "";
	};

	const getFifthElementClassName = () => {
		if (levels[3] === 1 && levels[4] === 1) return "";

		const isValidatedPosition = positions[4] === 5;
		if (
			(positions[4] === 100 && levels[1] === 4) ||
			(isValidatedPosition && levels[4] === 4)
		)
			return "err";
		if (isValidatedPosition && levels[4] === 1) return "waiting";
		if (isValidatedPosition && levels[4] === 2) return "warning";
		if (isValidatedPosition && levels[4] === 3) return "success";
		return "";
	};

	const showTimeline = () => {
		return (
			<div className="timeline">
				<ul>
					<li className={getFirstElementClassName()}>
						<span>{descriptions[0]}</span>
					</li>
					<li className={getSecondElementClassName()}>
						<span>{descriptions[1]}</span>
					</li>
					<li className={getThirdElementClassName()}>
						<span>{descriptions[2]}</span>
					</li>
					<li className={getFourthElementClassName()}>
						<span>{descriptions[3]}</span>
					</li>
					<li className={getFifthElementClassName()}>
						<span>{descriptions[4]}</span>
					</li>
				</ul>
			</div>
		);
	};

	const renderContent = () => {
		if (loading) return skeleton();

		return (
			<>
				<div className="status" style={{ height: "60px" }}>
					<div className="order-label d-flex flex-column">
						<h2 className="card-title m-0 p-0">
							{i18n.t("legacyOrderProgress.OrderStatus").toString()}{" "}
							<label className={`${statusLabel.color} m-0 p-0`}>
								{statusLabel.label}
							</label>
						</h2>
						{showDeliveryOrModalitySubtitle()}
					</div>
					{orderModel?.status === LegacyOrderStatus.ErrorUpdate ? (
						showReprocessBtnGroup()
					) : (
						<div className="btn-group dropdown">
							{actionsButtons.length >= 1 && (
								<button
									type="button"
									className={`${
										actionsButtons[0].action === "DELETE"
											? "btn btn-danger rounded"
											: actionsButtons[0].backgroundColor
									} d-flex justify-content-start border border-white`}
									style={{ width: "220px", height: "40px" }}
									onClick={() =>
										onActionButtonClick(actionsButtons[0].endpoint)
									}
								>
									{actionsButtons[0].name}
								</button>
							)}

							{actionsButtons.length > 1 && (
								<>
									<button
										type="button"
										className={`${actionsButtons[0].backgroundColor} dropdown-toggle dropdown-toggle-split border border-white`}
										data-toggle={"dropdown"}
										aria-haspopup="true"
										aria-expanded="false"
										style={{ height: "40px" }}
									>
										<span className="sr-only">Toggle Dropdown</span>
									</button>
									<div
										className="dropdown-menu p-0"
										style={{ marginLeft: "-218px", height: "40px" }}
									>
										{actionsButtons.map(
											(button: any) =>
												actionsButtons[0].name !== button.name && (
													<button
														key={button.name}
														className="dropdown-item text-danger d-flex justify-content-start"
														onClick={() => onActionButtonClick(button.endpoint)}
														style={{
															paddingLeft: "15px",
															width: "262px",
															height: "40px",
															backgroundColor: "#ffffff",
															cursor: "pointer",
														}}
													>
														{button.name}
													</button>
												)
										)}
									</div>
								</>
							)}
						</div>
					)}
				</div>
				<div className="progress-orders">
					{showTimeline()}
					<div className="pt-4 d-flex justify-content-end">
						<button
							className="text-link-timeline pr-4"
							onClick={() => handleHistoric(true)}
						>
							{i18n.t("legacyOrderProgress.SeeFullOrderHistory").toString()}
						</button>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
}
