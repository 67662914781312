import { useState } from "react";
import { retailerManagementApi } from "../../infrastructure/api/RetailerManagementApi";
import { useHistory } from "react-router-dom";
import { SellerView } from "../../domain/views/stores/SellerView";
import { SellerDto } from "../../domain/dtos/stores/SellerDto";
import { SellerBasicInfoView } from "../../domain/views/stores/SellerBasicInfoView";
import NewToastComponent from "../../components/NewToastComponent";
import { SellerPaymentView } from "../../domain/views/stores/SellerPaymentView";
import { API } from "../../API";

export default function useSellerScreenService() {
	const history = useHistory();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [currentComponent, setCurrentComponent] = useState<string>("basic");
	const [seller, setSeller] = useState<SellerView>({
		pointSaleId: null,
		gatewayId: null,
		credentialsString: null,
		whatsappPhoneNumber: null,
		phoneNumber: null,
		companyName: null,
		fullName: "",
		email: "",
		password: null,
		clientId: "",
		cnpj: null,
		processId: null,
		currentCluster: null,

		isDeleted: false,
		isEcommerce: null, // TODO boolean nullable, backend needs to fix it
		isSellerOnline: null, // TODO boolean nullable, backend needs to fix it
		isActive: false,
		isShowSellerInMap: null, // TODO boolean nullable, backend needs to fix it

		id: null,
		addressId: null,

		integrationConfig: null,
		address: null,

		modalities: new Array(),
		workingHours: new Array(),
	});

	const getSeller = async (sellerId: string): Promise<void> => {
		try {
			let data: SellerDto = await retailerManagementApi.getSeller(sellerId);
			setSeller(data);
			setIsLoading(false);
		} catch (error) {
			NewToastComponent({
				status: "error",
				title: "Não foi possível carregar loja",
				message: "Você será redirecionado",
			});
			setTimeout(() => {
				history.push(`/stores/1`);
			}, 3000);
		}
	};

	const updatePaymentData = async (
		sellerId: string,
		sellerData: SellerView,
		paymentData: SellerPaymentView
	): Promise<boolean> => {
		let updateSellerData: SellerDto = { ...sellerData, ...paymentData };
		let success = await retailerManagementApi.updateSeller(
			sellerId,
			updateSellerData
		);
		if (!success) return false;

		await getSeller(sellerId);
		return true;
	};

	const createSeller = async (basicInfoData: SellerView): Promise<boolean> => {
		let email = {
			email: basicInfoData.email,
		};
		// TODO create API file of https://lori.neomode.com.br endpoint
		try {
			await API.post("api/users/rememberpassword", email);
		} catch (error) {
			console.error(error);
		}

		let success = await retailerManagementApi.createSeller(basicInfoData);

		if (!success) return false;

		if (window.location.pathname.search("setup") === -1) {
			history.push(`/stores/1`);
		} else {
			history.push(`/setup/stores/1`);
		}
		return true;
	};

	const updateSellerBasicInfo = async (
		sellerId: string,
		sellerData: SellerView,
		basicInfoData: SellerBasicInfoView
	): Promise<boolean> => {
		let updateSellerData: SellerDto = { ...sellerData, ...basicInfoData };
		let success = await retailerManagementApi.updateSeller(
			sellerId,
			updateSellerData
		);
		if (!success) return false;

		await getSeller(sellerId);
		return true;
	};

	return {
		//Props
		isLoading,
		setIsLoading,
		currentComponent,
		setCurrentComponent,
		seller,
		// Functions
		getSeller,
		updatePaymentData,
		createSeller,
		updateSellerBasicInfo,
	};
}
