import { CpOrderDto } from "../../../../../domain/dtos/cpCommerceManagement/CpOrderDto";
import { CpOrderStatusDto } from "../../../../../domain/dtos/cpCommerceManagement/CpOrderStatusDto";
import { OrderDto } from "../../../../../domain/dtos/smOrders/OrderDto";
import { OrderStatus } from "../../../../../domain/enums/OrderStatus";
import { SummaryStatusEnum } from "../SummaryStatusEnum";

const mapCpToGenericStatus = (
	status: CpOrderStatusDto
): SummaryStatusEnum | undefined => {
	switch (status) {
		case CpOrderStatusDto.CreatedInternal:
			// case CpOrderStatusDto.CreatedExternal: // Passing "PreorderCreated" to be considered as WaitingSeparation
			return SummaryStatusEnum.WaitingPayment;
		case CpOrderStatusDto.AwaitingCreation:
			return SummaryStatusEnum.WaitingSellerApproval;
		case CpOrderStatusDto.CreatedExternal:
			return SummaryStatusEnum.WaitingSeparation;
		case CpOrderStatusDto.AwaitingInvoiceCreation:
			return SummaryStatusEnum.WaitingInvoicing;
		case CpOrderStatusDto.Separated:
		case CpOrderStatusDto.InvoiceCreated:
			return SummaryStatusEnum.WaitingDispatch;
		case CpOrderStatusDto.Shipping:
			return SummaryStatusEnum.WaitingDelivery;
		case CpOrderStatusDto.ReadyForPickup:
			return SummaryStatusEnum.WaitingWithdrawal;
		case CpOrderStatusDto.Finalized:
			return SummaryStatusEnum.Finalized;
		case CpOrderStatusDto.Canceled:
			return SummaryStatusEnum.Canceled;
		case CpOrderStatusDto.Error:
			return SummaryStatusEnum.WithError;
		default:
			return;
	}
};

const mapSmToGenericStatus = (
	status: OrderStatus,
	isDelivery: boolean
): SummaryStatusEnum | undefined => {
	// WithError is not a SM status, só it is being validated outside of this function to be counted
	switch (status) {
		case OrderStatus.Created:
			return SummaryStatusEnum.WaitingPayment;
		case OrderStatus.WaitingApproval:
			return SummaryStatusEnum.WaitingSellerApproval;
		case OrderStatus.WaitingSeparation:
			return SummaryStatusEnum.WaitingSeparation;
		case OrderStatus.WaitingInvoice:
			return SummaryStatusEnum.WaitingInvoicing;
		case OrderStatus.WaitingDispatch:
			return SummaryStatusEnum.WaitingDispatch;
		case OrderStatus.WaitingDelivery:
			if (isDelivery) return SummaryStatusEnum.WaitingDelivery;
			return SummaryStatusEnum.WaitingWithdrawal;
		case OrderStatus.Finalized:
			return SummaryStatusEnum.Finalized;
		case OrderStatus.Canceled:
			return SummaryStatusEnum.Canceled;
		default:
			return;
	}
};

export const mapStatusToGeneric = (
	order: OrderDto | CpOrderDto,
	isStateMachine: boolean
): SummaryStatusEnum | undefined => {
	if (isStateMachine)
		return mapSmToGenericStatus(
			(order as OrderDto).Status,
			(order as OrderDto).IsDelivery
		);
	return mapCpToGenericStatus((order as CpOrderDto).status);
};
