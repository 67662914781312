import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { loriTriggersApi } from "../../../infrastructure/api/LoriTriggers";
export default function IntegrationState(props) {
	const [loading, setLoading] = useState(true);
	const [integrations, setIntegrations] = useState([]);

	useEffect(() => {
		if (props?.integrationsList) {
			let activeIntegrations = props.integrationsList.filter(
				(integration) => integration.isActive === true
			);
			getExecutionCountIntegrations(activeIntegrations);
			setLoading(false);
			return;
		}
		getIntegrations();
		setInterval(async () => {
			getIntegrations();
		}, 60000);
	}, []);

	const getIntegrations = async () => {
		try {
			const response = await loriTriggersApi.getLoriTriggers(
				localStorage.getItem("client_id")
			);
			setLoading(false);
			let activeIntegrations = response.filter(
				(integration) => integration.isActive === true
			);
			getExecutionCountIntegrations(activeIntegrations);
		} catch {
			setLoading(false);
		}
	};

	const getExecutionCountIntegrations = (activeIntegrations) => {
		const newIntegrations = activeIntegrations.reduce(
			(addExecutionCount, currentAction) => {
				let action = currentAction.action;
				let repeated = addExecutionCount.find((elem) => elem.action === action);
				if (repeated) repeated.executionCount += currentAction.executionCount;
				else addExecutionCount.push(currentAction);
				return addExecutionCount;
			},
			[]
		);
		setIntegrations(newIntegrations);
	};

	const cardTitle = (integration) => {
		switch (integration.action) {
			case "GetCategories":
				return "Categorias Integradas";
			case "GetProducts":
				return "Produtos Integrados";
			case "GetPrices":
				return "Preços Integrados";
			case "GetSkus":
				return "Variações Integrados";
			case "GetStocks":
				return "Estoques Integrados";
			case "GetInvoices":
				return "Notas Fiscais Integradas";
			case "GetOrdersStatus":
				return "Status de Pedido Integrados";
			case "GetTracking":
				return "Rastreamentos";
			case "OrderCanceled":
				return "Pedidos Cancelados Integrados";
			case "GetPayment":
				return "Status de Pagamento Integrados";
			case "GetOrder":
				return "Pedidos Integrados";
			default:
				return "Integração em Andamento";
		}
	};

	const status = (integration) => {
		if (integration.isRunning) {
			return "Em execução";
		} else {
			switch (integration.message) {
				case "Finalized":
					return "Integrado";
				case "Running":
					return "Em execução";
				default:
					return "Erro na integração";
			}
		}
	};

	const icons = (integration) => {
		switch (integration.action) {
			case "GetCategories":
				return (
					<i
						className="material-icons"
						style={{ color: "#ffffff", fontSize: "24px" }}
					>
						playlist_add_check
					</i>
				);
			case "GetProducts":
				return (
					<i
						className="material-icons"
						style={{ color: "#ffffff", fontSize: "28px" }}
					>
						checkroom
					</i>
				);
			case "GetPrices":
				return (
					<i
						className="material-icons"
						style={{ color: "#ffffff", fontSize: "24px" }}
					>
						attach_money
					</i>
				);
			case "GetSkus":
				return (
					<i
						className="material-icons-outlined"
						style={{ color: "#ffffff", fontSize: "24px" }}
					>
						color_lens
					</i>
				);
			case "GetStocks":
				return (
					<i
						className="material-icons-outlined"
						style={{ color: "#ffffff", fontSize: "24px" }}
					>
						all_inbox
					</i>
				);
			case "GetOrdersStatus":
				return (
					<i
						className="material-icons"
						style={{ color: "#ffffff", fontSize: "24px" }}
					>
						hourglass_top
					</i>
				);
			case "GetInvoices":
				return (
					<i
						className="material-icons"
						style={{ color: "#ffffff", fontSize: "24px" }}
					>
						receipt_long
					</i>
				);
			case "GetTracking":
				return (
					<i
						className="material-icons"
						style={{ color: "#ffffff", fontSize: "24px" }}
					>
						list_alt
					</i>
				);
			case "OrderCanceled":
				return (
					<i
						className="material-icons"
						style={{ color: "#ffffff", fontSize: "24px" }}
					>
						highlight_off
					</i>
				);
			case "GetPayment":
				return (
					<i
						className="material-icons-outlined"
						style={{ color: "#ffffff", fontSize: "24px" }}
					>
						payments
					</i>
				);
			case "GetOrder":
				return (
					<i
						className="material-icons"
						style={{ color: "#ffffff", fontSize: "24px" }}
					>
						receipt
					</i>
				);
			default:
				return (
					<i
						className="material-icons"
						style={{ color: "#ffffff", fontSize: "24px" }}
					>
						device_hub
					</i>
				);
		}
	};

	const roundedStyle = (integration) => {
		let bckgroundColor = "";
		if (integration.isRunning) {
			bckgroundColor = "#7A9FEB";
		} else {
			switch (integration.message) {
				case "Finalized":
					bckgroundColor = "#6CD691";
					break;
				case "Running ":
					bckgroundColor = "#7A9FEB";
					break;
				default:
					bckgroundColor = "#FF5B5C";
			}
		}
		return { backgroundColor: bckgroundColor, width: "40px", height: "40px" };
	};

	const statusStyle = (integration) => {
		if (integration.isRunning) {
			return {
				fontSize: "12px",
				color: "#7A9FEB",
				border: "1px solid #7A9FEB",
				borderRadius: "15px",
				padding: "4px 16px",
			};
		} else {
			switch (integration.message) {
				case "Finalized":
					return {
						fontSize: "12px",
						color: "#6CD691",
						border: "1px solid #6CD691",
						borderRadius: "15px",
						padding: "4px 16px",
					};
				case "Running ":
					return {
						fontSize: "12px",
						color: "#7A9FEB",
						border: "1px solid #7A9FEB",
						borderRadius: "15px",
						padding: "4px 16px",
					};
				default:
					return {
						fontSize: "12px",
						color: "#FF5B5C",
						border: "1px solid #FF5B5C",
						borderRadius: "15px",
						padding: "4px 12px",
					};
			}
		}
	};

	const dateHour = (integration) => {
		const now = new Date();
		const timestamp = new Date(integration.lastEndRun);
		const subTime = now.getTime() - timestamp.getTime();
		const minutes = Math.round(subTime / 60000);
		if (minutes > 60) {
			const hours = Math.round(minutes / 60);
			return `Última atualização: ${hours} horas atrás`;
		} else {
			return `Última atualização: ${minutes} minutos atrás`;
		}
	};

	const skeleton = () => {
		const skele = [0, 1, 2, 3];
		return skele.map((index) => (
			<ContentLoader
				key={index}
				speed={2}
				width={1100}
				height={70}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 100"
				style={{ width: "100%" }}
			>
				<rect x="150" y="20" rx="3" ry="3" width="200" height="20" />
				<rect x="150" y="51" rx="3" ry="3" width="80" height="6" />
				<rect x="900" y="20" rx="3" ry="3" width="1500" height="20" />
				<rect x="1040" y="51" rx="3" ry="3" width="59" height="8" />
				<circle cx="70" cy="51" r="50" />
			</ContentLoader>
		));
	};

	const renderScreen = () => {
		return (
			<>
				<div className={`integrations-list pr-3 mr-0`}>
					{integrations.map(
						(integration, index) =>
							integration.action !== "GetProductsAuxCode" && (
								<div className="p-0 my-0" key={index}>
									<div className="d-flex">
										<div className="pr-2">
											<div
												className="rounded-circle d-flex align-items-center justify-content-center"
												style={roundedStyle(integration)}
											>
												{icons(integration)}
											</div>
										</div>

										<div className="mb-3">
											<h2
												className="card-title mb-0 pb-0"
												style={{ fontSize: "14px", width: "192px" }}
											>
												{cardTitle(integration)}
											</h2>

											<h6
												className="card-text mt-1"
												style={{ fontSize: "12px", color: "#BCBEC0" }}
											>
												{`${dateHour(integration)}`}
											</h6>
										</div>

										<div className="d-flex justify-content-end align-self-center w-100 mr-0 pr-0">
											<div className="">
												<h2
													className="card-title"
													style={statusStyle(integration)}
												>
													{status(integration)}
												</h2>
											</div>
										</div>
									</div>
								</div>
							)
					)}
				</div>
			</>
		);
	};

	const noIntegration = () => {
		return (
			<div className=" align-items-center py-5 empty">
				<div className="text-center">
					<p style={{ color: "#C4C4C4", fontSize: "14px" }}>
						{" "}
						Seus status ainda não estão disponíveis no momento
					</p>
				</div>
			</div>
		);
	};

	const handleTypeScreen = () => {
		if (integrations.length > 0 && loading === false) return renderScreen();

		noIntegration();
	};

	return (
		<>
			{localStorage.getItem("role") !== "1" && (
				<>
					<h2 className="card-title" style={{ fontSize: "18px" }}>
						Status da plataforma
					</h2>
					<h6
						className="card-subtitle mb-3 text-muted"
						style={{ fontSize: "14px" }}
					>
						Status do sistema e ultimas rotinas de integração
					</h6>
				</>
			)}
			{loading ? skeleton() : handleTypeScreen()}
		</>
	);
}
