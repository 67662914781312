import React, { useState, useEffect } from "react";
import { APIV2 } from "../../../../../API";
import Modal from "../../../../../components/Modal";
import { toast } from "react-toastify";

export default function SelectMechanic(props) {
	const [loading, setLoading] = useState(true);
	const [mechanichs, setMechanichs] = useState([]);
	useEffect(() => {
		APIV2.get("management/marketing/promotionengine/mechanics")
			.then(async (response) => {
				setMechanichs(response.data);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	}, []);
	return (
		<Modal
			id={"selectMech"}
			title={"Regras da promoção"}
			small={true}
			content={
				<div className="row p-3 conditions">
					<ul>
						{mechanichs.map((item) => (
							<li
								key={item.type}
								data-dismiss="modal"
								onClick={() => props.select(item)}
							>
								{item.name}
							</li>
						))}
					</ul>
				</div>
			}
		/>
	);
}
