import React, { useEffect } from "react";
import ProductList from "./components/ProductList.jsx";
import { useConfig } from "./../../../providers/ConfigProvider";
import LoadDashboard from "../../Dashboard/components/LoadDashboard";

import "./ProductsStyle.scss";
import ProductsContext from "./providers/ProductsContext.jsx";

export default function Products(props) {
	const { mainLoading } = useConfig();
	useEffect(() => {
		if (!props.match.params.page) {
			window.location.href = `/products/1`;
		}
	}, [props.match.params]);

	return (
		<>
			{mainLoading ? (
				<LoadDashboard />
			) : (
				<ProductsContext>
					<ProductList />
				</ProductsContext>
			)}
		</>
	);
}
