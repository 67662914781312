import styled from "styled-components";
import { paletteObject } from "../../../../../theme/foundations/palette";

export const Button = styled.button<{ color?: string }>`
	width: 40px;
	height: 40px;
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background: transparent;
	cursor: pointer;
	color: ${({ color }) => color};
	outline: none;

	&:focus {
		outline: none;
	}

	&:hover {
		border-radius: 5px;
		background: ${paletteObject.grey10.main};
	}
`;

export const ModalBody = styled.div`
	width: 500px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: ${paletteObject.white.main};
	border: 2px solid ${paletteObject.white.main};
	border-radius: 4px;
	padding: 35px;
`;

export const ColorBox = styled.div<{ error: boolean }>`
	width: 100%;
	border: 2px solid
		${({ error }) =>
			error ? paletteObject.danger.main : paletteObject.grey20.main};
	border-radius: 4px;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	position: relative;
	padding: 5px;
`;

export const TitleBox = styled.div`
	position: absolute;
	top: -15px;
	left: 8%;
	background: ${paletteObject.white.main};
	color: ${paletteObject.blueish80.main};
	padding: 0 5px;
	text-align: center;
`;

export const BorderBox = styled.div`
	margin-right: 10px;
	border: 2px solid ${paletteObject.grey50.main};
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	width: 20px;
	height: 20px;
`;
