import { Dispatch, SetStateAction, useEffect, useState } from "react";
import NewToastComponent from "../NewToastComponent";
import { i18n } from "../../translate/i18n";
import { AutocompleteStateView } from "../../domain/views/legacyApi/AutocompleteStateView";
import { AutocompleteStateDto } from "../../domain/dtos/legacyApi/AutocompleteStateDto";
import { loriLegacyApi } from "../../infrastructure/api/LoriLegacyApi";

export default function StateAutocompleteService(
	stateCode: string | null,
	setCurrentState: Dispatch<SetStateAction<AutocompleteStateView | null>>
) {
	const [currentStateInput, setCurrentStateInput] = useState<string>("");
	const [statesList, setStatesList] = useState<AutocompleteStateView[]>([]);

	useEffect(() => {
		if (statesList.length > 0) {
			findCurrentState(statesList);
			return;
		}
		getAllStates();
	}, [stateCode]);

	const findCurrentState = (states: AutocompleteStateView[]) => {
		const foundState = states.find((state) => state.code === stateCode);
		if (foundState) setCurrentState(foundState);
	};

	const getAllStates = async (): Promise<void> => {
		try {
			const data: AutocompleteStateDto[] = await loriLegacyApi.getAllStates();
			setStatesList(data);
			if (!stateCode) return;
			findCurrentState(data);
		} catch (error) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
		}
	};

	const filterOptions = (option: AutocompleteStateView): string => {
		option.code = option.code ?? "";
		option.name = option.name ?? "";
		return option.code + option.name;
	};

	const getOptionLabel = (option: AutocompleteStateView): string =>
		`${option.code} - ${option.name}`;

	const isOptionEqualToValue = (
		option: AutocompleteStateView,
		value: AutocompleteStateView
	): boolean => option.id === value.id;

	return {
		statesList,
		currentStateInput,
		setCurrentStateInput,
		// Functions
		filterOptions,
		getOptionLabel,
		isOptionEqualToValue,
	};
}
