import { TypographyOptions } from "@mui/material/styles/createTypography";

export const typography: TypographyOptions = {
	fontFamily: [
		"-apple-system",
		"BlinkMacSystemFont",
		'"Segoe UI"',
		"Roboto",
		'"Helvetica Neue"',
		"Arial",
		"sans-serif",
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(","),
	fontSize: 14,
	allVariants: {
		lineHeight: 1.2,
	},
};
