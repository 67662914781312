import React, { Component } from "react";
import EditorHtml from "../assets/EditorHtml";
import Button from "./Button";
import DOMPurify from "dompurify";

class Collapse extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [],
			view: false,
			question: this.props.item.question,
			answer: this.props.item.answer,
			order: this.props.item.order,
		};
		this.changeView = this.changeView.bind(this);
		this.changeAnwser = this.changeAnwser.bind(this);
		this.changeQuestion = this.changeQuestion.bind(this);
		this.saveQuestion = this.saveQuestion.bind(this);
	}

	async changeView() {
		await this.setState({ view: !this.state.view });
		this.forceUpdate();
	}
	async changeAnwser(e) {
		await this.setState({ answer: DOMPurify.sanitize(e) });
		this.forceUpdate();
	}
	async changeQuestion(e) {
		await this.setState({ question: e.target.value });
		this.forceUpdate();
	}
	saveQuestion() {
		this.changeView();
		this.props.changeItem(
			this.state.question,
			this.state.answer,
			this.state.order
		);
	}

	render() {
		return (
			<div className="dragndrop-item">
				<div
					className={!this.state.view ? "d-flex preview-collapse" : "d-none"}
				>
					<div className="content">
						<h5>{this.props.item.question}</h5>
						<div
							className="preview-body"
							dangerouslySetInnerHTML={{ __html: this.props.item.answer }}
						></div>
					</div>
					<div className="actions d-flex align-items-center justify-content-end">
						<div className="btn-group" role="group" aria-label="Basic example">
							<button
								type="button"
								className="btn btn-light btn-sm outline btn-icon"
							>
								<i className="material-icons"> open_with</i>
							</button>
							<button
								type="button"
								className="btn btn-light btn-sm outline btn-icon"
								onClick={() => this.changeView()}
							>
								<i className="material-icons">edit</i>
							</button>
							<button
								type="button"
								className="btn btn-light btn-sm outline btn-icon"
								onClick={() =>
									this.props.deleteFaq(
										this.props.item.id,
										this.props.item.order
									)
								}
							>
								<i className="material-icons text-danger">delete_outline</i>
							</button>
						</div>
					</div>
				</div>

				<div className={this.state.view ? "" : "d-none"}>
					<div>
						<form>
							<div className="form-group">
								<label htmlFor="question" className="font-weight-bold">
									Pergunta :{" "}
								</label>
								<input
									type="text"
									className="form-control"
									id="question"
									defaultValue={this.props.item.question}
									onChange={this.changeQuestion}
								/>
							</div>
							<div className="form-group">
								<label htmlFor="question" className="font-weight-bold">
									Resposta :{" "}
								</label>
								<EditorHtml
									change={this.changeAnwser}
									data={this.props.item.answer}
								/>
							</div>
							<div className="text-right">
								<Button
									className="btn btn-light"
									type="button"
									onClick={() => this.changeView()}
								>
									Cancelar
								</Button>
								&nbsp;&nbsp;
								<Button
									className="btn btn-success"
									type="button"
									onClick={() => this.saveQuestion()}
								>
									Salvar
								</Button>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

export default Collapse;
