import React, { useEffect, useState } from "react";
import EditorHtml from "../../../assets/EditorHtml";
import { API } from "../../../API";
import { toast } from "react-toastify";
import ContentLoader from "react-content-loader";
import DOMPurify from "dompurify";

function Regulation(props) {
	const [content, setContent] = useState("");
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		API.get("api/promotionpolicy/")
			.then((response) => {
				if (response.data == null) {
					setLoading(false);
				} else {
					setContent(response.data.content);
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	}, []);

	const handleChange = (e) => {
		setContent(DOMPurify.sanitize(e));
	};

	const onSend = () => {
		setLoading(true);
		var body = { content: content };
		API.post("api/promotionpolicy/", body)
			.then(() => {
				setLoading(false);
				toast.success("Os regulamentos foram atualizados com sucesso! 😃");
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const renderScreen = () => {
		return <EditorHtml data={content} change={handleChange} />;
	};

	const skeleton = () => {
		return (
			<>
				<ContentLoader
					speed={2}
					width={780}
					height={400}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="10 10 1100 700"
					style={{ width: "100%" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="1100" height="700" />
				</ContentLoader>
			</>
		);
	};

	return (
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<h4 className="card-title">Regulamento</h4>
			</div>
			<div className="card-content">
				<div className="card-body pt-0">
					{loading ? skeleton() : renderScreen()}
					<div className="d-flex justify-content-end align-items-end mt-3">
						<button
							className="btn btn-success"
							style={{ width: "150px" }}
							onClick={onSend}
						>
							Salvar
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Regulation;
