import React, { useState } from 'react';
import { i18n } from '../../../translate/i18n';

// import { Container } from './styles';

function SideNav(props) {
  const [activeRoute, setActivewRoute] = useState('basic')

  const menu = props.id ? [
    { title: 'Informações básicas', value: 'basic' },
    { title: 'Endereço', value: 'address' },
    { title: 'Modalidades', value: 'modalities' },
    { title: 'Pagamento', value: 'payment' },
    { title: i18n.t("integration.Title"), value: 'integration' },
    { title: 'Horários de funcionamento', value: 'workingHour' },
  ] : [
      { title: 'Informações básicas', value: 'basic' }]


  const handleActive = (value) => {
    setActivewRoute(value)
    props.activeComponent(value)
  }

  return (
    <div className="card sidebar">
      {/* <div className="card-header d-flex align-items-center">
        <h3 className="title-card">{props.menu.title}</h3>
      </div> */}
      <ul style={{ marginTop: '1em' }}>
        {menu.map((item, i) => {
          return (
            <li key={i} className={activeRoute === item.value ? 'active' : null}>
              <button disabled={(item.value === 'address' && props.isEcommerce) || props.isLoading} style={(item.value === 'address' && props.isEcommerce) || props.isLoading ? {color: '#c2c2c2', cursor:'not-allowed'}: null} onClick={() => handleActive(item.value)}>
                {item.title}
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  );
}

export default SideNav;