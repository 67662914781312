import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { LegacyOrderStatus } from "../../../../domain/enums/LegacyOrderStatus";
import { useOrders } from "../../providers/OrdersContext";
import { toast } from "react-toastify";
import { APIV1 } from "../../../../API";
import { OrderModel } from "../../../../domain/models/commerce/OrderModel";
import { i18n } from "../../../../translate/i18n";

type StatusInfo = {
	label: string;
	color: string;
};

type TemporaryContextTypes = {
	orderModel: OrderModel;
	loading: any;
	actionsButtons: any;
	positions: any;
	descriptions: any;
	levels: any;
	orderId: any;
	setLoading: any;
	role: any;
	freight: any;
};

type Props = {
	setIsDeliveryReceiptDialogOpen: Dispatch<SetStateAction<boolean>>;
	setIsOrderCheckDialogOpen: Dispatch<SetStateAction<boolean>>;
	setIsChangeOrderOpen: Dispatch<SetStateAction<boolean>>;
	setStatusToChangeTo: Dispatch<SetStateAction<string>>;
	historic: Function;
};

export default function ProgressOrdersService({
	setIsDeliveryReceiptDialogOpen,
	setIsOrderCheckDialogOpen,
	setIsChangeOrderOpen,
	setStatusToChangeTo,
	historic,
}: Props) {
	const {
		orderModel,
		loading,
		actionsButtons,
		positions,
		descriptions,
		levels,
		orderId,
		setLoading,
		role,
		freight,
	} = useOrders() as TemporaryContextTypes;
	const [statusLabel, setStatusLabel] = useState<StatusInfo>({
		label: "",
		color: "",
	});
	const unparsedConfigurations = localStorage.getItem("configurations");
	const configurations =
		unparsedConfigurations && JSON.parse(unparsedConfigurations)[0];
	const enableOrderPicking = configurations?.enableOrderPicking;

	useEffect(() => {
		if (!orderModel) return;
		getStatusLabel(orderModel.status as LegacyOrderStatus);
	}, [orderModel]);

	enum TextColor {
		Default = "text-blueish-80",
		Success = "text-success",
		Info = "text-info",
		Warning = "text-warning",
		Danger = "text-danger",
	}

	// prettier-ignore
	const statusMap: { [key in LegacyOrderStatus]: StatusInfo } = {
    [LegacyOrderStatus.None]: { label: i18n.t("legacyOrderProgress.None"), color: TextColor.Default },
    [LegacyOrderStatus.Pending]: { label: i18n.t("legacyOrderProgress.Pending"), color: TextColor.Success },
    [LegacyOrderStatus.PendingCreate]: { label: i18n.t("legacyOrderProgress.PendingCreate"), color: TextColor.Info },
    [LegacyOrderStatus.PendingApproval]: {label: i18n.t("legacyOrderProgress.PendingApproval"), color: TextColor.Info },
    [LegacyOrderStatus.ApprovedExternal]: { label: i18n.t("legacyOrderProgress.ApprovedExternal"), color: TextColor.Success },
    [LegacyOrderStatus.Processing]: { label: i18n.t("legacyOrderProgress.Processing"), color: TextColor.Warning },
    [LegacyOrderStatus.Separate]: { label: i18n.t("legacyOrderProgress.Separate"), color: TextColor.Info },
    [LegacyOrderStatus.InDelivery]: { label: i18n.t("legacyOrderProgress.InDelivery"), color: TextColor.Info },
    [LegacyOrderStatus.ReadyForPickup]: { label: i18n.t("legacyOrderProgress.ReadyForPickup"), color: TextColor.Success },
    [LegacyOrderStatus.Finalized]: { label: i18n.t("legacyOrderProgress.Finalized"), color: TextColor.Success },
    [LegacyOrderStatus.CanceledBySeller]: { label: i18n.t("legacyOrderProgress.CanceledBySeller"), color: TextColor.Danger },
    [LegacyOrderStatus.CanceledByBuyer]: { label: i18n.t("legacyOrderProgress.CanceledByBuyer"), color: TextColor.Danger },
    [LegacyOrderStatus.CanceledByAdmin]: { label: i18n.t("legacyOrderProgress.CanceledByAdmin"), color: TextColor.Danger },
    [LegacyOrderStatus.PendingCancel]: { label: i18n.t("legacyOrderProgress.PendingCancel"), color: TextColor.Warning },
    [LegacyOrderStatus.PendingCreatePreOrder]: { label: i18n.t("legacyOrderProgress.PendingCreatePreOrder"), color: TextColor.Info },
    [LegacyOrderStatus.PreOrderCreated]: { label: i18n.t("legacyOrderProgress.PreOrderCreated"), color: TextColor.Success },
    [LegacyOrderStatus.ErrorCancel]: { label: i18n.t("legacyOrderProgress.ErrorCancel"), color: TextColor.Danger },
    [LegacyOrderStatus.PendingUpdate]: { label: i18n.t("legacyOrderProgress.PendingUpdate"), color: TextColor.Warning },
    [LegacyOrderStatus.Updated]: { label: i18n.t("legacyOrderProgress.Updated"), color: TextColor.Success },
    [LegacyOrderStatus.ErrorUpdate]: { label: i18n.t("legacyOrderProgress.ErrorUpdate"), color: TextColor.Danger },
    [LegacyOrderStatus.InvoiceCreated]: { label: i18n.t("legacyOrderProgress.InvoiceCreated"), color: TextColor.Info },
    [LegacyOrderStatus.CanceledByProcessor]: { label: i18n.t("legacyOrderProgress.CanceledByProcessor"), color: TextColor.Danger },
  };

	const onActionButtonClick = (buttonEndpoint: string): void => {
		setStatusToChangeTo(buttonEndpoint);
		if (buttonEndpoint === "Finalized") setIsDeliveryReceiptDialogOpen(true);
		else if (buttonEndpoint === "Separate" && enableOrderPicking)
			setIsOrderCheckDialogOpen(true);
		else setIsChangeOrderOpen(true);
	};

	const getStatusLabel = (status: LegacyOrderStatus): void => {
		const statusInfo = statusMap[status] || statusMap[LegacyOrderStatus.None];
		setStatusLabel(statusInfo);
	};

	const handleHistoric = (bool: boolean): void => {
		historic(bool);
	};

	const reprocessOrder = async (): Promise<void> => {
		// Temporary Code - will be changed when backend finish their changes on "button" from GetOrder response
		setLoading(true);
		await APIV1.put(`orderprocessor/orders/${orderId}/reprocess`)
			.then(() => {
				toast.success(
					i18n.t("legacyOrderProgress.ReprocessSuccess").toString()
				);
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			})
			.catch(() => {
				toast.error(i18n.t("errorMessages.ERR.REQUEST").toString());
				setLoading(false);
			});
	};

	return {
		orderModel,
		statusLabel,
		onActionButtonClick,
		handleHistoric,
		reprocessOrder,
		// From useOrders context
		loading,
		actionsButtons,
		positions,
		descriptions,
		levels,
		role,
		freight,
	};
}
