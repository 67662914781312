import { ChangeEvent, useEffect, useState } from "react";
import { i18n } from "../../../../../translate/i18n";
import { AttributeTypes } from "../../enums/AttributeTypes";
import { useParams } from "react-router-dom";
import {
	FormErrors,
	InputValidator,
} from "../../../../../infrastructure/utils/FormUtils";
import { AttributesDto } from "../../../../../domain/dtos/products/attributes/AttributesDto";
import { catalogManagementApi } from "../../../../../infrastructure/api/CatalogManagementApi";
import { DropResult } from "react-beautiful-dnd";
import NewToastComponent from "../../../../../components/NewToastComponent";
import { isValidString } from "../../../../../infrastructure/utils/TypeValidator";
import useUnsavedFormSafety from "../../../../../hooks/useUnsavedFormSafety";
import { validateInput } from "../../../../../infrastructure/utils/ValidateInput";

type TableHeaderType = {
	title: string;
	position?: "left" | "center";
	width?: string;
};

type InputValueProps = {
	title: string;
	value: string;
	label: string | null;
	type: AttributeTypes;
};

export default function AttributesService() {
	const rowsTitleTable: TableHeaderType[] = [
		{
			title: "",
			position: "left",
			width: "50px",
		},
		{
			title: i18n.t(`attributes.Name`).toUpperCase(),
			position: "left",
			width: "200px",
		},
		{
			title: i18n.t(`attributes.Value`).toUpperCase(),
			position: "left",
			width: "700px",
		},
		{
			title: i18n.t(`attributes.Actions`).toUpperCase(),
			position: "center",
			width: "80px",
		},
	];

	const [attributes, setAttributes] = useState<AttributesDto[]>([]);
	const [oldAttributes, setOldAttributes] = useState<AttributesDto[]>([]);
	const [updateInputValue, setUpdateInputValue] = useState<AttributesDto>({
		title: "",
		value: "",
		label: null,
		order: "",
		type: AttributeTypes.Text,
	});
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);
	const [currentIndex, setCurrentIndex] = useState<number | null>(null);
	const [indexToBeDeleted, setIndexToBeDeleted] = useState<number>(0);
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
	const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);
	const [openModalIndex, setOpenModalIndex] = useState<number | null>(null);
	const [currentColor, setCurrentColor] = useState<string>("");
	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const validationFuncMapper: Record<string, Function> = {
		title: InputValidator.isRequired,
		value: InputValidator.isRequired,
	};

	const [attributeToDelete, setAttributeToDelete] = useState<AttributesDto>({
		title: "",
		value: "",
		label: null,
		order: "",
		type: AttributeTypes.Text,
	});
	const params = useParams();

	const {
		unsavedChanges,
		disableUnsavedChanges,
		promptMessage,
		enableUnsavedChanges,
	} = useUnsavedFormSafety();

	useEffect(() => {
		getAttributes();
	}, []);

	useEffect(() => {
		if (attributes.length > oldAttributes.length) return enableUnsavedChanges();

		disableUnsavedChanges();
	}, [attributes, oldAttributes]);

	const getAttributes = async (): Promise<void> => {
		try {
			const data: AttributesDto[] = await catalogManagementApi.getAttributes(
				params.skuId
			);
			setAttributes(data);
			setOldAttributes(data);
			setIsLoading(false);
			setIsLoadingButton(false);
		} catch (error) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			setIsLoading(false);
		}
	};

	const updateAttributeValue = (inputValues: InputValueProps): void => {
		enableUnsavedChanges();

		const validateAttributeUpdate = validateInput(
			updateInputValue,
			validationFuncMapper
		);

		if (!validateAttributeUpdate.isValid) return;

		const updateAttribute = attributes.map((attribute, index) =>
			index === currentIndex
				? handleAttributesType(attribute, inputValues)
				: attribute
		);

		setAttributes(updateAttribute);

		setCurrentIndex(null);
	};

	const cancelAttributeValue = (): void => {
		setAttributes([...attributes]);
		setCurrentIndex(null);
	};

	const handleAttributesType = (
		attribute: AttributesDto,
		inputValues: InputValueProps
	): AttributesDto => {
		if (AttributeTypes.Color === attribute.type) {
			return {
				...attribute,
				title: inputValues.title,
				value: currentColor,
				label: inputValues.value,
			};
		}
		return {
			...attribute,
			title: inputValues.title,
			value: inputValues.value,
		};
	};

	const updateChromePicker = (inputValue: string): void => {
		setCurrentColor(inputValue);
	};

	const handleDragDrop = (result: DropResult): void => {
		enableUnsavedChanges();

		if (!result.destination) {
			return;
		}

		const reorderedAttributes = Array.from(attributes);

		const [movedAttribute] = reorderedAttributes.splice(result.source.index, 1);

		reorderedAttributes.splice(result.destination.index, 0, movedAttribute);

		const updatedAttributes = reorderedAttributes.map((attribute, index) => ({
			...attribute,
			order: index.toString(),
		}));

		setAttributes(updatedAttributes);
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
		let { name, value } = event.currentTarget;

		if (validationFuncMapper[name] !== undefined) {
			const errMessages: string[] = validationFuncMapper[name](value);
			setFormErrors((prevState) => ({ ...prevState, [name]: errMessages }));
		}

		setUpdateInputValue((prevState: AttributesDto) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleEditChange = (index: number): void => {
		setCurrentIndex((prevIndex) => (prevIndex === index ? null : index));

		const attributeById = attributes.find(
			(_, currentIndex) => currentIndex === index
		);

		if (!attributeById) return;

		const dataValue = {
			title: attributeById.title,
			value:
				AttributeTypes.Color === attributeById.type
					? (attributeById.label as string)
					: attributeById.value,
			label: AttributeTypes.Color === attributeById.type ? currentColor : null,
			type: attributeById.type,
			order: attributeById.order,
		};

		setCurrentColor(attributeById.value);

		setUpdateInputValue(dataValue);
	};

	const handleDeleteAttributes = async (
		currentIndex: number,
		attributeId?: string
	): Promise<void> => {
		setIsLoadingButton(true);

		if (!isValidString(attributeId)) {
			setAttributes(attributes.filter((_, index) => index !== currentIndex));

			NewToastComponent({
				status: "success",
				title: i18n.t(`attributes.SuccessfullyDeleted`),
			});

			setIsLoadingButton(false);

			setIsOpenModal(false);

			return;
		}

		const success = await catalogManagementApi.deleteAttributes(
			params.skuId,
			attributeId as string
		);

		if (!success) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});

			setIsLoadingButton(false);

			return;
		}

		NewToastComponent({
			status: "success",
			title: i18n.t(`attributes.SuccessfullyDeleted`),
		});

		setAttributes(attributes.filter((_, index) => index !== currentIndex));

		setIsLoadingButton(false);

		setIsOpenModal(false);
	};

	const handleUpsertAttributes = async (): Promise<void> => {
		setIsLoadingButton(true);

		const reorderedAttributes = attributes.map((attribute, index) => ({
			...attribute,
			order: index.toString(),
		}));

		const existingTitle: boolean = attributeAlreadyExists(reorderedAttributes);

		if (existingTitle) {
			NewToastComponent({
				status: "error",
				title: i18n.t(`attributes.ErrorSavingAttributes`),
			});

			setIsLoading(false);

			return;
		}

		disableUnsavedChanges();

		const success = await catalogManagementApi.upsertAttributes(
			params.skuId,
			reorderedAttributes
		);

		if (!success) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
		}

		!existingTitle &&
			NewToastComponent({
				status: "success",
				title: i18n.t(`attributes.SuccessfullySaved`),
			});

		getAttributes();
	};

	const attributeAlreadyExists = (data: AttributesDto[]): boolean => {
		const existingTitle = new Set();

		for (const attribute of data) {
			const title = attribute.title;

			if (existingTitle.has(title)) {
				return true;
			}

			existingTitle.add(title);
		}
		return false;
	};

	return {
		// Props
		isLoading,
		isLoadingButton,
		updateInputValue,
		attributes,
		setAttributes,
		rowsTitleTable,
		currentIndex,
		indexToBeDeleted,
		setIndexToBeDeleted,
		formErrors,
		isOpenModal,
		setIsOpenModal,
		isOpenCreateModal,
		setIsOpenCreateModal,
		openModalIndex,
		setOpenModalIndex,
		attributeToDelete,
		setAttributeToDelete,
		unsavedChanges,
		promptMessage,
		// Functions
		handleDeleteAttributes,
		handleUpsertAttributes,
		updateAttributeValue,
		updateChromePicker,
		handleDragDrop,
		handleInputChange,
		handleEditChange,
		cancelAttributeValue,
	};
}
