import React from "react";
import CustomSkeleton from "../../../../MUICustomComponents/CustomSkeleton";

export default function RetailerLoadingCard() {
	return (
		<div className="card" style={{ padding: "24px" }}>
			<CustomSkeleton quantity={5} height={40} />
		</div>
	);
}
