import React, { useState } from "react";
import { useFinancial } from "../../providers/FinancialContext";
import MultDatePiker from "../../../../components/MultDatePiker";
import Select from "react-select";
import "./DateFilter.scss";
import { i18n } from "../../../../translate/i18n";
import ExportFinancial from "../ExportFinancial";
import { getFilterDate } from "../../../../infrastructure/utils/DateUtils.jsx";
import { DateTypes } from "../DataTypes/DateTypes.js";

export default function DateFilter({
	screen,
	setPage,
	tabData,
	setTabData,
	exportData,
}) {
	const { loading } = useFinancial();

	const customSelectStyles = {
		indicatorSeparator: (provided) => ({
			...provided,
			display: "none",
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			color: "#747E8B",
			paddingLeft: 5,
			svg: {
				width: 24,
			},
		}),
		option: (provided, state) => ({
			...provided,
			color: "#747E8B",
			backgroundColor: state.isFocused ? "#e9e9e9" : "#ffffff",
		}),
		group: (provided) => ({
			...provided,
			padding: 0,
			marginTop: "8px",
			borderTop: "1px solid #E0E3E7",
		}),
		groupHeading: (provided) => ({
			...provided,
			margin: 0,
		}),
		singleValue: (provided) => ({
			...provided,
			"& .react-select-container-group": {
				paddingLeft: "0px !important",
			},
		}),
	};

	const changeFilter = (newFilter) => {
		setTabData({ ...tabData, statusFilter: newFilter });
		setPage(1);
	};

	const changeTimeStatusFilter = (newFilter) => {
		setPage(1);
		let [newStartDate, newEndDate] = getFilterDate(0);
		newStartDate = newStartDate.getTime();
		newEndDate = newEndDate.getTime();
		setTabData({
			...tabData,
			timeStatusFilter: newFilter,
			startDate: newStartDate,
			endDate: newEndDate,
			statusFilter: tabData?.defaultFilterOption,
			rangeDate: DateTypes.Today.name,
		});
	};

	const setTabDataDate = (dateType) => {
		setPage(1);
		let [newStartDate, newEndDate] = getFilterDate(dateType.date);
		newStartDate = newStartDate.getTime();
		newEndDate = newEndDate.getTime();
		setTabData({
			...tabData,
			startDate: newStartDate,
			endDate: newEndDate,
			rangeDate: dateType.name,
		});
	};

	const onChangeDates = async (dates) => {
		setPage(1);
		let newEndDate;
		if (dates[1] === "") newEndDate = new Date(dates[0]).getTime();
		else newEndDate = new Date(dates[1]).getTime();
		setTabData({
			...tabData,
			startDate: new Date(dates[0]).getTime(),
			endDate: newEndDate,
			rangeDate: 4,
		});
	};

	const clearAllFilter = () => {
		setPage(1);
		let [newStartDate, newEndDate] = getFilterDate(0);
		newStartDate = newStartDate.getTime();
		newEndDate = newEndDate.getTime();
		setTabData({
			...tabData,
			startDate: newStartDate,
			endDate: newEndDate,
			statusFilter: tabData?.defaultFilterOption,
			rangeDate: DateTypes.Today.name,
		});
	};

	const dateButton = (dateType) => {
		return (
			<button
				disabled={loading ? true : false}
				className={
					tabData?.rangeDate === dateType.name ? "active-data-btn" : ""
				}
				onClick={() => setTabDataDate(dateType)}
			>
				{tabData?.rangeDate === dateType.name && loading === true ? (
					<>
						<i className="fas fa-spinner fa-spin"></i>
						<span>&nbsp;&nbsp;Processando...</span>
					</>
				) : (
					i18n.t(`financial.FilterPeriod.${dateType.name}`)
				)}
			</button>
		);
	};

	const timeStatusButton = (buttonStatus) => (
		<button
			disabled={loading ? true : false}
			className={`${
				tabData?.timeStatusFilter === buttonStatus ? "active-data-btn " : ""
			}flex-fill`}
			onClick={() => changeTimeStatusFilter(buttonStatus)}
		>
			{tabData?.timeStatusFilter === buttonStatus && loading === true ? (
				<>
					<i className="fas fa-spinner fa-spin"></i>
					<span>&nbsp;&nbsp;Processando...</span>
				</>
			) : (
				i18n.t(`financial.PayableType_${buttonStatus}`)
			)}
		</button>
	);

	return (
		<div className="date-filter-component">
			<div className="card-header d-flex flex-column flex-lg-row p-4">
				{tabData?.timeStatusFilter && (
					<div className="date-filter mr-3" style={{ width: "240px" }}>
						<label className="date-filter-title mb-2">
							{i18n.t("financial.PayableType")}
						</label>
						<div className="group-btn">
							{timeStatusButton("paid")}
							{timeStatusButton("waiting_funds")}
						</div>
					</div>
				)}
				<div className="date-filter mr-3" style={{ width: "540px" }}>
					<label className="date-filter-title mb-2">
						{i18n.t(`financial.FilterPeriod.${screen}`)}
					</label>
					<div className="group-btn">
						{dateButton(DateTypes.Today)}
						{tabData?.timeStatusFilter === "waiting_funds" ? (
							<>
								{dateButton(DateTypes.NextSevenDays)}
								{dateButton(DateTypes.NextThirtyDays)}
							</>
						) : (
							<>
								{dateButton(DateTypes.LastSevenDays)}
								{dateButton(DateTypes.LastThirtyDays)}
							</>
						)}
						<MultDatePiker
							useDatePiker={tabData?.rangeDate !== 4 ? true : false}
							date={(e) => onChangeDates(e)}
							className={tabData?.rangeDate === 4 ? "active-data-btn " : ""}
							loading={loading}
							type={tabData?.rangeDate}
							since={tabData?.rangeDate === 4 ? tabData?.startDate : null}
							until={tabData?.rangeDate === 4 ? tabData?.endDate : null}
						/>
					</div>
				</div>

				<div className="flex-fill">
					<label className="mb-2">
						<strong>Status</strong>
					</label>
					<Select
						styles={customSelectStyles}
						className="react-select-container"
						value={
							tabData?.filterOptions[
								tabData?.filterOptions.findIndex(
									(status) => status.value === tabData?.statusFilter
								)
							]
						}
						options={tabData?.filterOptions}
						onChange={(event) => !loading && changeFilter(event.value)}
						isSearchable={false}
					/>
				</div>
			</div>
			<div className="d-flex flex-column flex-lg-row justify-content-start px-4 pb-3">
				{(tabData?.statusFilter !== tabData?.defaultFilterOption ||
					tabData?.rangeDate !== DateTypes.Today.name) && (
					<button
						className="btn btn-grey-65 btn-icon mr-2"
						style={{ height: "40px" }}
						onClick={clearAllFilter}
					>
						<i className="material-icons">delete_outline</i> Limpar filtros e
						datas
					</button>
				)}
				{exportData[0]?.data?.length > 0 && (
					<ExportFinancial exportName={screen} exportData={exportData} />
				)}
			</div>
		</div>
	);
}
