import React, { useState, useEffect } from "react";
import { useFinancial } from "../providers/FinancialContext";
import SelectStore from "./SelectStore/SelectStore";
import { RolesAccess } from "../../../domain/enums/RolesAccess";

export default function FinancialHeader(props) {
	const { loading, available, waitingFunds, getBalance, bankAccount } =
		useFinancial();
	const [balanceValue, setBalanceValue] = useState(0);
	const [waitingFundsValue, setWaitingFundsValue] = useState(0);
	const [showBalanceAvailable, setShowBalanceAvailable] = useState(false);
	const [showBalanceWaitingFunds, setShowBalanceWaitingFunds] = useState(false);

	useEffect(() => {
		!!available
			? setBalanceValue(available.toString().replace(/(\d)(\d{2})$/, "$1.$2"))
			: setBalanceValue(0);
		!!waitingFunds
			? setWaitingFundsValue(
					waitingFunds.toString().replace(/(\d)(\d{2})$/, "$1.$2")
			  )
			: setWaitingFundsValue(0);
	}, [available, waitingFunds]);

	const changeShowBalanceAvailable = (balanceAvailable) => {
		if (showBalanceAvailable === balanceAvailable) {
			setShowBalanceAvailable(false);
		} else {
			setShowBalanceAvailable(true);
		}
	};

	const changeShowBalanceWaitingFunds = (balanceWaitingFunds) => {
		if (showBalanceWaitingFunds === balanceWaitingFunds) {
			setShowBalanceWaitingFunds(false);
		} else {
			setShowBalanceWaitingFunds(true);
		}
	};

	const validateForRetailer = (role) => {
		const isPagarMeForRetailer = JSON.parse(
			localStorage.getItem("usePagarMeDashboardForRetailer")
		);

		const isRetailer = role === RolesAccess.Retailer;

		return isRetailer && isPagarMeForRetailer;
	};

	return (
		<div className="card-header py-0">
			<div className="financial-header d-flex flex-column flex-lg-row justify-content-between">
				<div className="financial-title">
					<h4 className="card-title mb-3">Financeiro</h4>
					<p className="text-balance-icons">
						<strong>
							Apresentando as movimentações financeiras de:
							{!validateForRetailer(localStorage.getItem("role")) && (
								<span className="ml-1" style={{ color: "#7A9FEB" }}>
									{bankAccount.legalName}
								</span>
							)}
						</strong>
					</p>

					{validateForRetailer(localStorage.getItem("role")) ? (
						<SelectStore />
					) : (
						<p>
							Acompanhe movimentações financeiras, transações, saldo,
							recebíveis, solicitações diversas e demais informações de conta
						</p>
					)}
				</div>

				<div className="financial-balance d-flex flex-column justify-content-end flex-lg-row justify-content-start">
					<div className="">
						<div className="update-balance">
							<label className="pb-1">Valores atuais</label>
							<button
								type="button"
								className="btn-icons-financial"
								onClick={getBalance}
							>
								{loading ? (
									<i className="fas fa-redo-alt fa-spin"></i>
								) : (
									<i className="fas fa-redo-alt"></i>
								)}
							</button>
						</div>

						<div className="d-flex">
							<div className="card-balance mr-2">
								<div className="card-balance-icons bg-balence-success">
									<i className="material-icons-outlined">
										account_balance_wallet
									</i>
								</div>
								<div className="card-balance-data">
									<div className="">
										<span>Saldo Disponível</span>
										<button
											className="btn-icons-financial"
											type="button"
											onClick={(e) => changeShowBalanceAvailable(true)}
										>
											<i
												className="material-icons-outlined"
												style={{ fontSize: "20px" }}
											>
												{!loading && showBalanceAvailable
													? "visibility_off"
													: "visibility"}
											</i>
										</button>
									</div>
									{!loading && showBalanceAvailable ? (
										<label className="text-balence-success">
											{parseFloat(balanceValue).toLocaleString("pt-br", {
												style: "currency",
												currency: "BRL",
											})}
										</label>
									) : (
										<div>
											<label className="bg-visibilityOff"></label>
											<label className="bg-visibilityOff"></label>
											<label className="bg-visibilityOff"></label>
											<label className="bg-visibilityOff"></label>
											<label className="bg-visibilityOff"></label>
											<label className="bg-visibilityOff"></label>
										</div>
									)}
								</div>
							</div>
							<div className="card-balance">
								<div className="card-balance-icons bg-balance-info">
									<i className="material-icons-outlined">play_for_work</i>
								</div>
								<div className="card-balance-data">
									<div className="">
										<span>Saldo a Receber</span>
										<button
											className="btn-icons-financial"
											onClick={() => changeShowBalanceWaitingFunds(true)}
										>
											<i
												className="material-icons-outlined"
												style={{ fontSize: "20px" }}
											>
												{!loading && showBalanceWaitingFunds
													? "visibility_off"
													: "visibility"}
											</i>
										</button>
									</div>
									{!loading && showBalanceWaitingFunds ? (
										<label className="text-balance-info">
											{parseFloat(waitingFundsValue).toLocaleString("pt-br", {
												style: "currency",
												currency: "BRL",
											})}
										</label>
									) : (
										<div>
											<label className="bg-visibilityOff"></label>
											<label className="bg-visibilityOff"></label>
											<label className="bg-visibilityOff"></label>
											<label className="bg-visibilityOff"></label>
											<label className="bg-visibilityOff"></label>
											<label className="bg-visibilityOff"></label>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
