import React, { useEffect } from "react";
import LoginContext, { useLogin } from "./providers/LoginContext";
import RoleList from "./components/RoleList";
import Initial from "./components/Initial";
import { i18n } from "../../translate/i18n";

import "./Login.scss";
export default function IndexLogin(props) {
	return (
		<LoginContext>
			<Login prevRoutes={props.match.params.route} />
		</LoginContext>
	);
}
function Login({ prevRoutes }) {
	const { roles, loading, loadingLogin, setPrevRoute, backgroundImage } =
		useLogin();
	useEffect(() => {
		if (prevRoutes != null || prevRoutes != undefined) {
			return setPrevRoute(atob(prevRoutes));
		}
	}, []);

	return (
		<div className="container-login" style={backgroundImage}>
			<section className="bg-login">
				<div className="new-login">
					<div className="d-flex flex-column">
						<h2>{i18n.t("login.General.Welcome")}</h2>
						<img
							className="d-flex align-self-start mt-2"
							src="/images/neomode-logo.svg"
							height="25"
						/>
					</div>
					<div>
						{!loadingLogin && !loading && roles.length > 1 && <RoleList />}
						{(loadingLogin || roles.length === 0) && <Initial />}
					</div>
				</div>
			</section>
		</div>
	);
}
