import React from "react";
export default function ArrowStraight() {
	return (
		<div className="d-flex justify-content-center" style={{ height: "40px" }}>
			<svg
				style={{
					position: "absolute",
					zIndex: "0",
					left: "88px",
					top: "164px",
					overflow: "visible",
				}}
				width="390"
				height="72"
				viewBox="0 0 400 72"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM473.354 68.3536C473.549 68.1583 473.549 67.8417 473.354 67.6464L470.172 64.4645C469.976 64.2692 469.66 64.2692 469.464 64.4645C469.269 64.6597 469.269 64.9763 469.464 65.1716L472.293 68L469.464 70.8284C469.269 71.0237 469.269 71.3403 469.464 71.5355C469.66 71.7308 469.976 71.7308 470.172 71.5355L473.354 68.3536ZM2.5 3V64H3.5V3H2.5ZM7 68.5H473V67.5H7V68.5ZM2.5 64C2.5 66.4853 4.51472 68.5 7 68.5V67.5C5.067 67.5 3.5 65.933 3.5 64H2.5Z"
					fill="#939698"
				/>
			</svg>
		</div>
	);
}
