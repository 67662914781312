import { SelectChangeEvent } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

export const handleGenericChange = <T>(
	setState: Dispatch<SetStateAction<T>>,
	event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>,
	checked?: boolean
): void => {
	const { name, value } = event.target;
	if (checked !== undefined && typeof checked === "boolean") {
		setState((prevState) => ({ ...prevState, [name]: checked }));
		return;
	}
	setState((prevState) => ({ ...prevState, [name]: value }));
};
