import React from "react";

import Loading from "./Loading";

// import { Container } from './styles';

function Button(props) {
	return (
		<>
			<button
				className={`btn btn-new-width ${props.className}`}
				type={!props.type ? "button" : props.type}
				onClick={(e) => props.onClick && props.onClick(e)}
				id={!props.id ? "" : props.id}
				disabled={!props?.disable ? false : true}
				data-toggle={!props.dataToggle ? "" : props.dataToggle}
				data-target={!props.dataTarget ? "" : props.dataTarget}
				data-dismiss={!props.dataDismiss ? "" : props.dataDismiss}
			>
				{props.loading ? (
					<>
						<div className="lds-ring">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</>
				) : !props.children ? (
					"Botão  "
				) : (
					props.children
				)}
			</button>
		</>
	);
}

export default Button;
