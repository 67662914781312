import { paletteObject } from "../foundations/palette";

export const MuiMenuItem = {
	styleOverrides: {
		root: {
			"&.Mui-selected": {
				backgroundColor: paletteObject.grey10.main,
				color: paletteObject.blueish80.main,
			},
			'&[aria-selected="true"]': {
				backgroundColor: paletteObject.blue10.main,
				color: paletteObject.blueish80.main,
			},
			'&[aria-selected="true"].Mui-selected': {
				color: paletteObject.blueish80.main,
				backgroundColor: paletteObject.blue10.main,
			},
		},
	},
};
