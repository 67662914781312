import { paletteObject } from "../foundations/palette";

export const MuiInputLabel = {
	styleOverrides: {
		root: {
			fontSize: 14,
			backgroundColor: paletteObject.white.main,
			paddingRight: 5,
			transform: "translate(14px, 8px) scale(1)",
			transition: "transform 200ms ease-in-out",
			"&.MuiInputLabel-shrink": {
				transform: "translate(15px, -10px) scale(1)",
			},
		},
	},
};
