import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import SingleDateModal from "./SingleDateModal";

export default function SingleDatePicker({
	onChange,
	upateDate,
	error,
	disabled,
	isModal,
}) {
	const [singleDate, setSingleDate] = useState("");
	const [date, setDate] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {
		if (upateDate) {
			setDate(new Date(upateDate).toLocaleDateString());
			let dateValue = new Date(upateDate).toISOString();
			onChange(dateValue);
		}
	}, [singleDate, upateDate]);

	const handleDateInput = (dateInput) => {
		dateInput.preventDefault();
		let date = dateInput.target.value
			.replace(/\D+/g, "")
			.replace(
				/(\d{2})(\d{2})(\d{4})/,
				function (match, groupOne, groupTwo, groupThree) {
					var day = String(groupOne);
					var month = String(groupTwo);
					var year = String(groupThree);
					if (
						groupTwo === "01" ||
						groupTwo === "03" ||
						groupTwo === "05" ||
						groupTwo === "07" ||
						groupTwo === "08" ||
						groupTwo === "10" ||
						groupTwo === "12"
					) {
						if (groupOne >= 1 && groupOne <= 31) {
							if (groupTwo >= 1 && groupTwo <= 12) {
								if (groupThree >= 1990 && groupThree <= 2999) {
									setErrorMessage("");
									var error = false;
								} else {
									setErrorMessage(
										`Data inválida ${groupOne}/${groupTwo}/${groupThree}, ano referente inválido`
									);
									var error = true;
								}
							} else {
								setErrorMessage(
									`Data inválida ${groupOne}/${groupTwo}/${groupThree}`
								);
								var error = true;
							}
						} else {
							setErrorMessage(
								`Data inválida ${groupOne}/${groupTwo}/${groupThree}, dia referente inválido`
							);
							var error = true;
						}
					} else if (
						groupTwo === "04" ||
						groupTwo === "06" ||
						groupTwo === "09" ||
						groupTwo === "11"
					) {
						if (groupOne >= 1 && groupOne <= 30) {
							if (groupTwo >= 1 && groupTwo <= 12) {
								if (groupThree >= 1990 && groupThree <= 2999) {
									setErrorMessage("");
									var error = false;
								} else {
									setErrorMessage(
										`Data inválida ${groupOne}/${groupTwo}/${groupThree}`
									);
									var error = true;
								}
							} else {
								setErrorMessage(
									`Data inválida ${groupOne}/${groupTwo}/${groupThree}`
								);
								var error = true;
							}
						} else {
							setErrorMessage(
								`Data inválida ${groupOne}/${groupTwo}/${groupThree}`
							);
							var error = true;
						}
					} else if (groupTwo === "02") {
						if (groupOne >= 1 && groupOne <= 28) {
							if (groupTwo >= 1 && groupTwo <= 12) {
								if (groupThree >= 1990 && groupThree <= 2999) {
									setErrorMessage("");
									var error = false;
								} else {
									setErrorMessage(
										`Data inválida ${groupOne}/${groupTwo}/${groupThree}`
									);
									var error = true;
								}
							} else {
								setErrorMessage(
									`Data inválida ${groupOne}/${groupTwo}/${groupThree}`
								);
								var error = true;
							}
						} else if (
							groupThree % 4 == 0 &&
							(groupThree % 100 != 0 || groupThree % 400 == 0)
						) {
							setErrorMessage("");
							var error = false;
						} else {
							setErrorMessage(
								`Data inválida ${groupOne}/${groupTwo}/${groupThree}, dia referente a um ano bisexto`
							);
							var error = true;
						}
					} else {
						setErrorMessage(
							`Data inválida ${groupOne}/${groupTwo}/${groupThree}, mês referente inválido`
						);
						var error = true;
					}

					if (!error) {
						let newDate = new Date();
						let dateInput = newDate.setTime(
							new Date(`${year}-${month}-${day}`)
						);
						onChange(new Date(dateInput).toISOString());
					}
					return `${day}/${month}/${year}`;
				}
			);
		setDate(date);
	};

	const handleDateModal = (dateModal) => {
		setDate("");
		let newDate = new Date(
			new Date(
				new Date(dateModal).getFullYear(),
				new Date(dateModal).getMonth(),
				new Date(dateModal).getDate(),
				+3,
				0,
				0,
				0
			).toISOString()
		);
		setSingleDate(dateModal);
		onChange(newDate.toISOString());
	};

	return (
		<div className={`dropdown dropup w-100`}>
			<InputMask
				mask="99/99/9999"
				disabled={disabled ? false : true}
				className={`${error ? "neo-input-invalid" : "neo-input-valid"}`}
				value={!date ? new Date(singleDate).toLocaleDateString() : date}
				placeholder="dd/mm/aaaa"
				onChange={handleDateInput}
			/>
			{error && (
				<div className="neo-icons-required">
					<button type="button">
						<i className="material-icons">error_outline</i>
					</button>
				</div>
			)}
			{disabled ? (
				<div className="neo-icons-date">
					<button
						type="button"
						data-toggle="modal"
						data-target="#singleDateModal"
					>
						<i className="material-icons">calendar_today</i>
					</button>
				</div>
			) : (
				<div className="neo-icons-date">
					<button type="button">
						<i className="material-icons">calendar_today</i>
					</button>
				</div>
			)}
			{errorMessage && (
				<small className="text-danger">
					<em>{errorMessage}</em>
				</small>
			)}
			<SingleDateModal handleDateModal={handleDateModal} isModal={isModal} />
		</div>
	);
}
