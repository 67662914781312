import React from "react";
import { Box, Button, Modal, Stack } from "@mui/material";
import { i18n } from "../../../../../translate/i18n";
import CustomTypography from "../../../../../MUICustomComponents/CustomTypography";
import { AttributesDto } from "../../../../../domain/dtos/products/attributes/AttributesDto";

import * as Styled from "../Attributes/AttributesStyles";
import CustomIcon from "../../../../../MUICustomComponents/CustomIcon";
import { RotateRight } from "@mui/icons-material";

type ModalProps = {
	indexToBeDeleted: number;
	isOpenModal: boolean;
	setIsOpenModal: (value: boolean) => void;
	handleDeleteAttributes: (currentIndex: number, data: string) => void;
	attribute: AttributesDto;
	isLoadingButton: boolean;
};

export default function DeleteAttribute({
	indexToBeDeleted,
	isOpenModal,
	setIsOpenModal,
	handleDeleteAttributes,
	attribute,
	isLoadingButton,
}: ModalProps) {
	return (
		<Modal
			open={isOpenModal}
			onClose={() => setIsOpenModal(false)}
			aria-labelledby="delete-attribute-title"
			aria-describedby="delete-attribute-description"
		>
			<Styled.ModalBody>
				<CustomTypography variant="secondaryTitle">
					{i18n.t(`attributes.ConfirmDelete`).toString()}
				</CustomTypography>
				<Box mt={0.5}>
					<CustomTypography variant="default">
						{i18n.t(`attributes.ActionCannotBeReversed`).toString()}
					</CustomTypography>
				</Box>
				<Box mt={2}>
					<CustomTypography variant="default">
						<strong>{i18n.t(`attributes.Name`).toString()}:&nbsp;</strong>
						{attribute.title}
					</CustomTypography>
				</Box>
				<Stack direction="row" justifyContent="center" mt={4}>
					<Box mr={"10px"}>
						<Button
							variant="contained"
							color="light"
							onClick={() => setIsOpenModal(false)}
						>
							{i18n.t(`buttons.General.BtnCancel`).toString()}
						</Button>
					</Box>
					<Button
						variant="outlined"
						color="danger"
						onClick={() =>
							handleDeleteAttributes(indexToBeDeleted, attribute.id as string)
						}
						disabled={isLoadingButton}
					>
						{isLoadingButton ? (
							<CustomIcon
								icon={<RotateRight />}
								variant="default"
								isRotating={isLoadingButton}
							/>
						) : (
							i18n.t(`buttons.General.BtnDelete`).toString()
						)}
					</Button>
				</Stack>
			</Styled.ModalBody>
		</Modal>
	);
}
