import React, { Dispatch, SetStateAction } from "react";
import CustomAutocomplete from "../../MUICustomComponents/CustomAutocomplete";
import { i18n } from "../../translate/i18n";
import DealerAutocompleteService from "./DealerAutocompleteService";
import { DealerDto } from "../../domain/dtos/dealers/DealerDto";

type Props = {
	dealerId: string | null;
	currentDealer: DealerDto | null;
	setCurrentDealer: Dispatch<SetStateAction<DealerDto | null>>;
	handleAutocompleteChange: (dealer: DealerDto | null) => void;
	id: string;
	error?: boolean;
	helperText?: string[];
	emptyDealersCallback?: () => void;
	triggerLoadingDealers?: (isLoading: boolean) => void;
};

export default function DealerAutocomplete({
	dealerId,
	currentDealer,
	setCurrentDealer,
	handleAutocompleteChange,
	id,
	error = false,
	helperText = [],
	emptyDealersCallback = () => {},
	triggerLoadingDealers = () => {},
}: Props) {
	const {
		dealerList,
		currentDealerInput,
		setCurrentDealerInput,
		// Functions
		filterOptions,
		getOptionLabel,
		isOptionEqualToValue,
	} = DealerAutocompleteService(
		dealerId,
		setCurrentDealer,
		emptyDealersCallback,
		triggerLoadingDealers
	);

	return (
		<CustomAutocomplete
			id={id}
			renderInputLabel={i18n.t("components.Autocomplete.Picker")}
			placeholder={i18n.t("components.Autocomplete.Picker")}
			options={dealerList}
			value={currentDealer}
			onSelectChange={handleAutocompleteChange}
			inputValue={currentDealerInput}
			onInputChange={setCurrentDealerInput}
			filterOptions={filterOptions}
			getOptionLabel={getOptionLabel}
			isOptionEqualToValue={isOptionEqualToValue}
			error={error}
			helperText={helperText}
		/>
	);
}
