import React from "react";

export default function ArrowDownRightSm({ left }) {
	return (
		<svg
			style={{
				position: "absolute",
				zIndex: "0",
				left: left,
				bottom: "58px",
				overflow: "visible",
			}}
			width="288"
			height="170"
			viewBox="0 0 288 170"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM287.354 166.354C287.549 166.158 287.549 165.842 287.354 165.646L284.172 162.464C283.976 162.269 283.66 162.269 283.464 162.464C283.269 162.66 283.269 162.976 283.464 163.172L286.293 166L283.464 168.828C283.269 169.024 283.269 169.34 283.464 169.536C283.66 169.731 283.976 169.731 284.172 169.536L287.354 166.354ZM2.5 3V162H3.5V3L2.5 3ZM7 166.5H287V165.5H7V166.5ZM2.5 162C2.5 164.485 4.51472 166.5 7 166.5V165.5C5.067 165.5 3.5 163.933 3.5 162H2.5Z"
				fill="#939698"
			/>
		</svg>
	);
}
