import React, { useState, useEffect } from "react";
import moment from "moment";
import ContentLoader from "react-content-loader";
import { useOrders } from "../../providers/OrdersContext";

export default function OrderInfo() {
	const { loading, order, freight, role, actionLogs } = useOrders();
	const [date, setDate] = useState([]);

	useEffect(() => {
		const lastDate = actionLogs[actionLogs.length - 1];
		setDate(lastDate);
	}, [actionLogs]);

	const skeleton = () => {
		const skele = [0, 1, 2, 4, 5, 6];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={400}
				height={20}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="5" y="25" rx="3" ry="3" width="400" height="100" />
				<rect x="410" y="26" rx="3" ry="3" width="400" height="100" />
			</ContentLoader>
		));
	};

	return (
		<div className="card box-only">
			{loading ? (
				skeleton()
			) : (
				<>
					<div className="box-title">
						<h2 className="card-title">PEDIDO</h2>
						<label>nº {order?.friendlyCode}</label>
					</div>
					{!!order?.createDate && (
						<span className="card-subtitle mb-2">
							<strong>Data de criação: </strong>
							{moment(order?.createDate).format("DD/MM/YYYY HH:mm")}
						</span>
					)}
					{!!date?.lastUpdate && (
						<span className="card-subtitle mb-2">
							<strong>Última atualização: </strong>
							{moment(date?.lastUpdate).format("DD/MM/YYYY HH:mm")}
						</span>
					)}
					{order?.sellerOrderId && (
						<span className="card-subtitle mb-2">
							<strong>Código do ERP/PDV: </strong>
							{order?.sellerOrderId}
						</span>
					)}
					{order?.externalId && (
						<span className="card-subtitle mb-2">
							<strong>Código do Canal de Venda: </strong>
							{order?.externalId}
						</span>
					)}
					{role === 8
						? !!freight?.description && (
								<span className="card-subtitle mb-2">
									<strong>Tipo de envio: </strong>
									{freight?.description}
								</span>
						  )
						: order?.isDelivery !== null && (
								<span className="card-subtitle mb-2">
									<strong>Modalidade: </strong>
									{order?.isDelivery === false
										? "Retire na Loja"
										: "Receba em Casa"}
								</span>
						  )}
					{!!order?.channel && (
						<span className="card-subtitle mb-2">
							<strong>Canal de origem: </strong>
							{order?.channel}
						</span>
					)}
					{!!order?.dealerCode && (
						<span className="card-subtitle">
							<strong>Código do vendedor: </strong>
							{order?.dealerCode}
						</span>
					)}
				</>
			)}
		</div>
	);
}
