import React from "react";
import ContentLoader from "react-content-loader";

export default function Skeleton({
	cardBackground = "white",
	hasBadge = false,
}) {
	const skeleton = () => {
		return (
			<ContentLoader
				speed={2}
				width={"100%"}
				height={160}
				backgroundColor={
					cardBackground === "black" ? "rgb(243, 243, 243, 0.3)" : "#f3f3f3"
				}
				foregroundColor={
					cardBackground === "black" ? "rgb(236, 235, 235, 0.5)" : "#ecebeb"
				}
				viewBox="0 0 100% 160"
			>
				{hasBadge && (
					<>
						<rect x="0" y="8" rx="5" ry="5" width="55%" height="22" />
						<rect x="70%" y="8" rx="5" ry="5" width="30%" height="22" />
					</>
				)}
				{!hasBadge && (
					<rect x="0" y="8" rx="5" ry="5" width="100%" height="22" />
				)}

				<rect x="0" y="48" rx="3" ry="3" width="100%" height="16" />
				<rect x="0" y="72" rx="3" ry="3" width="100%" height="16" />
				<rect x="0" y="96" rx="3" ry="3" width="100%" height="16" />
				<rect x="0" y="120" rx="3" ry="3" width="100%" height="16" />
				<rect x="0" y="144" rx="3" ry="3" width="100%" height="16" />
			</ContentLoader>
		);
	};
	return skeleton();
}
