import { useEffect, useState } from "react";
import { CommercialConditionDto } from "../../../../../domain/dtos/MarketingManagement/CommercialConditionDto";
import NewToastComponent from "../../../../../components/NewToastComponent";
import { i18n } from "../../../../../translate/i18n";
import { marketingManagementApi } from "../../../../../infrastructure/api/MarketingManagementApi";

export default function ConditionListService() {
	const [conditions, setConditions] = useState<CommercialConditionDto[]>([]);
	const [isDeletingRequest, setIsDeletingRequest] = useState<boolean>(false);
	const [conditionToDelete, setConditionToDelete] = useState<string | null>(
		null
	);
	const [isDeletionOpen, setIsDeletionOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		getConditions();
	}, []);

	const handleDeleteButton = (conditionId: string) => {
		setIsDeletionOpen(true);
		setConditionToDelete(conditionId);
	};

	const getConditions = async (): Promise<void> => {
		try {
			const data: CommercialConditionDto[] =
				await marketingManagementApi.getConditions();
			setConditions(data);
		} catch {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.ERR.REQUEST"),
			});
		}
		setIsLoading(false);
	};

	const deleteCondition = async (conditionId: string): Promise<void> => {
		setIsDeletingRequest(true);
		const success = await marketingManagementApi.deleteCondition(conditionId);
		setIsDeletionOpen(false);
		setConditionToDelete(null);
		setIsDeletingRequest(false);
		if (!success) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.ERR.REQUEST"),
			});
			return;
		}
		NewToastComponent({
			status: "success",
			title: i18n.t("paymentConfig.ConditionDeletedSuccessfully"),
		});
		await getConditions();
	};

	return {
		conditions,
		conditionToDelete,
		isDeletingRequest,
		isDeletionOpen,
		isLoading,
		setIsDeletionOpen,
		handleDeleteButton,
		deleteCondition,
	};
}
