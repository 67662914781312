import React, { Component, useEffect, useState } from 'react';

import { API } from '../../../API';
import Loading from '../../Loading';

import PreviewAppBar from '../blocks/PreviewAppBar';
import PreviewAppBottomNavigation from '../blocks/PreviewAppBottomNavigation';
import PreviewAppCarouselBanner from '../blocks/PreviewAppCarouselBanner';
import PreviewAppListBanners from '../blocks/PreviewAppListBanners';


export default function PreviewAppHome(props) {
  const [carouselBanners, setCarouselBanners] = useState([{ imageUrl: '' }])
  const [listBanners, setListBanners] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    API.get('/api/components/banners?type=1').then(response => {
      setListBanners(response.data)
    })
  }, [])

  useEffect(() => {
    //console.log('banners', listBanners)
  }, [listBanners])

  useEffect(() => {
    setLoading(true)
      if (props.type.screen === 'new-body') {
        API.get('api/components/banners?type=0')
          .then(response => {
            if (response.data.length > 0) {
              setCarouselBanners(response.data)
            } else {
              setCarouselBanners([{ imageUrl: '' }])
            }
            setListBanners(prevState => [{ imageUrl: props.recoveryStateLogo }, ...prevState.filter(state => state.id !== props.type.id && state.imageUrl && state.id)])
            setLoading(false)
          })
          .catch((error) => {
            console.log(error)
            setLoading(false)
          });
          
      } else {
        setCarouselBanners([{ imageUrl: props.recoveryStateLogo }])
        API.get('api/components/banners?type=1')
          .then(response => {
            if (response.data.length > 0) {
              setListBanners(response.data)
            } else {
              setListBanners([{ imageUrl: '' }, { imageUrl: '' }, { imageUrl: '' }, { imageUrl: '' }, { imageUrl: '' },])
            }
            setLoading(false)
          }).catch(e => {
            setLoading(false)
          })
      }

  }, [props.recoveryStateLogo])

  return (
    <>
      <PreviewAppBar logo={true} actionRight={'search'} />
      {loading && !props.recoveryStateLogo ? <Loading /> :
        <div className="app-content">
          <PreviewAppCarouselBanner banners={carouselBanners} />
          <PreviewAppListBanners banners={listBanners} />
        </div>
      }
      <PreviewAppBottomNavigation />
    </>
  )
};
