import React, { useCallback, useEffect, useState } from "react";
import SingleDatePicker from "../../../components/SingleDatePicker";
import MaskPrice from "../../../components/MaskPrice";
import { APIV1 } from "../../../API";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { useFinancial } from "../providers/FinancialContext";

export default function FinancialAnticipationsModal({ filterSelect }) {
	const { bankAccount, confirmAnticipation } = useFinancial();
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
	});
	const [bank, setBank] = useState([]);
	const [disabled, setDisabled] = useState(false);
	const [isValidated, setIsValidated] = useState(false);
	const [isAvailable, setIsAvailable] = useState(false);
	const [stepTwo, setStepTwo] = useState(false);
	const [maximumLimits, setMaximumLimits] = useState("");
	const [minimumLimits, setMinimumLimits] = useState("");
	const [requestedAmount, setRequestedAmount] = useState("");
	const [verification, setVerification] = useState({
		isReceivablesFar: false,
		anticipationDate: "",
	});

	useEffect(() => {
		setBank(new Array(bankAccount[0]));
	}, [bankAccount]);

	const onSubmitAnticipationLimit = async (data) => {
		var singleDate = new Date(data.singleDate);
		setVerification({
			...verification,
			anticipationDate: new Date(singleDate).toISOString(),
		});
		if (data.singleDate) {
			let timeframe = !verification.isReceivablesFar ? "start" : "end";
			let payment_date = new Date(singleDate).getTime();
			const data = {
				timeframe: timeframe,
				payment_date: payment_date,
			};

			const body = {
				domain: `https://api.pagar.me/`,
				resource: `1/recipients/${localStorage.getItem(
					"recipientId"
				)}/bulk_anticipations/limits`,
				subject: `pagarme`,
				method: `get`,
				body: JSON.stringify(data),
			};

			try {
				const response = await APIV1.post(
					`im/proxy/${localStorage.getItem("client_id").toLowerCase()}`,
					body
				);
				let miniValue = response.data.minimum.amount
					.toString()
					.replace(/(\d)(\d{2})$/, "$1.$2");
				setMinimumLimits(miniValue);
				let maxValue = response.data.maximum.amount
					.toString()
					.replace(/(\d)(\d{2})$/, "$1.$2");
				setMaximumLimits(maxValue);
				setIsValidated(true);
				setStepTwo(true);
			} catch (error) {
				setStepTwo(false);
				setIsValidated(false);
				console.log(error);
				if (error.response.data.errors.length > 0) {
					toast.error(`${error.response.data.errors[0].message}`);
				} else {
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				}
			}
		}
	};

	const handleRequestedAmount = (event) => {
		let max = maximumLimits.replace(/[\s,.]+/g, "").trim();
		let min = minimumLimits.replace(/[\s,.]+/g, "").trim();
		const value = event.currentTarget.value.replace(/[\s,.]+/g, "").trim();

		if (parseInt(value) >= parseInt(min) && parseInt(value) <= parseInt(max)) {
			setRequestedAmount(parseInt(value));
			setIsAvailable(true);
		} else {
			setIsAvailable(false);
		}
	};

	const onSubmitAnticipation = () => {
		if (isAvailable) {
			confirmAnticipation(verification, requestedAmount);
		}
	};

	const returnInformation = () => {
		setDisabled(false);
		setIsValidated(false);
		setStepTwo(false);
	};

	return (
		<div
			className="modal fade"
			id="financialAnticipation"
			tabIndex="-1"
			aria-labelledby="financialAnticipationLabel"
			aria-hidden="true"
		>
			<div
				className="modal-dialog modal-dialog-centered"
				style={{ minWidth: "588px", maxHeight: "600px" }}
			>
				<div
					className="modal-content"
					style={{
						padding: "32px",
						minWidth: "588px",
						borderRadius: "8px",
					}}
				>
					<div className="modal-header d-flex flex-column m-0 p-0">
						<h5 className="financial-text title mb-2">Solicitar Antecipação</h5>
						<div className="progress-initial">
							<div className="timeline">
								<div>
									<div
										className={`timeline ${
											stepTwo ? "start-stepTwo" : "start-stepOne"
										}`}
									>
										{stepTwo ? (
											<span>
												<i className="material-icons">checkmark</i>
											</span>
										) : (
											<span>1</span>
										)}
									</div>
									<label className="text-success">Período</label>
								</div>
								<div>
									<div
										className={`timeline ${
											stepTwo ? "finish-stepTwo" : "finish-stepOne"
										}`}
									>
										<span>2</span>
									</div>
									<label className={stepTwo && "text-success"}>
										Valor a Antecipar
									</label>
								</div>
							</div>
						</div>
					</div>

					<div className="modal-body m-0 p-0">
						<div className="d-flex flex-column justify-content-start">
							{!isValidated ? (
								<form onSubmit={handleSubmit(onSubmitAnticipationLimit)}>
									<div className="d-flex flex-column">
										<label className="card-title mt-1 pt-1">
											Quais recebíveis você deseja antecipar?
										</label>
										<label
											className="m-0 p-0"
											style={{ fontSize: "16px", color: "#939698" }}
										>
											Escolha um período:{" "}
										</label>
									</div>
									<div className="custom-radio-input mt-1 pt-1">
										<label className="radio-input">
											{" "}
											Recebíveis Próximos ¹
											<input
												type="radio"
												value={verification.isReceivablesFar}
												checked={verification.isReceivablesFar ? false : true}
												onChange={(e) =>
													setVerification({
														...verification,
														isReceivablesFar: false,
													})
												}
											/>
											<span className="checkmark"></span>
										</label>
										<label className="radio-input">
											{" "}
											Recebíveis Longes ²
											<input
												type="radio"
												value={verification.isReceivablesFar}
												checked={verification.isReceivablesFar ? true : false}
												onChange={(e) =>
													setVerification({
														...verification,
														isReceivablesFar: true,
													})
												}
											/>
											<span className="checkmark"></span>
										</label>
									</div>
									<div>
										<p className="financial-text text m-0 p-0">
											<em>¹ Recebíveis perto de serem pagos</em>
										</p>
										<p className="financial-text text m-0 p-0">
											<em>
												² Final de todos os recebíveis que você possui para
												receber
											</em>
										</p>
									</div>
									<div className="d-flex flex-column mt-4">
										<label className="card-title mt-1 pt-1">
											Quando você deseja receber o valor antecipado?
										</label>
										<label
											className="m-0 p-0"
											style={{ fontSize: "16px", color: "#939698" }}
										>
											Preencha ou selecione uma data:
										</label>
									</div>
									<div className="neo-form">
										<div className="neo-form-date">
											<Controller
												control={control}
												name="singleDate"
												{...register("singleDate", { required: true })}
												render={({ field }) => (
													<SingleDatePicker
														onChange={(e) => field.onChange(e)}
														error={errors.singleDate}
														disabled={true}
														isModal={true}
													/>
												)}
											/>
										</div>
										{errors.singleDate && (
											<small className="text-danger">
												<em>Informação obrigatória!</em>
											</small>
										)}
									</div>
									<div className="d-flex justify-content-end mt-5">
										<button
											type="button"
											className="btn btn-link mr-2"
											data-dismiss="modal"
											style={{
												width: "190px",
												color: "#5A5E61",
												border: "1px solid #8D8D8D",
												backgroundColor: "transparent",
											}}
										>
											Cancelar
										</button>
										<button
											type="submit"
											className={`btn ${
												!errors.singleDate ? "btn-success" : "btn-light-outline"
											} btn-icon d-flex justify-content-center`}
											style={{ padding: "10px 20px", width: "190px" }}
										>
											Prosseguir
										</button>
									</div>
								</form>
							) : (
								<form onSubmit={handleSubmit(onSubmitAnticipation)}>
									<div className="d-flex flex-column">
										<label
											className="m-0 p-0 d-flex flex-row"
											style={{ fontSize: "14px", color: "#939698" }}
										>
											<em>
												Para a data e o período escolhidos, os limites de
												antecipação disponíveis são:
											</em>
											<div className="financial-help-tooltip d-flex justify-content-center">
												<div
													className="tooltipNeo"
													style={{ margin: "2px 0 0 3px" }}
												>
													<button className="">
														<i
															className="material-icons helper"
															style={{ fontSize: "18px" }}
														>
															help_outline
														</i>
													</button>
													<span className="helper-tooltip">
														<p className="m-0 p-0">
															Os valores são calculados
															<br />
															baseados nos dados
															<br />
															informados na etapa anterior
														</p>
													</span>
												</div>
											</div>
										</label>
										<label
											className="m-0 p-0"
											style={{ fontSize: "14px", color: "#939698" }}
										>
											Limite mínimo:{" "}
											<strong>
												{"R$ " +
													parseFloat(minimumLimits).toLocaleString("pt-br", {
														minimumFractionDigits: 2,
													})}
											</strong>
										</label>
										<label
											className="m-0 p-0"
											style={{ fontSize: "14px", color: "#939698" }}
										>
											Limite máximo:{" "}
											<strong>
												{"R$ " +
													parseFloat(maximumLimits).toLocaleString("pt-br", {
														minimumFractionDigits: 2,
													})}
											</strong>
										</label>
									</div>
									<div className="d-flex flex-column">
										<label className="card-title mt-1 pt-1">
											Que valor você deseja antecipar?
										</label>
										<label
											className="m-0 p-0 mb-2"
											style={{ fontSize: "16px", color: "#939698" }}
										>
											Digite o valor que deseja sacar:{" "}
										</label>
									</div>
									<MaskPrice
										onChange={handleRequestedAmount}
										defaultValue={String(
											requestedAmount.toLocaleString("pt-br", {
												minimumFractionDigits: 2,
											})
										)}
									/>
									<div style={{ fontSize: "12px" }}>
										<p className="financial-text text m-0 p-0">
											{isAvailable ? (
												<em>Realizar solicitação</em>
											) : (
												<em className="text-danger">
													O valor solicitado precisa estar entre o limite mínimo
													e o limite máximo
												</em>
											)}
										</p>
									</div>

									<div className="d-flex justify-content-between mt-5">
										<div>
											<button
												className="btn btn-outline btn-icon mx-1 d-flex justify-content-center"
												style={{
													padding: "10px 20px",
													border: "none",
													background: "transparent",
													color: "#5A5E61",
													width: "96px",
												}}
												onClick={returnInformation}
											>
												Voltar
											</button>
										</div>
										<div className="d-flex flex-row">
											<button
												type="button"
												className="btn btn-link mr-2"
												data-dismiss="modal"
												style={{
													width: "190px",
													color: "#5A5E61",
													border: "1px solid #8D8D8D",
													backgroundColor: "transparent",
												}}
											>
												Cancelar
											</button>
											<button
												type="submit"
												className={`btn ${
													isAvailable ? "btn-success" : "btn-light-outline"
												} btn-icon d-flex justify-content-center`}
												style={{ padding: "10px 20px", width: "190px" }}
											>
												Solicitar Antecipação
											</button>
										</div>
									</div>
								</form>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
