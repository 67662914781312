import React, { Component } from "react";
import DataTable from "react-data-table-component";
import NewPaginationComponent from "./newPaginationComponent";

export const customStyles = {
	headRow: {
		style: {
			border: "0",
			borderBottom: "1px solid #ebebeb",
			borderTop: "1px solid #ebebeb",
			marginTop: "0px",
			paddingLeft: "8px",
			paddingRight: "8px",
		},
	},
	headCells: {
		style: {
			color: "#4B5F79",
			fontSize: "12px",
			fontWeight: "bold",
			textTransform: "uppercase",
			paddingTop: "8px",
			paddingBottom: "8px",
		},
	},
	cells: {
		style: {
			border: "none",
			color: "#747E8B",
			fontSize: "14px",
		},
	},
	rows: {
		style: {
			border: "0",
			borderBottom: "1px solid #ebebeb",
			padding: "8px",
		},
		highlightOnHoverStyle: {
			backgroundColor: "#E9E9E9",
		},
	},
	pagination: {
		style: {
			border: "none",
		},
	},
};

// TODO refactor this List component (react-data-table) OR analyze if we can use only MUI for tables instead
class List extends Component {
	constructor(props) {
		super(props);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleRowsPerPageChange = this.handleRowsPerPageChange.bind(this);
		this.getPagesArray = this.getPagesArray.bind(this);

		this.state = {
			data: [],
			loading: false,
			totalRows: 0,
			perPage: 0,
		};
	}
	async componentDidMount() {
		this.setState({ loading: true });
		this.setState({
			loading: false,
			totalRows: this.props.totalRows,
			data: this.props.data,
			colums: this.props.colums,
			perPage: this.props.rowsPerPage,
		});
		this.forceUpdate();
	}

	handlePageChange = async (page) => {
		this.setState({ loading: true });
		await this.props.handlePageChange(page);
		this.setState({ loading: false });
	};

	// Nova função
	handleRowsPerPageChange = async (rows) => {
		this.setState({ loading: true });
		await this.props.handleRowsPerPageChange(rows);
		this.setState({ loading: false });
	};

	getPagesArray(pages) {
		const results = [];

		for (let i = 1; i <= pages; i++) {
			results.push(i);
		}

		return results;
	}

	render() {
		const { loading, data, totalRows, perPage } = this.state;

		return (
			<>
				{this.props.paginationServer ? (
					<DataTable
						keyField={this?.props?.keyField || "id"}
						persistTableHead={this?.props?.persistTableHead}
						progressComponent={this?.props?.progressComponent}
						progressPending={this?.props?.progressPending || loading}
						highlightOnHover={this?.props?.highlightOnHover}
						customStyles={this?.props?.overwriteCustomStyles || customStyles}
						conditionalRowStyles={this?.props?.conditionalRowStyles}
						expandableRows={this?.props?.expandableRows || false}
						expandableRowDisabled={this?.props?.expandableRowDisabled}
						expandOnRowClicked={this?.props?.expandOnRowClicked}
						expandableRowsHideExpander={this?.props?.expandableRowsHideExpander}
						onRowExpandToggled={this?.props?.onRowExpandToggled}
						expandableRowExpanded={this?.props?.expandableRowExpanded}
						expandableRowsComponent={this?.props?.expandableRowsComponent}
						columns={this.state.colums}
						data={this.state.data}
						noDataComponent={
							this?.props?.noDataComponent ||
							"Não há resultados para a sua busca!"
						}
						noHeader={true}
						pagination
						paginationServer={this.props.paginationServer}
						sortServer={this.props.sortServer}
						paginationDefaultPage={this.props.page}
						paginationTotalRows={totalRows}
						paginationPerPage={this.props.rowsPerPage}
						paginationComponentOptions={{
							rowsPerPageText: "Itens por página: ",
							rangeSeparatorText: "de",
						}}
						paginationComponent={(e) => (
							<NewPaginationComponent
								{...e}
								changeSelectOptions={this.props.changeSelectOptions}
								getPagesArray={this.getPagesArray}
								pageValue={this.props.page}
								noRowsPerPage={this?.props?.noRowsPerPage}
							/>
						)}
						onChangeRowsPerPage={this.handleRowsPerPageChange}
						onChangePage={this.handlePageChange}
						onSort={(column, sortDirection, event) =>
							this.props.sortedReturn(column, sortDirection, event)
						}
						defaultSortAsc={this.props.defaultSortAsc}
						pointerOnHover={this?.props?.pointerOnHover}
						onRowClicked={this?.props?.onRowClicked}
					/>
				) : (
					<DataTable
						keyField={this?.props?.keyField || "id"}
						persistTableHead={this?.props?.persistTableHead}
						progressComponent={this?.props?.progressComponent}
						progressPending={this?.props?.progressPending || loading}
						highlightOnHover={this?.props?.highlightOnHover}
						columns={this.state.colums}
						noHeader={true}
						data={this.state.data}
						customStyles={this?.props?.overwriteCustomStyles || customStyles}
						paginationComponentOptions={{ noRowsPerPage: true }}
						pagination={this?.props?.pagination}
						noTableHead={this?.props?.noTableHead}
						conditionalRowStyles={this?.props?.conditionalRowStyles}
						pointerOnHover={this?.props?.pointerOnHover}
						onRowClicked={this?.props?.onRowClicked}
					/>
				)}
			</>
		);
	}
}

// storiesOf("Pagination", module).add("Server-Side", () => <List />);

export default List;
