import { Button, Stack } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import LoadingButtonIcon from "../LoadingButtonIcon";
import { i18n } from "../../translate/i18n";
import { TCOrderCheckOptions } from "../../screens/TroqueCommerce/TCDetails/components/TCItems";

type Props = {
	isSubmitDisabled: boolean;
	isLoading: boolean;
	onCancelCallback: Function;
	handleDataReset: () => void;
	onSubmitCallback: Function;
	setIsOrderCheckDialogOpen: Dispatch<SetStateAction<boolean>>;
	exchangeIntegrationOptions?: TCOrderCheckOptions;
};

export default function OrderCheckActions({
	isSubmitDisabled,
	isLoading,
	onCancelCallback,
	handleDataReset,
	onSubmitCallback,
	setIsOrderCheckDialogOpen,
	exchangeIntegrationOptions,
}: Props) {
	return (
		<>
			{exchangeIntegrationOptions ? (
				<Button
					variant="contained"
					color="light"
					onClick={() => setIsOrderCheckDialogOpen(false)}
				>
					{i18n.t(`buttons.General.BtnCancel`).toString()}
				</Button>
			) : (
				<Button
					variant="contained"
					color="danger"
					onClick={() => onCancelCallback()}
					disabled={isLoading}
				>
					{i18n.t("orderCheck.RefuseOrder").toString()}
				</Button>
			)}
			<Stack direction="row" gap={2}>
				<Button
					variant="outlined"
					color="warning"
					onClick={() => handleDataReset()}
					disabled={isLoading}
				>
					{i18n.t("orderCheck.RedoConference").toString()}
				</Button>
				<Button
					variant="contained"
					color="success"
					onClick={() =>
						onSubmitCallback(exchangeIntegrationOptions !== undefined)
					}
					disabled={
						exchangeIntegrationOptions
							? isLoading
							: isSubmitDisabled || isLoading
					}
				>
					{isLoading ? (
						<LoadingButtonIcon />
					) : exchangeIntegrationOptions ? (
						i18n.t("buttons.General.BtnSave").toString()
					) : (
						i18n.t("orderCheck.AcceptOrder").toString()
					)}
				</Button>
			</Stack>
		</>
	);
}
