import React from "react";
import { i18n } from "../../../../translate/i18n";
import { insertHtml } from "../../../../infrastructure/utils/InsertHtml";

export default function FixedScreenNotification({ matchScreenName }) {
	const i18nDict = {
		OrderDetails: {
			text: i18n.t("header.NotificationTextOrderDetails"),
			link: "https://neomode.zendesk.com/hc/pt-br/articles/10174848241043",
		},
		Export: {
			text: i18n.t("header.NotificationTextExport"),
			link: "",
		},
		Categories: {
			text: i18n.t("header.NotificationTextCategories"),
			link: "",
		},
		PaymentConfig: {
			text: i18n.t("header.NotificationTextPaymentConfig"),
			link: "https://neomode.atlassian.net/wiki/spaces/CDA/pages/2573402124/Condi+es+Comerciais",
		},
	};

	return (
		<section className="fixed-notifications">
			<div className="container fixed-notifications-container d-flex align-items-center">
				<i className="fixed-notifications-container-icon material-icons">
					new_releases
				</i>
				<span className="fixed-notifications-container-text ml-3">
					{insertHtml(
						i18nDict[matchScreenName].text,
						i18n.t("header.NotificationTextLink"),
						<a href={i18nDict[matchScreenName].link} target="_blank">
							{i18n.t("header.NotificationTextLink")}
						</a>
					)}
				</span>
			</div>
		</section>
	);
}
