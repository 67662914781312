import React, { useState, createContext, useContext } from "react";
import { APIV2, APIV1 } from "../../../../API";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import axios from "axios";
import { i18n } from "../../../../translate/i18n";
import { buildURLWithParams } from "../../../../infrastructure/utils/BuildURLWithParams";

export const ProductsProvider = createContext({});

export default function ProductsContext(props) {
	const params = new URL(document.location).searchParams;
	const pageParam = useParams().page;
	const [loading, setLoading] = useState(true);
	const [loadingExport, setLoadingExport] = useState(false);
	const [products, setProducts] = useState([]);
	const [totalProducts, setTotalProducts] = useState(null);
	const [page, setPage] = useState(pageParam || "1");
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [query, setQuery] = useState(params.get("query") || "");
	const [sortField, setSortField] = useState("name");
	const [sortAscending, setSortAscending] = useState(true);
	const [productsFilters, setProductsFilters] = useState({
		HasPriceProduct: params.get("hasPrice") ? params.get("hasPrice") : "",
		Products: params.get("isActive") ? params.get("isActive") : "",
		HasImageProduct: params.get("hasImage") ? params.get("hasImage") : "",
	});
	const optionsDict = {
		isActive: [
			{ value: "", label: i18n.t("staticMessage.SelectMessage.ShowingAll") },
			{
				value: "true",
				label: i18n.t("staticMessage.SelectMessage.ShowActivated"),
			},
			{
				value: "false",
				label: i18n.t("staticMessage.SelectMessage.ShowDisabled"),
			},
		],
		hasPrice: [
			{ value: "", label: i18n.t("staticMessage.SelectMessage.ShowingAll") },
			{ value: "true", label: i18n.t("staticMessage.SelectMessage.WithPrice") },
			{
				value: "false",
				label: i18n.t("staticMessage.SelectMessage.WithoutPrice"),
			},
		],
		hasImage: [
			{ value: "", label: i18n.t("staticMessage.SelectMessage.ShowingAll") },
			{ value: "true", label: i18n.t("staticMessage.SelectMessage.WithImage") },
			{ value: "false", label: i18n.t("staticMessage.SelectMessage.NoImage") },
		],
	};

	const getProducts = async () => {
		setLoading(true);
		let queryParams = {
			page: page,
			perPage: rowsPerPage,
			sortAscending: sortAscending,
			sortField: sortField,
			isActive: productsFilters.Products,
			hasPrice: productsFilters.HasPriceProduct,
			hasImage: productsFilters.HasImageProduct,
			query: query,
		};

		await APIV2.get(`catalog/products?${buildURLWithParams(queryParams)}`)
			.then((response) => {
				setProducts(response.data.products);
				setTotalProducts(response.data.maxCount);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.error(error);
				toast.error(i18n.t("products.Messages.RequestFailed"));
			});
	};

	const deleteProduct = async (id) => {
		await APIV2.delete(`catalog/products/${id}`)
			.then(() => {
				getProducts();
			})
			.catch((error) => {
				console.error(error);
				toast.error(i18n.t("products.Messages.DeletionFailed"));
			});
	};

	const getSortBy = (column) => {
		try {
			setSortAscending(() => !sortAscending);
			setSortField(() => column.selector);
		} catch (error) {
			console.error(error);
		}
	};

	const handleExportProducts = async () => {
		setLoadingExport(true);
		await APIV1.get(
			`insights/export/products?clientId=${localStorage.getItem(
				"client_id"
			)}&isActive=${productsFilters.Products}&hasPrice=${
				productsFilters.HasPriceProduct
			}&hasImage=${productsFilters.HasImageProduct}&query=${query}`
		)
			.then((response) => {
				const exportInterval = setInterval(async () => {
					const resp = await axios.get(response.data.statusQueryGetUri);
					if (
						resp.data.runtimeStatus !== "Running" &&
						resp.data.runtimeStatus !== "Pending"
					) {
						switch (resp.data.runtimeStatus) {
							case "Completed":
								setLoadingExport(false);
								clearInterval(exportInterval);
								window.open(resp.data.output, "_blank");
								break;
							case "Failed":
							default:
								clearInterval(exportInterval);
								break;
						}
					}
				}, 4000);
			})
			.catch(() => {
				setLoadingExport(false);
			});
	};

	return (
		<ProductsProvider.Provider
			value={{
				page,
				setPage,
				rowsPerPage,
				setRowsPerPage,
				query,
				setQuery,
				productsFilters,
				setProductsFilters,
				products,
				totalProducts,
				sortAscending,
				loading,
				setLoading,
				getProducts,
				getSortBy,
				deleteProduct,
				optionsDict,
				handleExportProducts,
				setLoadingExport,
				loadingExport,
			}}
		>
			{props.children}
		</ProductsProvider.Provider>
	);
}

export const useProducts = () => useContext(ProductsProvider);
