const percentageDivision = (value: string): string => {
	return (parseFloat(value) / 100).toFixed(4);
};

const percentageMultiplication = (value: string): string => {
	return (parseFloat(value) * 100).toFixed(2);
};

const getPercentageOf = (value: number, total: number): number => {
	if (total === 0) return 0;

	return Number(((value / total) * 100).toFixed(2));
};

export { percentageDivision, percentageMultiplication, getPercentageOf };
