import { BaseOverrideColors } from "./baseOverrideColors";

import { paletteObject } from "../foundations/palette";
import { checkboxClasses } from "@mui/material";

declare module "@mui/material/Button" {
	interface ButtonPropsColorOverrides extends BaseOverrideColors {}
}

export const MuiCheckbox = {
	defaultProps: {
		disableRipple: true,
	},
	styleOverrides: {
		root: {
			borderRadius: 0,
			color: paletteObject.grey20.main,
			[`&.${checkboxClasses.checked}`]: {
				color: paletteObject.success.main,
			},
		},
	},
};
