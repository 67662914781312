import React, { useEffect, useState } from "react";

import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header/Header";
import Breadcrumbs from "../components/layout/Breadcrumb";

function LayoutWrapper(props) {
	const [params, setParams] = useState("");

	useEffect(() => {
		if (window.location.href.split("?")[1]) {
			localStorage.setItem("PARAMS", window.location.href.split("?")[1]);
			setParams(window.location.href);
		}
	}, [window.location.href]);

	return (
		<>
			<Header />
			<Breadcrumbs crumbs={props.crumbs} queryParams={params} />
			{props.children}
			<Footer />
		</>
	);
}

export default LayoutWrapper;
