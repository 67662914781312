import React, { useEffect, useState, useContext, createContext } from "react";
import Axios from "axios";
import { APIV2, APIIMPORT } from "../../../../API";

export const MatcherProviders = createContext({});

export default function MatcherContext(props) {
	const [blobStatus, setBlobStatus] = useState([]);
	const [status, setStatus] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadingDownload, setLoadingDownload] = useState(false);
	const [integrations, setIntegrations] = useState([]);
	const [integrationCode, setIntegrationCode] = useState("");

	useEffect(() => {
		setStatus(blobStatus);
	}, [blobStatus]);

	const getStatus = async () => {
		let arrayList = [];
		const response = await APIIMPORT.get(
			"importHistoric?page=1&perPage=5&onlyCatalogMatcher=true"
		);

		if (response.status == 502) {
			// Status 502 is a connection timeout error,
			// may happen when the connection was pending for too long,
			// and the remote server or a proxy closed it
			// let's reconnect
			await getStatus();
		} else if (response.status != 200) {
			// Reconnect in one second
			await new Promise((resolve) => setTimeout(resolve, 5000));
			await getStatus();
		} else {
			// Call subscribe() again to get the next message
			arrayList = await Promise.all(
				response.data.historics.map(async (file) => {
					try {
						const resp = await Axios.get(file.urlStatus);
						return { data: resp.data, id: file.id, file: file };
					} catch (error) {
						console.log(error);
						return { data: {}, id: file.id, file: file };
					}
				})
			);
			setBlobStatus(arrayList);
			setLoading(false);
			if (
				arrayList.some(
					(item) => item.data.ProgressStatus !== 100 && !item.data.message
				)
			) {
				await new Promise((resolve) => setTimeout(resolve, 2500));
				await getStatus();
			} else {
				return;
			}
		}
	};

	const getIntegrations = async () => {
		try {
			const response = await APIV2.get(
				`catalog/matcher/${localStorage.getItem("client_id")}/integrations`
			);
			setIntegrationCode(response.data[0].Code);
			setIntegrations(response.data);
			return;
		} catch (error) {
			return;
		}
	};

	return (
		<MatcherProviders.Provider
			value={{
				getStatus,
				blobStatus,
				setBlobStatus,
				status,
				setStatus,
				loading,
				setLoading,
				loadingDownload,
				setLoadingDownload,
				integrations,
				getIntegrations,
				integrationCode,
				setIntegrationCode,
			}}
		>
			{props.children}
		</MatcherProviders.Provider>
	);
}

export const useMatcher = () => useContext(MatcherProviders);
