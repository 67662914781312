import React, { useState, useEffect } from "react";
import Modal from "../../../../components/Modal";
import List from "../../../../components/List/List";
import ContentLoader from "react-content-loader";
import HeaderSearches from "../../../../components/HeaderSearches/HeaderSearches";
import { useHistory, Link } from "react-router-dom";
import { useProducts } from "../providers/ProductsContext";
import { i18n } from "../../../../translate/i18n";

export default function ProductList() {
	const {
		page,
		setPage,
		rowsPerPage,
		setRowsPerPage,
		query,
		setQuery,
		productsFilters,
		setProductsFilters,
		products,
		totalProducts,
		sortAscending,
		loading,
		setLoading,
		getProducts,
		getSortBy,
		deleteProduct,
		optionsDict,
	} = useProducts();
	const history = useHistory();
	const loads = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
	const [deleteId, setDeleteId] = useState(null);
	const columsList = [
		{
			name: "Produto",
			selector: "name",
			sortable: true,
			width: "100px",
			style: {
				overflow: "visible",
				color: "white",
				justifyContent: "center",
			},
			cell: (row) => (
				<div className="product-image">
					<img
						src={!row.imageUrl ? "/images/placeholder.jpg" : row.imageUrl}
						alt="Produto"
						className="shadow-sm"
					/>
				</div>
			),
		},

		{
			name: "",
			selector: "name",
			sortable: false,
			width: "600px",
			style: {
				overflow: "hidden",
				wordBreak: "break-word",
			},
			cell: (row) => (
				<div className="col">
					<div>
						<h6
							className="font-weight-bold"
							style={{ wordBreak: "break-word" }}
						>
							{row.name}
						</h6>
						<small className="text-muted">{row.code}</small>
					</div>
				</div>
			),
		},
		{
			sortable: true,
			name: "Status",
			selector: "isActive",
			cell: (row) =>
				row.isActive ? (
					<span className="badge badge-success stadium">ATIVO</span>
				) : (
					<span className="badge badge-grey-60 stadium">INATIVO</span>
				),
		},
		{
			name: "",
			sortable: true,
			width: "150px",
			cell: (row) => (
				<div className="btn-group" role="group">
					<Link
						id="btnGroupDrop1"
						to={`/products/${page}/edit/${!row.id ? row.code : row.id}`}
						className="btn btn-info"
					>
						Editar
					</Link>
					<button
						id="btnGroupDrop1"
						type="button"
						className="btn btn-sm border-left border-white btn-info rounded-right"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<span className="material-icons" style={{ fontSize: "15px" }}>
							keyboard_arrow_down
						</span>
					</button>
					<div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
						<a
							className="dropdown-item text-danger"
							data-toggle="modal"
							data-target="#deleteProduct"
							onClick={() => setDeleteId(!row.id ? row.code : row.id)}
						>
							Excluir
						</a>
					</div>
				</div>
			),
		},
	];
	const selectList = [
		{
			title: "Products",
			options: optionsDict.isActive,
		},
		{
			title: "HasPriceProduct",
			options: optionsDict.hasPrice,
		},
		{
			title: "HasImageProduct",
			options: optionsDict.hasImage,
		},
	];
	useEffect(() => {
		history.push(
			`${page}?query=${query}&isActive=${productsFilters.Products}&hasPrice=${productsFilters.HasPriceProduct}&hasImage=${productsFilters.HasImageProduct}`
		);
		getProducts();
	}, [page, rowsPerPage, query, productsFilters]);

	const changePage = (e) => {
		setLoading(true);
		setPage(e);
	};

	const changeRowsPerPage = (e) => {
		setLoading(true);
		setRowsPerPage(e);
		setPage("1");
	};

	const handleQueryChange = (queryValue) => {
		setQuery(queryValue);
		setPage("1");
	};

	const handleClearFilters = () => {
		setProductsFilters({
			Products: "",
			HasPriceProduct: "",
			HasImageProduct: "",
		});
		setPage("1");
	};

	const sortBy = (column) => {
		getSortBy(column);
	};

	const renderLoading = () => {
		return (
			<>
				<div className="container dash-content">
					<div
						className=""
						style={{ overflow: "hidden", paddingBottom: "1em" }}
					>
						{loads.map(() => (
							<ContentLoader
								width={1100}
								height={100}
								backgroundColor="#e6e6e6"
								foregroundColor="#fafafa"
								// viewBox="0 0 100 100"
							>
								<rect x="120" y="40" rx="5" ry="5" width="400" height="10" />
								<rect x="120" y="59" rx="5" ry="5" width="100" height="5" />
								<rect x="690" y="50" rx="5" ry="5" width="55" height="18" />
								<rect x="950" y="45" rx="3" ry="5" width="90" height="40" />
								<rect x="20" y="10" rx="0" ry="0" width="80" height="190" />
							</ContentLoader>
						))}
					</div>
				</div>
			</>
		);
	};

	return (
		<>
			<div className="container dash-content">
				<div className="card">
					<div className="card-header d-flex justify-content-between align-items-center p-3">
						<h2 className="card-title">Produtos</h2>
						<div className="">
							<Link
								className="btn btn-new-width btn-info light btn-icon mr-2"
								to={"/import"}
							>
								<i className="material-icons-outlined mr-2">upload</i>
								<span>Importar Produtos</span>
							</Link>
							<Link
								className="btn btn-grey-80 btn-icon"
								style={{ padding: "8px" }}
								to={`/products/${page}/new`}
							>
								<i className="material-icons">add</i>
							</Link>
						</div>
					</div>
					<div className="m-2 p-2">
						<HeaderSearches
							query={query}
							handleQueryChange={handleQueryChange}
							handleClearFilters={handleClearFilters}
							selectList={selectList}
							setFilters={setProductsFilters}
							filters={productsFilters}
							placeholder={"SearchProducts"}
						/>
					</div>
					<div className="card-content pt-1">
						<div
							className="d-flex align-items-center p-3"
							style={{ width: "100%" }}
						>
							<strong>
								{i18n
									.t("components.ShowingAllResultsQuantity")
									.replace("%X", totalProducts || "0")
									.toUpperCase()}
							</strong>
						</div>
						<div className="card-body no-padding">
							{totalProducts === 0 ? (
								<div className="empty">
									<div className="col-12 text-center">
										<img src={"/images/empty_products.svg"} width="20%" />
										<h3>
											<b>Ops! Não tem nada por aqui...</b>
										</h3>
									</div>
								</div>
							) : loading ? (
								renderLoading()
							) : (
								<List
									data={products}
									page={parseInt(page)}
									colums={columsList}
									totalRows={totalProducts}
									rowsPerPage={rowsPerPage}
									handlePageChange={changePage}
									handleRowsPerPageChange={changeRowsPerPage}
									paginationServer={true}
									sortedReturn={sortBy}
									sortServer={true}
									defaultSortAsc={sortAscending}
								/>
							)}
						</div>
					</div>
				</div>

				<Modal
					id={"deleteProduct"}
					title={"Certeza que deseja excluir este item?"}
					delete={true}
					actionDelete={() => deleteProduct(deleteId)}
				/>
			</div>
		</>
	);
}
