import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ContentLoader from "react-content-loader";
import List from "../../../components/List/List";
import FinancialEmpty from "./FinancialEmpty";
import moment from "moment";
import FinancialWithdrawModal from "./FinancialWithdrawModal";
import { useFinancial } from "../providers/FinancialContext";
import FinancialCancelWithdraw from "./FinancialCancelWithdraw";
import DateFilter from "./DateFilter/DateFilter";
import GenericTableLoader from "./GenericTableLoader";

export default function FinancialWithdraw() {
	const {
		listWithdraw,
		loading,
		setLoading,
		getListWithdraw,
		withdrawalFilters,
		setWithdrawalFilters,
		getTotalListWithdraw,
		withdrawExport,
	} = useFinancial();
	const history = useHistory();
	const [cancelWithdraw, setCancelWithdraw] = useState("");
	const [page, setPage] = useState(1);
	const [totalWithdrawAmount, setTotalWithdrawAmount] = useState(0);

	const columsList = [
		{
			name: "DATA DE SOLICITAÇÃO",
			selector: "date",
			width: "190px",
			cell: (row) => (
				<div className="">
					<p className="financial-text text m-0 p-0">
						<strong>{moment(row?.dateCreated).format("DD/MM/YYYY")}</strong>
					</p>
					<p className="financial-text text m-0 p-0">
						{moment(row?.dateCreated).format("HH:mm")}
					</p>
				</div>
			),
		},
		{
			name: "TIPO",
			selector: "tipo",
			width: "100px",
			center: true,
			cell: (row) => (
				<div className="financial-text text text-center">
					{row.type === "ted"
						? "TED"
						: row.type === "doc"
						? "DOC"
						: row.type === "conta_corrente"
						? "Conta Corrente"
						: "PIX"}
				</div>
			),
		},
		{
			name: "VALOR",
			selector: "valor",
			center: true,
			width: "250px",
			cell: (row) => (
				<div className="">
					<p className="financial-text text m-0 p-0">
						Valor solicitado:{" "}
						{parseFloat(row?.amount).toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						})}
					</p>
					<div className="d-flex flex-row">
						<p className="financial-text text m-0 p-0">
							<strong>
								Você Recebe:{" "}
								{parseFloat(row?.receive).toLocaleString("pt-br", {
									style: "currency",
									currency: "BRL",
								})}
							</strong>
						</p>
						<div className="financial-help-tooltip">
							<div className="tooltipNeo">
								<button className="">
									<i
										className="material-icons helper"
										style={{ fontSize: "18px" }}
									>
										help_outline
									</i>
								</button>
								<span className="helper-tooltip">
									<p className="m-0 p-0">
										Valor solicitado:{" "}
										{parseFloat(row?.amount).toLocaleString("pt-br", {
											style: "currency",
											currency: "BRL",
										})}
									</p>
									<p className="m-0 p-0">
										Taxas:{" "}
										{parseFloat(row?.fee).toLocaleString("pt-br", {
											style: "currency",
											currency: "BRL",
										})}
									</p>
									<p className="m-0 p-0">
										Você recebe:{" "}
										{parseFloat(row?.receive).toLocaleString("pt-br", {
											style: "currency",
											currency: "BRL",
										})}
									</p>
								</span>
							</div>
						</div>
					</div>
				</div>
			),
		},
		{
			name: "STATUS",
			selector: "status",
			center: true,
			width: "220px",
			cell: (row) => (
				<div
					className={`financial-label
				${
					row.status === "pending_transfer"
						? "warning"
						: row.status === "transferred"
						? "success"
						: row.status === "failed"
						? "danger"
						: row.status === "canceled"
						? "grey-65"
						: row.status === "processing"
						? "info"
						: null
				}
				`}
				>
					{row.status === "pending_transfer"
						? "Aguardando Transferência"
						: row.status === "transferred"
						? "Transferido"
						: row.status === "failed"
						? "Erro"
						: row.status === "processing"
						? "Processando"
						: row.status === "canceled"
						? "Cancelado"
						: null}
				</div>
			),
		},
		{
			name: "DATA DE TRANSFERÊNCIA",
			selector: "transferencia",
			center: true,
			width: "220px",
			cell: (row) => (
				<div className="">
					<p className="financial-text text text-center m-0 p-0">
						<strong>
							{row.status === "transferred"
								? "Concluída em:"
								: row.status === "processing"
								? "Disponível a partir de: "
								: row.status === "failed"
								? "Cancelada em: "
								: row.status === "canceled"
								? "Cancelada em: "
								: "Disponível a partir de: "}
						</strong>
					</p>
					<p className="financial-text text-center m-0 p-0">
						{row.status === "transferred"
							? moment(row?.dateUpdated).format("DD/MM/YYYY - HH:mm")
							: row.status === "processing"
							? moment(row?.dateEstimated).format("DD/MM/YYYY - HH:mm")
							: row.status === "canceled"
							? moment(row?.dateUpdated).format("DD/MM/YYYY - HH:mm")
							: row.status === "failed"
							? moment(row?.dateUpdated).format("DD/MM/YYYY - HH:mm")
							: moment(row?.dateEstimated).format("DD/MM/YYYY")}
						{}
					</p>
				</div>
			),
		},
		{
			name: "AÇÕES",
			selector: "action",
			style: {
				marginRight: "-8px",
			},
			right: true,
			cell: (row) => (
				<div className="financial-buttons">
					<div className="tooltipNeo" style={{ padding: "0px" }}>
						{!row.isFinalized ? (
							<>
								<button
									data-toggle={"modal"}
									data-target="#financialCancelWithdraw"
									onClick={() => setCancelWithdraw(row)}
								>
									<i className="material-icons text-danger">close</i>
								</button>
								<span>Cancelar solicitação</span>
							</>
						) : (
							<>
								<button disabled={true}>
									<i className="material-icons delete">close</i>
								</button>
								<span>
									{row.status === "canceled"
										? "Solicitação cancelada"
										: "Não é possível cancelar a solicitação"}
								</span>
							</>
						)}
					</div>
					{/* Code is commented while receipt download isn't working */}
					{/* <div className="tooltipNeo">
						<button className="">
							<i className="material-icons download">receipt_long</i>
						</button>
						<span>Baixar recibo</span>
					</div> */}
				</div>
			),
		},
	];

	useEffect(() => {
		const fetchData = async () => {
			setTotalWithdrawAmount((await getTotalListWithdraw()).length);
		};

		fetchData();
	}, [withdrawalFilters]);

	useEffect(() => {
		history.push(
			`/financial/withdraw?rowsPerPage=${withdrawalFilters?.rowsPerPage}`
		);
		getListWithdraw(page);
	}, [withdrawalFilters, page]);

	const changeRowsPerPage = (e) => {
		setLoading(true);
		setWithdrawalFilters({
			...withdrawalFilters,
			rowsPerPage: e,
		});
	};

	const changePage = (e) => {
		setLoading(true);
		setPage(e);
	};

	const skeletonButton = () => {
		return (
			<ContentLoader
				speed={2}
				width={1100}
				height={50}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 50"
				style={{ width: "100%" }}
			>
				<rect x="950" y="10" rx="3" ry="3" width="1500" height="45" />
			</ContentLoader>
		);
	};

	return (
		<>
			<div className="d-flex justify-content-between px-4 pt-4">
				<h3 className="financial-text title">Saques</h3>
				<button
					className="btn btn-grey-80 d-flex justify-content-center px-0"
					data-toggle="modal"
					data-target="#financialWithdraw"
					style={{ width: "190px" }}
				>
					<i className="material-icons-outlined">local_atm</i>
					<span className="ml-2">Solicitar Saque</span>
				</button>
			</div>
			<DateFilter
				screen={"Withdrawals"}
				setPage={setPage}
				tabData={withdrawalFilters}
				setTabData={setWithdrawalFilters}
				exportData={withdrawExport}
			/>
			<div
				className="d-flex align-items-center px-4 pt-1 pb-3 "
				style={{ width: "100%" }}
			>
				<strong>
					MOSTRANDO {loading ? "0" : totalWithdrawAmount} RESULTADOS
				</strong>
			</div>
			<div className="">
				<div className="card-list-pagination no-padding">
					{loading ? (
						<GenericTableLoader />
					) : listWithdraw.length > 0 ? (
						<List
							data={listWithdraw}
							page={page}
							colums={columsList}
							totalRows={totalWithdrawAmount}
							handlePageChange={changePage}
							paginationServer={true}
							rowsPerPage={withdrawalFilters?.rowsPerPage}
							handleRowsPerPageChange={changeRowsPerPage}
						/>
					) : (
						<FinancialEmpty
							img={"/images/empty_coupon.svg"}
							status={"saques"}
						/>
					)}
				</div>
				{loading ? skeletonButton() : <FinancialWithdrawModal />}
				<FinancialCancelWithdraw withdraw={cancelWithdraw} />
			</div>
		</>
	);
}
