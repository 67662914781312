import React, { useEffect, useState } from "react";
import LastOrdersTable from "./LastOrders/LastOrdersTable";
import LastOrdersHeader from "./LastOrders/LastOrdersHeader";

function LastOrders({ isLoadingOrders, orders }) {
	const [lastOrders, setLastOrders] = useState([]);
	const lastOrderQuantityShown = 10;

	useEffect(() => {
		if (orders) setLastOrders(mapLastOrders(orders.orders));
	}, [orders]);

	const mapLastOrders = (orders) => {
		const lastOrdersTableArray = orders
			.slice(0, lastOrderQuantityShown)
			.map((order) => {
				return {
					id: order.id,
					createDate: order.createDate,
					fullName: order.fullName,
					totalAmount: order.totalAmount,
					externalId: order?.externalId ?? null,
					friendlyCode: order.friendlyCode,
					sellerOrderId: order?.sellerOrderId | null,
					status: order.status,
					isDelivery: null, // Cp version doesn't use isDelivery to render the table
				};
			});
		return lastOrdersTableArray;
	};

	return (
		<div className="card">
			<LastOrdersHeader />
			<LastOrdersTable lastOrders={lastOrders} isLoading={isLoadingOrders} />
		</div>
	);
}

export default LastOrders;
