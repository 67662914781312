import { UIEvent } from "react";

interface ScrollHandlerProps {
	hasBottomContent: boolean;
	isScrolling: boolean;
	setIsScrolling: (value: boolean) => void;
	setShowContentBottom: (value: boolean) => void;
	scrollDown: () => void;
}

export const ScrollHandler = (
	event: UIEvent<HTMLDivElement>,
	{
		hasBottomContent,
		isScrolling,
		setIsScrolling,
		setShowContentBottom,
		scrollDown,
	}: ScrollHandlerProps
) => {
	const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

	const elementToScroll = event.currentTarget;

	const marginOfError = 1;

	setIsScrolling(false);

	setShowContentBottom(hasBottomContent);

	if (
		!isScrolling &&
		scrollTop + clientHeight + marginOfError >= scrollHeight
	) {
		elementToScroll.scrollTo({
			behavior: "smooth",
		});

		scrollDown();

		setShowContentBottom(false);

		setIsScrolling(true);
	}
};
