import styled from "styled-components";
import "../../../assets/css/_variables.scss";
import { Box, Grid } from "@mui/material";
import { paletteObject } from "../../../theme/foundations/palette";

export const BoxSideBySide = styled(Box)`
	display: flex;
	gap: 15px;
	flex-direction: row;
`;

export const ListContentBox = styled(Box)`
	width: 400px;
`;

export const ContentBox = styled(Box)`
	width: 900px;
	height: 495px;
	box-shadow: 0px 10px 14px 0 rgba(25, 42, 70, 0.1);
	background: ${paletteObject.white.main};
	border-radius: 3px;
	padding: 24px;
`;

export const EmptyContentBox = styled(Box)`
	text-align: center;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const ModalList = styled.ul`
	padding-left: 0;

	> li {
		list-style: none !important;
		padding-left: 0 !important;
		margin: 8px 0;
	}
`;

export const ModalBody = styled.div`
	width: 567px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: ${paletteObject.white.main};
	border: 2px solid ${paletteObject.white.main};
	border-radius: 4px;
	padding: 35px;
`;

export const BoxImage = styled.div`
	flex: 0 0 146px;
	height: 108px;
	margin-right: 21px;
`;

export const GridInput = styled(Grid)`
	height: 68px;
`;

export const BoxAttention = styled(Box)`
	border: 2px solid ${paletteObject.danger.main};
	border-radius: 5px;
	width: 100%;
	color: ${paletteObject.danger.main};
	padding: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	justify-content: center;
	margin: 20px 0;
`;

export const TitleAndSearchBox = styled(Box)`
	display: flex;
	justify-content: space-between;
	height: 50px;
`;

export const EmptyList = styled(Box)`
	text-align: center;
	margin-top: 140px;
`;

export const EmptyImage = styled.img`
	width: 35%;
	padding-bottom: 40px;
`;
