import React, { Component } from "react";
import appActions from "../appActions";
import { API } from "../API";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import queryString from "query-string";
import { toast } from "react-toastify";
export const history = createBrowserHistory();

class Stepper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			url: window.location.pathname.split("/")[2],
		};
	}
	sendDataStepper() {
		if (
			!this.props?.data?.primaryColor &&
			window.location.pathname.split("/")[3] === "colors"
		) {
			toast.error("Por favor selecionar a cor primária");
			return;
		}
		if (
			!this.props?.data?.secondaryColor &&
			window.location.pathname.split("/")[3] === "colors"
		) {
			toast.error("Por favor selecionar a cor secundária");
			return;
		}
		//Verifica se existe conteudo para enviar para API,
		//se não houver redireciona para o proximo passo

		if (this.props.content == "no-content") {
			if (this.props.email != undefined) {
				if (process.env.REACT_APP_AMBIENTE == "Produção") {
					API.get(this.props.email)
						.then((response) => {
							window.location.href = this.props.text;
						})
						.catch((error) => {
							console.log(error);
						});
				} else {
					window.location.href = this.props.text;
				}
			} else {
				window.location.href = this.props.text;
			}
		} else {
			var clientId = localStorage.getItem("client_id");
			var body = this.props.data;
			//Consulta materiais disponíveis a partir do token do usuário.
			API.put(this.props.url, body)
				.then(async (response) => {
					// If request is good...
					if (this.props.email != undefined) {
						if (process.env.REACT_APP_AMBIENTE == "Produção") {
							API.get(this.props.email)
								.then((res) => {
									window.location.href = this.props.text;
								})
								.catch((error) => {
									console.log(error);
								});
						} else {
							window.location.href = this.props.text;
						}
					} else {
						window.location.href = this.props.text;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}
	componentDidMount() {
		switch (this.state.url) {
			case "onboarding":
				this.setState({ step: 1 });
				this.forceUpdate();
				break;
			case "brand":
				this.setState({ step: 2 });
				this.forceUpdate();
				break;
			case "stores":
				this.setState({ step: 3 });
				this.forceUpdate();
				break;
			case "store":
				this.setState({ step: 3 });
				this.forceUpdate();
				break;
			case "content":
				this.setState({ step: 4 });
				this.forceUpdate();
				break;
			case "app-store":
				this.setState({ step: 5 });
				this.forceUpdate();
				break;
		}
	}
	render() {
		return (
			<div className="stepper">
				<div className="container">
					<a href={this.props.text} className="skip-step">
						{this.props.req ? null : "Pular esta etapa > "}
					</a>
					<div className="timeline">
						<button className="btn btn-light" onClick={() => history.goBack()}>
							Voltar
						</button>
						<ul>
							<li
								className={
									this.state.step == 1
										? "active"
										: this.state.step > 1
										? "completed"
										: ""
								}
							>
								<span>Onboarding</span>
							</li>
							<li
								className={
									this.state.step == 2
										? "active"
										: this.state.step > 2
										? "completed"
										: ""
								}
							>
								<span>Marca</span>
							</li>
							<li
								className={
									this.state.step == 3
										? "active"
										: this.state.step > 3
										? "completed"
										: ""
								}
							>
								<span>Lojas</span>
							</li>
							<li
								className={
									this.state.step == 4
										? "active"
										: this.state.step > 4
										? "completed"
										: ""
								}
							>
								<span>Conteúdo</span>
							</li>
							<li
								className={
									this.state.step == 5
										? "active"
										: this.state.step > 5
										? "completed"
										: ""
								}
							>
								<span>App Store</span>
							</li>
						</ul>
						<button
							className="btn btn-secondary"
							onClick={() => this.sendDataStepper()}
						>
							Avançar
						</button>
					</div>
				</div>
			</div>
		);
	}
}
export default connect((store) => ({
	text: store.text,
	req: store.req,
	fill: store.fill,
	data: store.data,
	url: store.url,
	content: store.content,
	email: store.email,
}))(Stepper);
