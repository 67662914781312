import React, { useEffect, useState } from "react";
import moment from "moment";
import { Popover } from "react-tiny-popover";
import Modal from "../../../../components/Modal";
import ContentLoader from "react-content-loader";
import { useMatcher } from "../providers/MatcherContext";

function MatcherImportList() {
	const [isPopoverOpen, setIsPopoverOpen] = useState("");

	const { getStatus, blobStatus, status, setStatus, loading } = useMatcher();

	useEffect(() => {
		getStatus();
	}, []);

	const matcherMoundList = () => {
		return (
			<table className="table history-import">
				<tbody>
					<tr>
						<th className="">TIPO DE MAPEAMENTO</th>
						<th className="text-center">INÍCIO</th>
						<th className="text-center">FIM</th>
						<th className="text-center">STATUS</th>
						<th className="text-center"></th>
						<th className="text-center"></th>
					</tr>
					{status?.map((blob, i) => (
						<tr key={i}>
							<td
								className={
									blob.data.HasError && blob.data.ProgressStatus !== 100
										? "align-middle  text-danger font-weight-bold"
										: blob.data.HasError
										? "align-middle  text-warning font-weight-bold"
										: "align-middle"
								}
							>
								{blob.file.type === "marketplace-categories-mapping"
									? "Mapeamento de Categorias"
									: blob.file.type === "marketplace-brands-mapping"
									? "Mapeamento de Marcas"
									: "Mapeamento de Atributos"}
							</td>
							<td
								className={
									blob.data.HasError && blob.data.ProgressStatus !== 100
										? "text-center align-middle text-danger font-weight-bold"
										: blob.data.HasError
										? "text-center align-middle text-warning font-weight-bold"
										: "text-center align-middle"
								}
							>
								{moment(blob.file.startDate).format("DD/MM/YYYY HH:mm")}
							</td>
							<td
								className={
									blob.data.HasError && blob.data.ProgressStatus !== 100
										? "text-center align-middle text-danger font-weight-bold"
										: blob.data.HasError
										? "text-center align-middle text-warning font-weight-bold"
										: "text-center align-middle"
								}
							>
								{moment(blob.file.timestamp).format("DD/MM/YYYY HH:mm")}
							</td>
							<td className="text-center" colSpan="2">
								{blob.data.HasError && blob.data.ProgressStatus !== 100 ? (
									<span className="badge badge-danger stadium text-center">
										Não importado
									</span>
								) : blobStatus.hasError ? (
									<span className="badge badge-warning stadium text-center">
										{" "}
										Arquivo com erro
									</span>
								) : (
									<div className="progress ">
										<div
											className={
												!blob.data.CurrentStatus
													? "progress-bar  bg-danger"
													: blob.data.HasError
													? "progress-bar  bg-warning"
													: "progress-bar  bg-primary"
											}
											role="progressbar"
											style={{
												width: !blob.data.CurrentStatus
													? "100%"
													: blob.data.ProgressStatus + "%",
												textAlign: "center",
											}}
											aria-valuenow="25"
											aria-valuemin="0"
											aria-valuemax="100"
										>
											{blob.data.ProgressStatus > 0 ? (
												<>{blob.data.ProgressStatus} %</>
											) : blob.data.CurrentStatus ? (
												<small>executando</small>
											) : (
												<>Erro</>
											)}
										</div>
										{(blob.data.HasError || !blob.data.CurrentStatus) && (
											<Popover
												style={{ zIndex: 10 }}
												isOpen={isPopoverOpen === blob.id}
												position={["top", "left"]} // if you'd like, you can limit the positions
												padding={10} // adjust padding here!
												reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
												// onClickOutside={() => setIsPopoverOpen("")} // handle click events outside of the popover/target here!
												content={() => (
													// you can also provide a render function that injects some useful stuff!

													<div
														style={{
															zIndex: 100,
															backgroundColor: "rgba(0,0,0, 0.8)",
															color: "white",
															padding: "1em",
															borderRadius: 5,
															width: 200,
															textAlign: "center",
														}}
													>
														A planilha enviada contem erros que precisam ser
														corrigidos, clique em “ver detalhes” para mais
														informações;
													</div>
												)}
											>
												<i
													className="material-icons text-danger"
													onBlur={() => setIsPopoverOpen("")}
													// onClick={() => setIsPopoverOpen(blob.id)}
													onMouseOver={() => setIsPopoverOpen(blob.id)}
													onMouseOut={() => setIsPopoverOpen("")}
													style={{ fontSize: "24px", right: "17.5%" }}
												>
													error_outline
												</i>
											</Popover>
										)}
									</div>
								)}
							</td>
							<td className="text-right">
								<a
									className="btn btn-link text-info"
									style={{ textDecorationLine: "underline" }}
									data-toggle="modal"
									data-target={`#details-${blob.id}`}
								>
									ver detalhes
								</a>
								<Modal
									id={`details-${blob.id}`}
									import="true"
									titleComponent={
										<>
											<h3 className="modal-title">
												Detalhes da importação{" "}
												{blob.data.HasError ? (
													<span
														className="text-warning"
														style={{ fontSize: "14px" }}
													>
														Ocorreu algum erro
													</span>
												) : blob.data.ProgressStatus === 100 &&
												  !blob.data.HasError ? (
													<span
														className="text-success"
														style={{ fontSize: "14px" }}
													>
														concluído
													</span>
												) : blob.data.HasError &&
												  blob.data.ProgressStatus !== 100 ? (
													<span
														className="text-danger"
														style={{ fontSize: "14px" }}
													>
														Ocorreu algum erro
													</span>
												) : (
													<span
														className="text-info"
														style={{ fontSize: "14px" }}
													>
														em andamento
													</span>
												)}
											</h3>
										</>
									}
									large={true}
									content={
										<div>
											{blob.data ? <p>{blob.data.currentStatus}</p> : null}
											<div className="progress">
												<div
													className={
														blob.data.HasError &&
														blob.data.ProgressStatus !== 100
															? "progress-bar  bg-danger"
															: blob.data.HasError
															? "progress-bar  bg-warning"
															: "progress-bar  bg-primary"
													}
													role="progressbar"
													style={{
														width: blob.data.ProgressStatus + "%",
														textAlign: "right",
													}}
													aria-valuenow="25"
													aria-valuemin="0"
													aria-valuemax="100"
												></div>
											</div>
											<div className="mt-5 p-2" id="details">
												{blob.data.Items && blob.data.Items.length > 0 && (
													<a
														href={blob.data.Items[0].MainFileUrl}
														target="_blank"
														className="download-archive"
													>
														<span className="material-icons">archive</span>
														download arquivo de importação
													</a>
												)}
												<div className="import-list-log-files">
													{blob.data.Items &&
														blob.data.Items.map((item, i) => (
															<div className="row d-flex align-items-center border-bottom">
																<div className="col-3 text-left ">
																	Parte {item.PartId}
																</div>
																<div className="col-5">
																	{item.HasData && !item.HasError ? (
																		<span className="badge badge-success stadium text-center">
																			Concluído
																		</span>
																	) : item.ValidationFileUrl ? (
																		<div className="log-erro">
																			<span className="badge badge-warning stadium text-center">
																				Arquivo com erro
																			</span>
																			<a
																				href={item.ValidationFileUrl}
																				target="_blank"
																			>
																				<i className="material-icons">
																					error_outline
																				</i>{" "}
																				log de erro
																			</a>
																		</div>
																	) : (
																		<span className="badge badge-info stadium text-center">
																			Em andamento
																		</span>
																	)}
																</div>
																<div className="col-4 text-right">
																	<a
																		href={item.PartFileUrl}
																		taget="_blank"
																		style={{ textDecoration: "underline" }}
																		className="btn btn-link text-info"
																	>
																		download
																	</a>
																</div>
															</div>
														))}
												</div>
											</div>
											<div className="import col-12">
												<div className="import-details-body">
													<p>
														Início:
														{moment(blob.file.startDate).format(
															"DD/MM/YYYY HH:mm"
														)}
													</p>
													<p>
														Fim:
														{moment(blob.file.timestamp).format(
															"DD/MM/YYYY HH:mm"
														)}
													</p>
													{/* {blob.data.CurrentStatus ? blob.data.HeaderErrors && blob.data.HeaderErrors.map((item, i) => */}
													<p>{blob.data.CurrentStatus}</p>
													{/* ) : <p>Não foi possível importar o Arquivo</p>} */}
												</div>
											</div>
										</div>
									}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	};
	const skeleton = () => {
		const skele = [0, 1, 2, 3, 4];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="16" y="25" rx="3" ry="3" width="115" height="10" />
				<rect x="450" y="26" rx="3" ry="3" width="60" height="10" />
				<rect x="750" y="26" rx="3" ry="3" width="52" height="10" />
				<rect x="970" y="17" rx="3" ry="3" width="100" height="35" />
			</ContentLoader>
		));
	};
	return <>{loading ? skeleton() : matcherMoundList()}</>;
}

export default MatcherImportList;
