import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import InputMask from "../../../../components/InputMask";
import InputSelect from "../../../../components/InputSelect";
import InputSingleDate from "../../../../components/InputSingleDate";
import { i18n } from "../../../../translate/i18n";
import { useOrders } from "../../providers/OrdersContext";
import { formatDateUTC } from "../../../../infrastructure/utils/FormatMask";
import { FormControlLabel, Checkbox } from "@mui/material";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";

export default function PrepareShippingModal() {
	const {
		register,
		handleSubmit,
		reset,
		resetField,
		formState: { errors },
	} = useForm();
	const { order, handleChangeTrackings, setLoadingStatus, orderId } =
		useOrders();
	const [fillTrackingManually, setFillTrackingManually] = useState(false);
	const [currentStep, setCurrentStep] = useState("firstStep");
	const [editing, setEditing] = useState(false);
	const timeUnitListRef = useRef([
		i18n.t("orderDetails.General.Minute"),
		i18n.t("orderDetails.General.Hour"),
		i18n.t("orderDetails.General.CalendarDay"),
		i18n.t("orderDetails.General.BusinessDay"),
	]);

	useEffect(() => {
		const today = new Date().toLocaleDateString("pt-Br");
		const date = order?.ShippingData?.Shipment?.EstimatedDeliveryDate;
		const deliveryDate = date ? new Date(date).toLocaleDateString("pt-br") : today;

		reset({
			recipientName: order?.Buyer?.FullName,
			phoneNumber: order?.Buyer?.PhoneNumber?.replace(
				/(\d{2})(\d{5})(\d{4})/,
				"($1) $2-$3"
			),
			carrierName: "Correios",
			shippingType: order?.ShippingData?.Shipment?.ShippingReference,
			deliveryTime: order?.ShippingData?.Shipment?.DeliveryTime,
			deliveryTimeUnit: getDeliveryTime(
				order?.ShippingData?.Shipment?.DeliveryTimeUnit
			),
			deliveryDate: deliveryDate,

			invoiceRequired:
				order?.Invoices.length === 1 ? order?.Invoices[0]?.Number : "",
			address: order?.ShippingData?.DeliveryAddress?.Street,
			number: order?.ShippingData?.DeliveryAddress?.Number,
			neighborhood: order?.ShippingData?.DeliveryAddress?.Neighborhood,
			complement: order?.ShippingData?.DeliveryAddress?.Complement,
			zipCode: order?.ShippingData?.DeliveryAddress?.ZipCode?.replace(
				/(\d{5})(\d{3})/,
				"$1-$2"
			),
			city: order?.ShippingData?.DeliveryAddress?.City,
			state: order?.ShippingData?.DeliveryAddress?.State,
			country: order?.ShippingData?.DeliveryAddress?.Country,
		});
	}, [order]);

	const getDeliveryTime = (index) => {
		const typeArrowDict = {
			Minute: i18n.t("orderDetails.General.Minute"),
			Hour: i18n.t("orderDetails.General.Hour"),
			CalendarDay: i18n.t("orderDetails.General.CalendarDay"),
			BusinessDay: i18n.t("orderDetails.General.BusinessDay"),
		};
		return (
			typeArrowDict[index] || i18n.t("orderDetails.OrderFlowInfo.Uninformed")
		);
	};

	const getDeliveryReverseTime = (data) => {
		const typeArrowDict = {
			Minutos: "Minute",
			Horas: "Hour",
			"Dias corridos": "CalendarDay",
			"Dias úteis": "BusinessDay",
		};
		return (
			typeArrowDict[data] || i18n.t("orderDetails.OrderFlowInfo.Uninformed")
		);
	};

	const handleFormSubmit = (data) => {
		setEditing(false);

		if (currentStep === "firstStep") setCurrentStep("secondStep");
		else {
			setLoadingStatus(true);
			const formTracking = [
				{
					addresseeName: data?.recipientName,
					addresseePhoneNumber: data?.phoneNumber,
					addresseeEmail: null,
					documentPickup: null,
					invoiceNumber: data?.invoiceRequired,
					trackingCode: fillTrackingManually ? data?.trackingCode : null,
					trackingUrl: fillTrackingManually ? data?.trackingUrl : null,
					carrierName: fillTrackingManually ? "Envio manual" : data?.carrierName,
					shippingReference: data?.shippingType,
					deliveryDate: formatDateUTC(data?.deliveryDate),
					deliveryTime: parseInt(data?.deliveryTime),
					deliveryTimeUnit: getDeliveryReverseTime(data?.deliveryTimeUnit),
					deliveryAddress: {
						street: data.address,
						number: data.number,
						neighborhood: data.neighborhood,
						complement: data.complement,
						zipCode: data.zipCode,
						city: data.city,
						state: data.state,
						country: data.country,
					},
				},
			];
			const button = document.getElementById("prepareShippingModal");
			button.click();
			handleChangeTrackings(orderId, formTracking, 4);
		}
	};

	const handleEditLink = () => {
		setEditing(true);
	};

	const errorIconMessage = () => (
		<div className="neo-icons-required mr-2">
			<button type="button">
				<i className="material-icons">error_outline</i>
			</button>
		</div>
	);

	return (
		<div
			className="modal fade"
			id="prepareShippingModal"
			tabIndex="-1"
			aria-labelledby="prepareShippingModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog">
				<div
					className="modal-content tracking-modal scroll-list"
					style={{ padding: "32px 32px 0 32px" }}
				>
					<div className="modal-header tracking-modal-header d-flex flex-column p-2 m-0">
						<h5 className="modal-title mb-3">
							{i18n.t("orderDetails.TypeButtom.PrepareShipping")}
						</h5>
						<div
							className={`pb-3 steplist ${currentStep} position-relative d-flex justify-content-between align-items-center w-100`}
						>
							<div className="steplist-step d-flex flex-column align-items-center">
								<div className="rounded-circle steplist-step-circle d-flex align-items-center justify-content-center mb-1">
									{currentStep === "firstStep" ? (
										"1"
									) : (
										<i className="material-icons">checkmark</i>
									)}
								</div>
								<label className="steplist-step-label m-0">
									{i18n.t("orderDetails.Trackings.ShippingInformation")}
								</label>
							</div>
							<div className="steplist-step d-flex flex-column align-items-center">
								<div className="rounded-circle steplist-step-circle d-flex align-items-center justify-content-center mb-1">
									2
								</div>
								<label className="steplist-step-label m-0">
									{i18n.t("orderDetails.Trackings.ShippingMethod")}
								</label>
							</div>
						</div>
					</div>

					<div className="modal-body tracking-modal-body m-0 p-0">
						<form
							className="neo-form"
							onKeyPress={(e) => {
								e.key === "Enter" && e.preventDefault();
							}}
							onSubmit={handleSubmit(handleFormSubmit)}
						>
							{currentStep === "firstStep" && (
								<>
									<div className="d-flex justify-content-between">
										<h6 className="tracking-form-title">
											{i18n.t("orderDetails.General.Recipient")}
										</h6>
										{!editing && (
											<a
												className="tracking-edit-link"
												onClick={() => handleEditLink()}
											>
												{i18n.t("orderDetails.General.Change")}
											</a>
										)}
									</div>
									<InputMask
										required={true}
										errors={errors}
										register={register}
										isInputText={true}
										isEnabled={editing}
										inputName={"recipientName"}
									/>
									<InputMask
										required={true}
										errors={errors}
										register={register}
										isInputText={false}
										isEnabled={editing}
										inputName={"phoneNumber"}
									/>
									<h6
										className="tracking-form-title m-0"
										style={{ padding: "24px 0 8px 0" }}
									>
										{i18n.t("orderDetails.General.DeliveryAddress")}
									</h6>
									<InputMask
										required={true}
										errors={errors}
										register={register}
										isInputText={true}
										isEnabled={editing}
										inputName={"address"}
									/>
									<div className="d-flex m-0 p-0">
										<div className="w-100 m-0" style={{ paddingRight: "8px" }}>
											<InputMask
												required={true}
												errors={errors}
												register={register}
												isInputText={true}
												isEnabled={editing}
												inputName={"number"}
											/>
										</div>
										<div
											className="w-100 m-0"
											style={{ padding: "0 8px 0 8px" }}
										>
											<InputMask
												required={false}
												errors={errors}
												register={register}
												isInputText={true}
												isEnabled={editing}
												inputName={"complement"}
											/>
										</div>
										<div className="w-100 m-0" style={{ paddingLeft: "8px" }}>
											<InputMask
												required={true}
												errors={errors}
												register={register}
												isInputText={false}
												isEnabled={editing}
												inputName={"zipCode"}
											/>
										</div>
									</div>

									<div className="d-flex m-0 p-0">
										<div className="w-100 m-0" style={{ paddingRight: "8px" }}>
											<InputMask
												required={true}
												errors={errors}
												register={register}
												isInputText={true}
												isEnabled={editing}
												inputName={"neighborhood"}
											/>
										</div>
										<div className="w-100 m-0" style={{ paddingLeft: "8px" }}>
											<InputMask
												required={true}
												errors={errors}
												register={register}
												isInputText={true}
												isEnabled={editing}
												inputName={"city"}
											/>
										</div>
									</div>
								</>
							)}

							{currentStep === "secondStep" && (
								<>
									<div className="d-flex justify-content-between">
										<h6 className="tracking-form-title">
											{i18n.t("orderDetails.Trackings.ShippingMethod")}
										</h6>
										{!editing && (
											<a
												className="tracking-edit-link"
												onClick={() => handleEditLink()}
											>
												{i18n.t("orderDetails.General.Change")}
											</a>
										)}
									</div>
									<FormControlLabel
										id={"fillTrackingManuallyTitle"}
										label={
											<CustomTypography>
												{i18n
													.t("orderDetails.Trackings.FillTrackingManually")
													.toString()}
											</CustomTypography>
										}
										control={
											<Checkbox
												name={"fillTrackingManually"}
												checked={fillTrackingManually}
												onChange={(event) => setFillTrackingManually(event.target.checked)}
											/>
										}
									/>

									{fillTrackingManually && <InputMask
										required={true}
										errors={errors}
										register={register}
										isInputText={true}
										isEnabled={fillTrackingManually}
										inputName={"trackingCode"}
									/>}
									{fillTrackingManually && <InputMask
										required={true}
										errors={errors}
										register={register}
										isInputText={true}
										isEnabled={fillTrackingManually}
										inputName={"trackingUrl"}
									/>}

									<section className="m-0 py-2 w-100">
										<div className="d-flex align-items-end">
											<label
												className="input-form-label"
												htmlFor="invoiceRequired"
											>
												{i18n.t("orderDetails.Trackings.InvoiceRequired")}
											</label>
										</div>
										<div className="neo-form">
											<div className="neo-form-input">
												<select
													className={`select-arrow-lg ${
														order?.Invoices.length === 1 && !editing
															? "neo-input-disabled"
															: errors["invoiceRequired"]
															? "neo-input-invalid"
															: "neo-input-valid"
													}`}
													disabled={order?.Invoices.length === 1 && !editing}
													{...register("invoiceRequired", { required: true })}
												>
													{order?.Invoices.map((value) => (
														<option key={value.Key} value={value.Number}>
															{`${value.Number} (${i18n.t(
																"orderDetails.General.Invoice"
															)} - ${value.Amount.toLocaleString("pt-br", {
																style: "currency",
																currency: "BRL",
															})})`}
														</option>
													))}
												</select>
												<div className="neo-input-icons">
													{errors["invoiceRequired"] && errorIconMessage()}
												</div>
											</div>
										</div>
										{errors["invoiceRequired"] && (
											<small className="text-danger">
												<em>
													{i18n.t(`orderDetails.General.MandatoryInformation`)}
												</em>
											</small>
										)}
									</section>
									<InputMask
										required={true}
										errors={errors}
										register={register}
										isInputText={true}
										isEnabled={editing}
										inputName={"carrierName"}
									/>
									<InputMask
										required={true}
										errors={errors}
										register={register}
										isInputText={true}
										isEnabled={editing}
										inputName={"shippingType"}
									/>
									<div className="d-flex m-0 p-0">
										<div className="w-100 m-0" style={{ paddingRight: "8px" }}>
											<InputMask
												required={true}
												errors={errors}
												register={register}
												isInputText={true}
												isEnabled={editing}
												inputName={"deliveryTime"}
											/>
										</div>
										<div
											className="w-100 m-0"
											style={{ padding: "16px 0 0 8px" }}
										>
											<InputSelect
												required={true}
												errors={errors}
												register={register}
												isInputText={false}
												isEnabled={editing}
												inputName={"deliveryTimeUnit"}
												options={timeUnitListRef.current}
												size={"sm"}
											/>
										</div>
									</div>
									<InputSingleDate
										required={true}
										errors={errors}
										register={register}
										isInputText={false}
										isEnabled={editing}
										inputName={"deliveryDate"}
										resetField={resetField}
									/>
								</>
							)}
							<div
								className="modal-footer tracking-modal-footer d-flex justify-content-end"
								style={{ padding: "32px 0 0 0" }}
							>
								<button
									type="button"
									className="btn btn-light outline mr-2"
									data-dismiss="modal"
									style={{
										padding: "10px 20px",
										width: "190px",
										marginBottom: "32px",
										color: "#5A5E61",
										border: "1px solid #8D8D8D",
										backgroundColor: "transparent",
									}}
								>
									{i18n.t("orderDetails.TypeButtom.BtnCancel")}
								</button>
								{currentStep === "firstStep" && (
									<button
										type="submit"
										className="btn btn-success confirm-button"
										style={{
											marginBottom: "32px",
										}}
									>
										{i18n.t("orderDetails.TypeButtom.BtnProceed")}
									</button>
								)}
								{currentStep === "secondStep" && (
									<button
										type="submit"
										className="btn btn-success confirm-button"
										style={{
											marginBottom: "32px",
										}}
									>
										{i18n.t("orderDetails.TypeButtom.BtnConfirmShipping")}
									</button>
								)}
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
