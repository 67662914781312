import React, { Component, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
//import { API, APIV2 } from '../../API';
import Loading from "../../../../components/Loading";
import Modal from "../../../../components/Modal";

export default function RulesShipping(props) {
	const [loading, setLoading] = useState(true);

	const [conditionOne, setConditionOne] = useState(true);
	const [conditionTwo, setConditiontwo] = useState(true);
	const [conditionThree, setConditionThree] = useState(true);
	const [conditionFour, setConditionFour] = useState(true);

	const [view, setView] = useState(false);
	const [nameRole, setNameRole] = useState("");

	const [minValue, setMinValue] = useState(false);
	const [cep, setCep] = useState(false);
	const [distance, setDistance] = useState(false);
	const [store, setStore] = useState(false);
	const [codeSku, setCodeSku] = useState(false);
	const [freightValue, setFreightValue] = useState(false);
	const [deliveryTime, setDeliveryTime] = useState(false);
	const [handlingTime, setHandlingTime] = useState(false);
	const [valueFreight, setValueFreight] = useState(false);

	useEffect(() => {
		//console.log("Basic Info")
		setLoading(false);
	}, []);

	const handleRolesShipping = () => {
		console.log(nameRole);
		setView(false);
	};

	const handleNewFreight = () => {
		// console.log("Novo Frete")
	};

	const onChange = (e) => {
		console.log("Modal");
	};

	const saveInfos = async (step) => {
		props.save(step); // essas informação vão o new freight
	};

	const handleNewCondition = (condition) => {
		if (condition === "0") {
			setCep(true);
			setDistance(false);
		} else {
			setCep(false);
			setDistance(true);
		}
	};

	const renderScreen = () => {
		return (
			<div className="row m-2">
				<div className="w-100 px-3 mb-2">
					<h3 className="card-title my-1 " style={{ fontSize: "18px" }}>
						Regras para o Frete*
					</h3>
					<p
						className="m-0 p-0"
						style={{
							color: "#747E8B",
							fontSize: "12px",
							lineHeight: "16px",
							fontWeight: "normal",
							fontStyle: "normal",
						}}
					>
						Defina condições e ao menos uma ação para adicionar ao frete
						personalizado.
					</p>
					<button
						className="btn btn-success outline w-100 d-flex justify-content-center align-items-center my-3"
						onClick={() => setView(true)}
					>
						<i className="material-icons">add</i>&nbsp;Adicionar Nova Regra
					</button>

					<div
						className={
							view === true
								? "content-role-freight d-flex flex-md-wrap"
								: "d-none"
						}
					>
						{/* nova regra */}
						<div className=" box-left">
							<div className="w-100 mb-2">
								<h3 className="card-title" style={{ fontSize: "18px" }}>
									Nova Regra
								</h3>
							</div>
							<div className="form-group">
								<label htmlFor="nameRole">
									<strong>Nome da Regra</strong>
								</label>
								<div className="">
									<input
										type="text"
										className="form-control"
										id="nameRole"
										placeholder="Clique para preencher"
										name="nameRole"
										value={nameRole}
										onChange={(e) => setNameRole(e.target.value)}
										style={{ width: "85%" }}
									/>
								</div>
								<label
									style={{
										color: "#A1A3A5",
										fontWeight: "500",
										fontSize: "12px",
									}}
								>
									<em>Ex: Promoção de Vinho.</em>
								</label>
							</div>

							<div className="w-100 mb-4">
								<h3 className="card-title" style={{ fontSize: "18px" }}>
									Condições para a Regra
								</h3>
								<p
									className="m-0 p-0"
									style={{
										color: "#747E8B",
										fontSize: "12px",
										lineHeight: "16px",
										fontWeight: "normal",
										fontStyle: "normal",
									}}
								>
									Selecione ao menos uma condição para a regra.
								</p>
								<button
									className="btn btn-light outline w-100 d-flex justify-content-center align-items-center my-3"
									data-toggle="modal"
									data-target="#newRole"
								>
									<i className="material-icons">add</i>&nbsp;Adicionar Condição
								</button>
							</div>

							{/* condições inseridas */}
							<div>
								<div className={minValue === false ? "d-none" : ""}>
									<div className="new-condition-freight">
										<label>
											<strong>Valor mínimo de Compra/Carrinho</strong>
										</label>
										<button className="" onClick={() => setMinValue(false)}>
											<icon className="material-icons">close</icon>
										</button>
									</div>
									<div className="row">
										<div className="form-group col-6">
											<div className="">
												<input
													type="text"
													className="form-control"
													id="shippingWay"
													placeholder="R$ 0,00"
													name="shippingWay"
													value={""}
													onChange={() => "asda"}
												/>
											</div>
										</div>
									</div>
								</div>

								<div className={distance === false ? "d-none" : ""}>
									<div className="new-condition-freight">
										<label>
											<strong>Fretes por distância</strong>
										</label>
										<button className="" onClick={() => setDistance(false)}>
											<icon className="material-icons">close</icon>
										</button>
									</div>
									<div className="row">
										<div className="form-group col-6">
											<label htmlFor="shippingWay" style={{ fontSize: "12px" }}>
												<strong>De (km)</strong>
											</label>
											<div className="">
												<input
													type="text"
													className="form-control"
													id="shippingWay"
													placeholder="0 km"
													name="shippingWay"
													value={""}
													onChange={() => "asda"}
												/>
											</div>
										</div>
										<div className="form-group col-6">
											<label htmlFor="shippingWay" style={{ fontSize: "12px" }}>
												<strong>Até (km)</strong>
											</label>
											<div className="">
												<input
													type="text"
													className="form-control"
													id="shippingWay"
													placeholder="0 km"
													name="shippingWay"
													value={""}
													onChange={() => "asda"}
												/>
											</div>
										</div>
									</div>
								</div>

								<div className={codeSku === false ? "d-none" : ""}>
									<div className="new-condition-freight">
										<label>
											<strong>Código de SKU</strong>
										</label>
										<button className="" onClick={() => setCodeSku(false)}>
											<icon className="material-icons">close</icon>
										</button>
									</div>
									<div className="row">
										<div className="form-group col-6">
											<label htmlFor="shippingWay" style={{ fontSize: "12px" }}>
												<strong>Código</strong>
											</label>
											<div className="">
												<input
													type="text"
													className="form-control"
													id="shippingWay"
													placeholder="Clique para preencher"
													name="shippingWay"
													value={""}
													onChange={() => "asda"}
												/>
											</div>
										</div>
									</div>
								</div>

								<div className={store === false ? "d-none" : ""}>
									<div className="new-condition-freight">
										<div>
											<label>
												<strong>Estabelecimento Participante</strong>
											</label>
											<p
												style={{
													color: "#A1A3A5",
													fontWeight: "500",
													fontSize: "12px",
												}}
											>
												Informe o(s) estabelecimento(s) ao(s) qual(is) a regra
												será aplicada.
											</p>
										</div>
										<button className="" onClick={() => setStore(false)}>
											<icon className="material-icons">close</icon>
										</button>
									</div>
									<div className="row">
										<div className="form-group col-6">
											<label htmlFor="shippingWay" style={{ fontSize: "12px" }}>
												<strong>Estabelecimento</strong>
											</label>
											<div className="">
												<input
													type="text"
													className="form-control"
													id="shippingWay"
													placeholder="Clique para preencher"
													name="shippingWay"
													value={""}
													onChange={() => "asda"}
												/>
											</div>
										</div>
										<div className="form-group col-6">
											<label htmlFor="shippingWay" style={{ fontSize: "12px" }}>
												<strong>Aplicação da Regra</strong>
											</label>
											<div className="">
												<select
													type="text"
													className="form-control"
													id="shippingWay"
													placeholder="Clique para preencher"
													name="shippingWay"
													value={""}
													onChange={() => "asda"}
												>
													<option value>Teste</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="form-group d-flex flex-row justify-content-start my-5">
								<div className="d-flex justify-content-start">
									<button
										type="button"
										className="btn btn-success mr-2"
										onClick={() => handleRolesShipping()}
									>
										Adicionar
									</button>
									<button
										type="button"
										className="btn btn-light"
										style={{
											color: "#5A5E61",
											border: "1px solid #8D8D8D",
											backgroundColor: "transparent",
										}}
										onClick={() => (window.location.href = "/freight")}
									>
										Cancelar
									</button>
								</div>
							</div>
						</div>

						{/* ações para a regra */}
						<div className=" box-right">
							<div className="w-100 mb-2">
								<h3 className="card-title" style={{ fontSize: "18px" }}>
									Ações para a Regra
								</h3>
								<p
									className="m-0 p-0"
									style={{
										color: "#747E8B",
										fontSize: "12px",
										lineHeight: "16px",
										fontWeight: "normal",
										fontStyle: "normal",
									}}
								>
									Ações permitem com que você escolha informações fixas para seu
									Envio Personalizado.
								</p>
								<button
									className="btn btn-light outline w-100 d-flex justify-content-center align-items-center my-3"
									data-toggle="modal"
									data-target="#newAction"
								>
									<i className="material-icons">add</i>&nbsp;Adicionar Ação para
									a Regra
								</button>
							</div>
							<div>
								{/* valor do frete */}
								<div className={freightValue === false ? "d-none" : ""}>
									<div className="new-condition-freight">
										<label>
											<strong>Valor do Frete</strong>
										</label>
										<button className="" onClick={() => setFreightValue(false)}>
											<icon className="material-icons">close</icon>
										</button>
									</div>
									<div className="row">
										<div className="form-group col-6">
											<div className="">
												<input
													type="text"
													className="form-control"
													id="shippingWay"
													placeholder="R$ 0,00"
													name="shippingWay"
													value={""}
													onChange={() => "asda"}
												/>
											</div>
										</div>
									</div>
								</div>

								{/* tempo de entrega */}
								<div className={deliveryTime === false ? "d-none" : ""}>
									<div className="new-condition-freight">
										<div>
											<label>
												<strong>Tempo de Entrega</strong>
											</label>
										</div>
										<button className="" onClick={() => setDeliveryTime(false)}>
											<icon className="material-icons">close</icon>
										</button>
									</div>
									<div className="row">
										<div className="form-group col-12 mb-0">
											<label htmlFor="shippingWay" style={{ fontSize: "12px" }}>
												<strong>Insira o Tempo</strong>
											</label>
											<div className="d-flex">
												<input
													type="text"
													className="form-control"
													id="shippingWay"
													placeholder="00"
													name="shippingWay"
													value={""}
													onChange={() => "asda"}
												/>
												<select
													type="text"
													className="form-control"
													id="shippingWay"
													name="shippingWay"
													value={""}
													onChange={() => "asda"}
												>
													<option value>Minutos</option>
												</select>
											</div>
										</div>
										<div className="col-12">
											<label
												style={{
													color: "#A1A3A5",
													fontWeight: "500",
													fontSize: "12px",
												}}
											>
												<em>
													Informe o prazo de entrega padrão para o Conjunto de
													Regras.
												</em>
											</label>
										</div>
									</div>
								</div>

								{/* prazo adicional */}
								<div className={handlingTime === false ? "d-none" : ""}>
									<div className="new-condition-freight">
										<div>
											<label>
												<strong>Prazo Adicional (Handling Time)</strong>
											</label>
										</div>
										<button className="" onClick={() => setHandlingTime(false)}>
											<icon className="material-icons">close</icon>
										</button>
									</div>
									<div className="row">
										<div className="form-group col-12 mb-0">
											<div className="d-flex">
												<input
													type="text"
													className="form-control"
													id="shippingWay"
													placeholder="00"
													name="shippingWay"
													value={""}
													onChange={() => "asda"}
												/>
												<select
													type="text"
													className="form-control"
													id="shippingWay"
													name="shippingWay"
													value={""}
													onChange={() => "asda"}
												>
													<option value>Minutos</option>
												</select>
											</div>
										</div>
										<div className="col-12">
											<label
												style={{
													color: "#A1A3A5",
													fontWeight: "500",
													fontSize: "12px",
												}}
											>
												<em>
													Informe o prazo a mais que seu estabelecimento leva
													para preparar o produto para o envio.
												</em>
											</label>
										</div>
									</div>
								</div>

								{/* valor adicionado no frete */}
								<div className={valueFreight === false ? "d-none" : ""}>
									<div className="new-condition-freight">
										<div>
											<label>
												<strong>Valor Acrescido ao Frete</strong>
											</label>
										</div>
										<button className="" onClick={() => setValueFreight(false)}>
											<icon className="material-icons">close</icon>
										</button>
									</div>
									<div className="row">
										<div className="form-group col-12 mb-0">
											<label htmlFor="shippingWay" style={{ fontSize: "12px" }}>
												<strong>Insira o Tempo</strong>
											</label>
											<div className="d-flex">
												<input
													type="text"
													className="form-control"
													id="shippingWay"
													placeholder="R$ 0,00"
													name="shippingWay"
													value={""}
													onChange={() => "asda"}
												/>
												<select
													type="text"
													className="form-control"
													id="shippingWay"
													name="shippingWay"
													value={""}
													onChange={() => "asda"}
												>
													<option value>Reais</option>
												</select>
											</div>
										</div>
										<div className="col-12">
											<label
												style={{
													color: "#A1A3A5",
													fontWeight: "500",
													fontSize: "12px",
												}}
											>
												<em>
													Informe o valor adicional no total do frete (em reais
													ou porcentagem).
												</em>
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="form-group d-flex flex-row justify-content-between w-100 px-3">
					<div className="d-flex justify-content-start">
						<button
							type="button"
							className="btn btn-light mr-2"
							onClick={() => saveInfos(2)}
						>
							Voltar
						</button>
					</div>
					<div className="d-flex justify-content-end">
						<button
							type="button"
							className="btn btn-light mr-2"
							style={{
								color: "#5A5E61",
								border: "1px solid #8D8D8D",
								backgroundColor: "transparent",
							}}
							onClick={() => (window.location.href = "/freight")}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-success"
							onClick={() => saveInfos(4)}
						>
							Salvar e Continuar
						</button>
					</div>
				</div>
				{/* area modal's */}
				<div
					className="modal fade"
					id="newRole"
					tabIndex="-1"
					role="dialog"
					aria-labelledby="newRole"
					aria-hidden="true"
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<div className="d-flex flex-column">
									<h5
										className="modal-title"
										style={{ fontSize: "19px" }}
										id="newRole"
									>
										Adicionar Condição ou Conjunto de Condições.
									</h5>
									<label
										style={{
											color: "#A1A3A5",
											fontWeight: "500",
											fontSize: "14px",
										}}
									>
										Informe o valor adicional no total do frete (em reais ou
										porcentagem).
									</label>
								</div>

								<button
									type="button"
									className="close text-danger"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="custom-control custom-checkbox my-1 mr-sm-2">
									<input
										type="checkbox"
										checked={minValue}
										className="custom-control-input"
										id="minValue"
										onChange={(e) => setMinValue(e.target.checked)}
									/>
									<label className="custom-control-label" for="minValue">
										Inserir valor mínimo de compra
									</label>
								</div>
								<hr />
								<div className="custom-control custom-checkbox my-1 mr-sm-2">
									<input
										type="checkbox"
										checked={cep}
										className="custom-control-input"
										id="customControlInline0"
										onChange={() => handleNewCondition("0")}
									/>
									<label
										className="custom-control-label"
										for="customControlInline0"
									>
										Inserir condição de frete por CEP
									</label>
								</div>

								<div className="custom-control custom-checkbox my-1 mr-sm-2">
									<input
										type="checkbox"
										checked={distance}
										className="custom-control-input"
										id="customControlInline1"
										onChange={() => handleNewCondition("1")}
									/>
									<label
										className="custom-control-label"
										for="customControlInline1"
									>
										Inserir condição de frete por distância
									</label>
								</div>
								<hr />
								<div className="custom-control custom-checkbox my-1 mr-sm-2">
									<input
										type="checkbox"
										checked={codeSku}
										className="custom-control-input"
										id="codeSku"
										onChange={(e) => setCodeSku(e.target.checked)}
									/>
									<label className="custom-control-label" for="codeSku">
										Inserir condição de frete por SKU
									</label>
								</div>
								<hr />
								<div className="custom-control custom-checkbox my-1 mr-sm-2">
									<input
										type="checkbox"
										checked={store}
										className="custom-control-input"
										id="store"
										onChange={(e) => setStore(e.target.checked)}
									/>
									<label className="custom-control-label" for="store">
										Estabelecimento Participante
									</label>
								</div>
								<hr />
							</div>
							<div className="modal-footer text-end">
								<button
									type="button"
									className="btn btn-light col-5"
									data-dismiss="modal"
								>
									Cancelar
								</button>
								<button
									type="button"
									className="btn btn-success col-5"
									/* onClick={() => this.save()} */ data-dismiss="modal"
								>
									Adicionar(1)
								</button>
							</div>
						</div>
					</div>
				</div>

				{/* Modal's two */}
				<div
					className="modal fade"
					id="newAction"
					tabIndex="-1"
					role="dialog"
					aria-labelledby="newAction"
					aria-hidden="true"
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<div className="d-flex flex-column">
									<h5
										className="modal-title"
										style={{ fontSize: "19px" }}
										id="newAction"
									>
										Adicionar Ação.
									</h5>
								</div>
								<button
									type="button"
									className="close text-danger"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="custom-control custom-checkbox my-1 mr-sm-2">
									<input
										type="checkbox"
										checked={freightValue}
										className="custom-control-input"
										id="freightValue"
										onChange={(e) => setFreightValue(e.target.checked)}
									/>
									<label className="custom-control-label" for="freightValue">
										Inserir valor do Frete
									</label>
								</div>
								<hr />
								<div className="custom-control custom-checkbox my-1 mr-sm-2">
									<input
										type="checkbox"
										checked={deliveryTime}
										className="custom-control-input"
										id="deliveryTime"
										onChange={(e) => setDeliveryTime(e.target.checked)}
									/>
									<label className="custom-control-label" for="deliveryTime">
										Inserir Tempo de Entrega
									</label>
								</div>
								<hr />
								<div className="custom-control custom-checkbox my-1 mr-sm-2">
									<input
										type="checkbox"
										checked={handlingTime}
										className="custom-control-input"
										id="handlingTime"
										onChange={(e) => setHandlingTime(e.target.checked)}
									/>
									<label className="custom-control-label" for="handlingTime">
										Inserir Prazo Adicional
									</label>
								</div>
								<hr />
								<div className="custom-control custom-checkbox my-1 mr-sm-2">
									<input
										type="checkbox"
										checked={valueFreight}
										className="custom-control-input"
										id="valueFreight"
										onChange={(e) => setValueFreight(e.target.checked)}
									/>
									<label className="custom-control-label" for="valueFreight">
										Inserir acréscimo ao frete (R$ ou %)
									</label>
								</div>
								<hr />
							</div>
							<div className="modal-footer text-end">
								<button
									type="button"
									className="btn btn-light col-5"
									data-dismiss="modal"
								>
									Cancelar
								</button>
								<button
									type="button"
									className="btn btn-success col-5"
									/* onClick={() => this.save()} */ data-dismiss="modal"
								>
									Adicionar(1)
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	return <>{loading ? <Loading /> : renderScreen()}</>;
}
