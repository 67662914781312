import { Grid, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { i18n } from "../../../translate/i18n";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import TCStatusDialog, { tcStatusFilter } from "./TCStatusDialog";
import { TCFilters } from "./TroqueCommerceListService";
import { DateType } from "../../../domain/enums/DateType";
import { getSinceFilter } from "../../../infrastructure/utils/Dates";
import { paletteObject } from "../../../theme/foundations/palette";
import SearchInputClose from "../../../components/SearchInputClose";

type Props = {
	tcFilters: TCFilters;
	isLoading: boolean;
	updateTCWithFilters: (filters: TCFilters) => void;
};

export default function TCListFilters({
	tcFilters,
	isLoading,
	updateTCWithFilters,
}: Props) {
	const isCustomBreakpoint = useMediaQuery(`(max-width:992px)`);
	const [isFilterDialogOpen, setIsFilterDialogOpen] = useState<boolean>(false);
	const [statusFilterButtons, setStatusFilterButtons] = useState<
		tcStatusFilter[]
	>([]);

	const updateFilters = (filteredStatusList: tcStatusFilter[]): void => {
		const filteredStatusString = separateStatusesAsString(filteredStatusList);
		const updatedFilters: TCFilters = {
			...tcFilters,
			page: 1,
			status: filteredStatusString,
		};
		updateTCWithFilters(updatedFilters);
	};

	const changeDate = (
		dateType: Exclude<DateType, DateType.CustomDate> | null
	): void => {
		if (dateType === null) {
			const updatedFilters: TCFilters = {
				...tcFilters,
				page: 1,
				dateType: null,
				since_updated_at: null,
			};
			updateTCWithFilters(updatedFilters);
			return;
		}
		const since_updated_at = getSinceFilter(dateType);
		const updatedFilters: TCFilters = {
			...tcFilters,
			page: 1,
			dateType: dateType,
			since_updated_at: since_updated_at,
		};
		updateTCWithFilters(updatedFilters);
	};

	const separateStatusesAsString = (statusList: tcStatusFilter[]): string => {
		if (statusList.length <= 0) return "";
		const onlyStatusIds: string[] = statusList.map((status) => status.id);
		const statusAsString: string = onlyStatusIds.join(",");
		return statusAsString;
	};

	const filterStatuses = (
		statusList: tcStatusFilter[],
		callUpdateRequest: boolean = false
	): void => {
		const checkedStatusList = statusList.filter((status) => status.isChecked);
		setStatusFilterButtons(checkedStatusList);
		if (callUpdateRequest) updateFilters(checkedStatusList);
	};

	const clearOneStatusFilter = (statusBtn: tcStatusFilter): void => {
		const newFilteredStatuses = statusFilterButtons.filter(
			(checkedStatus) => checkedStatus.id !== statusBtn.id
		);
		setStatusFilterButtons(newFilteredStatuses);
		updateFilters(newFilteredStatuses);
	};

	const clearAllFilters = (): void => {
		setStatusFilterButtons([]);
		const resetFilters: TCFilters = {
			page: 1,
			status: null,
			since_updated_at: null,
			dateType: null,
			ecommerce_number: "",
		};
		updateTCWithFilters(resetFilters);
	};

	const handleSearch = (search: string): void => {
		const updatedFilters: TCFilters = {
			...tcFilters,
			page: 1,
			ecommerce_number: search,
		};
		updateTCWithFilters(updatedFilters);
	};

	const dateFilterButton = (
		dateType: Exclude<DateType, DateType.CustomDate>
	): JSX.Element => {
		const className =
			tcFilters.dateType === dateType ? "active-data-btn" : "btn";
		return (
			<button
				className={className}
				onClick={() => changeDate(dateType)}
				disabled={isLoading}
			>
				{isLoading && tcFilters.dateType === dateType ? (
					<i className="fas fa-spinner fa-spin"></i>
				) : (
					<span>{i18n.t(`tcList.${dateType}`).toString()}</span>
				)}
			</button>
		);
	};

	return (
		<>
			<TCStatusDialog
				isOpen={isFilterDialogOpen}
				setIsOpen={setIsFilterDialogOpen}
				tcFilters={tcFilters}
				filterStatuses={filterStatuses}
			/>
			<Grid
				container
				spacing={2}
				className="filter-orders"
				alignItems={"flex-end"}
			>
				<Grid item xs={12} md={isCustomBreakpoint ? 12 : 6}>
					<SearchInputClose
						value={tcFilters.ecommerce_number ?? ""}
						onChange={handleSearch}
						placeholder={"Código do pedido"}
						loading={isLoading}
					/>
				</Grid>
				<Grid item xs={12} md={isCustomBreakpoint ? 12 : 6}>
					<div className="filter-date">
						<CustomTypography variant="small" isBold marginBottom={1}>
							{i18n.t("tcList.SelectedPeriod").toString()}
						</CustomTypography>
						<div className="group-btn">
							{dateFilterButton(DateType.Today)}
							{dateFilterButton(DateType.SevenDays)}
							{dateFilterButton(DateType.ThirtyDays)}
							<button
								className={
									tcFilters.dateType === null ? "active-data-btn" : "btn"
								}
								onClick={() => changeDate(null)}
								disabled={isLoading}
								style={{
									borderRight: `2px solid ${paletteObject.grey20.main}`,
									borderLeftColor: `2px solid ${paletteObject.grey20.main}`,
								}}
							>
								{isLoading && tcFilters.dateType === null ? (
									<i className="fas fa-spinner fa-spin"></i>
								) : (
									<span>Todos</span>
								)}
							</button>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} md={isCustomBreakpoint ? 12 : 6}>
					<div className="d-flex">
						<button
							className="btn btn-outline d-flex justify-content-center mr-1"
							style={{
								background: "transparent",
								border: "1px solid #5A5E61",
								color: "#5A5E61",
								width: "190px",
								height: "40px",
							}}
							type="button"
							onClick={() => setIsFilterDialogOpen(true)}
						>
							<i
								className="material-icons-outlined"
								style={{ marginRight: "8px" }}
							>
								filter_alt
							</i>
							{i18n
								.t("tcList.FilterBtn")
								.replace(
									"%X",
									statusFilterButtons.length === null
										? "0"
										: `${statusFilterButtons.length}`
								)}
						</button>
						<button
							className="btn btn-icon d-flex justify-content-center mx-1"
							onClick={clearAllFilters}
							style={{
								border: "1px solid #747E8B",
								background: "#747E8B",
								color: "#FFFFFF",
								width: "190px",
								height: "40px",
							}}
						>
							<i className="material-icons" style={{ marginRight: "8px" }}>
								delete_outline
							</i>
							{i18n.t("tcList.ClearFilters").toString()}
						</button>
					</div>
				</Grid>
				{statusFilterButtons.length > 0 && (
					<Grid item xs={12}>
						<CustomTypography variant="small" isBold marginBottom={1}>
							{i18n.t("tcList.AddedFilters").toString()}
						</CustomTypography>
						<div className="d-flex flex-row flex-wrap">
							{statusFilterButtons.map((checkedStatus) => (
								<button
									key={checkedStatus.id}
									className="btn-filtered mt-2"
									style={
										isLoading
											? { cursor: "not-allowed" }
											: { cursor: "pointer" }
									}
									disabled={isLoading}
									onClick={() => clearOneStatusFilter(checkedStatus)}
								>
									{checkedStatus.label} <i className="material-icons">close</i>
								</button>
							))}
						</div>
					</Grid>
				)}
			</Grid>
		</>
	);
}
