import React, { Component, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { API } from "../../../API";
import Loading from "../../../components/Loading";
import DragDropList from "../../../components/DragDropList";
import XLSX from "xlsx";
import DownloadExcel from "../../../components/DownloadExcel";
import { ToastContainer, toast } from "react-toastify";
const qs = require("querystring");

function Faq(props) {
	const [loading, setLoading] = useState(true);
	const [items, setItems] = useState([]);
	const [updatedList, setUpdatedList] = useState(false);

	useEffect(() => {
		getFaq();
	}, []);

	useEffect(() => {
		if (items.length > 0) {
			submitFaq();
		}
	}, [updatedList]);

	const getFaq = () => {
		API.get("api/faq/")
			.then(async (response) => {
				setItems(response.data);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const newQuestion = () => {
		var newItem = {
			question: "Nova pergunta",
			answer: "Nova Pergunta",
			order: items.length + 1,
			createDate: "2020-01-20T13:59:45.227",
			lastUpdate: "2020-01-20T13:59:45.227",
		};
		setItems([newItem, ...items]);
	};

	const changeQuestion = (question, answer, order, createDate, lastUpdate) => {
		for (var i in items) {
			if (items[i].order === order) {
				items[i].question = question;
				items[i].answer = answer;
				items[i].createDate = createDate;
				items[i].lastUpdate = lastUpdate;
			}
		}
	};

	const submitFaq = () => {
		setLoading(true);
		for (var i in items) {
			if (items[i].order !== i) {
				items[i].order = +i;
			}
		}

		API.post("api/faq", items)
			.then(async (response) => {
				getFaq();
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const deleteFaq = (id, order) => {
		setLoading(true);

		if (!id) {
			const itemsAfterDelete = items
				.filter((item) => item.order !== order)
				.map((item, index) => ({ ...item, order: index }));

			setItems(itemsAfterDelete);
			setLoading(false);
			return;
		}

		API.delete("api/faq?faqId=" + id)
			.then((async) => {
				setLoading(false);
				setItems((items) => items.filter((item) => item.id !== id));
				toast.success("Pergunta removida com sucesso");
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const importCsv = (e) => {
		let file = e.target.files[0];

		const reader = new FileReader();
		reader.onload = async (evt) => {
			// evt = on_file_select event
			/* Parse data */
			const bstr = evt.target.result;
			const wb = XLSX.read(bstr, { type: "binary" });
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			/* Convert array of arrays */
			const data = XLSX.utils.sheet_to_json(ws);
			/* Update state */

			handleForce(data);
		};
		reader.readAsBinaryString(file);
	};

	const handleForce = async (data) => {
		setLoading(true);
		let newArr = [...items]; // copying the old datas array
		await data.map(async (q, index) => {
			if (items.every((item) => item.question !== q.question)) {
				await setItems((items) => [...items, q]);
			} else if (items.some((item) => item.question === q.question)) {
				newArr[items.findIndex((r) => r.question === q.question)].answer =
					q.answer; // replace e.target.value with whatever you want to change it to
				setItems(newArr);
			}
		});
		setUpdatedList(!updatedList);
		setLoading(false);
	};

	const skeleton = () => {
		const skele = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={1100}
				height={100}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="16" y="10" rx="3" ry="3" width="300" height="20" />
				<rect x="16" y="45" rx="3" ry="3" width="600" height="10" />
				<rect x="850" y="17" rx="3" ry="3" width="200" height="60" />
			</ContentLoader>
		));
	};

	const renderScreen = () => {
		return (
			<div className="card-body pt-0">
				{loading ? (
					skeleton()
				) : (
					<DragDropList
						items={items}
						setItem={setItems}
						changeQuestion={changeQuestion}
						deleteFaq={deleteFaq}
					/>
				)}
				<button onClick={() => newQuestion()} className="new-question">
					Adicionar nova pergunta
				</button>
				<div className="d-flex justify-content-between mt-3">
					<DownloadExcel
						data={items}
						button={
							<button className="btn btn-light btn-icon-l outline">
								<i className="material-icons">vertical_align_bottom</i>
								Fazer download do arquivo
							</button>
						}
					/>
					<button
						className="btn btn-success"
						style={{ width: "150px" }}
						onClick={() => submitFaq()}
					>
						Salvar FAQ
					</button>
				</div>
			</div>
		);
	};

	return (
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center">
				<h4 className="card-title">Perguntas frequentes</h4>
				<div className="card-actions">
					{!loading ? (
						<label className={"btn btn-info btn-icon-l light mr-2 mb-0"}>
							<input type="file" onChange={importCsv} className="d-none" />
							<i className="material-icons">arrow_upward</i>
							Enviar arquivo
						</label>
					) : (
						<button
							className={"btn btn-info btn-icon-l light mr-2 mb-0"}
							disabled
						>
							<i className="material-icons">arrow_upward</i>
							Enviar arquivo
						</button>
					)}
					<button
						onClick={() => newQuestion()}
						className="btn btn-grey-80"
						style={{ width: "150px" }}
					>
						Nova pergunta
					</button>
				</div>
			</div>
			<div className="card-content">{renderScreen()}</div>
		</div>
	);
}

export default Faq;
