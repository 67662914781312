import React from "react";
import { i18n } from "../translate/i18n";

export default function InputSelect({
	inputName,
	errors,
	register,
	required,
	isEnabled,
	options,
	size,
}) {
	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	const errorIconMessage = () => (
		<div className="neo-icons-required mr-2">
			<button type="button">
				<i className="material-icons">error_outline</i>
			</button>
		</div>
	);

	return (
		<section className="m-0 py-2 w-100">
			<div className="d-flex align-items-end">
				<label className="input-form-label" htmlFor={inputName}>
					{i18n.t(`orderDetails.General.${capitalizeFirstLetter(inputName)}`)}
				</label>
			</div>
			<div className="neo-form">
				<div className="neo-form-input">
					<select
						className={`select-arrow-${size} ${
							!isEnabled
								? "neo-input-disabled"
								: errors[inputName]
								? "neo-input-invalid"
								: "neo-input-valid"
						}`}
						disabled={!isEnabled}
						{...register(inputName, { required: required })}
					>
						{options.map((value, index) => (
							<option key={index} value={value}>
								{value}
							</option>
						))}
					</select>
					<div className="neo-input-icons">
						{errors[inputName] && errorIconMessage()}
					</div>
				</div>
			</div>
			{errors[inputName] && (
				<small className="text-danger">
					<em>{i18n.t(`orderDetails.General.MandatoryInformation`)}</em>
				</small>
			)}
		</section>
	);
}
