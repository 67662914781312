import { RetailerConfiguration } from "../../domain/dtos/retailerManagement/retailerConfigurations/RetailerConfiguration";
import { ReturnExchangedSystem } from "../../domain/dtos/retailerManagement/retailerConfigurations/ReturnExchangedSystem";
import { RolesAccess } from "../../domain/enums/RolesAccess";

export const tCRoleAccess = (): RolesAccess[] => {
	const unparsedConfigurations = localStorage.getItem("configurations");
	if (!unparsedConfigurations) return [];

	const configurations: RetailerConfiguration = JSON.parse(
		unparsedConfigurations
	)[0];
	const { returnExchangedSystem } = configurations;

	if (returnExchangedSystem !== ReturnExchangedSystem.TroqueCommerce) return [];
	return [
		RolesAccess.Retailer,
		RolesAccess.Seller,
		RolesAccess.Marketplace,
		RolesAccess.MarketplaceRetailer,
	];
};
