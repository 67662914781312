import React from "react";
import ButtonStatus from "../ButtonStatus";
import ArrowDownDashed from "../../../../assets/floworder-arrow/ArrowDownDashed.svg";
import ArrowDownSuccess from "../../../../assets/floworder-arrow/ArrowDownSuccess.svg";
import ArrowDownSolid from "../../../../assets/floworder-arrow/ArrowDownSolid.svg";
import ArrowDownWarning from "../../../../assets/floworder-arrow/ArrowDownWarning.svg";
import ArrowDownInfo from "../../../../assets/floworder-arrow/ArrowDownInfo.svg";
import ArrowDownWhite from "../../../../assets/floworder-arrow/ArrowDownWhite.svg";
import ArrowStraightSmDashed from "../../../../assets/floworder-arrow/ArrowStraightSmDashed.svg";
import ArrowStraightSmSolid from "../../../../assets/floworder-arrow/ArrowStraightSmSolid.svg";
import ArrowStraightSmDanger from "../../../../assets/floworder-arrow/ArrowStraightSmDanger.svg";
import { useOrders } from "../../providers/OrdersContext";

export default function CreateFinalizedStatus({
	indexColumn,
	getTypeIcons,
	getTitleButton,
	getTypeInfo,
	getButtonStyle,
	getTypeArrow,
	getProcessOrder,
	getRetryProcessOrder,
	getFailedProcessOrder,
}) {
	const { flowStatus, loadingStatus, loadingCancel, isCancellation } =
		useOrders();
	const finalizedStatus = [0, 1, 2];

	const position = flowStatus?.enqueueOrderPosition;
	const lastPosition = parseInt(flowStatus?.lastOrderPosition);
	const lastProcessedStatus = lastPosition + 1;
	const stepCancellation = position > 100 && lastPosition === 6;
	const nextStep = flowStatus?.hasNextActionAvailable && position === 6;
	const finalized =
		flowStatus?.isFinished && (position === 8 || position === 7);
	const finalizedWithCancellation = flowStatus?.isFinished && position > 100;
	const finalizeCancellation =
		!flowStatus?.isProcessing && flowStatus?.isSuccessfull && position > 100;
	const canBeCancel = flowStatus?.canBeCancel;
	const loading =
		loadingStatus && !loadingCancel && position >= 6 && position < 100;
	const loadCanceled = loadingCancel && position === 112;
	const loadingRetry =
		loadingStatus && !isCancellation && lastPosition === 5 && position === 100;
	const errorCancel = position === 100 && flowStatus.status === "Canceled";
	const loadErrorCancel = loadingStatus && errorCancel;

	const getDateCanceled = (lastPosition) => {
		if (lastPosition === 1 && position > 100) return 2;
		else if (lastPosition === 2 && position > 100) return 3;
		else if (lastPosition === 3 && position > 100) return 4;
		else if (position === 8) return 7;
		else return 6;
	};

	const getStepCompleted = (position, lastPosition, lastProcessedStatus) => {
		if (position > 6 && position < 100) return true;
		else if (position > 100 && lastPosition > 6) return true;
		else if (position === 100 && lastProcessedStatus > 6) return true;
		else return false;
	};

	const stepCompleted = getStepCompleted(
		position,
		lastPosition,
		lastProcessedStatus
	);

	const processing = getProcessOrder(position === 6);

	const processingRetry = getRetryProcessOrder(position === 6);

	const failedProcess = getFailedProcessOrder(
		position === 6 || position === 112 || position === 113,
		lastProcessedStatus === 6 || (position > 100 && !flowStatus?.isSuccessfull)
	);

	const failedProcessByRow =
		failedProcess && position < 100 && position !== 7
			? 0
			: failedProcess && position === 7
			? 1
			: failedProcess && position > 100
			? 2
			: -1;

	const lastDateUpdate =
		flowStatus?.actions[getDateCanceled(lastPosition)]?.LastUpdate;

	const handleIcons = (index) => {
		switch (index) {
			case 0:
				return getTypeIcons("SwapHoriz");
			case 1:
				if (processing || processingRetry || loading)
					return getTypeIcons("WatchLater");
				else if (failedProcess) return getTypeIcons("Refresh");
				else return getTypeIcons("CheckCircle");
			case 2:
				if (loadingStatus && failedProcessByRow === index)
					return getTypeIcons("WatchLater");
				else if (failedProcessByRow === index && !flowStatus?.isSuccessfull)
					return getTypeIcons("Refresh");
				else return getTypeIcons("Cancel");
			default:
				break;
		}
	};

	const handleTitles = (index) => {
		switch (index) {
			case 0:
				//if (position === 7) return getTitleButton("ReturningGoodsCompleted");
				return getTitleButton("WaitingReturnGoodsPeriod"); //devoloção solicitada // troca solicitada
			case 1:
				if (loading) return getTitleButton("Processing");
				else if (failedProcess) return getTitleButton("Reprocess");
				else if (finalized) return getTitleButton("FinalizedCompleted");
				else return getTitleButton("FinalizedAction");
			case 2:
				if (loadCanceled) return getTitleButton("Processing");
				else if (failedProcessByRow === index && !flowStatus?.isSuccessfull)
					return getTitleButton("Reprocess");
				else if (loadingStatus && failedProcessByRow === index)
					return getTitleButton("CancelRetry");
				if (finalizedWithCancellation)
					return getTitleButton("CanceledCompleted");
				else return getTitleButton("CancelAction");
			default:
				break;
		}
	};

	const handleInfo = (index) => {
		const process = processing || processingRetry || nextStep;

		if (position > 100) {
			switch (index) {
				case 0:
				case 1:
					return getTypeInfo("NotStarted");
				case 2:
					if (loadCanceled) return getTypeInfo("WaitAMinute");
					else if (failedProcessByRow === index && !flowStatus?.isSuccessfull)
						return getTypeInfo("TryAgain");
					else if (position === 112) return getTypeInfo("Waiting");
					else if (loadingStatus && failedProcessByRow === index)
						return getTypeInfo("ProcessingRetry");
					else if (position > 112) return getTypeInfo(lastDateUpdate);
					else return getTypeInfo("NotStarted");
				default:
					break;
			}
		} else return showCorrectInfo(index, process);
	};

	const showCorrectInfo = (index, process) => {
		switch (index) {
			case 0:
				if (finalized) return getTypeInfo(lastDateUpdate);
				else if (process || loadingRetry) return flowStatus?.integrator;
				else if (stepCancellation) return getTypeInfo("InCancellation");
				else if (failedProcess) return getTypeInfo("IntegrationFailure");
				else return getTypeInfo("NotStarted");
			case 1:
				if (loading) return getTypeInfo("WaitAMinute");
				else if (nextStep) return getTypeInfo("ClickToConfirm");
				else if (stepCompleted) return getTypeInfo(lastDateUpdate);
				else if (processingRetry || loadingRetry)
					return getTypeInfo("ProcessingRetry");
				else if (failedProcess) return getTypeInfo("TryAgain");
				else if (processing) return getTypeInfo("Processing");
				else return getTypeInfo("NotStarted");
			case 2:
				if (loadErrorCancel || loadErrorCancel)
					return getTypeInfo("WaitAMinute");
				else if (errorCancel) return getTypeInfo("TryAgain");
				else return getTypeInfo("NotStarted");
			default:
				break;
		}
	};

	const handleStyles = (index) => {
		const process = processing || processingRetry;
		const activation = failedProcess || nextStep;

		if (position > 100) {
			switch (index) {
				case 0:
				case 1:
					return getButtonStyle("Empty");
				case 2:
					if (loadCanceled || (loadingStatus && failedProcessByRow === index))
						return getButtonStyle("RetryOutline");
					else if (finalizedWithCancellation) return getButtonStyle("Canceled");
					else if (finalizeCancellation) return getButtonStyle("Danger");
					else if (stepCancellation) return getButtonStyle("DangerOutline");
					else if (failedProcessByRow === index)
						return getButtonStyle("Warning");
					else return getButtonStyle("Empty");
				default:
					break;
			}
		} else return showCorrectStyle(index, process, activation);
	};

	const showCorrectStyle = (index, process, activation) => {
		switch (index) {
			case 0:
				//if (position === 7) return getButtonStyle("RetryOutline");
				if (process || loading || loadingRetry)
					return getButtonStyle("SuccessOutline");
				else if (activation) return getButtonStyle("WarningOutline");
				else if (stepCompleted) return getButtonStyle("Completed");
				else return getButtonStyle("Empty");
			case 1:
				if (process || loading || loadingRetry)
					return getButtonStyle("RetryOutline");
				else if (nextStep) return getButtonStyle("Success");
				else if (failedProcessByRow === index) return getButtonStyle("Warning");
				else if (stepCompleted) return getButtonStyle("Completed");
				else return getButtonStyle("Empty");
			case 2:
				if (loadCanceled || loadErrorCancel)
					return getButtonStyle("RetryOutline");
				else if (failedProcessByRow === index) return getButtonStyle("Warning");
				else if (errorCancel) return getButtonStyle("Warning");
				else return getButtonStyle("Empty");
			default:
				break;
		}
	};

	const handleIntegration = (index) => {
		const failed = "failed";
		const retry = "retry";
		const process = "process";
		const empty = "empty";
		switch (index) {
			case 0:
				if (loading || loadingRetry) return empty;
				else if (failedProcessByRow === index) return failed;
				else return empty;
			case 1:
				if (failedProcessByRow === index) return failed;
				else if (processing || loading || loadingRetry) return process;
				else if (nextStep) return empty;
				else if (processingRetry) return retry;
				else return empty;
			case 2:
				if (loadingStatus && failedProcessByRow === index) return process;
				else if (failedProcessByRow === index && !flowStatus?.isSuccessfull)
					return failed;
				else if (loadCanceled || loadErrorCancel) return process;
				else return empty;
			default:
				break;
		}
	};

	const handleDisabledButton = (index) => {
		const cancel = (canBeCancel && position === 112) || errorCancel;
		const clickConfirm = nextStep || failedProcess;

		if (loadingStatus || loadingCancel) return true;
		else {
			if (index === 0) return true;
			if (index === 1) return clickConfirm ? false : true;
			if (index === 2)
				return cancel ||
					(failedProcessByRow === index && !flowStatus?.isSuccessfull)
					? false
					: true;
		}
	};

	// TODO
	// Fix arrow color
	const handleArrowStepMain = (index) => {
		const process = processing || processingRetry;

		switch (index) {
			case 0:
				if (process || loading || loadingRetry)
					return getTypeArrow(ArrowDownInfo, "arrow-down");
				else if (nextStep) return getTypeArrow(ArrowDownSuccess, "arrow-down");
				else if (failedProcessByRow === index)
					return getTypeArrow(ArrowDownWarning, "arrow-down");
				else if (stepCompleted)
					return getTypeArrow(ArrowDownSolid, "arrow-down");
				else return getTypeArrow(ArrowDownDashed, "arrow-down");
			case 1:
				return getTypeArrow(ArrowDownWhite, "arrow-down");
			case 2:
				if (position === 112)
					return getTypeArrow(ArrowStraightSmDanger, "arrow-straight-sm");
				else if (position > 112)
					return getTypeArrow(ArrowStraightSmSolid, "arrow-straight-sm");
				else return getTypeArrow(ArrowStraightSmDashed, "arrow-straight-sm");
			default:
				break;
		}
	};

	const createStatusFinalized = () => {
		return finalizedStatus.map((index) => (
			<>
				<ButtonStatus
					key={index}
					name={
						index === 2 && indexColumn === 3
							? "#orderFlowCancel"
							: failedProcess || errorCancel
							? ""
							: "#orderFlow"
					}
					icons={handleIcons(index)}
					title={handleTitles(index)}
					info={handleInfo(index)}
					style={handleStyles(index)}
					integration={handleIntegration(index)}
					disabled={handleDisabledButton(index)}
					position={position}
					isError={failedProcess}
				/>

				{handleArrowStepMain(index)}
			</>
		));
	};

	return createStatusFinalized();
}
