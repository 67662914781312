import React from "react";
import { Link } from "react-router-dom";

export default function LastOrdersHeader() {
	return (
		<div className="card-header d-flex justify-content-between align-items-center">
			<h4 className="card-title" style={{ fontSize: "18px" }}>
				Últimas vendas
			</h4>
			<div className="card-actions">
				<Link to="/orders/1" className="btn btn-new-width btn-grey-80">
					Ver todos
				</Link>
			</div>
		</div>
	);
}
