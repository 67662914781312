import { useEffect, useState } from "react";
import { cpCommerceManagementApi } from "../../infrastructure/api/CPCommerceManagement";
import { buildURLWithParams } from "../../infrastructure/utils/BuildURLWithParams";
import { i18n } from "../../translate/i18n";
import NewToastComponent from "../../components/NewToastComponent";
import { DateType } from "../../domain/enums/DateType";
import { getSinceFilter } from "../../infrastructure/utils/Dates";
import { smCommerceManagementApi } from "../../infrastructure/api/SMCommerceManagement";
import { OrdersDto } from "../../domain/dtos/smOrders/OrdersDto";
import { CpOrdersDto } from "../../domain/dtos/cpCommerceManagement/CpOrdersDto";

export default function DashboardOrderService() {
	const [isLoadingOrders, setIsLoadingOrders] = useState<boolean>(true);
	const [orders, setOrders] = useState<CpOrdersDto | OrdersDto | null>(null);
	const [currentDateType, setCurrentDateType] = useState<
		Exclude<DateType, DateType.CustomDate>
	>(DateType.FifteenDays);
	const isStateMachine: boolean =
		localStorage.getItem("useStateMachine") === "true";
	const cpFields =
		"id,createDate,fullName,totalAmount,externalId,friendlyCode,sellerOrderId,status,freight,items,voucher,buyer";
	const fiveMinutesAsMs = 300000;

	useEffect(() => {
		getOrders(currentDateType);
		let interval = setInterval(() => {
			getOrders(currentDateType);
		}, fiveMinutesAsMs);

		return () => {
			clearInterval(interval);
		};
	}, [currentDateType]);

	const getOrders = async (
		dateType: Exclude<DateType, DateType.CustomDate>
	) => {
		setIsLoadingOrders(true);
		try {
			const since = getSinceFilter(dateType);
			const params = {
				page: 1,
				perPage: 2000,
				since: since,
				until: new Date().toISOString(),
				role: localStorage.getItem("role"),
				fields: cpFields,
			};
			const stringParams = buildURLWithParams(params);

			const data = isStateMachine
				? await smCommerceManagementApi.getOrders(stringParams)
				: await cpCommerceManagementApi.getOrders(stringParams);
			setIsLoadingOrders(false);
			setOrders(data);
		} catch {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			setIsLoadingOrders(false);
		}
	};

	return {
		isStateMachine,
		isLoadingOrders,
		orders,
		currentDateType,
		setCurrentDateType,
		getOrders,
	};
}
