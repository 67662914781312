import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { i18n } from "../../../../translate/i18n";
import { DateType } from "../../../../domain/enums/DateType";
import { SummaryStatusEnum } from "./SummaryStatusEnum";
import { SelectChangeEvent, useMediaQuery } from "@mui/material";
import { OrdersDto } from "../../../../domain/dtos/smOrders/OrdersDto";
import { CpOrdersDto } from "../../../../domain/dtos/cpCommerceManagement/CpOrdersDto";
import { getSinceFilter } from "../../../../infrastructure/utils/Dates";
import { buildURLWithParams } from "../../../../infrastructure/utils/BuildURLWithParams";
import { OrderDto } from "../../../../domain/dtos/smOrders/OrderDto";
import { getStatusListDict } from "./utils/getStatusListDict";
import { mapStatusToGeneric } from "./utils/mapStatusToGeneric";

type OrderPeriodOptions = { label: string; value: DateType };
type StatusCounter = Record<keyof typeof SummaryStatusEnum, number>;

export default function OrderSummaryService(
	orders: CpOrdersDto | OrdersDto | null,
	isStateMachine: boolean,
	currentDateType: Exclude<DateType, DateType.CustomDate>,
	setCurrentDateType: Dispatch<
		SetStateAction<Exclude<DateType, DateType.CustomDate>>
	>
) {
	const isCustomMediumBreakpoint = useMediaQuery(`(max-width:992px)`);
	const defaultStatusCounter = {
		WaitingPayment: 0,
		WaitingSellerApproval: 0,
		WaitingSeparation: 0,
		WaitingInvoicing: 0,
		WaitingDispatch: 0,
		WaitingDelivery: 0,
		WaitingWithdrawal: 0,
		Finalized: 0,
		Canceled: 0,
		WithError: 0,
	};
	const [statusCounter, setStatusCounter] =
		useState<StatusCounter>(defaultStatusCounter);
	const [aditionalLinkPath, setAditionalLinkPath] = useState<string>("");
	const statusListDict = getStatusListDict(isStateMachine);

	const orderPeriodOptions: OrderPeriodOptions[] = [
		{
			label: i18n.t(`orderSummary.${DateType.Today}`).toString(),
			value: DateType.Today,
		},
		{
			label: i18n.t(`orderSummary.${DateType.SevenDays}`).toString(),
			value: DateType.SevenDays,
		},
		{
			label: i18n.t(`orderSummary.${DateType.FifteenDays}`).toString(),
			value: DateType.FifteenDays,
		},
		{
			label: i18n.t(`orderSummary.${DateType.ThirtyDays}`).toString(),
			value: DateType.ThirtyDays,
		},
	];

	useEffect(() => {
		const newRedirectParams = {
			since: getSinceFilter(currentDateType),
			until: new Date().toISOString(),
		};
		setAditionalLinkPath(`&${buildURLWithParams(newRedirectParams)}`);
		if (!orders) return;
		mapSummaryStatuses(orders);
	}, [orders]);

	const checkIfSmOrderHasError = (order: OrderDto): boolean => {
		return (
			!order.ProcessingState.IsFinished &&
			!order.ProcessingState.HasNextActionAvailable
		);
	};

	const mapSummaryStatuses = (orders: CpOrdersDto | OrdersDto): void => {
		let newStatusConter = defaultStatusCounter;
		const ordersList = isStateMachine
			? (orders as OrdersDto).Items
			: (orders as CpOrdersDto).orders;
		ordersList.forEach((order) => {
			const genericStatus = mapStatusToGeneric(order, isStateMachine);
			// An SM order model can have both a "normal" status and "error" status, so the error is being validated here
			if (isStateMachine && checkIfSmOrderHasError(order as OrderDto)) {
				newStatusConter = {
					...newStatusConter,
					WithError: newStatusConter.WithError + 1,
				};
			}
			if (!genericStatus) return;
			newStatusConter = {
				...newStatusConter,
				[genericStatus]: newStatusConter[genericStatus] + 1,
			};
		});
		setStatusCounter(newStatusConter);
	};

	const handleSelectOrderPeriod = (event: SelectChangeEvent<string>): void => {
		const value = event.target.value as Exclude<DateType, DateType.CustomDate>;
		setCurrentDateType(value);
	};

	return {
		orderPeriodOptions,
		statusListDict,
		isCustomMediumBreakpoint,
		statusCounter,
		aditionalLinkPath,
		handleSelectOrderPeriod,
	};
}
