import React, { createContext, useContext, useEffect, useState } from "react";
import { APIV2 } from "../../../API";
import { i18n } from "../../../translate/i18n";
import { OrderMapper } from "../../../domain/mappers/orders/OrderMapper";

export const OrdersProvider = createContext({});

export default function OrdersContext(props) {
	const [orderId, setOrderId] = useState("");
	const [plp, setPlp] = useState("");
	const [loading, setLoading] = useState(true);
	const [status, setStatus] = useState("");
	const [fraudStatus, setFraudStatus] = useState("");
	const [buyer, setBuyer] = useState("");
	const [seller, setSeller] = useState("");
	const [delivery, setDelivery] = useState("");
	const [freight, setFreight] = useState("");
	const [paymentInfo, setPaymentInfo] = useState("");
	const [items, setItems] = useState([]);
	const [actionLogs, setActionLogs] = useState([]);
	const [levels, setLevels] = useState([]);
	const [positions, setPositions] = useState([]);
	const [descriptions, setDescriptions] = useState([]);
	const [productDisplayCodeName, setProductDisplayCodeName] = useState("");
	const [invoice, setInvoice] = useState([]);
	const [role, setRole] = useState(0);
	const [reload, setReload] = useState(false);
	const [updateFilterData, setUpdateFilterData] = useState([]);
	const [reloadFilter, setReloadFilter] = useState([]);
	const [tracking, setTracking] = useState([]);
	const [order, setOrder] = useState({
		friendlyCode: "",
		createDate: "",
		lastUpdate: "",
		channel: "",
		dealerCode: "",
		externalId: "",
		isDelivery: "",
	});
	const [amount, setAmount] = useState({
		discountAmount: "",
		subTotalAmount: "",
		totalAmount: "",
		feeAmount: "",
		freightAmount: "",
		voucher: "",
		bonusAmount: "",
	});
	const [actionsButtons, setActionsButtons] = useState([]);
	const [newPaymentStatus, setNewPaymentStatus] = useState({
		title: "",
		name: "",
		color: "",
	});
	const [paymentList, setPaymentList] = useState([]);
	const [arrStatus, setArrStatus] = useState([]);
	const [detailOrder, setDetailOrder] = useState({});
	const documentTypeDict = {
		Cnpj: i18n.t(`orderDetails.General.SellerDoc`),
		Cpf: i18n.t(`orderDetails.General.BuyerDoc`),
	};
	const [orderModel, setOrderModel] = useState();

	useEffect(() => {
		const config = JSON.parse(localStorage.getItem("configurations"));
		setProductDisplayCodeName(config[0].productDisplayCodeName);
		setRole(Number(localStorage.getItem("role")));
	}, [reload]);

	const getOrders = async (id) => {
		setLoading(true);
		setOrderId(id);
		await APIV2.get(`management/commerce/orders/${id}`)
			.then((response) => {
				setDetailOrder(response.data);
				getFraudStatus(response.data.paymentInfo.fraudStatus);
				getPaymentStatus(
					response.data.paymentInfo.paymentStatus,
					response.data?.paymentInfo?.paymentMethod?.type
				);
				setActionLogs(response.data.actionLogs);
				getSteps(response.data.steps);
				setBuyer((prevState) => ({ ...prevState, ...response.data.buyer }));
				setFreight((prevState) => ({ ...prevState, ...response.data.freight }));
				setSeller((prevState) => ({ ...prevState, ...response.data.seller }));
				setDelivery((prevState) => ({
					...prevState,
					...response.data.deliveryAddress,
				}));
				setPaymentInfo((prevState) => ({
					...prevState,
					...response.data.paymentInfo,
				}));
				setInvoice((prevState) => ({ ...prevState, ...response.data.invoice }));
				setStatus(response.data.status);
				setItems(response.data.items);
				setOrder({
					friendlyCode: response.data.friendlyCode,
					createDate: response.data.createDate,
					lastUpdate: response.data.lastUpdate,
					channel: response.data.channel,
					dealerCode: response.data.dealerCode,
					externalId: response?.data?.externalId,
					sellerOrderId: response?.data?.sellerOrderId,
					isDelivery: response.data.isDelivery,
				});
				setAmount({
					discountAmount: response.data.discountAmount,
					subTotalAmount: response.data.subTotalAmount,
					totalAmount:
						response.data.bonusAmount > 0
							? response.data.bonusAmount + response.data.totalAmount
							: response.data.totalAmount,
					feeAmount: response.data.feeAmount,
					freightAmount: response.data.freightAmount,
					voucher: response.data.voucher,
					bonusAmount: response.data.bonusAmount,
				});
				getActionsButtons(response?.data?.buttons);
				setTracking(response.data?.tracking);
				setPlp(response?.data?.plp);
				getPaymentList(response.data?.paymentList, response?.data?.paymentInfo);
				setOrderModel(OrderMapper.legacyToOrderModel(response.data));
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};

	const getPaymentList = (payments, paymentInfo) => {
		if (payments[0]?.paymentMethod?.type === 7) {
			payments.shift();
			payments.unshift(paymentInfo);
			setPaymentList(payments);
		} else {
			setPaymentList(payments);
		}
	};

	const getFraudStatus = (fraudStatus) => {
		fraudStatus === 1
			? setFraudStatus("Em análise")
			: fraudStatus === 2
			? setFraudStatus("Aprovado")
			: fraudStatus === 3
			? setFraudStatus("Negado")
			: fraudStatus === 4
			? setFraudStatus("Em análise manual")
			: fraudStatus === 5
			? setFraudStatus("Aguardando criação")
			: fraudStatus === 6
			? setFraudStatus("Erro na análise de fraude")
			: setFraudStatus("Não informado");
	};

	const getPaymentStatus = (paymentStatus, type) => {
		paymentStatus === 1
			? setNewPaymentStatus({
					title: "Aguardando...",
					name: "Aguardando pré-aprovação",
					color: "bg-warning",
			  })
			: paymentStatus === 2
			? setNewPaymentStatus({
					title: "Aguardando...",
					name: "Aguardando captura",
					color: "bg-warning",
			  })
			: paymentStatus === 3
			? setNewPaymentStatus({
					title: "Aguardando...",
					name: "Aguardando pagamento direto",
					color: "bg-warning",
			  })
			: paymentStatus === 4
			? setNewPaymentStatus({
					title: "Aguardando...",
					name: "Aguardando estorno",
					color: "bg-warning",
			  })
			: paymentStatus === 5
			? setNewPaymentStatus({
					title: "Pré-aprovado",
					name: "Pré-aprovado",
					color: "bg-primary",
			  })
			: paymentStatus === 6
			? setNewPaymentStatus({
					title: "Pago",
					name: "Pago",
					color: "bg-primary",
			  })
			: paymentStatus === 7
			? setNewPaymentStatus({
					title: "Estornado",
					name: "Estornado",
					color: "bg-secondary",
			  })
			: paymentStatus === 8
			? setNewPaymentStatus({
					title: "Erro",
					name: "Erro de pré-aprovação",
					color: "bg-danger",
			  })
			: paymentStatus === 9
			? setNewPaymentStatus({
					title: "Erro",
					name: "Erro de captura",
					color: "bg-danger",
			  })
			: paymentStatus === 10
			? setNewPaymentStatus({
					title: "Erro",
					name: "Erro de pagamento",
					color: "bg-danger",
			  })
			: paymentStatus === 11
			? setNewPaymentStatus({
					title: "Erro",
					name: "Erro de estorno",
					color: "bg-danger",
			  })
			: paymentStatus === 12
			? setNewPaymentStatus({
					title: "Pagamento",
					name: "Pagamento na criação do pedido",
					color: "bg-primary",
			  })
			: paymentStatus === 13
			? setNewPaymentStatus({
					title: type === 3 ? "Boleto bancário" : type === 6 ? "PIX" : null,
					name: type === 3 ? "Boleto bancário" : type === 6 ? "PIX" : null,
					color: "bg-primary",
			  })
			: paymentStatus === 14
			? setNewPaymentStatus({
					title: "Aguardando...",
					name: "Aguardando nova tentativa",
					color: "bg-warning",
			  })
			: paymentStatus === 15
			? setNewPaymentStatus({
					title: "Retentativas",
					name: "Retentativas expiradas",
					color: "bg-danger",
			  })
			: paymentStatus === 16
			? setNewPaymentStatus({
					title: "Aguardando...",
					name: "Aguardando pagamento na retirada",
					color: "bg-warning",
			  })
			: paymentStatus === 17
			? setNewPaymentStatus({
					title: "Pré-aprovado",
					name: "Pagamento na retirada pré-aprovado",
					color: "bg-primary",
			  })
			: paymentStatus === 18
			? setNewPaymentStatus({
					title: "Pagamento",
					name: "Pagamento na retirada aprovado",
					color: "bg-primary",
			  })
			: setNewPaymentStatus({
					title: "Não iniciado",
					name: "Não iniciado",
					color: "bg-secondary",
			  });
	};

	const getActionsButtons = (typeButtons) => {
		setActionsButtons([]);
		//remover objetos duplicados
		const buttons = typeButtons.filter(function (a) {
			return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
		}, Object.create(null));
		buttons.map((button) => {
			setActionsButtons((prevState) => [
				...prevState,
				{
					name: button.name,
					endpoint: button.endpoint,
					action: button.action,
					status: button.status,
					backgroundColor:
						button.status === "CanceledByAdmin"
							? "btn btn-danger"
							: button.status === "CanceledBySeller"
							? "btn btn-danger"
							: button.status === "CanceledByBuyer"
							? "btn btn-danger"
							: button.status === "CanceledByProcessor"
							? "btn btn-danger"
							: button.status === "Separate"
							? "btn btn-blueish-80"
							: button.status === "InDelivery"
							? "btn btn-blueish-80"
							: button.status === "ApprovedExternal"
							? "btn btn-blueish-80"
							: button.status === "Finalized"
							? "btn btn-blueish-80"
							: button.status === "ReadyForPickup"
							? "btn btn-blueish-80"
							: "btn btn-blueish-80",
					textColor:
						button.status === "CanceledByAdmin"
							? "text-danger"
							: button.status === "CanceledBySeller"
							? "text-danger"
							: button.status === "CanceledByBuyer"
							? "text-danger"
							: button.status === "CanceledByProcessor"
							? "text-danger"
							: button.status === "Separate"
							? "text-secondary"
							: button.status === "InDelivery"
							? "text-info"
							: button.status === "ApprovedExternal"
							? "text-warning"
							: button.status === "Finalized"
							? "text-success"
							: button.status === "ReadyForPickup"
							? "text-info"
							: "text-info",
				},
			]);
		});
	};

	const getSteps = (steps) => {
		const progress = [];
		steps.forEach((step) => {
			var body = {
				position: step.position,
				level: step.level,
				description: step.description,
				status: step.status,
			};
			progress.push(body);
		});
		/* get positions */
		const positions = progress.map((step) => step.position);
		setPositions(positions);
		/* get descriptions */
		const descriptions = progress.map((step) => step.description);
		setDescriptions(descriptions);
		/* get levels */
		const levels = progress.map((step) => step.level);
		setLevels(levels);
	};

	const openModal = (updatefilterData, filterData, arrStatus) => {
		setArrStatus(arrStatus);
		setReloadFilter(filterData);
		setUpdateFilterData(updatefilterData);
	};

	return (
		<OrdersProvider.Provider
			value={{
				orderModel,
				loading,
				amount,
				order,
				buyer,
				seller,
				delivery,
				paymentInfo,
				fraudStatus,
				positions,
				descriptions,
				levels,
				actionsButtons,
				status,
				items,
				orderId,
				actionLogs,
				productDisplayCodeName,
				newPaymentStatus,
				invoice,
				freight,
				role,
				reload,
				updateFilterData,
				reloadFilter,
				paymentList,
				arrStatus,
				setLoading,
				setItems,
				setAmount,
				setOrder,
				getOrders,
				setReload,
				openModal,
				tracking,
				setTracking,
				detailOrder,
				setDetailOrder,
				plp,
				setPlp,
				documentTypeDict,
			}}
		>
			{props.children}
		</OrdersProvider.Provider>
	);
}

export const useOrders = () => useContext(OrdersProvider);
