import { Tooltip, Zoom } from "@mui/material";
import React from "react";
import CustomIcon from "./CustomIcon";
import { HelpOutlineOutlined } from "@mui/icons-material";

export type HelpTooltipConfig = {
	title: string;
};

type Props = { helpTooltip: HelpTooltipConfig };

export default function HelpTooltip({ helpTooltip }: Props) {
	return (
		<Tooltip
			arrow
			placement="top"
			TransitionComponent={Zoom}
			title={helpTooltip.title}
		>
			<i style={{ marginLeft: "2px" }}>
				<CustomIcon icon={<HelpOutlineOutlined />} variant="small" />
			</i>
		</Tooltip>
	);
}
