import { foundations } from "./foundations";
import { palette, paletteObject } from "./foundations/palette";
import { components } from "./components";
import { createTheme, ThemeOptions } from "@mui/material";

export const theme: ThemeOptions = createTheme({
	...foundations,
	palette: {
		...palette,
		text: {
			primary: paletteObject.blueish80.main,
			secondary: paletteObject.blueish80.main,
		},
	},
	components: {
		...components,
	},
});

/**
 * Creates new theme with provided custom colors.
 *
 * OBS: Besides main color, the function also generates dark, light and contrastText variants
 *
 * @example createWhiteLabelTheme('#FF0000', '#00FF00')
 *
 * @param primaryColor White-Label primary color
 * @param secondaryColor White-Label secondary color
 * @returns new theme with primary and secondary colors using White-Label
 */
export function createWhiteLabelTheme(
	primaryColor: string,
	secondaryColor: string
): ThemeOptions {
	const newTheme: ThemeOptions = createTheme({
		...foundations,
		palette: {
			...palette,
			primary: { main: primaryColor },
			secondary: { main: secondaryColor },
			text: {
				primary: paletteObject.blueish80.main,
				secondary: paletteObject.blueish80.main,
			},
		},
		components: {
			...components,
		},
	});
	return newTheme;
}
