import React from "react";
import FinancialContext from "./providers/FinancialContext";
import LoadDashboard from "../Dashboard/components/LoadDashboard";
import { useConfig } from "./../../providers/ConfigProvider";
import Financial from "./Financial";

export default function Index(props) {
	const { mainLoading } = useConfig();

	return (
		<>
			{mainLoading ? (
				<LoadDashboard />
			) : (
				<FinancialContext>
					<Financial url={props.match.url} />
				</FinancialContext>
			)}
		</>
	);
}
