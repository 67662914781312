import { ChangeEvent, useState } from "react";
import {
	FormErrors,
	InputValidator,
} from "../../../../../infrastructure/utils/FormUtils";
import { SelectChangeEvent } from "@mui/material";
import { AttributeTypes } from "../../enums/AttributeTypes";
import { AttributesDto } from "../../../../../domain/dtos/products/attributes/AttributesDto";
import { i18n } from "../../../../../translate/i18n";

import NewToastComponent from "../../../../../components/NewToastComponent";
import { isValidString } from "../../../../../infrastructure/utils/TypeValidator";
import { validateInput } from "../../../../../infrastructure/utils/ValidateInput";

type InputValueProps = {
	title: string;
	value: string;
	label: string | null;
	type: AttributeTypes;
};

type AttributeToDeleteProps = {
	id: string;
	title: string;
};

type AttributeTypeSelectorProps = {
	label: string;
	value: AttributeTypes;
};

export default function ModalService(
	setIsOpenCreateModal: (value: boolean) => void,
	attributes: AttributesDto[],
	setAttributes: (value: AttributesDto[]) => void,
	updateAttributes?: (attributes: AttributesDto[]) => void
) {
	const atttributeTypeSelect: AttributeTypeSelectorProps[] = [
		{
			label: i18n.t(`attributes.Text`),
			value: AttributeTypes.Text,
		},
		{
			label: i18n.t(`attributes.Color`),
			value: AttributeTypes.Color,
		},
	];
	const [newAttributte, setNewAttributte] = useState<InputValueProps>({
		title: "",
		value: "",
		label: null,
		type: AttributeTypes.Text,
	});
	const [attributeToDelete, setAttributeToDelete] =
		useState<AttributeToDeleteProps>({
			id: "",
			title: "",
		});

	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const [errorColor, setErrorColor] = useState<boolean>(false);

	const validationFuncMapper: Record<string, Function> = {
		title: InputValidator.isRequired,
		value: InputValidator.isRequired,
		...(newAttributte.type === AttributeTypes.Color && {
			label: InputValidator.isRequired,
		}),
	};

	const updateChromePicker = (inputValue: string): void => {
		setNewAttributte((prevState: InputValueProps) => ({
			...prevState,
			value: inputValue,
		}));

		setErrorColor(false);
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
		let { name, value } = event.currentTarget;

		if (validationFuncMapper[name] !== undefined) {
			const errMessages: string[] = validationFuncMapper[name](value);
			setFormErrors((prevState) => ({ ...prevState, [name]: errMessages }));
		}

		setNewAttributte((prevState: InputValueProps) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSelectChange = (event: SelectChangeEvent): void => {
		setNewAttributte({
			title: "",
			value: "",
			label: null,
			type: AttributeTypes.Text,
		});

		const { name, value } = event.target;

		setNewAttributte((prevState: InputValueProps) => ({
			...prevState,
			[name]: value,
			...(newAttributte.type === AttributeTypes.Text && {
				label: null,
			}),
		}));
	};

	const createNewAttribute = (): void => {
		const validateAttributeCreation = validateInput(
			newAttributte,
			validationFuncMapper
		);

		if (!validateAttributeCreation.isValid) {
			setFormErrors((prevErrors) => {
				return {
					...prevErrors,
					...validateAttributeCreation.errors,
				} as FormErrors;
			});

			if (!isValidString(newAttributte.value)) {
				setErrorColor(true);
			}

			return;
		}

		const newOrder: number = attributes.length + 1;

		const attribute = {
			title: newAttributte.title,
			value: newAttributte.value,
			label: newAttributte.label,
			order: newOrder.toString(),
			type: newAttributte.type,
		};

		if (attributeAlreadyExists(attribute)) {
			NewToastComponent({
				status: "error",
				title: i18n.t("attributes.ExistingAttribute"),
				message: i18n.t("attributes.TryOtherName"),
			});

			return;
		}

		setAttributes([...attributes, attribute]);

		if (updateAttributes) {
			updateAttributes([...attributes, attribute]);
		}

		setIsOpenCreateModal(false);
	};

	const attributeAlreadyExists = (data: AttributesDto): boolean => {
		const existingAttribute: AttributesDto | undefined = attributes.find(
			(attribute) =>
				attribute.title.toLowerCase() === data.title.toLowerCase() &&
				attribute.type === data.type
		);

		return existingAttribute !== undefined;
	};

	return {
		// Props
		atttributeTypeSelect,
		newAttributte,
		formErrors,
		attributeToDelete,
		setAttributeToDelete,
		errorColor,
		// Functions
		updateChromePicker,
		handleInputChange,
		handleSelectChange,
		createNewAttribute,
	};
}
