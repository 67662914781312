import React, { useEffect, useState } from 'react';
import SkuList from './SkuList.jsx';

export default function Index(props) {

    let params = (new URL(document.location)).searchParams;
    
	useEffect(() => {
		if (!props.match.params.page) {
			window.location.href = `/sku/1`
		}
	}, [props.match.params]);


	return (
		<>
			<SkuList query={params.get("query")} page={props.match.params.page} hasStock={params.get("hasStock")} hasPrice={params.get("hasPrice")} isAvailable={params.get("isAvailable")} isActive={params.get("isActive")} />
		</>
	)
};
