import { Button, Stack } from "@mui/material";
import React from "react";
import CustomTypography from "../../MUICustomComponents/CustomTypography";
import { ActionButton } from "../../domain/types/ActionButton";

type Props = {
	title: string;
	message: string;
	imageSrc?: string;
	createButton?: ActionButton;
};

export default function EmptyComponent({
	title,
	message,
	imageSrc,
	createButton,
}: Props) {
	return (
		<Stack alignItems="center">
			{imageSrc && (
				<img src={imageSrc} width="20%" style={{ paddingBottom: "24px" }} />
			)}
			<CustomTypography variant="smallTitle">{title}</CustomTypography>
			<CustomTypography variant="default">{message}</CustomTypography>
			{createButton && (
				<Button
					variant={createButton.variant}
					color={createButton.color}
					onClick={() => createButton.onClick()}
					disabled={createButton.isDisabled}
					sx={{ mt: 2 }}
				>
					{createButton.text}
				</Button>
			)}
		</Stack>
	);
}
