import { useState, useEffect } from "react";
import { i18n } from "../translate/i18n";

type FormUnsavedChangesProps = {
	promptMessage: string;
	unsavedChanges: boolean;
	enableUnsavedChanges: () => void;
	disableUnsavedChanges: () => void;
};

/**
 * This hook shows a native browser alert, informing the user that there are unsaved changes in the form.
 *
 * @returns
 * Functions: `enableUnsavedChanges` and `disableUnsavedChanges`, Enables and disables the default browser alert.
 * Boolean: `unsavedChanges`, whether the changes are saved or not.
 * String: `promptMessage`, Default alert message.
 */
const useUnsavedFormSafety = (): FormUnsavedChangesProps => {
	const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

	const promptMessage: string = i18n.t("forms.UnsavedChanges");

	const enableUnsavedChanges = (): void => {
		setUnsavedChanges(true);
	};

	const disableUnsavedChanges = (): void => {
		setUnsavedChanges(false);
	};

	useEffect(() => {
		const beforeUnloadHandler = (event: BeforeUnloadEvent): void => {
			if (unsavedChanges) {
				event.returnValue = promptMessage;
			}
		};

		window.addEventListener("beforeunload", beforeUnloadHandler);

		return () => {
			window.removeEventListener("beforeunload", beforeUnloadHandler);
		};
	}, [unsavedChanges]);

	return {
		unsavedChanges,
		enableUnsavedChanges,
		disableUnsavedChanges,
		promptMessage,
	};
};

export default useUnsavedFormSafety;
