import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { APIV2 } from "../../../../../API";
import Modal from "../../../../../components/Modal";
import List from "../../../../../components/List/List";
import { toast } from "react-toastify";
import Empty from "../../../../../components/Empty";
import { usePromotion } from "../Providers/PromotionContext";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function PromotionsList(props) {
	const { loading, promotions, getPromotions } = usePromotion();
	const columsList = [
		{
			name: "Nome",
			selector: "name",
			sortable: false,
			grow: 2,
		},
		{
			sortable: false,
			name: "Status",
			selector: "isActive",
			cell: (row) =>
				row.isActive ? (
					<span className="badge badge-success stadium text-center">ATIVO</span>
				) : (
					<span className="badge stadium badge-grey-60">INATIVO</span>
				),
		},
		{
			sortable: false,
			name: "Nº de regras",
			selector: "mechanics",
			cell: (row) => row.mechanics.length,
		},
		{
			name: "",
			sortable: false,
			width: "150px",
			cell: (row) => (
				<div className="btn-group" role="group">
					<Link
						id="btnGroupDrop1"
						className="btn btn-info"
						to={`/promotions/edit/${row.id}`}
					>
						Editar
					</Link>
					<button
						id="btnGroupDrop1"
						type="button"
						className="btn btn-sm border-left border-white btn-info rounded-right"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<span className="material-icons" style={{ fontSize: "15px" }}>
							keyboard_arrow_down
						</span>
					</button>
					<div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
						<button
							className="dropdown-item text-danger"
							data-toggle="modal"
							data-target="#deletePromotion"
							onClick={() => setDeleteItem(row.id)}
						>
							Excluir
						</button>
					</div>
				</div>
			),
		},
	];
	const [data, setData] = useState(null);
	const [deleteItem, setDeleteItem] = useState(null);

	useEffect(() => {
		getPromotions();
	}, []);

	const skeleton = () => {
		const skele = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="16" y="25" rx="3" ry="3" width="115" height="10" />
				<rect x="450" y="26" rx="3" ry="3" width="60" height="10" />
				<rect x="750" y="26" rx="3" ry="3" width="52" height="10" />
				<rect x="970" y="17" rx="3" ry="3" width="100" height="35" />
			</ContentLoader>
		));
	};
	const setDelete = async () => {
		await APIV2.delete(`management/marketing/promotions/${deleteItem}`)
			.then(async (response) => {
				toast.success("Promoção deletada com sucesso.");
				window.location.reload();
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};
	return (
		<div className="container dash-content">
			<div className="card">
				<div className="card-header d-flex justify-content-between align-items-center">
					<h4 className="card-title">Promoções</h4>
					<div className="card-actions">
						<Link
							className="btn btn-new-width btn-grey-80"
							to={"/promotions/new"}
						>
							Nova promoção
						</Link>
					</div>
				</div>
				<div className="card-content">
					<div className="card-body no-padding">
						{promotions.length <= 0 && loading === false ? (
							<Empty
								img={"/images/empty_coupon.svg"}
								genre="sua primeira"
								name="promoção"
								link="/promotions/new"
								import={false}
							/>
						) : loading ? (
							skeleton()
						) : (
							<List
								data={promotions}
								colums={columsList}
								paginationServer={false}
								pagination={true}
							/>
						)}
					</div>
				</div>
			</div>
			<Modal
				id={"deletePromotion"}
				title={"Certeza que deseja excluir este item?"}
				delete={true}
				actionDelete={setDelete}
			/>
		</div>
	);
}
