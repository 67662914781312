import React, { useState } from "react";
import ReactInputMask from "react-input-mask";
import Calendar from "react-calendar";
import { i18n } from "../translate/i18n";

export default function InputSingleDate({
	inputName,
	errors,
	register,
	required,
	resetField,
	isEnabled,
}) {
	const [show, setShow] = useState(true);

	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	const changeDateCalendar = (value) => {
		const formatedDate = new Date(value).toLocaleDateString("pt-Br");
		resetField(inputName, { defaultValue: formatedDate.replace(/[/"]/g, "") });
	};

	const validateDateCalendar = () => {
		document.getElementById(inputName).focus();
		handleCalendar();
	};

	const errorIconMessage = () => (
		<div className="neo-icons-required">
			<button type="button">
				<i className="material-icons">error_outline</i>
			</button>
		</div>
	);

	const showIconCalendar = () => (
		<div className="neo-icon-calendar">
			<button type="button" onClick={isEnabled ? handleCalendar : undefined}>
				<i className="material-icons">calendar_today</i>
			</button>
		</div>
	);

	const handleCalendar = () => {
		if (show) {
			document.querySelector(`#${inputName}Id`).style.display = "block";
			setShow(false);
		} else {
			document.querySelector(`#${inputName}Id`).style.display = "none";
			setShow(true);
		}
	};

	return (
		<section className="m-0 py-2">
			<div className="d-flex align-items-end">
				<label className="input-form-label" htmlFor={inputName}>
					{i18n.t(`orderDetails.General.${capitalizeFirstLetter(inputName)}`)}
				</label>
			</div>
			<div className="neo-form">
				<div className="neo-form-input">
					<ReactInputMask
						className={`${
							!isEnabled
								? "neo-input-disabled"
								: errors[inputName]
								? "neo-input-invalid"
								: "neo-input-valid"
						}`}
						autocomplete="off"
						mask="99/99/9999"
						placeholder="dd/mm/aaaa"
						id={inputName}
						name={inputName}
						disabled={!isEnabled}
						{...register(inputName, { required: required })}
					/>
					<div className="neo-input-icons">
						{errors[inputName] && errorIconMessage()}
						{showIconCalendar()}
					</div>
				</div>
			</div>
			<div className="card neo-from-calendar">
				<div id={`${inputName}Id`} className="drop-calendar">
					<div className={`datepicker-calendar-view isPerPeriod w-100 m-0 p-0`}>
						<Calendar
							maxDetail="month"
							minDetail="month"
							onChange={(value) => changeDateCalendar(value)}
						/>
					</div>
					<div className="datepicker-footer pt-2">
						<button
							type="button"
							onClick={handleCalendar}
							moda
							className="btn"
							style={{
								color: "#5A5E61",
								border: "1px solid #8D8D8D",
								backgroundColor: "transparent",
							}}
						>
							{i18n.t(`orderDetails.TypeButtom.BtnCancel`)}
						</button>
						<button
							type="button"
							onClick={validateDateCalendar}
							className="btn btn-success ml-2 datepicker-footer-confirm"
						>
							{i18n.t(`orderDetails.TypeButtom.BtnConfirm`)}
						</button>
					</div>
				</div>
			</div>

			{errors[inputName] && (
				<small className="text-danger">
					<em>{i18n.t(`orderDetails.General.MandatoryInformation`)}</em>
				</small>
			)}
		</section>
	);
}
