import { i18n } from "../../../../translate/i18n";
import bankslipIcon from "../../../../assets/payments-flags/icon-pag-boleto.svg";
import defaultCardIcon from "../../../../assets/payments-flags/credit_card.svg";
import pixIcon from "../../../../assets/payments-flags/icon-pag-pix.svg";

export class FinancialPaymentMethodTypes {
	// boleto should be translated to bankslip, but will remain "boleto" because its how "Pagar.me" returns it
	static boleto = {
		text: i18n.t(`financial.PaymentMethod.boleto`),
		image: bankslipIcon,
	};
	static debit_card = {
		text: i18n.t(`financial.PaymentMethod.debit_card`),
		image: defaultCardIcon,
	};
	static credit_card = {
		text: i18n.t(`financial.PaymentMethod.credit_card`),
		image: defaultCardIcon,
	};
	static pix = {
		text: i18n.t(`financial.PaymentMethod.pix`),
		image: pixIcon,
	};
	static voucher = {
		text: i18n.t(`financial.PaymentMethod.voucher`),
		image: defaultCardIcon,
	};
	static unmappedPaymentMethod = {
		text: null, // Uses the text received in the response field 'payment_method'
		image: defaultCardIcon,
	};
}
