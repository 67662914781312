import React from "react";
import { ErrorOutline } from "@mui/icons-material";
import {
	FormControl,
	FormHelperText,
	InputLabel,
	OutlinedInput,
} from "@mui/material";
import ReactInputMask from "react-input-mask";

export default function CustomMaskInput(props) {
	return (
		<FormControl size="small" fullWidth>
			<InputLabel color="secondary" error={props?.error}>
				{props.title}
			</InputLabel>
			<ReactInputMask
				mask={props.mask}
				placeholder={props.mask}
				value={props.value ?? ""}
				onChange={props.onChange}
				type={props?.type ?? "text"}
				disabled={props?.disabled}
			>
				{() => (
					<OutlinedInput
						error={props.error}
						label={props.title}
						id={props.name}
						name={props.name}
						color="secondary"
						disabled={props?.disabled}
						endAdornment={
							props?.endAdornment ? (
								<>
									{props?.endAdornment}
									{props?.error && (
										<ErrorOutline sx={{ marginLeft: "8px" }} color="error" />
									)}
								</>
							) : (
								props?.error && <ErrorOutline color="error" />
							)
						}
					/>
				)}
			</ReactInputMask>
			<FormHelperText error={props?.error}>{props?.helperText}</FormHelperText>
		</FormControl>
	);
}
