import React, { useState } from "react";
import { useEffect } from "react";

import "./NotificationStyle.scss";

function Notifications() {
	const [frameClassList, setFrameClassList] = useState(false);

	useEffect(() => {
		const script = document.createElement("script");
		script.async = true;
		script.src = "https://cdn.headwayapp.co/widget.js";
		document.body.appendChild(script);
		var config = {
			selector: ".headway-badge",
			account: "7zjOj7",
			translations: {
				title: "Novidades da Plataforma",
				readMore: "Veja mais",
				footer: "Clique aqui para ver todas as novidades",
			},
		};
		script.onload = function () {
			window.Headway.init(config);
		};
	}, []);

	useEffect(() => {
		window.onload = () => {
			window.addEventListener("click", function (e) {
				if (!document.getElementById("HW_badge_cont").contains(e.target)) {
					setFrameClassList(
						document
							.getElementById("HW_frame_cont")
							.classList.contains("HW_visible")
					);
				}
			});
			document.getElementById("HW_badge_cont").addEventListener("click", () => {
				setFrameClassList(
					document
						.getElementById("HW_frame_cont")
						.classList.contains("HW_visible")
				);
			});
		};
	}, []);

	return (
		<>
			<span
				className="headway-badge"
				id="headway-badge"
				style={{ justifyContent: "center" }}
			>
				{frameClassList ? (
					<i
						class="material-icons settings-dropdown-icon active-widget"
						style={{
							position: "absolute",
							padding: "4px",
						}}
					>
						notifications
					</i>
				) : (
					<i
						className="material-icons-outlined settings-dropdown-icon "
						style={{
							position: "absolute",
							padding: "4px",
						}}
					>
						notifications_none
					</i>
				)}
			</span>
		</>
	);
}

export default Notifications;
