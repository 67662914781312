import styled from "styled-components";
import { paletteObject } from "../../theme/foundations/palette";
import { Box } from "@mui/material";

interface StyledProps {
	background: string;
}

export const DragDropImage = styled(Box)<StyledProps>`
	border-radius: 4px;
	margin: 0 auto;
	padding: 45px 16px;
	border: 1px solid rgba(0, 0, 0, 0.12);
	background: ${(props) => props.background};
	color: ${paletteObject.blueish20.main};
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	height: 100%;
	width: 100%;
	cursor: pointer;
	position: relative;
`;

export const DeleteImage = styled(Box)`
	position: absolute;
	right: 0;
	top: 0;
	color: ${paletteObject.danger.main};
`;

export const Image = styled.img`
	max-width: 140px;
	max-height: 93px;
`;
