import React, { useEffect } from "react";
import Button from "../../../components/Button";
import ContentLoader from "react-content-loader";
import LoginContext, { useLogin } from "../providers/LoginContext";
import Success from "./Success";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { i18n } from "../../../translate/i18n";

export default function IndexResetPassword() {
	return (
		<LoginContext>
			<ResetPassword />
		</LoginContext>
	);
}

function ResetPassword() {
	const {
		backgroundImage,
		submitResetPassword,
		handleLogin,
		loading,
		setLoading,
		disable,
		setDisable,
		newPassword,
		setNewPassword,
	} = useLogin();
	const history = useHistory();
	const infos = {
		email: "",
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	useEffect(() => {
		handleLogin();
	}, []);

	const handleResetPassword = async (email) => {
		submitResetPassword(email);
	};

	const skeleton = () => {
		const skele = [0];
		return skele.map((key) => (
			<ContentLoader
				key={key}
				speed={2}
				width={"402"}
				height={40}
				backgroundColor="#f3f3f3"
				foregroundColor="rgb(243, 243, 243, 0.3)"
				viewBox="0 0 402 40"
			>
				<rect x="0" y="0" rx="5" ry="5" width="402" height="40" />
			</ContentLoader>
		));
	};

	const handleRedirectLogin = () => {
		setDisable(true);
		setLoading(true);
		setTimeout(() => {
			setDisable(true);
			setLoading(true);
			setNewPassword(false);
			history.push(`/login`);
		}, 1000);
	};

	return (
		<div className="container-login" style={backgroundImage}>
			<section className="bg-login">
				<div className="new-login">
					<div className="d-flex flex-column">
						<h2>{i18n.t("login.General.Welcome")}</h2>
						<img
							className="d-flex align-self-start mt-2"
							src="/images/neomode-logo.svg"
							height="25"
						/>
					</div>
					<div>
						{newPassword ? (
							<Success />
						) : (
							<div className="initial">
								<h2>{i18n.t("login.General.ForgetPassword")}</h2>
								<div className="text-white pt-2 pb-4">
									{i18n.t("login.General.MessageChangePassword")}
								</div>
								<form
									style={{ position: "relative" }}
									onKeyPress={(event) => {
										event.key === "Enter" && event.preventDefault();
									}}
									onSubmit={handleSubmit(handleResetPassword)}
								>
									<div className="neo-form-row">
										<div className="pb-3">
											<label htmlFor="email">
												<strong
													className="text-white"
													style={{ fontSize: "12px" }}
												>
													{i18n.t("login.General.Email")}
												</strong>
											</label>
											{loading ? (
												skeleton()
											) : (
												<div className="neo-form">
													<div className="neo-form-input">
														<input
															className={`${
																errors.email
																	? "neo-input-invalid"
																	: "neo-input-valid"
															}`}
															id="email"
															name="email"
															defaultValue={infos.email}
															{...register("email")}
														/>
														{errors.email && (
															<div className="neo-icons-required">
																<button type="button">
																	<i className="material-icons">
																		error_outline
																	</i>
																</button>
															</div>
														)}
													</div>
												</div>
											)}
											{errors.email && (
												<small className="text-danger">
													<em>{i18n.t("login.General.RequiredField")}</em>
												</small>
											)}
										</div>
										<div className="pt-4">
											<Button
												type="submit"
												loading={loading}
												disable={disable}
												className="btn btn-secondary-130 w-100"
											>
												{i18n.t("login.General.RecoverPassword")}
											</Button>
										</div>
										<div className="pt-3">
											<Button
												loading={loading}
												disable={disable}
												onClick={handleRedirectLogin}
												className="btn btn-outline border w-100"
											>
												{i18n.t("login.General.BackToLogin")}
											</Button>
										</div>
									</div>
								</form>
							</div>
						)}
					</div>
				</div>
			</section>
		</div>
	);
}
