import React from "react";
import CategoryForm from "./components/CategoryForm/CategoryForm";
import CategoriesList from "./components/CategoryList/CategoryList";
import { CategoryProvider } from "./context/CategoryContext";
import { BoxSideBySide } from "./styles/CategoryStyles";
import CustomContainer from "../../MUICustomComponents/CustomContainer";
import { DeleteModal } from "./components/DeleteModal";

export default function Categories() {
	return (
		<CategoryProvider>
			<CustomContainer>
				<BoxSideBySide>
					<CategoriesList />
					<CategoryForm />
				</BoxSideBySide>
				<DeleteModal />
			</CustomContainer>
		</CategoryProvider>
	);
}
