import { PaletteOptions } from "@mui/material/styles/createPalette";

declare module "@mui/material/styles/createPalette" {
	interface Palette {
		bgNeo?: PaletteColor;
		light?: PaletteColor;
		link?: PaletteColor;

		// Marca
		primary: PaletteColor;
		secondary: PaletteColor;

		// Semânticas
		green10?: PaletteColor;
		green30?: PaletteColor;
		green60?: PaletteColor;
		success: PaletteColor;
		green130?: PaletteColor;
		green160?: PaletteColor;

		blue10?: PaletteColor;
		blue30?: PaletteColor;
		blue60?: PaletteColor;
		info: PaletteColor;
		blue130?: PaletteColor;
		blue160?: PaletteColor;

		orange10?: PaletteColor;
		orange30?: PaletteColor;
		orange60?: PaletteColor;
		warning: PaletteColor;
		orange130?: PaletteColor;
		orange160?: PaletteColor;

		red05?: PaletteColor;
		red10?: PaletteColor;
		red30?: PaletteColor;
		red60?: PaletteColor;
		danger?: PaletteColor;
		red130?: PaletteColor;
		red160?: PaletteColor;

		// Interface
		blueish80?: PaletteColor;
		blueish50?: PaletteColor;
		blueish20?: PaletteColor;
		white?: PaletteColor;
		grey10?: PaletteColor;
		grey20?: PaletteColor;
		grey30?: PaletteColor;
		grey50?: PaletteColor;
		grey60?: PaletteColor;
		grey65?: PaletteColor;
		grey70?: PaletteColor;
		grey80?: PaletteColor;
		grey90?: PaletteColor;
		grey95?: PaletteColor;
		black?: PaletteColor;
	}

	interface PaletteOptions {
		bgNeo?: PaletteColorOptions;
		light?: PaletteColorOptions;
		link?: PaletteColorOptions;

		// Marca
		primary?: PaletteColorOptions;
		secondary?: PaletteColorOptions;

		// Semânticas
		green10?: PaletteColorOptions;
		green30?: PaletteColorOptions;
		green60?: PaletteColorOptions;
		success?: PaletteColorOptions;
		green130?: PaletteColorOptions;
		green160?: PaletteColorOptions;

		blue10?: PaletteColorOptions;
		blue30?: PaletteColorOptions;
		blue60?: PaletteColorOptions;
		info?: PaletteColorOptions;
		blue130?: PaletteColorOptions;
		blue160?: PaletteColorOptions;

		orange10?: PaletteColorOptions;
		orange30?: PaletteColorOptions;
		orange60?: PaletteColorOptions;
		warning?: PaletteColorOptions;
		orange130?: PaletteColorOptions;
		orange160?: PaletteColorOptions;

		red05?: PaletteColorOptions;
		red10?: PaletteColorOptions;
		red30?: PaletteColorOptions;
		red60?: PaletteColorOptions;
		danger?: PaletteColorOptions;
		red130?: PaletteColorOptions;
		red160?: PaletteColorOptions;

		// Interface
		blueish80?: PaletteColorOptions;
		blueish50?: PaletteColorOptions;
		blueish20?: PaletteColorOptions;
		white?: PaletteColorOptions;
		grey10?: PaletteColorOptions;
		grey20?: PaletteColorOptions;
		grey30?: PaletteColorOptions;
		grey50?: PaletteColorOptions;
		grey60?: PaletteColorOptions;
		grey65?: PaletteColorOptions;
		grey70?: PaletteColorOptions;
		grey80?: PaletteColorOptions;
		grey90?: PaletteColorOptions;
		grey95?: PaletteColorOptions;
		black?: PaletteColorOptions;
	}
}

type ColorObject = { main: string };

export type PaletteObjectType = {
	bgNeo: ColorObject;
	light: ColorObject;
	link: ColorObject;
	primary: ColorObject;
	secondary: ColorObject;
	green10: ColorObject;
	green30: ColorObject;
	green60: ColorObject;
	success: ColorObject;
	green130: ColorObject;
	green160: ColorObject;
	blue10: ColorObject;
	blue30: ColorObject;
	blue60: ColorObject;
	info: ColorObject;
	blue130: ColorObject;
	blue160: ColorObject;
	orange10: ColorObject;
	orange30: ColorObject;
	orange60: ColorObject;
	warning: ColorObject;
	orange130: ColorObject;
	orange160: ColorObject;
	red05: ColorObject;
	red10: ColorObject;
	red30: ColorObject;
	red60: ColorObject;
	danger: ColorObject;
	error: ColorObject;
	red130: ColorObject;
	red160: ColorObject;
	// Interface
	blueish80: ColorObject;
	blueish50: ColorObject;
	blueish20: ColorObject;
	white: ColorObject;
	grey10: ColorObject;
	grey20: ColorObject;
	grey30: ColorObject;
	grey50: ColorObject;
	grey60: ColorObject;
	grey65: ColorObject;
	grey70: ColorObject;
	grey80: ColorObject;
	grey90: ColorObject;
	grey95: ColorObject;
	black: ColorObject;
};

export const paletteObject: PaletteObjectType = {
	bgNeo: { main: "#F2F4F4" }, //Background
	light: { main: "#E9ECEC" },
	link: { main: "transparent" },

	// Marca
	primary: { main: "#6D4AED" }, // NeoPurple, primária
	secondary: { main: "#00BFB5" }, // NeoBlue, secundária

	// Semânticas
	green10: { main: "#F6FFFA" }, // Green 10%
	green30: { main: "#B6EBC8" }, // Green 30%
	green60: { main: "#91E0AD" }, // Green 60%
	success: { main: "#6CD691" }, // Sucesso ou positivo
	green130: { main: "#56A77E" }, // Green 130%
	green160: { main: "#40776B" }, // Green 160%

	blue10: { main: "#F1F5FE" }, // Blue 10%
	blue30: { main: "#BDCFF5" }, // Blue 30%
	blue60: { main: "#9BB7F0" }, // Blue 60%
	info: { main: "#7A9FEB" }, // Aviso neutro ou notificação passiva
	blue130: { main: "#5F82BA" }, // Blue 130%
	blue160: { main: "#456589" }, // Blue 160%

	orange10: { main: "#FFF4E5" }, // Orange 10%
	orange30: { main: "#FED6A0" }, // Orange 30%
	orange60: { main: "#FEC171" }, // Orange 60%
	warning: { main: "#FDAC41" }, // Aviso sério
	orange130: { main: "#B78B49" }, // Orange 130%
	orange160: { main: "#706950" }, // Orange 160%

	red05: { main: "#FFEDED" }, // Red 05%
	red10: { main: "#FFD6D6" }, // Red 10%
	red30: { main: "#FFADAE" }, // Red 30%
	red60: { main: "#FF8485" }, // Red 60%
	danger: { main: "#FF5B5C" }, // Perigo ou erro
	error: { main: "#FF5B5C" }, // Should always be the same as danger
	red130: { main: "#B8555B" }, // Red 130%
	red160: { main: "#714E59" }, // Red16 160%

	// Interface
	blueish80: { main: "#4B5F79" }, // Deep Blue (titles)
	blueish50: { main: "#6B7B90" }, // Títulos Clareados (medium-blue)
	blueish20: { main: "#747E8B" }, // Blueish Grey (icons)
	white: { main: "#ffffff" }, // White 100%
	grey10: { main: "#F5F5F5" }, // Grey 10%
	grey20: { main: "#E9E9E9" }, // Grey 20%
	grey30: { main: "#DBDBDB" }, // Grey 30%
	grey50: { main: "#BCBEC0" }, // Grey 50% (Neo Cinza)
	grey60: { main: "#939698" }, // Grey 60%
	grey65: { main: "#8D8D8D" }, // Grey 65%
	grey70: { main: "#707070" }, // Grey 70%
	grey80: { main: "#5A5E61" }, // Grey 80%
	grey90: { main: "#2E2E34" }, // Grey 90%
	grey95: { main: "#1A1A1A" }, // Grey 95%
	black: { main: "#000000" }, // Pure Black
};

export const palette: PaletteOptions = paletteObject;
export type PaletteDiscriminatedUnion = keyof PaletteObjectType;
