import { useState } from "react";
import { useOrders } from "../../providers/OrdersContext";
import { OrderModel } from "../../../../domain/models/commerce/OrderModel";
import { LegacyOrderStatus } from "../../../../domain/enums/LegacyOrderStatus";
import { DeliveryAddressModel } from "../../../../domain/models/commerce/ShippingData/DeliveryAddressModel";
import { SellerAddressModel } from "../../../../domain/models/commerce/Seller/SellerAddressModel";

type TemporaryContextTypes = {
	orderModel: OrderModel;
	getOrders: Function;
};

export default function ShippingTypeService() {
	const { orderModel, getOrders } = useOrders() as TemporaryContextTypes;
	const enableAddresseDialog =
		orderModel.isDelivery && orderModel.status !== LegacyOrderStatus.Finalized;
	const [isAddresseeDialogOpen, setIsAddresseeDialogOpen] =
		useState<boolean>(false);

	const addressData: DeliveryAddressModel | SellerAddressModel =
		orderModel.isDelivery
			? orderModel.shippingData.deliveryAddress!
			: orderModel.seller.address;

	const addresseeUpdatedCallback = () => {
		setIsAddresseeDialogOpen(false);
		getOrders(orderModel.id);
	};

	return {
		orderModel,
		addressData,
		enableAddresseDialog,
		isAddresseeDialogOpen,
		setIsAddresseeDialogOpen,
		addresseeUpdatedCallback,
	};
}
