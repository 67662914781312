import { Box } from "@mui/material";
import styled from "styled-components";
import { paletteObject } from "../../../theme/foundations/palette";

export const CheckboxList = styled(Box)`
	.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
		background: ${paletteObject.success.main};
		border: none !important;
	}

	.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
		left: 52%;
		background: transparent;
		transform: translate(-50%, -50%) scale(1);
		transition: none;
		content: "indeterminate_check_box";
		font-family: "Material Icons";
		color: ${paletteObject.success.main};
	}

	.editValue-parent-checkbox
		.ant-tree-checkbox
		.ant-tree-checkbox-inner::before {
		position: absolute;
		left: 53%;
		top: 50%;
		background: transparent;
		transform: translate(-50%, -50%) scale(1);
		transition: none;
		content: "indeterminate_check_box";
		font-family: "Material Icons";
		color: ${paletteObject.success.main};
	}

	.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
	.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
	.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
		border: 1px solid ${paletteObject.success.main};
	}

	.ant-tree-checkbox-checked::after {
		border: 1px solid ${paletteObject.success.main};
	}
`;

export const ModalBody = styled.div`
	width: 567px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: ${paletteObject.white.main};
	border: 2px solid ${paletteObject.white.main};
	border-radius: 4px;
	padding: 35px;
`;
