import React from "react";
import {
	AddCircleOutlineOutlined,
	MoreHorizRounded,
	Search,
} from "@mui/icons-material";
import CustomIcon from "../../../../MUICustomComponents/CustomIcon";
import { i18n } from "../../../../translate/i18n";
import TreeView from "../TreeView/TreeView";
import { StyledScrollbar } from "../../../../MUICustomComponents/CustomScrollbar";
import { Alert, Box, Button, Paper, Stack } from "@mui/material";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import SearchInputClose from "../../../../components/SearchInputClose";
import CategoryListService from "./CategoryListService";
import CustomSkeleton from "../../../../MUICustomComponents/CustomSkeleton";

import * as Styled from "../../styles/CategoryStyles";
import LoadingButtonIcon from "../../../../components/LoadingButtonIcon";

export default function CategoriesList() {
	const {
		// Props
		queryValue,
		showSearch,
		setShowSearch,
		filteredCategories,
		visibleCategories,
		isLoadingList,
		showContentBottom,
		showFilteredList,
		isReorderMode,
		isUpsertMode,
		isDisabled,
		// Functions
		handleScroll,
		handleQueryChange,
		upsertCategory,
		sortCategories,
		updateReorderedCategories,
		handleCategoryOrderingCancellation,
	} = CategoryListService();

	const showTreeViewCategories = () => {
		const emptyList = showFilteredList
			? filteredCategories
			: sortCategories(visibleCategories);

		const titleKey =
			visibleCategories.length > 0
				? "categories.Titles.SearchEmptyCategories"
				: "categories.Titles.EmptyCategories";

		const messageKey =
			visibleCategories.length > 0
				? "categories.Messages.NoSearchResults"
				: "categories.Messages.ClickForNewCategory";

		if (emptyList.length > 0) {
			return (
				<>
					<TreeView visibleCategories={emptyList} />
					{showContentBottom && (
						<Box display="flex" justifyContent="center">
							<CustomIcon icon={<MoreHorizRounded />} variant="default" />
						</Box>
					)}
				</>
			);
		}

		return (
			<Styled.EmptyList gap={2}>
				<Styled.EmptyImage src={"/images/empty_inventory.svg"} />
				<CustomTypography variant="smallTitle">
					{i18n.t(titleKey).toString()}
				</CustomTypography>
				<CustomTypography variant="default">
					{i18n.t(messageKey).toString()}
				</CustomTypography>
			</Styled.EmptyList>
		);
	};

	return (
		<Paper variant="customPaper">
			<Styled.ListContentBox>
				{showSearch ? (
					<Styled.TitleAndSearchBox>
						<SearchInputClose
							alwaysShowClose={true}
							loading={false}
							value={queryValue}
							onChange={handleQueryChange}
							placeholder={i18n
								.t(`categories.Titles.SearchCategory`)
								.toString()}
						/>
					</Styled.TitleAndSearchBox>
				) : (
					<Styled.TitleAndSearchBox>
						<CustomTypography variant="title">
							{i18n.t(`categories.Titles.Categories`).toString()}
						</CustomTypography>
						<Box mr={1} mt={1}>
							<CustomIcon
								icon={<Search />}
								variant="default"
								hasPointerCursor
								onClick={() => setShowSearch(true)}
							/>
						</Box>
					</Styled.TitleAndSearchBox>
				)}

				{isReorderMode && (
					<Alert severity="info">
						{i18n.t(`categories.Messages.SortEditingMode`).toString()}
					</Alert>
				)}

				{!isReorderMode && isUpsertMode && (
					<Alert severity="info">
						{i18n.t(`categories.Messages.UpsertMode`).toString()}
					</Alert>
				)}

				<Box mt={2}>
					<StyledScrollbar
						minHeight={"550px"}
						maxHeight={"550px"}
						onScroll={handleScroll}
					>
						{isLoadingList ? (
							<CustomSkeleton quantity={15} height={30} />
						) : (
							showTreeViewCategories()
						)}
					</StyledScrollbar>
				</Box>

				<Stack mt={3} flexDirection={"row"} gap={1}>
					{isReorderMode ? (
						<>
							<Button
								variant="contained"
								color="light"
								fullWidth
								disabled={isDisabled}
								onClick={handleCategoryOrderingCancellation}
							>
								{i18n.t(`buttons.General.BtnCancel`).toString()}
							</Button>
							<Button
								variant="contained"
								color="success"
								fullWidth
								disabled={isDisabled}
								onClick={updateReorderedCategories}
							>
								{isDisabled ? (
									<LoadingButtonIcon />
								) : (
									i18n.t(`buttons.General.BtnSave`).toString()
								)}
							</Button>
						</>
					) : (
						<Button
							variant="contained"
							color="success"
							disabled={isUpsertMode || isLoadingList}
							fullWidth
							onClick={() => upsertCategory("", filteredCategories, false)}
						>
							{isLoadingList ? (
								<LoadingButtonIcon />
							) : (
								<>
									<Box mr={1} mb={0.5}>
										<CustomIcon
											icon={<AddCircleOutlineOutlined />}
											variant="small"
										/>
									</Box>

									{i18n.t(`categories.Titles.NewCategory`).toString()}
								</>
							)}
						</Button>
					)}
				</Stack>
			</Styled.ListContentBox>
		</Paper>
	);
}
