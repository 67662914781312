import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { APIV2 } from "../API";
import Select from "react-select";

// import { Container } from './styles';

function SelectStores(props) {
	const [stores, setStores] = useState([
		{
			value: "",
			label: "Nenhum",
		},
	]);
	const [load, setLoad] = useState(true);

	useEffect(() => {
		setStores([]);
		APIV2.get("/management/retailer/autocomplete/sellers")
			.then((response) => {
				response.data.forEach((data) => {
					setStores((prevState) => [
						...prevState,
						{ value: data.cnpj, label: data.fullName },
					]);
				});
				setLoad(false);
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	}, [props.storeCnpj]);

	const customStyles = {
		indicatorSeparator: (provided) => ({
			...provided,
			display: "none",
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			color: "#747E8B",
			paddingLeft: 5,
			svg: {
				width: 17,
			},
		}),
	};

	return (
		<>
			<>
				{props.filter ? (
					<Select
						styles={customStyles}
						className="react-select-container"
						placeholder="Mostrando Todos"
						value={
							props.storeCnpj === ""
								? props.storeCnpj
								: stores[(store) => store.value !== props.storeCnpj]
						}
						options={stores}
						theme={(theme) => ({
							...theme,
							borderRadius: 0,
							colors: {
								...theme.colors,
								primary25: "#BDCFF5",
								primary: "#9BB7F0",
							},
						})}
						onChange={(e) => props.setStoreCnpj(e.value)}
					/>
				) : (
					<>
						{!load && (
							<Select
								styles={customStyles}
								className="react-select-container"
								placeholder="Mostrando Todos"
								defaultValue={
									stores[
										stores.findIndex((store) => store.value === props.storeCnpj)
									]
								}
								options={stores}
								theme={(theme) => ({
									...theme,
									borderRadius: 0,
									colors: {
										...theme.colors,
										primary25: "#BDCFF5",
										primary: "#9BB7F0",
									},
								})}
								onChange={(e) => props.setStoreCnpj(e.value)}
							/>
						)}
					</>
				)}
			</>
		</>
	);
}

export default SelectStores;
