import cpOrderStatus from "./cpOrderStatusPT.json";
import cpActionLogTitle from "./cpActionLogTitlePT.json";
import legacyShipping from "./legacyShipping.json";
import legacyOrderProgress from "./legacyOrderProgressPT.json";

export const cpOrdersPT = {
	cpOrderStatus,
	cpActionLogTitle,
	legacyShipping,
	legacyOrderProgress,
};
