import React, { createContext, useContext, useEffect, useState } from "react";
import NewToastComponent from "../../../components/NewToastComponent";
import primaryBackground from "../../../assets/images/primary-background.jpg";
import secondaryBackground from "../../../assets/images/secondary-background.jpg";
import { API, APIV2 } from "../../../API";
import { i18n } from "../../../translate/i18n";
import { CredentialsTypes } from "../types/CredentialsTypes";

const qs = require("querystring");

export const LoginProvider = createContext({});

export default function LoginContext(props) {
	const [loading, setLoading] = useState(false);
	const [disable, setDisable] = useState(false);
	const [newPassword, setNewPassword] = useState(false);
	const [loadingLogin, setLoadingLogin] = useState(false);
	const [credentials, setCredentials] = useState({});
	const [error, setError] = useState(false);
	const [roles, setRoles] = useState([]);
	const [prevRoute, setPrevRoute] = useState("");
	const [count, setCount] = useState(0);
	const [backgroundImage, setBackgroundImage] = useState({
		backgroundImage: `url(${primaryBackground})`,
	});

	useEffect(() => {
		setLoading(false);
		localStorage.setItem("config", JSON.stringify(roles));
		if (roles.length === 1) {
			selectId(roles[0]);
		}
	}, [roles]);

	useEffect(() => {
		setTimeout(() => {
			if (count === 0) {
				setCount(count + 1);
				setBackgroundImage({ backgroundImage: `url(${primaryBackground})` });
			} else {
				setCount(count - 1);
				setBackgroundImage({ backgroundImage: `url(${secondaryBackground})` });
			}
		}, 8000);
	}, [backgroundImage]);

	const handleLogin = (email, pass) => {
		setLoading(true);
		setLoadingLogin(true);

		if (email && pass) {
			setCredentials({ email: email, pass: pass });
			localStorage.clear();
		}

		const body = {
			client_id: CredentialsTypes.ClientId,
			client_secret: CredentialsTypes.ClientSecret,
			grant_type:
				email && pass
					? CredentialsTypes.GrantTypePassword
					: CredentialsTypes.GrantTypeCredentials,
			...(email && pass && { username: email }),
			...(email && pass && { password: pass }),
			...(!email && !pass && { scope: CredentialsTypes.Scope }),
		};

		API.post("connect/token", qs.stringify(body), {
			headers: {
				ContentType: "application/x-www-form-urlencoded",
				"Access-Control-Allow-Origin": "*",
			},
		})
			.then(async (response) => {
				localStorage.setItem("token", response.data.access_token);
				email && pass && getAccess();
			})
			.catch((error) => {
				setError(true);
				setLoading(false);
				if (
					error.response &&
					error.response.data.error_description ===
						"invalid_username_or_password"
				) {
					NewToastComponent({
						status: "error",
						title: i18n.t(`login.General.MessageTitleError`),
						message: i18n.t(`login.General.ErrorLogin`),
					});
				} else {
					NewToastComponent({
						status: "error",
						title: i18n.t(`login.General.MessageTitleError`),
						message: i18n.t(`login.General.MessageError`),
					});
				}
			});
	};

	const getAccess = () => {
		API.get("api/access")
			.then(async (resp) => {
				setRoles(resp.data);
				setLoading(false);
				resp.data.length > 1 && setLoadingLogin(false);
			})
			.catch((error) => {
				setError(true);
				setLoading(false);
				console.error(error);
				NewToastComponent({
					status: "error",
					title: i18n.t(`login.General.MessageTitleError`),
					message: i18n.t(`login.General.MessageError`),
				});
			});
	};

	const selectId = (item) => {
		setLoading(true);
		setLoadingLogin(true);
		const body = {
			client_id: item.clientId,
			client_secret: CredentialsTypes.ClientSecret,
			grant_type: CredentialsTypes.GrantTypePassword,
			username: credentials.email,
			password: credentials.pass,
		};

		API.post("connect/token", qs.stringify(body), {
			headers: { ContentType: "application/x-www-form-urlencoded" },
		})
			.then(async (response) => {
				localStorage.setItem("token", response.data.access_token);
				localStorage.setItem("client_id", item.clientId);
				localStorage.setItem("retailerName", item.retailerName);
				localStorage.setItem("role", item.role);
				localStorage.setItem("retailerIconUrl", item.retailerIconUrl);

				setUserParams();
			})
			.catch((error) => {
				console.error(error);
				NewToastComponent({
					status: "error",
					title: i18n.t(`login.General.MessageTitleError`),
					message: i18n.t(`login.General.MessageError`),
				});
			});
	};

	const setUserParams = () => {
		API.get("api/autosetup/retailers/")
			.then((response) => {
				APIV2.get("management/retailer/configurations/internal")
					.then(async (response) => {
						const data = response.data;

						localStorage.setItem(
							"usePagarMeDashboard",
							data.usePagarMeDashboard
						);
						localStorage.setItem(
							"usePagarMeDashboardForRetailer",
							data.usePagarMeDashboardForRetailer
						);
						localStorage.setItem("useOrderEditor", data.useOrderEditor);
						localStorage.setItem(
							"UseCatalogProcessor",
							data.useCatalogProcessor
						);
						localStorage.setItem("UseCatalogMatcher", data.useCatalogMatcher);
						localStorage.setItem("useStateMachine", data.useStateMachine);

						if (
							localStorage.getItem("role") === "3" &&
							localStorage.getItem("usePagarMeDashboard") === "true"
						) {
							try {
								const response = await APIV2.get(
									`management/retailer/sellers/receiverId`
								);
								localStorage.setItem("recipientId", response.data.id);
							} catch (error) {
								console.error(error);
							}
						}

						APIV2.get("management/retailer/configurations/")
							.then(async (response) => {
								const configurations = new Array(response.data);
								localStorage.setItem(
									"configurations",
									JSON.stringify(configurations)
								);
								localStorage.setItem(
									"EnableUserPromotion",
									response.data.enableUserPromotion
								);

								if (prevRoute != null || prevRoute != undefined) {
									window.location.href = prevRoute;
								} else {
									window.location.href = "dashboard";
								}
							})
							.catch((error) => {
								console.error(error);
								NewToastComponent({
									status: "error",
									title: i18n.t(`login.General.MessageTitleError`),
									message: i18n.t(`login.General.MessageError`),
								});
							});
					})
					.catch((error) => {
						console.error(error);
						NewToastComponent({
							status: "error",
							title: i18n.t(`login.General.MessageTitleError`),
							message: i18n.t(`login.General.MessageError`),
						});
					});
				localStorage.setItem("primaryColor", response.data.primaryColor);
				localStorage.setItem("secondaryColor", response.data.secondaryColor);
				localStorage.setItem(
					"primaryLogo",
					response.data.logoHorizontalPrimaryImageUrl
				);
				localStorage.setItem(
					"secondaryLogo",
					response.data.logoHorizontalSecondaryImageUrl
				);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const submitResetPassword = async (email) => {
		setDisable(true);
		setLoading(true);
		await API.post(`api/users/rememberpassword`, email)
			.then((response) => {
				setDisable(true);
				setLoading(true);
				setNewPassword(true);
			})
			.catch((error) => {
				console.error(error);
				setDisable(false);
				setLoading(false);
				NewToastComponent({
					status: "error",
					title: i18n.t(`login.General.MessageTitleError`),
					message: i18n.t(`login.General.MessageError`),
				});
			});
	};

	return (
		<LoginProvider.Provider
			value={{
				roles,
				loading,
				setLoading,
				loadingLogin,
				setPrevRoute,
				error,
				handleLogin,
				selectId,
				backgroundImage,
				submitResetPassword,
				newPassword,
				setNewPassword,
				disable,
				setDisable,
			}}
		>
			{props.children}
		</LoginProvider.Provider>
	);
}

export const useLogin = () => useContext(LoginProvider);
