import React, { useState } from "react";
import Modal from "../../../components/Modal";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { API, APIV2 } from "../../../API";
import { useOrders } from "../providers/OrdersContext";
import { i18n } from "../../../translate/i18n";

export default function DropzoneInvoice({ id, onChange, disableDropForm }) {
	const { getOrders, setLoading } = useOrders();
	const [fileType, setFileType] = useState("");
	const [fileUrl, setFileUrl] = useState(null);
	const [xmlUrl, setXmlUrl] = useState(null);
	const [isUploadFile, setIsUploadFile] = useState(false);

	const onDrop = async (files) => {
		if (
			files[0].path.indexOf("xml") != -1 ||
			files[0].path.indexOf("pdf") != -1
		) {
			const xml = files[0].type.indexOf("xml");
			const formData = new FormData();
			formData.append("file", files[0]);
			if (xml === -1) {
				setFileType("PDF");
				setIsUploadFile(true);
				API.post("api/images", formData, {
					headers: { ContentType: "multipart/form-data" },
				})
					.then(async (resp) => {
						setFileUrl(resp.data.absoluteUrl);
						onChange(resp.data.absoluteUrl);
						setIsUploadFile(false);
					})
					.catch((error) => {
						console.error(error);
					});
			} else {
				setFileType("XML");
				setIsUploadFile(true);
				API.post("api/images", formData, {
					headers: { ContentType: "multipart/form-data" },
				})
					.then(async (resp) => {
						setXmlUrl(resp?.data?.absoluteUrl);
						onChange(resp?.data?.absoluteUrl);
						setIsUploadFile(false);
					})
					.catch((error) => {
						console.error(error);
					});
			}
		} else {
			toast.error("São suportados arquivos no formato XML e PDF!");
		}
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
	});

	const deleteInvoiceUrl = () => {
		setLoading(true);
		APIV2.delete(
			`management/commerce/orders/${id}/invoiceUrl?typeUrl=${fileType}`
		)
			.then((response) => {
				if (fileType === "xml") {
					setFileType("");
					setXmlUrl("");
					getOrders(id);
				} else {
					setFileType("");
					setFileUrl("");
					getOrders(id);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<>
			<div className="pt-2">
				<div className="d-flex align-items-end">
					<label className="input-form-label">
						{i18n.t("orderDetails.General.InvoiceFile")}
					</label>
				</div>
				{disableDropForm ? (
					<div {...getRootProps()} className="download-pdf-xml">
						<input {...getInputProps()} />
						<div className="success" style={{ width: "100%" }}>
							<div className="d-flex flex-column justify-content-center align-items-center">
								{isUploadFile ? (
									<i className="fas fa-spinner fa-spin"></i>
								) : (
									<>
										<i className="material-icons"> note_add </i>
										{isDragActive ? (
											<span>
												{i18n.t("orderDetails.Invoicing.MessageDropHere")}
											</span>
										) : (
											<span>
												{i18n.t("orderDetails.Invoicing.MessageDrop")}
											</span>
										)}
									</>
								)}
							</div>
						</div>
					</div>
				) : (
					<div className="download-pdf-xml">
						<div className="success" style={{ width: "100%" }}>
							<div className="d-flex flex-column justify-content-center align-items-center">
								<span>{i18n.t("orderDetails.Invoicing.MessageDrop")}</span>
							</div>
						</div>
					</div>
				)}

				<label
					style={{
						color: "#A1A3A5",
						fontWeight: "500",
						fontSize: "12px",
					}}
				>
					<em>{i18n.t("orderDetails.Invoicing.MessageFormatFiles")}</em>
				</label>
				<div
					className="d-flex justify-content-center flex-column m-0"
					style={{ paddingTop: "10px" }}
				>
					{(xmlUrl || fileUrl) && (
						<div className="d-flex align-items-end">
							<label className="input-form-label">Arquivos inseridos:</label>
						</div>
					)}
					{xmlUrl && (
						<div className="download-invoice p-0 m-0">
							<div className="download-invoice-label">
								<i className="material-icons-outlined">insert_drive_file</i>
								<span>
									&nbsp;{i18n.t("orderDetails.Invoicing.MessageFileXml")}
								</span>
							</div>
							<div className="download-invoice-icons">
								<a href={xmlUrl} target="_blank">
									<i className="material-icons-outlined">get_app</i>
								</a>
							</div>
						</div>
					)}
					{fileUrl && (
						<div className="download-invoice p-0 m-0">
							<div className="download-invoice-label">
								<i className="material-icons-outlined">insert_drive_file</i>
								<span>{i18n.t("orderDetails.Invoicing.MessageFilePdf")}</span>
							</div>
							<div className="download-invoice-icons">
								<a href={fileUrl} target="_blank">
									<i className="material-icons-outlined">get_app</i>
								</a>
							</div>
						</div>
					)}
				</div>
			</div>
			<Modal
				id={"deleteNF"}
				title={`Tem certeza que deseja excluir o arquivo ${fileType}?`}
				delete={true}
				actionDelete={deleteInvoiceUrl}
			/>
		</>
	);
}
