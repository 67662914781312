export enum LegacyOrderStatus {
	None = 0,
	Pending = 1,
	PendingCreate = 2,
	PendingApproval = 3,
	ApprovedExternal = 4,
	Processing = 5,
	Separate = 6,
	InDelivery = 7,
	ReadyForPickup = 8,
	Finalized = 9,
	CanceledBySeller = 10,
	CanceledByBuyer = 11,
	CanceledByAdmin = 12,
	PendingCancel = 13,
	PendingCreatePreOrder = 14,
	PreOrderCreated = 15,
	ErrorCancel = 16,
	PendingUpdate = 17,
	Updated = 18,
	ErrorUpdate = 19,
	InvoiceCreated = 20,
	CanceledByProcessor = 21,
}
