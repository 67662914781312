/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";

export class SearchList extends Component {
	constructor(props) {
		super(props);
		this.searchHandler = this.searchHandler.bind(this);
		this.state = {
			list: this.props.list,
		};
	}
	componentWillMount() {
		this.setState({ displayedContacts: this.state.list });
	}

	searchHandler(event) {
		let searcjQery = event.target.value.toLowerCase(),
			displayedContacts = this.state.list.filter((el) => {
				let searchValue = el.fullName.toLowerCase();
				return searchValue.indexOf(searcjQery) !== -1;
			});
		this.setState({
			displayedContacts: displayedContacts,
		});
		this.forceUpdate();
	}

	async selectItem(id) {
		for (var i in this.state.list) {
			if (this.state.list[i].id == id) {
				this.state.list[i].select = !this.state.list[i].select;
				this.forceUpdate();
				if (
					this.props.change != undefined &&
					this.props.change != null &&
					this.state.list[i].select == true
				) {
					this.props.change(this.state.list[i]);
				}
			}
		}
	}
	render() {
		let contacts = this.state.displayedContacts;
		return (
			<div className="holder col-12 search-list">
				{this.props.crud ? (
					<form className="row">
						<div className="form-group col-11 icon-right">
							<input
								type="text"
								className="form-control"
								onChange={this.searchHandler}
								placeholder={this.props.placeholder}
							/>
							<i className="material-icons">search</i>
						</div>
						<button
							className="btn btn-grey-80"
							style={{ height: "38px" }}
							onClick={() => this.props.change()}
						>
							{" "}
							+{" "}
						</button>
					</form>
				) : (
					<form>
						<div className="form-group col-12 p-0 autocomplete icon-right">
							<input
								type="text"
								className="form-control"
								onChange={this.searchHandler}
								placeholder={this.props.placeholder}
							/>
							<i className="material-icons">search</i>
						</div>
					</form>
				)}

				<div className="checkbox-list">
					<ul className="list-unstyled">
						{contacts <= 0 ? (
							<div className="row text-center mt-5">
								<div className="col-12 mt-5">
									<h3>Listagem Vazia</h3>
								</div>
								<div className="col-12 mt-2">
									<button
										className="btn btn-grey-80"
										onClick={() => this.props.change()}
									>
										Adicionar novo
									</button>
								</div>
							</div>
						) : (
							contacts.map((el) => {
								return (
									<li className="list-group-item" key={el.id}>
										{this.props.crud ? (
											<div className="row">{el.name}</div>
										) : (
											<div className="custom-control custom-checkbox">
												<input
													type="checkbox"
													className="custom-control-input"
													id={el.id}
													onChange={() => this.selectItem(el.id)}
													checked={el.select}
												/>
												<label className="custom-control-label" htmlFor={el.id}>
													{this.props.idFirst ? el.id + " - " : null}
													{this.props.pointSaleFirst
														? el.pointSaleId + " - "
														: null}
													{el.fullName}
												</label>
											</div>
										)}
									</li>
								);
							})
						)}
					</ul>
				</div>
			</div>
		);
	}
}

export default SearchList;
