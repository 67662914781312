import React from "react";
import useIntegrationService from "./IntegrationService";
import integrationSkeleton from "./IntegrationSkeleton";
import CustomInput from "../../../../MUICustomComponents/CustomInput";
import CustomAutocomplete from "../../../../MUICustomComponents/CustomAutocomplete";
import {
	Box,
	Stack,
	Button,
	Grid,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import { SellerIntegrationView } from "../../../../domain/views/stores/SellerIntegrationView";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import { i18n } from "../../../../translate/i18n";
import { IntegrationInputs } from "./IntegrationInputs";
import { FormValidator } from "../../../../infrastructure/utils/FormUtils";

type IntegrationProps = {
	sellerId: string;
	data: SellerIntegrationView;
	processId: string;
	getSeller: (sellerId: string) => void;
};

export default function Integration({
	sellerId,
	data,
	processId,
	getSeller,
}: IntegrationProps) {
	const {
		// Props
		configurations,
		integrationFieldList,
		integrationConfig,
		isDefaultSafetyStock,
		isLoading,
		orderProcessList,
		currentOrderProcess,
		orderProcessInput,
		formErrors,
		setCurrentOrderProcess,
		setOrderProcessInput,
		getOptionLabel,
		isOptionEqualToValue,
		filterOptions,
		// Functions
		onChangeIntegration,
		handleFormSubmit,
	} = useIntegrationService(processId, data, getSeller);

	return (
		<Box
			m={4}
			minHeight={335}
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
		>
			<Box pb={2}>
				<CustomTypography variant="title">
					{i18n.t("integration.Title").toString()}
				</CustomTypography>
				{isLoading ? (
					integrationSkeleton()
				) : (
					<>
						<CustomTypography variant="secondaryTitle" marginBottom={2}>
							{i18n.t("integration.Integration").toString()}
						</CustomTypography>
						<Grid container spacing={2}>
							{integrationFieldList.map((field) => (
								<Grid key={field.name} item xs={4} pb={2}>
									<CustomInput
										title={field.title}
										name={field.name}
										value={field.value}
										onChange={onChangeIntegration}
									/>
								</Grid>
							))}
						</Grid>
						<CustomTypography variant="secondaryTitle" marginBottom={2}>
							{i18n.t("integration.Order").toString()}
						</CustomTypography>
						<CustomAutocomplete
							id={IntegrationInputs.OrderProcesses}
							options={orderProcessList}
							value={currentOrderProcess}
							inputValue={orderProcessInput}
							renderInputLabel={i18n.t(
								`integration.${IntegrationInputs.OrderProcesses}`
							)}
							placeholder={i18n.t(
								`integration.${IntegrationInputs.OrderProcesses}`
							)}
							filterOptions={filterOptions}
							getOptionLabel={getOptionLabel}
							onSelectChange={setCurrentOrderProcess}
							onInputChange={setOrderProcessInput}
							isOptionEqualToValue={isOptionEqualToValue}
							{...(!configurations?.enableChangeSeller && {
								sxProps: { pb: 2 },
							})}
						/>
						{configurations?.enableChangeSeller && (
							<FormControlLabel
								control={
									<Checkbox
										name={IntegrationInputs.DisableToUseChangeSeller}
										checked={integrationConfig.disableToUseChangeSeller}
										onChange={onChangeIntegration}
									/>
								}
								label={
									<CustomTypography variant="default">
										{i18n.t("integration.disableToUseChangeSeller").toString()}
									</CustomTypography>
								}
							/>
						)}
						<CustomTypography variant="secondaryTitle" marginBottom={2}>
							{i18n.t("integration.Inventory").toString()}
						</CustomTypography>
						<Stack direction="row" spacing={2} alignItems="flex-start">
							<Grid item xs={4}>
								<CustomInput
									type="number"
									name={IntegrationInputs.SafetyStockQuantity}
									value={integrationConfig.safetyStockQuantity}
									title={i18n.t(
										`integration.${IntegrationInputs.SafetyStockQuantity}`
									)}
									onChange={onChangeIntegration}
									disabled={isDefaultSafetyStock}
									error={FormValidator.hasInputError(
										formErrors,
										IntegrationInputs.SafetyStockQuantity
									)}
									helperText={FormValidator.translateHelperTextList(
										formErrors,
										IntegrationInputs.SafetyStockQuantity
									)}
								/>
							</Grid>
							<FormControlLabel
								control={
									<Checkbox
										name={IntegrationInputs.IsDefaultSafetyStock}
										checked={isDefaultSafetyStock}
										onChange={onChangeIntegration}
									/>
								}
								label={
									<CustomTypography variant="default">
										{i18n.t("integration.isDefaultSafetyStock").toString()}
									</CustomTypography>
								}
							/>
						</Stack>
					</>
				)}
			</Box>
			<Stack direction="row" justifyContent="end">
				<Button
					color="success"
					variant="contained"
					disabled={isLoading || FormValidator.hasError(formErrors)}
					onClick={() =>
						handleFormSubmit(sellerId, integrationConfig, currentOrderProcess)
					}
				>
					{i18n.t("buttons.General.BtnSave").toString()}
				</Button>
			</Stack>
		</Box>
	);
}
