export enum ProductOrdering {
	None = 0,
	LowestPrice = 1,
	HigherPrice = 2,
	MostFavored = 3,
	BestSellers = 4,
	ReleaseDate = 5,
	AtoZ = 6,
	ZtoA = 7,
}
