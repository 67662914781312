import React, { useCallback } from "react";
import { i18n } from "../translate/i18n";

export default function InputCurrency({
	inputName,
	errors,
	register,
	required,
	resetField,
}) {
	const handleKeyUp = useCallback((event) => {
		let value = event.currentTarget.value;
		value = value.replace(/\D/g, ""); //remove todas a letras
		value = value.replace(/(\d)(\d{2})$/, "$1,$2");
		value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
		resetField(inputName, { defaultValue: !value ? "" : `R$ ${value}` });
	}, []);

	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	const errorIconMessage = () => (
		<div className="neo-icons-required">
			<button type="button">
				<i className="material-icons">error_outline</i>
			</button>
		</div>
	);

	return (
		<section className="m-0 py-2">
			<div className="d-flex align-items-end">
				<label className="input-form-label" htmlFor={inputName}>
					{i18n.t(`orderDetails.General.${capitalizeFirstLetter(inputName)}`)}
				</label>
			</div>
			<div className="neo-form">
				<div className="neo-form-input">
					<input
						className={`${
							errors[inputName] ? "neo-input-invalid" : "neo-input-valid"
						}`}
						autoComplete="off"
						placeholder={"R$ 0,01"}
						onKeyUp={handleKeyUp}
						id={inputName}
						name={inputName}
						disabled={false}
						{...register(inputName, { required: required })}
					/>
					<div className="neo-input-icons">
						{errors[inputName] && errorIconMessage()}
					</div>
				</div>
			</div>
			{errors[inputName] && (
				<small className="text-danger">
					<em>{i18n.t(`orderDetails.General.MandatoryInformation`)}</em>
				</small>
			)}
		</section>
	);
}
