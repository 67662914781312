import React from "react";
import { useMatcher } from "../providers/MatcherContext";
import MatcherUploadComponent from "./MatcherUploadComponente";

// import { Container } from './styles';

function MatcherUploadList() {
	const { getIntegrations, integrationCode, setIntegrationCode } = useMatcher();

	return (
		<>
			<div className="col-12 mt-3">
				<div className="row">
					<div className="col-lg-6 col-md-12">
						<MatcherUploadComponent
							icon="format_list_bulleted"
							tooltip="Mapeie a árvore de categorias do seu catálogo em comparação com o catálogo do marketplace"
							type="Categorias"
							downloadLink={`insights/export/catalog-matcher/categories?integrationCode=${integrationCode}`}
							uploadLink={`file/excel/marketplace-categories-mapping?integrationCode=${integrationCode}`}
						/>
					</div>
					<div className=" mobile-space col-lg-6 col-md-12">
						<MatcherUploadComponent
							icon="looks"
							tooltip="Mapeie as marcas do seu catálogo com as marcas disponíveis no catálogo do marketplace"
							type="Marcas"
							downloadLink={`insights/export/catalog-matcher/brands?integrationCode=${integrationCode}`}
							uploadLink={`file/excel/marketplace-brands-mapping?integrationCode=${integrationCode}`}
						/>
					</div>
				</div>
			</div>
			<div className="col-12 mt-3">
				<div className="row">
					<div className="col-lg-6 col-md-12">
						<MatcherUploadComponent
							icon="invert_colors"
							tooltip="Mapeie os atributos do seu catálogo (tamanho, voltagem, etc) com os atributos disponíveis no catálogo do marketplace"
							type="Atributos"
							downloadLink={`insights/export/catalog-matcher/attributes?integrationCode=${integrationCode}`}
							uploadLink={`file/excel/marketplace-attributes-mapping?integrationCode=${integrationCode}`}
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export default MatcherUploadList;
