import React, { Component } from 'react';
export class EmailPreview extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className="preview-email">
        <div className="barra" style={{ backgroundColor: this.props.barra }}>
        </div>
        <div className="email">
        </div>
        <div className="rectangle-email">
        </div>
        <div className="rectangle-email2">
        </div>
        <div className="rectangle-copy">
        </div>
        <div className="banner-app-2">
        </div>
      </div>
    )
  }
};

export default EmailPreview;
