import React, { Component } from "react";
import { API } from "../../../API";
import { connect } from "react-redux";
import appActions from "../../../appActions";
import { ChromePicker } from "react-color";
import Preview from "./Preview";
import Loading from "../../../components/Loading";
import { toast } from "react-toastify";
export class Colors extends Component {
	state = {
		displayColorPickerBarra: false,
		displayColorPickerPrimaria: false,
		displayColorPickerSecundaria: false,
		displayColorPickerDetalhe: false,
		displayColorPickerListagem: false,
		loading: true,
		primaria: "",
		secundaria: "",
		barra: "",
	};
	componentWillMount() {
		var clientId = localStorage.getItem("client_id");
		//Consulta materiais disponíveis a partir do token do usuário.
		API.get("api/autosetup/retailers/")
			.then(async (response) => {
				// If request is good...
				await this.setState({
					primaria: response.data.primaryColor,
					secundaria: response.data.secondaryColor,
					barra: response.data.appBarColor,
					loading: false,
				});
				this.forceUpdate();
				this.setFirstData();
			})
			.catch((error) => {
				console.log(error);
			});
	}
	componentDidUpdate() {
		this.setFirstData();
	}
	hidePicker() {
		document.removeEventListener("click", this.documentClick);
		this.displayPicker = false;
	}
	documentClick(e) {
		var el = this.$refs.colorpicker,
			target = e.target;

		if (el !== target && !el.contains(target)) {
			this.hidePicker();
		}
	}
	handleClick = () => {
		this.setState({
			displayColorPickerBarra: !this.state.displayColorPickerBarra,
		});
		this.setState({ displayColorPickerPrimaria: false });
		this.setState({ displayColorPickerSecundaria: false });
		this.setState({ displayColorPickerDetalhe: false });
		this.setState({ displayColorPickerListagem: false });
	};
	handleClickPrimaria = () => {
		this.setState({
			displayColorPickerPrimaria: !this.state.displayColorPickerPrimaria,
		});
		this.setState({ displayColorPickerBarra: false });
		this.setState({ displayColorPickerSecundaria: false });
		this.setState({ displayColorPickerDetalhe: false });
		this.setState({ displayColorPickerListagem: false });
	};
	handleClickSecundaria = () => {
		this.setState({
			displayColorPickerSecundaria: !this.state.displayColorPickerSecundaria,
		});
		this.setState({ displayColorPickerPrimaria: false });
		this.setState({ displayColorPickerBarra: false });
		this.setState({ displayColorPickerDetalhe: false });
		this.setState({ displayColorPickerListagem: false });
	};
	handleClickDetalhe = () => {
		this.setState({
			displayColorPickerDetalhe: !this.state.displayColorPickerDetalhe,
		});
		this.setState({ displayColorPickerPrimaria: false });
		this.setState({ displayColorPickerBarra: false });
		this.setState({ displayColorPickerSecundaria: false });
		this.setState({ displayColorPickerListagem: false });
	};
	handleClickListagem = () => {
		this.setState({
			displayColorPickerListagem: !this.state.displayColorPickerListagem,
		});
		this.setState({ displayColorPickerPrimaria: false });
		this.setState({ displayColorPickerBarra: false });
		this.setState({ displayColorPickerSecundaria: false });
		this.setState({ displayColorPickerDetalhe: false });
	};
	handleClose = () => {
		this.setState({ displayColorPicker: false });
	};
	handleChangeCompleteBarra = (color) => {
		this.setState({ barra: color.hex });
		this.forceUpdate();
	};
	handleChangeCompletePrimaria = (color) => {
		this.setState({ primaria: color.hex });
		this.forceUpdate();
	};
	handleChangeCompleteSecundaria = (color) => {
		this.setState({ secundaria: color.hex });
		this.forceUpdate();
	};

	handleChangeCompleteDetalhe = (color) => {
		this.setState({ detalhe: color.hex });
		this.forceUpdate();
	};
	handleChangeCompleteListagem = (color) => {
		this.setState({ listagem: color.hex });
		this.forceUpdate();
	};

	setFirstData() {
		//Configs API PUT DATA
		let data = {
			primaryColor: this.state.primaria,
			secondaryColor: this.state.secundaria,
			appBarColor: this.state.barra,
			priceDetailColor: this.state.detalhe,
			priceListColor: this.state.listagem,
		};
		// if (this.state.secundaria && this.state.primaria) {
		//Next Route
		this.props.dispatch(appActions.setNextRoute("/setup/brand/images"));
		//Passa para o Redux os dados para put da API
		//ajustar o TRUe para um state (Se os campos foram preenchidos)
		this.props.dispatch(
			appActions.sendData(data, true, "api/autosetup/brand/colors/", "content")
		);
		// }
	}
	render() {
		if (this.state.loading) {
			return <Loading />;
		} else {
			return this.renderScreen();
		}
	}
	renderScreen() {
		const popover = {
			position: "absolute",
			zIndex: "2",
		};
		return (
			<div className="row brand-colors align-items-center justify-content-center">
				<div className="col-sm-6 text-center">
					<h1>Cores da sua marca</h1>
					<div className="row mt-4">
						<div className="col">
							<div onClick={this.handleClick}>
								<div
									className="card-colorpicker"
									style={{ backgroundColor: this.state.barra }}
								>
									<div className="color-name">{this.state.barra}</div>
									<div className="color-label">Barra do APP</div>
								</div>
							</div>
							{this.state.displayColorPickerBarra ? (
								<div style={popover}>
									<div onClick={this.handleClose} />
									<ChromePicker
										color={this.state.barra}
										onChangeComplete={this.handleChangeCompleteBarra}
									/>
								</div>
							) : null}
						</div>
						<div className="col">
							<div onClick={this.handleClickPrimaria}>
								<div
									className="card-colorpicker"
									style={{ backgroundColor: this.state.primaria }}
								>
									<div className="color-name">{this.state.primaria}</div>
									<div className="color-label">Cor primária</div>
								</div>
							</div>
							{this.state.displayColorPickerPrimaria ? (
								<div style={popover}>
									<div onClick={this.handleClose} />
									<ChromePicker
										color={this.state.primaria}
										onChangeComplete={this.handleChangeCompletePrimaria}
									/>
								</div>
							) : null}
						</div>
						<div className="col">
							<div onClick={this.handleClickSecundaria}>
								<div
									className="card-colorpicker"
									style={{ backgroundColor: this.state.secundaria }}
								>
									<div className="color-name">{this.state.secundaria}</div>
									<div className="color-label">Cor secundária</div>
								</div>
							</div>
							{this.state.displayColorPickerSecundaria ? (
								<div style={popover}>
									<div onClick={this.handleClose} />
									<ChromePicker
										color={this.state.secundaria}
										onChangeComplete={this.handleChangeCompleteSecundaria}
									/>
								</div>
							) : null}
						</div>
						<div className="col-4 offset-2">
							<div onClick={this.handleClickDetalhe}>
								<div
									className="card-colorpicker"
									style={{ backgroundColor: this.state.detalhe }}
								>
									<div className="color-name">{this.state.detalhe}</div>
									<div className="color-label">Preço no detalhe de produto</div>
								</div>
							</div>
							{this.state.displayColorPickerDetalhe ? (
								<div style={popover}>
									<div onClick={this.handleClose} />
									<ChromePicker
										color={this.state.detalhe}
										onChangeComplete={this.handleChangeCompleteDetalhe}
									/>
								</div>
							) : null}
						</div>
						<div className="col-4">
							<div onClick={this.handleClickListagem}>
								<div
									className="card-colorpicker"
									style={{ backgroundColor: this.state.listagem }}
								>
									<div className="color-name">{this.state.listagem}</div>
									<div className="color-label">
										Preço na listagem de produto
									</div>
								</div>
							</div>
							{this.state.displayColorPickerListagem ? (
								<div style={popover}>
									<div onClick={this.handleClose} />
									<ChromePicker
										color={this.state.listagem}
										onChangeComplete={this.handleChangeCompleteListagem}
									/>
								</div>
							) : null}
						</div>
					</div>
				</div>

				{/* PREVIEW */}
				<div className="col-sm-4 text-center">
					<h1>Pré-visualização</h1>
					<Preview
						primaria={this.state.primaria}
						secundaria={this.state.secundaria}
						barra={this.state.barra}
					/>
				</div>
			</div>
		);
	}
}

export default connect((store) => ({ text: store.text }))(Colors);
