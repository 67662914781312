import { ChangeEvent, useEffect, useState } from "react";
import { OrderModel } from "../../domain/models/commerce/OrderModel";
import {
	FormErrors,
	FormValidations,
	FormValidator,
	InputValidator,
} from "../../infrastructure/utils/FormUtils";
import { ReceiptInputs } from "./ReceiptInputs";
import { CustomDataModel } from "../../domain/models/commerce/CustomDataModel";
import { cpCommerceManagementApi } from "../../infrastructure/api/CPCommerceManagement";
import NewToastComponent from "../NewToastComponent";
import { i18n } from "../../translate/i18n";
import { ReceiptFormData } from "./ReceiptFormData";
import { AttachmentKeys } from "../OrderAttachments/AttachmentKeys";

export enum CustomReceiptErrors {
	InfoCannotBeEmpty = "InfoCannotBeEmpty",
	ImageCannotBeEmpty = "ImageCannotBeEmpty",
}

export default function DeliveryReceiptDialogService(
	orderModel: OrderModel,
	onSubmitCallback: Function
) {
	const breakRowString = "\r\n";
	const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const [receiptData, setReceiptData] = useState<ReceiptFormData>({
		recipientName: "",
		recipientDocument: "",
		observation: "",
		fileUrl: "",
	});
	const [initialData, setInitialData] = useState<ReceiptFormData>({
		recipientName: "",
		recipientDocument: "",
		observation: "",
		fileUrl: "",
	});
	const formValidationMapper: FormValidations = {
		[ReceiptInputs.RecipientName]: (
			value: string,
			recipientDocument: string = receiptData.recipientDocument
		) => (recipientDocument !== "" ? InputValidator.isRequired(value) : []),
		[ReceiptInputs.RecipientDocument]: (
			value: string,
			recipientName: string = receiptData.recipientName
		) => (recipientName !== "" ? InputValidator.isRequired(value) : []),
	};

	const stringDict: { [key: string]: string } = {
		[ReceiptInputs.RecipientName]: `${i18n.t(
			`deliveryReceiptDialog.${ReceiptInputs.RecipientName}`
		)}: `,
		[ReceiptInputs.RecipientDocument]: `${i18n.t(
			`deliveryReceiptDialog.${ReceiptInputs.RecipientDocument}`
		)}: `,
		[ReceiptInputs.Observation]: `${i18n.t(
			`deliveryReceiptDialog.${ReceiptInputs.Observation}`
		)}: `,
	};

	useEffect(() => {
		if (!orderModel) return;
		getInitialDialogData();
	}, [orderModel]);

	const getInitialDialogData = () => {
		const receiptInfo = orderModel.customData.find(
			(data) => data.key === AttachmentKeys.DeliveryReceiptInfo
		);
		const receiptImage = orderModel.customData.find(
			(data) => data.key === AttachmentKeys.DeliveryReceiptImage
		);
		const { recipientName, recipientDocument, observation } = parseReceiptInfo(
			receiptInfo?.value ?? ""
		);
		const initialDataObj = {
			recipientName: recipientName,
			recipientDocument: recipientDocument,
			observation: observation,
			fileUrl: receiptImage?.value ?? "",
		};
		setInitialData(initialDataObj);
		setReceiptData(initialDataObj);
	};

	const handleCloseDialog = (): void => {
		getInitialDialogData();
		setFormErrors({});
	};

	const parseReceiptInfo = (
		receiptInfo: string
	): Omit<ReceiptFormData, "fileUrl"> => {
		const parsedData: Omit<ReceiptFormData, "fileUrl"> = {
			recipientName: "",
			recipientDocument: "",
			observation: "",
		};

		receiptInfo.split(breakRowString).forEach((info) => {
			for (const field in stringDict) {
				const stringValue = stringDict[field];
				if (info.startsWith(stringValue))
					parsedData[field as keyof Omit<ReceiptFormData, "fileUrl">] = info
						.replace(stringValue, "")
						.trim();
			}
		});

		return parsedData;
	};

	const handleFileUpload = (file: string): void => {
		setFormErrors((prevState) => ({
			...prevState,
			[CustomReceiptErrors.ImageCannotBeEmpty]: [],
		}));
		setReceiptData((prevState) => ({
			...prevState,
			fileUrl: file,
		}));
	};

	const validateSingleInput = (inputName: string, value: string) => {
		let newFormErrors: FormErrors = {};
		if (formValidationMapper[inputName]) {
			if (inputName === ReceiptInputs.RecipientName) {
				newFormErrors = {
					[ReceiptInputs.RecipientName]: formValidationMapper[
						ReceiptInputs.RecipientName
					](value, receiptData.recipientDocument),
					[ReceiptInputs.RecipientDocument]: formValidationMapper[
						ReceiptInputs.RecipientDocument
					](receiptData.recipientDocument, value),
				};
			}
			if (inputName === ReceiptInputs.RecipientDocument) {
				newFormErrors = {
					[ReceiptInputs.RecipientName]: formValidationMapper[
						ReceiptInputs.RecipientName
					](receiptData.recipientName, value),
					[ReceiptInputs.RecipientDocument]: formValidationMapper[
						ReceiptInputs.RecipientDocument
					](value, receiptData.recipientName),
				};
			}
		}
		setFormErrors((prevState) => ({
			...prevState,
			...newFormErrors,
			...(value !== "" && { [CustomReceiptErrors.InfoCannotBeEmpty]: [] }),
		}));
	};

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		validateSingleInput(name, value);

		setReceiptData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const generateUnifiedReceiptInfo = () => {
		const { recipientName, recipientDocument, observation } = receiptData;

		const parts = [
			recipientName &&
				`${stringDict[ReceiptInputs.RecipientName]}${recipientName}`,
			recipientDocument &&
				`${stringDict[ReceiptInputs.RecipientDocument]}${recipientDocument}`,
			observation && `${stringDict[ReceiptInputs.Observation]}${observation}`,
		];

		return parts.filter(Boolean).join(breakRowString);
	};

	const validateSubmitErrors = (hasInfoToBeSaved: boolean): boolean => {
		let errors: FormErrors = {};
		for (const [key, value] of Object.entries(receiptData)) {
			if (formValidationMapper[key] === undefined) continue;
			const errorMessages: string[] = formValidationMapper[key](value);
			errors[key] = errorMessages;
		}

		const hasInitialInfo = !!(
			initialData.recipientName ||
			initialData.recipientDocument ||
			initialData.observation
		);

		if (hasInitialInfo && !hasInfoToBeSaved) {
			errors[CustomReceiptErrors.InfoCannotBeEmpty] = [
				CustomReceiptErrors.InfoCannotBeEmpty,
			];
		}
		if (initialData.fileUrl && !receiptData.fileUrl) {
			errors[CustomReceiptErrors.ImageCannotBeEmpty] = [
				CustomReceiptErrors.ImageCannotBeEmpty,
			];
		}

		setFormErrors(errors);
		return FormValidator.hasError(errors);
	};

	const handleSubmit = async () => {
		const hasInfoToBeSaved = !!(
			receiptData.recipientName ||
			receiptData.recipientDocument ||
			receiptData.observation
		);

		const hasErrors = validateSubmitErrors(hasInfoToBeSaved);
		if (hasErrors) return;

		setIsLoadingSubmit(true);
		const customDataBatch: CustomDataModel[] = [];

		hasInfoToBeSaved &&
			customDataBatch.push({
				key: AttachmentKeys.DeliveryReceiptInfo,
				value: generateUnifiedReceiptInfo(),
			});

		receiptData.fileUrl &&
			customDataBatch.push({
				key: AttachmentKeys.DeliveryReceiptImage,
				value: receiptData.fileUrl,
			});

		if (customDataBatch.length === 0) {
			onSubmitCallback();
			return;
		}

		const upsertSuccess = await cpCommerceManagementApi.upsertCustomData(
			orderModel.id,
			customDataBatch
		);

		if (!upsertSuccess) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			return;
		}
		NewToastComponent({
			status: "success",
			title: i18n.t("deliveryReceiptDialog.ReceiptUpsertSuccess"),
		});

		await cpCommerceManagementApi.createActionLog(orderModel.id, {
			title: i18n.t("deliveryReceiptDialog.CreateLogTitle"),
			displayMessage: i18n.t("deliveryReceiptDialog.CreateLogMessage"),
		});

		setIsLoadingSubmit(false);
		onSubmitCallback();
	};

	return {
		isLoadingSubmit,
		receiptData,
		formErrors,
		handleFileUpload,
		handleInputChange,
		handleSubmit,
		handleCloseDialog,
	};
}
