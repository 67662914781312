import React, { FC, useContext } from "react";
import { Modal, Stack, Box, Button } from "@mui/material";
import { i18n } from "../../../translate/i18n";
import { ModalBody, ModalList } from "../styles/CategoryStyles";
import CategoryFormService from "./CategoryForm/CategoryFormService";
import { CategoryView } from "../../../domain/views/categories/CategoryView";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import CustomIcon from "../../../MUICustomComponents/CustomIcon";
import { WarningAmberRounded } from "@mui/icons-material";
import CustomScrollbar from "../../../MUICustomComponents/CustomScrollbar";
import { CategoryContext } from "../context/CategoryContext";

import * as Styled from "../styles/CategoryStyles";

const recursiveCategoryList = (data: CategoryView[]) => {
	return (
		<ModalList>
			{data.map((category: CategoryView) => (
				<li key={category.id}>
					<CustomTypography variant="default">{category.name}</CustomTypography>
					{category.children && recursiveCategoryList(category.children)}
				</li>
			))}
		</ModalList>
	);
};

export const DeleteModal: FC = () => {
	const { deleteCategory, isLoading } = CategoryFormService();

	const { modalIsOpen, setModalIsOpen, categoryToBeDeleted } =
		useContext(CategoryContext);

	return (
		<Modal
			open={modalIsOpen}
			onClose={() => setModalIsOpen(false)}
			aria-labelledby="delete-modal-title"
			aria-describedby="delete-modal-description"
		>
			<ModalBody>
				<CustomTypography variant="secondaryTitle">
					{i18n.t(`categories.Titles.DeleteCategoryTitle`).toString()}
				</CustomTypography>

				<Styled.BoxAttention>
					<CustomIcon icon={<WarningAmberRounded />} variant="default" />
					<Box width={400} pl={2}>
						<CustomTypography variant="default">
							{i18n.t("categories.Messages.DeleteCategoriesMessage").toString()}
						</CustomTypography>
					</Box>
				</Styled.BoxAttention>

				<Box mb={2}>
					<CustomTypography variant="default">
						<strong>
							{i18n.t(`categories.Titles.CategoryName`).toString()}:&nbsp;
						</strong>
						{categoryToBeDeleted.name}
					</CustomTypography>
				</Box>

				{categoryToBeDeleted.children.length > 0 && (
					<>
						<CustomTypography variant="default">
							<strong>
								{i18n.t(`categories.Titles.SubCategory`).toString()}:&nbsp;
							</strong>
						</CustomTypography>
						<CustomScrollbar minHeight={"50px"} maxHeight={"100px"}>
							{recursiveCategoryList(categoryToBeDeleted.children)}
						</CustomScrollbar>
					</>
				)}

				<Stack direction="row" justifyContent="end" mt={4}>
					<Box mr={"10px"}>
						<Button
							variant="contained"
							color="light"
							disabled={isLoading}
							onClick={() => {
								setModalIsOpen(false);
							}}
						>
							{i18n.t(`buttons.General.BtnCancel`).toString()}
						</Button>
					</Box>
					<Button
						variant="outlined"
						color="danger"
						disabled={isLoading}
						onClick={() => deleteCategory(categoryToBeDeleted.id ?? "")}
					>
						{i18n.t(`buttons.General.BtnConfirmDelete`).toString()}
					</Button>
				</Stack>
			</ModalBody>
		</Modal>
	);
};
