export enum PaymentTypeSM {
	CreditCard = 0,
	DebitCard = 1,
	PickupStore = 2,
	Bankslip = 3,
	Voucher = 4,
	Cashback = 5,
	Pix = 6,
	InstallmentPix = 7,
	PaymentLink = 8,
	TerminalCreditCard = 9,
	TerminalDebitCard = 10,
	Invoice = 11,
}
export enum SMPaymentTypeDescription {
	CreditCard = "CreditCard",
	DebitCard = "DebitCard",
	PickupStore = "PickupStore",
	Bankslip = "Bankslip",
	Voucher = "Voucher",
	Cashback = "Cashback",
	Pix = "Pix",
	InstallmentPix = "InstallmentPix",
	PaymentLink = "PaymentLink",
	TerminalCreditCard = "TerminalCreditCard",
	TerminalDebitCard = "TerminalDebitCard",
	Invoice = "Invoice",
}
