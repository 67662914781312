import React, { useEffect } from "react";
import { useOrders } from "./providers/OrdersContext";
import ItemsOrders from "./components/OrderDetails/ItemsOrders";
import TrackingOrderDelivery from "./components/OrderDetails/TrackingOrderDelivery";
import Payments from "./components/OrderDetails/Payments";
import ShippingType from "./components/OrderDetails/ShippingType";
import ClientInfo from "./components/OrderDetails/ClientInfo";
import OrderInfo from "./components/OrderDetails/OrderInfo";
import OrderFlow from "./components/OrderDetails/OrderFlow";
import Invoicing from "./components/OrderDetails/Invoicing";
import TrackingOrderPickupInStore from "./components/OrderDetails/TrackingOrderPickupInStore";

import "./OrdersStyle.scss";
export default function OrderDetails({ id, historic, handleHistoryLoading }) {
	const { setLoading, order, getOrders, getOrderProcess, loadingHistory } =
		useOrders();
	useEffect(() => {
		setLoading(true);
		getOrders(id);
	}, []);
	useEffect(() => {
		getOrderProcess(id);
	}, []);

	useEffect(() => {
		handleHistoryLoading(loadingHistory);
	}, [loadingHistory]);

	const iconCards = (icons) => {
		return (
			<div className={`icon-card-detail ${icons}`}>
				<i className="material-icons-outlined">{icons}</i>
			</div>
		);
	};

	return (
		<div>
			<section className="grid mt-4">
				<div className="card order-box mt-3">
					{iconCards("receipt_long")}
					<OrderInfo />
				</div>
				<div className="card order-box mt-3">
					{iconCards("attach_money")}
					<Payments />
				</div>
				<div className="card order-box mt-3">
					{iconCards("receipt")}
					<Invoicing id={id} />
				</div>

				<div className="card order-box mt-3">
					{iconCards("person_outline")}
					<ClientInfo />
				</div>
				<div className="card order-box mt-3">
					{iconCards("card_giftcard")}
					<ShippingType />
				</div>
				<div className="card order-box mt-3">
					{order.IsDelivery
						? iconCards("local_shipping")
						: iconCards("shopping_bag")}
					{order.IsDelivery ? (
						<TrackingOrderDelivery />
					) : (
						<TrackingOrderPickupInStore />
					)}
				</div>
				<div className="card flow-status order-box">
					<OrderFlow historic={historic} />
				</div>
			</section>
			<section className="my-3">
				<div className="card order-box mt-3">
					<ItemsOrders />
				</div>
			</section>
		</div>
	);
}
