enum AddressInputs {
	Street = "street",
	Number = "number",
	Cep = "cep",
	Complement = "complement",
	Neighborhood = "neighborhood",
	State = "state",
	City = "city",
}

export { AddressInputs };
