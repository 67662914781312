import React, { useState, useEffect } from "react";
import { API } from "../../../API";
import Products from "../../../components/Products";
import "react-widgets/dist/css/react-widgets.css";
import Button from "../../../components/Button";
import ContentLoader from "react-content-loader";
import { useConfig } from "./../../../providers/ConfigProvider";
import LoadDashboard from "../../Dashboard/components/LoadDashboard";
import NewToastComponent from "../../../components/NewToastComponent";

import "./CollectionsStyle.scss";

export default function NewCollection(props) {
	const { mainLoading } = useConfig();
	const [loading, setLoading] = useState(true);
	const [edit, setEdit] = useState(false);
	const [products, setProducts] = useState([]);
	const [id, setId] = useState(props.match.params.id);
	const [name, setName] = useState("");
	const [url, setUrl] = useState(null);
	const [disable, setDisable] = useState(false);

	useEffect(() => {
		setLoading(false);
		if (id) {
			getNewColections(id);
		}
	}, []);

	const getNewColections = (id) => {
		setLoading(true);
		setEdit(true);
		API.get(`api/tags/${id}`)
			.then(async (response) => {
				for (let i = 0; i < response.data.products.length; i++) {
					products[i] = response.data.products[i].product;
				}
				setId(response.data.id);
				setName(response.data.name);
				setUrl(response.data.url);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
				NewToastComponent({
					status: "error",
					title: "Parece que tivemos um erro...",
					message: "Tente novamente mais tarde.",
				});
			});
	};

	const handleChange = (e) => {
		setName(e.target.value.replace("/", ""));
	};
	const getProducts = (e) => {
		setProducts(e);
	};

	const create = (e) => {
		e.preventDefault();
		setDisable(true);

		let errorMsg;
		if (!name && products.length <= 0)
			errorMsg =
				"Para criar uma coleção, insira o nome e os produtos desejados!";
		else if (!name) errorMsg = "Para criar uma coleção, insira o nome";
		else if (products.length <= 0)
			errorMsg = "Para criar uma coleção, adicione os produtos desejados!";

		if (errorMsg) {
			NewToastComponent({
				status: "error",
				title: errorMsg,
			});
			setDisable(false);
			return;
		}

		const body = {
			id: id,
			name: name,
			products: products,
			url: name.replace(/\s/g, "-"),
		};
		for (let i = 0; i < products.length; i++) {
			body.products[i]["productId"] = products[i].id;
		}
		if (edit) {
			API.put("api/tags", body)
				.then(async () => {
					NewToastComponent({
						status: "success",
						title: "Coleção editada com sucesso!",
					});
					setDisable(false);
				})
				.catch(() => {
					NewToastComponent({
						status: "error",
						title: "Parece que tivemos um erro...",
						message: "Tente novamente mais tarde.",
					});
					setDisable(false);
				});
		} else {
			API.post("api/tags", body)
				.then(async (response) => {
					NewToastComponent({
						status: "success",
						title: "Coleção criada com sucesso!",
					});
					setDisable(false);
					window.location.href = `/collections/${props.match.params.page}/edit/${response.data.id}`;
				})
				.catch(() => {
					NewToastComponent({
						status: "error",
						title: "Parece que tivemos um erro...",
						message: "Tente novamente mais tarde.",
					});
					setDisable(false);
				});
		}
	};

	const renderScreen = () => {
		return (
			<>
				<div className="card-header">
					<h4 className="card-title">Dados da coleção</h4>
				</div>
				<div className="card-content">
					<div className="card-body pt-0">
						<form onSubmit={create} className="row">
							<div className="col-12">
								<div className="form-group col-12">
									<label htmlFor="codigoCupom">
										<strong>Nome da coleção</strong>
									</label>
									<div>
										<input
											type="text"
											className="form-control"
											id="codigoCupom"
											placeholder="Exemplo coleção"
											name="name"
											value={name}
											onChange={handleChange}
										/>
									</div>
								</div>
								<div className="form-group col-12 mt-2">
									<Products
										getProducts={getProducts}
										externalProducts={products}
									/>
								</div>
								<div className="form-group col-12">
									<Button
										className="btn btn-success"
										loading={disable}
										disable={disable}
										type="submit"
									>
										Salvar
									</Button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</>
		);
	};

	const skeleton = () => {
		return (
			<>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="490" height="40" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="360" height="40" />
					<rect x="390" y="25" rx="3" ry="3" width="140" height="40" />
				</ContentLoader>
			</>
		);
	};
	return (
		<>
			{mainLoading ? (
				<LoadDashboard />
			) : (
				<div className="container dash-content">
					<div className="card">{loading ? skeleton() : renderScreen()}</div>
				</div>
			)}
		</>
	);
}
