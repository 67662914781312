import React, { useState } from "react";
import { APIV2, APIIMPORT } from "../../../../API";
import Axios from "axios";
import { useMatcher } from "../providers/MatcherContext";
import NewToastComponent from "../../../../components/NewToastComponent";

function MatcherUploadComponent({
	icon,
	tooltip,
	type,
	downloadLink,
	uploadLink,
}) {
	const [output, setOutput] = useState("");
	const [importValue, setImportValue] = useState("");
	const [loadingImport, setLoadingImport] = useState(false);
	const { getStatus, loadingDownload, setLoadingDownload } = useMatcher();

	const download = async () => {
		try {
			setLoadingDownload(true);
			NewToastComponent({
				status: "success",
				title: "Iniciando o download da planilha de mapeamento. Aguarde!",
			});
			const response = await APIV2.get(downloadLink);
			let statusResponse = {};
			const apiStatusInterval = setInterval(async () => {
				statusResponse = await Axios.get(response.data.statusQueryGetUri);
				if (statusResponse.data.runtimeStatus === "Completed") {
					setOutput(statusResponse.data.output);
					clearInterval(apiStatusInterval);
					document.getElementById(type).click();
					setLoadingDownload(false);
					return false;
				}
			}, 3000);
		} catch {
			setLoadingDownload(false);
		}
	};

	const importDocument = () => async (event) => {
		setLoadingImport(true);
		let formData = new FormData();
		formData.append("file", event.target.files[0]);
		try {
			const response = await APIIMPORT.post(uploadLink, formData, {
				headers: { "Content-Type": "multipart/form-data" },
			});
			setLoadingImport(false);
			NewToastComponent({
				status: "success",
				title:
					"Planilha de mapeamento de (categoria, marca ou atributos) enviada com sucesso!",
			});
			getStatus();
		} catch {
			setLoadingImport(false);
			NewToastComponent({
				status: "error",
				title:
					"Não foi possível concluír o envio da planilha de mapeamento de (categoria, marca ou atributos)",
			});
		}
	};

	return (
		<div className="import-matcher">
			<span className="import-matcher-icon">
				<i className="material-icons">{icon}</i>
			</span>
			<div className="import-matcher-description">
				<p>
					Mapear {type}
					{"   "}
					<span className="tooltipNeoMatcher ml-1">
						<i className="material-icons">help_outline</i>
						<span>{tooltip}</span>
					</span>
				</p>

				<a className="d-none" id={type} href={output}></a>
				<button
					className="btn btn-link link p-0"
					disabled={loadingDownload}
					onClick={() => download()}
				>
					Baixar a última planilha mapeada
				</button>
			</div>
			<div className="import-matcher-upload">
				<label className="btn btn-grey-80 btn-block mb-0">
					<input type="file" onChange={importDocument()} value={importValue} />
					{loadingImport === true ? (
						<i className="fas fa-spinner fa-spin"></i>
					) : (
						"Atualizar Mapeamento"
					)}
				</label>
			</div>
		</div>
	);
}

export default MatcherUploadComponent;
