import React, { Dispatch, SetStateAction } from "react";
import CustomAutocomplete from "../../MUICustomComponents/CustomAutocomplete";
import { i18n } from "../../translate/i18n";
import StateAutocompleteService from "./StateAutocompleteService";
import { AutocompleteStateView } from "../../domain/views/legacyApi/AutocompleteStateView";

type Props = {
	stateCode: string | null;
	currentState: AutocompleteStateView | null;
	setCurrentState: Dispatch<SetStateAction<AutocompleteStateView | null>>;
	handleAutocompleteChange: (state: AutocompleteStateView | null) => void;
	id: string;
	error?: boolean;
	helperText?: string[];
	disabled?: boolean;
};

export default function StateAutocomplete({
	stateCode,
	currentState,
	setCurrentState,
	handleAutocompleteChange,
	id,
	error = false,
	helperText = [],
	disabled = false,
}: Props) {
	const {
		statesList,
		currentStateInput,
		setCurrentStateInput,
		// Functions
		filterOptions,
		getOptionLabel,
		isOptionEqualToValue,
	} = StateAutocompleteService(stateCode, setCurrentState);

	return (
		<CustomAutocomplete
			id={id}
			renderInputLabel={i18n.t("components.Autocomplete.State")}
			placeholder={i18n.t("components.Autocomplete.State")}
			options={statesList}
			value={currentState}
			onSelectChange={handleAutocompleteChange}
			inputValue={currentStateInput}
			onInputChange={setCurrentStateInput}
			filterOptions={filterOptions}
			getOptionLabel={getOptionLabel}
			isOptionEqualToValue={isOptionEqualToValue}
			error={error}
			helperText={helperText}
			disabled={disabled}
		/>
	);
}
