import React, { Component } from "react";
import { API } from "../../../API";
import SimpleCard from "../../../assets/SimpleCard";
import { connect } from "react-redux";
import Loading from "../../../components/Loading";
import appActions from "../../../appActions";
export class Materials extends Component {
	state = {
		content: [],
		loading: true,
	};

	componentWillMount() {
		//Consulta materiais disponíveis a partir do token do usuário.
		API.get("api/material")
			.then((response) => {
				// If request is good...
				this.setState({ content: response.data, loading: false });
				this.forceUpdate();
			})
			.catch((error) => {
				console.log(error);
			});
	}
	componentDidMount() {
		this.setNextRoute();
	}
	setNextRoute() {
		//Passa para o Redux o proximo passo do autosetup
		this.props.dispatch(
			appActions.setNextRoute("/setup/onboarding/basic-info")
		);
		this.props.dispatch(appActions.sendData(null, null, null, "no-content"));
	}
	render() {
		if (this.state.loading) {
			return <Loading />;
		} else {
			return this.renderScreen();
		}
	}
	renderScreen() {
		return (
			<div className="materials d-flex align-items-center">
				<div className="col">
					<div className="container sm">
						<h1>Materiais de apoio</h1>
						<p>
							Aqui você encontra uma seleção de materiais criados pela Neomode
							para ajudar você na sua nova jornada omnichannel.
						</p>
					</div>
					<div className="container mt-3">
						<div className="row mt-4">
							{/* Loop para listar o conteudo de Materials recebido da API */}
							{this.state.content.map((item, i) => (
								<div className="col-4 mb-3" key={i}>
									<SimpleCard
										title={item.title}
										link={item.linkDownload}
										description={item.description}
										icon={item.typeDownload}
									/>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect((store) => ({ text: store.text }))(Materials);
