import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { i18n } from "../../../translate/i18n";

function Invoice(invoice) {
	pdfMake.vfs = pdfFonts.pdfMake.vfs;

	const getPaymentMethodType = (paymentType) => {
		let paymentMethodList = [
			"CreditCard",
			"DebitCard",
			"PickupStore",
			"Bankslip",
			"Voucher",
			"Cashback",
			"Pix",
			"InstallmentPix",
			"PaymentLink",
		];
		return paymentMethodList?.some((method) => method === paymentType)
			? i18n.t(`orderDetails.Payments.${paymentType}`)
			: i18n.t(`orderDetails.Payments.NotFound}`);
	};

	const renderHeaderPDF = [
		{
			columns: [
				invoice?.image && {
					image: "snow",
					alignment: "left",
					maxWidth: 120,
					mexHeight: 100,
				},

				{
					text: [
						{
							text: [
								{
									alignment: "right",
									text: {
										alignment: "right",
										text: `Pedido nº ${invoice?.friendlyCode}\n`,
										bold: true,
										color: "#4B5F79",
										fontSize: 13,
									},
								},
								{
									alignment: "right",
									text: {
										alignment: "right",
										text: `Data do pedido: ${invoice?.createDate}\n\n\n`,
										bold: true,
										color: "#4B5F79",
										fontSize: 13,
									},
								},
							],
						},
					],
				},
			],
		},
	];

	const renderInformationInvoicePDF = [
		{
			columns: [
				{
					text: [
						{
							text: [
								{
									alignment: "left",
									text: `Data:  ${invoice?.createDate}\n`,
									bold: true,
									color: "#747E8B",
									fontSize: 10,
								},
								{
									alignment: "left",
									text: `Total:  ${invoice?.totalAmount?.toLocaleString(
										"pt-br",
										{
											style: "currency",
											currency: "BRL",
										}
									)}\n`,
									bold: true,
									color: "#747E8B",
									fontSize: 10,
								},
								{
									alignment: "left",
									text: `${
										localStorage.getItem("role") === "8"
											? "Tipo de envio:"
											: "Modalidade:"
									} ${invoice?.modality}\n`,
									bold: true,
									color: "#747E8B",
									fontSize: 10,
								},
							],
						},
					],
				},
				{
					text: [
						{
							text: [
								{
									alignment: "left",
									text: `Pagamento: ${
										invoice?.paymentStatus === "Pending"
											? "Não iniciado"
											: invoice?.paymentStatus === "PreApproved"
											? "Pré aprovado"
											: invoice?.paymentStatus === "FraudAnalysis"
											? "Em análise"
											: invoice?.paymentStatus === "WaitingCapture"
											? "Em análise"
											: invoice?.paymentStatus === "Error"
											? "Recusado"
											: invoice?.paymentStatus === "Paid"
											? "Pago"
											: invoice?.paymentStatus === "WaitingRefund"
											? "Em análise"
											: invoice?.paymentStatus === "Refunded"
											? "Estornado"
											: "Em análise"
									}\n`,
									bold: true,
									color: "#747E8B",
									fontSize: 10,
								},
							],
						},
					],
				},
			],
		},
	];

	const typePaymentMethod = invoice?.payments.map((payment, index) => {
		console.log(invoice);
		return [
			{
				alignment: "left",
				columns: [
					{
						text: [
							payment?.PaymentMethod?.Type && {
								text: [
									{
										text: `Método de pagamento: `,
										bold: true,
										color: "#747E8B",
										fontSize: 11,
									},
									{
										text: `${getPaymentMethodType(
											payment?.PaymentMethod?.Type
										)}\n`,
										color: "#747E8B",
										fontSize: 11,
									},
								],
							},
							payment?.PaymentMethod?.Info && {
								text: [
									{
										text: `${payment?.PaymentMethod?.Info}\n`,
										color: "#747E8B",
										fontSize: 11,
									},
								],
							},
							payment?.Amount && {
								text: [
									{
										text: `Valor: `,
										bold: true,
										color: "#747E8B",
										fontSize: 11,
									},
									{
										text: `${payment?.Amount?.toLocaleString("pt-br", {
											style: "currency",
											currency: "BRL",
										})}\n`,
										color: "#747E8B",
										fontSize: 11,
									},
								],
							},
							payment?.Installments && {
								text: [
									{
										text: `Parcelas: `,
										bold: true,
										color: "#747E8B",
										fontSize: 11,
									},
									{
										text: `${payment?.Installments}\n`,
										color: "#747E8B",
										fontSize: 11,
									},
								],
							},
							payment?.Message && {
								text: [
									{
										text: `Mensagem do adquirente: `,
										bold: true,
										color: "#747E8B",
										fontSize: 11,
									},
									{
										text: `${payment?.Message}\n`,
										color: "#747E8B",
										fontSize: 11,
									},
								],
							},
							payment?.Nsu && {
								text: [
									{
										text: `NSU: `,
										bold: true,
										color: "#747E8B",
										fontSize: 11,
									},
									{
										text: `${payment?.PaymentMethod?.Nsu}\n`,
										color: "#747E8B",
										fontSize: 11,
									},
								],
							},
							{ text: `\n` },
						],
					},
				],
			},
		];
	});

	const renderInformationsPDF = [
		{
			alignment: "left",
			columns: [
				{
					text: [
						{
							text: `PEDIDO PARA: \n\n`,
							bold: true,
							color: "#747E8B",
							fontSize: 10,
						},
						invoice?.buyerFullName && {
							text: `${invoice?.buyerFullName}\n`,
							bold: true,
							color: "#4B5F79",
							fontSize: 14,
						},
						invoice?.buyerDocument && {
							text: `${invoice?.buyerDocument}\n`,
							bold: true,
							color: "#747E8B",
							fontSize: 10,
						},
						invoice?.buyerEmail && {
							text: `${invoice?.buyerEmail}\n`,
							bold: true,
							color: "#7A9FEB",
							fontSize: 10,
						},
						invoice?.buyerFullAddress && {
							text: `${invoice?.buyerFullAddress}\n`,
							bold: true,
							color: "#747E8B",
							fontSize: 10,
						},
					],
				},
				{
					text: [
						{
							text: `PEDIDO DE: \n\n`,
							bold: true,
							color: "#747E8B",
							fontSize: 10,
						},
						invoice?.sellerFullName && {
							text: `${invoice?.sellerFullName}\n`,
							bold: true,
							color: "#4B5F79",
							fontSize: 14,
						},
						invoice?.sellerDocument && {
							text: `${invoice?.sellerDocument}\n`,
							bold: true,
							color: "#747E8B",
							fontSize: 10,
						},
						invoice?.sellerEmail && {
							text: `${invoice?.sellerEmail}\n`,
							bold: true,
							color: "#7A9FEB",
							fontSize: 10,
						},

						invoice?.sellerFullAddress && {
							text: `${invoice?.sellerFullAddress}\n`,
							bold: true,
							color: "#747E8B",
							fontSize: 10,
						},
					],
				},
			],
		},
	];

	// prettier-ignore
	const products = invoice?.items.map((item, index) => {
		const title = "Cód Produto: "
		const productName = item?.Sku?.Name !== null ? item?.Sku?.Name : ""
		const productCode = item?.Sku?.ProductCode !== null ? (title + item?.Sku?.ProductCode) : ""
		const referenceCode = item?.Sku?.ReferenceCode !== null ? (title + item?.Sku?.ReferenceCode) : ""
		const auxCode = item?.Sku?.AuxCode !== null ? (title + item?.Sku?.AuxCode) : ""
		const description =
			invoice?.productDisplayCodeName === "Code" ? productCode :
			invoice?.productDisplayCodeName === "ReferenceCode" ? referenceCode :
			invoice?.productDisplayCodeName === "AuxCode" ? auxCode : ""
		const skuCode = item?.Sku?.Code !== null ? " Cód sku: " + item?.Sku?.Code : ""
		const barCode = item?.Sku?.BarCode !== null ? " EAN: " + item?.Sku?.BarCode : ""
		return [
			{
				text: `\n${index + 1}\n\n`,
				color: "#BCBEC0",
				fontSize: 8,
				alignment: "center",
				//border: [false, true, false, true],
				margin: [0, 5, 0, 0],
			},
			{
				text: `\n${productName}\n ${description} ${skuCode} ${barCode}\n`,
				color: "#747E8B",
				alignment: "left",
				fontSize: 8,
				//border: [false, true, false, true],
				margin: [0, 0, 0, 5],
			},
			{
				text: `\n${item?.Quantity}\n`,
				color: "#747E8B",
				bold: true,
				alignment: "center",
				fontSize: 8,
				//border: [false, true, false, true],
				margin: [0, 5, 0, 0],
			},
			{
				text: `\n${item?.UnitPrice?.toLocaleString("pt-br", {
					style: "currency",
					currency: "BRL",
				})}\n`,
				alignment: "center",
				color: "#BCBEC0",
				fontSize: 8,
				//border: [false, true, false, true],
				margin: [0, 5, 0, 0],
			},
			{
				text: `\n${item?.TotalAmount?.toLocaleString("pt-br", {
					style: "currency",
					currency: "BRL",
				})}\n`,
				alignment: "right",
				color: "#747E8B",
				fontSize: 8,
				//border: [false, true, false, true],
				margin: [0, 5, 0, 0],
			},
		];
	});

	const renderTablePDF = [
		{
			text: `\n\nItens do Pedido (${invoice?.items?.length})\n\n`,
			bold: true,
			color: "#4B5F79",
			fontSize: 12,
		},
		{
			table: {
				headerRows: 1,
				widths: [20, 320, 30, "*", "*"],
				body: [
					[
						{
							text: "Nº",
							fontSize: 10,
							alignment: "center",
							color: "#747E8B",
							border: [false, true, false, false],
							margin: [0, 4, 0, 4],
						},
						{
							text: "ITEM",
							fontSize: 10,
							color: "#747E8B",
							border: [false, true, false, false],
							margin: [0, 4, 0, 4],
						},
						{
							text: "QTD",
							fontSize: 10,
							alignment: "center",
							color: "#747E8B",
							border: [false, true, false, false],
							margin: [0, 4, 0, 4],
						},
						{
							text: "PREÇO UNIT",
							fontSize: 10,
							alignment: "center",
							color: "#747E8B",
							border: [false, true, false, false],
							margin: [0, 4, 0, 4],
						},
						{
							text: "TOTAL",
							fontSize: 10,
							alignment: "right",
							color: "#747E8B",
							border: [false, true, false, false],
							margin: [0, 4, 0, 4],
						},
					],
					...products,
				],
			},
			layout: {
				hLineColor: function (i, node) {
					return i === 0 || i === node.table.body.length
						? "#E0E3E7"
						: "#E0E3E7";
				},
				vLineColor: function (i, node) {
					return i === 0 || i === node.table.widths.length
						? "#ffffff"
						: "#ffffff";
				},
			},
		},
	];

	const renderTotalInvoicePDF = [
		{
			defaultStyle: { alignment: "right" },
			layout: "noBorders",
			alignment: "right",
			table: {
				widths: ["*", 50],
				body: [
					[
						{
							text: `SubTotal:`,
							fontSize: 10,
							bold: true,
							color: "#747E8B",
							alignment: "right",
						},
						{
							text: `${invoice?.itemsAmount?.toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
							})}`,
							fontSize: 10,
							color: "#747E8B",
							noWrap: true,
						},
					],
					[
						{
							text: `Desconto:`,
							fontSize: 10,
							bold: true,
							color: "#747E8B",
							alignment: "right",
						},
						{
							text: `${invoice?.discountAmount?.toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
							})}`,
							fontSize: 10,
							color: "#747E8B",
							noWrap: true,
						},
					],
					[
						{
							text: `Taxas:`,
							fontSize: 10,
							bold: true,
							color: "#747E8B",
							alignment: "right",
						},
						{
							text: `${invoice?.feeAmount?.toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
							})}`,
							fontSize: 10,
							color: "#747E8B",
							noWrap: true,
						},
					],
					[
						{
							text: `Frete:`,
							fontSize: 10,
							bold: true,
							color: "#747E8B",
							alignment: "right",
						},
						{
							text: `${invoice?.shippingAmount?.toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
							})}`,
							fontSize: 10,
							color: "#747E8B",
							noWrap: true,
						},
					],
					[
						{
							text: `------------------`,
							fontSize: 10,
							bold: true,
							color: "#E0E3E7",
							alignment: "right",
						},
						{
							text: `------------------`,
							fontSize: 10,
							bold: true,
							color: "#E0E3E7",
							noWrap: true,
						},
					],
					[
						{
							text: `Valor Total:`,
							fontSize: 12,
							bold: true,
							color: "#747E8B",
							alignment: "right",
						},
						{
							text: `${invoice?.totalAmount?.toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
							})}`,
							fontSize: 12,
							color: "#747E8B",
							noWrap: true,
						},
					],
				],
			},
		},
	];

	function renderFooterPDF(currentPage, pageCount) {
		return [
			{
				text: currentPage,
				alignment: "right",
				bold: true,
			},
		];
	}

	const invoiceOrders = () => {
		let order = {
			pageSize: "A4",
			pageMargins: [30, 30, 30, 2],
			images: {
				snow: `${invoice?.image}`,
			},
			content: [
				renderHeaderPDF,
				{
					text: "___________________________________________________________________________________________________\n\n",
					bold: true,
					color: "#E0E3E7",
					fontSize: 12,
				},
				renderInformationInvoicePDF,
				{
					text: "___________________________________________________________________________________________________\n\n",
					bold: true,
					color: "#E0E3E7",
					fontSize: 12,
				},
				{
					text: "\n",
					color: "#E0E3E7",
					fontSize: 2,
				},
				renderInformationsPDF,
				renderTablePDF,
				{
					text: "\n",
					color: "#E0E3E7",
					fontSize: 15,
				},
				renderTotalInvoicePDF,
				{
					text: `\n\nPagamento ( ${invoice?.payments?.length} método(s) )\n`,
					bold: true,
					color: "#4B5F79",
					fontSize: 12,
				},
				{
					text: "___________________________________________________________________________________________________\n\n",
					bold: true,
					color: "#E0E3E7",
					fontSize: 12,
				},
				//renderPaymentData,
				typePaymentMethod,
			],
			footer: [renderFooterPDF],
		};
		return order;
	};

	pdfMake.createPdf(invoiceOrders()).open({}, window.open("", "_blank"));
}

export default Invoice;
