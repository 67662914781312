import React, { useEffect, useState } from "react";

function FilterOrdersModal({ filterSelect, arrStatus, arrModality }) {
	const [status, setStatus] = useState([]);
	const [countStatus, setCountStatus] = useState(0);
	const [countModality, setCountModality] = useState(0);
	const [statusList, setStatusList] = useState([
		{ checked: false, value: "Finalized", name: "Pedido Finalizado" },
		{ checked: false, value: "Canceled", name: "Pedido Cancelado" },
		{ checked: false, value: "Error", name: "Pedido com Erro" },
		{
			checked: false,
			value: "CreatedExternal",
			name: "Pedido criado pelo ponto de venda",
		},
		{ checked: false, value: "AwaitingCreation", name: "Criação Pendente" },
		{ checked: false, value: "CreatedInternal", name: "Pedido Criado" },
		{
			checked: false,
			value: "AwaitingInvoiceCreation",
			name: "Pedido em Faturamento",
		},
		{ checked: false, value: "InvoiceCreated", name: "Pedido Faturado" },
		{ checked: false, value: "Separated", name: "Pedido Separado" },
		{ checked: false, value: "ReadyForPickup", name: "Pronto para Retirada" },
		{ checked: false, value: "Shipping", name: "Pedido em Transporte" },
	]);
	const [modalityList, setModalityList] = useState([
		{ checked: false, value: "ReciveHome", name: "Receba em Casa" }, //true
		{ checked: false, value: "PickupStore", name: "Retire na Loja" }, // false
	]);
	const [triggerStatusTags, setTriggerStatusTags] = useState(false);

	useEffect(() => {
		getStatusOrders();
		getModalitiesOrders();
		setTriggerStatusTags(!triggerStatusTags);
	}, []);

	useEffect(() => {
		getFilterSelect(false);
	}, [triggerStatusTags]);

	const getStatusOrders = () => {
		if (arrStatus) {
			let result = statusList.reduce(
				(acc, obj) =>
					acc.concat(
						arrStatus.includes(obj.value)
							? Object.assign(obj, { checked: true })
							: obj
					),
				[]
			);
			setStatusList(result);
		}
		// //get count
		let countFilterStatus = statusList.filter((countFilter) => {
			if (countFilter.checked === true) {
				return countFilter;
			}
		});
		setCountStatus(countFilterStatus.length);
	};

	const getModalitiesOrders = () => {
		if (arrModality.length <= 0) {
			setModalityList([
				{ checked: false, value: "ReciveHome", name: "Receba em Casa" }, //true
				{ checked: false, value: "PickupStore", name: "Retire na Loja" }, // false
			]);
			setCountModality(0);
		} else if (arrModality.length > 1) {
			let valueModalite =
				arrModality === "false" ? "PickupStore" : "ReciveHome";
			const arrData = [];
			modalityList.forEach((data) => {
				if (valueModalite === data.value) {
					const body = {
						checked: true,
						value: data.value,
						name: data.name,
					};
					arrData.push(body);
				} else {
					const body = {
						checked: false,
						value: data.value,
						name: data.name,
					};
					arrData.push(body);
				}
			});
			setModalityList(arrData);
			setCountModality(1);
		} else {
			const arrData = [];
			modalityList.forEach((data) => {
				if (arrModality[0].value === data.value) {
					const body = {
						checked: true,
						value: data.value,
						name: data.name,
					};
					arrData.push(body);
				} else {
					const body = {
						checked: false,
						value: data.value,
						name: data.name,
					};
					arrData.push(body);
				}
			});
			setModalityList(arrData);
			setCountModality(1);
		}
	};

	const handleChooseStatus = (event) => {
		let result = statusList.reduce(
			(acc, object) =>
				acc.concat(
					event.target.value.includes(object.value)
						? Object.assign(object, { checked: event.target.checked })
						: object
				),
			[]
		);
		setStatusList(result);
		//get count
		let countFilterStatus = statusList.filter((countFilter) => {
			if (countFilter.checked === true) {
				return countFilter;
			}
		});
		setCountStatus(countFilterStatus.length);

		let statusObj = {
			checked: event.target.checked,
			name: event.target.name,
			value: event.target.value,
		};
		if (statusObj.length <= 0) {
			setStatus([{ checked: event.target.checked, value: "", name: "" }]);
		} else {
			setStatus([...status, statusObj]);
		}
	};

	const handleChooseModality = (event) => {
		const arrData = [];
		modalityList.forEach((data) => {
			if (event.target.value === data.value) {
				const body = {
					checked: event.target.checked,
					value: data.value,
					name: data.name,
				};
				arrData.push(body);
			} else {
				const body = {
					checked: false,
					value: data.value,
					name: data.name,
				};
				arrData.push(body);
			}
		});
		setModalityList(arrData);
		//get count
		let countFilterModality = arrData.filter((countFilter) => {
			return countFilter.checked === true;
		});

		countFilterModality.length <= 0 ? setCountModality(0) : setCountModality(1);
	};

	const getFilterSelect = (doNewOrderRequest) => {
		filterSelect(statusList, modalityList, doNewOrderRequest);
	};

	return (
		<div
			className="modal fade"
			id="filterOrders"
			tabIndex="-1"
			aria-labelledby="filterOrdersLabel"
			aria-hidden="true"
		>
			<div
				className="modal-dialog modal-dialog-centered"
				style={{ minWidth: "500px" }}
			>
				<div
					className="modal-content"
					style={{ padding: "32px", minWidth: "500px ", borderRadius: "8px" }}
				>
					<div className="modal-header d-flex flex-column">
						<h5 className="modal-title mb-2">Selecionar e aplicar filtros</h5>
						<p style={{ color: "#747E8B", fontSize: "14px" }}>
							Escolha os filtros nas opções abaixo para refinar seus resultados.
						</p>
					</div>
					<div className="modal-body">
						<div className="d-flex flex-row justify-content-start">
							<div className="">
								<label className="card-title" style={{ fontSize: "13px" }}>
									Status do Pedido
								</label>
								<ul className="m-0 p-0">
									{statusList.map((data, index) => (
										<li
											key={index}
											className="custom-control custom-checkbox my-1 mr-sm-2"
										>
											<input
												type="checkbox"
												className="custom-control-input"
												checked={data.checked}
												name={data.name}
												value={data.value}
												id={data.value}
												onChange={handleChooseStatus}
											/>
											<label
												style={{ cursor: "pointer" }}
												className="custom-control-label"
												for={data.value}
											>
												{data.name}
											</label>
										</li>
									))}
								</ul>
							</div>

							{localStorage.getItem("role") !== "8" && (
								<>
									<div className="mx-3">
										<label className="card-title" style={{ fontSize: "13px" }}>
											Modalidade
										</label>
										{modalityList.map((data, index) => (
											<div
												key={index}
												className="custom-control custom-checkbox my-1 mr-sm-2"
											>
												<input
													type="checkbox"
													className="custom-control-input"
													checked={data.checked}
													name={data.name}
													value={data.value}
													id={index}
													onChange={handleChooseModality}
												/>

												<label
													style={{ cursor: "pointer" }}
													className="custom-control-label"
													for={index}
												>
													{data.name}
												</label>
											</div>
										))}
									</div>
								</>
							)}
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-link"
							data-dismiss="modal"
							style={{
								width: "150px",
								color: "#5A5E61",
								border: "1px solid #8D8D8D",
								backgroundColor: "transparent",
							}}
						>
							Cancelar
						</button>

						<button
							className="btn btn-success btn-icon d-flex justify-content-center"
							data-dismiss="modal"
							onClick={() => getFilterSelect(true)}
							style={{ padding: "10px 20px", width: "200px" }}
						>
							Aplicar filtros ({countStatus + countModality})
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FilterOrdersModal;
