import React from "react";
import { useLogin } from "../providers/LoginContext";
import { i18n } from "../../../translate/i18n";
export default function RoleList() {
	const { roles, selectId } = useLogin();

	const handleSelectId = (id) => {
		selectId(id);
	};

	return (
		<div className="initial">
			<h2>{i18n.t("login.General.Login")}</h2>
			<p className="text-white">{i18n.t("login.General.ChooseClient")}</p>

			<div>
				<div className="roleContainer scroll-list">
					{roles.map((item, i) => {
						return (
							<li key={item.clientId} className="itemRole">
								<div className="d-flex">
									<div className="col-2 text-center d-flex align-items-center">
										{item.retailerIconUrl != "" ? (
											<img src={item.retailerIconUrl} className="avatar" />
										) : (
											<img src="/images/avatar.jpg" className="avatar" />
										)}
									</div>
									<div className="col text-left d-flex align-items-center py-3">
										<div>
											<span className="text-white name">
												{item.retailerName}
											</span>
											<small className="text-white role">{item.roleName}</small>
										</div>
									</div>
									<div className="col-3 go d-flex align-items-center">
										<a href="#" onClick={() => handleSelectId(item)}>
											{i18n.t("login.General.Into")}
										</a>
									</div>
								</div>
							</li>
						);
					})}
				</div>
				<a
					className="text-right _link"
					href="https://neomode.zendesk.com/"
					target="_blank"
				>
					{i18n.t("login.General.Help")}
				</a>
			</div>
		</div>
	);
}
