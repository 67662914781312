import React, { useState, useEffect } from "react";
import List from "../../../components/List/List";
import FinancialEmpty from "./FinancialEmpty";
import DateFilter from "./DateFilter/DateFilter";
import GenericTableLoader from "./GenericTableLoader";
import { useHistory } from "react-router-dom";
import { useFinancial } from "../providers/FinancialContext";
import { addMonetaryFormatting } from "../../../infrastructure/utils/FormatMask.jsx";
import { FinancialPaymentMethodTypes } from "./DataTypes/FinancialPaymentMethodTypes";
import { PayablesTransactionTypes } from "./DataTypes/PayablesTransactionTypes";

// TODO create hook function and API calls after TypeScript is deployed to PROD
export default function FinancialPayables() {
	const {
		loading,
		setLoading,
		listPayables,
		getListPayables,
		payableFilters,
		setPayableFilters,
		payablesExport,
		getTotalListPayables,
		totalPayablesAmount,
	} = useFinancial();
	const history = useHistory();
	const [page, setPage] = useState(1);

	const columsList = [
		{
			name: "Data",
			width: "110px",
			cell: (row) => (
				<div style={{ fontSize: "12px" }}>
					<p className="text-grey-70 mb-2">
						{new Date(row?.paymentDate).toLocaleDateString()}
					</p>
					<p className="text-grey-60 m-0">
						{new Date(row?.paymentDate).toLocaleTimeString()}
					</p>
				</div>
			),
		},
		{
			name: "Tipo",
			center: true,
			cell: (row) =>
				row.type in PayablesTransactionTypes && (
					<div
						className={`${
							PayablesTransactionTypes[row.type].className
						} text-center`}
					>
						{PayablesTransactionTypes[row.type].text}
					</div>
				),
		},
		{
			name: "Id da operação",
			center: true,
			cell: (row) => (
				<span
					className="text-grey-70"
					style={{ fontSize: "12px", fontWeight: "600" }}
				>
					{row?.id}
				</span>
			),
		},
		{
			name: "Id da transação",
			width: "142px",
			center: true,
			cell: (row) => (
				<span
					className="text-grey-70"
					style={{ fontSize: "12px", fontWeight: "600" }}
				>
					{row?.transactionId}
				</span>
			),
		},
		{
			name: "Descrição",
			width: "160px",
			center: true,
			cell: (row) =>
				row.paymentMethod && (
					<div
						className="text-center text-grey-80"
						style={{ fontSize: "12px" }}
					>
						{row.paymentMethod in FinancialPaymentMethodTypes ? (
							<>
								<img
									className="mb-1"
									src={FinancialPaymentMethodTypes[row.paymentMethod].image}
								/>
								<p className="mb-1" style={{ fontWeight: "600" }}>
									{FinancialPaymentMethodTypes[row.paymentMethod].text}
								</p>
								{row?.installment && (
									<p className="m-0">Parcela {row.installment}</p>
								)}
							</>
						) : (
							<>
								<img
									className="mb-1"
									src={FinancialPaymentMethodTypes.unmappedPaymentMethod.image}
								/>
								<p className="mb-1" style={{ fontWeight: "600" }}>
									{row.paymentMethod}
								</p>
							</>
						)}
					</div>
				),
		},
		{
			name: "Entradas",
			center: true,
			cell: (row) => revenueColumns(row, row?.amount > 0),
		},
		{
			name: "Saídas",
			center: true,
			cell: (row) => revenueColumns(row, row?.amount < 0),
		},
		{
			name: "Total",
			width: "115px",
			right: true,
			cell: (row) => (
				<div
					className={row.subTotal > 0 ? "text-success" : "text-danger"}
					style={{ fontWeight: "700" }}
				>
					R$ {addMonetaryFormatting(row?.subTotal)}
				</div>
			),
		},
	];

	const revenueColumns = (row, showAmount) => {
		return (
			<div className="text-center" style={{ fontWeight: "600" }}>
				{showAmount ? (
					<>
						<p className="text-grey-70 m-0">
							{row.feeAmount !== 0 && "Valor: "}R${" "}
							{addMonetaryFormatting(row.amount)}
						</p>
						{row.feeAmount !== 0 && (
							<div className="d-flex">
								<p className="text-grey-60 m-0">
									Taxas: R$ {addMonetaryFormatting(row.feeAmount)}
								</p>
								<div className="financial-help-tooltip">
									<div className="tooltipNeo">
										<button className="">
											<i
												className="material-icons helper text-grey-60"
												style={{ fontSize: "18px" }}
											>
												help_outline
											</i>
										</button>
										<span className="helper-tooltip">
											{row.fee > 0 && (
												<p className="m-0 p-0">
													Taxa: R$ {addMonetaryFormatting(row.fee)}
												</p>
											)}
											{row.anticipationFee > 0 && (
												<p className="m-0 p-0">
													Taxa de antecipação: R${" "}
													{addMonetaryFormatting(row.anticipationFee)}
												</p>
											)}
											{row.fraudCoverageFee > 0 && (
												<p className="m-0 p-0">
													Taxa de seguro: R${" "}
													{addMonetaryFormatting(row.fraudCoverageFee)}
												</p>
											)}
											<p className="m-0 p-0">
												<strong>
													Total de taxas: R${" "}
													{addMonetaryFormatting(row.feeAmount)}
												</strong>
											</p>
										</span>
									</div>
								</div>
							</div>
						)}
					</>
				) : (
					<p className="text-grey-70 m-0">R$ {addMonetaryFormatting(0)}</p>
				)}
			</div>
		);
	};

	const handleFilterChanges = async () => {
		await getTotalListPayables();
		history.push(
			`/financial/payables?rowsPerPage=${payableFilters?.rowsPerPage}`
		);
		getListPayables(page);
	};

	useEffect(() => {
		handleFilterChanges();
	}, [payableFilters, page]);

	const changeRowsPerPage = (e) => {
		setLoading(true);
		setPayableFilters({
			...payableFilters,
			rowsPerPage: e,
		});
	};

	const changePage = (e) => {
		setLoading(true);
		setPage(e);
	};

	return (
		<>
			<div className="d-flex justify-content-between px-4 pt-4">
				<h3 className="financial-text title">Recebíveis</h3>
			</div>
			<DateFilter
				screen={"Payables"}
				setPage={setPage}
				tabData={payableFilters}
				setTabData={setPayableFilters}
				exportData={payablesExport}
			/>
			<div
				className="d-flex align-items-center px-4 pt-1 pb-3"
				style={{ width: "100%" }}
			>
				<strong>
					MOSTRANDO {loading ? "0" : totalPayablesAmount} RESULTADOS
				</strong>
			</div>
			<div className="">
				<div className="card-list-pagination no-padding">
					{loading ? (
						<GenericTableLoader />
					) : listPayables.length > 0 ? (
						<List
							data={listPayables}
							page={page}
							colums={columsList}
							totalRows={totalPayablesAmount}
							handlePageChange={changePage}
							paginationServer={true}
							rowsPerPage={payableFilters?.rowsPerPage}
							handleRowsPerPageChange={changeRowsPerPage}
						/>
					) : (
						<FinancialEmpty
							img={"/images/empty_coupon.svg"}
							status={"recebíveis"}
						/>
					)}
				</div>
			</div>
		</>
	);
}
