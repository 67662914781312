import React from "react";
import CancelLink from "../../../../../components/CancelLink";
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	Stack,
	Tooltip,
	Zoom,
} from "@mui/material";
import CustomInput from "../../../../../MUICustomComponents/CustomInput";
import { i18n } from "../../../../../translate/i18n";
import ConditionFormService from "./ConditionFormService";
import { ConditionInput } from "../../enums/ConditionInput";
import CustomTypography from "../../../../../MUICustomComponents/CustomTypography";
import ReceivingMethods from "../ReceivingMethods/ReceivingMethods";
import LoadingButtonIcon from "../../../../../components/LoadingButtonIcon";
import { HelpOutlineOutlined } from "@mui/icons-material";
import CustomIcon from "../../../../../MUICustomComponents/CustomIcon";
import { FormValidator } from "../../../../../infrastructure/utils/FormUtils";
import CustomSkeleton from "../../../../../MUICustomComponents/CustomSkeleton";
import { insertHtml } from "../../../../../infrastructure/utils/InsertHtml";

export default function ConditionForm() {
	const {
		id,
		condition,
		formErrors,
		isLoadingScreen,
		isLoadingSubmit,
		isDefaultConditionCreation,
		// functions
		changeCondition,
		handleSubmit,
	} = ConditionFormService();

	const conditionForm = (): JSX.Element => {
		return (
			<div className="card">
				<Box p={4}>
					<CustomTypography variant="title">
						{i18n.t("paymentConfig.BasicInfo").toString()}
					</CustomTypography>
					{isLoadingScreen && <CustomSkeleton quantity={2} height={40} />}
					{!isLoadingScreen && (
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<CustomInput
									title={i18n.t("paymentConfig.ConditionTitle")}
									name={ConditionInput.Slug}
									value={condition.slug}
									onChange={changeCondition}
									error={FormValidator.hasInputError(
										formErrors,
										ConditionInput.Slug
									)}
									helperText={FormValidator.translateHelperTextList(
										formErrors,
										ConditionInput.Slug
									)}
								/>
							</Grid>
							<Grid item xs={4}>
								<Stack direction="row" spacing={1}>
									<CustomInput
										title={i18n.t("paymentConfig.ClampPercentage")}
										name={ConditionInput.ClampPercentage}
										value={condition.clampPercentage}
										onChange={changeCondition}
										type="number"
										disabled={condition.isDefault || isDefaultConditionCreation}
										endAdornment={"%"}
										error={FormValidator.hasInputError(
											formErrors,
											ConditionInput.ClampPercentage
										)}
										helperText={FormValidator.translateHelperTextList(
											formErrors,
											ConditionInput.ClampPercentage
										)}
									/>
									<Tooltip
										arrow
										placement="top"
										TransitionComponent={Zoom}
										title={insertHtml(
											i18n.t("paymentConfig.ClampPercentageTooltip"),
											i18n.t("paymentConfig.ClampPercentageLinkText"),
											<a
												href="https://neomode.atlassian.net/wiki/spaces/CDA/pages/2573402124/Condi+es+Comerciais#O-que-%C3%A9-o-arrasto%3F"
												target="_blank"
												style={{
													color: "#fff",
													textDecoration: "underline",
													fontWeight: "bold",
												}}
											>
												{i18n
													.t("paymentConfig.ClampPercentageLinkText")
													.toString()}
											</a>
										)}
									>
										<i style={{ marginTop: "6px" }}>
											<CustomIcon
												icon={<HelpOutlineOutlined />}
												variant="small"
											/>
										</i>
									</Tooltip>
								</Stack>
							</Grid>
							<Grid item xs={4}>
								<CustomInput
									title={i18n.t("paymentConfig.MinInstallmentValue")}
									name={ConditionInput.MinInstallmentValue}
									value={condition.minInstallmentValue}
									onChange={changeCondition}
									applyStartAdornment={"currency"}
									error={FormValidator.hasInputError(
										formErrors,
										ConditionInput.MinInstallmentValue
									)}
									helperText={FormValidator.translateHelperTextList(
										formErrors,
										ConditionInput.MinInstallmentValue
									)}
								/>
							</Grid>
							{condition.id && !condition.isDefault && (
								<Grid item xs={4}>
									<FormControlLabel
										control={
											<Checkbox
												name={ConditionInput.IsActive}
												checked={condition.isActive}
												onChange={changeCondition}
											/>
										}
										label={
											<CustomTypography variant="default">
												{i18n.t("paymentConfig.ConditionIsActive").toString()}
											</CustomTypography>
										}
									/>
								</Grid>
							)}
						</Grid>
					)}
					<Stack direction="row" justifyContent="end" spacing={2} mt={2}>
						<CancelLink to={"/configurations/payment/commercial-conditions"} />
						<Button
							type="submit"
							color="success"
							variant="contained"
							disabled={
								isLoadingSubmit ||
								isLoadingScreen ||
								FormValidator.hasError(formErrors)
							}
							onClick={() => handleSubmit(condition)}
						>
							{isLoadingSubmit ? (
								<LoadingButtonIcon />
							) : (
								i18n.t(`buttons.General.BtnSave`).toString()
							)}
						</Button>
					</Stack>
				</Box>
			</div>
		);
	};

	return (
		<Stack spacing={2}>
			{conditionForm()}
			{id && <ReceivingMethods conditionId={id} />}
		</Stack>
	);
}
