import React, { useState, useEffect } from "react";
import { i18n } from "../../translate/i18n";
import Select from "react-select";
import SearchInputClose from "../SearchInputClose";

import "./HeaderSearch.scss";

export default function HeaderSearches(props) {
	const [clearFilterCounter, setClearFilterCounter] = useState();

	const customSelectStyles = {
		indicatorSeparator: (provided) => ({
			...provided,
			display: "none",
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			color: "#747E8B",
			paddingLeft: 5,
			svg: {
				width: 24,
			},
		}),
		group: (provided) => ({
			...provided,
			padding: 0,
			marginTop: "8px",
			borderTop: "1px solid #E0E3E7",
		}),
		groupHeading: (provided) => ({
			...provided,
			margin: 0,
		}),
		singleValue: (provided) => ({
			...provided,
			"& .react-select-container-group": {
				paddingLeft: "0px !important",
			},
		}),
	};

	useEffect(() => {
		props.filters &&
			setClearFilterCounter(
				Object.values(props.filters).reduce(
					(counter, filterValue) =>
						filterValue !== "" ? counter + 1 : counter,
					0
				)
			);
	}, [props.filters]);

	const handleChange = (queryValue) => {
		props.handleQueryChange(queryValue);
	};

	const clearFilters = () => {
		props.handleClearFilters();
	};

	const exportFilters = () => {
		props.setLoadingExport(true);
		props.handleExportFilter();
	};

	const formatOptionLabel = (data) => (
		<div
			className={`react-select-container-group d-flex align-items-center justify-content-between${
				data?.isSubCategory ? " pl-2" : ""
			}`}
		>
			<span className="mr-2">{data.label}</span>
			{data?.subCategoryCount > 0 && (
				<>
					<span
						className="react-select-container-group-badge"
						style={{ fontSize: "10px" }}
					>
						{data.subCategoryCount}
					</span>
				</>
			)}
		</div>
	);

	const findValue = (label, options) => {
		if (label === "Category") {
			if (props.filters[label] === "") return options[0];
			if (options.length <= 1) return;
			let parentIndex = options?.findIndex((parent) =>
				parent?.options?.some(
					(children) => children?.value === props.filters[label]
				)
			);
			let childrenIndex;
			if (parentIndex) {
				childrenIndex = options[parentIndex]?.options?.findIndex(
					(children) => children?.value === props.filters[label]
				);
			}
			return options[parentIndex].options[childrenIndex];
		}

		return options[
			options.findIndex((option) => option.value === props.filters[label])
		];
	};

	const filterSelect = (label, options) => (
		<div className="filter-block ml-0 ml-lg-3">
			<label className="filter-block-label" htmlFor={`${label}Select`}>
				{i18n.t(`components.SelectName.${label}`)}
			</label>
			<Select
				styles={customSelectStyles}
				className="react-select-container"
				value={findValue(label, options)}
				options={options}
				formatOptionLabel={formatOptionLabel}
				theme={(theme) => ({
					...theme,
					borderRadius: 0,
					colors: {
						...theme.colors,
						primary25: "#BDCFF5",
						primary: "#9BB7F0",
					},
				})}
				onChange={(e) => {
					props.setFilters((prev) => ({ ...prev, [label]: e.value }));
				}}
				// menuIsOpen={true} // help debugging <Select/> elements
			/>
		</div>
	);

	return (
		<div className="card-header-search">
			<div className="header-search d-flex flex-column flex-lg-row">
				<div className="flex-fill">
					<SearchInputClose
						value={props.query}
						onChange={handleChange}
						placeholder={`${i18n.t(
							`components.Placeholder.${props.placeholder}`
						)}`}
					/>
				</div>
				<div className="d-flex flex-wrap flex-lg-row justify-content-between mt-5 mt-lg-0">
					{props.selectList &&
						props.selectList.map((select) =>
							filterSelect(select.title, select.options)
						)}
				</div>
			</div>
			{(clearFilterCounter || props.hasExport) && (
				<div className="d-flex mt-3 pt-3">
					{clearFilterCounter > 0 && (
						<button
							className="filter-btn filter-btn-clear d-flex align-items-center justify-content-center p-2"
							onClick={clearFilters}
						>
							<i className="material-icons-outlined">delete</i>{" "}
							{i18n
								.t("buttons.General.BtnClearFilter")
								.replace("%X", clearFilterCounter)}
						</button>
					)}

					{props.hasExport && (
						<button
							className="filter-btn filter-btn-export d-flex align-items-center justify-content-center p-2"
							onClick={exportFilters}
							disabled={props.loadingExport}
						>
							{props.loadingExport ? (
								<>
									<i className="fas fa-spinner fa-spin"></i>{" "}
									<span className="p-0 m-0">
										{i18n.t("buttons.General.BtnProcessFilter")}
									</span>
								</>
							) : (
								<>
									<i className="material-icons-outlined">download</i>{" "}
									{i18n.t("buttons.General.BtnExportFilter")}
								</>
							)}
						</button>
					)}
				</div>
			)}
		</div>
	);
}
