import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { retailerManagementApi } from "../../../../infrastructure/api/RetailerManagementApi";
import { DayOfWeekType } from "../../../../domain/enums/DayOfWeekType";
import NewToastComponent from "../../../../components/NewToastComponent";
import { SellerWorkingHoursView } from "../../../../domain/views/stores/SellerWorkingHoursView";

export default function useWorkingHoursService() {
	const localeOptions: Intl.DateTimeFormatOptions = {
		// en-US just for convention, doesnt have to do with internationalization
		hour: "2-digit",
		minute: "2-digit",
		hour12: false,
	};

	const defaultWorkingHourState: Dayjs = dayjs("2022-04-17T00:00");

	const [isLoading, setIsLoading] = useState(true);
	const [workingHoursList, setWorkingHoursList] = useState<
		SellerWorkingHoursView[]
	>([]);

	const onChangeOpeningHours = (
		time: Dayjs | null,
		day: DayOfWeekType
	): void => {
		if (time === null) return;

		let date = time.toDate();
		let hoursMin = date.toLocaleTimeString("en-US", localeOptions);

		setWorkingHoursList((prevState) => {
			return prevState.map((obj) => {
				if (obj.dayWeek === day) {
					return { ...obj, openHour: hoursMin };
				}

				return obj;
			});
		});
	};

	const onChangeClosingHours = (
		time: Dayjs | null,
		day: DayOfWeekType
	): void => {
		if (time === null) return;

		let date = time.toDate();
		let hoursMin = date.toLocaleTimeString("en-US", localeOptions);

		setWorkingHoursList((prevState) => {
			return prevState.map((obj) => {
				if (obj.dayWeek === day) {
					return { ...obj, closeHour: hoursMin };
				}

				return obj;
			});
		});
	};

	const getWorkingHours = async (sellerId: string): Promise<void> => {
		const arrDefault = [...Array(7)].map((_, index) => ({
			dayWeek: index,
			openHour: "00:00",
			closeHour: "00:00",
			opensAtAsDayjs: dayjs(`2022-04-17T00:00`),
			closesAtAsDayjs: dayjs(`2022-04-17T00:00`),
		}));

		try {
			const data = await retailerManagementApi.getWorkingHours(sellerId);

			const arr =
				data.length === 0
					? arrDefault
					: data
							.map(({ dayWeek, openHour, closeHour }) => ({
								dayWeek,
								openHour,
								closeHour,
								opensAtAsDayjs: dayjs(`2022-04-17T${openHour}`),
								closesAtAsDayjs: dayjs(`2022-04-17T${closeHour}`),
							}))
							.sort((a, b) => a.dayWeek - b.dayWeek);

			setWorkingHoursList(arr);

			setIsLoading(false);
		} catch {
			NewToastComponent({
				status: "error",
				title: "Parece que tivemos um erro...",
				message: "Tente novamente mais tarde.",
			});
			setWorkingHoursList(arrDefault);
			setIsLoading(false);
		}
	};

	const upsertWorkingHours = async (
		sellerId: string,
		data: SellerWorkingHoursView[]
	): Promise<void> => {
		setIsLoading(true);

		let success = await retailerManagementApi.upsertWorkingHours(
			sellerId,
			data
		);

		if (!success) {
			NewToastComponent({
				status: "error",
				title: "Parece que tivemos um erro...",
				message: "Tente novamente mais tarde.",
			});
			setIsLoading(false);
			return;
		}

		NewToastComponent({
			status: "success",
			title: "Horários editados com sucesso!",
		});

		getWorkingHours(sellerId);
		setIsLoading(false);
	};

	return {
		// Props
		isLoading,
		workingHoursList,
		defaultWorkingHourState,
		// Functions
		onChangeOpeningHours,
		onChangeClosingHours,
		getWorkingHours,
		upsertWorkingHours,
	};
}
