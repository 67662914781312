import React, { ChangeEvent } from "react";
import { Grid, SelectChangeEvent } from "@mui/material";
import { i18n } from "../../../../translate/i18n";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import CustomInput from "../../../../MUICustomComponents/CustomInput";
import RetailerCheckbox from "../components/RetailerCheckbox";
import RetailerCard from "../components/RetailerCard";
import { GeneralConfig } from "../types/GeneralConfig";

type Props = {
	generalConfig: GeneralConfig;
	onChange: (
		event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>,
		checked?: boolean
	) => void;
};

export default function GeneralCatalog({ generalConfig, onChange }: Props) {
	return (
		<RetailerCard>
			<CustomTypography variant="secondaryTitle" marginBottom={2}>
				{i18n.t("retailerConfig.GeneralCatalog").toString()}
			</CustomTypography>
			<Grid container columnSpacing={2} mt={1}>
				<Grid item xs={12}>
					<CustomInput
						title={i18n.t(`retailerConfig.${"minStock"}`)}
						name="minStock"
						value={generalConfig.minStock}
						onChange={onChange}
						type="number"
					/>
				</Grid>
				<Grid item xs={6}>
					<RetailerCheckbox
						id="isSinglePrice"
						isChecked={generalConfig.isSinglePrice}
						onChange={onChange}
					/>
				</Grid>
				<Grid item xs={6}>
					<RetailerCheckbox
						id="enableWholesalePrice"
						isChecked={generalConfig.enableWholesalePrice}
						onChange={onChange}
					/>
				</Grid>
			</Grid>
		</RetailerCard>
	);
}
