import React from "react";
import { Link } from "react-router-dom";
import CustomIcon from "../../../../MUICustomComponents/CustomIcon";
import { OpenInNew } from "@mui/icons-material";
import { formatDateStringOnly } from "../../../../infrastructure/utils/FormatMask";
import { showOrderCode } from "../../../../infrastructure/utils/OrdersUtils";
import { OrderStatus } from "../../../../domain/enums/OrderStatus";
import { CpOrderStatusDto } from "../../../../domain/dtos/cpCommerceManagement/CpOrderStatusDto";
import { i18n } from "../../../../translate/i18n";
import styled from "styled-components";
import { paletteObject } from "../../../../theme/foundations/palette";
import CustomSkeleton from "../../../../MUICustomComponents/CustomSkeleton";

type Props = {
	lastOrders: OrderProp[];
	isLoading: boolean;
};

type OrderProp = {
	id: string;
	createDate: string;
	fullName: string;
	totalAmount: number;
	externalId?: string | null;
	friendlyCode: string;
	sellerOrderId?: string | null;
	status: OrderStatus | CpOrderStatusDto;
	isDelivery?: boolean;
};

enum SmOrderStatusColor {
	Created = "#747E8B",
	WaitingApproval = "#747E8B",
	WaitingSeparation = "#FDAC41",
	WaitingInvoice = "#FDAC41",
	WaitingDispatch = "#7A9FEB",
	WaitingDelivery = "#7A9FEB",
	WaitingReturnGoods = "#7A9FEB",
	ReturningGoods = "#7A9FEB",
	Finalized = "#6CD691",
	WaitingCancel = "#FF5B5C",
	Canceled = "#FF5B5C",
}

enum CpOrderStatusColor {
	None = "#747E8B",
	AwaitingCreation = "#747E8B",
	CreatedInternal = "#747E8B",
	CreatedExternal = "#747E8B",
	InProgress = "#747E8B",
	AwaitingInvoiceCreation = "#FDAC41",
	InvoiceCreated = "#FDAC41",
	Separated = "#FDAC41",
	ReadyForPickup = "#7A9FEB",
	Shipping = "#7A9FEB",
	Finalized = "#6CD691",
	Canceled = "#FF5B5C",
	Error = "#FF5B5C",
}

const StyledTable = styled.table.attrs(() => ({ className: "table" }))`
	font-size: 12px;
	white-space: nowrap;
	overflow: hidden;
	th,
	td {
		vertical-align: middle;
	}
	#orderCode {
		a {
			color: ${paletteObject.grey90.main};
			font-weight: bold;
		}
		span {
			color: ${paletteObject.blueish20.main};
		}
	}
	#orderStatus {
		white-space: normal;
	}
	#clientName {
		max-width: 120px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	#actions {
		a {
			color: ${paletteObject.grey50.main};
		}
	}
`;

export default function LastOrdersTable({ lastOrders, isLoading }: Props) {
	const isStateMachine = localStorage.getItem("useStateMachine") === "true";

	const getStatusColor = (status: OrderStatus | CpOrderStatusDto): string => {
		if (isStateMachine)
			return SmOrderStatusColor[status as OrderStatus] ?? "#747E8B";
		return CpOrderStatusColor[status as CpOrderStatusDto] ?? "#747E8B";
	};

	const getStatusDescription = (
		status: OrderStatus | CpOrderStatusDto,
		isDelivery?: boolean
	): string => {
		if (isStateMachine) {
			if (
				(status === OrderStatus.WaitingDispatch ||
					status === OrderStatus.WaitingDelivery) &&
				!isDelivery
			)
				return i18n.t(`smOrderStatus.${status}Withdrawal`).toString();
			return i18n.t(`smOrderStatus.${status}`).toString();
		}
		return i18n.t(`cpOrderStatus.${status}`).toString();
	};

	const renderEmptyComponent = () => {
		return (
			<div className="align-items-center py-5 empty">
				<div className="text-center">
					<p style={{ color: "#C4C4C4", fontSize: "14px" }}>
						Não há pedidos no momento
					</p>
				</div>
			</div>
		);
	};

	const renderTable = () => {
		return (
			<StyledTable>
				<thead>
					<tr>
						<th scope="col">N. PEDIDO</th>
						<th scope="col">CLIENTE</th>
						<th scope="col" className="text-center">
							VALOR
						</th>
						<th scope="col" className="text-center">
							STATUS
						</th>
						<th scope="col" />
					</tr>
				</thead>
				<tbody>
					{lastOrders.map((order) => (
						<tr key={order.id}>
							<td id="orderCode">
								<Link to={`/orders/1/detail/${order.id}`}>
									{showOrderCode(
										order.friendlyCode,
										order.externalId,
										order.sellerOrderId
									)}
								</Link>
								<br />
								<span>{formatDateStringOnly(order.createDate)}</span>
							</td>
							<td id="clientName">{order.fullName}</td>
							<td className="text-center">
								{order.totalAmount.toLocaleString("pt-br", {
									style: "currency",
									currency: "BRL",
								})}
							</td>
							<td
								id="orderStatus"
								className="text-center"
								color={getStatusColor(order.status)}
							>
								{getStatusDescription(order.status, order?.isDelivery)}
							</td>
							<td id="actions">
								<Link to={`/orders/1/detail/${order.id}`}>
									<CustomIcon icon={<OpenInNew />} variant="small" />
								</Link>
							</td>
						</tr>
					))}
				</tbody>
			</StyledTable>
		);
	};

	const contentToBeRendered = () => {
		if (isLoading)
			return (
				<div className="px-3">
					<CustomSkeleton quantity={10} height={40} />;
				</div>
			);
		if (lastOrders.length === 0) return renderEmptyComponent();
		return renderTable();
	};

	return <div className="card-body p-0">{contentToBeRendered()}</div>;
}
