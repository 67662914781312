import React, { useEffect } from "react";
import { useMatcher } from "../providers/MatcherContext";

// import { Container } from './styles';

function IntegrationTabs() {
	const { getIntegrations, integrationCode, setIntegrationCode, integrations } =
		useMatcher();

	useEffect(() => {
		getIntegrations();
	}, []);
	return (
		<div className="detail">
			<div className="detail-tabs">
				{integrations?.map((integration) => (
					<button
						onClick={() => setIntegrationCode(integration.Code)}
						className={integrationCode === integration.Code ? "actived" : null}
					>
						{integration.Name}
					</button>
				))}
			</div>
		</div>
	);
}

export default IntegrationTabs;
