import React from "react";
import { i18n } from "../../../translate/i18n";

export default function LoadDashboard() {
	return (
		<div className="load-modal">
			<div className="load-modal-card">
				<div>
					<img src="/images/n-neomode.svg" alt="neomode" height="80" />
					<img
						className="rotate"
						src="/images/quadrado.svg"
						alt="neomode"
						height="20"
					/>
				</div>
				<h1 className="load-modal-text">
					{i18n.t(`dashboard.Dashboard.TextLoad`)}
				</h1>
				<div className="pt-4">
					<div className="snippet" data-title="dot-pulse">
						<div className="stage">
							<div className="dot-pulse"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
