import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment, { utc } from 'moment'
import 'moment/min/moment-with-locales'
import ContentLoader from 'react-content-loader';

// import { Container } from './styles';

function CatalogCompilation() {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [integrations, setIntegrationList] = useState([])

  useEffect(() => {
    moment().locale('pt-br')
    moment().format('LLLL')
    getIntegration();
    setInterval(async () => {
      getIntegration();
    }, 60000);
  }, [])

  const getIntegration = async () => {
    try {
      let response = await axios.get(`${process.env.REACT_APP_API_MICROSOFT_HEALTH}api/catalogcompilation`)
      setIntegrationList(response.data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setError(true)
      console.log(e)
      toast.error('Parece que tivemos um erro... Tente novamente mais tarde.', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        preventDuplicate: true,
      });
    }

  }

  const updateList = () => {
    setLoading(true)
    getIntegration()

  }

  const skeleton = () => {
    const skele = [0, 1, 2, 3, 4]
    return (

      skele.map(() =>
        <ContentLoader
          speed={2}
          width={1100}
          height={60}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          viewBox="0 0 1100 60"
          style={{ width: '100%' }}

        >
          <rect x="40" y="26" rx="3" ry="3" width="400" height="10" />
          <rect x="770" y="26" rx="3" ry="3" width="300" height="10" />
        </ContentLoader>
      )

    )
  }


  const formatHour = (lastExecution) => {
    //current time 
    const formated = moment().utc().local().tz('America/Sao_Paulo').format()
    const UTC = moment(formated).utc().local().format()
    //vem do back
    const executed = moment(lastExecution).utc().local().tz('Pacific/Galapagos').format('YYYY-MM-DD HH:mm:ss')
    return (moment(UTC).from(executed))
  }

  const renderList = () => (
    <>
      {integrations.map((integration, index) =>
        <div key={index} className="col-12 mb-3 pl-4 pr-4 d-flex justify-content-between">
          <span>{integration.name}</span>
          <span title={moment(integration.lastExecution).utc().local().tz('Pacific/Galapagos').format('DD/MM/YY HH:mm')}>
            {formatHour(integration.lastExecution)}
          </span>
        </div>
      )
      }
    </>
  )


  return (
    <div className="card"  >
      <div class="card-header d-flex justify-content-between align-items-center">
        <h4 class="card-title">Status geral do catálogo</h4>
        <div class="card-actions">
          <button className="btn-sm btn-info light d-flex align-items-center" onClick={() => updateList()}>
            <i className="material-icons">
              autorenew
              </i>
          </button>
        </div>
      </div>
      <div className="card-body p-2">
        <div className="row">
          {loading ? skeleton() : renderList()}
        </div>
      </div>
    </div>
  );
}

export default CatalogCompilation;