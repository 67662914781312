export enum SummaryStatusEnum {
	WaitingPayment = "WaitingPayment",
	WaitingSellerApproval = "WaitingSellerApproval",
	WaitingSeparation = "WaitingSeparation",
	WaitingInvoicing = "WaitingInvoicing",
	WaitingDispatch = "WaitingDispatch",
	WaitingDelivery = "WaitingDelivery",
	WaitingWithdrawal = "WaitingWithdrawal",
	Finalized = "Finalized",
	Canceled = "Canceled",
	WithError = "WithError",
}
