import React, { useEffect, useState } from "react";
import { i18n } from "../../../../translate/i18n";
import { useOrders } from "../../providers/OrdersContext";

function OrderFlowModal({ position }) {
	const { handleChangeStatus } = useOrders();
	const [message, setMessage] = useState({});
	const [confirmStatus, setConfirmStatus] = useState(false);
	useEffect(() => {
		if (position === 112) {
			return setMessage({
				title: i18n.t("orderDetails.MessageCancelStatusModal.Title"),
				text: i18n.t("orderDetails.MessageCancelStatusModal.Text"),
				checkbox: i18n.t("orderDetails.MessageCancelStatusModal.Checkbox"),
				buttom: i18n.t("orderDetails.MessageCancelStatusModal.Buttom"),
				color: "btn-danger",
			});
		} else if (position >= 6 && position < 100) {
			return setMessage({
				title: i18n.t("orderDetails.MessageFinishStatusModal.Title"),
				text: i18n.t("orderDetails.MessageFinishStatusModal.Text"),
				checkbox: i18n.t("orderDetails.MessageFinishStatusModal.Checkbox"),
				buttom: i18n.t("orderDetails.MessageFinishStatusModal.Buttom"),
				color: "btn-success",
			});
		} else {
			return setMessage({
				title: i18n.t("orderDetails.MessageChangeStatusModal.Title"),
				text: i18n.t("orderDetails.MessageChangeStatusModal.Text"),
				checkbox: i18n.t("orderDetails.MessageChangeStatusModal.Checkbox"),
				buttom: i18n.t("orderDetails.MessageChangeStatusModal.Buttom"),
				color: "btn-success",
			});
		}
	}, [position]);

	const confirmChangeStatus = (event) => {
		setConfirmStatus(event.target.checked);
	};

	const confirmAction = () => {
		setConfirmStatus(false);
		handleChangeStatus(position);
	};

	return (
		<div
			className="modal fade"
			id="orderFlow"
			tabIndex="-1"
			aria-labelledby="orderFlowLabel"
			aria-hidden="true"
		>
			<div
				className="modal-dialog modal-dialog-centered"
				style={{ minWidth: "567px" }}
			>
				<div
					className="modal-content"
					style={{ padding: "32px", minWidth: "567px ", borderRadius: "8px" }}
				>
					<div className="modal-header d-flex flex-column">
						<h5 className="modal-title mb-2">{message.title}</h5>
						<p style={{ color: "#747E8B", fontSize: "14px" }}>{message.text}</p>
					</div>
					<div className="modal-body ml-0">
						<div className="custom-control custom-checkbox my-1 mr-sm-2">
							<input
								type="checkbox"
								checked={confirmStatus}
								className="custom-control-input"
								id="customControlInline10"
								onChange={confirmChangeStatus}
							/>
							<label
								className="custom-control-label pl-2"
								htmlFor="customControlInline10"
							>
								{message.checkbox}
							</label>
						</div>
					</div>
					<div className="modal-footer d-flex justify-content-end">
						<button
							className="btn btn-icon d-flex justify-content-center"
							data-dismiss="modal"
							style={{
								padding: "10px 20px",
								width: "190px",
								height: "40px",
								color: "#5A5E61",
								border: "1px solid #8D8D8D",
								backgroundColor: "transparent",
							}}
						>
							{i18n.t("orderDetails.TypeButtom.BtnBack")}
						</button>
						<button
							className={`btn ${
								confirmStatus ? message.color : "btn-light"
							} btn-icon d-flex justify-content-center`}
							data-dismiss="modal"
							disabled={confirmStatus ? false : true}
							onClick={confirmAction}
							style={{
								padding: "10px 20px",
								width: "190px",
								height: "40px",
							}}
						>
							{message.buttom}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OrderFlowModal;
