type ErrorParams = {
	message?: string;
	errorMessages?: string[];
};

class BaseCustomError extends Error {
	constructor(errorType: string, message: string, errorMessages: string[]) {
		super(message);
		this.name = errorType;
		this.errorMessages = errorMessages;
	}

	errorMessages: string[];
}

class ValidationError extends BaseCustomError {
	constructor({
		message = "Exception thrown due to validation errors.",
		errorMessages = [],
	}: ErrorParams) {
		super("ValidationError", message, errorMessages);
	}
}

export { ValidationError };
