import React, { useEffect, useState } from 'react';
import BodyCellphone from '../../../../../../components/body-celphone/BodyCellphone';
import BodyBannerCell from '../celComponents/BodyBannerCel';
import CarouselBannerCel from '../celComponents/CarouselBannerCel';
import ContactCel from '../celComponents/ContactCel';
import DepartmentCel from '../celComponents/DepartmentCel';
import LastProduct from '../celComponents/LastProduct';
import OfferCel from '../celComponents/OfferCel';
import RecommendationCel from '../celComponents/RecommendationCel';
import ShowcaseCel from '../celComponents/ShowcaseCel';
import DragDropHome from './DragDropHome';

// import { Container } from './styles';

function Content(props) {
  const [components, setComponents] = useState([])

  useEffect(() => {
    console.log(props.plugins)
    mountList(props.plugins)
  }, [props.plugins])


  const mountList = (plugins) => {
    console.log(plugins)
    setComponents([])
    let pluginList = []
    plugins.forEach(pl => {
      switch (pl.type) {
        case 'carousel':
          pluginList.push(<BodyBannerCell />)
          break;
        case 'body':
          pluginList.push(<CarouselBannerCel />)
          break;
        case 'contact':
          pluginList.push(<ContactCel />)
          break;
        case 'showcase':
          pluginList.push(<ShowcaseCel />)
          break;
        case 'lastProducts':
          pluginList.push(<LastProduct />)
          break;
        case "recommendation":
          pluginList.push(<RecommendationCel />)
          break;
          case "department":
          pluginList.push(<DepartmentCel />)
          break;
          case "offer":
          pluginList.push(<OfferCel />)
          break;
        default:
          break;
      }
    })
    setComponents(pluginList)
    props.setNewList(plugins)
  }

  const selectToEdit = (plugin) => {
    console.log(plugin)
    props.selectedPlugin(plugin)
  }


  return (
    <div className="card" style={{ height: '780px', overflow: 'hidden' }}>
      <div className="card-body">
        <h4 className="mb-6 pb-6"> <strong>Ordene e edite seus componentes</strong>  </h4>
        <div className="row">
          <div className="col-6">
            <BodyCellphone>
              <div className="row">
                {components}
              </div>
            </BodyCellphone>
          </div>
          <div className="col-6">
            <h5 className="mb-6 pb-6"> <strong>Componentes Adicionados</strong>  </h5>
            <h6 class="card-subtitle mb-2 text-muted mb-6">Clique e arraste para mudar a ordem dos componentes na home</h6>
            <DragDropHome plugins={props.plugins} pluginSelect={selectToEdit} newOrder={e => mountList(e)} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content;