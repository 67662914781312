import React from "react";
import {
	ArrowCircleDown,
	CancelOutlined,
	CheckCircleOutline,
	DirectionsCar,
	DoneAll,
	ErrorOutline,
	HelpOutline,
	ReceiptLongOutlined,
	RequestPageOutlined,
	StoreOutlined,
} from "@mui/icons-material";
import { GenericStatusCardProps } from "../../../../../components/GenericStatusCard";
import { SummaryStatusEnum } from "../SummaryStatusEnum";
import { i18n } from "../../../../../translate/i18n";
import { OrderStatus } from "../../../../../domain/enums/OrderStatus";
import { CpOrderStatusDto } from "../../../../../domain/dtos/cpCommerceManagement/CpOrderStatusDto";

const baseOrderPath = "orders/1?";
const statusParam = "status=";

export const getStatusListDict = (
	isStateMachine: boolean
): GenericStatusCardProps<SummaryStatusEnum>[] => [
	{
		icon: <ArrowCircleDown />,
		bgColor: "blue30",
		color: "blue130",
		statusId: SummaryStatusEnum.WaitingPayment,
		label: i18n
			.t(`orderSummary.${SummaryStatusEnum.WaitingPayment}`)
			.toString(),
		link: `${baseOrderPath}${statusParam}${
			isStateMachine ? OrderStatus.Created : CpOrderStatusDto.CreatedInternal
		}`,
	},
	{
		icon: <HelpOutline />,
		bgColor: "blue30",
		color: "blue130",
		statusId: SummaryStatusEnum.WaitingSellerApproval,
		label: i18n
			.t(`orderSummary.${SummaryStatusEnum.WaitingSellerApproval}`)
			.toString(),
		link: `${baseOrderPath}${statusParam}${
			isStateMachine
				? OrderStatus.WaitingApproval
				: CpOrderStatusDto.AwaitingCreation
		}`,
	},
	{
		icon: <CheckCircleOutline />,
		bgColor: "blue30",
		color: "blue130",
		statusId: SummaryStatusEnum.WaitingSeparation,
		label: i18n
			.t(`orderSummary.${SummaryStatusEnum.WaitingSeparation}`)
			.toString(),
		link: `${baseOrderPath}${statusParam}${
			isStateMachine
				? OrderStatus.WaitingSeparation
				: CpOrderStatusDto.CreatedExternal
		}`,
	},
	{
		icon: <RequestPageOutlined />,
		bgColor: "blue30",
		color: "blue130",
		statusId: SummaryStatusEnum.WaitingInvoicing,
		label: i18n
			.t(`orderSummary.${SummaryStatusEnum.WaitingInvoicing}`)
			.toString(),
		link: `${baseOrderPath}${statusParam}${
			isStateMachine
				? OrderStatus.WaitingInvoice
				: CpOrderStatusDto.AwaitingInvoiceCreation
		}`,
	},
	{
		icon: <ReceiptLongOutlined />,
		bgColor: "blue30",
		color: "blue130",
		statusId: SummaryStatusEnum.WaitingDispatch,
		label: i18n
			.t(`orderSummary.${SummaryStatusEnum.WaitingDispatch}`)
			.toString(),
		link: `${baseOrderPath}${statusParam}${
			isStateMachine
				? OrderStatus.WaitingDispatch
				: `${CpOrderStatusDto.Separated},${CpOrderStatusDto.InvoiceCreated}`
		}`,
	},
	{
		icon: <DirectionsCar />,
		bgColor: "blue30",
		color: "blue130",
		statusId: SummaryStatusEnum.WaitingDelivery,
		label: i18n
			.t(`orderSummary.${SummaryStatusEnum.WaitingDelivery}`)
			.toString(),
		link: `${baseOrderPath}${statusParam}${
			isStateMachine
				? `${OrderStatus.WaitingDelivery}&isDelivery=true`
				: CpOrderStatusDto.Shipping
		}`,
	},
	{
		icon: <StoreOutlined />,
		bgColor: "blue30",
		color: "blue130",
		statusId: SummaryStatusEnum.WaitingWithdrawal,
		label: i18n
			.t(`orderSummary.${SummaryStatusEnum.WaitingWithdrawal}`)
			.toString(),
		link: `${baseOrderPath}${statusParam}${
			isStateMachine
				? `${OrderStatus.WaitingDelivery}&isDelivery=false`
				: CpOrderStatusDto.ReadyForPickup
		}`,
	},
	{
		icon: <DoneAll />,
		bgColor: "green30",
		color: "green130",
		statusId: SummaryStatusEnum.Finalized,
		label: i18n.t(`orderSummary.${SummaryStatusEnum.Finalized}`).toString(),
		link: `${baseOrderPath}${statusParam}${
			isStateMachine ? OrderStatus.Finalized : CpOrderStatusDto.Finalized
		}`,
	},
	{
		icon: <CancelOutlined />,
		bgColor: "red30",
		color: "red130",
		statusId: SummaryStatusEnum.Canceled,
		label: i18n.t(`orderSummary.${SummaryStatusEnum.Canceled}`).toString(),
		link: `${baseOrderPath}${statusParam}${
			isStateMachine ? OrderStatus.Canceled : CpOrderStatusDto.Canceled
		}`,
	},
	{
		icon: <ErrorOutline />,
		bgColor: "red30",
		color: "red130",
		statusId: SummaryStatusEnum.WithError,
		label: i18n.t(`orderSummary.${SummaryStatusEnum.WithError}`).toString(),
		link: `${baseOrderPath}${
			isStateMachine
				? "withError=true"
				: `${statusParam}${CpOrderStatusDto.Error}`
		}`,
	},
];
