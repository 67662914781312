import React, { Component } from 'react';

class PreviewAppTutorialContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundColor: localStorage.getItem('primaryColor'),
        }
    }


    render() {
        return (
            <div className="mt-4" data-ride="">
                <div className="tutorial-content text-center">
                    {this.props.content}
                </div>
            </div>
        )
    }
};

export default PreviewAppTutorialContent;
