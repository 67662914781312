import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import List from "../../../components/List/List";
import FinancialEmpty from "./FinancialEmpty";
import moment from "moment";
import { useFinancial } from "../providers/FinancialContext";
import FinancialAnticipationsModal from "./FinancialAnticipationsModal";
import DateFilter from "./DateFilter/DateFilter";
import { addMonetaryFormatting } from "../../../infrastructure/utils/FormatMask.jsx";
import GenericTableLoader from "./GenericTableLoader";

export default function FinancialAnticipations(props) {
	const {
		loading,
		setLoading,
		listAnticipations,
		getListAnticipations,
		cancelAnticipation,
		anticipationFilters,
		setAnticipationFilters,
		getTotalListAnticipation,
		anticipationExport,
	} = useFinancial();
	const history = useHistory();
	const [deleteAnticipation, setDeleteAnticipation] = useState([]);
	const [page, setPage] = useState(1);
	const [totalAnticipations, setTotalAnticipations] = useState(0);

	const columsList = [
		{
			name: "DATA DE SOLICITAÇÃO",
			selector: "date",
			sortable: false,
			width: "200px",
			cell: (row) => (
				<div className="">
					<p className="financial-text text m-0 p-0">
						<strong>{moment(row?.dateCreated).format("DD/MM/YYYY")}</strong>
					</p>
					<p className="financial-text text m-0 p-0">
						{moment(row?.dateCreated).format("HH:mm")}
					</p>
				</div>
			),
		},
		{
			name: "VALOR",
			selector: "valor",
			sortable: false,
			center: true,
			width: "300px",
			cell: (row) => (
				<div className="">
					<p className="financial-text text text-center m-0 p-0">
						Valor solicitado: R$ {addMonetaryFormatting(row?.amount)}
					</p>

					<div className="d-flex flex-row justify-content-center">
						<p className="financial-text text d-flex flex-row justify-content-center align-item-center m-0 p-0">
							<strong>
								Você recebe: R$ {addMonetaryFormatting(row?.receive)}
							</strong>
						</p>
						<div className="financial-help-tooltip d-flex justify-content-center">
							<div className="tooltipNeo">
								<button className="">
									<i
										className="material-icons helper"
										style={{ fontSize: "18px" }}
									>
										help_outline
									</i>
								</button>
								<span className="helper-tooltip">
									<p className="m-0 p-0">
										Valor solicitado: R$ {addMonetaryFormatting(row?.amount)}
									</p>
									<p className="m-0 p-0">
										Taxas: R$ {addMonetaryFormatting(row?.fee)}
									</p>
									<p className="m-0 p-0">
										Taxas de antecipação: R${" "}
										{addMonetaryFormatting(row?.anticipation_fee)}
									</p>
									<p className="m-0 p-0">
										Você recebe: R$ {addMonetaryFormatting(row?.receive)}
									</p>
								</span>
							</div>
						</div>
					</div>
				</div>
			),
		},
		{
			name: "STATUS",
			selector: "status",
			sortable: false,
			width: "200px",
			center: true,
			cell: (row) => (
				<div
					className={`financial-label
				${
					row.status === "pending"
						? "warning"
						: row.status === "approved"
						? "success"
						: row.status === "building"
						? "info"
						: row.status === "refused"
						? "danger"
						: row.status === "canceled"
						? "dark"
						: ""
				}
				`}
				>
					{row.status === "pending"
						? "Antecipação Pendente"
						: row.status === "approved"
						? "Antecipação Concluída"
						: row.status === "building"
						? "Antecipação Solicitada"
						: row.status === "refused"
						? "Erro na Antecipação"
						: row.status === "canceled"
						? "Antecipação Cancelada"
						: ""}
				</div>
			),
		},
		{
			name: "DATA DE TRANSFERÊNCIA",
			selector: "transfer",
			sortable: false,
			center: true,
			width: "280px",
			cell: (row) => (
				<div className="">
					<p className="financial-text text text-center m-0 p-0">
						<strong>
							{row?.status === "approved"
								? "Concluída em: "
								: row?.status === "canceled"
								? "Cancelada em: "
								: "Disponível a partir de: "}
						</strong>
					</p>
					<p className="financial-text text text-center m-0 p-0">
						{row?.status === "approved"
							? moment(row?.dateUpdated).format("DD/MM/YYYY - HH:mm")
							: row?.status === "canceled"
							? moment(row?.dateUpdated).format("DD/MM/YYYY - HH:mm")
							: moment(row?.paymentDate).format("DD/MM/YYYY")}
					</p>
				</div>
			),
		},
		{
			name: "CANCELAR",
			selector: "cancel",
			sortable: false,
			//center: true,
			cell: (row) => (
				<div className="financial-buttons" style={{ marginLeft: "18px" }}>
					{!row.isFinalized && (
						<div className="tooltipNeo">
							{/* A antecipação só pode ser cancelada enquando o status for "pending" ou "building" */}
							<button
								data-toggle={`${
									row.status == "pending" || row.status == "building"
										? "modal"
										: ""
								}`}
								data-target="#deleteAnticipation"
								onClick={(e) => setDeleteAnticipation(row)}
							>
								<i
									className={`material-icons ${
										row.status === "canceled" ? "text-danger" : "delete"
									}`}
								>
									close
								</i>
							</button>
							<span>{`${
								row.status == "pending" || row.status == "building"
									? "Cancelar solicitação"
									: "Solicitação concluída"
							}`}</span>
						</div>
					)}
				</div>
			),
		},
	];

	useEffect(() => {
		const fetchData = async () => {
			setTotalAnticipations((await getTotalListAnticipation()).length);
		};

		fetchData();
	}, [anticipationFilters]);

	useEffect(() => {
		history.push(
			`/financial/anticipations?rowsPerPage=${anticipationFilters?.rowsPerPage}`
		);
		getListAnticipations(page);
	}, [anticipationFilters, page]);

	const changeRowsPerPage = (e) => {
		setLoading(true);
		setAnticipationFilters({
			...anticipationFilters,
			rowsPerPage: e,
		});
	};

	const changePage = (e) => {
		setLoading(true);
		setPage(e);
	};

	const handleDeleteAntecipação = () => {
		cancelAnticipation(deleteAnticipation);
	};

	return (
		<>
			<FinancialAnticipationsModal />
			<div className="d-flex flex-column justify-content-between flex-lg-row px-4 pt-4">
				<div className="w-75">
					<h3 className="financial-text title mb-3">Antecipações</h3>
					<p className="mb-3 mb-lg-0">
						As diretrizes de funcionamento e valores de percentual antecipável e
						taxas para antecipações seguem as regras do Pagar.me
					</p>
				</div>
				<button
					className="btn btn-grey-80 d-flex justify-content-center"
					data-toggle="modal"
					data-target="#financialAnticipation"
					style={{ width: "190px", height: "40px" }}
				>
					<i className="material-icons-outlined">pending_actions</i>
					<span className="ml-2">Solicitar Antecipação</span>
				</button>
			</div>
			<DateFilter
				screen={"Anticipations"}
				setPage={setPage}
				tabData={anticipationFilters}
				setTabData={setAnticipationFilters}
				exportData={anticipationExport}
			/>
			<div
				className="d-flex align-items-center px-4 mb-3"
				style={{ width: "100%" }}
			>
				<strong>
					MOSTRANDO {loading ? "0" : totalAnticipations} RESULTADOS
				</strong>
			</div>
			<div>
				<div>
					{loading ? (
						<GenericTableLoader />
					) : totalAnticipations > 0 ? (
						<List
							data={listAnticipations}
							page={page}
							colums={columsList}
							totalRows={totalAnticipations}
							handlePageChange={changePage}
							paginationServer={true}
							rowsPerPage={anticipationFilters?.rowsPerPage}
							handleRowsPerPageChange={changeRowsPerPage}
						/>
					) : (
						<FinancialEmpty
							img={"/images/empty_coupon.svg"}
							status={"antecipações"}
						/>
					)}
				</div>
			</div>
			{/* cancel anticipation */}
			<div
				className="modal fade"
				id="deleteAnticipation"
				tabIndex="-1"
				aria-labelledby="deleteAnticipationLabel"
				aria-hidden="true"
			>
				<div
					className="modal-dialog modal-dialog-centered"
					style={{ minWidth: "456px", maxHeight: "310px" }}
				>
					<div
						className="modal-content"
						style={{
							padding: "32px",
							minWidth: "456px",
							borderRadius: "8px",
						}}
					>
						<div className="modal-header d-flex flex-column m-0 p-0">
							<h5 className="financial-text title mb-2">
								Cancelar Antecipação
							</h5>
						</div>

						<div className="modal-body m-0 p-0">
							<div className="d-flex flex-column justify-content-start">
								<div className="d-flex flex-column mt-3 mb-4">
									<label
										className="m-0 p-0"
										style={{ fontSize: "16px", color: "#707070" }}
									>
										Tem certeza de que deseja <strong>cancelar</strong> este
										antecipação?
										<br />
										Esta ação não poderá ser revertida!
									</label>
								</div>
								<div className="flex-column">
									<label
										className="d-flex m-0 p-0"
										style={{ fontSize: "14px", color: "#6CD691" }}
									>
										<strong style={{ color: "#707070" }}>
											Valor solicitado:
										</strong>
										&nbsp;&nbsp;
										{"R$ " +
											parseFloat(deleteAnticipation?.amount).toLocaleString(
												"pt-br",
												{
													minimumFractionDigits: 2,
												}
											)}
									</label>
									<label
										className="d-flex m-0 p-0"
										style={{ fontSize: "14px", color: "#707070" }}
									>
										<strong>Data:</strong>&nbsp;&nbsp;
										{moment(deleteAnticipation?.dateUpdated).format(
											"DD/MM/YYYY - HH:mm:ss"
										)}
									</label>
									<label
										className="d-flex m-0 p-0"
										style={{ fontSize: "14px", color: "#707070" }}
									>
										<strong>Status:</strong>&nbsp;&nbsp;
										{deleteAnticipation.status === "pending"
											? "Antecipação Pendente"
											: deleteAnticipation.status === "approved"
											? "Antecipação Concluída"
											: deleteAnticipation.status === "building"
											? "Solicitação Enviada"
											: deleteAnticipation.status === "refused"
											? "Erro na Antecipação"
											: deleteAnticipation.status === "canceled"
											? "Solicitação Cancelada"
											: ""}
									</label>
								</div>

								<div className="d-flex flex-row justify-content-between mt-4 px-3">
									<button
										type="button"
										data-dismiss="modal"
										className="btn btn-danger outline"
										onClick={handleDeleteAntecipação}
										style={{ width: "190px" }}
									>
										Sim, quero cancelar
									</button>
									<button
										type="submit"
										className={`btn btn-success btn-icon d-flex justify-content-center`}
										style={{ padding: "10px 20px", width: "190px" }}
										data-dismiss="modal"
									>
										Não quero cancelar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
