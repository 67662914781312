import React, { useState, useEffect } from "react";
import { API } from "../../../../API";
import ContentLoader from "react-content-loader";
import Modal from "../../../../components/Modal";
import List from "../../../../components/List/List";
import Empty from "../../../../components/Empty";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import NewToastComponent from "../../../../components/NewToastComponent";

export default function CollectionsList(props) {
	const columsList = [
		{
			name: "Título",
			selector: "name",
			sortable: false,
			grow: 2,
		},
		{
			name: "Quantidade de produtos",
			selector: "name",
			sortable: false,
			center: true,
			grow: 3,
			cell: (row) => <div className="">{row.products.length}</div>,
		},
		{
			name: "",
			sortable: true,
			width: "150px",
			cell: (row) => (
				<>
					{row.url === "vitrine" ? (
						<Link
							id="btnGroupDrop1"
							to={`/collections/${page}/edit/${row.id}`}
							className="btn btn-info btn-block button-showcase"
						>
							Editar
						</Link>
					) : (
						<div className=" btn-group " role="group">
							<Link
								id="btnGroupDrop1"
								to={`/collections/${page}/edit/${row.id}`}
								className="btn btn-info btn-block "
							>
								Editar
							</Link>

							<>
								<button
									id="btnGroupDrop1"
									type="button"
									className="btn btn-sm border-left border-white btn-info rounded-right"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									<span className="material-icons" style={{ fontSize: "15px" }}>
										keyboard_arrow_down
									</span>
								</button>
								<div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
									<a
										className="dropdown-item text-danger"
										data-toggle="modal"
										data-target="#deleteCollection"
										onClick={() => setDeleteId(row.id)}
									>
										Excluir
									</a>
								</div>
							</>
						</div>
					)}
				</>
			),
		},
	];
	const [loading, setLoading] = useState(true);
	const [collections, setCollections] = useState([]);
	const [totalCollections, setTotalCollections] = useState(null);
	const [page, setPage] = useState(props.page);
	const [deleteId, setDeleteId] = useState("");
	const skele = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
	const [rowsPerPage, setRowsPerPage] = useState(20);

	useEffect(() => {
		getProducts();
	}, [props.page, rowsPerPage, page]);

	const getProducts = () => {
		setLoading(true);
		API.get(`api/tags/paged?page=${page}&perPage=${rowsPerPage}`)
			.then((response) => {
				setCollections(response.data.data);
				setTotalCollections(response.data.recordsTotal);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
				NewToastComponent({
					status: "error",
					title: "Não foi possível exibir a lista de produtos.",
				});
			});
	};
	const changePage = (e) => {
		setLoading(true);
		setPage(e);
		const stateObj = { page: props.page };
		window.history.pushState(stateObj, "", e);
	};
	const deleteCollection = () => {
		API.delete("api/tags?id=" + deleteId)
			.then(() => {
				getProducts();
				NewToastComponent({
					status: "success",
					title: "Coleção excluída com sucesso.",
				});
			})
			.catch(() => {
				NewToastComponent({
					status: "error",
					title: "Não foi possível excluir essa coleção.",
				});
			});
	};
	const skeleton = () => {
		return skele.map(
			() => (
				// <div style={{overflow: 'hidden'}}>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="115" height="10" />
					<rect x="650" y="26" rx="3" ry="3" width="52" height="10" />
					<rect x="970" y="17" rx="3" ry="3" width="100" height="35" />
				</ContentLoader>
			)
			// </div>
		);
	};

	const changeRowsPerPage = (e) => {
		setLoading(true);
		setRowsPerPage(e);
	};

	return (
		<div className="container dash-content">
			<div className="card">
				<div className="card-header d-flex justify-content-between align-items-center">
					<h4 className="card-title">Suas coleções</h4>
					<div className="card-actions text-right">
						<Link
							className="btn btn-new-width btn-grey-80"
							to={`/collections/${page}/new`}
						>
							Nova coleção
						</Link>
					</div>
				</div>
				<div className="card-content">
					<div className="card-body no-padding">
						{collections.length <= 0 && loading === false ? (
							<Empty
								img={"/images/empty_coupon.svg"}
								genre="sua primeira"
								name="coleção"
								link="/collections/new"
								import={false}
							/>
						) : loading ? (
							skeleton()
						) : (
							<List
								data={collections}
								page={parseInt(page)}
								colums={columsList}
								totalRows={totalCollections}
								handlePageChange={changePage}
								handleRowsPerPageChange={changeRowsPerPage}
								rowsPerPage={rowsPerPage}
								paginationServer={true}
							/>
						)}
					</div>
				</div>
			</div>
			<Modal
				id={"deleteCollection"}
				title={"Certeza que deseja excluir este item?"}
				delete={true}
				actionDelete={deleteCollection}
			/>
		</div>
	);
}
