import React, { useEffect } from "react";
import useBasicInformationService from "./BasicInformationService";
import CustomInput from "../../../../MUICustomComponents/CustomInput";
import CustomMaskInput from "../../../../MUICustomComponents/CustomMaskInput";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	IconButton,
	InputAdornment,
	Stack,
	Typography,
} from "@mui/material";
import { FormValidator } from "../../../../infrastructure/utils/FormUtils";
import CustomAutocomplete from "../../../../MUICustomComponents/CustomAutocomplete";
import { SellerView } from "../../../../domain/views/stores/SellerView";
import BasicInformationSkeleton from "./BasicInformationSkeleton";
import { SellerBasicInfoView } from "../../../../domain/views/stores/SellerBasicInfoView";
import InternationalPhoneInput from "../../../../components/InternationalPhoneInput/InternationalPhoneInput";

type BasicInfoProps = {
	sellerId: string;
	data: SellerView;
	createSeller: (basicInfoData: SellerBasicInfoView) => Promise<boolean>;
	updateSellerBasicInfo: (
		sellerId: string,
		sellerData: SellerView,
		basicInfoData: SellerBasicInfoView
	) => Promise<boolean>;
};

export default function BasicInformation({
	sellerId,
	data,
	createSeller,
	updateSellerBasicInfo,
}: BasicInfoProps) {
	const {
		// Props
		isLoading,
		setIsLoading,
		sellerInfo,
		showPassword,
		sellerClusters,
		currentSellerCluster,
		currentClusterInput,
		formErrors,
		setCurrentClusterInput,
		// Functions
		getOptionLabel,
		filterOptions,
		setCurrentSellerCluster,
		isOptionEqualToValue,
		handleFormSubmit,
		getSellerClusters,
		setSellerInfo,
		onChangeSeller,
		onWhatsappPhoneChange,
		handleClickShowPassword,
		handleMouseDownPassword,
	} = useBasicInformationService(sellerId, createSeller, updateSellerBasicInfo);

	useEffect(() => {
		if (!sellerId || !data) {
			getSellerClusters();
			setIsLoading(false);
			return;
		}
		const {
			pointSaleId,
			whatsappPhoneNumber,
			phoneNumber,
			companyName,
			fullName,
			email,
			password,
			cnpj,
			currentCluster,
			isEcommerce,
			isActive,
		} = data;
		setSellerInfo({
			pointSaleId,
			whatsappPhoneNumber,
			phoneNumber,
			companyName,
			fullName,
			email,
			password,
			cnpj,
			currentCluster,
			isEcommerce,
			isActive,
		});
		if (data.id) {
			getSellerClusters(currentCluster);
		}
	}, [data]);

	return (
		<Box
			m={4}
			minHeight={335}
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
		>
			<Box>
				<Typography variant="h5" pb={3} pl={0} sx={{ fontWeight: "bold" }}>
					Informações básicas
				</Typography>
				{isLoading ? (
					BasicInformationSkeleton()
				) : (
					<Grid container spacing={2}>
						<Grid item xs={6} pb={2}>
							<CustomInput
								name="pointSaleId"
								value={sellerInfo?.pointSaleId}
								title="Id do ponto de venda"
								error={FormValidator.hasInputError(formErrors, "pointSaleId")}
								helperText={FormValidator.translateHelperTextList(
									formErrors,
									"pointSaleId"
								)}
								onChange={onChangeSeller}
							/>
						</Grid>

						<Grid item xs={6} pb={2}>
							<CustomInput
								name="fullName"
								value={sellerInfo?.fullName}
								title="Nome completo"
								error={FormValidator.hasInputError(formErrors, "fullName")}
								helperText={FormValidator.translateHelperTextList(
									formErrors,
									"fullName"
								)}
								onChange={onChangeSeller}
							/>
						</Grid>

						<Grid item xs={6} pb={2}>
							<CustomInput
								name="email"
								value={sellerInfo?.email}
								title="Email"
								error={FormValidator.hasInputError(formErrors, "email")}
								helperText={FormValidator.translateHelperTextList(
									formErrors,
									"email"
								)}
								onChange={onChangeSeller}
							/>
						</Grid>

						<Grid item xs={6} pb={2}>
							<CustomInput
								name="password"
								value={sellerInfo?.password}
								title="Senha"
								error={FormValidator.hasInputError(formErrors, "password")}
								helperText={FormValidator.translateHelperTextList(
									formErrors,
									"password"
								)}
								onChange={onChangeSeller}
								type={showPassword ? "text" : "password"}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge="end"
										>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								}
							/>
						</Grid>

						<Grid item xs={6} pb={2}>
							<CustomMaskInput
								name="cnpj"
								value={sellerInfo?.cnpj}
								title="Cnpj"
								error={FormValidator.hasInputError(formErrors, "cnpj")}
								helperText={FormValidator.translateHelperTextList(
									formErrors,
									"cnpj"
								)}
								onChange={onChangeSeller}
								type="number"
								mask="99.999.999/9999-99"
							/>
						</Grid>

						<Grid item xs={6} pb={2}>
							<CustomInput
								name="companyName"
								value={sellerInfo?.companyName}
								title="Razão social"
								error={FormValidator.hasInputError(formErrors, "companyName")}
								helperText={FormValidator.translateHelperTextList(
									formErrors,
									"companyName"
								)}
								onChange={onChangeSeller}
							/>
						</Grid>

						<Grid item xs={6} pb={2}>
							<CustomMaskInput
								name="phoneNumber"
								value={sellerInfo?.phoneNumber}
								title="Telefone"
								error={FormValidator.hasInputError(formErrors, "phoneNumber")}
								helperText={FormValidator.translateHelperTextList(
									formErrors,
									"phoneNumber"
								)}
								onChange={onChangeSeller}
								type="number"
								mask="(99) 99999-9999"
							/>
						</Grid>

						<Grid item xs={6} pb={2}>
							<InternationalPhoneInput
								inputName="whatsappPhoneNumber"
								title="Número de whatsapp"
								phoneNumber={sellerInfo.whatsappPhoneNumber}
								onPhoneChange={onWhatsappPhoneChange}
								error={FormValidator.hasInputError(
									formErrors,
									"whatsappPhoneNumber"
								)}
								helperText={FormValidator.translateHelperTextList(
									formErrors,
									"whatsappPhoneNumber"
								)}
							/>
						</Grid>
						{sellerClusters.length > 0 && (
							<Grid item xs={12} pb={2}>
								<CustomAutocomplete
									id="currentCluster"
									options={sellerClusters}
									value={currentSellerCluster}
									inputValue={currentClusterInput}
									renderInputLabel="Grupo Econômico"
									placeholder="Grupo Econômico"
									filterOptions={filterOptions}
									getOptionLabel={getOptionLabel}
									onSelectChange={setCurrentSellerCluster}
									onInputChange={setCurrentClusterInput}
									isOptionEqualToValue={isOptionEqualToValue}
								/>
							</Grid>
						)}

						<Grid item xs={6} pb={2}>
							<FormControlLabel
								control={
									<Checkbox
										name="isActive"
										checked={sellerInfo?.isActive}
										onChange={onChangeSeller}
									/>
								}
								label={
									<div style={{ fontSize: "14px" }}>Essa loja está ativa</div>
								}
							/>
						</Grid>

						<Grid item xs={6} pb={2}>
							<FormControlLabel
								control={
									<Checkbox
										name="isEcommerce"
										checked={sellerInfo?.isEcommerce ?? false} // TODO when backend fixes nullable boolean, change back Seller types
										onChange={onChangeSeller}
									/>
								}
								label={
									<div style={{ fontSize: "14px" }}>
										Essa loja é um e-commerce
									</div>
								}
							/>
						</Grid>
					</Grid>
				)}
			</Box>
			<Stack direction="row" justifyContent="end">
				<Button
					type="submit"
					color="success"
					variant="contained"
					disabled={isLoading || FormValidator.hasError(formErrors)}
					onClick={() =>
						handleFormSubmit(sellerId, data, sellerInfo, currentSellerCluster)
					}
				>
					Salvar
				</Button>
			</Stack>
		</Box>
	);
}
