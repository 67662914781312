import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { APIV1 } from "../../../API";
import { toast } from "react-toastify";
import axios from "axios";

export default function Filter(props) {
	const [statusQueryGetUri, setStatusQueryGetUri] = useState("");
	const [customStatus, setCustomStatus] = useState("");
	const [runtimeStatus, setRuntimeStatus] = useState("");
	const [output, setOutput] = useState("");
	const [load, setLoad] = useState(false);
	const [isActive, setIsActive] = useState(
		props.isActive == null ? "" : props.isActive
	);
	const [isAvailable, setIsAvailable] = useState(
		props.isAvailable == null ? "" : props.isAvailable
	);
	const [hasStock, setHasStock] = useState(
		props.hasStock == null ? "" : props.hasStock
	);
	const [hasPrice, setHasPrice] = useState(
		props.hasPrice == null ? "" : props.hasPrice
	);
	const [query, setQuery] = useState(props.query == null ? "" : props.query);
	const history = useHistory();
	const [labelEmpty, setLabelEmpty] = useState(null);
	const [labelFilter, setLabelFilter] = useState(false);
	const [labelClear, setLabelClear] = useState(false);
	const [reload, setReload] = useState(false);
	const [count, setCount] = useState(1);
	const [color, setColor] = useState(false);
	const [filter, setFilter] = useState({
		isActive: isActive,
		isAvailable: isAvailable,
		hasStock: hasStock,
		hasPrice: hasPrice,
	});
	const [clearfilter, setClearFilter] = useState({
		isActive: "",
		isAvailable: "",
		hasStock: "",
		hasPrice: "",
	});

	useEffect(() => {
		getExportFilterProducts();
		if (
			isActive !== "" ||
			isAvailable !== "" ||
			hasStock !== "" ||
			hasPrice !== ""
		) {
			setLabelFilter(true);
			setColor(true);
			setLabelClear(false);
			setOutput("");
			setRuntimeStatus("");
		} else {
			setLabelFilter(true);
			setLabelClear(false);
		}

		const a = isActive !== "" ? 1 : 0;
		const b = isAvailable !== "" ? 1 : 0;
		const c = hasStock !== "" ? 1 : 0;
		const d = hasPrice !== "" ? 1 : 0;
		setCount(a + b + c + d);

		setFilter({
			...filter,
			isActive: isActive,
			isAvailable: isAvailable,
			hasStock: hasStock,
			hasPrice: hasPrice,
		});
	}, [isActive, isAvailable, hasStock, hasPrice]);

	const handleClearFilter = () => {
		if (count === 0) {
			setLabelClear(false);
			setColor(false);
			props.searchFilter(filter);
		} else {
			props.searchFilter(filter);
			setLabelFilter(false);
			setLabelClear(true);
		}
	};

	const clearSearchFilter = (e) => {
		setColor(false);
		setCount(0);
		setFilter({
			isActive: "",
			isAvailable: "",
			hasStock: "",
			hasPrice: "",
		});
		setIsActive("");
		setIsAvailable("");
		setHasStock("");
		setHasPrice("");
		setLabelEmpty(null);
		setLabelFilter(false);
		setLabelClear(false);
		setReload(true);
		props.searchFilter(clearfilter);
	};

	const getExportFilterProducts = async () => {
		await APIV1.get(
			`insights/export/products?clientId=${localStorage.getItem(
				"clientId"
			)}&hasPrice=${hasPrice}&hasStock=${hasStock}&isActive=${isActive}&isAvailable=${isAvailable}&query=${query}`
		)
			.then((response) => {
				setStatusQueryGetUri(response.data.statusQueryGetUri);
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const exportInsights = async () => {
		setLoad(true);
		var exportFilter = setInterval(async () => {
			const resp = await axios.get(statusQueryGetUri);

			setCustomStatus(resp.data.customStatus);
			setRuntimeStatus(resp.data.runtimeStatus);
			setOutput(resp.data.output);

			if (resp.data.output !== null) {
				clearInterval(exportFilter);
				setLoad(false);
			}
		}, 1000);
	};

	return (
		<div className="d-flex mt-4">
			<div className="container m-0 px-1">
				<div className="d-flex flex-wrap justify-content-between mx-3">
					<div className="col-6 p-0 autocomplete icon-right">
						<label className="" htmlFor="isActive">
							<strong>Código do Produto</strong>
						</label>
						<input
							type="text"
							className="form-control"
							id="search"
							name="pesquisa"
							placeholder="Filtrar por Código de Produto"
							defaultValue={props.query}
						/>
						{props.load ? (
							<i
								className="fas fa-spinner fa-spin"
								style={{ marginTop: "28px" }}
							></i>
						) : (
							<i className="material-icons" style={{ marginTop: "28px" }}>
								search
							</i>
						)}
					</div>

					<div className="d-flex flex-column">
						<label className="" htmlFor="isActive">
							<strong>SKUs sem estoque</strong>
						</label>
						<select
							className="custom-select"
							id="isActive"
							name="isActive"
							value={isActive}
							onChange={(e) => {
								setIsActive(e.target.value);
							}}
							style={{ width: "230px" }}
						>
							<option value="">MOSTRANDO NENHUM</option>
							<option value="true">SOMENTE ATIVOS</option>
							<option value="false">SOMENTE INATIVOS</option>
						</select>
					</div>

					<div className="d-flex flex-column">
						<label className="" htmlFor="hasStock">
							<strong>SKUs sem imagem</strong>
						</label>
						<select
							className="custom-select"
							id="hasStock"
							name="hasStock"
							value={hasStock}
							onChange={(e) => {
								setHasStock(e.target.value);
							}}
							style={{ width: "230px" }}
						>
							<option value="">MOSTRANDO NENHUM</option>
							<option value="true">SOMENTE EM ESTOQUE</option>
							<option value="false">SOMENTE SEM ESTOQUE</option>
						</select>
					</div>
				</div>

				<div className="d-flex p-3">
					<div className="d-flex align-items-center" style={{ width: "100%" }}>
						<strong>MOSTRANDO {props.totalProducts} RESULTADOS</strong>
					</div>
					<div style={{ width: "100%" }}>
						<div className="row m-0 d-flex justify-content-end">
							{runtimeStatus !== "Completed" ? (
								<button
									className="btn btn-outline btn-icon mr-2 d-flex justify-content-center"
									style={{
										padding: "10px",
										border: "1px solid #E9ECEC",
										background: "transparent",
										color: "#747E8B",
										width: "200px",
									}}
									onClick={() => exportInsights()}
								>
									{load == true ? (
										<i className="fas fa-spinner fa-spin"></i>
									) : (
										<i className="material-icons">get_app</i>
									)}
									&nbsp;&nbsp;Exportar SKUs
								</button>
							) : (
								<a
									href={output}
									className="btn btn-icon mr-2"
									style={{
										padding: "10px 20px",
										color: "#5A5E61",
										border: "1px solid #8D8D8D",
										backgroundColor: "transparent",
									}}
									onClick={() => exportInsights()}
								>
									<i className="material-icons">get_app</i>&nbsp;&nbsp;Baixar
									Produtos
								</a>
							)}

							{labelFilter == true ? (
								<button
									className={
										color === true
											? "btn btn-grey-80 btn-icon d-flex justify-content-center"
											: "btn btn-outline btn-icon d-flex justify-content-center"
									}
									onClick={() => handleClearFilter()}
									style={{ padding: "10px 20px", width: "200px" }}
								>
									Aplicar filtros ({count})
								</button>
							) : null}

							{labelClear == true ? (
								<button
									className="btn btn-outline btn-icon d-flex justify-content-center"
									onClick={() => clearSearchFilter()}
									style={{
										padding: "10px 20px",
										border: "1px solid #E9ECEC",
										background: "#E9ECEC",
										width: "200px",
										color: "#747E8B",
									}}
								>
									<i className="material-icons">delete_sweep</i>
									&nbsp;&nbsp;Limpar filtros ({count})
								</button>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
