import React, { useEffect, useState } from "react";
import DatePickerNeo from "../../../../../../components/DatePickerNeo";
import DropImage from "../../../../../../components/DropImage";

// import { Container } from './styles';

function BodyBanner() {
	const [ranges, setRanges] = useState([10, 25, 50, 75, 100]);
	const [selectedRanges, setSelectedRanges] = useState(0);
	const [verticalSpace, setVerticalSpace] = useState(false);
	const [horizontalSpace, setHorizontalSpace] = useState(false);
	const [orientation, setOrientation] = useState("landscape");

	const handleInputChange = (e) => {
		console.log(e.target.value);
		setSelectedRanges(parseInt(e.target.value));
	};

	useEffect(() => {
		if (!horizontalSpace || !verticalSpace) {
			setSelectedRanges(0);
		}
	}, [verticalSpace, horizontalSpace]);
	return (
		<>
			<div className="container">
				<div class="row">
					<div className="col-12">
						<p>
							<strong>ORIENTAÇÃO</strong>
						</p>
					</div>
					<div className="col-12 row">
						<div className="col-3 text-center d-flex justify-content-center">
							<div
								className={
									orientation === "landscape"
										? "app-editor-orientation orientation-active"
										: "app-editor-orientation"
								}
							>
								<div
									className="app-editor-orientation-button"
									onClick={() => setOrientation("landscape")}
								>
									<div className="app-editor-orientation-button-landscape"></div>
								</div>
								<div className="app-editor-orientation-text">Paisagem</div>
							</div>
						</div>
						<div className="col-3 text-center d-flex justify-content-center">
							<div
								className={
									orientation === "portrait"
										? "app-editor-orientation orientation-active"
										: "app-editor-orientation"
								}
							>
								<div
									className="app-editor-orientation-button"
									onClick={() => setOrientation("portrait")}
								>
									<div className="app-editor-orientation-button-portrait"></div>
								</div>
								<div className="app-editor-orientation-text">Retrato</div>
							</div>
						</div>
						<div className="col-3 text-center d-flex justify-content-center">
							<div
								className={
									orientation === "square"
										? "app-editor-orientation orientation-active"
										: "app-editor-orientation"
								}
							>
								<div
									className="app-editor-orientation-button"
									onClick={() => setOrientation("square")}
								>
									<div className="app-editor-orientation-button-square"></div>
								</div>
								<div className="app-editor-orientation-text">Quadrado</div>
							</div>
						</div>
						<div className="col-3 text-center d-flex justify-content-center">
							<div
								className={
									orientation === "stripe"
										? "app-editor-orientation orientation-active"
										: "app-editor-orientation"
								}
							>
								<div
									className="app-editor-orientation-button"
									onClick={() => setOrientation("stripe")}
								>
									<div className="app-editor-orientation-button-stripe"></div>
								</div>
								<div className="app-editor-orientation-text">Tarja</div>
							</div>
						</div>
					</div>
					<div className="col-12 mt-3">
						<p>
							<strong>MARGENS DO BANNER</strong>
						</p>
					</div>
					<div className="col-12">
						<div className="form-check">
							<input
								className="form-check-input"
								type="checkbox"
								value=""
								id="defaultCheck1"
								onChange={(e) => setVerticalSpace(e.target.checked)}
							/>
							<label className="form-check-label" for="defaultCheck1">
								Espaçamento Vertical
							</label>
						</div>
						<div className="form-check mt-1">
							<input
								className="form-check-input"
								type="checkbox"
								value=""
								id="defaultCheck2"
								onChange={(e) => setHorizontalSpace(e.target.checked)}
							/>
							<label className="form-check-label" for="defaultCheck2">
								Espaçamento Horizontal
							</label>
						</div>
					</div>
					<div className="col-12 mt-3">
						<p>
							<strong>CANTOS DO BANNER</strong>
						</p>
					</div>
					<div className="col-12 row ">
						<div className="col-2 d-flex align-items-center">
							<div
								className="app-editor-orientation-button-square"
								style={{
									backgroundColor: "#5A5E61",
									borderRadius: `${Math.pow(2, selectedRanges)}px`,
									width: "30px",
									height: "30px",
								}}
							></div>
						</div>
						<div
							className="col-2 pr-1 text-right d-flex align-items-center"
							style={{
								color: selectedRanges === 0 ? "#5A5E61" : "",
								fontWeight: selectedRanges === 0 ? "bold" : "",
							}}
						>
							Reto
						</div>
						<div className="col-5 pl-0 pr-0 d-flex align-items-center">
							<form>
								<div class="form-group">
									<input
										type="range"
										list="rangedOption"
										disabled={!horizontalSpace || !verticalSpace}
										class="form-control-range"
										id="formControlRange"
										value={selectedRanges}
										min="0"
										max="3"
										step="1"
										onInput={(e) => handleInputChange(e)}
									/>
									<datalist id="rangedOption">
										<option value="0"></option>
										<option value="1"></option>
										<option value="2"></option>
										<option value="3"></option>
									</datalist>
								</div>
							</form>
						</div>
						<div
							className="col-2 pl-1 d-flex align-items-center"
							style={{
								color: selectedRanges === 3 && "#5A5E61",
								fontWeight: selectedRanges === 3 && "bold",
							}}
						>
							Arredondado
						</div>
					</div>
					<div className="col-12 mt-3">
						<p>
							<strong>INFORMAÇÕES</strong>
						</p>
					</div>
					<div className="col-12 form-group">
						<label className="form-check-label" htmlFor="carrousel-title">
							Titulo do Banner 1
						</label>
						<input
							type="text"
							className="form-control"
							name="carrousel-title"
						/>
					</div>
					<div className="col-12">
						<p>
							<strong>Vigência do Banner</strong>
						</p>
					</div>
					<div className="col-12">
						<div className="form-group d-flex flex-wrap row">
							<div className="col-6">
								<DatePickerNeo
									label="Data de início"
									dateFormat="dd/MM/yyyy HH:mm"
									time={true}
									// defaultValue={this.state.startIn}
									// selected={this.state.startIn}
									// onChange={this.handleChangeStartDate}
									minDate={new Date()}
								/>
							</div>
							<div className="col-6">
								<DatePickerNeo
									label="Data de término"
									dateFormat="dd/MM/yyyy HH:mm"
									time={true}
									// defaultValue={this.state.endIn}
									// selected={this.state.endIn < this.state.startIn ? this.state.startIn : this.state.endIn}
									// onChange={this.handleChangeEndDate}
									minDate={new Date()}
								/>
							</div>
						</div>
					</div>
					<div className="col-12">
						<p>
							<strong>Ação do banner</strong>
						</p>
					</div>
					<div className="col-12">
						<div className="input-group col-sm-12 p-0 m-0">
							<div className="input-group-prepend">
								<label htmlFor="inputGroupSelect01">
									<select
										className="custom-select btn-light"
										id="inputGroupSelect01"
										//  defaultValue={this.state.action} value={this.state.action} onChange={this.changeAction}
									>
										<option value="none">Não fazer nada</option>
										<option value="open_product">Ir para o produto</option>
										<option value="open_category">Ir para a categoria</option>
										<option value="open_url">Abrir URL fora do APP</option>
										<option value="open_tag">Ir para a coleção</option>
										<option value="open_webview">
											Abrir URL dentro do APP
										</option>
										{/* <option value="open_promotion">Ir para a promoção</option> */}
										<option value="zoom">Zoom</option>
									</select>
								</label>
							</div>

							{/* {this.state.action === "open_webview" ? <input type="text" className="form-control" placeholder='Abrir URL dentro do APP' value={this.state.open_webview} onChange={this.handleChangeWebview} /> : null}
                                                    {this.state.action === "open_url" ? <input type="text" className="form-control" placeholder='Abrir URL fora do APP' value={this.state.open_url} onChange={this.handleChangeUrl} /> : null}
                                                    {this.state.action === "open_category" ? <input type="text" className="form-control" placeholder='Categoria' defaultValue={this.state.open_category == null ? null : 1 + ' Categoria'} data-toggle="modal" data-target="#selectCategories" /> : null}
                                                    {this.state.action === "open_product" ? <input type="text" className="form-control" placeholder='Produto' value={this.state.open_product == null ? null : 1 + ' Produto'} data-toggle="modal" data-target="#selectProduct" /> : null}
                                                    {this.state.action === "open_tag" ?
                                                        <select className="form-control" placeholder="Coleções" name='referenceId' defaultValue={this.state.open_tag} value={this.state.open_tag} onChange={this.handleTags}>
                                                            {this.state.collectionList.map(tag =>
                                                                <option key={tag.id} value={tag.url}>{tag.name}</option>
                                                            )}
                                                        </select> : null}

                                                    {this.state.action === "zoom" || this.state.action === "none" || this.state.referenceId == null ? null : null} */}
						</div>
					</div>
					<div className="form-group col-12 mt-3">
						<DropImage
							//  previewImage={this.state.imageUrl} recoveryStateLogo={this.recoveryStateImage}
							height={"310px"}
							width={"360px"}
							background={"#F2F4F4"}
						/>
					</div>

					<div className="col-12">
						<div className="new-question mb-2">
							<div>
								<span
									className="text-muted d-flex align-items-center justify-content-center"
									style={{
										color: "#BDBEC0",
										fontFamily: "Helvetica",
										fontSize: "14px",
										fontWeight: "normal",
										lineHeight: "10px",
									}}
								>
									<i className="material-icons mr-1">add</i>
									Adicionar banner
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default BodyBanner;
