import React, { useEffect } from "react";
import ProductManagementContext from "./providers/ProductManagementContext";
import Inventory from "./Inventory/Inventory";
import { useConfig } from "./../../../providers/ConfigProvider";
import LoadDashboard from "../../Dashboard/components/LoadDashboard";
import "./ProductManagementStyle.scss";

export default function ProductManagement(props) {
	const { mainLoading } = useConfig();
	useEffect(() => {
		if (!props.match.params.page) {
			window.location.href = "/catalog/management/inventory/1";
		}
	}, [props.match.params]);

	return (
		<>
			{mainLoading ? (
				<LoadDashboard />
			) : (
				<ProductManagementContext>
					<Inventory />
				</ProductManagementContext>
			)}
		</>
	);
}
