import React from "react";
import ContentLoader from "react-content-loader";
import { useOrders } from "../../providers/OrdersContext";
import {
	formatDocumentCnpj,
	formatDocumentCpf,
} from "../../../../infrastructure/utils/FormatMask";

export default function ClientInfo() {
	const { loading, buyer, documentTypeDict } = useOrders();
	const maskPhone = /(\d{2})(\d{5})(\d{4})/;

	const skeleton = () => {
		const skele = [0, 1, 2, 4, 5, 6];
		return skele.map((key) => (
			<ContentLoader
				key={key}
				speed={2}
				width={400}
				height={20}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="5" y="25" rx="3" ry="3" width="400" height="100" />
				<rect x="410" y="26" rx="3" ry="3" width="400" height="100" />
			</ContentLoader>
		));
	};

	return (
		<div className="card box-one">
			{loading ? (
				skeleton()
			) : (
				<>
					<div className="box-title">
						<h2 className="card-title">DADOS DO CLIENTE</h2>
					</div>
					<span className="card-subtitle">
						<strong>{buyer?.fullName}</strong>
					</span>

					{buyer?.cnpj ? (
						<span className="card-subtitle">
							<strong>{documentTypeDict.Cnpj}: </strong>
							{formatDocumentCnpj(buyer?.cnpj)}
						</span>
					) : (
						buyer?.cpf && (
							<span className="card-subtitle">
								<strong>{documentTypeDict.Cpf}: </strong>
								{formatDocumentCpf(buyer?.cpf)}
							</span>
						)
					)}
					{buyer?.email && (
						<span className="card-subtitle">
							<strong className="text-nowrap">E-mail:&nbsp;&nbsp;</strong>
							{buyer?.email}
						</span>
					)}
					{buyer?.phoneNumber && (
						<span className="card-subtitle">
							<strong>Telefone: </strong>
							{buyer.phoneNumber.replace(maskPhone, "($1) $2-$3")}
						</span>
					)}
					{buyer?.companyName && (
						<span className="card-subtitle">
							<strong>Razão social: </strong>
							{buyer.companyName}
						</span>
					)}

					{buyer?.stateInscription && (
						<span className="card-subtitle">
							<strong>Inscrição estadual: </strong>
							{buyer.stateInscription}
						</span>
					)}
				</>
			)}
		</div>
	);
}
