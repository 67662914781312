import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class ButtonPrimary extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			// Insere o link do botão
			<Link
				className={
					this.props.icon != null
						? "btn btn-secondary btn-icon-c-r btn-lg"
						: "btn-secondary"
				}
				style={{ width: this.props.width }}
				to={this.props.link}
			>
				{/* Insere o titulo do botão */}
				{this.props.title}
				{/* Verifica se existe icone, se houver insere ao lado direito */}
				{this.props.icon != null ? (
					<i className="material-icons ">{this.props.icon}</i>
				) : (
					``
				)}
			</Link>
		);
	}
}
