import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import {API} from '../API'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
//import { toast } from 'react-toastify';


export class DropImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '',
      uploadedFileCloudinaryUrl: '',
      carrouselImages: []
    };
    this.close = this.close.bind(this);
    this.removeItemCarrousel = this.removeItemCarrousel.bind(this);

  }

  onImageDropCarrousel(files) {
    this.setState({
      uploadedFile: files[0]
    });

    this.handleImageUploadCarrousel(files[0]);
  }

  onImageDrop(files) {
      this.setState({ type: files.type })
      this.setState({ uploadedFile: files[0] });
      this.handleImageUpload(files[0]); 
  }
  async componentWillMount() {

    // if (this.props.type == 'carrousel') {
    //   await this.setState({ carrouselImages: this.props.data })
    // } else {
    await this.setState({ uploadedFileCloudinaryUrl: this.props.previewImage })
    //}
    this.forceUpdate()

  }
  componentWillReceiveProps() {
   
    if (this.props.type == 'carrousel' && this.props.data != null || this.props.data != undefined) {
      this.setState({ carrouselImages: this.props.data });
      this.forceUpdate();
    }else {
      this.setState({
        uploadedFileCloudinaryUrl: this.props.previewImage
      })
    }
    
  }
  handleImageUpload(file) {
    const formData = new FormData();
    formData.append('file', file)
   
    API.post('api/images', formData, { headers: {  ContentType: 'multipart/form-data' } })
      .then(response => {
        // If request is good...
        this.props.recoveryStateLogo(response.data.relativeUrl)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  handleImageUploadCarrousel(file) {
    const formData = new FormData();
    formData.append('file', file)
 
    API.post('api/images', formData, { headers: { ContentType: 'multipart/form-data' } })
      .then(async response => {
        // If request is good...
        var image = {
          order: this.state.carrouselImages.length + 1,
          imageUrl: response.data.relativeUrl

        }
        await this.state.carrouselImages.push(image)
        this.forceUpdate();
        this.props.recoveryStateLogo(this.state.carrouselImages)
        // this.props.recoveryStateLogo(this.state.carrouselImages)


      })
      .catch((error) => {
        console.log(error)
      })
  }
  close() {
    this.setState({ uploadedFileCloudinaryUrl: '' })
    this.props.recoveryStateLogo('')
  }
  removeItemCarrousel(index) {
    this.state.carrouselImages.splice(index, 1)
    this.forceUpdate();
  }
  render() {


    return (

      <div className="" style={{ height: this.props.height, width: this.props.width }}>



        {this.props.type == 'carrousel' ?
          <div >
            {this.state.carrouselImages.length > 0 ?
              <div className='row'>
                {this.state.carrouselImages.map((item, i) =>
                  <div className='logo-upload ' style={{ backgroundImage: 'url(' + item.imageUrl + ')', borderRadius: '5px', backgroundSize: 'cover', backgroundPosition: 'center', margin: '15px', width: '180px', height: '180px', border: '3px solid #E9ECEC' }}>
                    <button style={{ marginLeft: '100px' }} type='button' onClick={() => this.removeItemCarrousel(i)} className="close-img-carrousel"></button>
                    {/* <img src={item.imageUrl} width='180px' height='180px' key={i} /> */}
                  </div>
                )}
                <Dropzone
                  onDrop={this.onImageDropCarrousel.bind(this)}
                  accept="image/*"
                  multiple={false}>
                  {({ getRootProps, getInputProps }) => {
                    return (
                      <div className="hover-input mt-2 " style={{ width: '180px' }}
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        {
                          <div className="logo-upload mt-2">

                            <div style={{
                              height: this.props.height,
                              width: this.props.width,
                              backgroundColor: this.props.background,
                              border: ' 3px dashed #E9ECEC'
                            }} className="flex-center box-img" >

                              <p style={{ width: this.props.width, textAlign: 'center', color: this.props.colorText }}>
                              <i className="material-icons">
                                note_add
          </i>
          <br/> Arraste ou escolha um arquivo de imagem 
                               
          </p>
                            </div>
                          </div>
                        }
                      </div>
                    )
                  }}
                </Dropzone>
              </div>
              :
              <Dropzone
                onDrop={this.onImageDropCarrousel.bind(this)}
                accept="image/*"
                multiple={false}>
                {({ getRootProps, getInputProps }) => {
                  return (
                    <div className="hover-input col-12"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      {
                        <div className="logo-upload">

                          <div style={{
                            height: this.props.height,
                            width: this.props.width,
                            backgroundColor: this.props.background
                          }} className="flex-center box-img" >

                            <p style={{ width: this.props.width, textAlign: 'center', color: this.props.colorText }}>
                            <i className="material-icons">
                                note_add
                           
          </i> <br/>
                              Arraste ou escolha um arquivo de imagem
                            
          </p>
                        </div>
                        </div>
                      }
                    </div>
                  )
                }}
              </Dropzone>}


        <br /> <br />

      </div>
          :

    <Dropzone
      onDrop={this.onImageDrop.bind(this)}
      accept="image/*"
      multiple={false}>
      {({ getRootProps, getInputProps }) => {
        return (
          <div className="hover-input"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {
              <div className="logo-upload">
                {
                  this.state.uploadedFileCloudinaryUrl != '' || this.props.previewImage != '' ?
                    <button style={{ marginLeft: this.props.width }} onClick={this.close} className="close-img"></button>
                    : null
                }
                <div style={{
                  height: this.props.height,
                  width: this.props.width,
                  backgroundColor: this.props.background
                }} className="flex-center box-img" >

                  {this.props.previewImage != '' && this.state.uploadedFileCloudinaryUrl == '' ?
                    <img
                      src={this.props.previewImage}
                      style={{ width: this.props.width }} />
                    :
                    this.state.uploadedFileCloudinaryUrl != '' ?
                      <img
                        src={this.state.uploadedFileCloudinaryUrl}
                        style={{ width: this.props.width }} /> :
                      <p style={{ width: this.props.width, textAlign: 'center', color: this.props.colorText }}>Arraste ou escolha um arquivo <i className="material-icons">
                        vertical_align_bottom
                </i></p>}
                </div>
              </div>
            }
          </div>
        )
      }}
    </Dropzone>
  }



      </div>
    )
  }
};

export default DropImage;
