import React, { useCallback } from "react";

const MaskWeight = ({ ...props }) => {
	const handleOnChange = useCallback((e) => {
		const regex = /^\d{0,14}(,\d{0,2})?$/;
		let value = e.target.value;
		if (!regex.test(value)) {
			value = value.slice(0, -1);
		}
		e.target.value = value;
		props.handleWeight(e.target.value);
	}, []);

	return (
		<div className="input-group">
			<input
				{...props}
				defaultValue={props?.defaultValue?.replace(/\./g, "") || ""}
				onChange={handleOnChange}
				placeholder="0,01"
			/>
			<div className="input-group-append">
				<span className="input-group-text" style={{ fontSize: "14px" }}>
					Kg
				</span>
			</div>
		</div>
	);
};

export default MaskWeight;
