import { Box, Button, Stack } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../MUICustomComponents/CustomTypography";
import { i18n } from "../../../../../translate/i18n";
import ReceivingMethodsService, {
	ReceivingMethodView,
} from "./ReceivingMethodsService";
import ReceivingMethodComponent from "./ReceivingMethodComponent";
import LoadingButtonIcon from "../../../../../components/LoadingButtonIcon";
import EmptyComponent from "../../../../../components/EmptyComponent/EmptyComponent";
import { FormValidator } from "../../../../../infrastructure/utils/FormUtils";
import CustomSkeleton from "../../../../../MUICustomComponents/CustomSkeleton";

type Props = { conditionId: string };

export default function ReceivingMethods({ conditionId }: Props) {
	const {
		receivingMethodList,
		isLoadingScreen,
		isLoadingSubmit,
		formErrors,
		changeInstallmentQuantity,
		changeReceivingMethod,
		handleSubmit,
	} = ReceivingMethodsService(conditionId);

	const contentToBeRendered = (): JSX.Element => {
		if (isLoadingScreen) {
			return <CustomSkeleton quantity={6} height={40} />;
		}
		if (receivingMethodList.length <= 0) {
			return (
				<EmptyComponent
					imageSrc={"/images/empty_inventory.svg"}
					title={i18n.t("paymentConfig.EmptyReceivingMethodsTitle")}
					message={i18n.t("paymentConfig.EmptyReceivingMethodsMessage")}
					createButton={{
						text: i18n.t("paymentConfig.NewReceivingMethod"),
						color: "grey80",
						variant: "contained",
						onClick: () =>
							(window.location.href = `${process.env.REACT_APP_API_ENDPOINT}/admin/receivingmethods`),
					}}
				/>
			);
		}
		return (
			<>
				{receivingMethodList.map((receivingMethod: ReceivingMethodView) => (
					<ReceivingMethodComponent
						key={receivingMethod.receivingMethodId}
						receivingMethod={receivingMethod}
						formErrors={formErrors}
						changeInstallmentQuantity={changeInstallmentQuantity}
						changeReceivingMethod={changeReceivingMethod}
					/>
				))}
				<Stack direction="row" justifyContent="end" marginTop={2}>
					<Button
						type="submit"
						color="success"
						variant="contained"
						disabled={isLoadingSubmit || FormValidator.hasError(formErrors)}
						onClick={handleSubmit}
					>
						{isLoadingSubmit ? (
							<LoadingButtonIcon />
						) : (
							i18n.t(`buttons.General.BtnSave`).toString()
						)}
					</Button>
				</Stack>
			</>
		);
	};

	return (
		<div className="card">
			<Box p={4}>
				<CustomTypography variant="title">
					{i18n.t("paymentConfig.ReceivingMethods").toString()}
				</CustomTypography>
				{contentToBeRendered()}
			</Box>
		</div>
	);
}
