import React from "react";
import { useOrders } from "../../providers/OrdersContext";
import Skeleton from "./Skeleton";
import { i18n } from "../../../../translate/i18n";
import {
	formatPhoneNumber,
	formatDocument,
	formatZipCode,
} from "../../../../infrastructure/utils/FormatMask";

export default function General() {
	const { loading, order } = useOrders();

	return (
		<div className="order-main-box">
			{loading ? (
				<Skeleton />
			) : (
				<>
					<div className="order-header-box">
						<h2>{i18n.t("orderDetails.General.CustomerData")}</h2>
					</div>
					<div className="order-body-box m-0 p-0 pt-2">
						<p className="text-blueish">
							<strong>{order?.Buyer?.FullName}</strong>
						</p>
						{order?.Buyer?.Document && (
							<p className="text-blueish">
								<strong>
									{order?.Buyer?.DocumentType === "Cpf"
										? i18n.t("orderDetails.General.BuyerDoc")
										: i18n.t("orderDetails.General.SellerDoc")}
								</strong>
								:{" "}
								{formatDocument(
									order?.Buyer?.DocumentType,
									order?.Buyer?.Document
								)}
							</p>
						)}
						{order?.Buyer?.Email && (
							<p className="text-blueish">
								<strong>{i18n.t("orderDetails.General.Email")}: </strong>
								{order?.Buyer?.Email}
							</p>
						)}
						{order?.Buyer?.PhoneNumber && (
							<p className="text-blueish">
								<strong>{i18n.t("orderDetails.General.PhoneNumber")}: </strong>
								{formatPhoneNumber(order?.Buyer?.PhoneNumber)}
							</p>
						)}
						{order?.Buyer?.CompanyName && (
							<p className="text-blueish">
								<strong>{i18n.t("orderDetails.General.CompanyName")} </strong>
								{order?.Buyer?.CompanyName}
							</p>
						)}
					</div>
					<div className="order-header-box pt-2">
						<h2>{i18n.t("orderDetails.General.BillingAddress")}</h2>
					</div>
					{!!order?.ShippingData?.DeliveryAddress && (
						<div className="order-body-box m-0 p-0 pt-2">
							<p className="text-blueish">
								<strong>{order?.Buyer?.FullName}</strong>
							</p>
							<p className="text-blueish">
								{order?.ShippingData?.DeliveryAddress?.Street}{" "}
								{order?.ShippingData?.DeliveryAddress?.Number}{" "}
								{order?.ShippingData?.DeliveryAddress?.Complement}
							</p>
							<p className="text-blueish">
								{order?.ShippingData?.DeliveryAddress?.Neighborhood}{" "}
								{order?.ShippingData?.DeliveryAddress?.City}{" "}
								{order?.ShippingData?.DeliveryAddress?.State}
							</p>
							<p className="text-blueish">
								{i18n.t("orderDetails.General.ZipCode")}:{" "}
								{formatZipCode(order?.ShippingData?.DeliveryAddress?.ZipCode)}
							</p>
						</div>
					)}
				</>
			)}
		</div>
	);
}
