import React, { useCallback, useState } from "react";

const Input = ({ ...props }) => {
	const [priceValue, setPriceValue] = useState("");
	const handleKeyUp = useCallback((e) => {
		let value = e.currentTarget.value;
		value = value.replace(/\D/g, "");
		value = value.replace(/(\d)(\d{2})$/, "$1,$2");
		value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
		e.currentTarget.value = value;
		setPriceValue(e);
		return e;
	}, []);

	return (
		<>
			{props.vouchersForm === true ? (
				<div className="input-group">
					<input
						{...props}
						onKeyUp={handleKeyUp}
						placeholder="0,01"
						style={{ borderRadius: "0 5px 5px 0" }}
					/>
				</div>
			) : (
				<div
					className={`${
						priceValue || priceValue === "" ? "form-neo" : "form-neo-error"
					}`}
				>
					<span style={{ marginBottom: "2px" }}>R$</span>
					<input
						{...props}
						className="form-neo input-neo"
						onKeyUp={handleKeyUp}
						placeholder="0,01"
						style={{ borderRadius: "0 5px 5px 0" }}
					/>
				</div>
			)}
		</>
	);
};

export default Input;
