import React from "react";
import { Link } from "react-router-dom";
import { useFinancial } from "../providers/FinancialContext";

export default function FinancialTabs({ url }) {
	const { loading } = useFinancial();
	return (
		<div className="financial-tabs">
			<div className="change-tabs">
				<Link
					to={`/financial/payables`}
					className={
						url === "/financial/payables"
							? "actived"
							: loading
							? "loading"
							: "disabled"
					}
					onClick={(e) => loading && e.preventDefault()}
				>
					Recebíveis
				</Link>
				<Link
					to={`/financial/withdraw`}
					className={
						url === "/financial/withdraw"
							? "actived"
							: loading
							? "loading"
							: "disabled"
					}
					onClick={(e) => loading && e.preventDefault()}
				>
					Saques
				</Link>
				<Link
					to={`/financial/anticipations`}
					className={
						url === "/financial/anticipations"
							? "actived"
							: loading
							? "loading"
							: "disabled"
					}
					onClick={(e) => loading && e.preventDefault()}
				>
					Antecipações
				</Link>
				{/* Hide "Extrato"/"Statement" screen until improvements are done */}
				{/* <Link
					to={`/financial/statement`}
					className={
						url === "/financial/statement"
							? "actived"
							: loading
							? "loading"
							: "disabled"
					}
					onClick={(e) => loading && e.preventDefault()}
				>
					Extrato
				</Link> */}
			</div>
		</div>
	);
}
