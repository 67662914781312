import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import IntegrationState from "./IntegrationState";
import SearchInputClose from "../../../components/SearchInputClose";
import { loriTriggersApi } from "../../../infrastructure/api/LoriTriggers";
import { i18n } from "../../../translate/i18n";
import NewToastComponent from "../../../components/NewToastComponent";

function AllIntegrationsState() {
	const [integrations, setIntegrations] = useState([]);
	const [filteredIntegrations, setFilteredIntegrations] = useState([]);
	const [loading, setLoading] = useState(true);
	const [query, setQuery] = useState("");

	useEffect(() => {
		getIntegrations();
	}, []);

	const getIntegrations = async () => {
		setLoading(true);
		try {
			let clientList = [];
			const response = await loriTriggersApi.getAllLoriTriggers();
			response.forEach((item) => {
				if (clientList.some((client) => client.clientId === item.clientId)) {
					clientList[
						clientList.findIndex((client) => client.clientId === item.clientId)
					].integrationsArray.push(item);
				} else {
					clientList.push({
						clientId: item.clientId,
						integrationsArray: [item],
					});
				}
			});
			setIntegrations(clientList);
			setFilteredIntegrations(clientList);
			setLoading(false);
		} catch {
			setLoading(false);
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
		}
	};

	const handleQueryChange = (queryValue) => {
		setQuery(queryValue);
		if (queryValue === "") {
			setFilteredIntegrations(integrations);
			return;
		}
		const clientName = queryValue.toLowerCase();
		const newIntegrationList = integrations.filter((integration) => {
			const clientId = integration.clientId.toLowerCase();
			return clientId.indexOf(clientName) !== -1;
		});
		setFilteredIntegrations([...newIntegrationList]);
	};

	const skeleton = () => {
		const skele = [0, 1, 2, 3, 4];
		return skele.map((key) => (
			<ContentLoader
				key={key}
				speed={2}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="40" y="26" rx="3" ry="3" width="200" height="10" />
				<rect x="1000" y="26" rx="3" ry="3" width="50" height="10" />
			</ContentLoader>
		));
	};

	const renderItemList = (client, index) => (
		<div
			key={index}
			className="card card-body col-12 mb-2"
			style={{ padding: "10px" }}
		>
			<a
				className="col-12 d-flex justify-content-between"
				data-toggle="collapse"
				style={{ padding: "0px", color: "#343a40" }}
				href={`#${client.clientId}`}
				role="button"
				aria-expanded="false"
				aria-controls={client.clientId}
			>
				<span className="d-flex justify-content-between">
					<span style={{ marginRight: 10 }}>
						{client.integrationsArray[0].isActive &&
						!(
							client.integrationsArray[0].message === "Finalized" ||
							client.integrationsArray[0].message === "Running"
						) ? (
							<i className="material-icons" style={{ color: "#FF5B5C" }}>
								error
							</i>
						) : (
							<i className="material-icons" style={{ color: "#6CD691" }}>
								check_circle
							</i>
						)}
					</span>
					{client.clientId}
				</span>
				<span>
					<i className="material-icons">add</i>
				</span>
			</a>
			<div className="collapse mt-3" id={client.clientId}>
				<IntegrationState integrationsList={client.integrationsArray} />
			</div>
		</div>
	);

	return (
		<div className="card">
			<div className="card-header">
				<section className="d-flex justify-content-between align-items-center">
					<h4 className="card-title mb-3">Integrações</h4>
					<div className="card-actions">
						<button
							className="btn-sm btn-info light d-flex align-items-center"
							onClick={() => getIntegrations()}
							disabled={loading}
						>
							<i className="material-icons">autorenew</i>
						</button>
					</div>
				</section>
				<SearchInputClose
					value={query}
					onChange={handleQueryChange}
					placeholder={"Busque por clientId"}
				/>
			</div>
			<div className="card-body p-2">
				{loading ? (
					skeleton()
				) : (
					<div
						className="conditions"
						style={{ height: "304px", overflowX: "auto", padding: "10px" }}
					>
						{filteredIntegrations.map((client, index) =>
							renderItemList(client, index)
						)}
					</div>
				)}
			</div>
		</div>
	);
}

export default AllIntegrationsState;
