import React, { useEffect, useRef, useState } from "react";
import { APIV2 } from "../../../../API";
import DragDropList from "../../../../components/DragDropList";
import PreviewTutorial from "../../../../components/preview/PreviewTutorial";
import MaterialIcons from "./MaterialIcons.json";
import { toast } from "react-toastify";
import Modal from "../../../../components/Modal";
import Empty from "../../../../components/Empty";
import Button from "../../../../components/Button";
import ContentLoader from "react-content-loader";

export default function Index(props) {
	const [startDate, setStartDate] = useState(new Date());
	const [tuto, setTuto] = useState([]);
	const [tutorial, setTutorial] = useState({
		items: [],
		icons: [],
		selectItem: [],
		id: null,
		name: null,
		startDate: new Date(),
		modal: "#deleteTutorial",
		loading: true,
		order: null,
		clientId: null,
		primaryColor: localStorage.getItem("primaryColor"),
		hole: localStorage.getItem("hole"),
	});
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectItem, setSelectItem] = useState(true);
	const [disable, setDisable] = useState(false);
	const [newItem, setNewItem] = useState({});
	const ref = useRef(null);

	useEffect(() => {
		getTutorial();
	}, []);

	useEffect(() => {
		setTutorial((prevState) => ({
			...prevState,
			items: [...prevState.items, newItem],
		}));
	}, [newItem]);

	useEffect(() => {
		if (ref.current) ref.current.updateList();
	}, [tutorial.items]);

	useEffect(() => {
		setTutorial(tuto);
	}, [tuto]);

	const getTutorial = async () => {
		setLoading(true);
		let pages;
		let reorder;
		const icons = MaterialIcons;
		try {
			const response = await APIV2.get("management/marketing/tutorials");
			setTuto(response.data);
			setItems(response.data);
			if (response.data.length > 0) {
				pages = response.data[0].pages;
				reorder = await pages.sort((a, b) => {
					return a.order - b.order;
				});
				setTutorial((prevState) => ({
					...prevState,
					items: reorder,
					id: response.data[0].id,
					name: response.data[0].name,
					clientId: response.data[0].clientId,
					icons: icons,
				}));
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error(error);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	const newTutorial = async () => {
		if (items == undefined || items == null || items.length <= 0) {
			let body = {
				name: "Tutorial",
				startDate: startDate,
				clientId:
					tutorial.hole === 1 ? null : localStorage.getItem("client_id"),
				pages: [
					{
						order: 0,
						iconName: null,
						title: "Novo Titulo " /* + tutorial.items.length */,
						content: "Novo Conteúdo",
					},
				],
			};
			await APIV2.post("management/marketing/tutorials", body)
				.then((response) => {
					toast.success("Novo tutorial criado com sucesso! 😃");
				})
				.then(() => {
					getTutorial();
				})
				.catch((error) => {
					console.error(error);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				});
		} else {
			setLoading(false);
			setNewItem({
				iconName: tutorial.icon,
				title: "Novo Titulo " + tutorial.items.length,
				content: "Novo Conteúdo",
				order: tutorial.items.length + 1,
			});
		}
	};

	const setItem = (newItems) => {
		setTutorial((prevState) => ({ ...prevState, items: newItems }));
	};

	const changeTutorial = (tuto) => {
		for (let i in tutorial.items) {
			if (tutorial.items[i].order === tuto.order) {
				tutorial.items[i] = tuto;
				setSelectItem(tutorial.items[i]);
			}
		}
	};

	const saveTutorial = async () => {
		setDisable(true);
		for (let i in tutorial.items) {
			if (tutorial.items[i].order != i) {
				tutorial.items[i].order = +i;
			}
		}
		let body = {
			id: tutorial.id,
			name: tutorial.name,
			startDate: startDate /* ajuste na data */,
			clientId: tutorial.hole === 1 ? null : localStorage.getItem("client_id"),
			pages: tutorial.items,
		};

		await APIV2.put("management/marketing/tutorials/" + tutorial.id, body)
			.then((response) => {
				toast.success("Tutorial editado com sucesso! 😃");
				setDisable(false);
				getTutorial();
			})
			.catch((error) => {
				console.error(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
				setDisable(false);
			});
	};

	const setOrder = async (odr) => {
		let obj = tutorial.items.filter((n) => n.order !== odr);
		await setTutorial((prevState) => ({ ...prevState, items: obj }));
	};

	const deleteTutorial = () => {
		setTutorial((prevState) => ({ ...prevState, items: tutorial.order }));
		toast.error("Etapa excluída com sucesso! 😃");
		const button = document.getElementById("close");
		button.click();
	};

	const setItemPreview = async (i) => {
		setSelectItem(tutorial.items[i]);
	};

	const deleteDefaultTutorial = async () => {
		await APIV2.delete(`management/marketing/tutorials/${tutorial.id}`)
			.then((response) => {
				toast.success("Tutorial excluido com sucesso! 😃");
				setLoading(true);
			})
			.then(() => {
				getTutorial();
			})
			.catch((error) => {
				console.error(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const renderScreen = () => {
		return (
			<div className="row">
				<div className="col-7">
					{loading ? (
						skeleton()
					) : (
						<>
							<DragDropList
								icons={MaterialIcons}
								setItemPreview={setItemPreview}
								items={tutorial.items}
								tutorial={true}
								setItem={setItem}
								changeTutorial={changeTutorial}
								setOrder={setOrder}
								modal={tutorial.modal}
								ref={ref}
							/>
							{items.length !== 0 ? (
								<>
									{tutorial.items.length === 0 && (
										<h3
											className="p-5 d-flex align-items-center justify-content-center"
											style={{
												color: "#BDBEC0",
												fontFamily: "Helvetica",
												fontSize: "18px",
												fontWeight: "normal",
												lineHeight: "10px",
											}}
										>
											Não há etapas cadastradas
										</h3>
									)}
									<div className="row m-1 d-flex justify-content-around">
										<button
											onClick={() => newTutorial()}
											className="new-question d-flex justify-content-center align-items-center col-5"
										>
											<i className="material-icons mr-1">add</i>Nova etapa
										</button>
										<button
											onClick={() => deleteDefaultTutorial()}
											data-toggle="modal"
											data-target="#deleteDefaultTutorial"
											className="new-question d-flex justify-content-center align-items-center col-5"
										>
											<i className="material-icons mr-1">delete_outline</i>
											Excluir Tutorial
										</button>
									</div>
								</>
							) : (
								<Button
									onClick={() => newTutorial()}
									className="new-question d-flex justify-content-center align-items-center"
								>
									<i className="material-icons mr-2">add</i>Criar novo tutorial
								</Button>
							)}
						</>
					)}
				</div>
				<div className="col">
					<PreviewTutorial itens={selectItem} />
				</div>
			</div>
		);
	};

	const skeleton = () => {
		return (
			<>
				<ContentLoader
					speed={2}
					width={740}
					height={100}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="10 10 1300 400"
					style={{ width: "100%" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="2100" height="300" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={740}
					height={100}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="10 10 1300 400"
					style={{ width: "100%" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="2100" height="300" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={740}
					height={100}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="10 10 1300 400"
					style={{ width: "100%" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="2100" height="300" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={740}
					height={100}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="10 10 1300 400"
					style={{ width: "100%" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="2100" height="300" />
				</ContentLoader>
			</>
		);
	};

	return (
		<>
			<div className="card">
				{!tutorial ? (
					<Empty
						img={"/images/empty_sellers.svg"}
						genre="seu primeiro"
						name="tutorial"
						align={true}
						onClick={() => newTutorial()}
						tutorial={true}
					/>
				) : (
					<>
						<div className="card-header d-flex justify-content-between align-items-center">
							<h4 className="card-title">Tutorial do APP</h4>
						</div>
						<div className="card-content">
							<div className="card-body pt-0">
								{renderScreen()}
								{items.length !== 0 && (
									<div className="d-flex justify-content-start mt-3">
										<Button
											loading={disable}
											disable={disable}
											className="btn btn-success"
											onClick={() => saveTutorial()}
										>
											Salvar Tutorial
										</Button>
									</div>
								)}
							</div>
							<Modal
								id={"deleteTutorial"}
								title={"Tem certeza que deseja excluir esta etapa?"}
								delete={true}
								actionDelete={deleteTutorial}
							/>
							<Modal
								id={"deleteDefaultTutorial"}
								title={"Tem certeza que deseja excluir este tutorial?"}
								delete={true}
								actionDelete={deleteDefaultTutorial}
							/>
						</div>
					</>
				)}
			</div>
		</>
	);
}
