import React, { useEffect, useState } from "react";
import InvoiceOrderModal from "../OrderModal/InvoiceOrderModal";
import Skeleton from "./Skeleton";
import { i18n } from "../../../../translate/i18n";
import { useOrders } from "../../providers/OrdersContext";

export default function Invoicing({ id }) {
	const { loading, order, flowStatus } = useOrders();
	const [count, setCount] = useState(0);
	const [disableCarousel, setDisableCarousel] = useState({
		prev: true,
		next: false,
	});
	const [invoiceList, setInvoiceList] = useState([]);
	const enableTooltips =
		order?.Invoices?.length === 0 && order?.Status !== "WaitingInvoice";

	useEffect(() => {
		setInvoiceList(order?.Invoices);
	}, [order]);

	useEffect(() => {
		if (count === 0) {
			setDisableCarousel({ prev: true, next: false });
		} else if (count === invoiceList.length - 1) {
			setDisableCarousel({ prev: false, next: true });
		} else {
			setDisableCarousel({ prev: false, next: false });
		}
	}, [count]);

	const showButtonAddInvoice = () => {
		return (
			<div className="btn-tooltip">
				<button
					className={
						order?.Status !== "WaitingInvoice"
							? "btn btn-grey-50 d-flex align-items-center justify-content-center w-100"
							: "btn btn-grey-80 d-flex align-items-center justify-content-center w-100"
					}
					style={{ height: "43px" }}
					data-toggle="modal"
					data-target="#invoiceOrders"
					disabled={order?.Status !== "WaitingInvoice" ? true : false}
				>
					<i className="material-icons-outlined px-2">receipt</i>
					{order?.Invoices?.length <= 0
						? i18n.t("orderDetails.Invoicing.AddInvoice")
						: i18n.t("orderDetails.Invoicing.AddNewInvoice")}
				</button>
				{enableTooltips && (
					<span className="msg-tooltip" style={{ width: "182px" }}>
						{i18n.t("orderDetails.Invoicing.MessageTooltipInvoicing")}
					</span>
				)}
			</div>
		);
	};

	const showInvoice = () => {
		return (
			<>
				<div className="order-body-box m-0 p-0 pt-2">
					<p className="text-blueish">
						<strong>{i18n.t("orderDetails.General.InvoiceValue")}: </strong>
						{order?.Invoices[0].Amount?.toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						})}
					</p>
					<p className="text-blueish">
						<strong>{i18n.t("orderDetails.General.InvoiceNumber")}: </strong>
						{order?.Invoices[0]?.Number}
					</p>
					<p className="text-blueish">
						<strong>
							{i18n.t("orderDetails.General.InvoiceSerialNumber")}:{" "}
						</strong>
						{order?.Invoices[0]?.SerialNumber}
					</p>
					<p className="text-blueish">
						<strong>{i18n.t("orderDetails.General.InvoiceKey")}: </strong>{" "}
						{order?.Invoices[0]?.Key}
					</p>
					<p className="text-blueish">
						<strong>{i18n.t("orderDetails.General.IssuanceDate")}: </strong>
						{new Date(order?.Invoices[0]?.IssuanceDate).toLocaleString("pt-BR")}
					</p>
				</div>

				{order?.Invoices[0].FileUrl && (
					<div className="order-body-box m-0 p-0">
						<p
							className="text-blueish m-0 px-0 py-2"
							style={{ fontSize: "13px" }}
						>
							<strong>{i18n.t("orderDetails.General.InvoiceFile")}: </strong>
						</p>

						<div className="d-flex justify-content-center flex-column m-0 p-0">
							<div className="download-invoice m-0 p-0">
								<div className="download-invoice-label">
									<i className="material-icons-outlined">insert_drive_file</i>
									<span className="text-grey-60">
										&nbsp;{i18n.t("orderDetails.Invoicing.XmlFile")}
									</span>
								</div>
								<div className="download-invoice-icons">
									<a href={order?.Invoices[0].FileUrl} target="_blank">
										<i className="material-icons-outlined">get_app</i>
									</a>
								</div>
							</div>
						</div>
					</div>
				)}
			</>
		);
	};

	const showListInvoices = () => {
		return (
			<>
				<div
					id="carouselInvoiceControls"
					className="carousel slide double-cards-carousel"
					data-ride="carousel"
					data-interval="false"
					data-wrap="false"
				>
					<ol className="carousel-indicators mb-3">
						{invoiceList.map((value, index) => (
							<li
								key={value.id}
								data-target="#carouselInvoiceControls"
								data-slide-to={index}
								onClick={() => setCount(index)}
								className={
									index === 0
										? "carousel-indicators-item active"
										: "carousel-indicators-item"
								}
							></li>
						))}
						<button
							className={`carousel-control-prev ${
								!disableCarousel.prev && "text-secondary"
							}`}
							type="button"
							data-target="#carouselInvoiceControls"
							data-slide="prev"
							onClick={() => setCount(count - 1)}
							disabled={disableCarousel.prev}
						>
							<i class="material-icons-outlined" aria-hidden="true">
								navigate_before
							</i>
						</button>
						<button
							className={`carousel-control-next ${
								!disableCarousel.next && "text-secondary"
							}`}
							type="button"
							data-target="#carouselInvoiceControls"
							data-slide="next"
							onClick={() => setCount(count + 1)}
							disabled={disableCarousel.next}
						>
							<i class="material-icons-outlined" aria-hidden="true">
								navigate_next
							</i>
						</button>
					</ol>

					<div className="carousel-inner p-0">
						{invoiceList.map((value, index) => {
							return (
								<div
									key={value?.Number}
									className={
										index === 0 ? "carousel-item active" : "carousel-item"
									}
								>
									<label className="m-0 p-0 pb-3">
										<strong>
											{i18n.t(`orderDetails.General.Invoice`)} {index + 1}
										</strong>
									</label>

									<div className="text-orders-carousel">
										<strong>
											{i18n.t("orderDetails.General.InvoiceValue")}:{" "}
										</strong>
										{value?.Amount?.toLocaleString("pt-br", {
											style: "currency",
											currency: "BRL",
										})}
									</div>

									<div className="text-orders-carousel">
										<strong>
											{i18n.t("orderDetails.General.InvoiceNumber")}:{" "}
										</strong>
										{value?.Number}
									</div>

									<div className="text-orders-carousel">
										<strong>
											{i18n.t("orderDetails.General.InvoiceSerialNumber")}:{" "}
										</strong>
										{value?.SerialNumber}
									</div>

									<div className="text-orders-carousel">
										<strong>
											{i18n.t("orderDetails.General.InvoiceKey")}:{" "}
										</strong>
										{value?.Key}
									</div>

									<div className="text-orders-carousel">
										<strong>
											{i18n.t("orderDetails.General.IssuanceDate")}:{" "}
										</strong>
										{new Date(value?.IssuanceDate).toLocaleString("pt-BR", {
											timeZone: "UTC",
										})}
									</div>
									{value?.FileUrl && (
										<div className="d-flex justify-content-center flex-column m-0 px-0 pt-2 pb-0">
											<label className="m-0 px-0 py-2">
												<strong>
													{i18n.t("orderDetails.General.InvoiceFile")}:{" "}
												</strong>
											</label>

											<div
												className="download-invoice m-0 p-0"
												style={{ border: "1px solid #BCBEC0" }}
											>
												<div className="download-invoice-label">
													<i className="material-icons-outlined">
														insert_drive_file
													</i>
													<span>
														&nbsp;{i18n.t("orderDetails.Invoicing.XmlFile")}
													</span>
												</div>
												<div className="download-invoice-icons">
													<a href={value?.FileUrl} target="_blank">
														<i className="material-icons-outlined">get_app</i>
													</a>
												</div>
											</div>
										</div>
									)}
								</div>
							);
						})}
					</div>
				</div>
			</>
		);
	};

	return (
		<div className="order-main-box">
			{loading ? (
				<Skeleton />
			) : (
				<>
					<InvoiceOrderModal id={id} />
					<div className="order-header-box">
						<h2>{i18n.t("orderDetails.General.Invoicing")}</h2>
					</div>

					<div className="order-body-box m-0 p-0">
						{order?.Invoices?.length <= 0 ? (
							<div className="order-body-box m-0 p-0">
								<label
									className="m-0"
									style={{ textAlign: "center", padding: "40px 0 40px 0" }}
								>
									<span>{i18n.t("orderDetails.Messages.NoInvoicing")}</span>
								</label>
							</div>
						) : invoiceList.length === 1 ? (
							showInvoice()
						) : (
							showListInvoices()
						)}
						{flowStatus?.enqueueOrderPosition <= 3 && (
							<div className="pt-3">{showButtonAddInvoice()}</div>
						)}
					</div>
				</>
			)}
		</div>
	);
}
