import BaseApi from "./BaseApi";
import { HttpMethod } from "../../domain/enums/HttpMethodType";

interface IInsightsLegacyApi {
	getExportOrders: (queryParams: string) => Promise<any>;
	getExportVouchers: (queryParams: string) => Promise<any>;
}

class InsightsLegacyApi extends BaseApi implements IInsightsLegacyApi {
	async getExportOrders(queryParams: string): Promise<any> {
		const resp = await this.request<any>(
			`orders?${queryParams}`,
			HttpMethod.Get
		);
		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error("Could not export orders");
		}
		return resp.data;
	}

	async getExportVouchers(queryParams: string): Promise<any> {
		const resp = await this.request<any>(
			`vouchers?${queryParams}`,
			HttpMethod.Get
		);
		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error("Could not export vouchers");
		}
		return resp.data;
	}
}

export const insightsLegacyApi: IInsightsLegacyApi = new InsightsLegacyApi(
	"api/export/",
	false,
	process.env.REACT_APP_INSIGHTSLEGACY
);
