import { ChangeEvent, useEffect, useState } from "react";
import { ReceivingMethodDto } from "../../../../../domain/dtos/MarketingManagement/ReceivingMethodDto";
import { marketingManagementApi } from "../../../../../infrastructure/api/MarketingManagementApi";
import NewToastComponent from "../../../../../components/NewToastComponent";
import { i18n } from "../../../../../translate/i18n";
import { ReceivingMethodConditionDto } from "../../../../../domain/dtos/MarketingManagement/ReceivingMethodConditionDto";
import { ReceivingMethodConditionInstallmentDto } from "../../../../../domain/dtos/MarketingManagement/ReceivingMethodConditionInstallmentDto";
import { PurchaseMode } from "../../../../../domain/enums/PurchaseMode";
import {
	FormErrors,
	FormValidations,
	InputValidator,
} from "../../../../../infrastructure/utils/FormUtils";
import {
	percentageDivision,
	percentageMultiplication,
} from "../../../../../infrastructure/utils/MathOperations";
import { percentageRulesCommercialConditions } from "../../utils/PercentageRulesCommercialConditions";

export type InstallmentView = {
	value: string;
	priceVariationPercentage: string;
};

export type ReceivingMethodView = Omit<
	ReceivingMethodConditionDto,
	"installments"
> & {
	installments: InstallmentView[];
} & Pick<ReceivingMethodDto, "paymentMethodType">;

export default function ReceivingMethodsService(conditionId: string) {
	const [receivingMethodList, setReceivingMethodList] = useState<
		ReceivingMethodView[]
	>([]);
	const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(true);
	const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const minimumPercentage = -100;
	const formValidationMapper: FormValidations = {
		priceVariationPercentage: (value: string) =>
			InputValidator.isPercentageValid(value, minimumPercentage),
	};

	useEffect(() => {
		prepareReceivingMethodsList();
	}, []);

	const changeInstallmentQuantity = (
		inputValue: string,
		receivingMethodId: string
	): void => {
		let quantity = parseInt(inputValue) || 1;
		const newReceivingMethods = receivingMethodList.map((method) => {
			if (method.receivingMethodId === receivingMethodId) {
				const newInstallments: InstallmentView[] = [...Array(quantity)].map(
					(_, index) => {
						if (method.installments[index]) return method.installments[index];
						return { value: `${index + 1}`, priceVariationPercentage: "0" };
					}
				);
				method.installments = newInstallments;
			}
			return method;
		});
		setReceivingMethodList(newReceivingMethods);
	};

	const changeReceivingMethod = (
		e: ChangeEvent<HTMLInputElement>,
		receivingMethodId: string,
		installmentValue: string
	): void => {
		const { name, value } = e.target;

		let formattedValue = percentageRulesCommercialConditions(value);

		const errorMessage: string[] =
			formValidationMapper.priceVariationPercentage(formattedValue);
		setFormErrors((prevState) => ({ ...prevState, [name]: errorMessage }));

		const newReceivingMethods = receivingMethodList.map((method) => {
			if (method.receivingMethodId === receivingMethodId) {
				const newInstallments = method.installments.map((installment) => {
					if (installment.value === installmentValue) {
						installment.priceVariationPercentage = formattedValue;
					}
					return installment;
				});
				method.installments = newInstallments;
			}
			return method;
		});
		setReceivingMethodList(newReceivingMethods);
	};

	const handleSubmit = () => {
		setIsLoadingSubmit(true);
		const data: ReceivingMethodConditionDto[] = receivingMethodList.map(
			(receivingMethodView) => {
				const newReceivingMethodDto: ReceivingMethodConditionDto = {
					commercialConditionId: receivingMethodView.commercialConditionId,
					receivingMethodId: receivingMethodView.receivingMethodId,
					installments: mapViewInstallmentsToDtoInstallments(
						receivingMethodView.installments
					),
				};
				return newReceivingMethodDto;
			}
		);
		modifyReceivingMethodInBatch(data);
	};

	const createDefaultReceivingMethodView = (
		commercialConditionId: string,
		receivingMethod: ReceivingMethodDto
	): ReceivingMethodView => ({
		commercialConditionId,
		receivingMethodId: receivingMethod.id,
		installments: [{ value: "1", priceVariationPercentage: "0" }],
		paymentMethodType: receivingMethod.paymentMethodType,
	});

	const mapDtoInstallmentsToViewInstallments = (
		installmentsDto: ReceivingMethodConditionInstallmentDto[]
	): InstallmentView[] => {
		const mappedInstallmentView = installmentsDto.map((installment) => ({
			value: installment.value.toString(),
			priceVariationPercentage: percentageMultiplication(
				installment.priceVariationPercentage.toString()
			),
		}));
		return mappedInstallmentView;
	};
	const mapViewInstallmentsToDtoInstallments = (
		installmentsView: InstallmentView[]
	): ReceivingMethodConditionInstallmentDto[] => {
		const mappedInstallmentsDto = installmentsView.map((installment) => ({
			value: parseFloat(installment.value) ?? 0,
			priceVariationPercentage:
				parseFloat(percentageDivision(installment.priceVariationPercentage)) ??
				0,
		}));
		return mappedInstallmentsDto;
	};

	const prepareReceivingMethodsList = async (): Promise<void> => {
		let receivingMethods: ReceivingMethodDto[] = await getReceivingMethods();
		const receivingMethodConditions: ReceivingMethodConditionDto[] =
			await getReceivingMethodConditionsByCommercialConditionId(conditionId);

		receivingMethods = receivingMethods.filter(
			(method) => method.purchaseMode === PurchaseMode.ReceiveAtHome
		);

		const newReceivingMethodsList: ReceivingMethodView[] = receivingMethods.map(
			(receivingMethod) => {
				const foundReceivingMethodCondition:
					| ReceivingMethodConditionDto
					| undefined = receivingMethodConditions.find(
					(receivingMethodCondition) =>
						receivingMethodCondition.receivingMethodId === receivingMethod.id
				);

				if (foundReceivingMethodCondition) {
					const newReceivingMethodView: ReceivingMethodView = {
						...foundReceivingMethodCondition,
						installments: mapDtoInstallmentsToViewInstallments(
							foundReceivingMethodCondition.installments
						),
						paymentMethodType: receivingMethod.paymentMethodType,
					};
					return newReceivingMethodView;
				}
				return createDefaultReceivingMethodView(conditionId, receivingMethod);
			}
		);

		setReceivingMethodList(newReceivingMethodsList);
		setIsLoadingScreen(false);
	};

	const getReceivingMethods = async (): Promise<ReceivingMethodDto[]> => {
		try {
			const data: ReceivingMethodDto[] =
				await marketingManagementApi.getReceivingMethods();
			return data;
		} catch {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.ERR.REQUEST"),
			});
		}
		return [];
	};

	const getReceivingMethodConditionsByCommercialConditionId = async (
		conditionId: string
	): Promise<ReceivingMethodConditionDto[]> => {
		try {
			const data: ReceivingMethodConditionDto[] =
				await marketingManagementApi.getReceivingMethodConditionsByCommercialConditionId(
					conditionId
				);
			return data;
		} catch {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.ERR.REQUEST"),
			});
		}
		return [];
	};

	const modifyReceivingMethodInBatch = async (
		data: ReceivingMethodConditionDto[]
	): Promise<void> => {
		const success = await marketingManagementApi.modifyReceivingMethodInBatch(
			data
		);
		setIsLoadingSubmit(false);
		if (!success) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.ERR.REQUEST"),
			});
			return;
		}
		NewToastComponent({
			status: "success",
			title: i18n.t("paymentConfig.PaymentMethodsSavedSuccessfully"),
		});
	};

	return {
		receivingMethodList,
		isLoadingScreen,
		isLoadingSubmit,
		formErrors,
		changeInstallmentQuantity,
		changeReceivingMethod,
		handleSubmit,
	};
}
