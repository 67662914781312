import React from "react";
import { Link } from "react-router-dom";

export type SidebarItem = {
	title: string;
	icon?: JSX.Element;
	link: string;
	isActive: boolean;
};

type Props = { items: SidebarItem[] };

export default function Sidebar({ items }: Props) {
	return (
		<div className="card sidebar">
			<ul style={{ margin: "16px 0" }}>
				{items.map((item) => (
					<li key={item.title} className={item.isActive ? "active" : undefined}>
						<Link to={item.link}>
							{item?.icon}
							{item.title}
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
}
