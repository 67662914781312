import React from "react";
import MatcherImportList from "./components/MatcherImportList";
import IntegrationTabs from "./components/IntegrationsTabs";
import "./importMatcher.scss";
import MatcherContext from "./providers/MatcherContext";
import MatcherUploadList from "./components/MatcherUploadList";
import { Link } from "react-router-dom";

// Matcher screen is currently not in use
function Matcher() {
	return (
		<MatcherContext>
			<div className="container dash-content">
				<div class="card">
					<div className="card-header pb-2">
						<h4 className="card-title">Mapeamento de Catálogo</h4>
					</div>
					<div className="card-content">
						<div class="card-body">
							<div className="row">
								<div className="col-12">
									<p>
										A área de Mapeamento de Catálogo permite com que você
										integre facilmente o seu Catálogo da Lori com o catálogo do
										Marketplace com o qual está sendo realizada a integração.
										Abaixo, seguem as opções possíveis de mapeamento para seu
										catálogo.
									</p>
									<br />
									<p>
										Para realizar o mapeamento, primeiramente baixe a planilha
										base da opção desejada (categoria, atributo ou marca),
										realize o mapeamento seguindo estas instruções e, em
										seguida, envie a planilha mapeada para combinar corretamente
										os catálogos de ambas as plataformas.
									</p>
								</div>
								<div className="col-12">
									<IntegrationTabs />
								</div>
								<MatcherUploadList />
								<div className="col-12">
									<h4 className="card-title mt-5 import-status-titles">
										Status do Mapeamento
									</h4>
									<p>
										Acompanhe o status dos seus mapeamentos abaixo. Clique aqui
										para ver o seu{" "}
										<Link
											style={{ textDecorationLine: "underline" }}
											to={"matcher/history/1"}
										>
											Histórico de Mapeamentos
										</Link>
									</p>
									<MatcherImportList />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</MatcherContext>
	);
}

export default Matcher;
