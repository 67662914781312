import React from "react";
import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from "@mui/material";
import { ErrorOutline, KeyboardArrowDownRounded } from "@mui/icons-material";
import HelpTooltip, { HelpTooltipConfig } from "./HelpTooltip";

type Option = {
	label: string;
	value: any;
};

export type SelectOptions = Option[];

type HelpTooltip = {
	title: string;
};

type SelectProps = {
	id: string;
	label?: string;
	value: any;
	isDisabled?: boolean;
	options: Option[];
	onChange: (event: SelectChangeEvent) => void;
	error?: boolean;
	helperText?: string[];
	customWidth?: string;
	helpTooltip?: HelpTooltipConfig;
};

export default function CustomSelect({
	id,
	label,
	value,
	isDisabled = false,
	options,
	onChange,
	error = false,
	helperText = [],
	customWidth,
	helpTooltip,
}: SelectProps) {
	return (
		<FormControl
			size="small"
			fullWidth
			sx={{ ...(customWidth && { width: customWidth }) }}
		>
			{label && (
				<InputLabel id={`${id}-label`} color="secondary" error={error}>
					{label}
					{helpTooltip && <HelpTooltip helpTooltip={helpTooltip} />}
				</InputLabel>
			)}
			<Select
				labelId={`${id}-label`}
				id={id}
				name={id}
				value={value}
				label={label}
				disabled={isDisabled}
				onChange={onChange}
				IconComponent={KeyboardArrowDownRounded}
				color="secondary"
				error={error}
				endAdornment={
					error && (
						<ErrorOutline
							color="error"
							sx={{ position: "absolute", right: "30px" }}
						/>
					)
				}
			>
				{options.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
			<FormHelperText error={error}>{helperText}</FormHelperText>
		</FormControl>
	);
}
