import { ChangeEvent, useEffect, useState } from "react";
import NewToastComponent from "../../../../components/NewToastComponent";
import { SellerView } from "../../../../domain/views/stores/SellerView";
import { SellerPaymentView } from "../../../../domain/views/stores/SellerPaymentView";

export default function usePaymentService(
	sellerData: SellerView,
	updatePaymentData: (
		sellerId: string,
		sellerData: SellerView,
		paymentData: SellerPaymentView
	) => Promise<boolean>
) {
	const [loading, setLoading] = useState<boolean>(true);
	const [sellerPayment, setSellerPayment] = useState<SellerPaymentView>({
		gatewayId: "",
		credentialsString: "",
	});

	useEffect(() => {
		let { gatewayId, credentialsString } = sellerData;

		setSellerPayment({
			gatewayId: gatewayId,
			credentialsString: credentialsString,
		});

		setTimeout(() => {
			setLoading(false);
		}, 500);
	}, [sellerData]);

	const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
		const { name, value }: { name: string; value: string } = e.target;
		setSellerPayment((prevState: SellerPaymentView) => ({
			...prevState,
			[name]: value,
		}));
	};

	const updateSeller = async (
		sellerId: string,
		sellerData: SellerView,
		sellerPayment: SellerPaymentView
	): Promise<void> => {
		setLoading(true);

		let success = await updatePaymentData(sellerId, sellerData, sellerPayment);

		if (!success) {
			NewToastComponent({
				status: "error",
				title: "Parece que tivemos um erro...",
				message: "Tente novamente mais tarde.",
			});
			setLoading(false);
			return;
		}

		NewToastComponent({
			status: "success",
			title: "Configuração do pagamento editado com sucesso!",
		});
		setLoading(false);
	};

	return {
		//Props
		loading,
		sellerPayment,
		// Functions
		onInputChange,
		updateSeller,
	};
}
