export enum RolesAccess {
	Admin = "1", //NeomodeAdmin
	Retailer = "2", //Varejista
	Seller = "3", //Lojista
	Marketing = "4", //Marketing
	SalesManagement = "5", //Gestão de vendas (somente leitura)
	SalesManagementComplete = "6", //Gestão de vendas (acesso completo)
	Marketplace = "7", //Marketplace
	MarketplaceRetailer = "8", //Varejista de shopping
}
