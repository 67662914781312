import React from "react";
import { useOrders } from "./providers/OrdersContext";
import HistorySteps from "./components/OrderHistory/HistorySteps";

export default function OrderHistory() {
	const { actionLogs } = useOrders();

	return (
		<div className="detail-box">
			<div className="card box-two mb-3">
				<div className="container dash-content">
					<div className="card-header ">
						<h4 className="card-title">HISTÓRICO DO PEDIDO</h4>
					</div>
					<div className="card-content mb-3">
						<div className="card-body">
							<HistorySteps actionLogList={actionLogs} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
