import React, { useEffect } from "react";
import VouchersList from "./VouchersList";
import VouchersContext from "./providers/VouchersContext";
import { useConfig } from "./../../../../providers/ConfigProvider";
import LoadDashboard from "../../../Dashboard/components/LoadDashboard";

export default function Vouchers(props) {
	const { mainLoading } = useConfig();
	useEffect(() => {
		localStorage.removeItem("PARAMS");
		if (!props.match.params.page) {
			console.error(props.match.params.page);
			window.location.href = `/vouchers/1`;
		}
	}, [props.match.params]);

	return (
		<>
			{mainLoading ? (
				<LoadDashboard />
			) : (
				<VouchersContext>
					<VouchersList page={parseInt(props.match.params.page)} />
				</VouchersContext>
			)}
		</>
	);
}
