import styled from "styled-components";

export const Button = styled.button<{ color?: string }>`
	width: 100%;
	height: 100%;
	background: ${({ color }) => color};
	border-radius: 1px;
	cursor: pointer;
	border: none;
	outline: none;
	z-index: 1;

	&:focus {
		outline: none;
	}
`;
