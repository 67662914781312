import React, { useState } from "react";

export default function Payments({ newCondition }) {
	const [payments, setPayments] = useState([]);
	const paymentOptions = [
		{ paymentType: 1, paymentText: "Cartão de crédito (via APP)" },
		{ paymentType: 2, paymentText: "Na retirada" },
		{ paymentType: 3, paymentText: "Boleto (via APP)" },
		{ paymentType: 6, paymentText: "PIX" },
		{ paymentType: 10, paymentText: "Crédito - Maquininha" },
		{ paymentType: 11, paymentText: "Débito - Maquininha" },
	];

	const onChangePayment = (e) => {
		const payment = { type: e.target.value };
		let listData = payments.findIndex((val) => val.type === payment.type);

		if (listData < 0) {
			setPayments([...payments, payment]);
		} else {
			setPayments((currentPayments) =>
				currentPayments.filter((val) => val.type !== payment.type)
			);
		}
	};

	const savePayment = () => {
		const item = {
			value: payments,
		};
		if (payments.length > 1) {
			var text = " Selecionados";
		} else {
			var text = " Selecionado";
		}
		const itemView = {
			name: "Meios de pagamento",
			value: payments.length + text,
			itemId: "paymentMethods",
			modal: "#conditionModalPaymentMethods",
		};
		newCondition(item, itemView);
	};

	const paymentCheckbox = (paymentType, text) => {
		return (
			<div
				key={paymentType}
				className="custom-control custom-checkbox my-1 mr-sm-2"
			>
				<input
					type="checkbox"
					className="custom-control-input"
					id={`paymentMethod${paymentType}`}
					value={paymentType}
					onChange={onChangePayment}
				/>
				<label
					className="custom-control-label"
					htmlFor={`paymentMethod${paymentType}`}
				>
					{text}
				</label>
			</div>
		);
	};

	return (
		<div
			class="modal fade"
			id="conditionModalPaymentMethods"
			role="dialog"
			aria-labelledby="conditionModalPaymentMethods"
			aria-hidden="true"
		>
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title">Meio de pagamentos</h3>
						<button
							type="button"
							className="close text-danger"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						{paymentOptions.map((payment) =>
							paymentCheckbox(payment.paymentType, payment.paymentText)
						)}
					</div>

					<div
						className="modal-footer text-center"
						style={{ justifyContent: "center" }}
					>
						<button
							type="button"
							className="btn btn-light col-5"
							data-dismiss="modal"
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-success col-5"
							onClick={savePayment}
							data-dismiss="modal"
						>
							Pronto
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
