import React from "react";
import ContentLoader from "react-content-loader";

export default function AddressSkeleton(): JSX.Element {
	return (
		<>
			<ContentLoader
				speed={2}
				width={740}
				height={80}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 80"
				style={{ width: "100%" }}
			>
				<rect x="16" y="25" rx="3" ry="3" width="500" height="60" />
				<rect x="530" y="25" rx="3" ry="3" width="500" height="80" />
			</ContentLoader>
			<ContentLoader
				speed={2}
				width={740}
				height={80}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 80"
				style={{ width: "100%" }}
			>
				<rect x="16" y="25" rx="3" ry="3" width="330" height="60" />
				<rect x="360" y="25" rx="3" ry="3" width="330" height="80" />
				<rect x="710" y="25" rx="3" ry="3" width="330" height="80" />
			</ContentLoader>
			<ContentLoader
				speed={2}
				width={740}
				height={80}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 80"
				style={{ width: "100%" }}
			>
				<rect x="16" y="25" rx="3" ry="3" width="500" height="60" />
				<rect x="530" y="25" rx="3" ry="3" width="500" height="80" />
			</ContentLoader>
		</>
	);
}
