import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 60px 24px 60px 24px;
`;

export const Link = styled.a`
	margin-top: 24px;
	font-size: 14px;
	font-weight: 600;
	color: #6d4aed;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
		color: #6d4aed !important;
	}
`;
