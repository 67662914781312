import { UIEvent, useContext, useEffect, useState } from "react";
import { CategoryContext } from "../../context/CategoryContext";
import { CategoryView } from "../../../../domain/views/categories/CategoryView";
import { isValidString } from "../../../../infrastructure/utils/TypeValidator";
import { CategoryDto } from "../../../../domain/dtos/categories/CategoryDto";
import { ScrollHandler } from "../../../../components/common/ScrollHandler";

export default function CategoryListService() {
	const {
		//Props
		isDisabled,
		categories,
		isReorderMode,
		setIsReorderMode,
		isUpsertMode,
		setIsUpsertMode,
		isLoadingList,
		setDropdownIsOpen,
		//Functions
		upsertCategory,
		updateReorderedCategories,
		handleCategoryOrderingCancellation,
		//Hooks
		unsavedChanges,
		disableUnsavedChanges,
		promptMessage,
	} = useContext(CategoryContext);

	const [currentPosition, setCurrentPosition] = useState<number>(16);

	const [showContentBottom, setShowContentBottom] = useState<boolean>(false);
	const [visibleCategories, setVisibleCategories] = useState<CategoryView[]>(
		[]
	);
	const [queryValue, setQueryValue] = useState<string>("");
	const [showSearch, setShowSearch] = useState<boolean>(false);
	const [filteredCategories, setFilteredCategories] = useState<CategoryDto[]>(
		[]
	);
	const [isScrolling, setIsScrolling] = useState<boolean>(false);
	const [showFilteredList, setShowFilteredList] = useState<boolean>(false);

	const hasBottomContent: boolean = currentPosition < categories.length;

	const categoryLimit: number = 16;

	useEffect((): void => {
		setVisibleCategories(categories.slice(0, currentPosition));
	}, [categories]);

	const sortCategories = (categories: CategoryView[]): CategoryView[] => {
		categories.sort((a: CategoryView, b: CategoryView) => a.order - b.order);

		for (const category of categories) {
			if (category.children && category.children.length > 0) {
				category.children = sortCategories(category.children);
			}
		}

		return categories;
	};

	const handleScroll = (event: UIEvent<HTMLDivElement>): void => {
		if (showFilteredList) return;

		ScrollHandler(event, {
			hasBottomContent,
			isScrolling,
			setIsScrolling,
			setShowContentBottom,
			scrollDown,
		});
	};

	const scrollDown = (): void => {
		if (hasBottomContent) {
			const endIndex = Math.min(
				currentPosition + categoryLimit,
				categories.length
			);

			setVisibleCategories((prevArray) => {
				const updatedCategories = prevArray.concat(
					categories.slice(currentPosition, endIndex)
				);
				return updatedCategories;
			});

			setCurrentPosition(endIndex);
		}
	};

	const handleQueryChange = (queryValue: string): void => {
		setShowFilteredList(true);

		setQueryValue(queryValue);

		setShowContentBottom(false);

		if (!isValidString(queryValue)) {
			setFilteredCategories([]);

			setShowSearch(false);

			setShowFilteredList(false);

			return;
		}

		const categoryName = queryValue.toLowerCase();
		const newCategories = [];

		for (const category of categories) {
			const categoryId = category.name.toLowerCase();

			if (categoryId.includes(categoryName)) {
				newCategories.push(category);
			} else {
				const matchingChildren = (category.children || []).filter((child) => {
					return child.name.toLowerCase().includes(categoryName);
				});

				if (matchingChildren.length > 0) {
					newCategories.push({ ...category, children: matchingChildren });
				}
			}
		}

		setFilteredCategories(newCategories);
	};

	return {
		// Props
		categories,
		queryValue,
		showSearch,
		setShowSearch,
		filteredCategories,
		visibleCategories,
		isLoadingList,
		showContentBottom,
		showFilteredList,
		isReorderMode,
		setIsReorderMode,
		isUpsertMode,
		setIsUpsertMode,
		isDisabled,
		setDropdownIsOpen,
		//Hooks
		unsavedChanges,
		promptMessage,
		disableUnsavedChanges,
		// Functions
		handleScroll,
		handleQueryChange,
		upsertCategory,
		sortCategories,
		updateReorderedCategories,
		handleCategoryOrderingCancellation,
	};
}
