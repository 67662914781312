import React, { useState, useEffect } from "react";
import { API, APIV2 } from "../../../../../API";
import Products from "../../../../../components/Products";
import DatePickerNeo from "../../../../../components/DatePickerNeo";
import Modal from "../../../../../components/Modal";
import SelectMechanic from "./SelectMechanic";
import { Multiselect } from "react-widgets";
import "react-widgets/dist/css/react-widgets.css";
import { usePromotion } from "../Providers/PromotionContext";
import Button from "../../../../../components/Button";
import ContentLoader from "react-content-loader";
import { v4 as uuidv4 } from "uuid";
import MechanicsPromotions from "./MechanicsPromotions";
import CategoriesCheckbox from "../../../../../components/modals/CategoriesCheckbox/CategoriesCheckbox";

export default function RulesPromotions(props) {
	const {
		createPromotion,
		loading,
		setLoading,
		disable,
		setDisable,
		rules,
		setRules,
	} = usePromotion();
	const [promotion, setPromotion] = useState({
		id: "",
		startDate: new Date(),
		endDate: new Date(),
		isActive: false,
		name: "",
		typeList: 0,
		canSumDiscount: false,
	});

	const [edit, setEdit] = useState(false);
	const [products, setProducts] = useState([]);
	const [collections, setCollections] = useState([]);
	const [collectionList, setCollectionList] = useState([]);
	const [categories, setCategories] = useState([]);
	const [load, setLoad] = useState(false);
	const [collectionEdited, setCollectionEdited] = useState(false);
	const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false);

	useEffect(() => {
		if (props.id) {
			getPromotions();
		} else {
			setLoading(false);
		}
		getTagList();
		setTimeout(() => {
			setLoading(false);
			setDisable(false);
		}, 2000);
	}, []);

	const getTagList = async () => {
		await API.get(`api/tags`)
			.then(async (response) => {
				setCollectionList(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getProductList = (e) => {
		const body = [];
		for (let i = 0; i < e.length; i++) {
			body.push(e[i].code);
		}
		setProducts(body);
	};

	const getCategoryList = (categoryIds) => {
		if (categoryIds.length === 0) return setCategories([]);

		if (categoryIds.includes("")) return setCategories([...categories]);

		setCategories(categoryIds);
	};

	const getPromotions = () => {
		setLoading(false);
		if (props.id) {
			setLoading(true);
			setLoad(true);
			setEdit(true);
			APIV2.get(`management/marketing/promotions/${props.id}`)
				.then(async (response) => {
					setPromotion((oldPromotion) => ({
						...oldPromotion,
						id: response.data.id,
						startDate: new Date(response.data.startDate),
						endDate: new Date(response.data.endDate),
						typeList: response.data.typeList,
						isActive: response.data.isActive,
						name: response.data.name,
					}));
					response.data.typeList === 1 &&
						setProducts(response.data.genericListCodes);
					response.data.typeList === 2 &&
						setCollections(response.data.genericListCodes);
					response.data.typeList === 3 &&
						setCategories(response.data.genericListCodes);
					getRules(response.data.mechanics);
				})
				.catch((error) => {
					props.id && console.log(error);
				});
		}
	};

	const getRules = (mechanicList) => {
		mechanicList.forEach((data, index) => {
			const rule = {
				...data,
				tempId: data.id,
				order: index,
				discountValue: parseFloat(data.discountValue),
				maxValueItem: parseFloat(data.maxValueItem),
				labelCartItemQuantity: !data.cartItemQuantity ? "" : "cartItemQuantity",
				labelQuantityThatWillReceiveDiscount:
					!data.quantityThatWillReceiveDiscount
						? ""
						: "quantityThatWillReceiveDiscount",
				labelApplyFromCartItemsQuantity: !data.applyFromCartItemsQuantity
					? ""
					: "applyFromCartItemsQuantity",
				labelDiscountPercentage: !data.discountPercentage
					? ""
					: "discountPercentage",
				labelDiscountValue: !data.discountValue ? "" : "discountValue",
				labelMaxValueItem: !data.maxValueItem ? "" : "maxValueItem",
				label:
					data.mechanicType === "PromotionMechanicProgressiveDiscountPercent"
						? "Desconto Progressivo %"
						: data.mechanicType === "PromotionMechanicProgressiveDiscountValue"
						? "Desconto Progressivo valor"
						: data.mechanicType ===
						  "PromotionMechanicBuyMoreForLessDiscountPercent"
						? "Leve mais por menos %"
						: data.mechanicType === "PromotionMechanicBuyMoreForLessMaxValue"
						? "Leve mais por menos - valor"
						: "",
			};

			setRules((prevState) => [...prevState, rule]);
		});
		setLoading(false);
		setTimeout(() => {
			setLoad(false);
		}, 2000);
	};

	const selectMech = (e) => {
		const order = rules.length;

		switch (e.type) {
			case "PromotionMechanicProgressiveDiscountPercent":
				var rule = {
					tempId: uuidv4(),
					order: order,
					label: "Desconto Progressivo %",
					mechanicType: e.type,
					cartItemQuantity: "",
					applyFromCartItemsQuantity: "",
					discountPercentage: "",
					labelCartItemQuantity: "cartItemQuantity",
					labelApplyFromCartItemsQuantity: "applyFromCartItemsQuantity",
					labelDiscountPercentage: "discountPercentage",
				};
				break;
			case "PromotionMechanicProgressiveDiscountValue":
				var rule = {
					tempId: uuidv4(),
					order: order,
					label: "Desconto Progressivo valor",
					mechanicType: e.type,
					cartItemQuantity: "",
					applyFromCartItemsQuantity: "",
					discountValue: "",
					labelCartItemQuantity: "cartItemQuantity",
					labelApplyFromCartItemsQuantity: "applyFromCartItemsQuantity",
					labelDiscountValue: "discountValue",
				};
				break;
			case "PromotionMechanicBuyMoreForLessDiscountPercent":
				var rule = {
					tempId: uuidv4(),
					order: order,
					label: "Leve mais por menos %",
					mechanicType: e.type,
					cartItemQuantity: "",
					quantityThatWillReceiveDiscount: "",
					discountPercentage: "",
					labelCartItemQuantity: "cartItemQuantity",
					labelQuantityThatWillReceiveDiscount:
						"quantityThatWillReceiveDiscount",
					labelDiscountPercentage: "discountPercentage",
				};
				break;
			case "PromotionMechanicBuyMoreForLessMaxValue":
				var rule = {
					tempId: uuidv4(),
					order: order,
					label: "Leve mais por menos - valor",
					mechanicType: e.type,
					cartItemQuantity: "",
					quantityThatWillReceiveDiscount: "",
					maxValueItem: "",
					labelCartItemQuantity: "cartItemQuantity",
					labelQuantityThatWillReceiveDiscount:
						"quantityThatWillReceiveDiscount",
					labelMaxValueItem: "maxValueItem",
				};
				break;
			default:
		}
		setRules((prevState) => [...prevState, rule]);
	};

	const handleCreate = (e) => {
		setLoading(true);
		const newRules = [];
		rules.map((rule) => {
			const newRule = {
				mechanicType: rule.mechanicType,
				cartItemQuantity: rule.cartItemQuantity ? rule.cartItemQuantity : "",
				quantityThatWillReceiveDiscount: rule.quantityThatWillReceiveDiscount
					? rule.quantityThatWillReceiveDiscount
					: 0,
				applyFromCartItemsQuantity: rule.applyFromCartItemsQuantity
					? rule.applyFromCartItemsQuantity
					: false,
				discountValue: rule.discountValue ? rule.discountValue : "",
				discountPercentage: rule.discountPercentage
					? rule.discountPercentage
					: "",
				maxValueItem: rule.maxValueItem ? rule.maxValueItem : "",
			};
			newRules.push(newRule);
		});
		e.preventDefault();
		setLoading(true);
		setDisable(true);
		const collectionList = [];
		if (promotion.typeList === 2) {
			if (edit === true && collectionEdited === false) {
				collectionList.push(...collections);
			} else {
				collections.forEach((collection, i) => {
					collectionList.push(collection.url);
				});
			}
		}
		if (edit) {
			var body = {
				id: promotion.id,
				name: promotion.name,
				description: "",
				startDate: promotion.startDate,
				endDate: promotion.endDate,
				canSumDiscount: promotion.canSumDiscount,
				genericListCodes:
					promotion.typeList === 1
						? products
						: promotion.typeList === 2
						? collectionList
						: promotion.typeList === 3
						? categories
						: [],
				isDeleted: false,
				typeList: promotion.typeList,
				isActive: promotion.isActive,
				mechanics: newRules,
			};
			createPromotion(body);
		} else {
			var body = {
				name: promotion.name,
				description: "",
				startDate: promotion.startDate,
				endDate: promotion.endDate,
				canSumDiscount: promotion.canSumDiscount,
				genericListCodes:
					promotion.typeList === 1
						? products
						: promotion.typeList === 2
						? collectionList
						: promotion.typeList === 3
						? categories
						: [],
				isDeleted: false,
				typeList: promotion.typeList,
				isActive: promotion.isActive,
				mechanics: newRules,
			};
			createPromotion(body);
			setRules([]);
			setPromotion({
				name: "",
				startDate: new Date(),
				endDate: new Date(),
				isActive: false,
				typeList: 0,
				canSumDiscount: false,
			});
		}
	};

	const renderScreen = () => {
		return (
			<div className="row" style={{ borderLeft: "3px solid #E9ECEC" }}>
				<section className="section-step mb-2 pb-2">
					<span className="number">1</span>
					<h3>Informações Básicas</h3>
					{loading ? (
						skeleton()
					) : (
						<>
							<div className="row ml-2 mt-3">
								<div className="form-group col-6">
									<label htmlFor="codigoCupom">
										<strong>Título da promoção</strong>
									</label>
									<div>
										<input
											type="text"
											className="form-control"
											id="codigoCupom"
											placeholder="ABC123456"
											name="name"
											value={promotion.name}
											onChange={(e) =>
												setPromotion({ ...promotion, name: e.target.value })
											}
										/>
									</div>
								</div>
								<div className="form-group col-sm-1">
									<label htmlFor="Status">
										<strong>Status</strong>
									</label>
									<br />
									<label className="switch ">
										<input
											id="Status"
											type="checkbox"
											name="isActive"
											checked={promotion.isActive}
											onChange={(e) =>
												setPromotion({
													...promotion,
													isActive: e.target.checked,
												})
											}
										/>
										<span className="slider round"></span>
									</label>
								</div>
								<div className="row ml-3">
									<div className="form-group d-flex flex-wrap">
										<label htmlFor="codigoCupom" className="w-100">
											<strong>Vigência</strong>
										</label>
										<div className="mr-4">
											<DatePickerNeo
												label="Data e hora de início"
												dateFormat="dd/MM/yyyy HH:mm"
												time={true}
												value={promotion.startDate}
												selected={
													!promotion.startDate
														? new Date()
														: promotion.startDate
												}
												onChange={(date) =>
													setPromotion({ ...promotion, startDate: date })
												}
												minDate={new Date()}
											/>
										</div>
										<div className="">
											<DatePickerNeo
												label="Data e hora de término"
												dateFormat="dd/MM/yyyy HH:mm"
												time={true}
												value={promotion.endDate}
												selected={
													promotion.endDate < promotion.startDate
														? promotion.startDate
														: !promotion.startDate
														? new Date()
														: promotion.endDate
												}
												onChange={(date) =>
													setPromotion({ ...promotion, endDate: date })
												}
												minDate={new Date()}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="form-gorup row ml-2 mt-3">
								<label className="col-12" htmlFor="limiteUso">
									<strong>Produtos que participam da promoção</strong>
								</label>
								<div className="input-group col-sm-5">
									{load ? (
										productsSkeleton()
									) : (
										<>
											<div className="input-group-prepend">
												<label htmlFor="inputGroupSelect01">
													<select
														className="custom-select btn-light"
														id="inputGroupSelect01"
														value={promotion.typeList}
														onChange={(e) =>
															setPromotion((prevState) => ({
																...prevState,
																typeList: parseInt(e.target.value),
															}))
														}
													>
														<option value={0}>Todos</option>
														<option value={1}>Produtos</option>
														<option value={2}>Coleções</option>
														<option value={3}>Categorias</option>
													</select>
												</label>
											</div>
											{promotion.typeList === 1 ? (
												<input
													type="text"
													className="form-control"
													value={products.length + " Produtos"}
													data-toggle="modal"
													data-target="#selectProduct"
												/>
											) : promotion.typeList === 2 ? (
												<Multiselect
													data={collectionList}
													value={collections}
													valueField="url"
													className="form-control"
													textField={(item) => (item ? item.name : "")}
													allowCreate="onFilter"
													onCreate={(name) => handleCreate(name)}
													onChange={(value) => {
														setCollectionEdited(true);
														setCollections(value);
													}}
													busy={collectionList.length >= 0 ? false : true}
												/>
											) : promotion.typeList === 3 ? (
												<>
													<input
														type="text"
														className="form-control"
														value={categories.length + " Categorias"}
														onClick={() => setIsOpenCategoryModal(true)}
													/>
												</>
											) : (
												<input
													type="text"
													className="form-control"
													value={"Todos os produtos da base"}
												/>
											)}
										</>
									)}
								</div>
							</div>
						</>
					)}
				</section>
				<section className="section-step">
					<span className="number">2</span>
					<h3>Regras da promoção</h3>
					{loading ? (
						rulesSkeleton()
					) : (
						<>
							{rules.length <= 0 ? (
								<div className="form-group col-12 mt-5">
									<p>Nenhuma regra de promoção selecionada</p>
								</div>
							) : (
								<MechanicsPromotions />
							)}
							<Button
								className="btn btn-link btn-icon-c-l mt-2"
								type="button"
								dataToggle="modal"
								dataTarget="#selectMech"
							>
								<i className="material-icons">add_circle_outline</i>Adicionar
								nova regra
							</Button>
							<SelectMechanic select={selectMech} />
						</>
					)}
				</section>

				<div className="form-group col-12">
					<Button
						className="btn btn-success"
						loading={disable}
						disable={disable}
						onClick={handleCreate}
					>
						Salvar
					</Button>
				</div>

				{!loading && (
					<>
						<Modal
							id={"selectProduct"}
							title={"Produtos"}
							large={true}
							content={
								<Products
									getProducts={getProductList}
									productsPromo={products}
								/>
							}
						/>

						<CategoriesCheckbox
							isOpenCategoryModal={isOpenCategoryModal}
							setIsOpenCategoryModal={setIsOpenCategoryModal}
							selectedValues={categories}
							handleFunction={getCategoryList}
							isMultiSelect={true}
						/>
					</>
				)}
			</div>
		);
	};

	const skeleton = () => {
		return (
			<>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", marginBottom: "35px" }}
				>
					<rect x="0" y="25" rx="3" ry="3" width="485" height="40" />
					<rect x="515" y="25" rx="3" ry="3" width="40" height="24" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", marginBottom: "35px" }}
				>
					<rect x="0" y="25" rx="3" ry="3" width="180" height="40" />
					<rect x="220" y="25" rx="3" ry="3" width="180" height="40" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", marginBottom: "35px" }}
				>
					<rect x="0" y="25" rx="3" ry="3" width="335" height="40" />
				</ContentLoader>
			</>
		);
	};

	const productsSkeleton = () => {
		return (
			<>
				<ContentLoader
					speed={2}
					width={1100}
					height={100}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 100"
					style={{ width: "100%" }}
				>
					<rect x="0" y="0" rx="0" ry="3" width="1000" height="100" />
				</ContentLoader>
			</>
		);
	};

	const rulesSkeleton = () => {
		return (
			<>
				<ContentLoader
					speed={2}
					width={1100}
					height={280}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 270"
					style={{ width: "100%" }}
				>
					<rect x="0" y="25" rx="3" ry="3" width="595" height="250" />
				</ContentLoader>
			</>
		);
	};

	return <>{renderScreen()}</>;
}
