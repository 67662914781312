import { API } from "./API";

export default {
	setFilterProductList(text, req) {
		return { type: "SET_FILTER_PRODUCT", payload: { text: text, req: req } };
	},

	setNextRoute(text, req, state) {
		//mandar post para API com dados preenchidos.
		return { type: "SET_TEXT_REQ", payload: { text, req, state } };
	},

	sendData(data, fill, url, content, email) {
		return { type: "SET_DATA", payload: { data, fill, url, content, email } };
	},
	authUser(token) {
		var res = false;

		API.get("api/users/userinfo")
			.then(async (response) => {
				// If request is good...
				res = true;
			})
			.catch((error) => {
				console.log(error);
			});

		return { type: "AUTH_USER", payload: { token, res } };
	},
};
