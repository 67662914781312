import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import moment from "moment";

function MultiDatePiker(props) {
	const [formatCalendar, setFormatCalendar] = useState("month");
	const [period, setPeriod] = useState("month");
	const [showCalendar, setShowCalendar] = useState(false);
	const [date, setDate] = useState(["", ""]);
	const [isWeek, setIsWeek] = useState(false);
	const [byPeriod, setByPeriod] = useState(false);
	const [formattedDate, setFormattedDate] = useState([]);
	const [showDates, setShowDates] = useState(false);
	useEffect(() => {
		const since = new Date(props.since).toUTCString();
		const until = new Date(props.until).toUTCString();

		if (
			getTypeSearchDates(0) === props.since ||
			getTypeSearchDates(1) === props.since ||
			getTypeSearchDates(2) === props.since
		) {
			setDate("", "");
			setShowDates(true);
		} else {
			if (!props.since) {
				setDate("", "");
			} else {
				setDate([since, until]);
			}
			setShowDates(false);
		}
	}, [props.useDatePiker, props.clearDates, props.since, props.until]);

	const getTypeSearchDates = (i) => {
		const typeSearchDatesDict = {
			0: new Date(
				new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					new Date().getDate(),
					0,
					0,
					0,
					0,
					0
				)
			).toISOString(),
			1: new Date(
				new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					new Date().getDate() - 6,
					0,
					0,
					0,
					0
				)
			).toISOString(),
			2: new Date(
				new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					new Date().getDate() - 29,
					0,
					0,
					0,
					0
				)
			).toISOString(),
		};

		return typeSearchDatesDict[i];
	};

	const selectType = (type) => {
		switch (type) {
			case "period":
				setPeriod("period");
				setShowCalendar(true);
				setIsWeek(false);
				setByPeriod(true);
				setFormatCalendar("month");
				setDate(["", ""]);
				setFormattedDate([]);
				break;
			case "week":
				setPeriod("week");
				setShowCalendar(true);
				setByPeriod(false);
				setIsWeek(true);
				setFormatCalendar("month");
				setDate(["", ""]);
				setFormattedDate([]);
				break;
			case "today":
				setPeriod("today");
				setFormatCalendar("month");
				setShowCalendar(false);
				setDate([
					new Date(
						new Date(
							new Date().getFullYear(),
							new Date().getMonth(),
							new Date().getDate(),
							0,
							0,
							0,
							0
						)
					),
					new Date(new Date().setTime(new Date().getTime())),
				]);
				setFormattedDate([
					new Date(
						new Date(
							new Date().getFullYear(),
							new Date().getMonth(),
							new Date().getDate(),
							0,
							0,
							0,
							0
						)
					).toISOString(),
					new Date(new Date().setTime(new Date().getTime())).toISOString(),
				]);
				break;
			case "lastWeek":
				setPeriod("lastWeek");
				setFormatCalendar("month");
				setShowCalendar(false);
				setByPeriod(false);
				setIsWeek(true);
				setDate([
					new Date(
						new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 6)
					),
					new Date(new Date().setTime(new Date().getTime() - 3600 * 1000)),
				]);
				setFormattedDate([
					new Date(
						new Date(
							new Date().getFullYear(),
							new Date().getMonth(),
							new Date().getDate() - 6,
							0,
							0,
							0,
							0
						)
					).toISOString(),
					new Date(new Date().setTime(new Date().getTime())).toISOString(),
				]);
				break;
			case "lastThirtyDays":
				setPeriod("lastThirtyDays");
				setFormatCalendar("month");
				setShowCalendar(false);
				setDate([
					new Date(
						new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 29)
					),
					new Date(new Date().setTime(new Date().getTime() - 3600 * 1000)),
				]);
				setFormattedDate([
					new Date(
						new Date(
							new Date().getFullYear(),
							new Date().getMonth(),
							new Date().getDate() - 29,
							0,
							0,
							0,
							0
						)
					).toISOString(),
					new Date(new Date().setTime(new Date().getTime())).toISOString(),
				]);

				break;
			case "year":
				setPeriod("year");
				setShowCalendar(true);
				setFormatCalendar(type);
				setByPeriod(false);
				setIsWeek(false);
				setDate(["", ""]);
				setFormattedDate([]);
				break;
			default:
				setPeriod("month");
				setShowCalendar(true);
				setFormatCalendar(type);
				setByPeriod(false);
				setIsWeek(false);
				setDate(["", ""]);
				setFormattedDate([]);
				break;
		}
	};

	const handleDate = (value) => {
		const newDate = new Date();

		setDate(["", ""]);
		if (isWeek) {
			setDate([
				value,
				new Date(
					newDate.setTime(new Date(value).getTime() + 3600 * 1000 * 24 * 6)
				),
			]);
			setFormattedDate([
				new Date(
					new Date(value).getFullYear(),
					new Date(value).getMonth(),
					new Date(value).getDate(),
					new Date(value).getHours(),
					new Date(value).getMinutes(),
					new Date(value).getSeconds(),
					0
				).toISOString(),
				new Date(
					new Date(value).getFullYear(),
					new Date(value).getMonth(),
					new Date(value).getDate() + 7,
					new Date(value).getHours(),
					new Date(value).getMinutes(),
					new Date(value).getSeconds(),
					0
				).toISOString(),
			]);
		} else if (formatCalendar === "year" && !byPeriod) {
			setDate([
				value,
				new Date(
					new Date(value).getFullYear(),
					new Date(value).getMonth() + 1,
					0
				),
			]);
			setFormattedDate([
				new Date(
					new Date(value).getFullYear(),
					new Date(value).getMonth(),
					new Date(value).getDate(),
					new Date(value).getHours(),
					new Date(value).getMinutes(),
					new Date(value).getSeconds(),
					0
				).toISOString(),
				new Date(
					new Date(value).getFullYear(),
					new Date(value).getMonth() + 1,
					new Date(value).getDate(),
					new Date(value).getHours(),
					new Date(value).getMinutes(),
					new Date(value).getSeconds(),
					0
				).toISOString(),
			]);
		} else if (!byPeriod && formatCalendar === "month") {
			setDate([value, value]);
			setFormattedDate([
				new Date(
					new Date(value).getFullYear(),
					new Date(value).getMonth(),
					new Date(value).getDate(),
					new Date(value).getHours(),
					new Date(value).getMinutes(),
					new Date(value).getSeconds(),
					0
				).toISOString(),
				new Date(
					new Date(value).getFullYear(),
					new Date(value).getMonth(),
					new Date(value).getDate(),
					new Date(value).getHours() + 24,
					new Date(value).getMinutes(),
					new Date(value).getSeconds(),
					0
				).toISOString(),
			]);
		} else if (byPeriod && formatCalendar === "month") {
			setDate([value[0], value[1]]);
			setFormattedDate([
				new Date(
					new Date(value[0]).getFullYear(),
					new Date(value[0]).getMonth(),
					new Date(value[0]).getDate(),
					new Date(value[0]).getHours(),
					new Date(value[0]).getMinutes(),
					new Date(value[0]).getSeconds(),
					0
				).toISOString(),
				new Date(
					new Date(value[1]).getFullYear(),
					new Date(value[1]).getMonth(),
					new Date(value[1]).getDate(),
					new Date(value[1]).getHours(),
					new Date(value[1]).getMinutes(),
					new Date(value[1]).getSeconds() + 1,
					0
				).toISOString(),
			]);
		}
	};

	const confirmDate = () => {
		props.date(formattedDate);
		closeCalendar();
	};

	const closeCalendar = () => {
		document.querySelector("#dropCalendar").classList.remove("show");
	};

	return (
		<>
			<div className={`dropdown ${props.className}`}>
				<button
					className="btn dropdown-toggle calendar-toggle btn-icon-r"
					type="button"
					id={"dropdownMenuButton"}
					data-toggle={"dropdown"}
					aria-haspopup="true"
					aria-expanded="false"
					disabled={props.loading}
				>
					{(props.isCurrentDateType || props.type === 4) &&
					props.loading === true ? (
						<>
							<i
								className="fas fa-spinner fa-spin p-0 m-0 mt-1"
								style={{ fontSize: "12px" }}
							></i>
							<span>&nbsp;&nbsp;Processando...</span>
						</>
					) : (!date[0] && !date[1]) || showDates ? (
						<>
							<i className="material-icons">event_note</i>
							<span>PERSONALIZADO</span>
						</>
					) : (
						<>
							{date[0]
								? new Date(date[0])
										.toLocaleString("pt-BR", {
											timeZone: "UTC",
										})
										.substr(0, 10)
								: date[0]}
							:
							{date[1]
								? new Date(date[1])
										.toLocaleString("pt-BR", {
											timeZone: "UTC",
										})
										.substr(0, 10)
								: date[1]}
						</>
					)}
				</button>
				<form
					id="dropCalendar"
					className="dropdown-menu "
					style={{ padding: "0", borderRadius: "5px", overflow: "hidden" }}
					aria-labelledby="dropdownMenuButton"
				>
					<div className="datepicker mult-datepicker">
						<div className="datepicker-options">
							<ul className="list-group">
								<li
									onClick={() => selectType("today")}
									className={
										period === "today"
											? "list-group-item list-group-item-action datepicker-options-item active"
											: "list-group-item list-group-item-action datepicker-options-item"
									}
								>
									Hoje
								</li>
								<li
									onClick={() => selectType("lastWeek")}
									className={
										period === "lastWeek"
											? "list-group-item list-group-item-action datepicker-options-item active"
											: "list-group-item list-group-item-action datepicker-options-item"
									}
								>
									Últimos 7 dias
								</li>
								<li
									onClick={() => selectType("lastThirtyDays")}
									className={
										period === "lastThirtyDays"
											? "list-group-item list-group-item-action datepicker-options-item active"
											: "list-group-item list-group-item-action datepicker-options-item"
									}
								>
									Últimos 30 dias
								</li>
							</ul>
							<hr />
							<ul className="list-group">
								<li
									onClick={() => selectType("month")}
									className={
										period === "month"
											? "list-group-item list-group-item-action datepicker-options-item active"
											: "list-group-item list-group-item-action datepicker-options-item"
									}
								>
									Por dia
								</li>
								<li
									onClick={() => selectType("week")}
									className={
										period === "week"
											? "list-group-item list-group-item-action datepicker-options-item active"
											: "list-group-item list-group-item-action datepicker-options-item"
									}
								>
									Por semana
								</li>
								<li
									onClick={() => selectType("year")}
									className={
										period === "year"
											? "list-group-item list-group-item-action datepicker-options-item active"
											: "list-group-item list-group-item-action datepicker-options-item"
									}
								>
									Por mês
								</li>
								<li
									onClick={() => selectType("period")}
									className={
										period === "period"
											? "list-group-item list-group-item-action datepicker-options-item active"
											: "list-group-item list-group-item-action datepicker-options-item"
									}
								>
									Por período
								</li>
							</ul>
						</div>
						<div className="datepicker-calendar">
							<div className="datepicker-header-text">
								<div className=" btn datepicker-header-text-from btn-icon-r">
									<i className="material-icons">event_note</i>
									De: {moment(date[0]).format("DD/MM/YYYY")}
								</div>
								<div className="btn datepicker-header-text-to">
									{date[1] && <> Até: {moment(date[1]).format("DD/MM/YYYY")}</>}
								</div>
							</div>
							<div
								className={`datepicker-calendar-view ${
									period === "period" && "isPerPeriod"
								}`}
							>
								{(period === "lastThirtyDays" ||
									period === "lastWeek" ||
									period === "today") && (
									<div className="block-navigation"></div>
								)}
								<Calendar
									rangeDivider={"Á"}
									clearIcon={""}
									closeCalendar={false}
									disabled={false}
									disableCalendar={false}
									maxDetail={period === "period" ? "month" : formatCalendar}
									minDetail={period === "period" ? "year" : formatCalendar}
									selectRange={byPeriod}
									showNavigation={true}
									prev2Label=""
									next2Label=""
									value={date}
									onChange={(value) => handleDate(value)}
									activeStartDate={
										(period === "lastThirtyDays" ||
											period === "lastWeek" ||
											period === "today") &&
										date[0]
									}
								/>
							</div>
							<div className="datepicker-footer">
								<button
									type="button"
									onClick={() => closeCalendar()}
									className="btn"
									style={{
										color: "#5A5E61",
										border: "1px solid #8D8D8D",
										backgroundColor: "transparent",
									}}
								>
									Cancelar
								</button>
								<button
									type="button"
									onClick={() => confirmDate()}
									disabled={
										date[1] === "" ||
										date[0] === "" ||
										formattedDate.length === 0
									}
									className="btn btn-success ml-2 datepicker-footer-confirm"
								>
									Confirmar
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default MultiDatePiker;
