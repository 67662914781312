import React from "react";
import AddressSkeleton from "./AddressSkeleton";
import useAddressService from "./AddressService";
import CustomInput from "../../../../MUICustomComponents/CustomInput";
import { Typography, Button, Stack, Box, Grid } from "@mui/material";
import { AddressView } from "../../../../domain/views/stores/AddressView";
import { AddressInputs } from "../../enums/StoreInputs";
import { i18n } from "../../../../translate/i18n";
import StateAutocomplete from "../../../../components/StateAutocomplete/StateAutocomplete";
import ZipCodeInput from "../../../../components/ZipCodeinput/ZipCodeInput";
import { FormValidator } from "../../../../infrastructure/utils/FormUtils";

type AddressProps = {
	sellerId: string;
	data: AddressView;
	getSeller: (sellerId: string) => Promise<void>;
};

export default function Address({ sellerId, data, getSeller }: AddressProps) {
	const {
		//Props
		address,
		isLoading,
		currentState,
		formErrors,
		isLoadingZipCode,
		setIsLoadingZipCode,
		setCurrentState,
		handleAutocompleteChange,
		// Functions
		handleZipCodeChange,
		handleInputChange,
		upsertAddress,
	} = useAddressService(data, getSeller);

	const renderInput = (fieldName: AddressInputs): JSX.Element => {
		return (
			<CustomInput
				name={fieldName}
				title={i18n.t(`stores.Address.${[fieldName]}`)}
				value={address[fieldName] ?? ""}
				onChange={handleInputChange}
				disabled={isLoadingZipCode}
				error={FormValidator.hasInputError(formErrors, fieldName)}
				helperText={FormValidator.translateHelperTextList(
					formErrors,
					fieldName
				)}
			/>
		);
	};

	return (
		<Box
			m={4}
			minHeight={335}
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
		>
			<Box>
				<Typography variant="h5" pb={3} pl={0} sx={{ fontWeight: "bold" }}>
					{i18n.t("stores.Titles.Address").toString()}
				</Typography>
				{isLoading ? (
					AddressSkeleton()
				) : (
					<Grid container spacing={2}>
						<Grid item xs={4} pb={2} gap={1} display="flex" alignItems="center">
							<ZipCodeInput
								inputName={AddressInputs.Cep}
								zipCode={address?.cep}
								isLoadingZipCode={isLoadingZipCode}
								setIsLoadingZipCode={setIsLoadingZipCode}
								handleZipCodeChange={handleZipCodeChange}
								formErrorText={FormValidator.translateHelperTextList(
									formErrors,
									AddressInputs.Cep
								)}
							/>
						</Grid>
						<Grid item xs={12} pb={2}>
							{renderInput(AddressInputs.Street)}
						</Grid>
						<Grid item xs={4} pb={2}>
							{renderInput(AddressInputs.Number)}
						</Grid>
						<Grid item xs={4} pb={2}>
							{renderInput(AddressInputs.Complement)}
						</Grid>
						<Grid item xs={4} pb={2}>
							{renderInput(AddressInputs.Neighborhood)}
						</Grid>
						<Grid item xs={6} pb={2}>
							<StateAutocomplete
								id={AddressInputs.State}
								currentState={currentState}
								setCurrentState={setCurrentState}
								handleAutocompleteChange={handleAutocompleteChange}
								stateCode={address.state ?? null}
								disabled={isLoadingZipCode}
								error={FormValidator.hasInputError(
									formErrors,
									AddressInputs.State
								)}
								helperText={FormValidator.translateHelperTextList(
									formErrors,
									AddressInputs.State
								)}
							/>
						</Grid>
						<Grid item xs={6} pb={2}>
							{renderInput(AddressInputs.City)}
						</Grid>
					</Grid>
				)}
			</Box>

			<Stack direction="row" justifyContent="end">
				<Button
					color="success"
					variant="contained"
					disabled={
						isLoading || isLoadingZipCode || FormValidator.hasError(formErrors)
					}
					onClick={() => upsertAddress(sellerId, address)}
				>
					{i18n.t("buttons.General.BtnSave").toString()}
				</Button>
			</Stack>
		</Box>
	);
}
