import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { i18n } from "../translate/i18n";

type Props = {
	to: string;
};

export default function CancelLink({ to }: Props) {
	return (
		<Link to={to}>
			<Button variant="contained" color="light">
				{i18n.t(`buttons.General.BtnCancel`).toString()}
			</Button>
		</Link>
	);
}
