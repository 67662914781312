import React, { Component } from 'react';

export class Empty extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='card empty'>
        <div className='card-body text-center'>
          <p>Sua rede não possui nenhum ponto de venda cadastrado ainda. Vamos cadastrar <br />algumas lojas?</p>
          <div className=" d-flex justify-content-center buttons-outlet">
            <div className='col'>
              {/* <button className='secundary-button'>Importar arquivo csv</button>
              <a href='#' className='download-model'>
                Baixar nosso modelo aqui
                <i className="material-icons" style={{ fontSize: '12px' }}>
                  open_in_new
                </i>
              </a> */}
              <button className='add-outlet-button'  onClick={() => window.location.href = '/setup/store/new'}>Adicionar novo ponto de venda</button>
            </div>
          </div>
        </div>
      </div> 
    )
  }
};

export default Empty;
