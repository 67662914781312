import { Button, Stack } from "@mui/material";
import React from "react";
import LoadingButtonIcon from "../../../../components/LoadingButtonIcon";
import { i18n } from "../../../../translate/i18n";

type Props = {
	isLoadingUpdate: boolean;
	hasError?: boolean;
	onSubmit: () => void;
};

export default function RetailerUpdateButton({
	isLoadingUpdate,
	hasError = false,
	onSubmit,
}: Props) {
	return (
		<Stack direction="row" justifyContent="end" marginTop={2}>
			<Button
				type="submit"
				color="success"
				variant="contained"
				disabled={isLoadingUpdate || hasError}
				onClick={onSubmit}
			>
				{isLoadingUpdate ? (
					<LoadingButtonIcon />
				) : (
					i18n.t(`buttons.General.BtnSave`).toString()
				)}
			</Button>
		</Stack>
	);
}
