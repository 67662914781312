import { ExpandMore } from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import CustomTypography from "../../../../../MUICustomComponents/CustomTypography";
import CustomInput from "../../../../../MUICustomComponents/CustomInput";
import { i18n } from "../../../../../translate/i18n";
import {
	PaymentType,
	mapPaymentTypeToDescription,
} from "../../../../../domain/enums/PaymentType";
import {
	InstallmentView,
	ReceivingMethodView,
} from "./ReceivingMethodsService";
import {
	FormErrors,
	FormValidator,
} from "../../../../../infrastructure/utils/FormUtils";

type Props = {
	receivingMethod: ReceivingMethodView;
	formErrors: FormErrors;
	changeInstallmentQuantity: (
		inputValue: string,
		receivingMethodId: string
	) => void;
	changeReceivingMethod: (
		e: ChangeEvent<HTMLInputElement>,
		receivingMethodId: string,
		installmentValue: string
	) => void;
};

export default function ReceivingMethodComponent({
	receivingMethod,
	formErrors,
	changeReceivingMethod,
	changeInstallmentQuantity,
}: Props) {
	const receivingMethodDescription = mapPaymentTypeToDescription(
		receivingMethod.paymentMethodType
	);
	const [installmentQuantity, setInstallmentQuantity] = useState<string>(
		receivingMethod.installments.length.toString()
	);
	const maxInstallmentQuantity = "240";

	const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
		let quantity = e.target.value;
		if (parseInt(quantity) > parseInt(maxInstallmentQuantity)) {
			quantity = maxInstallmentQuantity;
		}
		setInstallmentQuantity(quantity);
		changeInstallmentQuantity(quantity, receivingMethod.receivingMethodId);
	};

	const intallmentRow = (installment: InstallmentView) => {
		const name = `${receivingMethod.receivingMethodId}-${installment.value}`;
		return (
			<TableRow
				key={`${receivingMethod.receivingMethodId}-${installment.value}`}
				sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
			>
				<TableCell sx={{ fontWeight: "bold" }}>{installment.value}</TableCell>
				<TableCell>
					<CustomInput
						name={name}
						placeholder={i18n.t(
							"paymentConfig.PriceVariationPercentagePlaceholder"
						)}
						value={installment.priceVariationPercentage ?? ""}
						type="number"
						endAdornment={"%"}
						onChange={(e) =>
							changeReceivingMethod(
								e,
								receivingMethod.receivingMethodId,
								installment.value
							)
						}
						customWidth="110px"
						error={FormValidator.hasInputError(formErrors, name)}
						helperText={FormValidator.translateHelperTextList(formErrors, name)}
					/>
				</TableCell>
			</TableRow>
		);
	};

	const renderInstallmentTable = () => {
		return (
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell width={"15%"} sx={{ fontWeight: "bold" }}>
								{i18n.t("paymentConfig.Installment").toString()}
							</TableCell>
							<TableCell sx={{ fontWeight: "bold" }}>
								{i18n.t("paymentConfig.PriceVariationPercentage").toString()}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{receivingMethod.installments.map((installment: InstallmentView) =>
							intallmentRow(installment)
						)}
					</TableBody>
				</Table>
			</TableContainer>
		);
	};

	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMore />}
				aria-controls={`${receivingMethod.receivingMethodId}-Accordion`}
				id={`${receivingMethod.receivingMethodId}-Accordion`}
			>
				<CustomTypography variant="default" isBold>
					{i18n.t(`paymentType.${receivingMethodDescription}`).toString()}
				</CustomTypography>
			</AccordionSummary>
			<AccordionDetails>
				<CustomInput
					title={i18n.t("paymentConfig.InstallmentQuantity")}
					name={"InstallmentQuantity"}
					value={installmentQuantity}
					onChange={handleQuantityChange}
					type="number"
					maxChar={3}
					disabled={receivingMethod.paymentMethodType === PaymentType.Pix}
				/>
				{renderInstallmentTable()}
			</AccordionDetails>
		</Accordion>
	);
}
