import React, { ChangeEvent } from "react";
import { Grid, SelectChangeEvent } from "@mui/material";
import { i18n } from "../../../../translate/i18n";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import CustomInput from "../../../../MUICustomComponents/CustomInput";
import RetailerCard from "../components/RetailerCard";
import RetailerCheckbox from "../components/RetailerCheckbox";
import { AppConfig } from "../types/AppConfig";
import {
	FormErrors,
	FormValidator,
} from "../../../../infrastructure/utils/FormUtils";

type Props = {
	appConfig: AppConfig;
	onChange: (
		event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>,
		checked?: boolean
	) => void;
	formErrors: FormErrors;
};

export default function AppPayment({ appConfig, onChange, formErrors }: Props) {
	return (
		<RetailerCard>
			<CustomTypography variant="secondaryTitle" marginBottom={2}>
				{i18n.t("retailerConfig.AppPayment").toString()}
			</CustomTypography>
			<Grid container columnSpacing={2} mt={-1}>
				<Grid item xs={12}>
					<RetailerCheckbox
						id="hasSplitPayment"
						isChecked={appConfig.hasSplitPayment}
						onChange={onChange}
						helpTooltip={{
							title: i18n.t(`retailerConfig.HasSplitPaymentTooltip`),
						}}
					/>
				</Grid>
				<Grid item xs={12} mt={1}>
					<CustomInput
						title={i18n.t(`retailerConfig.${"percentageSplit"}`)}
						name="percentageSplit"
						value={appConfig.percentageSplit}
						onChange={onChange}
						type="number"
						endAdornment={"%"}
						disabled={!appConfig.hasSplitPayment}
						error={FormValidator.hasInputError(formErrors, "percentageSplit")}
						helperText={FormValidator.translateHelperTextList(
							formErrors,
							"percentageSplit"
						)}
					/>
				</Grid>
				<Grid item xs={6}>
					<RetailerCheckbox
						id="useDealerCommission"
						isChecked={appConfig.useDealerCommission}
						onChange={onChange}
						disabled={!appConfig.hasSplitPayment}
						helpTooltip={{
							title: i18n.t(`retailerConfig.UseDealerCommissionTooltip`),
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<RetailerCheckbox
						id="isSellerTaxPayor"
						isChecked={appConfig.isSellerTaxPayor}
						onChange={onChange}
						disabled={!appConfig.hasSplitPayment}
					/>
				</Grid>
			</Grid>
		</RetailerCard>
	);
}
