import React, { ChangeEvent } from "react";
import { Grid, SelectChangeEvent } from "@mui/material";
import { i18n } from "../../../../translate/i18n";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import CustomInput from "../../../../MUICustomComponents/CustomInput";
import CustomSelect, {
	SelectOptions,
} from "../../../../MUICustomComponents/CustomSelect";
import RetailerCard from "../components/RetailerCard";
import { TimeType } from "../../../../domain/dtos/retailerManagement/retailerConfigurations/TimeType";
import { AppConfig } from "../types/AppConfig";

type Props = {
	appConfig: AppConfig;
	onChange: (
		event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>,
		checked?: boolean
	) => void;
};

export default function AppOrder({ appConfig, onChange }: Props) {
	const pickUpTimeTypeOptions: SelectOptions = [
		{
			label: i18n.t("retailerConfig.PickUpTimeType.Minutes"),
			value: TimeType.Minutes,
		},
		{
			label: i18n.t("retailerConfig.PickUpTimeType.Hours"),
			value: TimeType.Hours,
		},
		{
			label: i18n.t("retailerConfig.PickUpTimeType.Days"),
			value: TimeType.Days,
		},
		{
			label: i18n.t("retailerConfig.PickUpTimeType.Weeks"),
			value: TimeType.Weeks,
		},
		{
			label: i18n.t("retailerConfig.PickUpTimeType.Months"),
			value: TimeType.Months,
		},
		{
			label: i18n.t("retailerConfig.PickUpTimeType.None"),
			value: TimeType.None,
		},
	];

	return (
		<RetailerCard>
			<CustomTypography variant="secondaryTitle" marginBottom={2}>
				{i18n.t("retailerConfig.AppOrder").toString()}
			</CustomTypography>
			<Grid container columnSpacing={2} mt={1}>
				<Grid item xs={6}>
					<CustomInput
						title={i18n.t(`retailerConfig.${"pickUpTime"}`)}
						name="pickUpTime"
						value={appConfig.pickUpTime}
						onChange={onChange}
						type="number"
						helpTooltip={{ title: i18n.t(`retailerConfig.PickUpTimeTooltip`) }}
					/>
				</Grid>
				<Grid item xs={6}>
					<CustomSelect
						label={i18n.t(`retailerConfig.${"pickUpTimeType"}`)}
						id="pickUpTimeType"
						onChange={onChange}
						value={appConfig.pickUpTimeType}
						options={pickUpTimeTypeOptions}
					/>
				</Grid>
			</Grid>
		</RetailerCard>
	);
}
