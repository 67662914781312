import React from "react";
import ContentLoader from "react-content-loader";

export default function ModalitiesSkeleton() {
	return [...Array(4)].map((_, index) => (
		<ContentLoader
			key={index}
			speed={2}
			width={740}
			height={60}
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
			viewBox="0 0 1100 80"
			style={{ width: "100%" }}
		>
			<rect x="0" y="10" rx="3" ry="3" width="330" height="60" />
			<rect x="360" y="10" rx="3" ry="3" width="330" height="60" />
			<rect x="710" y="10" rx="3" ry="3" width="330" height="60" />
		</ContentLoader>
	));
}
