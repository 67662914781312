import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import DragDropBanners from "../../../../components/DragDropBanners";
import Empty from "../../../../components/Empty";
import { APIV2 } from "../../../../API";
import { toast } from "react-toastify";
import Modal from "../../../../components/Modal";
import AdBlockDetect from "react-ad-block-detect";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function BodyBanners(props) {
	const [edit, setEdit] = useState(false);
	const [loading, setLoading] = useState(true);
	const [allBanners, setAllBanners] = useState([]);
	const [banners, setBanners] = useState([]);
	const [expired, setExpired] = useState([]);
	const [deleteId, setDeleteId] = useState("");
	const [idBanner, setIdBanner] = useState("");
	const [today, setToday] = useState(new Date());
	const [isDefault, setIsDefault] = useState([]);
	const [show, setShow] = useState(new Date());

	useEffect(() => {
		getBodyBanners();
	}, []);

	const getBodyBanners = async () => {
		APIV2.get("management/marketing/banners")
			.then(async (response) => {
				let allBanners = response.data.filter((obj) => {
					return obj.type === 1;
				});
				setAllBanners(allBanners);
				let filterdate = response.data.filter((obj) => {
					let endIn = new Date(obj.endIn);
					if (today <= endIn) {
						return new Date(obj.endIn);
					}
				});
				let filterType = filterdate.filter((obj) => {
					return obj.type === 1;
				});
				setBanners(filterType);

				let filterExpired = response.data.filter((obj) => {
					let endIn = new Date(obj.endIn);
					if (today > endIn) {
						return new Date(obj.endIn);
					}
				});
				let isDefault = filterExpired.filter((obj) => {
					return obj.isDefault === false;
				});
				setIsDefault(isDefault);

				let bodyExpired = isDefault.filter((obj) => {
					return obj.type === 1;
				});
				setExpired(bodyExpired);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				if (allBanners.length <= 0) {
					setLoading(false);
				} else {
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				}
			});
	};

	const getBannerId = (id) => {
		setDeleteId(id);
	};

	const deleteBanner = (id) => {
		setIdBanner(id);
	};

	const deleteOldBanner = async () => {
		await APIV2.delete("management/marketing/banners/" + deleteId)
			.then(async (response) => {
				toast.error("Banner deletado com sucesso! 😃");
				window.location.reload();

				let filterExpired = response.data.filter((obj) => {
					let endIn = new Date(obj.endIn);
					if (today > endIn) {
						return new Date(obj.endIn);
					}
				});
				let isDefault = filterExpired.filter((obj) => {
					return obj.isDefault === false;
				});
				setIsDefault(isDefault);

				let bodyExpired = isDefault.filter((obj) => {
					return obj.type === 1;
				});
				setExpired(bodyExpired);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				if (allBanners.length <= 0) {
					setLoading(false);
				} else {
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				}
			});
	};

	const newReorderBanner = async (newReorderBanners) => {
		await newReorderBanners.forEach(async (reorderBanner) => {
			await APIV2.put(
				"management/marketing/banners/" + reorderBanner.id,
				reorderBanner
			)
				.then(async (response) => {})
				.catch((error) => {
					console.log(error);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				});
		});
		toast.success("Lista reordenada com sucesso! 😃");
	};

	const showLabel = () => {
		if (show === true) {
			setShow(false);
		} else {
			setShow(true);
		}
	};

	const skeleton = () => {
		const skele = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
		return skele.map((index) => (
			<ContentLoader
				key={index}
				speed={2}
				width={1100}
				height={110}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 110"
				style={{ width: "100%" }}
			>
				<rect x="16" y="0" rx="3" ry="3" width="100" height="100" />
				<rect x="350" y="55" rx="3" ry="3" width="60" height="10" />
				<rect x="970" y="53" rx="3" ry="3" width="100" height="35" />
			</ContentLoader>
		));
	};

	return (
		<>
			{allBanners.length <= 0 && loading === false ? (
				<div className="card">
					<Empty
						img={"/images/empty_banner-vert.svg"}
						genre="seu primeiro"
						name="banner"
						link="new-body"
						align={true}
					/>
				</div>
			) : (
				<div className="card pb-4">
					<div className="card-header d-flex justify-content-between align-items-center">
						<h4 className="card-title">Banner de Corpo</h4>
						<div className="card-actions">
							<Link
								className="btn btn-grey-80"
								style={{ width: "150px" }}
								to={"new-body"}
							>
								Novo Banner
							</Link>
						</div>
					</div>

					<div className="pr-4 pl-4 pb-3">
						<AdBlockDetect>
							<div class="alert alert-danger" role="alert">
								AdBlock foi detectador, favor desativar e recarregue a página
							</div>
						</AdBlockDetect>
						<div className="p-2">
							<label className="mr-5 mt-2">
								<strong>Banners</strong>
							</label>

							{loading
								? skeleton()
								: banners.length <= 0 && (
										<h3
											className="p-5 d-flex align-items-center justify-content-center"
											style={{
												color: "#BDBEC0",
												fontFamily: "Helvetica",
												fontSize: "18px",
												fontWeight: "normal",
												lineHeight: "10px",
											}}
										>
											Não há Banners cadastrados
										</h3>
								  )}

							<DragDropBanners
								banners={banners}
								newItemBanners={newReorderBanner}
								deleteBanner={deleteBanner}
								idBanner={idBanner}
								url={props.url}
								className="bg-light-blue-300"
							/>

							<div className="accordion" id="oldBanners">
								{expired.length <= 0 ? null : (
									<>
										<div className="new-question mb-2">
											<div
												onClick={showLabel}
												data-toggle="collapse"
												data-target="#collapseBanner"
												aria-expanded="true"
												aria-controls="collapseTwo"
												id="headingOne"
											>
												<span
													className="text-muted d-flex align-items-center justify-content-center"
													style={{
														color: "#BDBEC0",
														fontFamily: "Helvetica",
														fontSize: "14px",
														fontWeight: "normal",
														lineHeight: "10px",
													}}
												>
													<i className="material-icons">more_horiz</i>&nbsp;
													{show == true
														? "Ocultar banner antigos"
														: "Ver banner antigos"}
												</span>
											</div>
										</div>
										<div
											id="collapseBanner"
											className="collapse"
											aria-labelledby="headingOne"
											data-parent="#oldBanners"
										>
											{expired.map((banner) => (
												<div
													key={banner.id}
													style={{
														borderRadius: "4px",
														border: "1px solid rgb(233, 236, 236)",
													}}
												>
													<div className="list-banner mx-1">
														<div className="banner-img">
															<img
																src={
																	banner.imageUrl == null
																		? "/images/placeholder.jpg"
																		: banner.imageUrl
																}
																alt="Banners"
																className="shadow-sm"
															/>
														</div>

														<div className="banner-description d-flex align-items-center justify-content-start">
															<h6
																className="font-weight-bold justify-content-start"
																style={{
																	whiteSpace: "nowrap",
																	overflow: "hidden",
																	textOverflow: "ellipsis",
																}}
															>
																{banner.title}
															</h6>
														</div>

														<div className="banner-label d-flex justify-content-end">
															<span className="badge badge-danger stadium">
																EXPIRADO
															</span>
														</div>

														<div className="banner-buttons">
															<div
																className="btn-group"
																role="group"
																aria-label="Basic example"
															>
																<Link
																	className="btn btn-light btn-sm outline btn-icon"
																	to={`new-body/edit/${banner.id}`}
																>
																	<i className="material-icons">edit</i>
																</Link>
																<button
																	type="button"
																	className="btn btn-light btn-sm outline btn-icon"
																	data-toggle="modal"
																	data-target="#deleteBody"
																	onClick={() => getBannerId(banner.id)}
																>
																	<i className="material-icons text-danger">
																		delete_outline
																	</i>
																</button>
															</div>
														</div>
													</div>
												</div>
											))}
										</div>
									</>
								)}
							</div>
						</div>
					</div>
					<Modal
						id={"deleteBody"}
						title={"Tem certeza que deseja excluir este item?"}
						delete={true}
						actionDelete={deleteOldBanner}
					/>
				</div>
			)}
		</>
	);
}
