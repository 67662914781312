import React, { useEffect } from "react";
import DealersContext from "./providers/DealersContext";
import DealersList from "./DealersList";
import { useConfig } from "./../../providers/ConfigProvider";
import LoadDashboard from "../Dashboard/components/LoadDashboard";

export default function Dealers(props) {
	const { mainLoading } = useConfig();
	useEffect(() => {
		localStorage.removeItem("PARAMS");
		if (!props.match.params.page) {
			window.location.href = `/salespeople/1`;
		}
		localStorage.removeItem("transRowsPerPage");
		localStorage.removeItem("transType");
		localStorage.removeItem("transStatus");
		localStorage.removeItem("transPaymentMethod");
		localStorage.removeItem("transStartDate");
		localStorage.removeItem("transEndDate");
		localStorage.removeItem("transSearchName");
	}, [props.match.params]);

	return (
		<>
			{mainLoading ? (
				<LoadDashboard />
			) : (
				<DealersContext>
					<DealersList page={parseInt(props.match.params.page)} />
				</DealersContext>
			)}
		</>
	);
}
