import React, { createContext, useContext, useEffect, useState } from "react";
import { APIV1, APIV2 } from "../../../API";
import { toast } from "react-toastify";
import {
	createStatementExport,
	createWithdrawExport,
	createAnticipationExport,
	createPayablesExport,
} from "./exports.js";
import { getFilterDate } from "../../../infrastructure/utils/DateUtils.jsx";
import { DateTypes } from "../components/DataTypes/DateTypes";
import { retailerManagementApi } from "../../../infrastructure/api/RetailerManagementApi";
import NewToastComponent from "../../../components/NewToastComponent";
import { i18n } from "../../../translate/i18n";
import { StringValidator } from "../../../infrastructure/utils/TypeValidators/StringValidator";
import { insertHtml } from "../../../infrastructure/utils/InsertHtml";
import { useLocation } from "react-router-dom";

export const FinancialProvider = createContext({});

export default function FinancialContext(props) {
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const [available, setAvailable] = useState("");
	const [waitingFunds, setWaitingFunds] = useState("");
	const [itemDetails, setItemDetails] = useState({});
	const [listStatement, setListStatement] = useState([]);
	const [listWithdraw, setListWithdraw] = useState([]);
	const [listTransactions, setListTransactions] = useState([]);
	const [listPayables, setListPayables] = useState([]);
	const [bankAccount, setBankAccount] = useState([]);
	const [reloadFilter, setReloadFilter] = useState([]);
	const [openFilterModal, setOpenFilterModal] = useState(false);
	const [updateFilterData, setUpdateFilterData] = useState([]);
	const [listAnticipations, setListAnticipations] = useState([]);
	const [statementExport, setStatementExport] = useState([]);
	const [withdrawExport, setWithdrawExport] = useState([]);
	const [anticipationExport, setAnticipationExport] = useState([]);
	const [payablesExport, setPayablesExport] = useState([]);
	const [totalPayablesAmount, setTotalPayablesAmount] = useState(0);

	const [defaultStartDate, defaultEndDate] = getFilterDate(0);

	const [statementFilters, setStatementFilters] = useState({
		startDate: defaultStartDate.getTime(),
		endDate: defaultEndDate.getTime(),
		rowsPerPage: 20,
		rangeDate: DateTypes.Today.name,
		statusFilter: "available",
		defaultFilterOption: "available",
		filterOptions: [
			{ label: "Transferido", value: "transferred" },
			{ label: "Disponível", value: "available" },
			{ label: "Aguardando Saldo", value: "waiting_funds" },
		],
	});

	const [payableFilters, setPayableFilters] = useState({
		startDate: defaultStartDate.getTime(),
		endDate: defaultEndDate.getTime(),
		rowsPerPage: 20,
		rangeDate: DateTypes.Today.name,
		statusFilter: "",
		defaultFilterOption: "",
		filterOptions: [
			{ label: "Mostrar todos", value: "" },
			{ label: "Pedido Contestado", value: "chargeback" },
			{ label: "Estorno", value: "refund" },
			{ label: "Falha no estorno", value: "refund_reversal" },
			{ label: "Contestação revertida", value: "chargeback_refund" },
			{ label: "Crédito em conta", value: "credit" },
		],
		timeStatusFilter: "paid",
	});

	const [withdrawalFilters, setWithdrawalFilters] = useState({
		startDate: defaultStartDate.getTime(),
		endDate: defaultEndDate.getTime(),
		rowsPerPage: 20,
		rangeDate: DateTypes.Today.name,
		statusFilter: "",
		defaultFilterOption: "",
		filterOptions: [
			{ label: "Mostrar todos", value: "" },
			{ label: "Saque pendente", value: "pending_transfer" },
			{ label: "Saque transferido", value: "transferred" },
			{ label: "Erro na transferência", value: "failed" },
			{ label: "Processando saque", value: "processing" },
			{ label: "Saque cancelado", value: "canceled" },
		],
	});

	const [anticipationFilters, setAnticipationFilters] = useState({
		startDate: defaultStartDate.getTime(),
		endDate: defaultEndDate.getTime(),
		rowsPerPage: 20,
		rangeDate: DateTypes.Today.name,
		statusFilter: "",
		defaultFilterOption: "",
		filterOptions: [
			{ label: "Mostrar todos", value: "" },
			{ label: "Antecipação Pendente", value: "pending" },
			{ label: "Antecipação Concluída", value: "approved" },
			{ label: "Antecipação Solicitada", value: "building" },
			{ label: "Erro na Antecipação", value: "refused" },
			{ label: "Antecipação Cancelada", value: "canceled" },
		],
	});

	const [storeList, setStoreList] = useState([
		{
			value: "",
			label: "Selecione uma loja",
		},
	]);

	const [seller, setSeller] = useState("");
	const [hasRecepientId, setHasRecepientId] = useState(true);

	useEffect(() => {
		if (localStorage.getItem("role") === "3") {
			getBalance();
			getBankAccount();
		}
	}, []);

	const getTotalListStatement = async () => {
		const data = {
			count: 1000,
			page: 1,
			status: `${statementFilters?.statusFilter || ""}`,
			start_date: `${statementFilters?.startDate || ""}`,
			end_date: `${statementFilters?.endDate || ""}`,
		};
		const body = {
			domain: `https://api.pagar.me/`,
			resource: `1/recipients/${localStorage.getItem(
				"recipientId"
			)}/balance/operations`,
			subject: `pagarme`,
			method: `get`,
			body: JSON.stringify(data),
		};

		try {
			const response = await APIV1.post(
				`im/proxy/${localStorage.getItem("client_id").toLowerCase()}`,
				body
			);
			const exportData = createStatementExport(response.data);
			setStatementExport(exportData);

			return response.data;
		} catch (error) {
			console.log(error);
		}
	};

	const getTotalListAnticipation = async () => {
		const queryParams = `?count=1000&page=1${
			anticipationFilters?.statusFilter
				? `&status=${anticipationFilters?.statusFilter}`
				: ""
		}&payment_date=>=${anticipationFilters?.startDate}&payment_date=<=${
			anticipationFilters?.endDate
		}`;
		const body = {
			domain: `https://api.pagar.me/`,
			resource: `1/recipients/${localStorage.getItem(
				"recipientId"
			)}/bulk_anticipations${queryParams}`,
			subject: `pagarme`,
			method: `get`,
		};

		try {
			const response = await APIV1.post(
				`im/proxy/${localStorage.getItem("client_id").toLowerCase()}`,
				body
			);
			const exportData = createAnticipationExport(response.data);
			setAnticipationExport(exportData);

			return response.data;
		} catch (error) {
			console.log(error);
		}
	};

	const getTotalListWithdraw = async () => {
		const queryParams = `?count=1000&page=1${
			withdrawalFilters?.statusFilter
				? `&status=${withdrawalFilters?.statusFilter}`
				: ""
		}&created_at=>=${withdrawalFilters?.startDate}&created_at=<=${
			withdrawalFilters?.endDate
		}&recipient_id=${localStorage.getItem("recipientId")}`;
		const body = {
			domain: `https://api.pagar.me/`,
			resource: `1/transfers${queryParams}`,
			subject: `pagarme`,
			method: `get`,
		};

		try {
			const response = await APIV1.post(
				`im/proxy/${localStorage.getItem("client_id").toLowerCase()}`,
				body
			);
			const newWithdrawExport = createWithdrawExport(response.data);
			setWithdrawExport(newWithdrawExport);

			return response.data;
		} catch (error) {
			console.error(error);
		}
	};

	const getTotalListPayables = async () => {
		const queryParams = `?count=1000&page=1${
			payableFilters?.timeStatusFilter
				? `&status=${payableFilters?.timeStatusFilter}`
				: ""
		}${
			payableFilters?.statusFilter
				? `&type=${payableFilters?.statusFilter}`
				: ""
		}&payment_date=>=${payableFilters?.startDate}&payment_date=<=${
			payableFilters?.endDate
		}&recipient_id=${localStorage.getItem("recipientId")}`;
		const body = {
			domain: `https://api.pagar.me/`,
			resource: `1/payables${queryParams}`,
			subject: `pagarme`,
			method: `get`,
		};
		try {
			const response = await APIV1.post(
				`im/proxy/${localStorage.getItem("client_id").toLowerCase()}`,
				body
			);
			const exportData = createPayablesExport(response.data);
			setPayablesExport(exportData);
			setTotalPayablesAmount(response.data.length);

			return response.data;
		} catch (errors) {
			console.log(errors);
		}
	};

	useEffect(() => {}, [openFilterModal]);

	const getBankAccount = async () => {
		const body = {
			domain: `https://api.pagar.me/`,
			resource: `1/recipients/${localStorage.getItem("recipientId")}`,
			subject: `pagarme`,
			method: `get`,
			body: "",
		};

		try {
			const response = await APIV1.post(
				`im/proxy/${localStorage.getItem("client_id").toLowerCase()}`,
				body
			);
			const data = {
				id: response.data.bank_account.id,
				agencia: response.data.bank_account.agencia,
				agenciaDv: response.data.bank_account.agencia_dv,
				bankCode: response.data.bank_account.bank_code,
				conta: response.data.bank_account.conta,
				contaDv: response.data.bank_account.conta_dv,
				dateCreated: response.data.bank_account.date_created,
				document: response.data.bank_account.document_number,
				documentType: response.data.bank_account.document_type,
				legalName: response.data.bank_account.legal_name,
				object: response.data.bank_account.object,
				type: response.data.bank_account.type,
			};
			setBankAccount(data);
		} catch (error) {
			console.log(error);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	const getBalance = async () => {
		const body = {
			domain: `https://api.pagar.me/`,
			//resource: `1/balance/`, //Saldo do recebedor principal
			resource: `1/recipients/${localStorage.getItem("recipientId")}/balance`, //Saldo do recebedor específico
			subject: `pagarme`,
			method: `get`,
			body: "",
		};

		try {
			const response = await APIV1.post(
				`im/proxy/${localStorage.getItem("client_id").toLowerCase()}`,
				body
			);
			setAvailable(response.data.available.amount);
			setWaitingFunds(response.data.waiting_funds.amount);
		} catch (error) {
			console.log(error);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	const getListWithdraw = async (page) => {
		setLoading(true);
		const queryParams = `?count=${withdrawalFilters?.rowsPerPage}&page=${page}${
			withdrawalFilters?.statusFilter
				? `&status=${withdrawalFilters?.statusFilter}`
				: ""
		}&created_at=>=${withdrawalFilters?.startDate}&created_at=<=${
			withdrawalFilters?.endDate
		}&recipient_id=${localStorage.getItem("recipientId")}`;
		const body = {
			domain: `https://api.pagar.me/`,
			resource: `1/transfers${queryParams}`,
			subject: `pagarme`,
			method: `get`,
		};
		setTimeout(async () => {
			try {
				const response = await APIV1.post(
					`im/proxy/${localStorage.getItem("client_id").toLowerCase()}`,
					body
				);
				setListWithdraw([]);
				response.data.forEach((withdraw, index) => {
					let subTotal = withdraw.amount + withdraw.fee;

					let isFinalized =
						withdraw.status !== "processing" &&
						withdraw.status !== "pending_transfer";

					const data = {
						id: withdraw.id,
						dateCreated: withdraw.date_created,
						dateUpdated: withdraw.date_updated,
						type: withdraw.type,
						amount: subTotal.toString().replace(/(\d)(\d{2})$/, "$1.$2"),
						fee: withdraw?.fee.toString().replace(/(\d)(\d{2})$/, "$1.$2"),
						receive: withdraw.amount
							.toString()
							.replace(/(\d)(\d{2})$/, "$1.$2"),
						status: withdraw.status,
						dateEstimated: withdraw.funding_estimated_date,
						isFinalized: isFinalized,
					};
					setListWithdraw((prevState) => [...prevState, data]);
				});
				setLoading(false);
			} catch (error) {
				console.log(error);
				setLoading(false);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			}
		}, 3000);
	};

	const createNewWithdraw = (value) => {
		setLoading(true);
		const data = {
			amount: value,
			recipient_id: `${localStorage.getItem("recipientId")}`,
		};
		const body = {
			domain: `https://api.pagar.me/`,
			resource: `1/transfers`,
			subject: `pagarme`,
			method: `post`,
			body: JSON.stringify(data),
		};

		try {
			APIV1.post(
				`im/proxy/${localStorage.getItem("client_id").toLowerCase()}`,
				body
			);
			setTimeout(async () => {
				getBalance();
				getListWithdraw(1);
			}, 4000);
		} catch (errors) {
			console.log(errors);
			setLoading(false);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	const oneTrafer = (transferId) => {
		const body = {
			domain: `https://api.pagar.me/`,
			resource: `1/transfers/${transferId}`, //`1/balance/operations/balance_operation_id`
			subject: `pagarme`,
			method: `get`,
			body: "",
		};

		try {
			APIV1.post(
				`im/proxy/${localStorage.getItem("client_id").toLowerCase()}`,
				body
			);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	const cancelWithdraw = (withdraw) => {
		setLoading(true);
		const body = {
			domain: `https://api.pagar.me/`,
			resource: `1/transfers/${withdraw.id}/cancel`,
			subject: `pagarme`,
			method: `post`,
			body: "",
		};
		try {
			APIV1.post(
				`im/proxy/${localStorage.getItem("client_id").toLowerCase()}`,
				body
			);
			setTimeout(async () => {
				getBalance();
				getListWithdraw(1);
			}, 4000);
		} catch (error) {
			console.log(error);
			setLoading(false);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	const getListStatement = async (page) => {
		setLoading(true);
		const data = {
			count: `${statementFilters?.rowsPerPage || ""}`,
			page: `${!page ? "" : page}`,
			status: `${statementFilters?.statusFilter || ""}`,
			start_date: `${statementFilters?.startDate || ""}`,
			end_date: `${statementFilters?.endDate || ""}`,
		};
		const body = {
			domain: `https://api.pagar.me/`,
			resource: `1/recipients/${localStorage.getItem(
				"recipientId"
			)}/balance/operations`,
			subject: `pagarme`,
			method: `get`,
			body: JSON.stringify(data),
		};
		setTimeout(async () => {
			try {
				const response = await APIV1.post(
					`im/proxy/${localStorage.getItem("client_id").toLowerCase()}`,
					body
				);
				setListStatement([]);
				response.data.forEach((extract) => {
					if (extract.type === "transfer" && extract.amount > 0) {
						var transfer = "chargeback";
					} else if (extract.type === "transfer" && extract.amount < 0) {
						var transfer = "sacks";
					} else {
						var transfer = extract?.type;
					}

					let totalFee =
						Math.abs(extract?.movement_object?.fee || 0) +
						Math.abs(extract?.movement_object?.anticipation_fee || 0);
					let transferTypeAmount =
						extract.amount < 0
							? extract.amount - totalFee
							: extract.amount + totalFee;

					const data = {
						id: extract?.id,
						dateCreated: extract?.date_created,
						dateUpdated: extract?.movement_object?.funding_estimated_date,
						type: transfer,
						amount: extract?.amount,
						status: extract?.status,
						transferTypeAmount: transferTypeAmount,
					};
					setListStatement((prevState) => [...prevState, data]);
				});

				setLoading(false);
			} catch (error) {
				console.log(error);
				setLoading(false);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			}
		}, 1000);
	};

	const getSpecificStatement = async (id) => {
		setLoading(true);
		const body = {
			domain: `https://api.pagar.me/`,
			resource: `1/balance/operations/${id}`,
			subject: `pagarme`,
			method: `get`,
			body: "",
		};

		try {
			const response = await APIV1.post(
				`im/proxy/${localStorage.getItem("client_id").toLowerCase()}`,
				body
			);

			let fee = response.data?.movement_object?.fee || 0;
			let anticipationFee =
				response.data?.movement_object?.anticipation_fee || 0;
			let totalFee = fee + anticipationFee;

			let initialAmount;
			let finalAmount;
			if (response.data.type === "transfer" && response.data.amount > 0) {
				var transfer = "chargeback";
				initialAmount = response.data.amount;
				finalAmount = response.data.amount + Math.abs(totalFee);
			} else if (
				response.data.type === "transfer" &&
				response.data.amount < 0
			) {
				var transfer = "sacks";
				initialAmount = response.data.amount - Math.abs(totalFee);
				finalAmount = response.data.amount;
			} else {
				var transfer = response.data.type;
				initialAmount = response.data.amount;
				finalAmount = response.data.amount - Math.abs(totalFee);
			}

			const data = {
				id: response.data?.id,
				dateCreated: response.data?.date_created,
				dateUpdated: response.data?.movement_object?.date_updated,

				paymentDate: !response.data?.movement_object?.payment_date
					? response.data?.movement_object?.funding_estimated_date
					: response.data?.movement_object?.payment_date,

				type: transfer,
				paymentMethod: response.data?.movement_object?.payment_method,
				initialAmount: initialAmount,
				status: response.data?.status,
				fee: fee,
				anticipationFee: anticipationFee,
				finalAmount: finalAmount,
			};
			setItemDetails(data);
			setLoading(false);
			const transactionId = response.data?.movement_object?.transaction_id;
			const splitRuleId = response.data?.movement_object?.split_rule_id;
			if (transactionId && splitRuleId) {
				getSplitRule(transactionId, splitRuleId);
			}
		} catch (error) {
			console.log(error);
			setLoading(false);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	const getSplitRule = async (transactionId, splitRuleId) => {
		const body = {
			domain: `https://api.pagar.me/`,
			resource: `1/transactions/${transactionId}/split_rules/${splitRuleId}`,
			subject: `pagarme`,
			method: `get`,
			body: "",
		};
		try {
			const response = await APIV1.post(
				`im/proxy/${localStorage.getItem("client_id").toLowerCase()}`,
				body
			);
		} catch (error) {
			console.log(error);
			setLoading(false);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	const getListTransactions = async (
		rowsPerPage,
		page,
		status,
		paymentMethod,
		startDate,
		endDate,
		searchName
	) => {
		setLoading(true);
		setListTransactions([]);
		const data = {
			count: `${!rowsPerPage ? "" : rowsPerPage}`,
			page: `${!page ? "" : page}`,
			status: `${!status ? "" : status}`,
			payment_method: `${!paymentMethod ? "" : paymentMethod}`,
		};

		const body = {
			domain: `https://api.pagar.me/`,
			resource: `1/transactions?date_created=${">=" + startDate}&date_created=${
				"<=" + endDate
			}`,
			subject: `pagarme`,
			method: `get`,
			body: JSON.stringify(data),
		};

		try {
			const response = await APIV1.post(
				`im/proxy/${localStorage.getItem("client_id").toLowerCase()}`,
				body
			);

			if (searchName) {
				var transaction = response.data.filter((element) => {
					let name = element.customer.name.toLowerCase();
					if (name.indexOf(searchName.toLowerCase()) != -1) {
						return element;
					}
				});
				transaction.forEach((transaction, index) => {
					const data = {
						id: transaction?.id,
						dateCreated: transaction?.date_created,
						dateUpdated: transaction?.date_updated,
						status: transaction?.status,
						amount: transaction?.amount
							.toString()
							.replace(/(\d)(\d{2})$/, "$1.$2"),
						paymentMethod: transaction?.payment_method,
						cardBrand: transaction?.card_brand,
						cardLastDigits: transaction?.card_last_digits,
						name: transaction?.customer?.name,
						typeDocument: transaction?.customer?.document_type,
						documentNumber: transaction?.customer?.document_number,
						number: transaction?.customer?.documents[0]?.number,
						email: transaction?.customer?.email,
					};
					setListTransactions((prevState) => [...prevState, data]);
				});
			} else {
				response.data.forEach((transaction, index) => {
					const data = {
						id: transaction?.id,
						dateCreated: transaction?.date_created,
						dateUpdated: transaction?.date_updated,
						status: transaction?.status,
						amount: transaction?.amount
							.toString()
							.replace(/(\d)(\d{2})$/, "$1.$2"),
						paymentMethod: transaction?.payment_method,
						cardBrand: transaction?.card_brand,
						cardLastDigits: transaction?.card_last_digits,
						name: transaction?.customer?.name,
						typeDocument: transaction?.customer?.document_type,
						documentNumber: transaction?.customer?.document_number,
						number: transaction?.customer?.documents[0]?.number,
						email: transaction?.customer?.email,
					};
					setListTransactions((prevState) => [...prevState, data]);
				});
			}
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	const getListPayables = async (page) => {
		setLoading(true);
		const queryParams = `?count=${payableFilters?.rowsPerPage}&page=${page}${
			payableFilters?.timeStatusFilter
				? `&status=${payableFilters?.timeStatusFilter}`
				: ""
		}${
			payableFilters?.statusFilter
				? `&type=${payableFilters?.statusFilter}`
				: ""
		}&payment_date=>=${payableFilters?.startDate}&payment_date=<=${
			payableFilters?.endDate
		}&recipient_id=${localStorage.getItem("recipientId")}`;
		const body = {
			domain: `https://api.pagar.me/`,
			resource: `1/payables${queryParams}`,
			subject: `pagarme`,
			method: `get`,
		};

		try {
			const response = await APIV1.post(
				`im/proxy/${localStorage.getItem("client_id").toLowerCase()}`,
				body
			);
			setListPayables([]);

			await response.data.forEach((payables) => {
				let fee = Math.abs(payables?.fee) || 0;
				let anticipationFee = Math.abs(payables?.anticipation_fee) || 0;
				let fraudCoverageFee = Math.abs(payables?.fraud_coverage_fee) || 0;
				let feeAmount = fee + anticipationFee + fraudCoverageFee;

				// TODO when payables.amount is negative, the subTotal adds feeAmout so that the table has the same behavior as the Excel export.
				// Needs better analysis to know the correct behavior, and change it both at financial tables and export if necessary
				let subTotal =
					payables.amount < 0
						? payables.amount + feeAmount
						: payables.amount - feeAmount;

				const data = {
					id: payables?.id,
					transactionId: payables?.transaction_id,
					dateCreated: payables?.date_created,
					paymentDate: payables?.payment_date,
					type: payables?.type,
					status: payables?.status,
					paymentMethod: payables?.payment_method,
					installment: payables?.installment,
					amount: payables?.amount,
					fee: fee,
					subTotal: subTotal || 0,
					anticipationFee: anticipationFee,
					fraudCoverageFee: fraudCoverageFee,
					feeAmount: feeAmount,
				};
				setListPayables((prevState) => [...prevState, data]);
			});

			setLoading(false);
		} catch (errors) {
			console.log(errors);
			setLoading(false);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	const openModal = (updatefilterData, filterData) => {
		setReloadFilter(filterData);
		setUpdateFilterData(updatefilterData);
		setOpenFilterModal(true);
	};

	const getListAnticipations = async (page) => {
		setLoading(true);
		const queryParams = `?count=${
			anticipationFilters?.rowsPerPage || ""
		}&page=${page}${
			anticipationFilters?.statusFilter
				? `&status=${anticipationFilters?.statusFilter}`
				: ""
		}&payment_date=>=${anticipationFilters?.startDate}&payment_date=<=${
			anticipationFilters?.endDate
		}`;
		const body = {
			domain: `https://api.pagar.me/`,
			resource: `1/recipients/${localStorage.getItem(
				"recipientId"
			)}/bulk_anticipations${queryParams}`,
			subject: `pagarme`,
			method: `get`,
		};

		setTimeout(async () => {
			try {
				const response = await APIV1.post(
					`im/proxy/${localStorage.getItem("client_id").toLowerCase()}`,
					body
				);
				setListAnticipations([]);
				response.data.forEach((anticipation) => {
					let subTotal =
						anticipation.amount -
						(anticipation.fee + anticipation.anticipation_fee);

					const data = {
						id: anticipation.id,
						dateCreated: anticipation.date_created,
						dateUpdated: anticipation.date_updated,
						paymentDate: anticipation.payment_date,
						type: anticipation.type,
						status: anticipation.status,
						amount: anticipation.amount,
						anticipation_fee: anticipation.anticipation_fee,
						fee: anticipation.fee,
						receive: subTotal,
						isFinalized:
							anticipation.status === "pending"
								? false
								: anticipation.status === "approved"
								? true
								: anticipation.status === "refused"
								? false
								: anticipation.status === "building"
								? false
								: anticipation.status === "canceled"
								? true
								: false,
					};
					setListAnticipations((prevState) => [...prevState, data]);
				});
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			}
		}, 1000);
	};

	const confirmAnticipation = async (verification, requestedAmount) => {
		setLoading(true);
		const data = {
			payment_date: `${new Date(verification.anticipationDate).getTime()}`,
			timeframe: `${verification.isPayablesFar === false ? "start" : "end"}`,
			requested_amount: requestedAmount,
		};

		const body = {
			domain: `https://api.pagar.me/`,
			resource: `1/recipients/${localStorage.getItem(
				"recipientId"
			)}/bulk_anticipations`,
			subject: `pagarme`,
			method: `post`,
			body: JSON.stringify(data),
		};
		try {
			const response = await APIV1.post(
				`im/proxy/${localStorage.getItem("client_id").toLowerCase()}`,
				body
			);
			const confirm = {
				domain: `https://api.pagar.me/`,
				resource: `1/recipients/${localStorage.getItem(
					"recipientId"
				)}/bulk_anticipations/${response.data.id}/confirm`,
				subject: `pagarme`,
				method: `post`,
				body: "",
			};
			localStorage.setItem("anticipations", true);
			try {
				const resp = await APIV1.post(
					`im/proxy/${localStorage.getItem("client_id").toLowerCase()}`,
					confirm
				);
				window.location.href = "/financial/anticipations";
			} catch (e) {
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			}
		} catch (errors) {
			console.log(errors);
			setLoading(false);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	const cancelAnticipation = async (bulkAnticipationId) => {
		const body = {
			domain: `https://api.pagar.me/`,
			resource: `1/recipients/${localStorage.getItem(
				"recipientId"
			)}/bulk_anticipations/${bulkAnticipationId.id}/cancel`,
			subject: `pagarme`,
			method: `post`,
			body: "",
		};

		try {
			const response = await APIV1.post(
				`im/proxy/${localStorage.getItem("client_id").toLowerCase()}`,
				body
			);
			setLoading(true);
			getListAnticipations(1);
		} catch (errors) {
			console.log(errors);
			setLoading(false);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	const getStoreList = async () => {
		try {
			let response = await APIV2.get(
				"/management/retailer/autocomplete/sellers"
			);

			setSeller(response.data[0].id);

			getRecipientId(response.data[0].id);

			await response.data.forEach((data) => {
				setStoreList((prevState) => [
					...prevState,
					{ value: data.id, label: data.fullName },
				]);
			});
		} catch (error) {
			NewToastComponent({
				status: "error",
				title: i18n.t("financial.ErrorMessage.ErrorGetListSellers"),
			});
		}
	};

	const ErrorGatewayId = (id) => {
		return insertHtml(
			i18n.t("financial.ErrorMessage.ErrorGetSeller"),
			i18n.t("financial.ErrorMessage.ClickHere"),
			<a href={`/stores/1/edit/${id}`} style={{ textDecoration: "blink" }}>
				{i18n.t("financial.ErrorMessage.ClickHere")}
			</a>
		);
	};

	const getCurrentFinancialTab = () => {
		const splittedPathname = location.pathname.split("/");
		const financialTab = splittedPathname[splittedPathname.length - 1];
		return financialTab;
	};

	const getRecipientId = async (id) => {
		setLoading(true);
		try {
			let data = await retailerManagementApi.getSeller(id);

			localStorage.setItem("recipientId", data.gatewayId);

			if (StringValidator.isNullOrWhitespace(data.gatewayId)) {
				setHasRecepientId(false);
				setLoading(false);
				setListPayables([]);
				setSeller(id);
				return NewToastComponent({
					status: "error",
					title: ErrorGatewayId(id),
				});
			}

			setHasRecepientId(true);
			setSeller(id);
			getBalance();
			getBankAccount();

			const financialTab = getCurrentFinancialTab();
			if (financialTab === "payables") {
				getTotalListPayables();
				getListPayables(1);
				return;
			}
			if (financialTab === "withdraw") {
				getTotalListWithdraw();
				getListWithdraw(1);
				return;
			}
			getTotalListAnticipation();
			getListAnticipations(1);
		} catch (error) {
			setLoading(false);
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.ERR.REQUEST"),
			});
		}
	};

	return (
		<FinancialProvider.Provider
			value={{
				loading,
				setLoading,
				available,
				waitingFunds,
				getBalance,
				listStatement,
				listPayables,
				getListStatement,
				listWithdraw,
				listTransactions,
				listAnticipations,
				createNewWithdraw,
				getListWithdraw,
				getListTransactions,
				getSpecificStatement,
				getListPayables,
				getListAnticipations,
				itemDetails,
				cancelWithdraw,
				bankAccount,
				oneTrafer,
				openModal,
				reloadFilter,
				updateFilterData,
				confirmAnticipation,
				cancelAnticipation,
				statementFilters,
				setStatementFilters,
				payableFilters,
				setPayableFilters,
				withdrawalFilters,
				setWithdrawalFilters,
				anticipationFilters,
				setAnticipationFilters,
				statementExport,
				withdrawExport,
				anticipationExport,
				payablesExport,
				totalPayablesAmount,
				getTotalListWithdraw,
				getTotalListStatement,
				getTotalListAnticipation,
				getTotalListPayables,
				storeList,
				getStoreList,
				getRecipientId,
				seller,
				hasRecepientId,
			}}
		>
			{props.children}
		</FinancialProvider.Provider>
	);
}

export const useFinancial = () => useContext(FinancialProvider);
