import React from "react";

import { Switch, Route } from "react-router-dom";
import ConditionList from "./components/ConditionsList/ConditionList";
import ConditionForm from "./components/ConditionForm/ConditionForm";

export default function CommercialConditions() {
	return (
		<Switch>
			<Route
				path="/configurations/payment/commercial-conditions"
				exact
				children={<ConditionList />}
			/>
			<Route
				key={"newCondition"}
				path="/configurations/payment/commercial-conditions/new"
				exact
				children={<ConditionForm />}
			/>
			<Route
				key={"editCondition"}
				path="/configurations/payment/commercial-conditions/:id"
				exact
				children={<ConditionForm />}
			/>
		</Switch>
	);
}
