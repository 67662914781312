import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import TroqueCommerceList from "./TroqueCommerceList/TroqueCommerceList";
import TCDetails from "./TCDetails/TCDetails";

export default function TroqueCommerce() {
	return (
		<div className="container dash-content">
			<Switch>
				<Route
					path="/troque-commerce"
					exact
					children={<Redirect to="/troque-commerce/1" />}
				/>
				<Route
					path="/troque-commerce/:page"
					exact
					children={<TroqueCommerceList />}
				/>
				<Route path="/troque-commerce/:page/:id" children={<TCDetails />} />
			</Switch>
		</div>
	);
}
