import React, { useEffect, useState } from "react";
import { useVouchers } from "../providers/VouchersContext";
import Button from "../../../../../components/Button";
import DatePickerNeo from "../../../../../components/DatePickerNeo";
import MaskPrice from "../../../../../components/MaskPrice";
import CurrencyFormat from "react-currency-format";
import VouchersConditions from "./VouchersConditions";
import ContentLoader from "react-content-loader";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function VouchersForm({ id }) {
	const {
		disable,
		loading,
		voucher,
		setVoucher,
		setLoading,
		getNewVouchers,
		createCupom,
		updateCupom,
		validateCupons,
		handleVoucherHour,
		editedStartDate,
		setEditedStartDate,
		editedEndDate,
		setEditedEndDate,
		requestCompleted,
	} = useVouchers();
	const [editing, setEditing] = useState(false);
	const [discount, setDiscount] = useState("numericalValue");
	const {
		register,
		handleSubmit,
		reset,
		resetField,
		control,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			code: voucher.code,
			usageLimit: voucher.usageLimit,
			startDate: new Date(voucher.startDate),
			endDate: new Date(voucher.endDate),
		},
	});

	useEffect(() => {
		getNewVouchers(id);
		if (id) {
			setEditing(true);
		} else {
			setEditing(false);
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		if (editing && requestCompleted) {
			reset({
				...voucher,
				startDate:
					editing && !editedStartDate
						? handleVoucherHour(voucher.startDate)
						: new Date(voucher.startDate),
				endDate:
					editing && !editedEndDate
						? voucher.endDate < voucher.startDate
							? handleVoucherHour(voucher.startDate)
							: handleVoucherHour(voucher.endDate)
						: voucher.endDate < voucher.startDate
						? new Date(voucher.startDate)
						: new Date(voucher.endDate),
			});
		}
	}, [requestCompleted]);

	const randomCode = (event) => {
		event.preventDefault();
		let text = "";
		const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
		for (let i = 0; i < 6; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		resetField("code", { defaultValue: text });
	};

	const handleDiscountValue = (data) => {
		if (discount === "numericalValue") {
			let formattedValue = data.discountValue;
			formattedValue = formattedValue.replace(/\D/g, "");
			formattedValue = formattedValue.replace(/(\d)(\d{2})$/, "$1.$2");
			data.discountValue = formattedValue;
			data.discountPercentage = "";
		}

		if (discount === "percentage") {
			let removePercentage = data.discountPercentage;
			removePercentage = removePercentage.replace("%", "");
			data.discountPercentage = removePercentage;
			data.discountValue = "";
		}
	};

	const handleFormSubmit = (data) => {
		!editing && handleDiscountValue(data);
		let formVoucher = {
			...voucher,
			code: data.code,
			usageLimit: data.usageLimit,
			...(discount === "numericalValue" && {
				discountValue: data.discountValue,
			}),
			...(discount === "percentage" && {
				discountPercentage: data.discountPercentage,
			}),
			freeShipping: data.freeShipping,
			isApplyInTotalCart: data.isApplyInTotalCart,
			startDate: data.startDate,
			endDate: data.endDate,
			isNotApplyProductReprice: data.isNotApplyProductReprice,
			isActive: data.isActive,
		};
		editing ? updateCupom(formVoucher) : createCupom(formVoucher);
	};

	const skeleton = () => {
		return (
			<>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", marginBottom: "35px" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="335" height="40" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", marginBottom: "35px" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="335" height="40" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", marginBottom: "35px" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="335" height="40" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", marginBottom: "10px" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="40" height="20" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", marginBottom: "35px" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="40" height="20" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", marginBottom: "35px" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="180" height="40" />
					<rect x="220" y="25" rx="3" ry="3" width="180" height="40" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", marginBottom: "35px" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="180" height="40" />
				</ContentLoader>
			</>
		);
	};

	const renderScreen = () => {
		return (
			<div className="card-content">
				<div className="card-body pt-0">
					<div className="card-header ml-0 pl-0">
						<h4 className="card-title">
							{editing ? "Editar cupom de desconto" : "Novo cupom de desconto"}
						</h4>
					</div>
					<form
						onKeyPress={(e) => {
							e.key === "Enter" && e.preventDefault();
						}}
						onSubmit={handleSubmit(handleFormSubmit)}
					>
						<div className="d-flex">
							{/* codigo do cupom */}
							<div className="form-group row" style={{ width: "365px" }}>
								<label className="col-12" htmlFor="code">
									<strong>Código do cupom</strong>
								</label>
								<div className="col-sm-12">
									<div className="neo-form mb-0">
										<div className="neo-form-input">
											<input
												maxLength="10"
												className={`${
													errors.code ? "neo-input-invalid" : "neo-input-valid"
												}${editing ? " input-disabled" : ""}`}
												id="code"
												placeholder="ABC123456"
												name="code"
												disabled={editing}
												{...register("code", { required: true })}
											/>
											{errors.code && (
												<div className="neo-icons-required">
													<button type="button">
														<i className="material-icons">error_outline</i>
													</button>
												</div>
											)}
										</div>
									</div>
									{errors.code && (
										<small className="text-danger">
											<em>Campo Obrigatório</em>
										</small>
									)}
								</div>
							</div>
							{!editing && (
								<div className="vouchers-help-tooltip mt-2 pt-4 ml-3">
									<div className="tooltipVouchers pr-0">
										<button onClick={randomCode}>
											<i
												className="material-icons helper"
												style={{ fontSize: "25px" }}
											>
												loop
											</i>
										</button>
										<span className="helper-tooltip">
											<p>Gerar código de cupom</p>
										</span>
									</div>
								</div>
							)}

							{/* isActive */}
							<div className="form-group ml-2">
								<label className="" htmlFor="isActive">
									<strong>Cupom ativo</strong>
								</label>
								<br />
								<div className="mt-1">
									<label className="switch">
										<input
											id="isActive"
											type="checkbox"
											name="isActive"
											defaultChecked={voucher.isActive}
											{...register("isActive")}
										/>
										<span className="slider round"></span>
									</label>
								</div>
							</div>
						</div>

						{/* limite de uso */}
						<div className="form-group row">
							<label className="col-12" htmlFor="usageLimit">
								<strong>Limite de uso</strong>
							</label>
							<div className="col-sm-4">
								<div className="neo-form mb-0">
									<div className="neo-form-input">
										<input
											type="number"
											className={`${
												errors.usageLimit
													? "neo-input-invalid"
													: "neo-input-valid"
											}${editing ? " input-disabled" : ""}`}
											id="usageLimit"
											name="usageLimit"
											placeholder="0"
											disabled={editing}
											{...register("usageLimit", {
												min: {
													value: 0,
													message: "O valor deve ser maior ou igual a 0",
												},
											})}
										/>
									</div>
								</div>
								{errors.usageLimit && (
									<small className="text-danger">
										<em>{errors.usageLimit?.message}</em>
									</small>
								)}
							</div>
							<div className="col-sm-8 d-flex align-items-center">
								<small id="emailHelp" className="text-muted">
									0 = ilimitado
								</small>
							</div>
						</div>
						{/* desconto */}
						<div className="form-group row">
							<label className="col-12" htmlFor="desconto">
								<strong>Desconto</strong>
							</label>
							<div className="input-group col-sm-5">
								<div className="input-group-prepend">
									<label htmlFor="inputGroupSelect01">
										{editing ? (
											voucher.discountPercentage ? (
												<p className="custom-select btn-light">Porcentagem</p>
											) : (
												<p className="custom-select btn-light">Valor</p>
											)
										) : (
											<select
												className="custom-select btn-light"
												style={{ height: "40px" }}
												value={discount}
												onChange={(event) => setDiscount(event.target.value)}
											>
												<option selected value="numericalValue">
													Valor
												</option>
												<option value="percentage">Porcentagem</option>
											</select>
										)}
									</label>
								</div>
								{editing ? (
									voucher.discountValue ? (
										<CurrencyFormat
											value={String(voucher.discountValue)}
											displayType={"input"}
											name="discountValue"
											disabled
											className="form-control disabled"
											decimalSeparator={"."}
											prefix={"R$"}
											onValueChange={(values) => {
												const { formattedValue, value } = values;
												voucher.discountValue = parseFloat(value);
											}}
										/>
									) : (
										<CurrencyFormat
											value={String(voucher.discountPercentage)}
											suffix={"%"}
											format={"##%"}
											disabled
											className="form-control disabled"
											name="discountPercentage"
											thousandSeparator={true}
											onValueChange={(values) => {
												const { formattedValue, value } = values;
												voucher.discountPercentage = parseFloat(value);
											}}
										/>
									)
								) : discount === "numericalValue" ? (
									<div
										className="input-group-prepend d-flex flex-nowrap"
										style={{ height: "40px" }}
									>
										<div
											className="input-group-text"
											style={{ fontSize: "13px", fontWeight: "500" }}
										>
											R$
										</div>
										<div className="neo-form mb-0">
											<div className="neo-form-input">
												<Controller
													name="discountValue"
													control={control}
													rules={{ required: true }}
													render={({ field }) => (
														<MaskPrice
															className={
																errors.discountValue
																	? "neo-input-invalid"
																	: "neo-input-valid"
															}
															defaultValue={String(
																voucher.discountValue.toLocaleString("pt-br", {
																	minimumFractionDigits: 2,
																})
															)}
															onChange={(e) => field.onChange(e)}
															vouchersForm={true}
															// onChange={handleDiscountValue}
														/>
													)}
												/>
												{errors.discountValue && (
													<div className="neo-icons-required">
														<button type="button">
															<i className="material-icons">error_outline</i>
														</button>
													</div>
												)}
											</div>
											{errors.discountValue && (
												<small className="text-danger">
													<em>Campo Obrigatório</em>
												</small>
											)}
										</div>
									</div>
								) : (
									<div className="neo-form voucher-input-percentage mb-0">
										<div className="neo-form-input">
											<Controller
												name="discountPercentage"
												control={control}
												rules={{ required: true }}
												render={({ field }) => (
													<CurrencyFormat
														defaultValue={String(voucher.discountPercentage)}
														suffix={"%"}
														format={"##%"}
														className={
															errors.discountPercentage
																? "neo-input-invalid"
																: "neo-input-valid"
														}
														thousandSeparator={true}
														onChange={(e) => field.onChange(e)}
														// onValueChange={handleDiscountValue}
													/>
												)}
											/>
											{errors.discountPercentage && (
												<div
													className="neo-icons-required"
													style={{ height: "fit-content" }}
												>
													<button type="button d-flex align-items-center ">
														<i className="material-icons mt-1">error_outline</i>
													</button>
												</div>
											)}
										</div>
										{errors.discountPercentage && (
											<small className="text-danger">
												<em>Campo Obrigatório</em>
											</small>
										)}
									</div>
								)}
							</div>
						</div>
						{/* isNotApplyProductReprice */}
						<div className="row mb-2">
							<div className="custom-control custom-checkbox col-12 mx-3">
								<input
									id="isNotApplyProductReprice"
									type="checkbox"
									disabled={editing}
									className="custom-control-input"
									defaultChecked={voucher.isNotApplyProductReprice}
									{...register("isNotApplyProductReprice")}
								/>
								<label
									className="custom-control-label"
									for="isNotApplyProductReprice"
								>
									Incluir produtos promocionais no cupom
								</label>
							</div>
						</div>
						{/* isApplyInTotalCart */}
						<div className="mb-2 d-flex flex-row">
							<div className="custom-control custom-checkbox mr-3">
								<input
									id="isApplyInTotalCart"
									type="checkbox"
									disabled={editing}
									className="custom-control-input"
									defaultChecked={voucher.isApplyInTotalCart}
									{...register("isApplyInTotalCart")}
								/>
								<label
									className="custom-control-label"
									for="isApplyInTotalCart"
								>
									Aplicar desconto ao valor total da compra
								</label>
							</div>
							<div className="vouchers-help-tooltip">
								<div className="tooltipVouchers">
									<button disabled>
										<i
											className="material-icons helper"
											style={{ fontSize: "18px" }}
										>
											help_outline
										</i>
									</button>
									<span className="helper-tooltip">
										<p>
											Atenção, o desconto será aplicado tanto no produto quanto
											no frete !
										</p>
									</span>
								</div>
							</div>
						</div>
						{/* isApplyInTotalCart */}
						<div className="row mb-2">
							<div className="custom-control custom-checkbox col-12 mx-3">
								<input
									id="freeShipping"
									type="checkbox"
									disabled={editing}
									className="custom-control-input"
									defaultChecked={voucher.freeShipping}
									{...register("freeShipping")}
								/>
								<label className="custom-control-label" for="freeShipping">
									Adicionar frete grátis ao cupom
								</label>
							</div>
						</div>
						{/* datas */}
						<div className="form-group d-flex flex-wrap mt-4">
							<label className="w-100">
								<strong>Vigência</strong>
							</label>
							<br />
							<div className="mr-5">
								<Controller
									name="startDate"
									control={control}
									render={({ field }) => (
										<DatePickerNeo
											label="Data de início"
											dateFormat="dd/MM/yyyy HH:mm"
											time={true}
											selected={field.value}
											onChange={(date) => {
												setEditedStartDate(true);
												field.onChange(date);
												// field.onChange(new Date(e));
											}}
											minDate={new Date()}
										/>
									)}
								/>
							</div>
							<div className="" style={{ zIndex: "1000" }}>
								<Controller
									name="endDate"
									control={control}
									render={({ field }) => (
										<DatePickerNeo
											label="Data de término"
											dateFormat="dd/MM/yyyy HH:mm"
											time={true}
											selected={field.value}
											onChange={(date) => {
												setEditedEndDate(true);
												field.onChange(date);
												// field.onChange(new Date(e));
											}}
											minDate={new Date()}
										/>
									)}
								/>
							</div>
						</div>

						<VouchersConditions editing={editing} id={id} />

						<div className="form-group mt-5">
							<Button
								type="submit"
								className="btn btn-success"
								loading={disable}
								disable={disable}
							>
								{editing ? "Editar cupom" : "Criar cupom"}
							</Button>
							<Link
								to={"/vouchers/1"}
								className="btn btn-new-width btn-light ml-3"
							>
								Cancelar
							</Link>
						</div>
					</form>
				</div>
			</div>
		);
	};
	return <>{loading ? skeleton() : renderScreen()}</>;
}
