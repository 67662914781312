import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import moment from "moment";

export default function SingleDateModal({ handleDateModal, isModal }) {
	const [isSelected, setIsSelected] = useState(false);
	const [singleDate, setSingleDate] = useState("");

	const handleDate = (value) => {
		setSingleDate(value);
		setIsSelected(true);
	};

	const confirmDate = () => {
		handleDateModal(singleDate);
		const button = document.getElementById("singleDateModal");
		button.click();
		closeCalendar();
	};

	const closeCalendar = () => {
		document.querySelector("#singleDateModal").classList.remove("show");
	};

	return (
		<div
			className="modal fade m-0 p-0"
			id="singleDateModal"
			tabIndex="-1"
			aria-labelledby="singleDateModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="">
						<div className="">
							<div className="p-2">
								<div className="btn datepicker-header-text-from btn-icon-r w-100">
									<i className="material-icons">event_note</i>
									De:{" "}
									<span style={{ color: "#7A9FEB" }}>
										{singleDate && moment(singleDate).format("DD/MM/YYYY")}
									</span>
								</div>
							</div>
							<div
								className={`datepicker-calendar-view isPerPeriod w-100 m-0 p-0`}
							>
								<Calendar
									clearIcon={""}
									closeCalendar={false}
									disabled={false}
									disableCalendar={false}
									maxDetail="month"
									minDetail="month"
									selectRange={false}
									showNavigation={true}
									prev2Label=""
									next2Label=""
									onChange={(value) => handleDate(value)}
									activeStartDate={singleDate}
								/>
							</div>
							<div className="datepicker-footer">
								<button
									type="button"
									data-dismiss={isModal ? "" : "modal"}
									onClick={closeCalendar}
									moda
									className="btn"
									style={{
										color: "#5A5E61",
										border: "1px solid #8D8D8D",
										backgroundColor: "transparent",
									}}
								>
									Cancelar
								</button>
								<button
									type="button"
									data-dismiss={isModal ? "" : "modal"}
									onClick={confirmDate}
									disabled={!isSelected}
									className="btn btn-success ml-2 datepicker-footer-confirm"
								>
									Confirmar
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
