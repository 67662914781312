import React, { useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import useWorkingHoursService from "./WorkingHoursService";
import { i18n } from "../../../../translate/i18n";
import { DayOfWeekType } from "../../../../domain/enums/DayOfWeekType";
import {
	Box,
	Button,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { Dayjs } from "dayjs";
import WorkingHoursSkeleton from "./WorkingHoursSkeleton";

export default function WorkingHours({ sellerId }: { sellerId: string }) {
	const {
		// Props
		isLoading,
		workingHoursList,
		defaultWorkingHourState,
		// Functions
		onChangeOpeningHours,
		onChangeClosingHours,
		getWorkingHours,
		upsertWorkingHours,
	} = useWorkingHoursService();

	useEffect(() => {
		getWorkingHours(sellerId);
	}, []);

	return (
		<Box
			m={4}
			minHeight={335}
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
		>
			<Box>
				<Typography variant="h5" pb={3} pl={0} fontWeight="bold">
					Horário de funcionamento
				</Typography>

				{isLoading ? (
					WorkingHoursSkeleton()
				) : (
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell sx={{ fontWeight: "bold" }}>
										Dia da semana
									</TableCell>
									<TableCell sx={{ fontWeight: "bold" }}>Abertura</TableCell>
									<TableCell sx={{ fontWeight: "bold" }}>Fechamento</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{workingHoursList.map((day, index) => (
									<TableRow key={index}>
										<TableCell width={200} sx={{ fontWeight: "bold" }}>
											{i18n
												.t(`stores.DaysOfWeek.${DayOfWeekType[day.dayWeek]}`)
												.toString()}
										</TableCell>
										<TableCell>
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DemoContainer components={["TimeField"]}>
													<Box width={130}>
														<TimeField
															size="small"
															color="secondary"
															value={
																day.opensAtAsDayjs ?? defaultWorkingHourState
															}
															onChange={(time: Dayjs | null) =>
																onChangeOpeningHours(time, day.dayWeek)
															}
															format="HH:mm"
														/>
													</Box>
												</DemoContainer>
											</LocalizationProvider>
										</TableCell>
										<TableCell>
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DemoContainer components={["TimeField"]}>
													<Box width={130}>
														<TimeField
															size="small"
															color="secondary"
															value={
																day.closesAtAsDayjs ?? defaultWorkingHourState
															}
															onChange={(time: Dayjs | null) =>
																onChangeClosingHours(time, day.dayWeek)
															}
															format="HH:mm"
														/>
													</Box>
												</DemoContainer>
											</LocalizationProvider>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</Box>

			<Stack direction="row" justifyContent="end" mt={3}>
				<Button
					color="success"
					variant="contained"
					disabled={isLoading}
					onClick={async () =>
						await upsertWorkingHours(sellerId, workingHoursList)
					}
				>
					Salvar
				</Button>
			</Stack>
		</Box>
	);
}
