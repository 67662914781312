import React from "react";
import { useOrders } from "../../providers/OrdersContext";
import CreateApprovalStatus from "./CreateApprovalStatus";
import CreateDeliveryStatus from "./CreateDeliveryStatus";
import CreateFinalizedStatus from "./CreateFinalizedStatus";
import CreateInvoiceStatus from "./CreateInvoiceStatus";
import CreateDispatchStatus from "./CreateDispatchStatus";
import CreateSeparateStatus from "./CreateSeparateStatus";
import OrderFlowModal from "../OrderModal/OrderFlowModal";
import { i18n } from "../../../../translate/i18n";
import { formatDate } from "../../../../infrastructure/utils/FormatMask";
import SkeletonFlowStatus from "./SkeletonFlowStatus";
import { insertHtml } from "../../../../infrastructure/utils/InsertHtml";
import { OrderStatus } from "../../../../domain/enums/OrderStatus";
import FixedAlertComponent from "../../../../components/FixedAlertComponent/FixedAlertComponent";
import { Box, Typography } from "@mui/material";
export default function OrderFlow({ historic }) {
	const { order, flowStatus, loading, orderModel } = useOrders();
	const arr = [0, 1, 2, 3, 4, 5];

	const position = flowStatus?.enqueueOrderPosition;
	const isProcessing = flowStatus?.isProcessing;
	const isError = flowStatus?.lastRanWithError;
	const isSuccessfull = flowStatus?.isSuccessfull;
	const nextStep = flowStatus?.hasNextActionAvailable;
	const actions = flowStatus?.lastUpdate?.length;

	const handleHistoric = (bool) => {
		historic(bool);
	};

	const getTypeInfo = (data) => {
		const typeinfoDict = {
			ClickToConfirm: i18n.t(`orderDetails.OrderFlowInfo.ClickToConfirm`),
			ClickToFinish: i18n.t(`orderDetails.OrderFlowInfo.ClickToFinish`),
			ClickToCancel: i18n.t(`orderDetails.OrderFlowInfo.ClickToCancel`),
			IntegrationFailure: i18n.t(
				`orderDetails.OrderFlowInfo.IntegrationFailure`
			),
			TryAgain: i18n.t(`orderDetails.OrderFlowInfo.TryAgain`),
			Waiting: i18n.t(`orderDetails.OrderFlowInfo.Waiting`),
			WaitingForIntegration: i18n.t(
				`orderDetails.OrderFlowInfo.WaitingForIntegration`
			),
			Integration: i18n.t(`orderDetails.OrderFlowInfo.Integration`),
			Processing: i18n.t(`orderDetails.OrderFlowInfo.Processing`),
			InCancellation: i18n.t(`orderDetails.OrderFlowInfo.InCancellation`),
			ChangeSeller: i18n.t(`orderDetails.OrderFlowInfo.ChangeSeller`),
			NotStarted: i18n.t(`orderDetails.Status.NotStarted`),
			ProcessingRetry: i18n.t(`orderDetails.OrderFlowInfo.ProcessingRetry`),
			WaitAMinute: i18n.t(`orderDetails.OrderFlowInfo.WaitAMinute`),
		};
		return typeinfoDict[data] || formatDate(data);
	};

	const getTypeIcons = (index) => {
		const typeIconDict = {
			CheckCircle: "check_circle",
			AddCircleOutline: "add_circle_outline",
			AllInbox: "all_inbox",
			ReceiptLong: "receipt_long",
			Mail: "mail",
			CardGiftcard: "card_giftcard",
			Cancel: "cancel",
			MarkEmailRead: "mark_email_read",
			ShoppingBag: "shopping_bag",
			SwapHoriz: "swap_horiz",
			Refresh: "refresh",
		};
		return typeIconDict[index] || "watch_later";
	};

	const getButtonStyle = (style) => {
		const styleButtonDict = {
			Success: "success",
			SuccessOutline: "success-outline",
			Warning: "warning",
			WarningOutline: "warning-outline",
			Danger: "danger",
			DangerOutline: "danger-outline",
			Completed: "completed",
			Canceled: "canceled",
			RetryOutline: "retry-outline",
		};
		return styleButtonDict[style] || "empty";
	};

	const getTitleButton = (title) => {
		const titleButtonDict = {
			Processing: i18n.t(`orderDetails.Status.Processing`),
			CreatedCompleted: i18n.t(`orderDetails.Status.CreatedCompleted`),
			WaitingApprovalPending: i18n.t(
				`orderDetails.Status.WaitingApprovalPending`
			),
			WaitingApprovalAction: i18n.t(
				`orderDetails.Status.WaitingApprovalAction`
			),
			WaitingApprovalCompleted: i18n.t(
				`orderDetails.Status.WaitingApprovalCompleted`
			),
			WaitingSeparationPending: i18n.t(
				`orderDetails.Status.WaitingSeparationPending`
			),
			WaitingSeparationAction: i18n.t(
				`orderDetails.Status.WaitingSeparationAction`
			),
			WaitingSeparationCompleted: i18n.t(
				`orderDetails.Status.WaitingSeparationCompleted`
			),
			WaitingInvoicePending: i18n.t(
				`orderDetails.Status.WaitingInvoicePending`
			),
			WaitingInvoiceAction: i18n.t(`orderDetails.Status.WaitingInvoiceAction`),
			WaitingInvoiceCompleted: i18n.t(
				`orderDetails.Status.WaitingInvoiceCompleted`
			),
			WaitingDispatchWithdrawalPending: i18n.t(
				`orderDetails.Status.WaitingDispatchWithdrawalPending`
			),
			WaitingDispatchWithdrawalAction: i18n.t(
				`orderDetails.Status.WaitingDispatchWithdrawalAction`
			),
			WaitingDispatchWithdrawalCompleted: i18n.t(
				`orderDetails.Status.WaitingDispatchWithdrawalCompleted`
			),
			WaitingDispatchPending: i18n.t(
				`orderDetails.Status.WaitingDispatchPending`
			),
			WaitingDispatchAction: i18n.t(
				`orderDetails.Status.WaitingDispatchAction`
			),
			WaitingDispatchCompleted: i18n.t(
				`orderDetails.Status.WaitingDispatchCompleted`
			),
			WaitingDeliveryWithdrawalPending: i18n.t(
				`orderDetails.Status.WaitingDeliveryWithdrawalPending`
			),
			WaitingDeliveryWithdrawalAction: i18n.t(
				`orderDetails.Status.WaitingDeliveryWithdrawalAction`
			),
			WaitingDeliveryWithdrawalCompleted: i18n.t(
				`orderDetails.Status.WaitingDeliveryWithdrawalCompleted`
			),
			WaitingDeliveryPending: i18n.t(
				`orderDetails.Status.WaitingDeliveryPending`
			),
			WaitingDeliveryAction: i18n.t(
				`orderDetails.Status.WaitingDeliveryAction`
			),
			WaitingDeliveryCompleted: i18n.t(
				`orderDetails.Status.WaitingDeliveryCompleted`
			),
			WaitingReturnGoodsPeriod: i18n.t(
				`orderDetails.Status.WaitingReturnGoodsPeriod`
			),
			FinalizedAction: i18n.t(`orderDetails.Status.FinalizedAction`),
			FinalizedCompleted: i18n.t(`orderDetails.Status.FinalizedCompleted`),
			WaitingCancelPeriod: i18n.t(`orderDetails.Status.WaitingCancelPeriod`),
			CancelAction: i18n.t(`orderDetails.Status.CancelAction`),
			CancelRequest: i18n.t(`orderDetails.Status.CancelRequest`),
			CanceledCompleted: i18n.t(`orderDetails.Status.CanceledCompleted`),
			CancelPending: i18n.t(`orderDetails.Status.CancelPending`),
			CancelRetry: i18n.t(`orderDetails.Status.CancelRetry`),
			ReturningGoodsCompleted: i18n.t(
				`orderDetails.Status.ReturningGoodsCompleted`
			),
			Reprocess: i18n.t(`orderDetails.Status.Reprocess`),
		};
		return titleButtonDict[title] || i18n.t(`orderDetails.Status.NotStarted`);
	};

	const getProcessOrder = (currentPosition) => {
		if (isProcessing && !isError && !isSuccessfull && currentPosition)
			return true;
	};

	const getRetryProcessOrder = (currentPosition) => {
		if (isProcessing && isError && !isSuccessfull && currentPosition)
			return true;
	};

	const getFailedProcessOrder = (currentPosition, lastProcessedStep) => {
		if (position === 100 && lastProcessedStep) return true;
		else if (actions === 0) return true;
		else if (!isProcessing && !nextStep && currentPosition) return true;
		else if (!isProcessing && isError && !isSuccessfull && currentPosition)
			return true;
		else return false;
	};

	const getTypeArrow = (src, style) => {
		return (
			<div className="arrow-svg">
				<img src={src} className={`img-svg ${style}`} />
			</div>
		);
	};

	const getFlowStatus = (index) => {
		const createflowStatusDict = {
			0: (
				<CreateApprovalStatus
					indexColumn={index}
					getTypeIcons={getTypeIcons}
					getTitleButton={getTitleButton}
					getTypeInfo={getTypeInfo}
					getButtonStyle={getButtonStyle}
					getTypeArrow={getTypeArrow}
					getProcessOrder={getProcessOrder}
					getRetryProcessOrder={getRetryProcessOrder}
					getFailedProcessOrder={getFailedProcessOrder}
				/>
			),
			1: (
				<CreateSeparateStatus
					indexColumn={index}
					getTypeIcons={getTypeIcons}
					getTitleButton={getTitleButton}
					getTypeInfo={getTypeInfo}
					getButtonStyle={getButtonStyle}
					getTypeArrow={getTypeArrow}
					getProcessOrder={getProcessOrder}
					getRetryProcessOrder={getRetryProcessOrder}
					getFailedProcessOrder={getFailedProcessOrder}
				/>
			),
			2: (
				<CreateInvoiceStatus
					indexColumn={index}
					getTypeIcons={getTypeIcons}
					getTitleButton={getTitleButton}
					getTypeInfo={getTypeInfo}
					getButtonStyle={getButtonStyle}
					getTypeArrow={getTypeArrow}
					getProcessOrder={getProcessOrder}
					getRetryProcessOrder={getRetryProcessOrder}
					getFailedProcessOrder={getFailedProcessOrder}
				/>
			),
			3: (
				<CreateDispatchStatus
					indexColumn={index}
					getTypeIcons={getTypeIcons}
					getTitleButton={getTitleButton}
					getTypeInfo={getTypeInfo}
					getButtonStyle={getButtonStyle}
					getTypeArrow={getTypeArrow}
					getProcessOrder={getProcessOrder}
					getRetryProcessOrder={getRetryProcessOrder}
					getFailedProcessOrder={getFailedProcessOrder}
				/>
			),
			4: (
				<CreateDeliveryStatus
					indexColumn={index}
					getTypeIcons={getTypeIcons}
					getTitleButton={getTitleButton}
					getTypeInfo={getTypeInfo}
					getButtonStyle={getButtonStyle}
					getTypeArrow={getTypeArrow}
					getProcessOrder={getProcessOrder}
					getRetryProcessOrder={getRetryProcessOrder}
					getFailedProcessOrder={getFailedProcessOrder}
				/>
			),
			5: (
				<CreateFinalizedStatus
					indexColumn={index}
					getTypeIcons={getTypeIcons}
					getTitleButton={getTitleButton}
					getTypeInfo={getTypeInfo}
					getButtonStyle={getButtonStyle}
					getTypeArrow={getTypeArrow}
					getProcessOrder={getProcessOrder}
					getRetryProcessOrder={getRetryProcessOrder}
					getFailedProcessOrder={getFailedProcessOrder}
				/>
			),
		};
		return createflowStatusDict[index];
	};

	const translatedText = insertHtml(
		i18n.t(`orderDetails.General.ToastText`),
		i18n.t(`orderDetails.General.ToastTextBold`),
		<strong>&nbsp;{i18n.t(`orderDetails.General.ToastTextBold`)}</strong>
	);

	return (
		<div className="m-0" style={{ padding: "24px 24px 6px 24px" }}>
			<div className="order-header-box">
				<h2
					className="d-flex align-items-center m-0 p-0"
					style={{ fontSize: "24px" }}
				>
					{i18n.t(`orderDetails.OrderInfo.OrderFlowTitle`)}
				</h2>
				<a className="hyper-link pt-1" onClick={() => handleHistoric(true)}>
					{i18n.t(`orderDetails.OrderInfo.OrderFlowLinkHistoric`)}
				</a>
			</div>

			<div className="order-body-box scroll-card m-0 p-0">
				<div className="m-0 p-0" style={{ width: "1062px" }}>
					<p className="text-blueish-md mb-0 py-2">
						{i18n.t(`orderDetails.OrderInfo.OrderFlowText`)}nº{" "}
						{order?.FriendlyCode}
					</p>
					{orderModel?.status === OrderStatus.Canceled && (
						<FixedAlertComponent alertType={"danger"} marginBottom={"16px"}>
							<Box>
								<Typography fontSize={12}>
									<strong>
										{i18n
											.t("orderDetails.OrderInfo.CanceledOrderAlert")
											.toString()}
									</strong>
								</Typography>
								<Typography fontSize={12}>
									<strong>
										{i18n
											.t("orderDetails.OrderInfo.CancellationReason")
											.toString()}
										:
									</strong>{" "}
									{orderModel.cancellationReason}
								</Typography>
							</Box>
						</FixedAlertComponent>
					)}
					{isError && (
						<div
							className="alert alert-danger alert-dismissible d-flex align-items-center mb-4"
							role="alert"
						>
							<i className="material-icons pr-2">error_outline</i>
							{translatedText}
						</div>
					)}

					{loading ? (
						<SkeletonFlowStatus />
					) : (
						<div className="row">
							{arr.map((i) => (
								<div key={i} id={i} className="col-2 m-0">
									{getFlowStatus(i)}
								</div>
							))}
						</div>
					)}
				</div>
			</div>
			<OrderFlowModal />
		</div>
	);
}
