import { Avatar, Box, IconButton, Stack } from "@mui/material";
import React from "react";
import { OpenInNew } from "@mui/icons-material";
import { Link } from "react-router-dom";
import CustomIcon from "../MUICustomComponents/CustomIcon";
import {
	PaletteDiscriminatedUnion,
	paletteObject,
} from "../theme/foundations/palette";
import CustomSkeleton from "../MUICustomComponents/CustomSkeleton";
import CustomTypography from "../MUICustomComponents/CustomTypography";

export type GenericStatusCardProps<T> = {
	icon: JSX.Element;
	bgColor: PaletteDiscriminatedUnion;
	color: PaletteDiscriminatedUnion;
	statusId: T;
	label: string;
	link: string;
};

type Props<T> = {
	cardInfo: GenericStatusCardProps<T>;
	counter: number;
	aditionalLinkPath: string;
	isLoading: boolean;
};

export default function GenericStatusCard<T>({
	cardInfo,
	counter,
	aditionalLinkPath,
	isLoading,
}: Props<T>) {
	return (
		<Stack
			direction="row"
			alignItems="center"
			border="2px solid #F1F1F2"
			borderRadius="5px"
			width="205px"
			height="95px"
			pl={2}
			gap={2}
			position="relative"
		>
			<Avatar sx={{ bgcolor: paletteObject[cardInfo.bgColor].main }}>
				<CustomIcon
					icon={cardInfo.icon}
					variant="default"
					color={cardInfo.color}
				/>
			</Avatar>
			<Box>
				{isLoading ? (
					<CustomSkeleton quantity={1} height={24} width={"40px"} />
				) : (
					<CustomTypography variant="big" isBold>
						{counter}
					</CustomTypography>
				)}
				<CustomTypography variant="small" color="blueish20">
					{cardInfo.label}
				</CustomTypography>
			</Box>
			<Link to={`${cardInfo.link}${aditionalLinkPath}`}>
				<IconButton sx={{ position: "absolute", top: 0.5, right: 0.5 }}>
					<CustomIcon icon={<OpenInNew />} variant="small" color="grey50" />
				</IconButton>
			</Link>
		</Stack>
	);
}
