import React, { useState } from "react";
import Button from "../../../components/Button";
import { useHistory } from "react-router-dom";
import { i18n } from "../../../translate/i18n";

require("dotenv/config");
export default function Success() {
	const history = useHistory();
	const [disable, setDisable] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleRedirectLogin = () => {
		setDisable(true);
		setLoading(true);
		setTimeout(() => {
			setDisable(true);
			setLoading(true);
			history.push(`/login`);
		}, 1000);
	};

	return (
		<div className="initial">
			<h2>
				{i18n.t("login.General.Ready")}
				<i className="material-icons pl-2">check_circle</i>
			</h2>

			<div className="text-white pb-2 pt-4">
				{i18n.t("login.General.MessageRecoverPassword")}
			</div>

			<div className="pt-4">
				<Button
					loading={loading}
					disable={disable}
					onClick={handleRedirectLogin}
					className="btn btn-secondary-130 w-100"
				>
					{i18n.t("login.General.BackToLogin")}
				</Button>
			</div>
		</div>
	);
}
