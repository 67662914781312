import React, { Component } from 'react';
export class SplashPreview extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className='splash-preview' style={{ backgroundColor: this.props.cor }}>
        <div className={this.props.full ? 'screen-splash' : 'logo-splash'} style={{backgroundImage: 'url(' + this.props.imagem + ')'}}>
        </div>
      </div>
    )
  }
};

export default SplashPreview;
