import React from "react";
import { Grid } from "@mui/material";
import RetailerConfigService from "./RetailerConfigService";
import { useParams, Switch, Route, Redirect } from "react-router-dom";
import Sidebar, { SidebarItem } from "../../../components/Sidebar/Sidebar";
import { i18n } from "../../../translate/i18n";
import { RetailerConfig } from "./enums/RetailerConfig";
import GeneralConfigurations from "./GeneralConfigurations/GeneralConfigurations";
import AppConfigurations from "./AppConfigurations/AppConfigurations";
import RetailerLoadingCard from "./components/RetailerLoadingCard";

export default function RetailerConfigurations() {
	const { retailerConfigurations, isLoadingUpdate, updateRetailerConfig } =
		RetailerConfigService();
	const { screen } = useParams();

	const items: SidebarItem[] = [
		{
			title: i18n.t("retailerConfig.GeneralConfigScreen"),
			link: "/settings/retailerconfiguration/general",
			isActive: screen === RetailerConfig.General,
		},
		{
			title: i18n.t("retailerConfig.AppConfigScreen"),
			link: "/settings/retailerconfiguration/app",
			isActive: screen === RetailerConfig.App,
		},
	];

	return (
		<div className="container dash-content">
			<Grid container spacing={2}>
				<Grid item xs={3} p={0}>
					<Sidebar items={items} />
				</Grid>
				<Grid item xs={9}>
					<Switch>
						<Route
							path="/settings/retailerconfiguration"
							exact
							children={
								<Redirect to="/settings/retailerconfiguration/general" />
							}
						/>
						{!retailerConfigurations ? (
							<RetailerLoadingCard />
						) : (
							<>
								<Route
									path="/settings/retailerconfiguration/general"
									children={
										<GeneralConfigurations
											retailerConfigurations={retailerConfigurations}
											isLoadingUpdate={isLoadingUpdate}
											updateRetailerConfig={updateRetailerConfig}
										/>
									}
								/>
								<Route
									path="/settings/retailerconfiguration/app"
									children={
										<AppConfigurations
											retailerConfigurations={retailerConfigurations}
											isLoadingUpdate={isLoadingUpdate}
											updateRetailerConfig={updateRetailerConfig}
										/>
									}
								/>
							</>
						)}
					</Switch>
				</Grid>
			</Grid>
		</div>
	);
}
