import React, { useState, useEffect } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import OrderDetails from "./OrderDetails";
import OrdersContext from "./providers/OrdersContext";
import OrderHistory from "./OrderHistory";
import CreateInvoice from "./CreateInvoice";
import { useConfig } from "../../providers/ConfigProvider";
import LoadDashboard from "../Dashboard/components/LoadDashboard";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function Orders(props) {
	const { mainLoading } = useConfig();
	const [detail, setDetail] = useState(true);
	const [historic, setHistoric] = useState(false);
	const [loadingInvoice, setLoadingInvoice] = useState(false);
	const [loadingHistory, setLoadingHistory] = useState(true);

	useEffect(() => {
		setLoadingInvoice(true);
		setTimeout(() => {
			setLoadingInvoice(false);
		}, 3000);
	}, [detail, historic]);

	const getDetail = () => {
		setDetail(true);
		setHistoric(false);
	};

	const getHistoric = (bool) => {
		setDetail(false);
		setHistoric(true);
	};

	const handleHistoryLoading = (loadingHistory) => {
		setLoadingHistory(loadingHistory);
	};

	return (
		<>
			{mainLoading ? (
				<LoadDashboard />
			) : (
				<OrdersContext>
					<div className="container">
						<div className="detail">
							<div className="detail-tabs">
								<button
									disabled={loadingInvoice ? true : false}
									onClick={getDetail}
									className={
										detail === true
											? "actived"
											: loadingInvoice
											? "loading"
											: null
									}
								>
									Detalhes do pedido
								</button>

								<button
									disabled={loadingHistory}
									onClick={getHistoric}
									className={
										historic === true
											? "actived"
											: loadingHistory
											? "loading"
											: null
									}
								>
									Histórico
								</button>
							</div>
							<CreateInvoice loadingInvoice={loadingInvoice} />
						</div>
						{detail === true && (
							<OrderDetails
								id={props.match.params.id}
								historic={getHistoric}
								handleHistoryLoading={handleHistoryLoading}
							/>
						)}
						{historic === true && <OrderHistory />}{" "}
					</div>
				</OrdersContext>
			)}
		</>
	);
}
