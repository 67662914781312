import React, { ChangeEvent } from "react";
import { Grid, SelectChangeEvent } from "@mui/material";
import { i18n } from "../../../../translate/i18n";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import CustomInput from "../../../../MUICustomComponents/CustomInput";
import CustomSelect, {
	SelectOptions,
} from "../../../../MUICustomComponents/CustomSelect";
import RetailerCard from "../components/RetailerCard";
import RetailerCheckbox from "../components/RetailerCheckbox";
import { ProductOrdering } from "../../../../domain/dtos/retailerManagement/retailerConfigurations/ProductOrdering";
import { ComplementaryNameDisplay } from "../../../../domain/dtos/retailerManagement/retailerConfigurations/ComplementaryNameDisplay";
import { AppConfig } from "../types/AppConfig";

type Props = {
	appConfig: AppConfig;
	onChange: (
		event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>,
		checked?: boolean
	) => void;
};

export default function AppCatalog({ appConfig, onChange }: Props) {
	const ProductDisplayCodeNameOptions: SelectOptions = [
		{
			label: i18n.t("retailerConfig.ProductDisplayCodeName.Code"),
			value: "Code",
		},
		{
			label: i18n.t("retailerConfig.ProductDisplayCodeName.AuxCode"),
			value: "AuxCode",
		},
		{
			label: i18n.t("retailerConfig.ProductDisplayCodeName.ReferenceCode"),
			value: "ReferenceCode",
		},
	];

	const catalogSortOptions: SelectOptions = [
		{
			label: i18n.t("retailerConfig.CatalogSort.None"),
			value: ProductOrdering.None,
		},
		{
			label: i18n.t("retailerConfig.CatalogSort.LowestPrice"),
			value: ProductOrdering.LowestPrice,
		},
		{
			label: i18n.t("retailerConfig.CatalogSort.HigherPrice"),
			value: ProductOrdering.HigherPrice,
		},
		{
			label: i18n.t("retailerConfig.CatalogSort.MostFavored"),
			value: ProductOrdering.MostFavored,
		},
		{
			label: i18n.t("retailerConfig.CatalogSort.BestSellers"),
			value: ProductOrdering.BestSellers,
		},
		{
			label: i18n.t("retailerConfig.CatalogSort.ReleaseDate"),
			value: ProductOrdering.ReleaseDate,
		},
		{
			label: i18n.t("retailerConfig.CatalogSort.AtoZ"),
			value: ProductOrdering.AtoZ,
		},
		{
			label: i18n.t("retailerConfig.CatalogSort.ZtoA"),
			value: ProductOrdering.ZtoA,
		},
	];

	const complementaryNameDisplay: SelectOptions = [
		{
			label: i18n.t("retailerConfig.ComplementaryNameDisplay.Code"),
			value: ComplementaryNameDisplay.Code,
		},
		{
			label: i18n.t(
				"retailerConfig.ComplementaryNameDisplay.ComplementaryName"
			),
			value: ComplementaryNameDisplay.ComplementaryName,
		},
		{
			label: i18n.t(
				"retailerConfig.ComplementaryNameDisplay.CodeAndComplementaryName"
			),
			value: ComplementaryNameDisplay.CodeAndComplementaryName,
		},
		{
			label: i18n.t("retailerConfig.ComplementaryNameDisplay.None"),
			value: ComplementaryNameDisplay.None,
		},
	];

	return (
		<RetailerCard>
			<CustomTypography variant="secondaryTitle" marginBottom={2}>
				{i18n.t("retailerConfig.AppCatalog").toString()}
			</CustomTypography>
			<Grid container spacing={2} mt={-1}>
				<Grid item xs={12}>
					<CustomSelect
						label={i18n.t(`retailerConfig.${"productDisplayCodeName"}`)}
						id="productDisplayCodeName"
						onChange={onChange}
						value={appConfig.productDisplayCodeName}
						options={ProductDisplayCodeNameOptions}
						helpTooltip={{
							title: i18n.t(`retailerConfig.ProductDisplayCodeNameTooltip`),
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<CustomSelect
						label={i18n.t(`retailerConfig.${"catalogSort"}`)}
						id="catalogSort"
						onChange={onChange}
						value={appConfig.catalogSort}
						options={catalogSortOptions}
						helpTooltip={{ title: i18n.t(`retailerConfig.CatalogSortTooltip`) }}
					/>
				</Grid>
				<Grid item xs={12}>
					<CustomSelect
						label={i18n.t(
							`retailerConfig.${"showComplementaryNameOnProductList"}`
						)}
						id="showComplementaryNameOnProductList"
						onChange={onChange}
						value={appConfig.showComplementaryNameOnProductList}
						options={complementaryNameDisplay}
						helpTooltip={{
							title: i18n.t(
								`retailerConfig.ShowComplementaryNameOnProductListTooltip`
							),
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<CustomSelect
						label={i18n.t(
							`retailerConfig.${"showComplementaryNameOnProductDetail"}`
						)}
						id="showComplementaryNameOnProductDetail"
						onChange={onChange}
						value={appConfig.showComplementaryNameOnProductDetail}
						options={complementaryNameDisplay}
						helpTooltip={{
							title: i18n.t(
								`retailerConfig.ShowComplementaryNameOnProductDetailTooltip`
							),
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<CustomInput
						title={i18n.t(`retailerConfig.${"customRuleByCategories"}`)}
						name="customRuleByCategories"
						value={appConfig.customRuleByCategories}
						onChange={onChange}
						multiline={true}
						rows={3}
						helpTooltip={{
							title: i18n.t(`retailerConfig.CustomRuleByCategoriesTooltip`),
						}}
					/>
				</Grid>
			</Grid>
			<Grid container columnSpacing={2}>
				<Grid item xs={6}>
					<RetailerCheckbox
						id="buyWithOneClick"
						isChecked={appConfig.buyWithOneClick}
						onChange={onChange}
						helpTooltip={{
							title: i18n.t(`retailerConfig.BuyWithOneClickTooltip`),
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<RetailerCheckbox
						id="isShowSubCategories"
						isChecked={appConfig.isShowSubCategories}
						onChange={onChange}
						helpTooltip={{
							title: i18n.t(`retailerConfig.IsShowSubCategoriesTooltip`),
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<RetailerCheckbox
						id="alwaysIncludeECommerce"
						isChecked={appConfig.alwaysIncludeECommerce}
						onChange={onChange}
						helpTooltip={{
							title: i18n.t(`retailerConfig.AlwaysIncludeECommerceTooltip`),
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<RetailerCheckbox
						id="showReaderCodeBar"
						isChecked={appConfig.showReaderCodeBar}
						onChange={onChange}
					/>
				</Grid>
				<Grid item xs={6}>
					<RetailerCheckbox
						id="enableCRMPropz"
						isChecked={appConfig.enableCRMPropz}
						onChange={onChange}
						helpTooltip={{
							title: i18n.t(`retailerConfig.EnableCRMPropzTooltip`),
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<RetailerCheckbox
						id="showSkuSelector"
						isChecked={appConfig.showSkuSelector}
						onChange={onChange}
					/>
				</Grid>
				<Grid item xs={6}>
					<RetailerCheckbox
						id="showUnavailableProducts"
						isChecked={appConfig.showUnavailableProducts}
						onChange={onChange}
					/>
				</Grid>
			</Grid>
		</RetailerCard>
	);
}
