import {
	Checkbox,
	FormControlLabel,
	SxProps,
	Theme,
	Tooltip,
	Zoom,
} from "@mui/material";
import React, { ChangeEvent } from "react";
import CustomTypography from "./CustomTypography";
import CustomIcon from "./CustomIcon";
import { HelpOutlineOutlined } from "@mui/icons-material";

type Props = {
	id: string;
	isChecked: boolean;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	label: string;
	tooltipText?: string;
	formControlSx?: SxProps<Theme>;
};

export default function CustomCheckbox({
	id,
	isChecked,
	onChange,
	label,
	tooltipText,
	formControlSx,
}: Props) {
	return (
		<FormControlLabel
			sx={{ margin: 0, ...formControlSx }}
			control={
				<Checkbox
					name={id}
					checked={isChecked}
					onChange={onChange}
					sx={{ padding: 0, marginRight: 1 }}
				/>
			}
			label={
				<CustomTypography variant="default">
					{label}
					{tooltipText && (
						<>
							{" "}
							<Tooltip
								arrow
								placement="top"
								TransitionComponent={Zoom}
								title={tooltipText}
							>
								<i>
									<CustomIcon icon={<HelpOutlineOutlined />} variant="small" />
								</i>
							</Tooltip>
						</>
					)}
				</CustomTypography>
			}
		/>
	);
}
