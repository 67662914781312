import { DateType } from "../../domain/enums/DateType";

export const getSinceFilter = (
	dateType: Exclude<DateType, DateType.CustomDate>
): string => {
	const date = new Date();
	date.setHours(0, 0, 0, 0);
	switch (dateType) {
		case DateType.Today:
			const today = date.toISOString();
			return today;
		case DateType.SevenDays:
			date.setDate(date.getDate() - 6);
			const sevenDays = date.toISOString();
			return sevenDays;
		case DateType.FifteenDays:
			date.setDate(date.getDate() - 14);
			const fifteenDays = date.toISOString();
			return fifteenDays;
		case DateType.ThirtyDays:
		default:
			date.setDate(date.getDate() - 29);
			const thirtyDays = date.toISOString();
			return thirtyDays;
	}
};

export const getInitialDateType = (since: string | null): DateType => {
	if (!since || since === getSinceFilter(DateType.Today)) return DateType.Today;
	if (since === getSinceFilter(DateType.SevenDays)) return DateType.SevenDays;
	if (since === getSinceFilter(DateType.ThirtyDays)) return DateType.ThirtyDays;
	return DateType.CustomDate;
};
