import React, { Component } from 'react';

import PrevTutorial from './screens/PrevTutorial'

class PreviewTutorial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appBar : localStorage.getItem('primaryColor'),
      primaryColor : localStorage.getItem('primaryColor')
    }
  }
  render() {
    return (
      <div className="preview app">
        <div className="body">
          <PrevTutorial itens={this.props.itens}/>
        </div>
      </div>
    )
  }
};

export default PreviewTutorial;
