export enum IntegrationInputs {
	OrderIntegrationId = "orderIntegrationId",
	PaymentIntegrationId = "paymentIntegrationId",
	FraudIntegrationId = "fraudIntegrationId",
	OrderIntegrationCredentials = "orderIntegrationCredentials",
	PaymentIntegrationCredentials = "paymentIntegrationCredentials",
	FraudIntegrationCredentials = "fraudIntegrationCredentials",
	OrderProcesses = "orderProcesses",
	SafetyStockQuantity = "safetyStockQuantity",
	IsDefaultSafetyStock = "isDefaultSafetyStock",
	DisableToUseChangeSeller = "disableToUseChangeSeller",
}
