import React, { Component } from "react";
import { APIV2 } from "../../../../API";
import { toast } from "react-toastify";
import Modal from "../../../../components/Modal";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export class StandardBanner extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: null,
			imageUrl: "",
			edit: false,
			loading: true,
		};
		this.handleChange = this.handleChange.bind(this);
		this.deleteBanners = this.deleteBanners.bind(this);
		this.setDelete = this.setDelete.bind(this);
	}

	handleChange(e) {
		this.setState({
			[e.target.name]: e.target.value,
		});
		this.forceUpdate();
	}
	setDelete(id) {
		this.setState({ deleteId: id });
	}

	async deleteBanners(id) {
		await APIV2.delete("management/marketing/banners/" + this.state.deleteId)
			.then(async (response) => {
				toast.error("Banner deletado com sucesso! 😃");
				window.location.reload();
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	}
	render() {
		return (
			<div className="p-0">
				<div>
					{this.props.isDefault.length === 0 && this.props.loading === false ? (
						<div /* className="card p-2" */>
							<Link
								className="btn btn-grey-80"
								style={{ width: "150px" }}
								to={"new-default"}
							>
								Adicionar
							</Link>
						</div>
					) : (
						this.props.isDefault.map((banner) => (
							<div
								key={banner.id}
								style={{
									borderRadius: "4px",
									border: "1px solid rgb(233, 236, 236)",
								}}
							>
								<div className="list-banner mx-1">
									<div className="banner-img">
										<img
											src={
												banner.imageUrl == null
													? "/images/placeholder.jpg"
													: banner.imageUrl
											}
											alt="Banners"
											className="shadow-sm"
										/>
									</div>

									<div className="banner-description d-flex align-items-center justify-content-start">
										<h6
											className="font-weight-bold justify-content-start"
											style={{
												whiteSpace: "nowrap",
												overflow: "hidden",
												textOverflow: "ellipsis",
											}}
										>
											{banner.title}
										</h6>
									</div>

									<div className="col-4"></div>

									<div className="banner-buttons">
										<div
											className="btn-group"
											role="group"
											aria-label="Basic example"
										>
											<Link
												className="btn btn-light btn-sm outline btn-icon"
												to={`new-default/edit/${banner.id}`}
											>
												<i className="material-icons">edit</i>
											</Link>
											<button
												type="button"
												className="btn btn-light btn-sm outline btn-icon"
												data-toggle="modal"
												data-target="#deleteExpired"
												onClick={() => this.setDelete(banner.id)}
											>
												<i className="material-icons text-danger">
													delete_outline
												</i>
											</button>
										</div>
										<Modal
											id={"deleteExpired"}
											title={"Tem certeza que deseja excluir este item?"}
											delete={true}
											actionDelete={this.deleteBanners}
										/>
									</div>
								</div>
							</div>
						))
					)}
				</div>
			</div>
		);
	}
}

export default StandardBanner;
