import courierLabel from "./courierLabelPT.json";
import orderDetails from "./orderDetailsPT.json";
import orderList from "./orderListPT.json";
import smOrderStatus from "./smOrderStatusPT.json";

export const ordersPT = {
	courierLabel,
	orderDetails,
	orderList,
	smOrderStatus,
};
