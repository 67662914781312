export enum TCStatus {
	em_analise = "em_analise",
	aguardando_envio = "aguardando_envio",
	em_transito = "em_transito",
	problemas_no_envio = "problemas_no_envio",
	entrega_realizada = "entrega_realizada",
	aguardando_pagamento = "aguardando_pagamento",
	finalizado = "finalizado",
	cancelado = "cancelado",
	aguardando_recebimento = "aguardando_recebimento",
	aguardando_nfd = "aguardando_nfd",
	processando_pagamento = "processando_pagamento",
}
