import React, { useEffect } from "react";
import StoresList from "./StoresList";
export default function Index(props) {
	useEffect(() => {
		localStorage.removeItem("PARAMS");
		if (!props.match.params.page) {
			window.location.href = `/stores/1`;
		}
	}, [props.match.params]);

	return <StoresList page={parseInt(props.match.params.page)} />;
}
