import React from "react";
import useModalitiesService from "./ModalitiesService";
import ModalitiesSkeleton from "./ModalitiesSkeleton";
import {
	Stack,
	Button,
	Grid,
	Typography,
	Box,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import { SellerModalityView } from "../../../../domain/views/stores/SellerModalityView";

type ModalityProps = {
	sellerId: string;
	data: SellerModalityView[];
	getSeller: (sellerId: string) => Promise<void>;
};

export default function Modalities({
	sellerId,
	data,
	getSeller,
}: ModalityProps) {
	const {
		//Props
		availableModalities,
		isLoading,
		selectedModalities,
		// Functions
		selectModality,
		updateModalities,
	} = useModalitiesService(sellerId, data, getSeller);

	return (
		<Box
			m={4}
			minHeight={335}
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
		>
			<Box>
				<Typography variant="h5" pb={3} pl={0} sx={{ fontWeight: "bold" }}>
					Modalidades
				</Typography>
				{isLoading ? (
					ModalitiesSkeleton()
				) : (
					<Grid container spacing={2} mb={3} pl={1}>
						{availableModalities.map((modality, index) => (
							<Grid item sm={6} lg={4} key={modality.id?.toString() ?? index}>
								<FormControlLabel
									id={modality.id?.toString() ?? ""}
									label={
										<div style={{ fontSize: "14px" }}>{modality.name}</div>
									}
									control={
										<Checkbox
											name={`${index}`}
											checked={selectedModalities.some(
												(select) => select.id === modality.id
											)}
											onChange={() => selectModality(modality)}
										/>
									}
								/>
							</Grid>
						))}
					</Grid>
				)}
			</Box>
			<Stack direction="row" justifyContent="end">
				<Button
					color="success"
					variant="contained"
					disabled={isLoading}
					onClick={() => updateModalities(sellerId, selectedModalities)}
				>
					Salvar
				</Button>
			</Stack>
		</Box>
	);
}
