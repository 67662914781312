import React, { ChangeEvent } from "react";
import { Grid, SelectChangeEvent } from "@mui/material";
import { i18n } from "../../../../translate/i18n";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import RetailerCard from "../components/RetailerCard";
import RetailerCheckbox from "../components/RetailerCheckbox";
import { AppConfig } from "../types/AppConfig";

type Props = {
	appConfig: AppConfig;
	onChange: (
		event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>,
		checked?: boolean
	) => void;
};

export default function AppSale({ appConfig, onChange }: Props) {
	return (
		<RetailerCard>
			<CustomTypography variant="secondaryTitle" marginBottom={2}>
				{i18n.t("retailerConfig.AppSale").toString()}
			</CustomTypography>
			<Grid container columnSpacing={2} mt={-1}>
				<Grid item xs={6}>
					<RetailerCheckbox
						id="hasDealerCode"
						isChecked={appConfig.hasDealerCode}
						onChange={onChange}
						helpTooltip={{
							title: i18n.t(`retailerConfig.HasDealerCodeTooltip`),
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<RetailerCheckbox
						id="isDealerRankingByNetwork"
						isChecked={appConfig.isDealerRankingByNetwork}
						onChange={onChange}
					/>
				</Grid>
			</Grid>
		</RetailerCard>
	);
}
