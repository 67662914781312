import { ChangeEvent, useEffect, useState } from "react";
import { DealerDto } from "../../domain/dtos/dealers/DealerDto";
import { OrderModel } from "../../domain/models/commerce/OrderModel";
import { ItemModel } from "../../domain/models/commerce/ItemModel";
import { getPercentageOf } from "../../infrastructure/utils/MathOperations";
import { PickingModel } from "../../domain/models/commerce/PickingModel";
import { TCOrderAsSimplifiedOrderModel } from "../../screens/TroqueCommerce/TCDetails/components/TCItems";

export type OrderTableItem = ItemModel & {
	checkedQuantity: number;
};

export default function OrderCheckDialogService(
	orderModel: OrderModel | TCOrderAsSimplifiedOrderModel,
	onSubmitCallback: Function
) {
	const [currentDealer, setCurrentDealer] = useState<DealerDto | null>(null);
	const [orderTableItems, setOrderTableItems] = useState<OrderTableItem[]>([]);
	const [currentEanValue, setCurrentEanValue] = useState<string>("");
	const [checkedItemsQuantity, setCheckedItemsQuantity] = useState<number>(0);
	const [checkedItemsProgress, setCheckedItemsProgress] = useState<number>(0);
	const [packagesQuantity, setPackagesQuantity] = useState<string>("1");
	const [isAttentionDialogOpen, setIsAttentionDialogOpen] =
		useState<boolean>(false);
	const [notFoundEan, setNotFoundEan] = useState<string>("");
	const [isDealerListEmpty, setIsDealerListEmpty] = useState<boolean>(false);
	const [isLoadingDealers, setIsLoadingDealers] = useState<boolean>(false);

	useEffect(() => {
		if (!orderModel) return;
		initializeItemTable(orderModel);
	}, [orderModel]);

	const initializeItemTable = (
		data: OrderModel | TCOrderAsSimplifiedOrderModel
	): void => {
		const newItems: OrderTableItem[] = data.items.map((item: ItemModel) => ({
			...item,
			checkedQuantity: 0,
		}));
		setOrderTableItems(newItems);
	};

	const emptyDealersCallback = () => {
		setIsDealerListEmpty(true);
	};

	const triggerLoadingDealers = (isLoading: boolean) => {
		setIsLoadingDealers(isLoading);
	};

	const handleAutocompleteChange = (dealer: DealerDto | null): void => {
		setCurrentDealer(dealer);
	};

	const handleEanInput = (event: ChangeEvent<HTMLInputElement>): void => {
		const { value } = event.target;
		setCurrentEanValue(value);
	};

	const handleSeparationProgress = (): void => {
		const checkedQuantity = checkedItemsQuantity + 1;
		const newCheckedProgress = getPercentageOf(
			checkedQuantity,
			orderModel.itemQuantity
		);
		setCheckedItemsQuantity(checkedQuantity);
		setCheckedItemsProgress(newCheckedProgress);
	};

	const submitEan = (): void => {
		setCurrentEanValue("");
		const foundItemIndex: number = orderTableItems.findIndex((item) =>
			item.ean
				? item.ean?.toLowerCase() === currentEanValue.toLowerCase()
				: item.skuCode.toLowerCase() === currentEanValue.toLowerCase()
		);
		if (foundItemIndex === -1) {
			setIsAttentionDialogOpen(true);
			setNotFoundEan(currentEanValue);
			return;
		}
		if (
			orderTableItems[foundItemIndex].checkedQuantity ===
			orderTableItems[foundItemIndex].quantity
		) {
			return;
		}

		const newOrderTableItems: OrderTableItem[] = [...orderTableItems];
		const updatedItem: OrderTableItem = {
			...newOrderTableItems[foundItemIndex],
			checkedQuantity: newOrderTableItems[foundItemIndex].checkedQuantity + 1,
		};
		newOrderTableItems[foundItemIndex] = updatedItem;
		handleSeparationProgress();
		setOrderTableItems(newOrderTableItems);
	};

	const handleDataReset = (): void => {
		setCurrentDealer(null);
		initializeItemTable(orderModel);
		setCurrentEanValue("");
		setCheckedItemsQuantity(0);
		setCheckedItemsProgress(0);
		setPackagesQuantity("1");
	};

	const onSubmit = (isExchangeIntegration: boolean) => {
		if (isExchangeIntegration) {
			// Currently no exchange integration has interaction with neither picker nor packages
			onSubmitCallback(checkedItemsQuantity !== orderModel.itemQuantity);
			return;
		}
		if (!currentDealer) return;
		const packages = Number(packagesQuantity);
		const picking: PickingModel = {
			picker: {
				code: currentDealer.dealerCode,
				name: currentDealer.fullName,
				document: currentDealer.salesmanCpf || currentDealer.salesmanCnpj || "",
			},
			packagesQuantity: packages,
		};
		onSubmitCallback(picking);
	};

	return {
		orderTableItems,
		currentDealer,
		isDealerListEmpty,
		currentEanValue,
		checkedItemsQuantity,
		checkedItemsProgress,
		packagesQuantity,
		isAttentionDialogOpen,
		notFoundEan,
		isLoadingDealers,
		triggerLoadingDealers,
		setNotFoundEan,
		setIsAttentionDialogOpen,
		setPackagesQuantity,
		setCurrentDealer,
		emptyDealersCallback,
		handleAutocompleteChange,
		handleEanInput,
		submitEan,
		handleDataReset,
		onSubmit,
	};
}
