import { BaseOverrideColors } from "./baseOverrideColors";
import {
	PaletteDiscriminatedUnion,
	paletteObject,
} from "../foundations/palette";

declare module "@mui/material/ButtonGroup" {
	interface ButtonGroupPropsColorOverrides extends BaseOverrideColors {}
}

type BtnGroupOwnerState = {
	color: PaletteDiscriminatedUnion;
};

export const MuiButtonGroup = {
	styleOverrides: {
		outlined: ({ ownerState }: { ownerState: BtnGroupOwnerState }) => ({
			"& .MuiButtonGroup-groupedOutlined": {
				borderColor: paletteObject[ownerState.color].main,
			},
		}),
	},
};
