import React from "react";
import { OrderTableItem } from "./OrderCheckDialogService";
import {
	Divider,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import { CheckCircleRounded } from "@mui/icons-material";
import CustomIcon from "../../MUICustomComponents/CustomIcon";
import { i18n } from "../../translate/i18n";
import { paletteObject } from "../../theme/foundations/palette";
import CustomScrollbar from "../../MUICustomComponents/CustomScrollbar";

type Props = {
	orderTableItems: OrderTableItem[];
};

export default function OrderCheckTable({ orderTableItems }: Props) {
	const renderOrderItem = (item: OrderTableItem) => (
		<TableRow
			key={item.id}
			sx={{
				height: "48px",
				...(item.checkedQuantity === item.quantity && {
					backgroundColor: paletteObject.green30.main,
				}),
			}}
		>
			<TableCell align="center">
				{item.checkedQuantity === item.quantity && (
					<CustomIcon
						icon={<CheckCircleRounded />}
						variant="small"
						color="success"
					/>
				)}
			</TableCell>
			<TableCell
				sx={{
					fontWeight: "bold",
					whiteSpace: "nowrap",
					overflow: "hidden",
					textOverflow: "ellipsis",
				}}
				title={item.name}
			>
				{item.name}
			</TableCell>
			<TableCell align="center" sx={{ fontSize: "12px" }}>
				{item.productCode}
			</TableCell>
			<TableCell align="center" sx={{ fontSize: "12px" }}>
				<span
					{...(!item.ean && {
						style: { cursor: "pointer" },
						onClick: () => navigator.clipboard.writeText(item.skuCode),
					})}
				>
					{item.skuCode}
				</span>
			</TableCell>
			<TableCell align="center" sx={{ fontSize: "12px" }}>
				<span
					{...(item.ean && {
						style: { cursor: "pointer" },
						onClick: () => navigator.clipboard.writeText(item.ean ?? ""),
					})}
				>
					{item.ean}
				</span>
			</TableCell>
			<TableCell align="center" sx={{ fontWeight: "bold" }}>
				{item.checkedQuantity}/{item.quantity}
			</TableCell>
		</TableRow>
	);

	return (
		<>
			<Divider />
			<CustomScrollbar minHeight={"0px"} maxHeight={"30vh"}>
				<Table sx={{ tableLayout: "fixed" }}>
					<TableHead>
						<TableRow
							sx={{
								".MuiTableCell-head": {
									fontWeight: "bold",
								},
							}}
						>
							<TableCell align="center" width={"36px"} />
							<TableCell width={"40%"}>
								{i18n.t("orderCheck.TableProduct").toString()}
							</TableCell>
							<TableCell align="center" width={"15%"}>
								{i18n.t("orderCheck.TableProductCode").toString()}
							</TableCell>
							<TableCell align="center" width={"15%"}>
								{i18n.t("orderCheck.TableSkuCode").toString()}
							</TableCell>
							<TableCell align="center" width={"15%"}>
								{i18n.t("orderCheck.TableEan").toString()}
							</TableCell>
							<TableCell align="center" width={"15%"}>
								{i18n.t("orderCheck.TableQuantity").toString()}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>{orderTableItems.map(renderOrderItem)}</TableBody>
				</Table>
			</CustomScrollbar>
		</>
	);
}
