import { Dispatch, SetStateAction, useEffect, useState } from "react";
import NewToastComponent from "../NewToastComponent";
import { i18n } from "../../translate/i18n";
import { DealerDto } from "../../domain/dtos/dealers/DealerDto";
import { marketingManagementApi } from "../../infrastructure/api/MarketingManagementApi";

export default function DealerAutocompleteService(
	dealerId: string | null,
	setCurrentDealer: Dispatch<SetStateAction<DealerDto | null>>,
	emptyDealersCallback: () => void,
	triggerLoadingDealers: (isLoading: boolean) => void
) {
	const [currentDealerInput, setCurrentDealerInput] = useState<string>("");
	const [dealerList, setDealerList] = useState<DealerDto[]>([]);

	useEffect(() => {
		getDealerList();
	}, [dealerId]);

	const getDealerList = async (): Promise<void> => {
		triggerLoadingDealers(true);
		try {
			const data: DealerDto[] = await marketingManagementApi.getDealerList();
			const newDealerList = data || [];
			setDealerList(newDealerList);
			triggerLoadingDealers(false);
			if (newDealerList.length === 0) emptyDealersCallback();
			if (!dealerId) return;
			const foundDealer = data.find((dealer) => dealer.id === dealerId);
			if (foundDealer) setCurrentDealer(foundDealer);
		} catch (error) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			triggerLoadingDealers(false);
		}
	};

	const filterOptions = (option: DealerDto): string => {
		option.dealerCode = option.dealerCode ?? "";
		option.fullName = option.fullName ?? "";
		return option.dealerCode + option.fullName;
	};

	const getOptionLabel = (option: DealerDto): string => option.fullName;

	const isOptionEqualToValue = (option: DealerDto, value: DealerDto): boolean =>
		option.id === value.id;

	return {
		dealerList,
		currentDealerInput,
		setCurrentDealerInput,
		// Functions
		filterOptions,
		getOptionLabel,
		isOptionEqualToValue,
	};
}
