/**
 * This function builds a string, with valid query parameters.
 *
 * Obs: When the value does not meet the requirements, it will be discarded.
*
 * @example let queryParams = { status: true, isActive: false };  // `status=true&isActive=false`
 *
 * @param object to build the query params.
 * @returns string with query params.

 */
function buildURLWithParams(queryParams: object): string {
	const urlParams = Object.entries(queryParams)
		.filter(
			([_, value]) => value !== null && value !== undefined && value !== ""
		)
		.map(([key, query]) => `${key}=${query}`)
		.join("&");

	return `${urlParams}`;
}

export { buildURLWithParams };
