import { useEffect, useState } from "react";
import { retailerManagementApi } from "../../../../infrastructure/api/RetailerManagementApi";
import NewToastComponent from "../../../../components/NewToastComponent";
import { SellerModalityView } from "../../../../domain/views/stores/SellerModalityView";
import { SellerModalityDto } from "../../../../domain/dtos/stores/SellerModalityDto";
import { ValidationError } from "../../../../domain/exceptions/CustomErrors";

export default function useModalitiesService(
	sellerId: string,
	data: SellerModalityView[],
	getSeller: (sellerId: string) => Promise<void>
) {
	const [availableModalities, setAvailableModalities] = useState<
		SellerModalityView[]
	>([]);
	const [selectedModalities, setSelectedModalities] = useState<
		SellerModalityView[]
	>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		if (data.length > 0) {
			setSelectedModalities(data);
		}
		getModalities(sellerId);
	}, []);

	const getModalities = async (sellerId: string): Promise<void> => {
		setIsLoading(true);

		let arrData: SellerModalityDto[] = [];

		try {
			let data: SellerModalityDto[] = await retailerManagementApi.getModalities(
				sellerId
			);

			if (data.length === 0) return;
			data.forEach((modality) => {
				try {
					arrData.push(modality);
				} catch (error) {
					if (error instanceof ValidationError) {
						NewToastComponent({
							status: "error",
							title: "Parece que tivemos um erro",
							message: "Uma modalidade não foi carregada",
						});
						return;
					}
				}
			});
		} catch {
			NewToastComponent({
				status: "error",
				title: "Parece que tivemos um erro",
				message: "As modalidade não foi carregada",
			});
		}

		setAvailableModalities(arrData);
		setTimeout(() => {
			setIsLoading(false);
		}, 500);
	};

	const updateModalities = async (
		sellerId: string,
		data: SellerModalityView[]
	): Promise<void> => {
		setIsLoading(true);

		let success = await retailerManagementApi.updateModalities(sellerId, data);

		if (!success) {
			NewToastComponent({
				status: "error",
				title: "Não foi possível atualizar as modalidades",
				message: "Tente novamente",
			});
			setIsLoading(false);
			return;
		}
		NewToastComponent({
			status: "success",
			title: "Modalidades editadas com sucesso!",
		});
		getSeller(sellerId);
		setIsLoading(false);
	};

	const selectModality = (modality: SellerModalityView): void => {
		const hasModality = selectedModalities.some(
			(obj: SellerModalityDto) => obj.id === modality.id
		);

		if (hasModality) {
			setSelectedModalities((prevState) =>
				prevState.filter((obj) => obj.id !== modality.id)
			);
			return;
		}
		setSelectedModalities((prevState) => [...prevState, modality]);
	};

	return {
		// Props
		availableModalities,
		isLoading,
		selectedModalities,
		// Functions
		selectModality,
		updateModalities,
	};
}
