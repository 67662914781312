import React, { useState } from "react";
import DropzoneInvoice from "../DropzoneInvoice";
import InputMask from "../../../../components/InputMask";
import InputSingleDate from "../../../../components/InputSingleDate";
import InputCurrency from "../../../../components/InputCurrency";
import { useOrders } from "../../providers/OrdersContext";
import { useForm } from "react-hook-form";
import { formatDateUTC } from "../../../../infrastructure/utils/FormatMask";
import { i18n } from "../../../../translate/i18n";
import SkeletonInvoices from "../OrderDetails/SkeletonInvoices";

export default function InvoiceOrderModal({ id }) {
	const { handleChangeInvoices, loadingStatus, setLoadingStatus } = useOrders();
	const {
		register,
		handleSubmit,
		resetField,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			key: "",
			number: "",
			serialNumber: "",
			amount: "",
			fileUrl: "",
			issuanceDate: "",
		},
	});
	const [fileUrl, setFileUrl] = useState(null);

	const handleformDropFile = (fileBlob) => {
		setFileUrl(fileBlob);
	};

	const handleFormSubmit = (data) => {
		setLoadingStatus(true);
		const date = formatDateUTC(data?.issuanceDate);
		const amount = data?.invoiceValue
			?.replace(/\D/g, "")
			?.replace(/(\d)(\d{2})$/, "$1.$2");

		const formInvoice = [
			{
				key: data?.invoiceKey,
				number: data?.invoiceNumber,
				serialNumber: data?.invoiceSerialNumber,
				amount: parseFloat(amount),
				fileUrl: fileUrl,
				issuanceDate: date,
			},
		];

		const button = document.getElementById("invoiceOrders");
		button.click();
		handleChangeInvoices(id, formInvoice, 3);
		resetField("invoiceNumber", { defaultValue: "" });
		resetField("invoiceSerialNumber", { defaultValue: "" });
		resetField("invoiceKey", { defaultValue: "" });
		resetField("issuanceDate", { defaultValue: "" });
		resetField("invoiceValue", { defaultValue: "" });
	};

	const handleCancelModal = () => {
		resetField("invoiceNumber", { defaultValue: "" });
		resetField("invoiceSerialNumber", { defaultValue: "" });
		resetField("invoiceKey", { defaultValue: "" });
		resetField("issuanceDate", { defaultValue: "" });
		resetField("invoiceValue", { defaultValue: "" });
		const button = document.getElementById("invoiceOrders");
		button.click();
	};

	const showModalInvoices = () => {
		return (
			<>
				<div className="modal-header d-flex flex-column">
					<div className="card-header m-0 p-0 mb-3">
						<h4 className="card-title mb-3">
							{i18n.t("orderDetails.General.Invoice")}
						</h4>
						<label
							className="p-0 m-0"
							style={{ fontSize: "14px", color: "#747E8B" }}
						>
							{i18n.t("orderDetails.Invoicing.MessageIncludeInvoicing")}
						</label>
					</div>
				</div>
				<div className="modal-body m-0 p-0">
					<form
						className="neo-form"
						onKeyPress={(event) => {
							event.key === "Enter" && event.preventDefault();
						}}
						onSubmit={handleSubmit(handleFormSubmit)}
					>
						<div className="d-flex flex-row">
							<div className="w-100" style={{ paddingRight: "18px" }}>
								<div className="d-flex">
									<div className="w-100" style={{ paddingRight: "8px" }}>
										<InputMask
											required={true}
											errors={errors}
											register={register}
											isInputText={true}
											isEnabled={true}
											inputName={"invoiceNumber"}
										/>
									</div>
									<div className="w-75" style={{ paddingLeft: "8px" }}>
										<InputMask
											required={true}
											errors={errors}
											register={register}
											isInputText={true}
											isEnabled={true}
											inputName={"invoiceSerialNumber"}
										/>
									</div>
								</div>

								<InputMask
									required={true}
									errors={errors}
									register={register}
									isInputText={true}
									isEnabled={true}
									inputName={"invoiceKey"}
								/>

								<div className="row">
									<div className="col-7 m-0" style={{ paddingRight: "8px" }}>
										<InputSingleDate
											required={true}
											errors={errors}
											register={register}
											isInputText={false}
											isEnabled={true}
											inputName={"issuanceDate"}
											resetField={resetField}
										/>
									</div>
									<div className="col-5 m-0" style={{ paddingLeft: "8px" }}>
										<InputCurrency
											required={true}
											errors={errors}
											register={register}
											isEnabled={true}
											inputName={"invoiceValue"}
											resetField={resetField}
										/>
									</div>
								</div>
							</div>
							<div className="w-100" style={{ paddingLeft: "18px" }}>
								<DropzoneInvoice
									id={id}
									onChange={handleformDropFile}
									disableDropForm={true}
								/>
							</div>
						</div>
						<div className="d-flex justify-content-start mt-4">
							<button
								type="submit"
								className="btn btn-success"
								style={{ padding: "10px 20px", width: "190px" }}
							>
								{i18n.t("orderDetails.Status.WaitingInvoiceAction")}
							</button>

							<button
								type="button"
								className="btn btn-light outline ml-2"
								//data-dismiss="modal"
								onClick={handleCancelModal}
								style={{
									padding: "10px 20px",
									width: "190px",
									color: "#5A5E61",
									border: "1px solid #8D8D8D",
									backgroundColor: "transparent",
								}}
							>
								{i18n.t("orderDetails.TypeButtom.BtnCancel")}
							</button>
						</div>
					</form>
				</div>
			</>
		);
	};

	return (
		<div
			className="modal fade"
			id="invoiceOrders"
			tabIndex="-1"
			aria-labelledby="invoiceOrdersLabel"
			aria-hidden="true"
		>
			<div
				className="modal-dialog modal-dialog pt-5 mt-5"
				style={{ minWidth: "1050px" }}
			>
				<div className="modal-content rounded" style={{ padding: "32px" }}>
					{loadingStatus ? <SkeletonInvoices /> : showModalInvoices()}
				</div>
			</div>
		</div>
	);
}
