import React, { useState } from "react";
import Button from "../../../components/Button";
import ContentLoader from "react-content-loader";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useLogin } from "../providers/LoginContext";
import { i18n } from "../../../translate/i18n";
export default function Initial() {
	const { handleLogin, error, loading, setLoading } = useLogin();
	const history = useHistory();
	const [disable, setDisable] = useState(false);
	const [isVisible, setIsVisible] = useState("0");
	const infos = {
		email: "",
		password: "",
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const saveInfos = (info) => {
		setDisable(true);
		handleLogin(info.email, info.password);
		setDisable(false);
	};

	const showVisibility = (event) => {
		event.preventDefault();
		let view = event.currentTarget.name;
		view !== isVisible && setIsVisible("1");
		view === isVisible && setIsVisible("0");
	};

	const resetPassword = () => {
		setDisable(true);
		setLoading(true);
		setTimeout(() => {
			setDisable(true);
			setLoading(true);
			history.push("/reset-password");
		}, 1000);
	};

	const skeleton = () => {
		const skele = [0];
		return skele.map((key) => (
			<ContentLoader
				key={key}
				speed={2}
				width={"402"}
				height={40}
				backgroundColor="#f3f3f3"
				foregroundColor="rgb(243, 243, 243, 0.3)"
				viewBox="0 0 402 40"
			>
				<rect x="0" y="0" rx="5" ry="5" width="402" height="40" />
			</ContentLoader>
		));
	};

	return (
		<div className="initial">
			<h2 className="pb-4">{i18n.t("login.General.Into")}</h2>
			<form
				style={{ position: "relative" }}
				onKeyDown={(event) => {
					if (event.key === "Enter") {
						handleSubmit(saveInfos)();
					}
				}}
				onSubmit={(event) => {
					event.preventDefault();
					handleSubmit(saveInfos)();
				}}
			>
				{error && (
					<div class="alert alert-danger _error" role="alert">
						<i className="material-icons pr-2">error_outline</i>
						{i18n.t("login.General.ErrorLogin")}
					</div>
				)}
				<div className="neo-form-row">
					{/* email */}
					<div className="">
						<label htmlFor="email">
							<strong className="text-white" style={{ fontSize: "12px" }}>
								{i18n.t("login.General.Email")}
							</strong>
						</label>
						{loading ? (
							skeleton()
						) : (
							<div className="neo-form">
								<div className="neo-form-input">
									<input
										className={`${
											errors.email ? "neo-input-invalid" : "neo-input-valid"
										}`}
										autoComplete
										id="email"
										name="email"
										placeholder="Digite seu email"
										defaultValue={infos.email}
										{...register("email", { required: true })}
									/>
									{errors.email && (
										<div className="neo-icons-required">
											<button type="button">
												<i className="material-icons">error_outline</i>
											</button>
										</div>
									)}
								</div>
							</div>
						)}
						{errors.email && (
							<small className="text-danger">
								<em>{i18n.t("login.General.RequiredField")}</em>
							</small>
						)}
					</div>
					{/* password */}
					<div className="pt-4">
						<label htmlFor="password">
							<strong className="text-white" style={{ fontSize: "12px" }}>
								{i18n.t("login.General.Password")}
							</strong>
						</label>
						{loading ? (
							skeleton()
						) : (
							<div className="neo-form">
								<div className="neo-form-date">
									<input
										type={isVisible === "0" ? "password" : ""}
										autoComplete
										className={`${
											errors.password ? "neo-input-invalid" : "neo-input-valid"
										}`}
										id="password"
										name="password"
										placeholder="Digite sua senha"
										defaultValue={!infos.password ? "" : infos.password}
										{...register("password", {
											required: infos.id ? false : true,
										})}
									/>

									{errors.password && (
										<div className="neo-icons-required">
											<button type="button">
												<i className="material-icons">error_outline</i>
											</button>
										</div>
									)}
									<div className="neo-icons-date">
										<button type="button" name="1" onClick={showVisibility}>
											<i className="material-icons">
												{isVisible === "0" ? "visibility" : "visibility_off"}
											</i>
										</button>
									</div>
								</div>
							</div>
						)}
						{errors.password && (
							<small className="text-danger">
								<em>{i18n.t("login.General.RequiredField")}</em>
							</small>
						)}
					</div>
					<div className="form-group d-flex justify-content-between">
						<a
							className="text-right _link"
							href="https://neomode.zendesk.com/"
							target="_blank"
						>
							{i18n.t("login.General.Help")}
						</a>

						<a className="text-right _link" onClick={resetPassword}>
							{i18n.t("login.General.ForgetPassword")}
						</a>
					</div>
					<div className="pt-2">
						<Button
							type="submit"
							loading={loading}
							disable={disable}
							className="btn btn-secondary-130 w-100"
						>
							{i18n.t("login.General.Into")}
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
}
