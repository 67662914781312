// TODO
// Might need refactor, should it really accept args?
export function IsValidNumber(...args: any[]): boolean {
	return args
		.map((value) => !isNaN(Number(value)))
		.every((value) => value === true);
}

export function isValidString(value: string | null | undefined): boolean {
	return value !== null && value !== undefined && value.trim().length > 0;
}

/**
 * This function validates if the url parameter is a valid
 *
 * @param url The URL to be validated
 * @param acceptOnlyHttps if only HTTPS should be valid
 * @returns if it's a valid URL
 */
export function isValidUrl(
	url: string,
	acceptOnlyHttps: boolean = false
): boolean {
	try {
		const newUrl = new URL(url);
		if (acceptOnlyHttps) return newUrl.protocol === "https:";
		return newUrl.protocol === "http:" || newUrl.protocol === "https:";
	} catch {
		return false;
	}
}

export function isValueInStringEnum<E extends string>(
	strEnum: Record<string, E>,
	value: string
): boolean {
	const enumValues = Object.values(strEnum) as string[];

	return enumValues.includes(value);
}

export function isNegativeNumber(number: number): boolean {
	return number <= -1;
}
