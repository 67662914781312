import { paletteObject } from "../foundations/palette";

declare module "@mui/material/Paper" {
	interface PaperPropsVariantOverrides {
		customPaper: true;
		grey10: true;
	}
}

export const MuiPaper = {
	variants: [
		{
			props: { variant: "customPaper" },
			style: {
				boxShadow: "0px 10px 14px 0 rgba(25, 42, 70, 0.1);",
				padding: "24px",
			},
		},
		{
			props: { variant: "grey10" },
			style: {
				backgroundColor: paletteObject.grey10.main,
				padding: "24px",
			},
		},
	],
};
