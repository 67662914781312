import React from 'react';

// import { Container } from './styles';

function ContactCel() {
  return (
    <>
      <div className="col-12 p-2 pl-4 pr-4 mb-2 d-flex justify-content-between align-items-cente">
        <div >
          <small>
            Tem alguma duvida, fale conosco
          </small>
        </div>
        <div>
          <button className="btn btn-icon-c-l btn-sm"><i class="material-icons">phone</i> Whatsapp</button>
        </div>
      </div>
    </>
  );
}

export default ContactCel;