import React from "react";
import ButtonStatus from "../ButtonStatus";
import ArrowDownDashed from "../../../../assets/floworder-arrow/ArrowDownDashed.svg";
import ArrowDownSuccess from "../../../../assets/floworder-arrow/ArrowDownSuccess.svg";
import ArrowDownSolid from "../../../../assets/floworder-arrow/ArrowDownSolid.svg";
import ArrowDownWarning from "../../../../assets/floworder-arrow/ArrowDownWarning.svg";
import ArrowDownInfo from "../../../../assets/floworder-arrow/ArrowDownInfo.svg";
import ArrowStraightSmDashed from "../../../../assets/floworder-arrow/ArrowStraightSmDashed.svg";
import ArrowStraightSmSolid from "../../../../assets/floworder-arrow/ArrowStraightSmSolid.svg";
import ArrowUpRightDashed from "../../../../assets/floworder-arrow/ArrowUpRightDashed.svg";
import ArrowUpRightSolid from "../../../../assets/floworder-arrow/ArrowUpRightSolid.svg";
import { useOrders } from "../../providers/OrdersContext";
export default function CreateDeliveryStatus({
	indexColumn,
	getTypeIcons,
	getTitleButton,
	getTypeInfo,
	getButtonStyle,
	getTypeArrow,
	getProcessOrder,
	getRetryProcessOrder,
	getFailedProcessOrder,
}) {
	const delivery = [0, 1, 2];
	const { flowStatus, loadingStatus } = useOrders();

	const isDelivery = flowStatus?.isDelivery;
	const position = flowStatus?.enqueueOrderPosition;
	const lastPosition = parseInt(flowStatus?.lastOrderPosition);
	const lastProcessedStatus = lastPosition + 1;
	const stepCancellation = position > 100 && lastPosition === 5;
	const nextStep = flowStatus?.hasNextActionAvailable && position === 5;
	const loading = loadingStatus && position === 5;
	const loadingRetry = loadingStatus && lastPosition === 4 && position === 100;
	const isCanceled = flowStatus.status === "Canceled" && position === 100;

	const getDateCanceled = (lastPosition) => {
		if (lastPosition === 1 && position > 100) return 2;
		else if (lastPosition === 2 && position > 100) return 3;
		else if (lastPosition === 3 && position > 100) return 4;
		else return 5;
	};

	const getStepCompleted = (position, lastPosition, lastProcessedStatus) => {
		if (position > 5 && position < 100) return true;
		else if (position > 100 && lastPosition > 5) return true;
		else if (position === 100 && lastProcessedStatus > 5) return true;
		else return false;
	};

	const stepCompleted = getStepCompleted(
		position,
		lastPosition,
		lastProcessedStatus
	);

	const processing = getProcessOrder(position === 5);

	const processingRetry = getRetryProcessOrder(position === 5);

	const failedProcess = getFailedProcessOrder(
		position === 5,
		lastProcessedStatus === 5
	);

	const lastDateUpdate =
		flowStatus?.actions[getDateCanceled(lastPosition)]?.LastUpdate;

	const handleIcons = (index) => {
		switch (index) {
			case 0:
				if (isDelivery) return getTypeIcons("MarkEmailRead");
				else return getTypeIcons("ShoppingBag");
			case 1:
				if (processing || processingRetry || loading)
					return getTypeIcons("WatchLater");
				else if (failedProcess) return getTypeIcons("Refresh");
				else return getTypeIcons("CheckCircle");
			case 2:
				return getTypeIcons("Cancel");
			default:
				break;
		}
	};

	const handleTitles = (index) => {
		switch (index) {
			case 0:
				if (isDelivery) return getTitleButton("WaitingDeliveryPending");
				else return getTitleButton("WaitingDeliveryWithdrawalPending");
			case 1:
				if (loading) return getTitleButton("Processing");
				else if (failedProcess) return getTitleButton("Reprocess");
				else if (isDelivery && stepCompleted)
					return getTitleButton("WaitingDeliveryCompleted");
				else if (isDelivery) return getTitleButton("WaitingDeliveryAction");
				else if (!isDelivery && stepCompleted)
					return getTitleButton("WaitingDeliveryWithdrawalCompleted");
				else if (!isDelivery)
					return getTitleButton("WaitingDeliveryWithdrawalAction");
				break;
			case 2:
				return getTitleButton("WaitingCancelPeriod");
			default:
				break;
		}
	};

	const handleInfo = (index) => {
		const process = processing || processingRetry || nextStep;
		if (position > 100 || isCanceled) {
			switch (index) {
				case 0:
				case 1:
					return getTypeInfo("NotStarted");
				case 2:
					if (position === 112 || isCanceled) return getTypeInfo("Waiting");
					else if (position > 112) return getTypeInfo(lastDateUpdate);
					else return getTypeInfo("NotStarted");
				default:
					break;
			}
		} else return showCorrectInfo(index, process);
	};

	const showCorrectInfo = (index, process) => {
		switch (index) {
			case 0:
				if (stepCompleted) return getTypeInfo(lastDateUpdate);
				else if (process || loadingRetry) return flowStatus?.integrator;
				else if (stepCancellation) return getTypeInfo("InCancellation");
				else if (failedProcess) return getTypeInfo("IntegrationFailure");
				else return getTypeInfo("NotStarted");
			case 1:
				if (loading) return getTypeInfo("WaitAMinute");
				else if (nextStep) return getTypeInfo("ClickToConfirm");
				else if (stepCompleted) return getTypeInfo(lastDateUpdate);
				else if (processingRetry || loadingRetry)
					return getTypeInfo("ProcessingRetry");
				else if (failedProcess) return getTypeInfo("TryAgain");
				else if (processing) return getTypeInfo("Processing");
				else return getTypeInfo("NotStarted");
			case 2:
				return getTypeInfo("NotStarted");
			default:
				break;
		}
	};

	const handleStyles = (index) => {
		const process = processing || processingRetry;
		const activation = failedProcess || nextStep;
		if (position > 100 || isCanceled) {
			switch (index) {
				case 0:
				case 1:
					return getButtonStyle("Empty");
				case 2:
					if (position === 112) return getButtonStyle("DangerOutline");
					if (position > 112 || isCanceled) return getButtonStyle("Canceled");
					else return getButtonStyle("Empty");
				default:
					break;
			}
		} else return showCorrectStyle(index, process, activation);
	};

	const showCorrectStyle = (index, process, activation) => {
		switch (index) {
			case 0:
				if (process || loading || loadingRetry)
					return getButtonStyle("SuccessOutline");
				else if (activation) return getButtonStyle("WarningOutline");
				else if (stepCompleted) return getButtonStyle("Completed");
				else return getButtonStyle("Empty");
			case 1:
				if (process || loading || loadingRetry)
					return getButtonStyle("RetryOutline");
				else if (nextStep) return getButtonStyle("Success");
				else if (failedProcess) return getButtonStyle("Warning");
				else if (stepCompleted) return getButtonStyle("Completed");
				else return getButtonStyle("Empty");
			case 2:
				return getButtonStyle("Empty");
			default:
				break;
		}
	};

	const handleIntegration = (index) => {
		const failed = "failed";
		const retry = "retry";
		const process = "process";
		const empty = "empty";
		switch (index) {
			case 0:
				if (loading || loadingRetry) return empty;
				else if (failedProcess) return failed;
				else return empty;
			case 1:
				if (processing || loading || loadingRetry) return process;
				else if (nextStep) return empty;
				else if (processingRetry) return retry;
				else return empty;
			default:
				break;
		}
	};

	const handleDisabledButton = (index) => {
		const clickConfirm = nextStep || failedProcess;
		if (loadingStatus) return true;
		else {
			if (index === 0 || index === 2) return true;
			if (index === 1) return clickConfirm ? false : true;
		}
	};

	const handleArrowStep = (index) => {
		const process = processing || processingRetry;

		if (position > 100) {
			switch (index) {
				case 0:
					return getTypeArrow(ArrowDownDashed, "arrow-down");
				case 1:
					return getTypeArrow(ArrowUpRightDashed, "arrow-up-right");
				case 2:
					if (position > 100)
						return getTypeArrow(ArrowStraightSmSolid, "arrow-straight-sm");
					else return getTypeArrow(ArrowStraightSmDashed, "arrow-straight-sm");
				default:
					break;
			}
		} else return handleArrowStepMain(index, process);
	};

	const handleArrowStepMain = (index, process) => {
		switch (index) {
			case 0:
				if (process || loading || loadingRetry)
					return getTypeArrow(ArrowDownInfo, "arrow-down");
				else if (nextStep) return getTypeArrow(ArrowDownSuccess, "arrow-down");
				else if (failedProcess)
					return getTypeArrow(ArrowDownWarning, "arrow-down");
				else if (stepCompleted)
					return getTypeArrow(ArrowDownSolid, "arrow-down");
				else return getTypeArrow(ArrowDownDashed, "arrow-down");
			case 1:
				if (stepCompleted)
					return getTypeArrow(ArrowUpRightSolid, "arrow-up-right");
				else return getTypeArrow(ArrowUpRightDashed, "arrow-up-right");
			case 2:
				return getTypeArrow(ArrowStraightSmDashed, "arrow-straight-sm");
			default:
				break;
		}
	};

	const createStatusDelivery = () => {
		return delivery.map((index) => (
			<>
				<ButtonStatus
					key={index}
					name={
						index === 2 && indexColumn === 3
							? "#orderFlowCancel"
							: failedProcess
							? ""
							: "#orderFlow"
					}
					icons={handleIcons(index)}
					title={handleTitles(index)}
					info={handleInfo(index)}
					style={handleStyles(index)}
					integration={handleIntegration(index)}
					disabled={handleDisabledButton(index)}
					position={position}
					isError={failedProcess}
				/>

				{handleArrowStep(index)}
			</>
		));
	};

	return createStatusDelivery();
}
