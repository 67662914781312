import React from 'react';

// import { Container } from './styles';

function DepartmentCel() {
  return (
    <div className="col-12 mb-2">
      <img src="https://via.placeholder.com/250x200" alt="teste"/>
      {/* <img src="https://via.placeholder.com/260x400" alt="teste"/> */}
    </div>
  );
}

export default DepartmentCel;