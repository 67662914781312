import React, { Component } from "react";
import { API } from "../../../../../API";
import CheckBoxList from "../../../../../components/CheckBoxList";
import Loading from "../../../../../components/Loading";
import { ToastContainer, toast } from "react-toastify";
export class State extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.save = this.save.bind(this);
	}
	state = {
		loading: true,
		states: [],
		item: [],
		listState: [],
	};

	componentDidMount() {
		//Consulta materiais disponíveis a partir do token do usuário.
		API.get("api/autocomplete/allstates")
			.then(async (response) => {
				// If request is good...
				this.setState({
					listState: response.data,
					loading: false,
				});
				this.forceUpdate();
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	}
	onChange(i) {
		if (this.state.states.indexOf(i) !== -1) {
			const index = this.state.states.indexOf(i);
			this.state.states.splice(index, 1);
			const index2 = this.state.item.indexOf(i);
			this.state.item.splice(index2, 1);
		} else {
			this.state.states.push(i);
			var component = {
				name: i.name,
				code: i.code,
			};
			this.setState({
				item: this.state.item.concat(component),
			});
		}
	}
	save(i) {
		var item = {
			states: this.state.item,
			value: this.state.item,
		};
		if (this.state.item.length > 1) {
			var text = " Estados";
		} else {
			var text = " Estado";
		}
		var itemView = {
			name: "Estado",
			value: this.state.item.length + text,
			itemId: "states",
			modal: "#conditionModalStates",
		};
		this.props.newCondition(item, itemView);
	}
	render() {
		return (
			<div
				class="modal fade"
				id="conditionModalStates"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="conditionModalStates"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h3 className="modal-title">Estados</h3>
							<button
								type="button"
								className="close text-danger"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body estados checkbox-list">
							{this.state.loading ? (
								<Loading />
							) : (
								<CheckBoxList
									list={this.state.listState}
									change={this.onChange}
								/>
							)}
						</div>
						<div
							className="modal-footer text-center"
							style={{ justifyContent: "center" }}
						>
							<button
								type="button"
								className="btn btn-light col-5"
								data-dismiss="modal"
							>
								Cancelar
							</button>
							<button
								type="button"
								className="btn btn-success col-5"
								onClick={() => this.save()}
								data-dismiss="modal"
							>
								Pronto
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default State;
