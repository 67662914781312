import React from "react";
import { useOrders } from "../../providers/OrdersContext";
import EditingOrders from "./EditingOrders";
import ContentLoader from "react-content-loader";
import moment from "moment";
import OrderAttachments from "../../../../components/OrderAttachments/OrderAttachments";

export default function ItemsOrders() {
	const { loading, order, buyer, seller, orderModel } = useOrders();

	const skeleton = () => {
		const skele = [0, 1, 2, 4, 5, 6];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={400}
				height={20}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="5" y="25" rx="3" ry="3" width="400" height="100" />
				<rect x="410" y="26" rx="3" ry="3" width="400" height="100" />
			</ContentLoader>
		));
	};

	return (
		<div className="detail-container">
			<div className="card box-three p-4">
				<div className="box-container-orders">
					<div className="box-header">
						<div className="box-img-orders ml-0">
							{localStorage.getItem("primaryLogo") !== "undefined" && (
								<img
									src={localStorage.getItem("primaryLogo")}
									alt="imageSeller"
									className="rounded"
								/>
							)}
						</div>
						<div className="d-flex justify-content-center align-items-start flex-column mt-3 ml-0 pl-1">
							<h2 className="card-title" style={{ fontSize: "20px" }}>
								PEDIDO
							</h2>
							<span>
								Pedido nº&nbsp;<strong>{order.friendlyCode}</strong>
							</span>
							<span>
								Data do pedido:&nbsp;
								<strong>
									{loading === true
										? ""
										: moment(order.createDate).format("DD/MM/YYYY HH:mm")}
								</strong>
							</span>
						</div>
					</div>
					<div className="box-body">
						<div className="">
							{loading ? (
								skeleton()
							) : (
								<>
									<p className="m-0 pb-1">Pedido para</p>
									<h2 className="card-title my-0 py-0">{buyer.fullName}</h2>
									<span className="py-1">{buyer.cpf && buyer.cpf}</span>
									{/* <span className="py-1">{role === 8 ? `****@******.com` : buyer.email}</span> */}
									<span className="py-1">
										{buyer.address &&
											` ${
												buyer.address.street == null ? "" : buyer.address.street
											}
											${buyer.address.number == null ? "" : buyer.address.number}
											${buyer.address.complement == null ? "" : buyer.address.complement}
										`}
									</span>
									<span className="py-1">
										{buyer.address &&
											`	${
												buyer.address.neighborhood == null
													? ""
													: buyer.address.neighborhood
											}
											${buyer.address.city == null ? "" : buyer.address.city}
											${buyer.address.state == null ? "" : buyer.address.state}
										`}
									</span>
									<span className="py-1">
										{buyer.address &&
											`${
												buyer.address == null ? "" : "CEP: " + buyer.address.cep
											}`}
									</span>
								</>
							)}
						</div>
						<div className="">
							{loading ? (
								skeleton()
							) : (
								<>
									<p className="m-0 pb-1">Pedido de</p>
									<h2 className="card-title my-0 py-0">{seller.fullName}</h2>
									<span className="py-1">{seller.cnpj}</span>
									<span className="py-1">{seller.email}</span>
									<span className="py-1">
										{seller.address &&
											`	${
												seller.address.street == null
													? ""
													: seller.address.street
											}
											${seller.address.number === null ? "" : seller.address.number}
											${seller.address.complement === null ? "" : seller.address.complement}
										`}
									</span>
									<span className="py-1">
										{seller.address &&
											`	${
												seller.address.neighborhood === null
													? ""
													: seller.address.neighborhood
											}
											${seller.address.city === null ? "" : seller.address.city}
											${seller.address.state === null ? "" : seller.address.state}
										`}
									</span>
									<span className="py-1">
										{seller.address &&
											`${
												seller.address.cep === null ? "" : seller.address.cep
											} `}
									</span>
								</>
							)}
						</div>
					</div>
				</div>
				<EditingOrders />
				<OrderAttachments orderModel={orderModel} />
			</div>
		</div>
	);
}
