import { Box, Stack } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import { TCOrder } from "../../../../domain/models/troquecommerce/TCOrder";
import CustomSkeleton from "../../../../MUICustomComponents/CustomSkeleton";
import { formatCurrency } from "../../../../infrastructure/utils/FormatUtils";

type Props = { data: TCOrder | null; isLoading: boolean };

export default function TCData({ data, isLoading }: Props) {
	// const renderReverseCoupon = (): JSX.Element => {
	// 	// TODO find order with reverse_coupon
	// 	return <></>;
	// };
	// const renderReversePayment = (): JSX.Element => {
	// 	// TODO find order with reverse_payment
	// 	return <></>;
	// };

	return (
		<Box className="card" p={3} position="relative">
			<div className={`icon-card-detail ${"currency_exchange"}`}>
				<i className="material-icons-outlined">currency_exchange</i>
			</div>
			<CustomTypography variant="secondaryTitle" isBold marginBottom={1}>
				Dados da troca/devolução
			</CustomTypography>
			{isLoading || !data ? (
				<CustomSkeleton quantity={5} height={20} />
			) : (
				<>
					<Stack direction="column" spacing={0.5}>
						<CustomTypography variant="default" color="grey60">
							<strong>Valor total do pedido: </strong>{" "}
							{formatCurrency(data.price)}
						</CustomTypography>
						<CustomTypography variant="default" color="grey60">
							<strong>Valor do vale-compras: </strong>{" "}
							{formatCurrency(data.exchange_value)}
						</CustomTypography>
						<CustomTypography variant="default" color="grey60">
							<strong>Valor do reembolso: </strong>{" "}
							{formatCurrency(data.refund_value)}
						</CustomTypography>
					</Stack>
					{/* {data.reverse_coupon && renderReverseCoupon()}
					{data.reverse_payment && renderReversePayment()} */}
					{data.is_second_request ||
						(data.is_second_reverse && (
							<>
								<CustomTypography variant="secondaryTitle" isBold>
									Detalhes
								</CustomTypography>
								<Stack direction="column" spacing={0.5}>
									{data.is_second_request && (
										<CustomTypography variant="default" color="grey60">
											- Segunda solicitação de troca
										</CustomTypography>
									)}
									{data.is_second_reverse && (
										<CustomTypography variant="default" color="grey60">
											- Solicitação de troca de um pedido que utilizou
											vale-compras gerado pela Troquecommerce. (Troca da troca)
										</CustomTypography>
									)}
								</Stack>
							</>
						))}
				</>
			)}
		</Box>
	);
}
