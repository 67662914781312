import React from "react";
import Button from "./Button";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function Empty(props) {
	return (
		<div className="empty">
			{props.orders ? (
				<div className="col-12 text-center">
					<img src={props.img} width="20%" />
					<h3>
						<b>Não há pedidos para os filtros inseridos</b>
					</h3>
				</div>
			) : (
				<div className="col-12 text-center">
					<img src={props.img} width="20%" />
					<h3>
						<b>Ops! Não tem nada por aqui...</b>
					</h3>
					<p>
						Você pode criar {props.genre} {props.name} clicando no botão abaixo
					</p>
					{props.tutorial ? (
						<Button
							type="button"
							className="btn btn-grey-80"
							onClick={() => props.onClick()}
						>
							Criar {props.name}
						</Button>
					) : (
						<>
							<Link className="btn btn-grey-80" to={props.link}>
								Criar {props.name}
							</Link>
							&nbsp;&nbsp;
						</>
					)}

					{props.isDefault ? (
						<Link
							className="btn btn-new-width btn-info light btn-icon"
							to={"new-default"}
						>
							{props.isDefaultName}
						</Link>
					) : null}
					{props.import ? (
						<Link
							className="btn btn-new-width btn-info light btn-icon"
							to={"/import"}
						>
							<span className="material-icons" style={{ fontSize: "22px" }}>
								arrow_upward
							</span>
						</Link>
					) : null}
					{props.importIn && (
						<Button type="button" className="btn btn-info light btn-icon">
							<input
								type="file"
								onChange={props.onChange}
								style={{ opacity: 0, position: "absolute", zIndex: 1 }}
							/>
							<i className="material-icons-outlined">upload</i>
							&nbsp;&nbsp;Importar Vendedores
						</Button>
					)}
				</div>
			)}
		</div>
	);
}
