import React, { Dispatch, SetStateAction } from "react";
import { i18n } from "../../translate/i18n";
import { Box, Grid, Typography } from "@mui/material";
import CustomInput from "../../MUICustomComponents/CustomInput";
import { AddresseeInputs } from "./AddresseeInputs";
import StateAutocomplete from "../../components/StateAutocomplete/StateAutocomplete";
import UpdateAddresseeDialogService from "./UpdateAddresseeDialogService";
import CustomDialog from "../../MUICustomComponents/CustomDialog";
import { FormValidator } from "../../infrastructure/utils/FormUtils";
import FixedAlertComponent from "../FixedAlertComponent/FixedAlertComponent";
import { OrderModel } from "../../domain/models/commerce/OrderModel";
import ZipCodeInput from "../ZipCodeinput/ZipCodeInput";

type Props = {
	orderModel: OrderModel;
	isAddresseeDialogOpen: boolean;
	setIsAddresseeDialogOpen: Dispatch<SetStateAction<boolean>>;
	callback: Function;
};

export default function UpdateAddresseeDialog({
	orderModel,
	isAddresseeDialogOpen,
	setIsAddresseeDialogOpen,
	callback,
}: Props) {
	const {
		deliveryData,
		isLoadingUpdate,
		isLoadingZipCode,
		setIsLoadingZipCode,
		currentState,
		setCurrentState,
		formErrors,
		handleDeliveryInfoChange,
		handleZipCodeChange,
		handleAutocompleteChange,
		handleSubmit,
		handleCloseDialog,
	} = UpdateAddresseeDialogService(orderModel, callback);

	const inputField = (fieldName: AddresseeInputs) => {
		return (
			<CustomInput
				name={fieldName}
				title={i18n.t(`addresseeDialog.${[fieldName]}`)}
				placeholder={i18n.t(`addresseeDialog.${[fieldName]}`)}
				value={deliveryData[fieldName] ?? ""}
				onChange={handleDeliveryInfoChange}
				disabled={isLoadingZipCode}
				error={FormValidator.hasInputError(formErrors, fieldName)}
				helperText={FormValidator.translateHelperTextList(
					formErrors,
					fieldName
				)}
			/>
		);
	};

	const addresseeForm = () => {
		return (
			<Grid container spacing={2} mb={2}>
				<Grid item xs={12}>
					<Typography fontSize={16} sx={{ fontWeight: "bold" }}>
						{i18n.t("addresseeDialog.Addressee").toString()}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					{inputField(AddresseeInputs.FullName)}
				</Grid>
				<Grid item xs={12}>
					<Typography fontSize={16} sx={{ fontWeight: "bold" }}>
						{i18n.t("addresseeDialog.DeliveryAddress").toString()}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<ZipCodeInput
						inputName={AddresseeInputs.ZipCode}
						zipCode={deliveryData.zipCode}
						isLoadingZipCode={isLoadingZipCode}
						setIsLoadingZipCode={setIsLoadingZipCode}
						handleZipCodeChange={handleZipCodeChange}
						formErrorText={FormValidator.translateHelperTextList(
							formErrors,
							AddresseeInputs.ZipCode
						)}
					/>
				</Grid>
				<Grid item xs={8}>
					{inputField(AddresseeInputs.Street)}
				</Grid>
				<Grid item xs={3}>
					{inputField(AddresseeInputs.Number)}
				</Grid>
				<Grid item xs={3}>
					{inputField(AddresseeInputs.Complement)}
				</Grid>
				<Grid item xs={6}>
					{inputField(AddresseeInputs.Neighborhood)}
				</Grid>
				<Grid item xs={6}>
					{inputField(AddresseeInputs.City)}
				</Grid>
				<Grid item xs={6}>
					<StateAutocomplete
						id={AddresseeInputs.State}
						currentState={currentState}
						setCurrentState={setCurrentState}
						handleAutocompleteChange={handleAutocompleteChange}
						stateCode={deliveryData.state ?? null}
						disabled={isLoadingZipCode}
						error={FormValidator.hasInputError(
							formErrors,
							AddresseeInputs.State
						)}
						helperText={FormValidator.translateHelperTextList(
							formErrors,
							AddresseeInputs.State
						)}
					/>
				</Grid>
			</Grid>
		);
	};

	return (
		<CustomDialog
			isOpen={isAddresseeDialogOpen}
			setIsOpen={setIsAddresseeDialogOpen}
			closeCallback={handleCloseDialog}
			dialogInfo={{
				title: i18n.t("addresseeDialog.Title"),
			}}
			actionButton={{
				text: i18n.t(`buttons.General.BtnSave`),
				variant: "contained",
				color: "success",
				onClick: handleSubmit,
				isDisabled:
					isLoadingUpdate ||
					isLoadingZipCode ||
					FormValidator.hasError(formErrors),
				isLoading: isLoadingUpdate,
			}}
			dialogStyles={{ titleVariant: "title" }}
		>
			{addresseeForm()}
			<FixedAlertComponent alertType={"warning"}>
				<Box>
					<ul style={{ margin: 0, fontSize: "8px", paddingLeft: "20px" }}>
						<li>
							<Typography fontSize={12}>
								{i18n.t("addresseeDialog.OrderAlreadyInvoiced").toString()}
							</Typography>
						</li>
						<li>
							<Typography fontSize={12}>
								{i18n
									.t("addresseeDialog.PostageLabelAlreadyGenerated")
									.toString()}
							</Typography>
						</li>
					</ul>
				</Box>
			</FixedAlertComponent>
		</CustomDialog>
	);
}
