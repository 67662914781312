import buttons from "./buttonsPT.json";
import components from "./componentsPT.json";
import input from "./inputPT.json";
import searchInputClose from "./searchInputClosePT.json";
import staticMessage from "./staticMessagePT.json";
import addresseeDialog from "./addresseeDialogPT.json";
import deliveryReceiptDialog from "./deliveryReceiptDialogPT.json";
import attachments from "./attachmentsPT.json";
import orderTable from "./orderTablePT.json";
import orderCheck from "./orderCheckPT.json";

export const componentsPT = {
	buttons,
	components,
	input,
	searchInputClose,
	staticMessage,
	addresseeDialog,
	deliveryReceiptDialog,
	attachments,
	orderTable,
	orderCheck,
};
