import React, { useCallback, useEffect, useState } from "react";
import { Multiselect } from "react-widgets";
import { API, APIV2 } from "../../../../API";
import EditorHtml from "../../../../assets/EditorHtml";
import NewDropImage from "../../../../components/NewDropImage";
import DatePickerNeo from "../../../../components/DatePickerNeo";
import "react-widgets/dist/css/react-widgets.css";
import { toast } from "react-toastify";
import Button from "../../../../components/Button";
import ContentLoader from "react-content-loader";
import MaskWeight from "../../../../components/MaskWeight";
import MaskMeasure from "../../../../components/MaskMeasure";
import DOMPurify from "dompurify";
import CategoriesCheckbox from "../../../../components/modals/CategoriesCheckbox/CategoriesCheckbox";
import { marketingManagementApi } from "../../../../infrastructure/api/MarketingManagementApi";
import { i18n } from "../../../../translate/i18n";
import CustomAutocomplete from "../../../../MUICustomComponents/CustomAutocomplete";

export default function DataProducts(props) {
	const [selectCategories, setSelectCategories] = useState([]);
	const [tagList, setTagList] = useState([]);
	const [releaseDate, setReleaseDate] = useState();
	const [disabledButton, setDisablebutton] = useState(false);
	const [loading, setLoading] = useState(true);
	const [loadingImages, setLoadingImages] = useState(false);
	const [images, setImages] = useState([]);
	const [useCatalogImages, setUseCatalogImages] = useState([]);
	const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false);
	const [selectedValues, setSelectedValues] = useState([]);
	const [conditions, setConditions] = useState([]);
	const [currentCondition, setCurrentCondition] = useState(null);
	const [currentConditionInput, setCurrentConditionInput] = useState("");

	const [product, setProduct] = useState({
		name: "",
		code: "",
		url: null,
		imageUrl: "",
		hexColor: null,
		description: "",
		categories: [],
		images: [],
		isActive: true,
		keywords: "",
		releaseDate: "",
		height: "",
		width: "",
		lenght: "",
		weight: "",
		auxCode: "",
		referenceCode: "",
		complementaryName: "",
		brand: "",
		tags: [],
		collections: [],
	});

	useEffect(() => {
		getConditions();
		if (props.id) {
			getProducts();
		} else {
			setLoading(false);
		}
		getTags();
	}, []);

	const filterConditionOptions = (option) => option.slug ?? "";
	const getConditionOptionLabel = (option) => option.slug;
	const isOptionEqualToValue = (option, value) => option.id === value.id;
	const handleConditionChange = (condition) => setCurrentCondition(condition);
	const getConditions = async () => {
		try {
			const data = await marketingManagementApi.getConditions();
			setConditions(data);
			if (props.id) getProductCommercialConditionsByProductId(props.id, data);
		} catch {
			toast.error(i18n.t("errorMessages.ERR.REQUEST"));
		}
	};

	const getProductCommercialConditionsByProductId = async (
		productId,
		conditionsList
	) => {
		try {
			const data =
				await marketingManagementApi.getProductCommercialConditionsByProductId(
					productId
				);
			const foundCurrentCondition = conditionsList.find(
				(condition) => condition.id === data.commercialConditionId
			);
			if (foundCurrentCondition) setCurrentCondition(foundCurrentCondition);
		} catch {
			toast.error(i18n.t("errorMessages.ERR.REQUEST"));
		}
	};
	const upsertProductCommercialConditionInBatch = async (
		currentCondition,
		productId
	) => {
		if (!currentCondition) return;
		const data = [
			{ commercialConditionId: currentCondition.id, productId: productId },
		];
		const success =
			await marketingManagementApi.upsertProductCommercialConditionInBatch(
				data
			);
		if (!success) {
			toast.error(i18n.t("errorMessages.ERR.REQUEST"));
		}
	};

	const getProducts = async () => {
		try {
			const response = await APIV2.get("catalog/products/" + props.id);

			setProduct(response.data);

			setUseCatalogImages(
				response.data.images
					? response.data.images.sort(function (a, b) {
							return a.order - b.order;
					  })
					: []
			);
			setImages(
				response.data.images
					? response.data.images.sort(function (a, b) {
							return a.order - b.order;
					  })
					: []
			);
			if (response.data.releaseDate) {
				setReleaseDate(new Date(response.data.releaseDate));
			}

			setSelectedValues(response.data.categories.map((item) => item.id));

			setLoading(false);
		} catch (error) {
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	const getTags = async () => {
		try {
			const response = await API.get("api/tags");
			if (props.edit) {
				setTagList(response.data);
			} else {
				setTagList(response.data);
			}
		} catch (error) {
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	const createProd = (e) => {
		e.preventDefault();
		let imagesProduct = product.images ? product.images : [];
		for (let i = 0; i < imagesProduct.length; i++) {
			product.images[i].order = i;
		}
		create(product);
	};

	const changeBool = useCallback(() => {
		setProduct((prevState) => ({ ...prevState, isActive: !product.isActive }));
	}, [product.isActive]);

	const handleCreate = (name) => {
		let newOption = {
			name,
			id: "teste",
		};
		setTagList((prevState) => [...prevState, newOption]);
	};

	const changeAnwser = (e) => {
		product.description = DOMPurify.sanitize(e);
	};

	const recoveryStateImage = (e) => {
		let newCatalogArrayImages = [];
		let arrayImages = [];
		newCatalogArrayImages = e.map((image, index) => {
			return {
				originalImageUrl: image.imageUrl,
				order: index + 1,
				uriHash: !image.uriHash ? "" : image.uriHash,
			};
		});
		setUseCatalogImages(newCatalogArrayImages);
		arrayImages = e.map((image, index) => {
			return {
				imageUrl: image.imageUrl,
				order: index + 1,
				uriHash: !image.uriHash ? "" : image.uriHash,
			};
		});
		setImages(arrayImages);
		// }

		let imageArray = e.map((image, index) => ({
			clientId: null,
			createDate: image.createDate,
			id: image.id,
			imageUrl: image.imageUrl,
			isActive: image.isActive,
			lastUpdate: image.lastUpdate,
			order: index,
		}));

		setProduct((prevState) => ({ ...prevState, images: imageArray }));
	};

	const handleChange = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		setProduct((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleChangeDate = async (date) => {
		var json = product["releaseDate"];
		json = date;
		product["releaseDate"] = json;
		setReleaseDate(json);
	};

	const newCondition = (categoryIds) => {
		const newCategoryIds = [];
		let amountCategories = [];

		if (categoryIds.length === 0) {
			setSelectedValues([]);
		} else if (categoryIds.includes("")) {
			setSelectedValues([...selectedValues]);
			amountCategories = [...selectedValues];
		} else {
			setSelectedValues(categoryIds);
			amountCategories = categoryIds;
		}

		amountCategories.forEach((id) => {
			const categoryId = { id: id };
			newCategoryIds.push(categoryId);
		});

		setSelectCategories(newCategoryIds);

		setProduct((prevState) => ({ ...prevState, categories: newCategoryIds }));
	};

	const getImagesCatalog = async () => {
		setLoadingImages(true);
		try {
			const response = await APIV2.get(
				`catalog/products/${product.code}/images`
			);
			setUseCatalogImages(response.data);
			setLoadingImages(false);
		} catch (error) {}
	};

	const handleWeight = useCallback((weightValue) => {
		let formatWeight = weightValue;
		formatWeight = formatWeight.replace(/,/, ".");
		setProduct((prevState) => ({
			...prevState,
			weight: parseFloat(formatWeight),
		}));
	}, []);

	const handleMeasure = useCallback((measureName, measureValue) => {
		let formatMeasure = measureValue;
		formatMeasure = formatMeasure.replace(/,/, ".");
		setProduct((prevState) => ({
			...prevState,
			[measureName]: parseFloat(formatMeasure),
		}));
	}, []);

	const create = (product) => {
		setDisablebutton(true);
		product.weight = !product.weight ? 0 : product.weight;
		product.width = !product.width ? 0 : product.width;
		product.lenght = !product.lenght ? 0 : product.lenght;
		product.height = !product.height ? 0 : product.height;
		if (localStorage.getItem("UseCatalogProcessor") === "true") {
			APIV2.put(
				`/catalog/products/${product.code}/images`,
				localStorage.getItem("UseCatalogProcessor") === "true"
					? useCatalogImages
					: product.images
			);

			APIV2.put(
				`/catalog/products/${product.code}/categories`,
				selectCategories
			);
		}
		if (props.id) {
			APIV2.put(`catalog/products/${props.id}`, product)
				.then(async (response) => {
					await upsertProductCommercialConditionInBatch(
						currentCondition,
						props.id
					);
					toast.success("Produto editado com sucesso! 😃");
					if (localStorage.getItem("UseCatalogProcessor") === "true") {
						getImagesCatalog();
					}
					setLoading(false);
					setDisablebutton(false);
				})
				.catch((error) => {
					setLoading(false);
					setDisablebutton(false);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				});
		} else {
			setLoading(true);
			APIV2.post("catalog/products", product)
				.then(async (response) => {
					await upsertProductCommercialConditionInBatch(
						currentCondition,
						response.data.id
					);
					!response.data
						? (window.location.href =
								`/products/${props.page}/edit/` + product.code)
						: (window.location.href =
								`/products/${props.page}/edit/` + response.data.id);
					if (localStorage.getItem("UseCatalogProcessor") === "true") {
						getImagesCatalog();
					}
					setDisablebutton(false);
				})
				.catch((error) => {
					setLoading(false);
					setDisablebutton(false);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				});
		}
	};

	const renderScreen = () => {
		return (
			<div className="card">
				<div className="card-header" id="headingOne">
					<div className="mb-0">
						<h4 className="card-title">Dados do Produto</h4>
					</div>
				</div>

				<div id="dadosProdutos" className="#" aria-labelledby="headingOne">
					<div className="card-content">
						<div className="card-body py-0">
							{loading ? (
								<div className="row">{skeletonData()}</div>
							) : (
								<>
									<div className="row">
										<div className="form-group col-sm-6">
											<label htmlFor="productName">
												<strong>Nome do produto</strong>
											</label>
											<div>
												<input
													type="text"
													className="form-control"
													id="productName"
													name="name"
													value={product.name}
													onChange={handleChange}
													required
												/>
											</div>
										</div>
										<div className="form-group col-sm-5">
											<label htmlFor="complementaryName">
												<strong>Nome complementar</strong>
											</label>
											<div>
												<input
													type="text"
													className="form-control"
													id="complementaryName"
													name="complementaryName"
													value={product.complementaryName}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="form-group col-sm-1">
											<label htmlFor="productActive">
												<strong>Ativo?</strong>
											</label>
											<br />
											<label className="switch ">
												<input
													id="Status"
													type="checkbox"
													name="isActive"
													value={product.isActive}
													defaultChecked={product.isActive}
													onChange={changeBool}
													disabled={disabledButton}
												/>
												<span className="slider round"></span>
											</label>
										</div>
										<div className="form-group col-sm-4">
											<label htmlFor="productCode">
												<strong>Código do produto</strong>
											</label>
											<div>
												<input
													type="text"
													className="form-control"
													disabled={props.id ? true : false}
													id="productCode"
													placeholder="0"
													name="code"
													value={product.code}
													required
													onChange={handleChange}
												/>
											</div>
										</div>

										<div className="form-group col-sm-4">
											<label htmlFor="productCode">
												<strong>Código auxiliar do produto</strong>
											</label>
											<div>
												<input
													type="text"
													className="form-control"
													id="auxCode"
													placeholder="0"
													name="auxCode"
													value={product.auxCode}
													onChange={handleChange}
												/>
											</div>
										</div>

										<div className="form-group col-sm-4">
											<label htmlFor="productCode">
												<strong>Código de referência do produto</strong>
											</label>
											<div>
												<input
													type="text"
													className="form-control"
													id="referenceCode"
													placeholder="0"
													name="referenceCode"
													value={product.referenceCode}
													onChange={handleChange}
												/>
											</div>
										</div>

										<div className="form-group col-sm-4">
											<label htmlFor="productCategories">
												<strong>Categorias</strong>
											</label>
											<br />
											<div className="input-group ">
												<div className="input-group-prepend">
													<div className="input-group-text">Categorias</div>
												</div>
												<input
													id="productCategories"
													type="button"
													className="form-control"
													value={`${selectedValues.length} Selecionadas`}
													onClick={() => setIsOpenCategoryModal(true)}
												/>
											</div>
										</div>
										<div className="form-group col-sm-4">
											<label htmlFor="commercialCondition">
												<strong>
													{i18n.t("products.CommercialCondition")}
												</strong>
											</label>
											<CustomAutocomplete
												id="commercialCondition"
												placeholder={i18n.t("products.CommercialCondition")}
												options={conditions}
												value={currentCondition}
												inputValue={currentConditionInput}
												filterOptions={filterConditionOptions}
												getOptionLabel={getConditionOptionLabel}
												isOptionEqualToValue={isOptionEqualToValue}
												onSelectChange={handleConditionChange}
												onInputChange={setCurrentConditionInput}
											/>
										</div>
										<div className="form-group col-sm-3">
											<DatePickerNeo
												className="form-control"
												label="Data de lançamento"
												dateFormat="dd/MM/yyyy"
												time={false}
												selected={releaseDate}
												onChange={handleChangeDate}
											/>
										</div>

										<div className="form-group col-6">
											<label htmlFor="productBrand">
												<strong>Marca</strong>
											</label>
											<input
												type="text"
												className="form-control"
												id="productBrand"
												name="brand"
												value={product.brand}
												onChange={handleChange}
											/>
										</div>
										<div className="form-group col-6">
											<label htmlFor="productCollections">
												<strong>Coleções</strong>
											</label>
											<Multiselect
												data={tagList}
												value={
													localStorage.getItem("UseCatalogProcessor") === "true"
														? product.collections
														: product.tags
												}
												valueField="id"
												className="form-control"
												textField={(item) => item.name}
												allowCreate="onFilter"
												onCreate={(name) => handleCreate(name)}
												onChange={(value) =>
													setProduct((prevState) =>
														localStorage.getItem("UseCatalogProcessor") ===
														"true"
															? {
																	...prevState,
																	collections: value,
															  }
															: {
																	...prevState,
																	tags: value,
															  }
													)
												}
												busy={tagList.length >= 0 ? false : true}
											/>
										</div>

										<div className="form-group col-12">
											<label htmlFor="productKeywords">
												<strong>Palavras chave</strong>
											</label>
											<input
												type="text"
												className="form-control"
												id="productKeywords"
												name="keywords"
												value={product.keywords}
												onChange={handleChange}
											/>
										</div>
									</div>

									<CategoriesCheckbox
										isOpenCategoryModal={isOpenCategoryModal}
										setIsOpenCategoryModal={setIsOpenCategoryModal}
										selectedValues={selectedValues}
										handleFunction={newCondition}
										isMultiSelect={true}
									/>
								</>
							)}
						</div>

						<div className="card-header">
							<h4 className="card-title">Medidas e peso do produto</h4>
						</div>
						<div className="card-content">
							<div className="card-body py-0">
								{loading ? (
									<div className="row">{skeletonMeasure()}</div>
								) : (
									<div className="row">
										<div className="form-group col-sm-3">
											<label htmlFor="productKeywords">
												<strong>Peso (Kg)</strong>
											</label>
											<MaskWeight
												className="form-control"
												defaultValue={product.weight.toLocaleString("pt-br")}
												name="weight"
												handleWeight={handleWeight}
												ref={(ref) => ref && ref.focus()}
												onFocus={(e) =>
													(e.currentTarget.selectionStart =
														e.currentTarget.selectionEnd =
															e.currentTarget.value.length)
												}
											/>
										</div>
										<div className="form-group col-sm-3">
											<label htmlFor="productKeywords">
												<strong>Largura (cm)</strong>
											</label>
											<MaskMeasure
												className="form-control"
												defaultValue={product.width.toLocaleString("pt-br")}
												ref={(ref) => ref && ref.focus()}
												onFocus={(e) =>
													(e.currentTarget.selectionStart =
														e.currentTarget.selectionEnd =
															e.currentTarget.value.length)
												}
												name="width"
												handleMeasure={handleMeasure}
											/>
										</div>
										<div className="form-group col-sm-3">
											<label htmlFor="productKeywords">
												<strong>Comprimento (cm)</strong>
											</label>
											<MaskMeasure
												className="form-control"
												defaultValue={product.lenght.toLocaleString("pt-br")}
												ref={(ref) => ref && ref.focus()}
												onFocus={(e) =>
													(e.currentTarget.selectionStart =
														e.currentTarget.selectionEnd =
															e.currentTarget.value.length)
												}
												name="lenght"
												handleMeasure={handleMeasure}
											/>
										</div>
										<div className="form-group col-sm-3">
											<label htmlFor="productKeywords">
												<strong>Altura (cm)</strong>
											</label>
											<MaskMeasure
												className="form-control"
												defaultValue={product.height.toLocaleString("pt-br")}
												ref={(ref) => ref && ref.focus()}
												onFocus={(e) =>
													(e.currentTarget.selectionStart =
														e.currentTarget.selectionEnd =
															e.currentTarget.value.length)
												}
												name="height"
												handleMeasure={handleMeasure}
											/>
										</div>
									</div>
								)}

								<div className="card-header px-0">
									<h4 className="card-title">Descrição do produto</h4>
								</div>
								<div className="card-content">
									<div className="card-body p-0 m-0">
										{loading ? (
											<div className="row">{skeletonDescription()}</div>
										) : (
											<div className="row">
												<div className="form-group col">
													<EditorHtml
														change={changeAnwser}
														data={product.description}
													/>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="card-header">
								<h4 className="card-title">Imagens do produto</h4>
							</div>
							<div className="card-content">
								<div className="card-body py-0">
									<div className="row">
										{loading || loadingImages ? (
											skeletonImage()
										) : (
											<div className="form-group col-12">
												<NewDropImage
													type={"carrousel"}
													data={images}
													recoveryStateLogo={recoveryStateImage}
													height={"180px"}
													width={"100%"}
													background={"#fff"}
													colorText={"#747E8B"}
												/>
											</div>
										)}
										<div className="form-group col-12 mt-3 text-right">
											<Button
												className="btn-success"
												type="submit"
												id="submit"
												loading={disabledButton}
												disable={disabledButton}
												onClick={createProd}
											>
												{props.id ? "Salvar alterações" : "Criar produto"}
											</Button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const skeletonData = () => {
		return (
			<>
				<ContentLoader
					speed={2}
					width={1100}
					height={"60"}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", margin: "0 0 16px 0" }}
				>
					<rect x="10" y="25" rx="3" ry="3" width="520" height="40" />
					<rect x="550" y="25" rx="3" ry="3" width="430" height="40" />
					<rect x="990" y="25" rx="3" ry="3" width="40" height="24" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={"60"}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", margin: "0 0 16px 0" }}
				>
					<rect x="10" y="25" rx="3" ry="3" width="335" height="40" />
					<rect x="360" y="25" rx="3" ry="3" width="335" height="40" />
					<rect x="710" y="25" rx="3" ry="3" width="335" height="40" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={"60"}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", margin: "0 0 16px 0" }}
				>
					<rect x="10" y="25" rx="3" ry="3" width="335" height="40" />
					<rect x="360" y="25" rx="3" ry="3" width="335" height="40" />
					<rect x="710" y="25" rx="3" ry="3" width="190" height="40" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={"60"}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", margin: "0 0 16px 0" }}
				>
					<rect x="10" y="25" rx="3" ry="3" width="1025" height="40" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={"60"}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", margin: "0 0 16px 0" }}
				>
					<rect x="10" y="25" rx="3" ry="3" width="1025" height="40" />
				</ContentLoader>
			</>
		);
	};

	const skeletonMeasure = () => {
		return (
			<ContentLoader
				speed={2}
				width={1100}
				height={"60"}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%", margin: "0 0 16px 0" }}
			>
				<rect x="10" y="25" rx="3" ry="3" width="245" height="40" />
				<rect x="280" y="25" rx="3" ry="3" width="245" height="40" />
				<rect x="540" y="25" rx="3" ry="3" width="245" height="40" />
				<rect x="800" y="25" rx="3" ry="3" width="245" height="40" />
			</ContentLoader>
		);
	};

	const skeletonDescription = () => {
		return (
			<ContentLoader
				speed={2}
				width={1100}
				height={"380"}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 380"
				style={{ width: "100%", margin: "0 0 16px 0" }}
			>
				<rect x="10" y="25" rx="3" ry="3" width="1025" height="340" />
			</ContentLoader>
		);
	};

	const skeletonImage = () => {
		return (
			<ContentLoader
				speed={2}
				width={1100}
				height={200}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 200"
				style={{ width: "100%", margin: "0 0 16px 0" }}
			>
				<rect x="10" y="25" rx="3" ry="3" width="1025" height="180" />
			</ContentLoader>
		);
	};

	return <>{renderScreen()}</>;
}
