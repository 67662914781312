import React, { ReactNode, forwardRef } from "react";
import styled from "styled-components";
import { paletteObject } from "../theme/foundations/palette";

interface StyledProps {
	minHeight?: string;
	maxHeight?: string;
}

export const StyledScrollbar = styled.div<StyledProps>`
	min-height: ${({ minHeight }) => minHeight};
	max-height: ${({ maxHeight }) => maxHeight};
	overflow: auto;

	&::-webkit-scrollbar {
		width: 6px;
		height: 6px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: ${paletteObject.grey20.main};
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: ${paletteObject.grey50.main};
		height: 40px;
	}
`;

interface CustomScrollbarProps {
	children: ReactNode;
	minHeight: string;
	maxHeight: string;
}

const CustomScrollbar = forwardRef<HTMLDivElement, CustomScrollbarProps>(
	({ children, minHeight, maxHeight }, ref) => {
		return (
			<StyledScrollbar minHeight={minHeight} maxHeight={maxHeight} ref={ref}>
				{children}
			</StyledScrollbar>
		);
	}
);

export default CustomScrollbar;
