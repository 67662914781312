import React from "react";
import ContentLoader from "react-content-loader";

export default function PaymentSkeleton(): JSX.Element[] {
	return [...Array(2)].map((_, index) => (
		<ContentLoader
			key={index}
			speed={2}
			width={740}
			height={80}
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
			viewBox="0 0 1100 80"
			style={{ width: "100%" }}
		>
			<rect x="16" y="25" rx="3" ry="3" width="1000" height="60" />
		</ContentLoader>
	));
}
