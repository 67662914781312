import React from "react";
import FinancialHeader from "./components/FinancialHeader";
import FinancialPayables from "./components/FinancialPayables";
import FinancialWithdraw from "./components/FinancialWithdraw";
import FinancialAnticipations from "./components/FinancialAnticipations";
import "./FinancialStyle.scss";
import FinancialTabs from "./components/FinancialTabs";
import EmptyRecipientId from "./components/Empty/EmptyRecipientId";
import { useFinancial } from "./providers/FinancialContext";

export default function Financial({ url }) {
	const { seller, hasRecepientId } = useFinancial();

	return (
		<div className="container dash-content">
			<div className="card">
				<FinancialHeader />
				{hasRecepientId ? (
					<>
						<FinancialTabs url={url} />
						{url === "/financial/payables" && <FinancialPayables />}
						{url === "/financial/withdraw" && <FinancialWithdraw />}
						{url === "/financial/anticipations" && <FinancialAnticipations />}
					</>
				) : (
					<EmptyRecipientId id={seller} />
				)}
			</div>
		</div>
	);
}
