import React, {
	ChangeEvent,
	DragEvent,
	FC,
	useCallback,
	useRef,
	useState,
} from "react";
import { i18n } from "../../translate/i18n";

import * as Styled from "./UploadImageStyles";
import { DeleteOutline, UploadRounded } from "@mui/icons-material";
import CustomIcon from "../../MUICustomComponents/CustomIcon";
import { API } from "../../API";
import { isValidString } from "../../infrastructure/utils/TypeValidator";
import CustomTypography from "../../MUICustomComponents/CustomTypography";
import NewToastComponent from "../NewToastComponent";
import { paletteObject } from "../../theme/foundations/palette";
import { Box } from "@mui/material";

interface DropzoneProps {
	onFileUpload: (file: string) => void;
	image: string;
	helpText?: string;
	allowPdf?: boolean;
}

export const UploadImage: FC<DropzoneProps> = ({
	onFileUpload,
	image,
	helpText,
	allowPdf,
}) => {
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [imageUrl, setImageUrl] = useState<string>("");

	function isValidImageType(fileType: string): boolean {
		const allowedTypes = ["image/jpeg", "image/jpg", "image/gif", "image/png"];
		if (allowPdf) allowedTypes.push("application/pdf");
		return allowedTypes.includes(fileType);
	}

	const handleDrop = useCallback(
		(event: DragEvent<HTMLDivElement>) => {
			event.preventDefault();

			const files = event.dataTransfer.files;

			if (files) {
				validateImage(Array.from(files));
			}
		},
		[onFileUpload]
	);

	const handleFileInputChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const files = event.target.files;

			if (files) {
				validateImage(Array.from(files));
			}
		},
		[onFileUpload]
	);

	const validateImage = (files: File[]) => {
		if (!files) return;

		if (files && files.length > 0) {
			const uploadedFile = files[0];

			if (isValidImageType(uploadedFile.type)) {
				getImageUrl(uploadedFile);
			} else {
				NewToastComponent({
					status: "error",
					title: i18n.t("errorMessages.ERR.VALID.IMAGE"),
				});
			}
		}
	};

	const getImageUrl = (uploadedFile: File) => {
		const formData = new FormData();

		formData.append("file", uploadedFile);
		API.post("api/images", formData, {
			headers: { ContentType: "multipart/form-data" },
		})
			.then((response: any) => {
				onFileUpload(response.data.absoluteUrl);
				setImageUrl(response.data.absoluteUrl);
			})
			.catch(() => {
				NewToastComponent({
					status: "error",
					title: i18n.t("errorMessages.ERR.LOAD.IMAGE"),
				});
			});
	};

	const handleOpenFile = useCallback(() => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	}, []);

	const removeImage = () => {
		setImageUrl("");
		onFileUpload("");
	};

	const backgroundImage = (): string => {
		if (isValidString(imageUrl) || isValidString(image)) {
			return paletteObject.white.main;
		}
		return paletteObject.bgNeo.main;
	};

	return (
		<>
			<Styled.DragDropImage
				onDragOver={(event: DragEvent<HTMLDivElement>) =>
					event.preventDefault()
				}
				onDrop={handleDrop}
				onClick={handleOpenFile}
				background={backgroundImage()}
			>
				{isValidString(imageUrl) || isValidString(image) ? (
					<>
						<Styled.Image src={isValidString(image) ? image : imageUrl} />
						<Styled.DeleteImage onClick={removeImage}>
							<CustomIcon icon={<DeleteOutline />} variant="default" />
						</Styled.DeleteImage>
					</>
				) : (
					<>
						<input
							type="file"
							style={{ display: "none" }}
							ref={fileInputRef}
							onChange={handleFileInputChange}
						/>
						<CustomIcon icon={<UploadRounded />} variant="default" />
						<CustomTypography variant="small">
							{i18n
								.t(`staticMessage.SelectMessage.DragAndDropImage`)
								.toString()}
						</CustomTypography>
					</>
				)}
			</Styled.DragDropImage>
			{helpText && (
				<Box mt={0.5}>
					<CustomTypography variant="small" fontStyle={"italic"} color="grey60">
						{helpText}
					</CustomTypography>
				</Box>
			)}
		</>
	);
};
