import React from "react";
import ChromePicker from "../../../../../components/CustomChromePicker/ChromePicker";
import CustomIcon from "../../../../../MUICustomComponents/CustomIcon";
import CustomTypography from "../../../../../MUICustomComponents/CustomTypography";
import AttributesService from "./AttributesService";
import CustomInput from "../../../../../MUICustomComponents/CustomInput";
import DeleteAttribute from "../Modals/DeleteAttribute";
import CreateAttribute from "../Modals/CreateAttribute";
import {
	DragDropContext,
	Droppable,
	Draggable,
	DraggableProvided,
	DroppableProvided,
} from "react-beautiful-dnd";
import { paletteObject } from "../../../../../theme/foundations/palette";
import { AttributeTypes } from "../../enums/AttributeTypes";
import { FormValidator } from "../../../../../infrastructure/utils/FormUtils";
import { i18n } from "../../../../../translate/i18n";
import { AttributeInputs } from "../../enums/AttributeInputs";
import {
	EditOutlined,
	DeleteOutlineOutlined,
	Close,
	Done,
	DragHandleRounded,
	RotateRight,
} from "@mui/icons-material";
import {
	Box,
	Stack,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Button,
} from "@mui/material";
import { AttributesDto } from "../../../../../domain/dtos/products/attributes/AttributesDto";
import CustomSkeleton from "../../../../../MUICustomComponents/CustomSkeleton";
import { Prompt } from "react-router-dom";

import * as Styled from "./AttributesStyles";

export default function Attributes() {
	const {
		// Props
		isLoading,
		isLoadingButton,
		updateInputValue,
		attributes,
		setAttributes,
		rowsTitleTable,
		currentIndex,
		indexToBeDeleted,
		setIndexToBeDeleted,
		formErrors,
		isOpenModal,
		setIsOpenModal,
		isOpenCreateModal,
		setIsOpenCreateModal,
		openModalIndex,
		setOpenModalIndex,
		attributeToDelete,
		setAttributeToDelete,
		unsavedChanges,
		promptMessage,
		// Functions
		handleDeleteAttributes,
		handleUpsertAttributes,
		updateAttributeValue,
		updateChromePicker,
		handleDragDrop,
		handleInputChange,
		handleEditChange,
		cancelAttributeValue,
	} = AttributesService();

	const showActionButtons = (
		attribute: AttributesDto,
		index: number
	): JSX.Element => {
		return (
			<Stack flexDirection="row" justifyContent="right">
				<Styled.Button
					onClick={() => handleEditChange(index)}
					disabled={currentIndex !== null && currentIndex !== index}
				>
					<CustomIcon icon={<EditOutlined />} variant="default" />
				</Styled.Button>
				<Styled.Button
					color={paletteObject.danger.main}
					onClick={() => {
						setOpenModalIndex(index);
						setIsOpenModal(true);
						setAttributeToDelete(attribute);
						setIndexToBeDeleted(index);
					}}
				>
					<CustomIcon icon={<DeleteOutlineOutlined />} variant="default" />
				</Styled.Button>

				<DeleteAttribute
					indexToBeDeleted={indexToBeDeleted}
					isOpenModal={isOpenModal && index === openModalIndex}
					setIsOpenModal={setIsOpenModal}
					handleDeleteAttributes={handleDeleteAttributes}
					attribute={attributeToDelete}
					isLoadingButton={isLoadingButton}
				/>
			</Stack>
		);
	};

	const showEditionButtons = (): JSX.Element => {
		return (
			<Stack flexDirection="row" justifyContent="right">
				<Styled.Button
					onClick={() => updateAttributeValue(updateInputValue)}
					color={paletteObject.secondary.main}
				>
					<CustomIcon icon={<Done />} variant="default" />
				</Styled.Button>
				<Styled.Button
					color={paletteObject.danger.main}
					onClick={cancelAttributeValue}
				>
					<CustomIcon icon={<Close />} variant="default" />
				</Styled.Button>
			</Stack>
		);
	};

	const handleDisplayedInputs = (
		attribute: AttributesDto,
		index: number
	): JSX.Element => {
		const currentValue =
			AttributeTypes.Color === attribute.type
				? attribute.label
				: attribute.value;

		const chromePicker = AttributeTypes.Color === attribute.type && (
			<Styled.BorderBox>
				<ChromePicker
					updateChromePicker={updateChromePicker}
					currentColor={attribute.value}
					disabled={currentIndex !== index}
				/>
			</Styled.BorderBox>
		);

		const valueView =
			currentIndex === index ? (
				<Box pt={0.5}>
					<CustomInput
						name={AttributeInputs.Value}
						value={updateInputValue.value}
						title={i18n.t(`attributes.Value`)}
						onChange={handleInputChange}
						error={FormValidator.hasInputError(
							formErrors,
							AttributeInputs.Value
						)}
					/>
				</Box>
			) : (
				<CustomTypography variant="default">{currentValue}</CustomTypography>
			);
		return (
			<Stack direction="row" alignItems="center">
				{chromePicker}
				{valueView}
			</Stack>
		);
	};

	return (
		<Box px={3} py={4}>
			<Prompt when={unsavedChanges} message={promptMessage} />
			{isOpenCreateModal && (
				<CreateAttribute
					isOpenCreateModal={isOpenCreateModal}
					setIsOpenCreateModal={setIsOpenCreateModal}
					attributes={attributes}
					setAttributes={setAttributes}
				/>
			)}
			<Box
				mb={2}
				display={"flex"}
				justifyContent={"space-between"}
				alignItems={"center"}
			>
				<Box>
					<CustomTypography variant="title">
						{i18n.t(`attributes.Title`).toString()}
					</CustomTypography>
				</Box>
				{attributes.length > 0 && (
					<Stack direction="row" justifyContent="end" mb={2}>
						<Button
							variant="contained"
							color="info"
							onClick={() => setIsOpenCreateModal(true)}
						>
							{i18n.t(`attributes.NewAttribute`).toString()}
						</Button>
					</Stack>
				)}
			</Box>

			{isLoading ? (
				<CustomSkeleton quantity={4} height={60} />
			) : (
				<>
					{attributes.length <= 0 ? (
						<Stack flexDirection={"row"} justifyContent={"center"} mb={5}>
							<Box width={400} textAlign={"center"} gap={6}>
								<CustomTypography variant="default" isBold={true}>
									{i18n.t(`attributes.NoRegisteredAttributes`).toString()}
								</CustomTypography>
								<CustomTypography variant="small">
									{i18n.t(`attributes.ClickCreateNewAttribute`).toString()}
								</CustomTypography>
								<Button
									color="info"
									variant="contained"
									onClick={() => setIsOpenCreateModal(true)}
									sx={{ marginTop: "20px" }}
								>
									{i18n.t(`attributes.NewAttribute`).toString()}
								</Button>
							</Box>
						</Stack>
					) : (
						<TableContainer component={Box}>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										{rowsTitleTable.map((row) => (
											<TableCell
												key={row.title}
												align={row.position}
												width={row.width}
											>
												<CustomTypography variant="small" isBold={true}>
													{row.title}
												</CustomTypography>
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<DragDropContext onDragEnd={handleDragDrop}>
									<Droppable droppableId="attributes">
										{(provided: DroppableProvided) => (
											<TableBody
												{...provided.droppableProps}
												ref={provided.innerRef}
											>
												{attributes.map((attribute, index) => (
													<Draggable
														key={attribute.id || index.toString()}
														draggableId={attribute.id || index.toString()}
														index={index}
													>
														{(provided: DraggableProvided) => (
															<TableRow
																ref={provided.innerRef}
																{...provided.draggableProps}
															>
																<TableCell
																	{...provided.dragHandleProps}
																	height={"70px"}
																>
																	<Styled.Button>
																		<CustomIcon
																			icon={<DragHandleRounded />}
																			variant="default"
																		/>
																	</Styled.Button>
																</TableCell>
																<TableCell sx={{ width: "200px" }}>
																	{index === currentIndex ? (
																		<Box pt={0.5}>
																			<CustomInput
																				name={AttributeInputs.Title}
																				value={updateInputValue.title}
																				title={i18n.t(`attributes.Name`)}
																				onChange={handleInputChange}
																				error={FormValidator.hasInputError(
																					formErrors,
																					AttributeInputs.Title
																				)}
																			/>
																		</Box>
																	) : (
																		<CustomTypography variant="default">
																			{attribute.title}
																		</CustomTypography>
																	)}
																</TableCell>
																<TableCell sx={{ width: "700px" }}>
																	{handleDisplayedInputs(attribute, index)}
																</TableCell>
																<TableCell>
																	{index === currentIndex
																		? showEditionButtons()
																		: showActionButtons(attribute, index)}
																</TableCell>
															</TableRow>
														)}
													</Draggable>
												))}
												{provided.placeholder}
											</TableBody>
										)}
									</Droppable>
								</DragDropContext>
							</Table>
						</TableContainer>
					)}
				</>
			)}

			{attributes.length > 0 && (
				<Stack flexDirection={"row"} justifyContent={"flex-end"} mt={5}>
					<Button
						color="success"
						variant="contained"
						onClick={handleUpsertAttributes}
						disabled={isLoadingButton}
					>
						{isLoadingButton ? (
							<CustomIcon
								icon={<RotateRight />}
								variant="default"
								isRotating={isLoadingButton}
							/>
						) : (
							i18n.t(`buttons.General.BtnSave`).toString()
						)}
					</Button>
				</Stack>
			)}
		</Box>
	);
}
