import React, {useEffect, useState} from "react";
import Invoice from "./components/Invoice.jsx";
import moment from "moment";
import {useOrders} from "./providers/OrdersContext";

export default function CreateInvoice({loadingInvoice}) {
  const {
    amount,
    order,
    buyer,
    seller,
    delivery,
    paymentInfo,
    items,
    freight,
    productDisplayCodeName,
    loading
  } = useOrders();

  let paymentMethod = paymentInfo.paymentMethod;

  const information = {
    delivery: delivery,
    productDisplayCodeName: productDisplayCodeName,
    amount: {
      feeAmount: !amount.feeAmount ? 0 : amount ?. feeAmount,
      discountAmount: !amount.discountAmount ? 0 : amount ?. discountAmount,
      freightAmount: !amount.freightAmount ? 0 : amount ?. freightAmount,
      totalAmount: !amount.totalAmount ? 0 : amount ?. totalAmount
    },
    order: {
      friendlyCode: !order.friendlyCode ? "" : order ?. friendlyCode,
      createDate: !order.createDate ? "" : moment(order ?. createDate).format("DD/MM/YYYY - HH:mm:ss"),
      modality: localStorage.getItem("role") === "8" ? freight.description : order.isDelivery ? "Receba em Casa" : "Retire na Loja"
    },
    paymentInfo: {
      info: ! paymentMethod ?. info ? "" : paymentMethod ?. info,
      acquirerMessage: !paymentInfo ?. acquirerMessage ? "" : paymentInfo ?. acquirerMessage,
      fraudStatus: !paymentInfo ?. fraudStatus ? "" : paymentInfo ?. fraudStatus,
      acquirerApprovalCode: !paymentInfo ?. acquirerApprovalCode ? "" : paymentInfo ?. acquirerApprovalCode,
      nsu: !paymentInfo ?. nsu ? "" : paymentInfo ?. nsu,
      paymentStatus: !paymentInfo ?. paymentStatus ? "" : paymentInfo ?. paymentStatus,
      quantityInstallments: !paymentInfo ?. quantityInstallments ? "" : paymentInfo ?. quantityInstallments
    },
    seller: {
      address: {
        cep: !seller ?. address ?. cep ? "" : seller ?. address ?. cep,
        street: !seller ?. address ?. street ? "" : seller ?. address ?. street,
        number: !seller ?. address ?. number ? "" : seller ?. address ?. number,
        complement: !seller ?. address ?. complement ? "" : seller ?. address ?. complement,
        neighborhood: !seller ?. address ?. neighborhood ? "" : seller ?. address ?. neighborhood,
        city: !seller ?. address ?. city ? "" : seller ?. address ?. city,
        state: !seller ?. address ?. state ? "" : seller ?. address ?. state
      },
      cnpj: !seller ?. cnpj ? "" : seller ?. cnpj,
      email: !seller ?. email ? "" : seller ?. email,
      fullName: !seller ?. fullName ? "" : seller ?. fullName
    },
    buyer: {
      address: {
        cep: !buyer ?. address ?. cep ? "" : buyer ?. address ?. cep,
        street: !buyer ?. address ?. street ? "" : buyer ?. address ?. street,
        number: !buyer ?. address ?. number ? "" : buyer ?. address ?. number,
        complement: !buyer ?. address ?. complement ? "" : buyer ?. address ?. complement,
        neighborhood: !buyer ?. address ?. neighborhood ? "" : buyer ?. address ?. neighborhood,
        city: !buyer ?. address ?. city ? "" : buyer ?. address ?. city,
        state: !buyer ?. address ?. state ? "" : buyer ?. address ?. state
      },
      fullName: !buyer ?. fullName ? "" : buyer ?. fullName,
      cpf: !buyer ?. cpf ? "" : buyer ?. cpf,
      email: !buyer.email ? "" : buyer ?. email
    },
    image: localStorage.getItem("primaryLogo") === "undefined" ? "" : localStorage.getItem("primaryLogo")
  };

  return (<div className="detail-invoice">
    <button onClick={
        (e) => Invoice(information, items)
      }
      disabled={loading}
      className={
        loading && "loading-invoice"
    }>
      <i className="material-icons">print</i>
    </button>
  </div>);
}
