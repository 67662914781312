import React, { useEffect, useState } from "react";
import { i18n } from "../../../../translate/i18n";
import { OrderFilterStatus } from "../../enums/OrderFilterStatus";
function FilterOrdersModal({
	filterSelect,
	arrStatus,
	arrModality,
	isWithError,
}) {
	const [status, setStatus] = useState([]);
	const [countStatus, setCountStatus] = useState(0);
	const [countModality, setCountModality] = useState(0);
	const [statusList, setStatusList] = useState([
		{
			checked: false,
			value: "Created",
			name: i18n.t("orderDetails.Status.CreatedStatusFilter"),
		},
		{
			checked: false,
			value: "WaitingApproval",
			name: i18n.t("orderDetails.Status.WaitingApprovalPending"),
		},
		{
			checked: false,
			value: "WaitingSeparation",
			name: i18n.t("orderDetails.Status.WaitingSeparationPending"),
		},
		{
			checked: false,
			value: "WaitingInvoice",
			name: i18n.t("orderDetails.Status.WaitingInvoicePending"),
		},
		{
			checked: false,
			value: "WaitingDispatch",
			name: i18n.t("orderDetails.Status.WaitingDispatchPending"),
		},
		{
			checked: false,
			value: "WaitingDelivery",
			name: i18n.t("orderDetails.Status.WaitingDeliveryPending"),
		},
		{
			checked: false,
			value: "WaitingReturnGoods",
			name: i18n.t("orderDetails.Status.WaitingReturnGoodsPeriod"),
		},
		{
			checked: false,
			value: "ReturningGoods",
			name: i18n.t("orderDetails.Status.ReturningGoodsPending"),
		},
		{
			checked: false,
			value: "Finalized",
			name: i18n.t("orderDetails.Status.FinalizedCompleted"),
		},
		{
			checked: false,
			value: "WaitingCancel",
			name: i18n.t("orderDetails.Status.WaitingCancelPeriod"),
		},
		{
			checked: false,
			value: "Canceled",
			name: i18n.t("orderDetails.Status.CanceledCompleted"),
		},
		{
			checked: false,
			value: OrderFilterStatus.WithError,
			name: i18n.t("orderDetails.Status.OrderWithError"),
		},
	]);
	const [modalityList, setModalityList] = useState([
		{
			checked: false,
			value: "ReciveHome",
			name: i18n.t("orderDetails.General.ReceiveAtHome"),
		}, //true
		{
			checked: false,
			value: "PickupStore",
			name: i18n.t("orderDetails.General.PickUpInStore"),
		}, //false
	]);
	const [triggerStatusTags, setTriggerStatusTags] = useState(false);

	useEffect(() => {
		getStatusOrders();
		getModalitiesOrders();
		setTriggerStatusTags(!triggerStatusTags);
	}, []);

	useEffect(() => {
		getFilterSelect(false);
	}, [triggerStatusTags]);

	const getStatusOrders = () => {
		if (arrStatus || isWithError) {
			const result = statusList.map((obj) => {
				if (arrStatus?.includes(obj.value)) {
					return { ...obj, checked: true };
				} else {
					return obj;
				}
			});

			if (isWithError) {
				const withErrorIndex = result.findIndex(
					(obj) => obj.value === OrderFilterStatus.WithError
				);
				if (withErrorIndex !== -1) {
					result[withErrorIndex].checked = true;
				}
			}
			setStatusList(result);
		}

		let countFilterStatus = statusList.filter((countFilter) => {
			if (countFilter.checked === true) {
				return countFilter;
			}
		});
		setCountStatus(countFilterStatus.length);
	};

	const getModalitiesOrders = () => {
		if (arrModality.length <= 0) {
			setModalityList([
				{
					checked: false,
					value: "ReciveHome",
					name: i18n.t("orderDetails.General.ReceiveAtHome"),
				}, //true
				{
					checked: false,
					value: "PickupStore",
					name: i18n.t("orderDetails.General.PickUpInStore"),
				}, //false
			]);
			setCountModality(0);
		} else if (arrModality.length > 1) {
			let valueModalite =
				arrModality === "false" ? "PickupStore" : "ReciveHome";
			const arrData = [];
			modalityList.forEach((data) => {
				if (valueModalite === data.value) {
					const body = {
						checked: true,
						value: data.value,
						name: data.name,
					};
					arrData.push(body);
				} else {
					const body = {
						checked: false,
						value: data.value,
						name: data.name,
					};
					arrData.push(body);
				}
			});
			setModalityList(arrData);
			setCountModality(1);
		} else {
			const arrData = [];
			modalityList.forEach((data) => {
				if (arrModality[0].value === data.value) {
					const body = {
						checked: true,
						value: data.value,
						name: data.name,
					};
					arrData.push(body);
				} else {
					const body = {
						checked: false,
						value: data.value,
						name: data.name,
					};
					arrData.push(body);
				}
			});
			setModalityList(arrData);
			setCountModality(1);
		}
	};

	const handleChooseStatus = (event) => {
		let result = statusList.reduce(
			(acc, object) =>
				acc.concat(
					event.target.value.includes(object.value)
						? Object.assign(object, { checked: event.target.checked })
						: object
				),
			[]
		);

		setStatusList(result);

		let countFilterStatus = statusList.filter((countFilter) => {
			if (countFilter.checked === true) {
				return countFilter;
			}
		});
		setCountStatus(countFilterStatus.length);

		let statusObj = {
			checked: event.target.checked,
			name: event.target.name,
			value: event.target.value,
		};
		if (statusObj.length <= 0) {
			setStatus([{ checked: event.target.checked, value: "", name: "" }]);
		} else {
			setStatus([...status, statusObj]);
		}
	};

	const handleChooseModality = (event) => {
		const arrData = [];
		modalityList.forEach((data) => {
			if (event.target.value === data.value) {
				const body = {
					checked: event.target.checked,
					value: data.value,
					name: data.name,
				};
				arrData.push(body);
			} else {
				const body = {
					checked: false,
					value: data.value,
					name: data.name,
				};
				arrData.push(body);
			}
		});
		setModalityList(arrData);
		let countFilterModality = arrData.filter((countFilter) => {
			return countFilter.checked === true;
		});

		countFilterModality.length <= 0 ? setCountModality(0) : setCountModality(1);
	};

	const getFilterSelect = (doNewOrderRequest) => {
		filterSelect(statusList, modalityList, doNewOrderRequest);
	};

	return (
		<div
			className="modal fade"
			id="filterOrders"
			tabIndex="-1"
			aria-labelledby="filterOrdersLabel"
			aria-hidden="true"
		>
			<div
				className="modal-dialog modal-dialog-centered"
				style={{ minWidth: "500px" }}
			>
				<div
					className="modal-content"
					style={{ padding: "32px", minWidth: "500px ", borderRadius: "8px" }}
				>
					<div className="modal-header d-flex flex-column">
						<h5 className="modal-title mb-2">
							{i18n.t("orderDetails.TitlesMessages.SelectApplyFilter")}
						</h5>
						<p style={{ color: "#747E8B", fontSize: "14px" }}>
							{i18n.t("orderDetails.TitlesMessages.ChooseFilter")}
						</p>
					</div>
					<div className="modal-body">
						<div className="d-flex flex-row justify-content-start">
							<div className="">
								<label className="card-title" style={{ fontSize: "13px" }}>
									{i18n.t("orderDetails.General.OrderStatus")}
								</label>
								<ul className="m-0 p-0">
									{statusList.map((data) => (
										<li
											key={data.name}
											className="custom-control custom-checkbox my-1 mr-sm-2"
										>
											<input
												type="checkbox"
												className="custom-control-input"
												checked={data.checked}
												name={data.name}
												value={data.value}
												id={data.value}
												onChange={handleChooseStatus}
											/>
											<label
												style={{ cursor: "pointer" }}
												className="custom-control-label"
												htmlFor={data.value}
											>
												{data.name}
											</label>
										</li>
									))}
								</ul>
							</div>

							{localStorage.getItem("role") !== "8" && (
								<>
									<div className="mx-3">
										<label className="card-title" style={{ fontSize: "13px" }}>
											{i18n.t("orderDetails.General.Modality")}
										</label>
										{modalityList.map((data, index) => (
											<div
												key={data.name}
												className="custom-control custom-checkbox my-1 mr-sm-2"
											>
												<input
													type="checkbox"
													className="custom-control-input"
													checked={data.checked}
													name={data.name}
													value={data.value}
													id={index}
													onChange={handleChooseModality}
												/>

												<label
													style={{ cursor: "pointer" }}
													className="custom-control-label"
													htmlFor={index}
												>
													{data.name}
												</label>
											</div>
										))}
									</div>
								</>
							)}
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-link"
							data-dismiss="modal"
							style={{
								width: "150px",
								color: "#5A5E61",
								border: "1px solid #8D8D8D",
								backgroundColor: "transparent",
							}}
						>
							{i18n.t("orderDetails.TypeButtom.BtnCancel")}
						</button>

						<button
							className="btn btn-success btn-icon d-flex justify-content-center"
							data-dismiss="modal"
							onClick={() => getFilterSelect(true)}
							style={{ padding: "10px 20px", width: "200px" }}
						>
							{i18n.t("orderDetails.General.ApplyFilter")} (
							{countStatus + countModality})
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FilterOrdersModal;
