import React, { Component } from "react";
import { API } from "../../../API";
import { connect } from "react-redux";
import appActions from "../../../appActions";
import EditorHtml from "../../../assets/EditorHtml";
import Loading from "../../../components/Loading";
import DOMPurify from "dompurify";

export class TermsOfUse extends Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}
	state = {
		content: null,
		loading: true,
	};
	componentWillMount() {
		//Consulta materiais disponíveis a partir do token do usuário.
		API.get("api/userterms/")
			.then((response) => {
				// If request is good...
				this.setState({
					content: response.data.content,
					loading: false,
				});
				this.forceUpdate();
				this.setFirstData();
				this.setNextRoute();
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	}

	componentDidMount() {
		this.setNextRoute();
	}
	setNextRoute() {
		//Passa para o Redux o proximo passo do autosetup
		this.props.dispatch(
			appActions.setNextRoute("/setup/content/privacy-policy", false)
		);
	}

	setFirstData() {
		let data = {
			content: this.state.content,
		};
		//Passa para o Redux os dados para put da API
		//ajustar o TRUe para um state
		this.props.dispatch(
			appActions.sendData(data, true, "api/userterms/", "content")
		);
	}
	async handleChange(e) {
		await this.setState({ content: DOMPurify.sanitize(e) });

		this.forceUpdate();
		let data = {
			content: this.state.content,
		};
		//Passa para o Redux os dados para put da API
		//ajustar o TRUe para um state
		this.props.dispatch(
			appActions.sendData(data, true, "api/userterms/", "content")
		);
	}
	render() {
		if (this.state.loading) {
			return <Loading />;
		} else {
			return this.renderScreen();
		}
	}
	renderScreen() {
		return (
			<div className="terms-of-use d-flex justify-content-center align-items-center">
				<div className="row">
					<div className="col-12">
						<div className="container sm ">
							<h1 className="text-center">Termos de uso</h1>
							<div className="mt-4 text-center">
								<p>
									Para proteger o seu app, empresa e usuários você precisará
									publicar seus termos de uso em uma linguagem simples e
									objetiva.
								</p>
								<div className="alert alert-danger" role="alert">
									O modelo utilizado de exemplo deverá ser{" "}
									<strong>validado e reformulado</strong> junto ao setor
									jurídico da sua empresa antes da publicação no app
								</div>
							</div>
						</div>
					</div>
					<div className="col-12">
						<div
							className="container mt-2"
							style={{ margin: "auto", width: "90%", marginTop: "20px" }}
						>
							<EditorHtml
								data={this.state.content}
								change={this.handleChange}
							/>
							<br />
							<button
								className="btn btn-light btn-icon-l outline"
								data-container="body"
								data-toggle="popover"
								title="Não esqueça"
								data-placement="top"
								data-content="Valide o conteúdo previamente junto ao jurídico de sua empresa."
								onClick={() =>
									window.open(
										"https://loriconfigprd.blob.core.windows.net/lori-static/841699b5-5f05-4236-ac42-09dc65e89799.docx"
									)
								}
							>
								<i
									className="material-icons"
									style={{ fontSize: "24", float: "left", marginRight: "10px" }}
								>
									vertical_align_bottom
								</i>
								Baixe aqui nosso modelo de termos de uso
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect((store) => ({ text: store.text, req: store.req }))(
	TermsOfUse
);
