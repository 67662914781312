import React, { useState, useEffect } from "react";
import { useOrders } from "../../providers/OrdersContext";
import Skeleton from "./Skeleton";
import PrepareShippingModal from "../OrderModal/PrepareShippingModal";
import ChangeTrackingDataModal from "../OrderModal/ChangeTrackingDataModal";
import { i18n } from "../../../../translate/i18n";
import {
	formatZipCode,
	formatDate,
} from "../../../../infrastructure/utils/FormatMask";

export default function TrackingOrderDelivery() {
	const { order, loading, flowStatus } = useOrders();
	const [copyLinkActived, setCopyLinkActived] = useState(false);
	const [copyCodeActived, setCopyCodeActived] = useState(false);
	const [count, setCount] = useState(0);
	const [disableCarousel, setDisableCarousel] = useState({
		prev: true,
		next: false,
	});

	const [trackingList, setTrackingList] = useState([]);
	const enableTooltips =
		order?.Trackings?.length === 0 && order?.Status !== "WaitingDispatch";

	useEffect(() => {
		setTrackingList(order?.Trackings);
	}, [order]);

	useEffect(() => {
		if (count === 0) {
			setDisableCarousel({ prev: true, next: false });
		} else if (count === trackingList.length - 1) {
			setDisableCarousel({ prev: false, next: true });
		} else {
			setDisableCarousel({ prev: false, next: false });
		}
	}, [count]);

	const copyToClipboard = (text, type) => {
		navigator.clipboard.writeText(text);
		if (type === "code") {
			setCopyCodeActived(true);
			setTimeout(() => setCopyCodeActived(false), 3000);
			return;
		}
		setCopyLinkActived(true);
		setTimeout(() => setCopyLinkActived(false), 3000);
	};

	const handleShippingTypeDelivery = () => (
		<>
			<div className="order-body-box">
				<p className="text-blueish">
					<strong>{order?.Trackings[0]?.AddresseeName}</strong>
				</p>
				<p className="text-blueish">
					{order?.Trackings[0]?.DeliveryAddress?.Street}{" "}
					{order?.Trackings[0]?.DeliveryAddress?.Number}{" "}
					{order?.Trackings[0]?.DeliveryAddress?.Complement}
				</p>
				<p className="text-blueish">
					{order?.Trackings[0]?.DeliveryAddress?.Neighborhood}{" "}
					{order?.Trackings[0]?.DeliveryAddress?.City}{" "}
					{order?.Trackings[0]?.DeliveryAddress?.State}
				</p>

				<p className="text-blueish">
					{i18n.t("orderDetails.General.ZipCode")}:{" "}
					{formatZipCode(order?.Trackings[0]?.DeliveryAddress?.ZipCode)}
				</p>
			</div>
			{order?.Trackings?.length === 1
				? showRegisteredTracking()
				: showRegisteredListTracking()}
		</>
	);

	const showButtonShippingTypeDelivery = () => {
		return (
			<>
				<div className="btn-tooltip">
					<button
						className={
							order?.Status !== "WaitingDispatch"
								? "btn btn-grey-50 d-flex align-items-center justify-content-center w-100"
								: "btn btn-grey-80 d-flex align-items-center justify-content-center w-100"
						}
						style={{ height: "43px" }}
						data-toggle="modal"
						data-target="#prepareShippingModal"
						disabled={order?.Status !== "WaitingDispatch" ? true : false}
					>
						<i className="material-icons-outlined px-2">local_shipping</i>
						{i18n.t("orderDetails.TypeButtom.PrepareShipping")}
					</button>
					{enableTooltips && (
						<span className="msg-tooltip" style={{ width: "182px" }}>
							{i18n.t("orderDetails.Trackings.NoTracking")}
						</span>
					)}
				</div>
			</>
		);
	};

	const handleShippingPackageListId = () => {
		return (
			<>
				{order?.Trackings[0]?.PackageListId &&
					order?.Trackings[0]?.ShippingLabelUrl && (
						<a
							href={order.Trackings[0].ShippingLabelUrl}
							target="_blank"
							className={
								"btn btn-grey-80 btn-block btn-icon-l mt-2 d-flex justify-content-center"
							}
							style={{
								color: "#fff",
								width: "auto",
								paddingTop: "1em",
								paddingBottom: "1em",
								height: "43px",
								lineHeight: "19px",
							}}
						>
							<i
								className="material-icons-outlined align-self-center m-0 mr-2"
								style={{ fontSize: "24px" }}
							>
								download
							</i>
							{i18n.t("orderDetails.TypeButtom.BtnDownloadPLP")}
						</a>
					)}
			</>
		);
	};

	const showRegisteredTracking = () => {
		return (
			<>
				<div className="order-header-box">
					<h2>{i18n.t("orderDetails.General.Tracking")}</h2>
				</div>
				<div className="order-body-box">
					{order?.Trackings[0]?.ShippingType && (
						<p className="text-blueish">
							<strong>{i18n.t("orderDetails.General.ShippingType")}: </strong>
							{order?.Trackings[0]?.ShippingReference}
						</p>
					)}
					{order?.Trackings[0]?.CarrierName && (
						<p className="text-blueish">
							<strong>{i18n.t("orderDetails.General.CarrierName")}: </strong>
							{order?.Trackings[0]?.CarrierName}
						</p>
					)}
					{order?.Trackings[0]?.TrackingUrl && (
						<div className="tracking-card">
							<p className="text-blueish d-flex flex-row">
								<strong>{i18n.t("orderDetails.General.TrackingUrl")}: </strong>
								<div className="tracking-box">
									<a href={order?.Trackings[0]?.TrackingUrl} target="_blank">
										{i18n.t("orderDetails.General.Link")}
									</a>
									{!copyLinkActived && (
										<button
											onClick={() =>
												copyToClipboard(
													order?.Trackings[0]?.TrackingUrl,
													"link"
												)
											}
										>
											<i className="material-icons">content_copy</i>
										</button>
									)}
									{copyLinkActived && (
										<button
											onClick={() =>
												copyToClipboard(
													order?.Trackings[0]?.TrackingUrl,
													"link"
												)
											}
										>
											<i
												style={{ color: "#6CD691" }}
												className="material-icons"
											>
												check_circle
											</i>
										</button>
									)}
								</div>
							</p>
						</div>
					)}

					{order?.Trackings[0]?.DeliveryDate && (
						<p className="text-blueish">
							<strong>{i18n.t("orderDetails.General.DeliveryDate")}: </strong>
							{new Date(order?.Trackings[0]?.DeliveryDate).toLocaleDateString()}
						</p>
					)}

					{order?.Trackings[0]?.TrackingCode && (
						<div className="tracking-card">
							<p className="text-blueish d-flex flex-row">
								<strong>{i18n.t("orderDetails.General.TrackingCode")}: </strong>
								<div className="tracking-box">
									<label className="ml-1">
										{order?.Trackings[0]?.TrackingCode}
									</label>
									<div>
										{!copyCodeActived && (
											<button
												onClick={() =>
													copyToClipboard(
														order?.Trackings[0]?.TrackingCode,
														"code"
													)
												}
											>
												<i className="material-icons">content_copy</i>
											</button>
										)}
										{copyCodeActived && (
											<button
												onClick={() =>
													copyToClipboard(
														order?.Trackings[0]?.TrackingCode,
														"code"
													)
												}
											>
												<i
													style={{ color: "#6CD691" }}
													className="material-icons"
												>
													check_circle
												</i>
											</button>
										)}
									</div>
								</div>
							</p>
						</div>
					)}
				</div>
			</>
		);
	};

	const showRegisteredListTracking = () => {
		return (
			<>
				<div className="order-header-box">
					<h2>{i18n.t("orderDetails.General.Tracking")}</h2>
				</div>
				<div
					id="carouselTrackingControls"
					className="carousel slide double-cards-carousel"
					data-ride="carousel"
					data-interval="false"
					data-wrap="false"
				>
					<ol className="carousel-indicators mb-3">
						{trackingList.map((value, index) => (
							<li
								key={value.id}
								data-target="#carouselTrackingControls"
								data-slide-to={index}
								onClick={() => setCount(index)}
								className={
									index === 0
										? "carousel-indicators-item active"
										: "carousel-indicators-item"
								}
							></li>
						))}
						<button
							className={`carousel-control-prev ${
								!disableCarousel.prev && "text-secondary"
							}`}
							type="button"
							data-target="#carouselTrackingControls"
							data-slide="prev"
							onClick={() => setCount(count - 1)}
							disabled={disableCarousel.prev}
						>
							<i className="material-icons-outlined" aria-hidden="true">
								navigate_before
							</i>
						</button>
						<button
							className={`carousel-control-next ${
								!disableCarousel.next && "text-secondary"
							}`}
							type="button"
							data-target="#carouselTrackingControls"
							data-slide="next"
							onClick={() => setCount(count + 1)}
							disabled={disableCarousel.next}
						>
							<i className="material-icons-outlined" aria-hidden="true">
								navigate_next
							</i>
						</button>
					</ol>

					<div className="carousel-inner p-0">
						{trackingList.map((tracking, index) => {
							return (
								<div
									key={tracking.id}
									className={
										index === 0 ? "carousel-item active" : "carousel-item"
									}
								>
									<div className="order-body-box m-0 p-0">
										{tracking?.ShippingReference && (
											<div className="text-orders-carousel">
												<strong>
													{i18n.t("orderDetails.General.ShippingType")}:{" "}
												</strong>
												{tracking?.ShippingReference}
											</div>
										)}

										{tracking?.CarrierName && (
											<div className="text-orders-carousel">
												<strong>
													{i18n.t("orderDetails.General.CarrierName")}:{" "}
												</strong>
												{tracking?.CarrierName}
											</div>
										)}

										{tracking?.TrackingUrl && (
											<div className="tracking-card">
												<div className="text-orders-carousel d-flex flex-row">
													<strong>
														{i18n.t("orderDetails.General.TrackingUrl")}:{" "}
													</strong>
													<div className="tracking-box">
														<a href={tracking?.TrackingUrl} target="_blank">
															{i18n.t("orderDetails.General.Link")}
														</a>
														{!copyLinkActived && (
															<button
																onClick={() =>
																	copyToClipboard(tracking?.TrackingUrl, "link")
																}
															>
																<i className="material-icons">content_copy</i>
															</button>
														)}
														{copyLinkActived && (
															<button
																onClick={() =>
																	copyToClipboard(tracking?.TrackingUrl, "link")
																}
															>
																<i
																	style={{ color: "#6CD691" }}
																	className="material-icons"
																>
																	check_circle
																</i>
															</button>
														)}
													</div>
												</div>
											</div>
										)}

										{tracking?.DeliveryDate && (
											<div className="text-orders-carousel">
												<strong>
													{i18n.t("orderDetails.General.DeliveryDate")}:{" "}
												</strong>
												{formatDate(tracking?.DeliveryDate)}
											</div>
										)}

										{tracking?.TrackingCode && (
											<div className="tracking-card">
												<div className="text-orders-carousel d-flex flex-row">
													<strong>
														{i18n.t("orderDetails.General.TrackingCode")}:{" "}
													</strong>
													<div className="tracking-box">
														<label className="ml-1">
															{tracking?.TrackingCode}
														</label>
														<div>
															{!copyCodeActived && (
																<button
																	onClick={() =>
																		copyToClipboard(
																			tracking?.TrackingCode,
																			"code"
																		)
																	}
																>
																	<i className="material-icons">content_copy</i>
																</button>
															)}
															{copyCodeActived && (
																<button
																	onClick={() =>
																		copyToClipboard(
																			tracking?.TrackingCode,
																			"code"
																		)
																	}
																>
																	<i
																		style={{ color: "#6CD691" }}
																		className="material-icons"
																	>
																		check_circle
																	</i>
																</button>
															)}
														</div>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</>
		);
	};

	return (
		<div className="order-main-box">
			{loading ? (
				<Skeleton />
			) : (
				<>
					<PrepareShippingModal />
					<ChangeTrackingDataModal />
					<div className="order-header-box">
						<h2>{i18n.t("orderDetails.General.Shipping")}</h2>
					</div>

					<div className="order-body-box m-0 p-0">
						{order?.Trackings?.length <= 0 ? (
							<div className="order-body-box m-0 p-0">
								<label
									className="m-0"
									style={{ textAlign: "center", padding: "40px 0 40px 0" }}
								>
									<span>{i18n.t("orderDetails.Trackings.NoTrackingText")}</span>
								</label>
							</div>
						) : (
							handleShippingTypeDelivery()
						)}
						{flowStatus?.enqueueOrderPosition <= 4 && (
							<div className="pt-3">{showButtonShippingTypeDelivery()}</div>
						)}

						{flowStatus?.enqueueOrderPosition >= 4 && (
							<div>{handleShippingPackageListId()}</div>
						)}
					</div>
				</>
			)}
		</div>
	);
}
