import React, { useEffect, useState } from "react";
import LastOrdersTable from "./LastOrders/LastOrdersTable";
import LastOrdersHeader from "./LastOrders/LastOrdersHeader";

function LastOrders({ isLoadingOrders, orders }) {
	const [lastOrders, setLastOrders] = useState([]);
	const lastOrderQuantityShown = 10;

	useEffect(() => {
		if (orders) setLastOrders(mapLastOrders(orders.Items));
	}, [orders]);

	const mapLastOrders = (orders) => {
		const lastOrdersTableArray = orders
			.slice(0, lastOrderQuantityShown)
			.map((order) => {
				return {
					id: order.id,
					createDate: order.CreateDate,
					fullName: order.Buyer.FullName,
					totalAmount: order.Totals.TotalAmount,
					externalId: order?.ExternalId ?? null,
					friendlyCode: order.FriendlyCode,
					sellerOrderId: order?.SellerOrderId | null,
					status: order.Status,
					isDelivery: order.IsDelivery,
				};
			});
		return lastOrdersTableArray;
	};

	return (
		<div className="card">
			<LastOrdersHeader />
			<LastOrdersTable lastOrders={lastOrders} isLoading={isLoadingOrders} />
		</div>
	);
}

export default LastOrders;
