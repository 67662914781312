import React from "react";
import IntegrationState from "./components/IntegrationState.jsx";
import Suggestion from "./components/Suggestion.jsx";
import CatalogStatus from "./components/CatalogStatus.jsx";

import "./Status.scss";

function Status(props) {
	return (
		<div className="container mb-3">
			<div className="row mb-3">
				<div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
					<CatalogStatus />
				</div>
			</div>

			<div className="mb-2 d-flex justify-content-between">
				<div className="w-100 mr-2">
					<IntegrationState />
				</div>
				<div className="w-100 ml-2">
					<Suggestion />
				</div>
			</div>
		</div>
	);
}

export default Status;
