import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { APIV2 } from "../../../API";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { i18n } from "../../../translate/i18n";
import { buildURLWithParams } from "../../../infrastructure/utils/BuildURLWithParams";
import NewToastComponent from "../../../components/NewToastComponent";

export const DealersProvider = createContext({});

export default function DealersContext(props) {
	const params = new URL(document.location).searchParams;
	const pageParam = useParams().page;
	const [page, setPage] = useState(pageParam || "1");
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [query, setQuery] = useState(params.get("query") || "");
	const [dealerFilters, setDealerFilters] = useState({
		Office: params.get("role") ? params.get("role") : "",
	});
	const [dealersList, setDealersList] = useState([]);
	const [roleClient, setRoleClient] = useState("");
	const [loading, setLoading] = useState(false);
	const [messageToast, setMessageToast] = useState("");
	const optionsDict = [
		{
			value: "",
			label: i18n.t("staticMessage.SelectMessage.ShowingAll"),
		},
		{
			value: "Salesperson",
			label: i18n.t("staticMessage.SelectMessage.Salesperson"),
		},
		{
			value: "ResponsibleSeller",
			label: i18n.t("staticMessage.SelectMessage.ResponsibleSeller"),
		},
		{
			value: "Supervisor",
			label: i18n.t("staticMessage.SelectMessage.Supervisor"),
		},
		{
			value: "Coordinator",
			label: i18n.t("staticMessage.SelectMessage.Coordinator"),
		},
		{
			value: "AssistantManager",
			label: i18n.t("staticMessage.SelectMessage.AssistantManager"),
		},
		{
			value: "Manager",
			label: i18n.t("staticMessage.SelectMessage.Manager"),
		},
		{
			value: "Partner",
			label: i18n.t("staticMessage.SelectMessage.Partner"),
		},
	];

	const documentTypeDict = {
		Cnpj: i18n.t(`orderDetails.General.SellerDoc`),
		Cpf: i18n.t(`orderDetails.General.BuyerDoc`),
	};

	useEffect(() => {
		getDealersList();
	}, [query, dealerFilters]);

	const getDealersList = () => {
		setDealersList([]);
		setLoading(true);

		let queryParams = {
			page: page,
			perpage: rowsPerPage,
			query: query,
			role: dealerFilters.Office,
		};

		APIV2.get(`management/marketing/dealers?${buildURLWithParams(queryParams)}`)
			.then(async (response) => {
				setLoading(false);
				setRoleClient(localStorage.getItem("role"));
				if (response.status !== 204) {
					setDealersList(response.data);
					setLoading(false);
				} else {
					setDealersList([]);
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				NewToastComponent({
					status: "error",
					title: i18n.t("errorMessages.ERR.REQUEST"),
				});
				setLoading(false);
			});
	};

	const getSearch = (query) => {
		setQuery(query);
	};

	const deleteDealer = (id) => {
		APIV2.delete(`management/marketing/dealers/${id}`)
			.then(async () => {
				window.location.reload();
				localStorage.setItem("deleteDealer", true);
			})
			.catch((error) => {
				console.log(error);
				NewToastComponent({
					status: "error",
					title: i18n.t("errorMessages.ERR.REQUEST"),
				});
			});
	};

	const contextValue = useMemo(
		() => ({
			dealersList,
			loading,
			messageToast,
			roleClient,
			setMessageToast,
			setLoading,
			getSearch,
			page,
			setPage,
			rowsPerPage,
			setRowsPerPage,
			query,
			setQuery,
			optionsDict,
			dealerFilters,
			setDealerFilters,
			getDealersList,
			deleteDealer,
			documentTypeDict,
		}),
		[
			dealersList,
			loading,
			messageToast,
			roleClient,
			setMessageToast,
			setLoading,
			getSearch,
			page,
			setPage,
			rowsPerPage,
			setRowsPerPage,
			query,
			setQuery,
			optionsDict,
			dealerFilters,
			setDealerFilters,
			getDealersList,
			deleteDealer,
			documentTypeDict,
		]
	);

	return (
		<DealersProvider.Provider value={contextValue}>
			{props.children}
		</DealersProvider.Provider>
	);
}

export const useDealers = () => useContext(DealersProvider);
