// Summarize received by order list request
export enum CpOrderStatusDto {
	None = "None",
	AwaitingCreation = "AwaitingCreation",
	CreatedInternal = "CreatedInternal",
	CreatedExternal = "CreatedExternal",
	InProgress = "InProgress",
	AwaitingInvoiceCreation = "AwaitingInvoiceCreation",
	InvoiceCreated = "InvoiceCreated",
	Separated = "Separated",
	ReadyForPickup = "ReadyForPickup",
	Shipping = "Shipping",
	Finalized = "Finalized",
	Canceled = "Canceled",
	Error = "Error",
}
