import React, { ChangeEvent } from "react";
import { Grid, SelectChangeEvent } from "@mui/material";
import { i18n } from "../../../../translate/i18n";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import CustomInput from "../../../../MUICustomComponents/CustomInput";
import RetailerCard from "../components/RetailerCard";
import RetailerCheckbox from "../components/RetailerCheckbox";
import CustomSelect, {
	SelectOptions,
} from "../../../../MUICustomComponents/CustomSelect";
import { EcommerceBehaviorSellerList } from "../../../../domain/dtos/retailerManagement/retailerConfigurations/EcommerceBehaviorSellerList";
import { EcommerceLocationSellerList } from "../../../../domain/dtos/retailerManagement/retailerConfigurations/EcommerceLocationSellerList";
import { AppConfig } from "../types/AppConfig";

type Props = {
	appConfig: AppConfig;
	onChange: (
		event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>,
		checked?: boolean
	) => void;
};

export default function AppCheckout({ appConfig, onChange }: Props) {
	const showEcommerceOptions: SelectOptions = [
		{
			label: i18n.t("retailerConfig.ShowEcommerce.AlwaysShow"),
			value: EcommerceBehaviorSellerList.AlwaysShow,
		},
		{
			label: i18n.t("retailerConfig.ShowEcommerce.ShowWhenOnlyAvailable"),
			value: EcommerceBehaviorSellerList.ShowWhenOnlyAvailable,
		},
	];

	const locationEcommerceOptions: SelectOptions = [
		{
			label: i18n.t("retailerConfig.LocationEcommerce.AlwaysTop"),
			value: EcommerceLocationSellerList.AlwaysTop,
		},
		{
			label: i18n.t("retailerConfig.LocationEcommerce.AlwaysBottom"),
			value: EcommerceLocationSellerList.AlwaysBottom,
		},
	];

	return (
		<RetailerCard>
			<CustomTypography variant="secondaryTitle" marginBottom={2}>
				{i18n.t("retailerConfig.AppCheckout").toString()}
			</CustomTypography>
			<Grid container spacing={2} mt={-2}>
				<Grid item xs={6}>
					<RetailerCheckbox
						id="isMinAmountTotalPurchase"
						isChecked={appConfig.isMinAmountTotalPurchase}
						onChange={onChange}
						helpTooltip={{
							title: i18n.t(`retailerConfig.IsMinAmountTotalPurchaseTooltip`),
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<RetailerCheckbox
						id="showAllSellersOnCheckout"
						isChecked={appConfig.showAllSellersOnCheckout}
						onChange={onChange}
						helpTooltip={{
							title: i18n.t(`retailerConfig.ShowAllSellersOnCheckoutTooltip`),
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<CustomInput
						title={i18n.t(`retailerConfig.${"displayRadius"}`)}
						name="displayRadius"
						value={appConfig.displayRadius}
						onChange={onChange}
						type="number"
						helpTooltip={{
							title: i18n.t(`retailerConfig.DisplayRadiusTooltip`),
						}}
					/>
				</Grid>
				<Grid item xs={12} mt={-1}>
					<RetailerCheckbox
						id="forceSellerNameAtList"
						isChecked={appConfig.forceSellerNameAtList}
						onChange={onChange}
						helpTooltip={{
							title: i18n.t(`retailerConfig.ForceSellerNameAtListTooltip`),
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<CustomSelect
						label={i18n.t(
							`retailerConfig.${"showEcommerceSellerListCheckout"}`
						)}
						id="showEcommerceSellerListCheckout"
						onChange={onChange}
						value={appConfig.showEcommerceSellerListCheckout}
						options={showEcommerceOptions}
						helpTooltip={{
							title: i18n.t(
								`retailerConfig.ShowEcommerceSellerListCheckoutTooltip`
							),
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<CustomSelect
						label={i18n.t(
							`retailerConfig.${"locationEcommerceSellerListCheckout"}`
						)}
						id="locationEcommerceSellerListCheckout"
						onChange={onChange}
						value={appConfig.locationEcommerceSellerListCheckout}
						options={locationEcommerceOptions}
						helpTooltip={{
							title: i18n.t(
								`retailerConfig.LocationEcommerceSellerListCheckoutTooltip`
							),
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<CustomInput
						title={i18n.t(`retailerConfig.${"checkoutPaymentAlertText"}`)}
						name="checkoutPaymentAlertText"
						value={appConfig.checkoutPaymentAlertText}
						onChange={onChange}
						multiline={true}
						rows={3}
						helpTooltip={{
							title: i18n.t(`retailerConfig.CheckoutPaymentAlertTextTooltip`),
						}}
					/>
				</Grid>
			</Grid>
		</RetailerCard>
	);
}
