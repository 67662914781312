import NewToastComponent from "../../../../components/NewToastComponent";
import { ChangeEvent, useEffect, useState } from "react";
import { retailerManagementApi } from "../../../../infrastructure/api/RetailerManagementApi";
import { SellerIntegrationDto } from "../../../../domain/dtos/stores/SellerIntegrationDto";
import { SellerIntegrationView } from "../../../../domain/views/stores/SellerIntegrationView";
import { useHistory } from "react-router-dom";
import { SellerOrderProcessView } from "../../../../domain/views/stores/SellerOrderProcessView";
import { i18n } from "../../../../translate/i18n";
import { IntegrationInputs } from "./IntegrationInputs";
import { isNegativeNumber } from "../../../../infrastructure/utils/TypeValidator";
import {
	FormErrors,
	FormValidations,
	FormValidator,
	InputValidator,
} from "../../../../infrastructure/utils/FormUtils";

type IntegrationConfigForm = {
	id?: string;
	orderIntegrationId: string | null;
	fraudIntegrationId: string | null;
	paymentIntegrationId: string | null;
	orderIntegrationCredentials: string | null;
	fraudIntegrationCredentials: string | null;
	paymentIntegrationCredentials: string | null;
	safetyStockQuantity: string | null;
	disableToUseChangeSeller: boolean;
};

export default function useIntegrationService(
	processId: string,
	data: SellerIntegrationView,
	getSeller: (sellerId: string) => void
) {
	const history = useHistory();
	const unparsedConfigurations = localStorage.getItem("configurations");
	const configurations =
		unparsedConfigurations && JSON.parse(unparsedConfigurations)[0];
	const [integrationConfig, setIntegrationConfig] =
		useState<IntegrationConfigForm>({
			orderIntegrationId: null,
			fraudIntegrationId: null,
			paymentIntegrationId: null,
			orderIntegrationCredentials: null,
			fraudIntegrationCredentials: null,
			paymentIntegrationCredentials: null,
			safetyStockQuantity: null,
			disableToUseChangeSeller: false,
		});
	const [isDefaultSafetyStock, setIsDefaultSafetyStock] =
		useState<boolean>(true);
	const [isLoading, setIsLoading] = useState(false);
	const [orderProcessList, setOrderProcessList] = useState<
		SellerOrderProcessView[]
	>([]);
	const [currentOrderProcess, setCurrentOrderProcess] =
		useState<SellerOrderProcessView | null>(null);
	const [orderProcessInput, setOrderProcessInput] = useState<string>("");
	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const formValidationMapper: FormValidations = {
		[IntegrationInputs.SafetyStockQuantity]:
			InputValidator.validatePositiveValue,
	};

	const getOptionLabel = (process: SellerOrderProcessView) =>
		process.name || `${process.id} (${i18n.t("integration.NotIdentified")})`;
	const isOptionEqualToValue = (
		option: SellerOrderProcessView,
		value: SellerOrderProcessView
	) => option.id === value.id;
	const filterOptions = (option: SellerOrderProcessView) =>
		option.name + option.id;

	const integrationFieldList = [
		{
			title: i18n.t(`integration.${IntegrationInputs.OrderIntegrationId}`),
			name: IntegrationInputs.OrderIntegrationId,
			value: integrationConfig?.orderIntegrationId,
		},
		{
			title: i18n.t(`integration.${IntegrationInputs.PaymentIntegrationId}`),
			name: IntegrationInputs.PaymentIntegrationId,
			value: integrationConfig?.paymentIntegrationId,
		},
		{
			title: i18n.t(`integration.${IntegrationInputs.FraudIntegrationId}`),
			name: IntegrationInputs.FraudIntegrationId,
			value: integrationConfig?.fraudIntegrationId,
		},
		{
			title: i18n.t(
				`integration.${IntegrationInputs.OrderIntegrationCredentials}`
			),
			name: IntegrationInputs.OrderIntegrationCredentials,
			value: integrationConfig?.orderIntegrationCredentials,
		},
		{
			title: i18n.t(
				`integration.${IntegrationInputs.PaymentIntegrationCredentials}`
			),
			name: IntegrationInputs.PaymentIntegrationCredentials,
			value: integrationConfig?.paymentIntegrationCredentials,
		},
		{
			title: i18n.t(
				`integration.${IntegrationInputs.FraudIntegrationCredentials}`
			),
			name: IntegrationInputs.FraudIntegrationCredentials,
			value: integrationConfig?.fraudIntegrationCredentials,
		},
	];

	useEffect(() => {
		getOrderProcesses();
		getSellerIntegration(data);
	}, []);

	const getInitialSafetyStockQuantity = (
		dataQuantity: number
	): string | null => {
		return isNegativeNumber(dataQuantity) ? null : String(dataQuantity);
	};

	const getSellerIntegration = (data: SellerIntegrationView): void => {
		if (!data) return;

		try {
			setIsDefaultSafetyStock(isNegativeNumber(data.safetyStockQuantity));
			const newIntegrationConfig: IntegrationConfigForm = {
				...data,
				safetyStockQuantity: getInitialSafetyStockQuantity(
					data.safetyStockQuantity
				),
			};
			setIntegrationConfig(newIntegrationConfig);
			setTimeout(() => {
				setIsLoading(false);
			}, 500);
		} catch (error) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			setTimeout(() => {
				history.push(`/stores/1`);
			}, 3000);
		}
	};

	/**
	 * This function receives a change event and insert its value to integrationConfig or isDefaultSafetyStock
	 * @param event input onChange event
	 */
	const onChangeIntegration = (event: ChangeEvent<HTMLInputElement>): void => {
		const { name, value, checked } = event.target;

		if (formValidationMapper[name] !== undefined) {
			const newErrors: string[] = formValidationMapper[name](value);
			setFormErrors((prevState) => ({ ...prevState, [name]: newErrors }));
		}

		if (name === IntegrationInputs.DisableToUseChangeSeller) {
			setIntegrationConfig((prevState: IntegrationConfigForm) => ({
				...prevState,
				disableToUseChangeSeller: checked,
			}));
			return;
		}

		if (name === IntegrationInputs.IsDefaultSafetyStock) {
			setIsDefaultSafetyStock(checked);
			setIntegrationConfig((prevState: IntegrationConfigForm) => ({
				...prevState,
				safetyStockQuantity: checked
					? null
					: getInitialSafetyStockQuantity(data?.safetyStockQuantity) ?? "0",
			}));
			setFormErrors({
				...formErrors,
				[IntegrationInputs.SafetyStockQuantity]: [],
			});
			return;
		}

		setIntegrationConfig((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	/**
	 * This function apply validations to call upsertIntegrationConfig and upsertOrderProcess
	 *
	 * If after valitation at least one request is successful, calls getSeller() from SellerScreenHook.ts
	 * @param sellerId seller identifier
	 * @param integrationConfig integrationConfig object
	 */
	const handleFormSubmit = async (
		sellerId: string,
		integrationConfig: IntegrationConfigForm,
		orderProcess: SellerOrderProcessView | null
	): Promise<void> => {
		if (!isDefaultSafetyStock) {
			let errors: FormErrors = {
				[IntegrationInputs.SafetyStockQuantity]: formValidationMapper[
					IntegrationInputs.SafetyStockQuantity
				](integrationConfig.safetyStockQuantity),
			};
			setFormErrors(errors);
			if (FormValidator.hasError(errors)) return;
		}

		setIsLoading(true);

		let integrationSuccess = await upsertIntegrationConfig(
			sellerId,
			integrationConfig
		);

		let orderProcessSuccess =
			orderProcess && (await upsertOrderProcess(sellerId, orderProcess));

		(integrationSuccess || orderProcessSuccess) && getSeller(sellerId);
		setIsLoading(false);
	};

	/**
	 * This function should generally be called at useEffect on component mount.
	 * @returns A simplified list of order processes for given retailer.
	 */
	async function getOrderProcesses(): Promise<void> {
		setIsLoading(true);

		let resp: SellerOrderProcessView[];

		try {
			resp = await retailerManagementApi.getOrderProcesses();
		} catch {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			setIsLoading(false);
			return;
		}

		setOrderProcessList(resp);

		if (processId) {
			let currentProcess = resp.find((processObj) => {
				return processObj.id.toLowerCase() === processId.toLowerCase();
			});
			if (currentProcess) {
				setCurrentOrderProcess(currentProcess);
				setOrderProcessInput(getOptionLabel(currentProcess));
			}
		}

		setIsLoading(false);
	}

	/**
	 * This function upserts selected order process.
	 * @param sellerId Seller id.
	 * @param orderProcess The order process object.
	 */
	async function upsertOrderProcess(
		sellerId: string,
		orderProcess: SellerOrderProcessView
	): Promise<boolean> {
		let success = await retailerManagementApi.upsertOrderProcess(
			sellerId,
			orderProcess
		);

		if (!success) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			return false;
		}
		return true;
	}

	/**
	 * This functions upserts the provided integrationConfig data
	 * @param sellerId seller identifier
	 * @param configView integrationConfig object
	 * @returns boolean of the result of upsert request
	 */
	async function upsertIntegrationConfig(
		sellerId: string,
		configView: IntegrationConfigForm
	): Promise<boolean> {
		const safetyStockAsNumber: number = configView.safetyStockQuantity
			? Number(configView.safetyStockQuantity)
			: -1;

		let configDto: SellerIntegrationDto = {
			...(configView.id !== undefined && { id: configView.id }),
			orderIntegrationId: configView.orderIntegrationId,
			orderIntegrationCredentials: configView.orderIntegrationCredentials,
			fraudIntegrationId: configView.fraudIntegrationId,
			fraudIntegrationCredentials: configView.fraudIntegrationCredentials,
			paymentIntegrationId: configView.paymentIntegrationId,
			paymentIntegrationCredentials: configView.paymentIntegrationCredentials,
			safetyStockQuantity: safetyStockAsNumber,
			disableToUseChangeSeller: configView.disableToUseChangeSeller,
		};

		let success = await retailerManagementApi.upsertIntegrationConfig(
			sellerId,
			configDto
		);

		if (!success) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			return false;
		}

		NewToastComponent({
			status: "success",
			title: i18n.t("integration.UpdateSuccess"),
		});
		return true;
	}

	return {
		// Props
		configurations,
		integrationFieldList,
		integrationConfig,
		isDefaultSafetyStock,
		isLoading,
		orderProcessList,
		currentOrderProcess,
		orderProcessInput,
		formErrors,
		setCurrentOrderProcess,
		setOrderProcessInput,
		setIntegrationConfig,
		getOptionLabel,
		isOptionEqualToValue,
		filterOptions,
		// Functions
		onChangeIntegration,
		handleFormSubmit,
		getOrderProcesses,
	};
}
