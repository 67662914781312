import orderDetails from "./orders/orderDetailsEN.json";
import errorMessages from "./errors/errorMessagesEN.json";
import productManagement from "./productManagement/productManagementEN.json";
import exportScreen from "./exportScreen/exportScreenEN.json";
import header from "./header/headerEN.json";
import searchInputClose from "./components/searchInputCloseEN.json";
import components from "./components/componentsEN.json";

const messages = {
	en: {
		translations: {
			orderDetails,
			errorMessages,
			productManagement,
			exportScreen,
			header,
			searchInputClose,
			components,
		},
	},
};

export { messages };
