import React, { Component } from 'react';
import TermsOfUse from './TermsOfUse'
import PrivacyPolicy from './PrivacyPolicy';
import Faq from './Faq';
import PromotionPolicy from './PromotionPolicy'
import  { Redirect } from 'react-router-dom'

function Index(props) {


const handleScreen= () => {
	let comp;
	switch (props.screen) {
		case 'terms-of-use':
			comp = <TermsOfUse />;
			break
		case 'privacy-policy':
			comp = (<PrivacyPolicy />);
			break
		case 'faq':
			comp = (<Faq />);
			break
		case 'promotion-policy':
			comp = (<PromotionPolicy />);
			break
		default:
			comp = (<Redirect exact push to='/*'  />)
			break
	}

	return comp;
}



	return (
		<>
			{handleScreen()}
		</>
	)
};

export default Index;
