import React, { useEffect } from "react";
import "react-widgets/dist/css/react-widgets.css";
import Address from "./components/Address/Address";
import Payment from "./components/Payment/Payment";
import Modalities from "./components/Modalities/Modalities";
import SideNav from "./components/SideNav";
import WorkingHours from "./components/WorkingHours/WorkingHours";
import BasicInformation from "./components/BasicInformation/BasicInformation";
import { useConfig } from "./../../providers/ConfigProvider";
import LoadDashboard from "../Dashboard/components/LoadDashboard";
import Integration from "./components/Integration/Integration";
import useSellerScreenService from "./SellerScreenService";

export default function New(props) {
	const { mainLoading } = useConfig();
	const {
		isLoading,
		currentComponent,
		setCurrentComponent,
		seller,
		// Functions
		getSeller,
		updatePaymentData,
		createSeller,
		updateSellerBasicInfo,
	} = useSellerScreenService();

	useEffect(() => {
		if (props.match.params.id) {
			getSeller(props.match.params.id);
		}
	}, []);

	const renderComponent = () => {
		switch (currentComponent) {
			case "basic":
				return (
					<BasicInformation
						sellerId={props.match.params.id}
						data={seller}
						createSeller={createSeller}
						updateSellerBasicInfo={updateSellerBasicInfo}
					/>
				);
			case "address":
				return (
					<Address
						sellerId={props.match.params.id}
						data={seller.address}
						getSeller={getSeller}
					/>
				);
			case "modalities":
				return (
					<Modalities
						sellerId={props.match.params.id}
						data={seller.modalities}
						getSeller={getSeller}
					/>
				);
			case "payment":
				return (
					<Payment
						sellerId={props.match.params.id}
						sellerData={seller}
						updatePaymentData={updatePaymentData}
					/>
				);
			case "integration":
				return (
					<Integration
						sellerId={props.match.params.id}
						data={seller.integrationConfig}
						processId={seller.processId}
						getSeller={getSeller}
					/>
				);
			case "workingHour":
				return <WorkingHours sellerId={props.match.params.id} />;

			default:
				break;
		}
	};

	return (
		<>
			{mainLoading ? (
				<LoadDashboard />
			) : (
				<div className="container dash-content">
					<div className="row">
						<div className="col-4">
							<SideNav
								isEcommerce={seller?.isEcommerce}
								isLoading={isLoading}
								activeComponent={setCurrentComponent}
								id={props.match.params.id}
							/>
						</div>
						<div className="card col-8 m-0 p-0">{renderComponent()}</div>
					</div>
				</div>
			)}
		</>
	);
}
