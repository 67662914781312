import React, { ChangeEvent } from "react";
import { Grid, SelectChangeEvent } from "@mui/material";
import { i18n } from "../../../../translate/i18n";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import CustomInput from "../../../../MUICustomComponents/CustomInput";
import RetailerCard from "../components/RetailerCard";
import RetailerCheckbox from "../components/RetailerCheckbox";
import { AppConfig } from "../types/AppConfig";

type Props = {
	appConfig: AppConfig;
	onChange: (
		event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>,
		checked?: boolean
	) => void;
};

export default function AppCart({ appConfig, onChange }: Props) {
	return (
		<RetailerCard>
			<CustomTypography variant="secondaryTitle" marginBottom={2}>
				{i18n.t("retailerConfig.AppCart").toString()}
			</CustomTypography>
			<Grid container columnSpacing={2} mt={-1}>
				<Grid item xs={6}>
					<RetailerCheckbox
						id="enablePromotionEngine"
						isChecked={appConfig.enablePromotionEngine}
						onChange={onChange}
						helpTooltip={{
							title: i18n.t(`retailerConfig.EnablePromotionEngineTooltip`),
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<RetailerCheckbox
						id="enableUserPromotion"
						isChecked={appConfig.enableUserPromotion}
						onChange={onChange}
						helpTooltip={{
							title: i18n.t(`retailerConfig.EnableUserPromotionTooltip`),
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<RetailerCheckbox
						id="enablePickupOnlyCartMessage"
						isChecked={appConfig.enablePickupOnlyCartMessage}
						onChange={onChange}
						helpTooltip={{
							title: i18n.t(
								`retailerConfig.EnablePickupOnlyCartMessageTooltip`
							),
						}}
					/>
				</Grid>
				<Grid item xs={12} mt={1}>
					<CustomInput
						title={i18n.t(`retailerConfig.${"minAmountPurchase"}`)}
						name="minAmountPurchase"
						value={appConfig.minAmountPurchase}
						onChange={onChange}
						type="number"
					/>
				</Grid>
				{appConfig.enablePickupOnlyCartMessage && (
					<Grid item xs={12} mt={2}>
						<CustomInput
							title={i18n.t(`retailerConfig.${"pickupOnlyCartMessage"}`)}
							name="pickupOnlyCartMessage"
							value={appConfig.pickupOnlyCartMessage}
							onChange={onChange}
							multiline={true}
							rows={3}
							helpTooltip={{
								title: i18n.t(`retailerConfig.PickupOnlyCartMessageTooltip`),
							}}
						/>
					</Grid>
				)}
			</Grid>
		</RetailerCard>
	);
}
