import React, { useEffect } from "react";
import { useFinancial } from "../../providers/FinancialContext";
import Select from "react-select";

export default function SelectStore() {
	const { storeList, getStoreList, getRecipientId, seller } = useFinancial();

	useEffect(() => {
		getStoreList();
	}, []);

	const customStyles = {
		indicatorSeparator: (provided) => ({
			...provided,
			display: "none",
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			color: "#747E8B",
			paddingLeft: 5,
			svg: {
				width: 17,
			},
		}),
	};

	return (
		<div className="select-store-header">
			<Select
				styles={customStyles}
				className="react-select-container position"
				placeholder="Selecione uma loja"
				value={
					storeList[storeList.findIndex((store) => store.value === seller)]
				}
				options={storeList}
				theme={(theme) => ({
					...theme,
					borderRadius: 0,
					colors: {
						...theme.colors,
						primary25: "#BDCFF5",
						primary: "#9BB7F0",
					},
				})}
				onChange={(e) => getRecipientId(e.value)}
			/>
		</div>
	);
}
