import { MouseEvent, useState } from "react";

type ChromePickerProps = {
	updateChromePicker: (colorPicker: string) => void;
};

type ColorProps = {
	hex: string;
};

export default function ChromePickerService({
	updateChromePicker,
}: ChromePickerProps) {
	const [colorValue, setColorValue] = useState<string>("");
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const openColorPicker = Boolean(anchorEl);
	const id = openColorPicker ? "simple-popover" : undefined;

	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleChangeColor = (color: ColorProps): void => {
		setColorValue(color.hex);
		updateChromePicker(color.hex);
	};

	return {
		// Props
		colorValue,
		anchorEl,
		openColorPicker,
		id,
		// Functions
		handleChangeColor,
		handleClick,
		handleClose,
	};
}
