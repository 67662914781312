export enum PhoneMask {
	BrazilLandline = "(99) 9999-9999",
	BrazilMobile = "(99) 99999-9999",
}

type MaskRules = {
	[countryCode: string]: {
		[functionName: string]: (...args: any[]) => PhoneMask;
	};
};

export const countryMaskRules: MaskRules = {
	BR: {
		maskByLengthRule: (length: number): PhoneMask => {
			if (length <= 10) return PhoneMask.BrazilLandline;
			return PhoneMask.BrazilMobile;
		},
	},
};
