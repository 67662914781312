import React, { useState, useEffect } from "react";
import { API } from "../../../API";
import CollectionsList from "./components/CollectionsList.jsx";
import { toast } from "react-toastify";
import { useConfig } from "./../../../providers/ConfigProvider";
import LoadDashboard from "../../Dashboard/components/LoadDashboard";
import "./CollectionsStyle.scss";

export default function Collections(props) {
	const { mainLoading } = useConfig();
	const [loading, setLoading] = useState(true);
	const [collections, setCollections] = useState([]);
	const [totalCollections, setTotalCollections] = useState([]);
	const [page, setPage] = useState(1);

	useEffect(() => {
		setPage(props.match.params.page);
		if (!props.match.params.page) {
			window.location.href = "/collections/1";
		}
	}, []);

	const deleteColection = (id) => {
		API.delete("api/tags?id=" + id)
			.then(async (response) => {
				window.location.reload();
			})
			.catch((error) => {
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};
	return (
		<>
			{mainLoading ? (
				<LoadDashboard />
			) : (
				<CollectionsList
					loading={loading}
					collections={collections}
					deleteColection={deleteColection}
					numberPages={parseInt(totalCollections / 10)}
					/* changePage={changePage} */ totalItems={totalCollections}
					page={props.match.params.page}
				/>
			)}
		</>
	);
}
