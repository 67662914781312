import React, { Dispatch, SetStateAction } from "react";
import {
	Autocomplete,
	FormControl,
	FormHelperText,
	SxProps,
	TextField,
	Theme,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { ErrorOutline } from "@mui/icons-material";

type Props<T> = {
	id: string;
	options: T[];
	value: T | null;
	inputValue: string | null;
	renderInputLabel?: string;
	placeholder: string;
	filterOptions: (option: T) => string;
	getOptionLabel: (option: T) => string;
	onSelectChange: Function;
	onInputChange: Dispatch<SetStateAction<string>>;
	isOptionEqualToValue: (option: T, value: T) => boolean;
	error?: boolean;
	helperText?: string[];
	disabled?: boolean;
	sxProps?: SxProps<Theme>;
};

export default function CustomAutocomplete<T>({
	id,
	options,
	value,
	inputValue,
	renderInputLabel,
	placeholder,
	filterOptions,
	getOptionLabel,
	onSelectChange,
	onInputChange,
	isOptionEqualToValue,
	error = false,
	helperText = [],
	disabled = false,
	sxProps,
}: Props<T>) {
	const createdFilterOptions = createFilterOptions({
		stringify: filterOptions,
	});

	return (
		<FormControl fullWidth sx={sxProps}>
			<Autocomplete
				size="small"
				id={id}
				options={options}
				value={value}
				inputValue={inputValue ?? ""}
				filterOptions={createdFilterOptions}
				getOptionLabel={getOptionLabel}
				onChange={(_, newValue) => onSelectChange(newValue)}
				onInputChange={(_, newValue) => onInputChange(newValue)}
				isOptionEqualToValue={isOptionEqualToValue}
				disabled={disabled}
				renderInput={(params) => (
					<TextField
						{...params}
						label={renderInputLabel}
						placeholder={placeholder}
						color="secondary"
						error={error}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{error && <ErrorOutline color="error" />}
									{params.InputProps.endAdornment}
								</>
							),
						}}
					/>
				)}
			/>
			<FormHelperText error={error}>{helperText}</FormHelperText>
		</FormControl>
	);
}
