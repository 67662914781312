import React, { Component } from 'react';

// import { Container } from './styles';

export default class SimpleCard extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  render() {
    return (
      //link do botao
      <div className="material-card" onClick={() => window.open(this.props.link)}>
          {/* Insere o icone do card se houver */}
          {this.props.icon != null ?
            <div className="icon">
              <i className="material-icons">{this.props.icon}</i>
            </div> : ''
          }
          <div className="content">
            {/* Insere o titulo do card se houver */}
            <span className="card-title">{this.props.title}</span><br />
            {/* Insere a descrição do card se houver */}
            <span>{this.props.description}</span>
          </div>
        </div>
    );
  }
}
