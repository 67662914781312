import React from "react";

export default function FinancialEmpty(props) {
	return (
		<div className="empty">
			<div className="col-12 text-center">
				<img src={props.img} width="20%" />
				<h3>
					<strong>
						Ops! Não há movimentações para o período ou filtro aplicado!
					</strong>
				</h3>
			</div>
		</div>
	);
}
