import React from 'react';

// import { Container } from './styles';

function RecommendationCel() {
  return (
    <>
      <div className="col-12 d-flex justify-content-center row m-0 mb-2" style={{height: '100px'}}>
        <div className="col-3 p-2">
          <img src="https://via.placeholder.com/50x80" alt="teste" />
        </div>
        <div className="col-3 p-2">
          <img src="https://via.placeholder.com/50x80" alt="teste" />
        </div>
        <div className="col-3 p-2">
          <img src="https://via.placeholder.com/50x80" alt="teste" />
        </div>
        <div className="col-3 p-2">
          <img src="https://via.placeholder.com/50x80" alt="teste" />
        </div>
      </div>
    </>
  );
}

export default RecommendationCel;