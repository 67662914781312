import React, { useEffect, useRef, useState } from "react";
import InputSingleDate from "../../../../components/InputSingleDate";
import InputMask from "../../../../components/InputMask";
import { useForm } from "react-hook-form";
import { i18n } from "../../../../translate/i18n";
import { useOrders } from "../../providers/OrdersContext";
import InputSelect from "../../../../components/InputSelect";
import { formatDateUTC } from "../../../../infrastructure/utils/FormatMask";

export default function ChangeTrackingDataModal() {
	const {
		register,
		handleSubmit,
		reset,
		resetField,
		formState: { errors },
	} = useForm();
	const { order, handleChangeTrackings, setLoadingStatus, orderId } =
		useOrders();
	const [editing, setEditing] = useState(true);
	const carrierNameListRef = useRef(["Correios", "Jadlog", "FedEx"]);
	const shippingTypeListRef = useRef(["PAC", "Sedex", "Padrão"]);
	const timeUnitListRef = useRef([
		i18n.t("orderDetails.General.Minute"),
		i18n.t("orderDetails.General.Hour"),
		i18n.t("orderDetails.General.CalendarDay"),
		i18n.t("orderDetails.General.BusinessDay"),
	]);
	useEffect(() => {
		reset({
			invoiceNumber:
				order?.Invoices.length === 1 ? order?.Invoices[0]?.InvoiceNumber : "",
			carrierRequired: order?.Invoices[0]?.CarrierName,
			shippingType: order?.Invoices[0]?.ShippingReference,
			deliveryTime: order?.Invoices[0]?.DeliveryTime,
			deliveryTimeUnit: getDeliveryTime(order?.Invoices[0]?.DeliveryTimeUnit),
			estimatedDelivery: new Date().toLocaleDateString(),
		});
	}, [order]);

	const getDeliveryTime = (index) => {
		const typeArrowDict = {
			Minutos: "Minute",
			Horas: "Hour",
			"Dias corridos": "CalendarDay",
			"Dias úteis": "BusinessDay",
		};
		return (
			typeArrowDict[index] || i18n.t("orderDetails.OrderFlowInfo.Uninformed")
		);
	};

	const handleFormSubmit = (data) => {
		setLoadingStatus(true);
		const formTracking = [
			{
				addresseeEmail: null,
				documentPickup: null,
				invoiceNumber: data?.invoiceRequired,
				trackingCode: null,
				trackingUrl: null,
				carrierName: data?.carrierRequired,
				shippingReference: data?.shippingType,
				deliveryDate: formatDateUTC(data?.estimatedDelivery),
				deliveryTime: data?.deliveryTime,
				deliveryTimeUnit: data?.deliveryTimeUnit,
			},
		];
		const button = document.getElementById("trackingDataModal");
		button.click();
		handleChangeTrackings(orderId, formTracking);
	};

	const errorIconMessage = () => (
		<div className="neo-icons-required">
			<button type="button">
				<i className="material-icons">error_outline</i>
			</button>
		</div>
	);

	return (
		<div
			className="modal fade"
			id="trackingDataModal"
			style={{ overflowY: "scroll" }}
			tabIndex="-1"
			aria-labelledby="trackingDataModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog">
				<div className="modal-content tracking-modal p-4">
					<div className="modal-header tracking-modal-header d-flex flex-column p-2 m-0">
						<h5 className="modal-title m-0">
							{i18n.t("orderDetails.Trackings.ChangeTrackingData")}
						</h5>
					</div>
					<div className="modal-body tracking-modal-body mh-100 p-2 m-0">
						<form
							className="neo-form"
							onKeyPress={(e) => {
								e.key === "Enter" && e.preventDefault();
							}}
							onSubmit={handleSubmit(handleFormSubmit)}
						>
							<h6 className="tracking-form-title mb-3">
								{i18n.t("orderDetails.Trackings.ShippingMethod")}
							</h6>
							<div className="neo-form-input d-flex flex-column justify-content-start position-relative pb-3">
								<label
									className="tracking-input-label"
									htmlFor="invoiceRequired"
								>
									{i18n.t("orderDetails.InvoiceRequired")}
								</label>
								<select
									className={`${
										order?.Invoices.length === 1 && !editing
											? "neo-input-disabled"
											: errors["invoiceRequired"]
											? "neo-input-invalid"
											: "neo-input-valid"
									}`}
									disabled={order?.Invoices.length === 1 && !editing}
									{...register("invoiceRequired", { required: true })}
								>
									{order?.Invoices.map((value) => (
										<option key={value.Key} value={value.Number}>
											{`${value.Number} (${i18n.t(
												"orderDetails.Invoice"
											)} - ${value.Amount.toLocaleString("pt-br", {
												style: "currency",
												currency: "BRL",
											})})`}
										</option>
									))}
								</select>
								{errors["invoiceRequired"] && errorIconMessage()}
							</div>
							<InputSelect
								required={true}
								errors={errors}
								register={register}
								isInputText={false}
								isEnabled={true}
								inputName={"carrierRequired"}
								options={carrierNameListRef.current}
							/>
							<InputSelect
								required={true}
								errors={errors}
								register={register}
								isInputText={false}
								isEnabled={true}
								inputName={"shippingType"}
								options={shippingTypeListRef.current}
							/>
							<div className="d-flex">
								<div className="mr-3">
									<InputMask
										required={true}
										errors={errors}
										register={register}
										isInputText={true}
										isEnabled={true}
										inputName={"deliveryTime"}
									/>
								</div>
								<div className="w-100 d-flex align-item-end m-0 p-0 pt-3">
									<InputSelect
										required={true}
										errors={errors}
										register={register}
										isInputText={false}
										isEnabled={true}
										inputName={"deliveryTimeUnit"}
										options={timeUnitListRef.current}
									/>
								</div>
							</div>
							<InputSingleDate
								required={true}
								errors={errors}
								register={register}
								isInputText={false}
								isEnabled={true}
								inputName={"estimatedDelivery"}
								resetField={resetField}
							/>
							<div className="tracking-modal-footer d-flex justify-content-end mt-3">
								<button
									type="button"
									className="btn btn-link cancel-button mr-2"
									data-dismiss="modal"
									style={{
										color: "#5A5E61",
										border: "1px solid #8D8D8D",
										backgroundColor: "transparent",
									}}
								>
									{i18n.t("orderDetails.Cancel")}
								</button>
								<button
									type="submit"
									className="btn btn-success confirm-button"
								>
									{i18n.t("orderDetails.SaveChanges")}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
