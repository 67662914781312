export function lightOrDark(color) {
	// Variables for red, green, blue values
	let r;
	let g;
	let b;
	let lightOrDark;

	if (color.match(/^rgb/)) {
		// RGB separate variables
		color = color.match(
			/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
		);
		r = color[1];
		g = color[2];
		b = color[3];
	} else {
		// Convert HEX to RGB
		color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));
		r = color >> 16;
		g = (color >> 8) & 255;
		b = color & 255;
	}

	lightOrDark = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

	if (lightOrDark > 127.5) {
		return "light";
	} else {
		return "dark";
	}
}
