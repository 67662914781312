import React from "react";
import {
	IDataTableColumn,
	IDataTableConditionalRowStyles,
} from "react-data-table-component";
import { ListOrderModel } from "../../domain/models/commerce/OrderList/ListOrderModel";
import {
	formatDate,
	formatDateStringOnly,
	formatDocument,
} from "../../infrastructure/utils/FormatMask";
import { DocumentType } from "../../domain/enums/DocumentType";
import { customStyles } from "../List/List";
import "./OrderTable.scss";
import { Link } from "react-router-dom";
import { paletteObject } from "../../theme/foundations/palette";
import { Tooltip, Zoom } from "@mui/material";
import CustomIcon from "../../MUICustomComponents/CustomIcon";
import { WarningAmberRounded } from "@mui/icons-material";
import { i18n } from "../../translate/i18n";

export const overwriteOrderStyles = {
	...customStyles,
	headCells: {
		...customStyles.headCells,
		style: {
			...customStyles.headCells.style,
			textAlign: "center",
			padding: "8px",
		},
	},
	cells: {
		...customStyles.cells,
		style: {
			...customStyles.cells.style,
			padding: "0px 8px",
		},
	},
	rows: {
		...customStyles.rows,
		style: {
			...customStyles.rows.style,
			width: "1110px",
		},
	},
};

export const conditionalRowStyles: IDataTableConditionalRowStyles<ListOrderModel>[] =
	[
		{
			when: (row) => row.isDelayed,
			style: {
				backgroundColor: paletteObject.red05.main,
				["&:hover" as string]: {
					backgroundColor: paletteObject.red10.main,
				},
			},
		},
	];

export default function OrderTableColumns() {
	const columns: IDataTableColumn<ListOrderModel>[] = [
		{
			name: i18n.t("orderTable.Client").toString(),
			grow: 5,
			cell: (row) => (
				<div
					className="order-table-client"
					data-tag="allowRowEvents"
					style={{ maxWidth: "100%" }}
				>
					<p
						className="order-table-client-name"
						data-tag="allowRowEvents"
						style={{
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}
						title={row.buyerFullName}
					>
						{row.buyerFullName}
					</p>
					{row.documentType !== DocumentType.None && (
						<p data-tag="allowRowEvents">
							{i18n.t(`orderTable.${row.documentType}`).toString()}{" "}
							{formatDocument(row.documentType, row.document)}
						</p>
					)}
					<small data-tag="allowRowEvents">
						{i18n.t("orderTable.LastUpdate").toString()}{" "}
						{formatDate(row.lastUpdate)}
					</small>
				</div>
			),
		},
		{
			name: i18n.t("orderTable.OrderCode").toString(),
			grow: 5,
			cell: (row) => (
				<div className="order-table-order" data-tag="allowRowEvents">
					<Link
						className="order-table-order-code"
						style={{ whiteSpace: "nowrap" }}
						target="_blank"
						to={`/orders/1/detail/${row.id}`}
					>
						{row.orderCode}
					</Link>
					<p data-tag="allowRowEvents">{formatDate(row.createDate)}</p>
				</div>
			),
		},
		{
			name: i18n.t("orderTable.Status").toString(),
			center: true,
			grow: 2,
			cell: (row) => (
				<div
					className={`order-table-status ${row.statusClassName}`}
					data-tag="allowRowEvents"
				>
					<i className="material-icons-outlined" data-tag="allowRowEvents">
						{row.statusIcon}
					</i>
					<p data-tag="allowRowEvents" style={{ wordBreak: "keep-all" }}>
						{row.statusLabel}
					</p>
				</div>
			),
		},
		{
			name: i18n.t("orderTable.Items").toString(),
			center: true,
			grow: 1,
			cell: (row) => (
				<div className="order-table-products" data-tag="allowRowEvents">
					<p data-tag="allowRowEvents">{row.productQuantity}</p>
				</div>
			),
		},
		{
			name: i18n.t("orderTable.Modality").toString(),
			grow: 3,
			cell: (row) => (
				<div className="order-table-modality" data-tag="allowRowEvents">
					<p className="order-table-modality-title" data-tag="allowRowEvents">
						{i18n
							.t(`orderTable.${row.isDelivery ? "Delivery" : "PickupInStore"}`)
							.toString()}
					</p>
					{row.carrierName && (
						<span
							className="order-table-modality-subtitle"
							data-tag="allowRowEvents"
						>
							{row.carrierName}
						</span>
					)}
				</div>
			),
		},
		{
			name: i18n.t("orderTable.EstimateDelivery").toString(),
			center: true,
			grow: 3,
			cell: (row) => (
				<div className="order-table-estimate" data-tag="allowRowEvents">
					{row.isDelayed && (
						<p>
							<Tooltip
								arrow
								placement="top"
								TransitionComponent={Zoom}
								title={i18n.t("orderTable.DelayedOrder").toString()}
							>
								<i>
									<CustomIcon
										icon={<WarningAmberRounded />}
										variant="small"
										color="warning"
									/>
								</i>
							</Tooltip>
						</p>
					)}
					{row.estimateDate ? formatDateStringOnly(row.estimateDate) : "-"}
				</div>
			),
		},
		{
			name: i18n.t("orderTable.Store").toString(),
			grow: 3,
			cell: (row) => (
				<div className="order-table-origin" data-tag="allowRowEvents">
					<p
						className="order-table-origin-title"
						data-tag="allowRowEvents"
						title={row.sellerFullName}
					>
						{row.sellerFullName}
					</p>
					{row?.origin && <small data-tag="allowRowEvents">{row.origin}</small>}
				</div>
			),
		},
		{
			name: i18n.t("orderTable.Value").toString(),
			center: true,
			grow: 2,
			cell: (row) => (
				<div className="order-table-total" data-tag="allowRowEvents">
					{row.totalAmount.toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					})}
				</div>
			),
		},
		{
			name: i18n.t("orderTable.Payment").toString(),
			center: true,
			grow: 2,
			cell: (row) => (
				<div className="order-table-payment" data-tag="allowRowEvents">
					<span data-tag="allowRowEvents">
						<img src={row.paymentIcon} alt="" data-tag="allowRowEvents" />
					</span>
					<p data-tag="allowRowEvents">{row.paymentLabel}</p>
				</div>
			),
		},
	];

	return columns;
}
