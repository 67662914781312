import { useState } from "react";
import { useParams } from "react-router-dom";
import { i18n } from "../../../translate/i18n";
import { retailerManagementApi } from "../../../infrastructure/api/RetailerManagementApi";
import NewToastComponent from "../../../components/NewToastComponent";
import {
	SellerInfoDto,
	StoresDto,
} from "../../../domain/dtos/stores/StoresDto";
import { buildURLWithParams } from "../../../infrastructure/utils/BuildURLWithParams";

export default function useSellerListService() {
	const pageParam = useParams().page;
	const queryParam = useParams().query;
	const isActiveParams = useParams().isActive;
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(pageParam || "1");
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [query, setQuery] = useState(queryParam || "");
	const [sellerList, setSellerList] = useState<SellerInfoDto[]>([]);
	const [totalSellers, setTotalSellers] = useState<number>();
	const [sellerFilters, setSellerFilters] = useState({
		StoreStatus: isActiveParams ? isActiveParams : "",
	});

	const optionsDict = {
		isActive: [
			{ value: "", label: i18n.t("staticMessage.SelectMessage.ShowingAll") },
			{
				value: "true",
				label: i18n.t("staticMessage.SelectMessage.ShowActivated"),
			},
			{
				value: "false",
				label: i18n.t("staticMessage.SelectMessage.ShowDisabled"),
			},
		],
	};

	const getStores = async (): Promise<void> => {
		setLoading(true);

		let queryParams = {
			page: page,
			perPage: rowsPerPage,
			sortField: "Id",
			query: query,
			isActive: sellerFilters.StoreStatus,
		};

		try {
			let data: StoresDto = await retailerManagementApi.getStoreList(
				buildURLWithParams(queryParams)
			);

			setSellerList(data.sellers);

			setTotalSellers(data.maxCount);
		} catch (error) {
			NewToastComponent({
				status: "error",
				title: i18n.t("stores.UpsertListError").toString(),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
		}

		setLoading(false);
	};

	const deleteStore = async (storeId: string): Promise<boolean> => {
		let success = await retailerManagementApi.deleteStore(storeId);

		if (!success) {
			NewToastComponent({
				status: "error",
				title: i18n.t("stores.DeleteError"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			return false;
		}
		NewToastComponent({
			status: "success",
			title: i18n.t("stores.DeleteSuccess"),
		});

		await getStores();

		return true;
	};

	return {
		loading,
		setLoading,
		page,
		setPage,
		rowsPerPage,
		setRowsPerPage,
		query,
		setQuery,
		sellerList,
		totalSellers,
		sellerFilters,
		setSellerFilters,
		optionsDict,
		getStores,
		deleteStore,
	};
}
