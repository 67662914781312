import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { APIV2, APIV1 } from "../../../API";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Axios from "axios";

export default function CatalogStatus(props) {
	const [loading, setLoading] = useState(true);
	const [
		quantityProductsAvailableForSale,
		setQuantityProductsAvailableForSale,
	] = useState(null);
	const [quantityProductsWithoutPrice, setQuantityProductsWithoutPrice] =
		useState(null);
	const [quantitySkusWithoutStock, setQuantitySkusWithoutStock] =
		useState(null);
	const [statusQueryGetUri, setStatusQueryGetUri] = useState(null);
	const [customStatus, setCustomStatus] = useState("");
	const [runtimeStatus, setRuntimeStatus] = useState("");
	const [output, setOutput] = useState("");
	const [load, setLoad] = useState(false);

	useEffect(() => {
		getCatalogs();
		getExportWithoutSku();
	}, [props.reload]);

	const getExportWithoutSku = async () => {
		await APIV1.get(
			`insights/export/stock?clientId=${localStorage.getItem("client_id")}`
		)
			.then((response) => {
				setStatusQueryGetUri(response.data.statusQueryGetUri);
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const exportInsights = async () => {
		setLoad(true);
		var exportFilter = setInterval(async () => {
			const resp = await Axios.get(statusQueryGetUri);
			setCustomStatus(resp.data.customStatus);
			setRuntimeStatus(resp.data.runtimeStatus);
			setOutput(resp.data.output);

			if (resp.data.output !== null) {
				clearInterval(exportFilter);
				setLoad(false);
			}
		}, 1000);
	};

	const getCatalogs = () => {
		APIV2.get("catalog/reportcatalog")
			.then(async (response) => {
				setQuantitySkusWithoutStock(response.data.quantitySkusWithoutStock);
				setQuantityProductsWithoutPrice(
					response.data.quantityProductsWithoutPrice
				);
				setQuantityProductsAvailableForSale(
					response.data.quantityProductsAvailableForSale
				);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const skeleton = () => {
		const skele = [0, 1];
		return skele.map(
			(key) => (
				<ContentLoader
					key={key}
					speed={2}
					width={1100}
					height={70}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1800 100"
					style={{ width: "100%" }}
				>
					<rect x="150" y="20" rx="3" ry="3" width="200" height="20" />
					<rect x="150" y="51" rx="3" ry="3" width="80" height="6" />

					<rect x="650" y="20" rx="3" ry="3" width="200" height="20" />
					<rect x="760" y="51" rx="3" ry="3" width="80" height="6" />

					<rect x="1080" y="20" rx="3" ry="3" width="200" height="20" />
					<rect x="1080" y="51" rx="3" ry="3" width="80" height="8" />

					<rect x="1600" y="20" rx="3" ry="3" width="200" height="20" />
					<rect x="1750" y="51" rx="3" ry="3" width="80" height="8" />

					<circle cx="70" cy="51" r="40" />
					<circle cx="1000" cy="51" r="40" />
				</ContentLoader>
			)
			// </div>
		);
	};

	const renderScreen = () => {
		return (
			<div className="d-flex justify-content-between flex-wrap">
				<div style={{ width: "500px" }}>
					<div className="row mb-2">
						<div className="col-1">
							<div
								className="rounded-circle d-flex align-items-center justify-content-center"
								style={{
									backgroundColor: "#FF5B5C",
									width: "40px",
									height: "40px",
								}}
							>
								<i
									className="material-icons"
									style={{ color: "#FFFFFF", fontSize: "24px" }}
								>
									money_off
								</i>
							</div>
						</div>
						<div className="col-6 ml-3">
							<h2
								className="card-title pb-0 mb-0"
								style={{ fontSize: "14px" }}
							>{`Produtos sem Preço`}</h2>
							<Link
								to={
									"products/1?hasStock=&hasPrice=false&isAvailable=&isActive="
								}
								className="pt-0 mt-0"
								style={{ fontSize: "14px", color: "#7A9FEB" }}
							>
								Ver lista de produtos
							</Link>
						</div>
						<div className="col-4">
							<h2
								className="card-title text-right"
								style={{ fontSize: "14px", color: "#FF5B5C" }}
							>{` ${quantityProductsWithoutPrice} Produtos`}</h2>
							<h6
								className="card-subtitle mb-2 text-muted text-right"
								style={{ fontSize: "12px" }}
							>{`sem preço`}</h6>
						</div>
					</div>
					<div className="row mb-2">
						<div className="col-1">
							<div
								className="rounded-circle d-flex align-items-center justify-content-center"
								style={{
									backgroundColor: "#FF5B5C",
									width: "40px",
									height: "40px",
								}}
							>
								<i
									className="material-icons"
									style={{ color: "#FFFFFF", fontSize: "24px" }}
								>
									error_outline
								</i>
							</div>
						</div>
						<div className="col-6 ml-3">
							<div className="mb-3">
								<h2
									className="card-title pb-0 mb-0"
									style={{ fontSize: "14px" }}
								>{`SKUs sem Estoque`}</h2>
							</div>
							{runtimeStatus !== "Completed" ? (
								<button
									className="btn btn-outline btn-icon mr-4 d-flex justify-content-center"
									style={{
										padding: "10px",
										border: "1px solid #E9ECEC",
										background: "transparent",
										color: "#747E8B",
										width: "200px",
									}}
									onClick={() => exportInsights()}
								>
									{load == true ? (
										<i className="fas fa-spinner fa-spin"></i>
									) : (
										<i className="material-icons">get_app</i>
									)}
									&nbsp;&nbsp;Exportar SKUs
								</button>
							) : (
								<a
									href={output}
									className="btn btn-grey-80 btn-icon mr-4 d-flex justify-content-center"
									style={{ padding: "10px 20px", width: "200px" }}
									onClick={() => exportInsights()}
								>
									<i className="material-icons">get_app</i>&nbsp;&nbsp;Baixar
									SKUs
								</a>
							)}
						</div>
						<div className="col-4">
							<h2
								className="card-title text-right"
								style={{ fontSize: "14px", color: "#FF5B5C" }}
							>{` ${quantitySkusWithoutStock} SKUs`}</h2>
							<h6
								className="card-subtitle mb-2 text-muted text-right"
								style={{ fontSize: "12px" }}
							>{`sem estoque`}</h6>
						</div>
					</div>
				</div>
				{/* segunda coluna */}
				<div style={{ width: "500px" }}>
					<div className="row mb-2">
						<div className="col-1">
							<div
								className="rounded-circle d-flex align-items-center justify-content-center"
								style={{
									backgroundColor: "#01BFB5",
									width: "40px",
									height: "40px",
								}}
							>
								<i
									className="material-icons"
									style={{ color: "#FFFFFF", fontSize: "24px" }}
								>
									list_alt
								</i>
							</div>
						</div>
						<div className="col-6 ml-3">
							<h2
								className="card-title pb-0 mb-0"
								style={{ fontSize: "14px" }}
							>{`Produtos disponíveis para Venda`}</h2>
							<Link
								to={"products/1?hasStock=&hasPrice=&isAvailable=true&isActive="}
								className="pt-0 mt-0"
								style={{ fontSize: "14px", color: "#7A9FEB" }}
							>
								Ver lista de produtos
							</Link>
						</div>
						<div className="col-4">
							<h2
								className="card-title text-right"
								style={{ fontSize: "14px", color: "#01BFB5" }}
							>{` ${quantityProductsAvailableForSale} Produtos`}</h2>
							<h6
								className="card-subtitle mb-2 text-muted text-right"
								style={{ fontSize: "12px" }}
							>{`disponíveis para venda`}</h6>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const noIntegration = () => {
		return (
			<div className=" align-items-center py-5 empty">
				<div className="text-center">
					{/* <h3 style={{ color: '#4B5F79', fontSize: '24px', fontWeight: 'bold' }}>Ops, não temos nada por aqui ainda!</h3> */}
					<p style={{ color: "#C4C4C4", fontSize: "14px" }}>
						{" "}
						Seus status ainda não estão disponíveis no momento
					</p>
				</div>
			</div>
		);
	};

	return (
		<>
			<div className="card" style={{ height: "100%" }}>
				<div className="card-body">
					{localStorage.getItem("role") !== "1" && (
						<>
							<h2 className="card-title" style={{ fontSize: "18px" }}>
								Status do Catálogo
							</h2>
							<h6
								className="card-subtitle mb-3 text-muted mb-2"
								style={{ fontSize: "14px" }}
							>
								Último Status do Catálogo
							</h6>
						</>
					)}
					{loading
						? skeleton()
						: loading === false
						? renderScreen()
						: noIntegration()}
				</div>
			</div>
		</>
	);
}
