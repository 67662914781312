import React from "react";
import Modal from "../../../../components/Modal";
import { APIV2 } from "../../../../API";
import { toast } from "react-toastify";

export default function ConfigGlobals(props) {
	const handleCreate = (e) => {
		var body = {
			hasDynamicTutorial:
				props.action === "hasDynamicTutorial" ? props.choose : "",
			useNewCheckout: props.action === "useNewCheckout" ? props.choose : "",
			useOnlyLocalAssets:
				props.action === "useOnlyLocalAssets" ? props.choose : "",
			useLegacyCatalogAPI:
				props.action === "useLegacyCatalogAPI" ? props.choose : "",
			useLegacyCommerceAPI:
				props.action === "useLegacyCommerceAPI" ? props.choose : "",
			useLegacyMarketingAPI:
				props.action === "useLegacyMarketingAPI" ? props.choose : "",
			useLegacyRetailerAPI:
				props.action === "useLegacyRetailerAPI" ? props.choose : "",
			supportsDebitCard:
				props.action === "supportsDebitCard" ? props.choose : "",
			useNewDealerRanking:
				props.action === "useNewDealerRanking" ? props.choose : "",
			minimalAppVersion: props.action === "minimalAppVersion" ? props.text : "",
			urlBaseLori: props.action === "urlBaseLori" ? props.text : "",
			productPageType: props.action === "productPageType" ? props.detail : "",
			listClientId: props.clientId,
			catalogProductCodeMarketplace:
				props.action === "catalogProductCodeMarketplace" ? props.detail : "",
			catalogSkuCodeMarketplace:
				props.action === "catalogSkuCodeMarketplace" ? props.detail : "",
			useCatalogProcessor:
				props.action === "useCatalogProcessor" ? props.choose : "",
			useStateMachine: props.action === "useStateMachine" ? props.choose : "",
			useGeoCatalog: props.action === "useGeoCatalog" ? props.choose : "",
			useOrderEditor: props.action === "useOrderEditor" ? props.choose : "",
			useFilter: props.action === "useFilter" ? props.choose : "",
			useCatalogMatcher:
				props.action === "useCatalogMatcher" ? props.choose : "",
			isVtexCheckout: props.action === "isVtexCheckout" ? props.choose : "",
		};
		console.log(body);

		APIV2.put("management/retailer/configurations/internal/global/", body)
			.then((response) => {
				toast.success("Configurações globais editado com sucesso! 😃");
				window.location.href = "/retailerconfigurations";
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};
	return (
		<Modal
			id={props.id}
			title={"Nova configuração global"}
			confirm={true}
			content={
				<div className="alert alert-danger" role="alert">
					Ao criar uma configuração Global todas as configurações dos Varejistas
					serão sobrescritas, tem certeza que deseja continuar com essa ação?
				</div>
			}
			actionConfirm={handleCreate}
		/>
	);
}
