import React, { useEffect } from "react";
import moment from "moment";
import { useFinancial } from "../providers/FinancialContext";

export default function FinancialCancelWithdraw({ withdraw }) {
	const { cancelWithdraw } = useFinancial();

	return (
		<div
			className="modal fade"
			id="financialCancelWithdraw"
			tabIndex="-1"
			aria-labelledby="financialCancelWithdraw"
			aria-hidden="true"
		>
			<div
				className="modal-dialog modal-dialog-centered"
				style={{ minWidth: "500px" }}
			>
				<div
					className="modal-content"
					style={{ padding: "32px", minWidth: "500px ", borderRadius: "8px" }}
				>
					<div className="modal-header d-flex flex-column">
						<h5 className="financial-text title mb-2">
							Cancelar solicitação de saque
						</h5>
						<p style={{ color: "#747E8B", fontSize: "14px" }}>
							Tem certeza que deseja <strong>cancelar</strong> este saque? Esta
							ação não poderá ser revertida!
						</p>
					</div>
					<div className="modal-body">
						<div className="d-flex flex-column mb-3">
							<span className="financial-text text d-flex flex-row">
								<strong>Valor:&nbsp;&nbsp;</strong>
								<p className="text-success m-0 p-0">
									{parseFloat(withdraw.amount).toLocaleString("pt-br", {
										style: "currency",
										currency: "BRL",
									})}
								</p>
							</span>
							<span className="financial-text text">
								<strong>Data:&nbsp;&nbsp;</strong>
								{moment(new Date()).format("DD/MM/YYYY - HH:mm:ss")}
							</span>
							<span className="financial-text text">
								<strong>Status:&nbsp;&nbsp;</strong>
								{withdraw.status === "pending_transfer"
									? "Aguardando Transferência"
									: withdraw.status === "transferred"
									? "Transferido"
									: withdraw.status === "failed"
									? "Erro"
									: withdraw.status === "processing"
									? "Processando"
									: withdraw.status === "canceled"
									? "Cancelado"
									: ""}
							</span>
						</div>
					</div>
					<div className="modal-footer d-flex justify-content-end">
						<button
							type="button"
							className="btn btn-outline-danger"
							style={{ width: "190px" }}
							data-dismiss={"modal"}
							onClick={(e) => cancelWithdraw(withdraw)}
						>
							Sim, quero cancelar
						</button>
						<button
							className="btn btn-success"
							style={{ width: "190px" }}
							data-dismiss="modal"
						>
							Não quero cancelar
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
