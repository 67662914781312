import React, { ChangeEvent } from "react";
import { Grid, SelectChangeEvent } from "@mui/material";
import { i18n } from "../../../../translate/i18n";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import CustomInput from "../../../../MUICustomComponents/CustomInput";
import RetailerCard from "../components/RetailerCard";
import { AppConfig } from "../types/AppConfig";

type Props = {
	appConfig: AppConfig;
	onChange: (
		event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>,
		checked?: boolean
	) => void;
};

export default function AppClearSale({ appConfig, onChange }: Props) {
	return (
		<RetailerCard>
			<CustomTypography variant="secondaryTitle" marginBottom={2}>
				{i18n.t("retailerConfig.AppClearSale").toString()}
			</CustomTypography>
			<Grid container columnSpacing={2} mt={1}>
				<Grid item xs={12}>
					<CustomInput
						title={i18n.t(`retailerConfig.${"sdkClearSaleFingerprint"}`)}
						name="sdkClearSaleFingerprint"
						value={appConfig.sdkClearSaleFingerprint}
						onChange={onChange}
					/>
				</Grid>
			</Grid>
		</RetailerCard>
	);
}
