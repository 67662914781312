import React, { useEffect, useState } from "react";
import { APIV2 } from "../../../API";
import NewDropImage from "../../../components/NewDropImage";
import Loading from "../../../components/Loading";
import "react-widgets/dist/css/react-widgets.css";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import Attributes from "./components/Attributes/Attributes";

export default function NewSku(props) {
	const [sku, setSku] = useState({
		id: null,
		code: null,
		auxCode: null,
		isActive: null,
		name: null,
		barcode: null,
		imageUrl: "",
		images: [],
	});
	const [loading, setLoading] = useState(true);
	const [LoadingImages, setLoadingImages] = useState(false);

	useEffect(() => {
		APIV2.get(
			"catalog/products/" +
				props.match.params.productId +
				"/skus/" +
				props.match.params.skuId
		)
			.then(async (response) => {
				setSku((prevState) => ({
					...prevState,
					id: response.data.id,
					code: response.data.code,
					auxCode: response.data.auxCode,
					isActive: response.data.isActive,
					name: response.data.name,
					barcode: response.data.barcode,
					imageUrl: response.data.imageUrl,
					images: response.data.images
						? response.data.images.sort(function (a, b) {
								return a.order - b.order;
						  })
						: [],
				}));
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	}, []);

	const handleChange = (e) => {
		let { name, value } = e.target;
		setSku((prevState) => ({ ...prevState, [name]: value }));
	};

	const changeBool = (e) => {
		setSku((prevState) => ({ ...prevState, isActive: e }));
	};

	const recoveryStateImage = (e) => {
		if (e.length > 0) {
			setSku((prevState) => ({ ...prevState, imageUrl: e.imageUrl }));
		}
		for (let i = 0; i < sku.images.length; i++) {
			sku.images[i].order = i;
		}
	};

	const getImagesCatalog = async () => {
		setLoadingImages(true);
		try {
			const response = await APIV2.get(
				`catalog/products/${props.match.params.productId}/skus/${
					sku.id ? sku.id : sku.code
				}/images`
			);
			setSku((prevState) => ({
				...prevState,
				images: response.data
					.map((image) => {
						return {
							isActive: image.isActive,
							order: image.order,
							imageUrl: image.originalImageUrl,
							productCode: image.productCode,
							resizedImageUrl: image.resizedImageUrl,
							skuCode: image.skuCode,
							uriHash: image.uriHash,
						};
					})
					.sort(function (a, b) {
						return a.order - b.order;
					}),
			}));
			setLoadingImages(false);
		} catch (error) {
			console.log(error);
			setLoadingImages(false);
		}
	};

	const saveSku = async () => {
		let imagesUseCatalogProcessor = [];

		if (localStorage.getItem("UseCatalogProcessor") === "true") {
			imagesUseCatalogProcessor = sku.images.map((image) => {
				return {
					originalImageUrl: image.imageUrl
						? image.imageUrl
						: image.originalImageUrl,
					order: image.order,
					uriHash: image.uriHash ? image.uriHash : "",
				};
			});
		}

		try {
			await APIV2.put(
				`/catalog/products/${props.match.params.productId}/skus/${
					sku.id ? sku.id : sku.code
				}/images`,
				localStorage.getItem("UseCatalogProcessor") === "true"
					? imagesUseCatalogProcessor
					: sku.images
			);
			getImagesCatalog();
		} catch (error) {
			console.log(error);
		}

		APIV2.put(
			"catalog/products/" +
				props.match.params.productId +
				"/skus/" +
				props.match.params.skuId,
			sku
		)
			.then(async (response) => {
				toast.success("SKU editado com sucesso!😃");
			})
			.catch((error) => {
				console.log(error);
				if (error.response.data.errors.length > 0) {
					error.response.data.errors.forEach((err) => {
						switch (err) {
							case "The Name field is required.":
								toast.error("Nome da variação não foi preenchido");
								break;
							default:
								toast.error(
									"Parece que tivemos um erro... Tente novamente mais tarde."
								);
								break;
						}
					});
				} else {
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				}
			});
	};

	const renderContent = () => {
		return (
			<>
				<div className="form-group col-10">
					<label htmlFor="name">
						<strong>Nome da variação</strong>
					</label>
					<div>
						<input
							type="text"
							className="form-control"
							id="name"
							placeholder="Preto"
							name="name"
							value={sku.name}
							onChange={handleChange}
							required
						/>
					</div>
				</div>
				<div className="form-group col-2">
					<label htmlFor="limiteUso">
						<strong>Visibilidade</strong>
					</label>
					<br />
					<label className="switch ">
						<input
							type="checkbox"
							name="isActive"
							checked={sku.isActive}
							onChange={(e) => changeBool(e.target.checked)}
						/>
						<span className="slider round"></span>
					</label>
				</div>
				<div className="form-group col-4">
					<label htmlFor="code">
						<strong>Código da variação</strong>
					</label>
					<div>
						<input
							disabled={props.match.params.skuId}
							type="text"
							className="form-control"
							id="code"
							placeholder="0"
							name="code"
							value={sku.code}
							onChange={handleChange}
							required
						/>
					</div>
				</div>
				<div className="form-group col-4">
					<label htmlFor="auxCode">
						<strong>Código Auxiliar</strong>
					</label>
					<div>
						<input
							type="text"
							className="form-control"
							id="auxCode"
							placeholder="0"
							name="auxCode"
							value={sku.auxCode}
							onChange={handleChange}
							required
						/>
					</div>
				</div>
				<div className="form-group col-4">
					<label htmlFor="barcode">
						<strong>Código de barras</strong>
					</label>
					<div>
						<input
							type="text"
							className="form-control"
							id="barcode"
							placeholder="0"
							name="barcode"
							value={sku.barcode}
							onChange={handleChange}
						/>
					</div>
				</div>

				<div className="form-group col-12">
					{LoadingImages ? (
						<Loading />
					) : (
						<NewDropImage
							type={"carrousel"}
							data={sku.images}
							recoveryStateLogo={recoveryStateImage}
							height={"180px"}
							width={"100%"}
							background={"#fff"}
							colorText={"#747E8B"}
						/>
					)}
				</div>
			</>
		);
	};

	return (
		<>
			<div className="container">
				<div className="card">
					<div className="card-header">
						<div className="mb-0">
							<h4 className="card-title">
								{!sku.name
									? "Edição da variação..."
									: `Edição da variação ${sku.name}`}
							</h4>
						</div>
					</div>
					<div className="card-content">
						<div className="card-body">
							<div className="row">
								{loading ? <Loading /> : renderContent()}
								<div className="form-group col-12 d-flex justify-content-end align-items-end">
									<Button
										className="btn btn-success"
										type="button"
										loading={loading}
										disable={loading}
										onClick={() => saveSku()}
									>
										Salvar
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container my-4">
				<div className="card">
					<Attributes />
				</div>
			</div>
		</>
	);
}
