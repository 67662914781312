import { BaseOverrideColors } from "./baseOverrideColors";

import { paletteObject } from "../foundations/palette";

declare module "@mui/material/Button" {
	interface AutoCompletePropsColorOverrides extends BaseOverrideColors {}
}

export const MuiAutocomplete = {
	styleOverrides: {
		listbox: {
			fontSize: 14,
			"& .MuiAutocomplete-option": {
				'&[aria-selected="true"]': {
					backgroundColor: paletteObject.blue10.main,
					color: paletteObject.blueish80.main,
				},
				'&[aria-selected="true"].Mui-focused': {
					color: paletteObject.blueish80.main,
					backgroundColor: paletteObject.blue10.main,
				},

				"&.Mui-focused": {
					backgroundColor: paletteObject.grey10.main,
					color: paletteObject.blueish80.main,
				},
			},
		},
	},
};
