import React from "react";
import ContentLoader from "react-content-loader";

export default function InventoryCustomLoader() {
	return (
		<div className="d-flex flex-column w-100">
			{[...Array(15)].map((_, index) => (
				<ContentLoader
					key={index}
					speed={2}
					width={1110}
					height={96}
					backgroundColor="#E9E9E9"
					foregroundColor="#F5F5F5"
					viewBox="0 0 1110 96"
					style={{
						width: "1110px !important",
						borderBottom: "1px solid #E0E3E7",
					}}
				>
					<rect x="26" y="36" rx="6" ry="6" width="24" height="24" />
					<rect x="58" y="16" rx="6" ry="6" width="64" height="64" />
					<rect x="138" y="19" rx="6" ry="6" width="150" height="19" />
					<rect x="138" y="42" rx="6" ry="6" width="150" height="16" />
					<rect x="138" y="62" rx="6" ry="6" width="124" height="16" />
					<rect x="439" y="39" rx="6" ry="6" width="64" height="19" />
					<rect x="568" y="39" rx="6" ry="6" width="76" height="19" />
					<rect x="723" y="40" rx="6" ry="6" width="40" height="16" />
					<circle cx="880" cy="48" r="12" />
					<rect x="1028" y="36" rx="6" ry="6" width="24" height="24" />
					<rect x="1060" y="36" rx="6" ry="6" width="24" height="24" />
				</ContentLoader>
			))}
		</div>
	);
}
