import React, { useEffect, useState } from "react";
import { useOrders } from "../../providers/OrdersContext";
import {
	Divider,
	Link,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from "@mui/material";
import { Typography } from "antd";
import { isValidUrl } from "../../../../infrastructure/utils/TypeValidator";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import CustomIcon from "../../../../MUICustomComponents/CustomIcon";

export default function OrderAttachments() {
	const { order } = useOrders();
	const [attachmentList, setAttachmentList] = useState([]);

	useEffect(() => {
		if (!order.CustomData) return;
		createAttachmentList(order.CustomData);
	}, [order]);

	const createAttachmentList = (customData) => {
		const newAttachmentList = customData.filter(
			(attachment) => attachment.Key !== "CORREIOS_NUMBER"
		);
		setAttachmentList(newAttachmentList);
	};

	const attachmentItem = (item) => (
		<TableRow key={item.Key}>
			<TableCell component="th" scope="row" sx={{ whiteSpace: "nowrap" }}>
				<Typography>{item.Key}</Typography>
			</TableCell>
			<TableCell align="right">
				{isValidUrl(item.Value, false) ? (
					<Link
						href={item.Value}
						underline="none"
						color="inherit"
						target="_blank"
						rel="noreferrer"
					>
						Abrir <CustomIcon icon={<OpenInNewIcon />} variant="small" />
					</Link>
				) : (
					<Typography>{item.Value}</Typography>
				)}
			</TableCell>
		</TableRow>
	);

	return (
		<>
			{attachmentList.length > 0 && (
				<>
					<Divider sx={{ my: 3 }} />
					<Paper variant="grey10" elevation={0}>
						<CustomTypography variant="smallTitle">
							Informações adicionais
						</CustomTypography>
						<CustomTypography variant="subtitle">
							Anexos ({attachmentList.length})
						</CustomTypography>
						<Table sx={{ width: "50%" }}>
							<TableBody>{attachmentList?.map(attachmentItem)}</TableBody>
						</Table>
					</Paper>
				</>
			)}
		</>
	);
}
