import React, { Dispatch, SetStateAction } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import { i18n } from "../../../translate/i18n";
import { DateType } from "../../../domain/enums/DateType";
import { AutocompleteSellerView } from "../../../domain/views/dealers/AutocompleteSellerView";
import { OrderFilterStatus } from "../../../screens/SMOrders/enums/OrderFilterStatus";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import SellerAutocomplete from "../../SellerAutocomplete/SellerAutocomplete";
import SearchInputClose from "../../SearchInputClose";
import MultiDatePiker from "../../MultDatePiker";
import { getSinceFilter } from "../../../infrastructure/utils/Dates";
import { OrderFilters } from "../../../domain/types/orders/OrderFilters";
import { AutocompleteSellerDto } from "../../../domain/dtos/dealers/AutocompleteSellerDto";

type OrderStatus = {
	checked: boolean;
	value: OrderFilterStatus | string; // refactor on status filter will remove "| string"
	name: string;
};

type Props = {
	isLoading: boolean;
	orderFilters: OrderFilters;
	currentSeller: AutocompleteSellerView | null;
	setCurrentSeller: Dispatch<SetStateAction<AutocompleteSellerView | null>>;
	updateFilters: (orderFilters: OrderFilters) => void;
	filtered: OrderStatus[];
	setFiltered: Dispatch<SetStateAction<OrderStatus[]>>;
	setModality: Dispatch<SetStateAction<OrderStatus[]>>;
	clearOneFilter: (data: OrderStatus) => void;
	openModal: Function;
};

export default function OrderFiltersLayout({
	isLoading,
	orderFilters,
	currentSeller,
	setCurrentSeller,
	updateFilters,
	filtered,
	setFiltered,
	setModality,
	clearOneFilter,
	openModal,
}: Props) {
	const isCustomBreakpoint = useMediaQuery(`(max-width:992px)`);
	const defaultSellerOption: AutocompleteSellerDto = {
		clientId: null,
		cnpj: "",
		fullName: i18n.t("orderList.AllSellers"),
		id: "",
		isActive: false,
		pointSaleId: "",
	};

	const handleFilterUpdate = (newFilters: OrderFilters): void => {
		const newOrderFilters: OrderFilters = {
			...newFilters,
			page: 1,
		};
		updateFilters(newOrderFilters);
	};

	const changeDate = (type: Exclude<DateType, DateType.CustomDate>): void => {
		handleFilterUpdate({
			...orderFilters,
			dateType: type,
			since: getSinceFilter(type),
			until: new Date().toISOString(),
		});
	};

	const changeToCustomDate = (dates: string[]): void => {
		handleFilterUpdate({
			...orderFilters,
			dateType: DateType.CustomDate,
			since: dates[0],
			until: dates[1],
		});
	};

	const changeSearch = (search: string): void => {
		if (!search) {
			clearAllFilters();
			return;
		}
		setCurrentSeller(defaultSellerOption);
		setFiltered([]);
		setModality([]);
		handleFilterUpdate({
			...orderFilters,
			rowsPerPage: 20,
			dateType: DateType.CustomDate,
			since: new Date(2000, 0, 1).toISOString(),
			until: new Date().toISOString(),
			status: null,
			isDelivery: null,
			search: search,
			sellerCode: null,
			withError: null,
		});
	};

	const changeSeller = (seller: AutocompleteSellerView | null): void => {
		if (currentSeller?.cnpj === defaultSellerOption.cnpj && seller === null) {
			return;
		}

		setCurrentSeller(seller);

		if (currentSeller === null && seller?.cnpj === defaultSellerOption.cnpj) {
			return;
		}

		handleFilterUpdate({
			...orderFilters,
			sellerCode: !seller || !seller?.cnpj ? null : seller.cnpj,
		});
	};

	const clearAllFilters = (): void => {
		setCurrentSeller(defaultSellerOption);
		setFiltered([]);
		setModality([]);
		handleFilterUpdate({
			...orderFilters,
			rowsPerPage: 20,
			dateType: DateType.Today,
			since: getSinceFilter(DateType.Today),
			until: new Date().toISOString(),
			status: null,
			isDelivery: null,
			search: null,
			sellerCode: null,
			withError: null,
		});
	};

	const dateFilterButton = (
		dateType: Exclude<DateType, DateType.CustomDate>
	): JSX.Element => {
		const className =
			orderFilters.dateType === dateType ? "active-data-btn" : "btn";
		return (
			<button
				className={className}
				onClick={() => changeDate(dateType)}
				disabled={isLoading}
			>
				{isLoading && orderFilters.dateType === dateType ? (
					<i className="fas fa-spinner fa-spin"></i>
				) : (
					<span>{i18n.t(`orderList.${dateType}`).toString()}</span>
				)}
			</button>
		);
	};

	return (
		<div className="filter-orders">
			<Grid
				container
				p={3}
				pt={0}
				mt={0}
				spacing={1}
				columnSpacing={2}
				alignItems={"flex-end"}
			>
				<Grid
					item
					xs={12}
					md={
						localStorage.getItem("role") !== "2" && isCustomBreakpoint ? 12 : 6
					}
				>
					<SearchInputClose
						value={orderFilters.search ?? ""}
						onChange={changeSearch}
						placeholder={i18n.t("orderList.SearchPlaceholder")}
						loading={isLoading}
					/>
				</Grid>
				{localStorage.getItem("role") === "2" && (
					<Grid item xs={12} md={6}>
						<CustomTypography variant="small" isBold marginBottom={1}>
							{i18n.t("orderList.ShowingOrdersOf").toString()}
						</CustomTypography>
						<SellerAutocomplete
							hideLabel
							sellerCode={orderFilters.sellerCode}
							currentSeller={currentSeller}
							setCurrentSeller={setCurrentSeller}
							handleAutocompleteChange={changeSeller}
							defaultOption={defaultSellerOption}
						/>
					</Grid>
				)}
				<Grid item xs={12} md={isCustomBreakpoint ? 12 : 6}>
					<div className="filter-date">
						<CustomTypography variant="small" isBold marginBottom={1}>
							{i18n.t("orderList.SelectedPeriod").toString()}
						</CustomTypography>
						<div className="group-btn">
							{dateFilterButton(DateType.Today)}
							{dateFilterButton(DateType.SevenDays)}
							{dateFilterButton(DateType.ThirtyDays)}
							<MultiDatePiker
								useDatePiker={orderFilters.dateType !== DateType.CustomDate}
								date={changeToCustomDate}
								className={
									orderFilters.dateType === DateType.CustomDate
										? "active-data-btn py-0"
										: ""
								}
								loading={isLoading}
								isCurrentDateType={
									orderFilters.dateType === DateType.CustomDate
								}
								since={orderFilters.since}
								until={orderFilters.until}
							/>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} md={isCustomBreakpoint ? 12 : 6}>
					<div className="d-flex">
						<button
							className="btn btn-outline d-flex justify-content-center mr-1"
							style={{
								background: "transparent",
								border: "1px solid #5A5E61",
								color: "#5A5E61",
								width: "190px",
								height: "40px",
							}}
							type="button"
							onClick={() => openModal()}
							data-toggle="modal"
							data-target="#filterOrders"
						>
							<i
								className="material-icons-outlined"
								style={{ marginRight: "8px" }}
							>
								filter_alt
							</i>
							{i18n
								.t("orderList.Filter")
								.replace(
									"%X",
									filtered.length === null ? "0" : `${filtered.length}`
								)}
						</button>
						<button
							className="btn btn-icon d-flex justify-content-center mx-1"
							onClick={clearAllFilters}
							style={{
								border: "1px solid #747E8B",
								background: "#747E8B",
								color: "#FFFFFF",
								width: "190px",
								height: "40px",
							}}
						>
							<i className="material-icons" style={{ marginRight: "8px" }}>
								delete_outline
							</i>
							{i18n.t("orderList.ClearFilters").toString()}
						</button>
					</div>
				</Grid>
				{filtered.length > 0 && (
					<Grid item xs={12}>
						<CustomTypography variant="small" isBold marginBottom={1}>
							{i18n.t("orderList.AddedFilters").toString()}
						</CustomTypography>
						<div className="d-flex flex-row flex-wrap">
							{filtered.map((data) => (
								<button
									key={data.value}
									className="btn-filtered mt-2"
									style={
										isLoading
											? { cursor: "not-allowed" }
											: { cursor: "pointer" }
									}
									disabled={isLoading}
									onClick={() => clearOneFilter(data)}
								>
									{data.name} <i className="material-icons">close</i>
								</button>
							))}
						</div>
					</Grid>
				)}
			</Grid>
		</div>
	);
}
