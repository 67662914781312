import React, { SetStateAction } from "react";
import { Modal, Box, Button, Stack } from "@mui/material";
import { i18n } from "../../../translate/i18n";
import { StyledScrollbar } from "../../../MUICustomComponents/CustomScrollbar";
import CategoriesCheckboxService from "./CategoriesCheckboxService";
import { Tree } from "antd";
import SearchInputClose from "../../SearchInputClose";
import { MoreHorizRounded } from "@mui/icons-material";
import CustomIcon from "../../../MUICustomComponents/CustomIcon";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import CustomSkeleton from "../../../MUICustomComponents/CustomSkeleton";
import { Key } from "antd/lib/table/interface";

import * as Styled from "./CategoryCheckboxStyles";

type CategoriesCheckboxProps = {
	isMultiSelect: boolean;
	selectedValues: string[];
	handleFunction: (value: Key[]) => Key[];
	isOpenCategoryModal: boolean;
	setIsOpenCategoryModal: (value: SetStateAction<boolean>) => void;
};

export default function CategoriesCheckbox({
	isMultiSelect,
	selectedValues,
	handleFunction,
	isOpenCategoryModal,
	setIsOpenCategoryModal,
}: CategoriesCheckboxProps) {
	const {
		// Props
		categoriesSelect,
		queryValue,
		showContentBottom,
		renderedCheckboxes,
		isLoading,
		// Functions
		onCheck,
		handleQueryChange,
		handleSubmit,
		handleCancelSubmit,
		handleScroll,
	} = CategoriesCheckboxService({
		selectedValues,
		handleFunction,
		isMultiSelect,
		setIsOpenCategoryModal,
	});

	return (
		<Modal
			open={isOpenCategoryModal}
			onClose={handleCancelSubmit}
			aria-labelledby="category-modal-title"
			aria-describedby="category-modal-description"
		>
			<Styled.ModalBody>
				<Box mb={2}>
					<CustomTypography variant="secondaryTitle">
						{i18n.t(`categories.Titles.Categories`).toString()}
					</CustomTypography>
				</Box>

				<Box mb={4}>
					<SearchInputClose
						loading={false}
						value={queryValue}
						onChange={handleQueryChange}
						placeholder={i18n.t(`categories.Titles.SearchCategory`).toString()}
					/>
				</Box>

				{isLoading ? (
					<CustomSkeleton quantity={8} height={30} />
				) : (
					<StyledScrollbar
						onScroll={handleScroll}
						minHeight={"300px"}
						maxHeight={"300px"}
					>
						<Styled.CheckboxList>
							<Tree
								checkable
								checkStrictly={true}
								checkedKeys={categoriesSelect}
								onCheck={onCheck}
								treeData={renderedCheckboxes}
							/>
						</Styled.CheckboxList>

						{showContentBottom && (
							<Box display="flex" justifyContent="center">
								<CustomIcon icon={<MoreHorizRounded />} variant="default" />
							</Box>
						)}
					</StyledScrollbar>
				)}

				<Stack direction="row" justifyContent="end" mt={4}>
					<Box mr={"10px"}>
						<Button
							variant="contained"
							color="light"
							onClick={handleCancelSubmit}
						>
							{i18n.t(`buttons.General.BtnCancel`).toString()}
						</Button>
					</Box>
					<Button variant="contained" color="success" onClick={handleSubmit}>
						{i18n.t(`buttons.General.BtnSave`).toString()}
					</Button>
				</Stack>
			</Styled.ModalBody>
		</Modal>
	);
}
