import BaseApi from "./BaseApi";
import { HttpMethod } from "../../domain/enums/HttpMethodType";
import { OrdersDto } from "../../domain/dtos/smOrders/OrdersDto";
import { PickingModel } from "../../domain/models/commerce/PickingModel";

interface ISMCommerceManagement {
	getOrders: (params: string) => Promise<OrdersDto>;
	upsertPicking: (orderId: string, pickingData: any) => Promise<boolean>;
}

class SMCommerceManagement extends BaseApi implements ISMCommerceManagement {
	async getOrders(params: string): Promise<OrdersDto> {
		let resp = await this.request<OrdersDto>(
			`orders?${params}`,
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error("Could not get orders");
		}

		return resp.data;
	}

	async upsertPicking(
		orderId: string,
		pickingData: PickingModel
	): Promise<boolean> {
		const resp = await this.request(
			`orders/${orderId}/picking`,
			HttpMethod.Post,
			pickingData
		);

		return this.isResponseSuccessful(resp.status);
	}
}

export const smCommerceManagementApi: ISMCommerceManagement =
	new SMCommerceManagement("sm/management/commerce/");
