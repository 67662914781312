export enum PaymentStatus {
	Pending = "Pending",
	PreApproved = "PreApproved",
	FraudAnalysis = "FraudAnalysis",
	WaitingCapture = "WaitingCapture",
	Paid = "Paid",
	WaitingRefund = "WaitingRefund",
	Refunded = "Refunded",
	Error = "Error",
}
