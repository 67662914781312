import React from "react";

export default function ArrowDownRightSm({ left }) {
	return (
		<svg
			style={{
				position: "absolute",
				zIndex: "0",
				left: left,
				bottom: "58px",
				overflow: "visible",
			}}
			width="101"
			height="170"
			viewBox="0 0 101 170"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM100.354 166.354C100.549 166.158 100.549 165.842 100.354 165.646L97.1716 162.464C96.9763 162.269 96.6597 162.269 96.4645 162.464C96.2692 162.66 96.2692 162.976 96.4645 163.172L99.2929 166L96.4645 168.828C96.2692 169.024 96.2692 169.34 96.4645 169.536C96.6597 169.731 96.9763 169.731 97.1716 169.536L100.354 166.354ZM2.5 3L2.5 162L3.5 162L3.5 3L2.5 3ZM7 166.5H100V165.5H7V166.5ZM2.5 162C2.5 164.485 4.51472 166.5 7 166.5V165.5C5.067 165.5 3.5 163.933 3.5 162L2.5 162Z"
				fill="#939698"
			/>
		</svg>
	);
}
