import React, {
	ReactNode,
	createContext,
	useEffect,
	useMemo,
	useState,
	useRef,
} from "react";
import { CategoryDto } from "../../../domain/dtos/categories/CategoryDto";
import { i18n } from "../../../translate/i18n";
import { CategoryView } from "../../../domain/views/categories/CategoryView";
import { catalogManagementApi } from "../../../infrastructure/api/CatalogManagementApi";
import { useHistory } from "react-router-dom";
import { useSearchParams } from "../../../hooks/useSearchParams";
import { CategorySearchParams } from "../types/CategorySearchParams";
import { categoryContextStates } from "../types/CategoryContextStates";
import { CategorySelect } from "../types/CategorySelect";
import useUnsavedFormSafety from "../../../hooks/useUnsavedFormSafety";
import NewToastComponent from "../../../components/NewToastComponent";

const CategoryContext = createContext<categoryContextStates>(
	{} as categoryContextStates
);

const CategoryProvider = ({ children }: { children: ReactNode }) => {
	const paramsRef = useRef<boolean>(false);
	const history = useHistory();
	const searchParams = useSearchParams<CategorySearchParams>();

	const clearCurrentCategory: CategoryView = {
		parentId: null,
		children: [],
		isActive: false,
		name: "",
		code: "",
		description: null,
		order: 0,
		iconUrl: null,
	};

	const clearCurrentParentCategoryId: CategorySelect = {
		id: "",
		name: i18n.t(`categories.Titles.Root`),
	};

	const [isEditable, setIsEditable] = useState<boolean>(false);
	const [currentCategory, setCurrentCategory] =
		useState<CategoryView>(clearCurrentCategory);
	const [categoryToBeDeleted, setCategoryToBeDeleted] =
		useState<CategoryView>(clearCurrentCategory);
	const [currentParentCategoryId, setCurrentParentCategoryId] =
		useState<CategorySelect>(clearCurrentParentCategoryId);
	const [enableCategoryForm, setEnableCategoryForm] = useState<boolean>(false);
	const [categoryId, setCategoryId] = useState<string>("");
	const [categoryOrder, setCategoryOrder] = useState<number>(0);
	const [categories, setCategories] = useState<CategoryView[]>([]);
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingList, setIsLoadingList] = useState<boolean>(true);
	const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);
	const [isReorderMode, setIsReorderMode] = useState<boolean>(false);
	const [isUpsertMode, setIsUpsertMode] = useState<boolean>(false);
	const [isCancellationAction, setIsCancellationAction] =
		useState<boolean>(false);

	const {
		unsavedChanges,
		enableUnsavedChanges,
		disableUnsavedChanges,
		promptMessage,
	} = useUnsavedFormSafety();

	useEffect(() => {
		setIsLoading(true);

		setIsLoadingList(true);

		paramsRef.current = true;

		getCategories();
	}, []);

	useEffect(() => {
		if (searchParams.id !== undefined) return;

		setEnableCategoryForm(false);

		setIsUpsertMode(false);

		disableUnsavedChanges();

		setIsCancellationAction(false);
	}, [searchParams.id]);

	const notifyDataChange = (promptMessage: string): void => {
		const isConfirmationOfChange = window.confirm(promptMessage);

		if (!isConfirmationOfChange) return setIsCancellationAction(false);

		isReorderMode ? closeReorderMode() : clearCategoryForm();
	};

	const getCategories = async (): Promise<void> => {
		disableUnsavedChanges();

		setIsCancellationAction(false);

		try {
			let data: CategoryDto[] = await catalogManagementApi.getCategoryList();

			setCategories(data);

			history.push(history.location.pathname);

			paramsRef.current && getIdByUrlParams(data);
		} catch (error) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.ERR.REQUEST"),
			});
		}

		setIsLoading(false);

		setIsLoadingList(false);
	};

	const upsertCategory = (
		id: string,
		categories: CategoryView[],
		editing: boolean
	): void => {
		setIsUpsertMode(true);

		setDropdownIsOpen(false);

		setCategoryOrder(categories.length);

		setEnableCategoryForm(true);

		setIsEditable(editing);

		setCategoryId(id);

		if (!editing) {
			return history.push(history.location.pathname);
		}

		history.push(`${history.location.pathname}?id=${id}`);
	};

	const handleCategoryCancellation = (): void => {
		paramsRef.current = false;

		if (unsavedChanges) {
			return setIsCancellationAction(true);
		}

		clearCategoryForm();
	};

	const handleCategoryDeletion = (id: string): void => {
		setDropdownIsOpen(false);

		setCategoryToBeDeleted(getCategoryById(categories, id)!);

		setModalIsOpen(true);
	};

	const handleCategoryOrderingCancellation = (): void => {
		setDropdownIsOpen(false);

		if (unsavedChanges) return setIsCancellationAction(true);

		if (!unsavedChanges) {
			setIsUpsertMode(false);

			setIsReorderMode(false);
		}
	};

	const clearCategoryForm = (): void => {
		setIsEditable(false);

		setIsUpsertMode(false);

		setEnableCategoryForm(false);

		setIsCancellationAction(false);

		setCurrentCategory(clearCurrentCategory);

		setCurrentParentCategoryId(clearCurrentParentCategoryId);

		getCategories();
	};

	const closeReorderMode = (): void => {
		setIsLoading(true);

		setIsLoadingList(true);

		setIsUpsertMode(false);

		setIsReorderMode(false);

		setDropdownIsOpen(false);

		getCategories();
	};

	const updateReorderedCategories = async (): Promise<void> => {
		setDropdownIsOpen(false);

		setIsDisabled(true);

		setIsLoading(true);

		setIsLoadingList(true);

		const success = await catalogManagementApi.updateReorderedCategories(
			categories
		);

		setIsDisabled(false);

		setIsReorderMode(false);

		NewToastComponent({
			status: !success ? "error" : "success",
			title: !success
				? i18n.t("categories.Messages.ErrorReorder")
				: i18n.t("categories.Messages.ReorderCategory"),
		});

		setIsUpsertMode(false);

		setIsReorderMode(false);

		getCategories();
	};

	const getIdByUrlParams = (data: CategoryView[]): void => {
		if (searchParams.id && typeof searchParams.id === "string") {
			const isValidId = getCategoryById(data, searchParams.id);

			if (isValidId !== null) {
				return upsertCategory(searchParams.id, data, true);
			}

			history.push(history.location.pathname);
		}
	};

	const getCategoryById = (
		categories: CategoryView[],
		categoryId: string
	): CategoryDto | null => {
		for (const category of categories) {
			if (category.id === categoryId) {
				return category;
			}

			if (category.children.length > 0) {
				const result = getCategoryById(category.children, categoryId);
				if (result) {
					return result;
				}
			}
		}

		return null;
	};

	const handleToggleMenu = () => {
		setDropdownIsOpen(!dropdownIsOpen);
	};

	const contextValue = useMemo(
		() => ({
			//Props
			isEditable,
			setIsEditable,
			categoryOrder,
			categories,
			setCategories,
			categoryId,
			setCategoryId,
			enableCategoryForm,
			setEnableCategoryForm,
			modalIsOpen,
			setModalIsOpen,
			isReorderMode,
			setIsReorderMode,
			isUpsertMode,
			setIsUpsertMode,
			isDisabled,
			isLoading,
			setIsLoading,
			isLoadingList,
			setIsLoadingList,
			dropdownIsOpen,
			setDropdownIsOpen,
			paramsRef,
			isCancellationAction,
			setIsCancellationAction,
			categoryToBeDeleted,
			setCategoryToBeDeleted,
			currentParentCategoryId,
			setCurrentParentCategoryId,
			clearCurrentParentCategoryId,
			currentCategory,
			setCurrentCategory,
			clearCurrentCategory,
			//Functions
			getCategories,
			upsertCategory,
			handleCategoryDeletion,
			getCategoryById,
			updateReorderedCategories,
			handleToggleMenu,
			notifyDataChange,
			handleCategoryCancellation,
			handleCategoryOrderingCancellation,
			clearCategoryForm,
			//Hooks
			unsavedChanges,
			enableUnsavedChanges,
			disableUnsavedChanges,
			promptMessage,
		}),
		[
			//Props
			isEditable,
			setIsEditable,
			categoryOrder,
			categories,
			setCategories,
			categoryId,
			setCategoryId,
			enableCategoryForm,
			setEnableCategoryForm,
			modalIsOpen,
			setModalIsOpen,
			isReorderMode,
			setIsReorderMode,
			isUpsertMode,
			setIsUpsertMode,
			isDisabled,
			isLoading,
			isLoadingList,
			setIsLoadingList,
			dropdownIsOpen,
			setDropdownIsOpen,
			paramsRef,
			isCancellationAction,
			setIsCancellationAction,
			categoryToBeDeleted,
			setCategoryToBeDeleted,
			currentParentCategoryId,
			setCurrentParentCategoryId,
			clearCurrentParentCategoryId,
			currentCategory,
			setCurrentCategory,
			clearCurrentCategory,
			//Functions
			getCategories,
			upsertCategory,
			handleCategoryDeletion,
			getCategoryById,
			updateReorderedCategories,
			handleToggleMenu,
			notifyDataChange,
			handleCategoryCancellation,
			handleCategoryOrderingCancellation,
			clearCategoryForm,
			//Hooks
			unsavedChanges,
			enableUnsavedChanges,
			disableUnsavedChanges,
			promptMessage,
		]
	);

	return (
		<CategoryContext.Provider value={contextValue}>
			{children}
		</CategoryContext.Provider>
	);
};

export { CategoryProvider, CategoryContext };
