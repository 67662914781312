import { ChangeEvent, Key, useContext, useEffect, useState } from "react";
import { CategoryView } from "../../../../domain/views/categories/CategoryView";
import { CategoryContext } from "../../context/CategoryContext";
import NewToastComponent from "../../../../components/NewToastComponent";
import { i18n } from "../../../../translate/i18n";
import { catalogManagementApi } from "../../../../infrastructure/api/CatalogManagementApi";
import { MethodTypeFlow } from "../../enums/MethodTypeFlow";
import {
	FormErrors,
	InputValidator,
} from "../../../../infrastructure/utils/FormUtils";

import { CategoryInputs } from "../../enums/CategoryInputs";
import { isValidString } from "../../../../infrastructure/utils/TypeValidator";
import { validateInput } from "../../../../infrastructure/utils/ValidateInput";
import { CategorySelect } from "../../types/CategorySelect";

export default function CategoryFormService() {
	const {
		//Props
		isEditable,
		isLoading,
		setIsLoading,
		isLoadingList,
		setIsLoadingList,
		categoryOrder,
		categories,
		categoryId,
		enableCategoryForm,
		setEnableCategoryForm,
		setModalIsOpen,
		isReorderMode,
		setIsReorderMode,
		isDisabled,
		isCancellationAction,
		setIsCancellationAction,
		clearCurrentCategory,
		currentCategory,
		setCurrentCategory,
		clearCurrentParentCategoryId,
		currentParentCategoryId,
		setCurrentParentCategoryId,
		setIsUpsertMode,
		//Function
		getCategories,
		upsertCategory,
		getCategoryById,
		notifyDataChange,
		handleCategoryCancellation,
		handleCategoryDeletion,
		handleCategoryOrderingCancellation,
		clearCategoryForm,
		//Hooks
		unsavedChanges,
		enableUnsavedChanges,
		disableUnsavedChanges,
		promptMessage,
	} = useContext(CategoryContext);

	const [isOpenCategoryModal, setIsOpenCategoryModal] =
		useState<boolean>(false);

	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const validationFuncMapper: Record<string, Function> = {
		code: InputValidator.isRequired,
		name: InputValidator.isRequired,
	};

	useEffect((): void => {
		setFormErrors({});

		isEditable ? prepareUpdate() : prepareCreate();
	}, [isEditable, categoryId]);

	const prepareUpdate = (): void => {
		const categoryById = getCategoryById(categories, categoryId);

		if (!categoryById)
			return setCurrentParentCategoryId(clearCurrentParentCategoryId);

		setCurrentParentCategoryId(determineParentCategoryName(categoryById));

		setCurrentCategory(categoryById);
	};

	const prepareCreate = (): void => {
		if (isValidString(categoryId)) {
			prepareUpdate();
		} else {
			setCurrentParentCategoryId(clearCurrentParentCategoryId);
		}

		setCurrentCategory(clearCurrentCategory);
	};

	const determineParentCategoryName = (
		category: CategoryView
	): CategorySelect => {
		if (!isEditable) {
			return { id: category.id ?? "", name: category.name };
		}

		const categorybyId = getCategoryById(categories, category.parentId ?? "");

		if (!categorybyId) return clearCurrentParentCategoryId;

		return { id: categorybyId.id ?? "", name: categorybyId.name };
	};

	const handleCheckedChange = (event: ChangeEvent<HTMLInputElement>): void => {
		enableUnsavedChanges();

		setCurrentCategory({
			...currentCategory,
			isActive: event.target.checked,
		});
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
		enableUnsavedChanges();

		let { name, value } = event.target;

		if (validationFuncMapper[name] !== undefined) {
			const errMessages: string[] = validationFuncMapper[name](value);
			setFormErrors((prevState) => ({ ...prevState, [name]: errMessages }));
		}

		if (name === CategoryInputs.Code) {
			value = value
				.toLowerCase()
				.replace(/[^\w\s-]/g, "")
				.replace(/\s+/g, "-");
		}

		setCurrentCategory({
			...currentCategory,
			[name]: value,
		});
	};

	const deleteCategory = async (id: string): Promise<void> => {
		setIsLoading(true);

		setIsLoadingList(true);

		let success = await catalogManagementApi.deleteCategory(id);

		requestValidation(success, MethodTypeFlow.Delete);
	};

	const handleFormSubmit = async (): Promise<void> => {
		const data: CategoryView = {
			...(isEditable && { id: currentCategory.id }),
			parentId: currentParentCategoryId?.id ?? null,
			children: currentCategory.children,
			isActive: currentCategory.isActive,
			name: currentCategory.name,
			code: currentCategory.code,
			description: currentCategory.description,
			order: isEditable ? currentCategory.order : categoryOrder + 1,
			iconUrl: currentCategory.iconUrl,
		};

		const validateCategoryData = validateInput(data, validationFuncMapper);

		if (!validateCategoryData.isValid) {
			setFormErrors((prevErrors) => {
				return { ...prevErrors, ...validateCategoryData.errors } as FormErrors;
			});
			setIsLoading(false);
			return;
		}

		if (isEditable) {
			let success = await catalogManagementApi.updateCategory(data, categoryId);

			requestValidation(success, MethodTypeFlow.Update);

			return;
		}

		setIsLoading(true);

		setIsLoadingList(true);

		let success = await catalogManagementApi.createCategory(data);

		requestValidation(success, MethodTypeFlow.Create);
	};

	const requestValidation = (success: boolean, name: string): void => {
		if (!success) {
			NewToastComponent({
				status: "error",
				title: i18n.t(`errorMessages.ERR.REQUEST`),
			});

			clearCategoryForm();

			setIsLoading(false);

			setModalIsOpen(false);

			return;
		}
		NewToastComponent({
			status: "success",
			title: i18n.t(`categories.Messages.${name}`),
		});

		clearCategoryForm();

		setModalIsOpen(false);

		setCurrentParentCategoryId(clearCurrentParentCategoryId);

		getCategories();
	};

	const handleFileUpload = (file: string): void => {
		enableUnsavedChanges();

		setCurrentCategory({
			...currentCategory,
			iconUrl: file,
		});
	};

	const getParentCategories = (categoryIds: Key[]): Key[] => {
		enableUnsavedChanges();

		const parentCategories = getCategoryById(
			categories,
			categoryIds[0] as string
		);

		if (categoryIds.length === 0) {
			setCurrentParentCategoryId(clearCurrentParentCategoryId);
			return [""];
		}

		if (parentCategories) {
			setCurrentParentCategoryId({
				id: parentCategories.id ?? "",
				name: parentCategories.name,
			});

			return categoryIds;
		}

		setCurrentParentCategoryId({ ...currentParentCategoryId });

		return [currentParentCategoryId.id];
	};

	return {
		//Props
		isOpenCategoryModal,
		setIsOpenCategoryModal,
		formErrors,
		isEditable,
		isLoading,
		categoryOrder,
		categories,
		categoryId,
		enableCategoryForm,
		setEnableCategoryForm,
		setModalIsOpen,
		isReorderMode,
		setIsReorderMode,
		isDisabled,
		isLoadingList,
		isCancellationAction,
		setIsCancellationAction,
		clearCurrentCategory,
		currentCategory,
		setCurrentCategory,
		clearCurrentParentCategoryId,
		currentParentCategoryId,
		setCurrentParentCategoryId,
		setIsUpsertMode,
		//Functions
		getParentCategories,
		handleFormSubmit,
		handleFileUpload,
		handleInputChange,
		handleCheckedChange,
		getCategories,
		getCategoryById,
		upsertCategory,
		handleCategoryCancellation,
		handleCategoryDeletion,
		handleCategoryOrderingCancellation,
		notifyDataChange,
		clearCategoryForm,
		deleteCategory,
		//Hooks
		unsavedChanges,
		promptMessage,
		enableUnsavedChanges,
		disableUnsavedChanges,
	};
}
