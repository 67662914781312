const initialState = { text: null, req: null };

export default (state = initialState, action) => {
	switch (action.type) {
		case "AUTH_USER":
			return { ...state, token: action.payload.token, res: action.payload.res };
		case "SET_TEXT_REQ":
			return {
				...state,
				text: action.payload.text,
				req: action.payload.req,
				state: action.payload.state,
			};
		case "SET_DATA":
			return {
				...state,
				data: action.payload.data,
				fill: action.payload.fill,
				url: action.payload.url,
				content: action.payload.content,
				email: action.payload.email,
			};
		case "SET_FILTER_PRODUCT":
			return { ...state, text: action.payload.text, req: action.payload.req };
		default:
			return state;
	}
};
