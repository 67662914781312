import React, { Dispatch, SetStateAction } from "react";
import { i18n } from "../../translate/i18n";
import { Box, Grid } from "@mui/material";
import CustomInput from "../../MUICustomComponents/CustomInput";
import CustomDialog from "../../MUICustomComponents/CustomDialog";
import DeliveryReceiptDialogService, {
	CustomReceiptErrors,
} from "./DeliveryReceiptDialogService";
import { ReceiptInputs } from "./ReceiptInputs";
import { UploadImage } from "../UploadImage/UploadImage";
import CustomTypography from "../../MUICustomComponents/CustomTypography";
import { OrderModel } from "../../domain/models/commerce/OrderModel";
import { FormValidator } from "../../infrastructure/utils/FormUtils";

type Props = {
	orderModel: OrderModel;
	isDeliveryReceiptDialogOpen: boolean;
	setIsDeliveryReceiptDialogOpen: Dispatch<SetStateAction<boolean>>;
	onSubmitCallback: Function;
};

export default function DeliveryReceiptDialog({
	orderModel,
	isDeliveryReceiptDialogOpen,
	setIsDeliveryReceiptDialogOpen,
	onSubmitCallback,
}: Props) {
	const {
		isLoadingSubmit,
		receiptData,
		formErrors,
		handleFileUpload,
		handleInputChange,
		handleSubmit,
		handleCloseDialog,
	} = DeliveryReceiptDialogService(orderModel, onSubmitCallback);

	const inputField = (fieldName: ReceiptInputs) => {
		return (
			<CustomInput
				name={fieldName}
				title={i18n.t(`deliveryReceiptDialog.${fieldName}`)}
				placeholder={i18n.t(`deliveryReceiptDialog.${fieldName}`)}
				value={receiptData[fieldName] ?? ""}
				onChange={handleInputChange}
				error={FormValidator.hasInputError(formErrors, fieldName)}
				helperText={FormValidator.translateHelperTextList(
					formErrors,
					fieldName
				)}
			/>
		);
	};

	const addresseeForm = () => {
		return (
			<Box>
				<Grid container spacing={2} mb={2} mt={-1}>
					<Grid item xs={12}>
						{inputField(ReceiptInputs.RecipientName)}
					</Grid>
					<Grid item xs={12}>
						{inputField(ReceiptInputs.RecipientDocument)}
					</Grid>
					<Grid item xs={12}>
						{inputField(ReceiptInputs.Observation)}
					</Grid>
					<Grid item xs={12}>
						<CustomTypography variant="small" isBold>
							{i18n.t("deliveryReceiptDialog.Receipt").toString()}
						</CustomTypography>
					</Grid>
					<Grid item xs={12}>
						<UploadImage
							onFileUpload={handleFileUpload}
							image={receiptData.fileUrl ?? ""}
							helpText={i18n.t("deliveryReceiptDialog.UploadFileHelpText")}
							allowPdf
						/>
					</Grid>
				</Grid>
				<ul style={{ margin: 0, marginTop: "20px", paddingLeft: "20px" }}>
					{FormValidator.hasInputError(
						formErrors,
						CustomReceiptErrors.InfoCannotBeEmpty
					) && (
						<CustomTypography color="error" variant="small">
							<li>É necessário preencher informações do comprovante</li>
						</CustomTypography>
					)}
					{FormValidator.hasInputError(
						formErrors,
						CustomReceiptErrors.ImageCannotBeEmpty
					) && (
						<CustomTypography color="error" variant="small">
							<li>O comprovante não pode ser salvo sem um anexo</li>
						</CustomTypography>
					)}
				</ul>
			</Box>
		);
	};

	return (
		<CustomDialog
			isOpen={isDeliveryReceiptDialogOpen}
			setIsOpen={setIsDeliveryReceiptDialogOpen}
			closeCallback={handleCloseDialog}
			dialogInfo={{
				title: i18n.t("deliveryReceiptDialog.Title"),
			}}
			actionButton={{
				text: i18n.t(`buttons.General.BtnSave`),
				variant: "contained",
				color: "success",
				onClick: handleSubmit,
				isDisabled: isLoadingSubmit || FormValidator.hasError(formErrors),
				isLoading: isLoadingSubmit,
			}}
			dialogStyles={{ titleVariant: "title" }}
		>
			{addresseeForm()}
		</CustomDialog>
	);
}
