import React from "react";
import CustomTypography from "../../../../../MUICustomComponents/CustomTypography";
import CustomInput from "../../../../../MUICustomComponents/CustomInput";
import CustomSelect from "../../../../../MUICustomComponents/CustomSelect";
import ChromePicker from "../../../../../components/CustomChromePicker/ChromePicker";
import ModalService from "./ModalService";
import { FormValidator } from "../../../../../infrastructure/utils/FormUtils";
import { Box, Button, Grid, Modal, Stack } from "@mui/material";
import { i18n } from "../../../../../translate/i18n";
import { AttributeInputs } from "../../enums/AttributeInputs";
import { AttributeTypes } from "../../enums/AttributeTypes";
import { AttributesDto } from "../../../../../domain/dtos/products/attributes/AttributesDto";

import * as Styled from "../Attributes/AttributesStyles";

type ModalProps = {
	isOpenCreateModal: boolean;
	setIsOpenCreateModal: (value: boolean) => void;
	attributes: AttributesDto[];
	setAttributes: (value: AttributesDto[]) => void;
	updateAttributes?: (attributes: AttributesDto[]) => void;
};

export default function CreateAttribute({
	isOpenCreateModal,
	setIsOpenCreateModal,
	attributes,
	setAttributes,
	updateAttributes,
}: ModalProps) {
	const {
		// Props
		atttributeTypeSelect,
		newAttributte,
		formErrors,
		// Functions
		updateChromePicker,
		handleInputChange,
		handleSelectChange,
		createNewAttribute,
		errorColor,
	} = ModalService(
		setIsOpenCreateModal,
		attributes,
		setAttributes,
		updateAttributes
	);

	return (
		<Modal
			open={isOpenCreateModal}
			onClose={() => setIsOpenCreateModal(false)}
			aria-labelledby="create-attribute-title"
			aria-describedby="create-attribute-description"
		>
			<Styled.ModalBody>
				<CustomTypography variant="secondaryTitle">
					{i18n.t(`attributes.NewAttribute`).toString()}
				</CustomTypography>

				<Box mt={2}>
					<Grid container spacing={1}>
						<Grid item xs={3}>
							<CustomSelect
								id={AttributeInputs.Type}
								label={i18n.t(`attributes.Type`).toString()}
								value={newAttributte.type}
								options={atttributeTypeSelect}
								isDisabled={false}
								onChange={handleSelectChange}
							/>
						</Grid>
						<Grid item xs={9}>
							<CustomInput
								name={AttributeInputs.Title}
								title={i18n.t(`attributes.Name`).toString()}
								placeholder={i18n.t(`attributes.Name`).toString()}
								value={newAttributte.title}
								onChange={handleInputChange}
								error={FormValidator.hasInputError(
									formErrors,
									AttributeInputs.Title
								)}
							/>
						</Grid>
					</Grid>

					<Grid container spacing={1} mt={0.5}>
						<Grid
							item
							xs={newAttributte.type === AttributeTypes.Color ? 10 : 12}
						>
							<CustomInput
								name={
									newAttributte.type === AttributeTypes.Color
										? AttributeInputs.Label
										: AttributeInputs.Value
								}
								title={i18n.t(`attributes.Value`).toString()}
								placeholder={i18n.t(`attributes.Value`).toString()}
								value={
									newAttributte.type === AttributeTypes.Color
										? newAttributte.label
										: newAttributte.value
								}
								onChange={handleInputChange}
								error={FormValidator.hasInputError(
									formErrors,
									newAttributte.type === AttributeTypes.Color
										? AttributeInputs.Label
										: AttributeInputs.Value
								)}
							/>
						</Grid>
						{newAttributte.type === AttributeTypes.Color && (
							<Grid item xs={2}>
								<Styled.ColorBox error={errorColor}>
									<Styled.TitleBox>
										{i18n.t(`attributes.Color`).toString()}
									</Styled.TitleBox>

									<ChromePicker
										updateChromePicker={updateChromePicker}
										currentColor={newAttributte.value}
									/>
								</Styled.ColorBox>
							</Grid>
						)}
					</Grid>
				</Box>

				<Stack direction="row" justifyContent="center" mt={3}>
					<Box mr={"10px"}>
						<Button
							variant="contained"
							color="light"
							onClick={() => setIsOpenCreateModal(false)}
						>
							{i18n.t(`buttons.General.BtnCancel`).toString()}
						</Button>
					</Box>
					<Button
						variant="contained"
						color="success"
						onClick={createNewAttribute}
					>
						{i18n.t(`buttons.General.BtnConfirm`).toString()}
					</Button>
				</Stack>
			</Styled.ModalBody>
		</Modal>
	);
}
