import React, { useState } from "react";
import ConfigGlobals from "./ConfigGlobals";
import List from "../../../../components/List/List";
import SearchInputClose from "../../../../components/SearchInputClose";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function RetailersList(props) {
	const [action, setAction] = useState("selected");
	const [text, setText] = useState("");
	const [detail, setDetail] = useState("");
	const [choose, setChoose] = useState("");
	const [type, setType] = useState(false);
	const [query, setQuery] = useState("");
	const [filteredRetailers, setFilteredRetailers] = useState(props.retailers);
	const [toggleFilter, setToggleFilter] = useState(false);

	const handleQueryChange = (queryValue) => {
		setQuery(queryValue);
		const newList = props.retailers.filter((retailer) => {
			return retailer.clientId.toLowerCase().includes(queryValue.toLowerCase());
		});
		setFilteredRetailers([...newList]);
		setToggleFilter(!toggleFilter);
	};

	const columsList = [
		{
			name: "Client Id",
			selector: "name",
			sortable: false,
			cell: (row) => <b>{row.clientId}</b>,
		},
		{
			name: "",
			sortable: true,
			width: "92px",
			style: {
				paddingLeft: "14px",
				paddingRight: "14px",
			},
			cell: (row) => (
				<div className="btn-group" role="group">
					<Link
						to={`/retailerconfigurations/edit/${row.id}`}
						className="btn btn-info p-auto"
						style={{ width: "64px", height: "40px" }}
					>
						Editar
					</Link>
				</div>
			),
		},
	];

	const changeAction = (e) => {
		const value = e.target.value;
		console.log(value);
		if (
			value === "hasDynamicTutorial" ||
			value === "useNewCheckout" ||
			value === "useOnlyLocalAssets" ||
			value === "useLegacyCatalogAPI" ||
			value === "useLegacyCommerceAPI" ||
			value === "useLegacyMarketingAPI" ||
			value === "useLegacyRetailerAPI" ||
			value === "supportsDebitCard" ||
			value === "useNewDealerRanking" ||
			value === "useCatalogProcessor" ||
			value === "useGeoCatalog" ||
			value === "useOrderEditor" ||
			value === "useFilter" ||
			value === "useCatalogMatcher" ||
			value === "usePagarMeDashboard" ||
			value === "isVtexCheckout" ||
			value === "useStateMachine"
		) {
			setType(true);
		} else {
			setType(false);
		}
		setAction(e.target.value);
	};
	const changeDetail = (e) => {
		var type = parseInt(e.target.value);
		setDetail(type);
	};
	const changeText = (e) => {
		setText(e.target.value);
	};
	const handleChange = (e) => {
		console.log(e.target.checked);
		setChoose(e.target.checked);
	};
	return (
		<div className="container dash-content">
			<div className="card">
				<div className="card-header p-4">
					<section className="d-flex justify-content-between align-items-center mb-3">
						<h4 className="card-title">Configurações do varejistas</h4>
						<div className="card-actions">
							<Link
								className="btn btn-grey-80"
								to={"/retailerconfigurations/new"}
							>
								Nova configuração
							</Link>
							&nbsp;&nbsp;
							<button
								className="btn btn-info light btn-icon"
								data-toggle="modal"
								data-target="#exampleModal"
							>
								Nova configuração global
							</button>
						</div>
					</section>
					<SearchInputClose
						value={query}
						onChange={handleQueryChange}
						placeholder="Busque por clientId"
					/>
				</div>
				<div className="card-content mt-3">
					<div className="card-body no-padding">
						{toggleFilter ? (
							<List
								data={filteredRetailers}
								colums={columsList}
								paginationServer={false}
								pagination={true}
							/>
						) : (
							<section>
								<List
									data={filteredRetailers}
									colums={columsList}
									paginationServer={false}
									pagination={true}
								/>
							</section>
						)}
					</div>
				</div>
			</div>
			{/* Modal para as configurações internas */}
			<div
				className="modal fade"
				id="exampleModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h3 className="modal-title">Nova configuração global</h3>
							<button
								type="button"
								className="close text-danger"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body pt-0">
							<div className="form-group mt-3">
								<div className="input-group">
									<div className="input-group-prepend">
										<label htmlFor="inputGroupSelect01">
											<select
												className="custom-select btn-light"
												id="inputGroupSelect01"
												placeholder="Selecione uma configuração"
												value={action}
												onChange={changeAction}
											>
												<option value="selected">
													Escolha uma configuração
												</option>
												<option value="hasDynamicTutorial">
													Utiliza tutorial dinâmico?
												</option>
												<option value="useNewCheckout">
													Utiliza o novo checkout?
												</option>
												<option value="useOnlyLocalAssets">
													Utilizar assets dinâmicos?
												</option>
												<option value="useLegacyCatalogAPI">
													API Catalog legada
												</option>
												<option value="useLegacyCommerceAPI">
													API Commerce legada
												</option>
												<option value="useLegacyMarketingAPI">
													API Marketing legada
												</option>
												<option value="useLegacyRetailerAPI">
													API Retailer legada
												</option>
												<option value="supportsDebitCard">
													Suporta venda com cartão de débito?
												</option>
												<option value="useNewDealerRanking">
													Utiliza novo Ranking de vendedor?
												</option>
												<option value="minimalAppVersion">
													Versão mínima suportada do APP
												</option>
												<option value="urlBaseLori">URL base da Lori</option>
												<option value="productPageType">
													Tela detalhe de produto
												</option>
												<option value="catalogProductCodeMarketplace">
													Código do produto marketplace
												</option>
												<option value="catalogSkuCodeMarketplace">
													Código do sku marketplace
												</option>
												<option value="useCatalogProcessor">
													Utiliza CatalogProcessor?
												</option>
												<option value="useGeoCatalog">
													Utiliza GeoCatalog?
												</option>
												<option value="useOrderEditor">Editar Pedido?</option>
												<option value="useFilter">Utiliza Filtros?</option>
												<option value="useCatalogMatcher">
													Utiliza Mapeamento de Catálogo?
												</option>
												<option value="usePagarMeDashboard">
													Utiliza Dashboard Pagar.me?
												</option>
												<option value="useStateMachine">
													Utiliza Máquina de Status?
												</option>

												<option value="isVtexCheckout">
													Utiliza Checkout Vtex?
												</option>
											</select>
										</label>
									</div>

									{(action === "minimalAppVersion" ||
										action === "urlBaseLori") && (
										<div className="form-group col-sm-3 flex-column pl-1">
											<input
												type="text"
												className="form-control"
												name="text"
												value={text}
												onChange={changeText}
											/>
										</div>
									)}
									{action === "catalogProductCodeMarketplace" && (
										<div className="input-group-prepend pl-1">
											<label htmlFor="catalogProductCodeMarketplace">
												<select
													className="custom-select btn-light"
													id="catalogProductCodeMarketplace"
													name="detail"
													value={detail}
													onChange={changeDetail}
												>
													<option defaultValue="selected">Selecione</option>
													<option value={0}>Código</option>
													<option value={1}>Código Auxiliar</option>
													<option value={2}>Código de Referência</option>
												</select>
											</label>
										</div>
									)}
									{action === "productPageType" && (
										<div className="input-group-prepend pl-1">
											<label htmlFor="inputGroupSelect01">
												<select
													className="custom-select btn-light"
													id="inputGroupSelect01"
													name="detail"
													value={detail}
													onChange={changeDetail}
												>
													<option defaultValue="selected">Selecione</option>
													<option value={0}>Clássico</option>
													<option value={1}>Moda</option>
													<option value={2}>Luxo</option>
													<option value={3}>Ótica</option>
												</select>
											</label>
										</div>
									)}
									{action === "catalogSkuCodeMarketplace" && (
										<div className="input-group-prepend pl-1">
											<label htmlFor="catalogSkuCodeMarketplace">
												<select
													className="custom-select btn-light"
													id="catalogSkuCodeMarketplace"
													name="detail"
													value={detail}
													onChange={changeDetail}
												>
													<option defaultValue="selected">Selecione</option>
													<option value={0}>Código</option>
													<option value={1}>Código Auxiliar</option>
													<option value={3}>EAN</option>
												</select>
											</label>
										</div>
									)}
									{type && (
										<div className="form-group col-sm-1 d-flex justify-content-between mt-2">
											<div className="mr-2">
												<label>
													<strong>Não</strong>
												</label>
											</div>
											<div className="mr-2">
												<label className="switch ">
													<input
														type="checkbox"
														name="choose"
														value={choose}
														checked={choose}
														onChange={handleChange}
													/>
													<span className="slider round"></span>
												</label>
											</div>
											<div>
												<label>
													<strong>Sim</strong>
												</label>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
						<div className="modal-footer text-center">
							{action === "selected" ? (
								<button type="button" disabled className="btn btn-success">
									Confirmar
								</button>
							) : (
								<button
									type="button"
									className="btn btn-success"
									data-toggle="modal"
									data-target="#openConfig"
									data-dismiss="modal"
								>
									Confirmar
								</button>
							)}
							<button
								type="button"
								className="btn btn-light "
								data-dismiss="modal"
							>
								Cancelar
							</button>
						</div>
					</div>
				</div>
			</div>
			<ConfigGlobals
				id={"openConfig"}
				action={action}
				text={text}
				detail={detail}
				clientId={props.clientId}
				choose={choose}
			/>
		</div>
	);
}
