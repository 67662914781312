import React from 'react';

// import { Container } from './styles';

function BodyCellphone(props) {
  return (
    <div className="preview app" style={{ height: props.heightExternalMargin, width: props.widthExternalMargin }}>
      <div className="body" style={{ height: props.heightInternalMargin, width: props.widthInternalMargin }}	>
        
          {props.children}
      </div>
    </div>
  );
}

export default BodyCellphone;