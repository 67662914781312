import React, { ReactNode } from "react";
import { AlertContainer } from "./FixedAlertComponentStyles";
import { PaletteDiscriminatedUnion } from "../../theme/foundations/palette";
import CustomIcon from "../../MUICustomComponents/CustomIcon";
import { ErrorOutline } from "@mui/icons-material";

type Props = {
	children: ReactNode;
	alertType: "warning" | "danger";
	marginBottom?: string;
};

export default function FixedAlertComponent({
	children,
	alertType,
	marginBottom,
}: Props) {
	const backgroundColors: {
		[key in Props["alertType"]]: PaletteDiscriminatedUnion;
	} = {
		warning: "orange10",
		danger: "red10",
	};

	const icon: {
		[key in Props["alertType"]]: JSX.Element;
	} = {
		warning: <ErrorOutline />,
		danger: <ErrorOutline />,
	};

	return (
		<AlertContainer
			color={alertType}
			bgColor={backgroundColors[alertType]}
			marginBottom={marginBottom || "0px"}
		>
			<CustomIcon icon={icon[alertType]} variant="default" />
			{children}
		</AlertContainer>
	);
}
