import React, { FC, ReactNode } from "react";
import { Container } from "@mui/material";
import styled from "styled-components";

const StyledContainer = styled(Container)`
	max-width: 1140px !important;
	padding-left: 15px !important;
	padding-right: 15px !important;
	padding-bottom: 20px !important;
	margin-top: 8px !important;
`;

interface CustomContainerProps {
	children: ReactNode;
}

const CustomContainer: FC<CustomContainerProps> = ({ children }) => {
	return <StyledContainer>{children}</StyledContainer>;
};

export default CustomContainer;
