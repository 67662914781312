import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { API } from "../../../../../API";
import { fromEvent } from "rxjs";
import { map, debounceTime } from "rxjs/operators";

export default function Cities({ newCondition }) {
	const [listCities, setListCities] = useState([]);
	const [list, setList] = useState([]);
	const [text, setText] = useState("");
	const [loading, setLoading] = useState(false);
	const cityInput = useRef();

	useEffect(() => {
		const searchCity = fromEvent(cityInput.current, "input")
			.pipe(
				debounceTime(500),
				map((event) => event.target.value)
			)
			.subscribe((text) => setText(text));
		//return searchCity.unsubscribe;
	}, []);

	useEffect(() => {
		getAllcities();
	}, [text, list]);

	const getAllcities = async () => {
		if (text.length === 0) {
			setListCities([]);
			return;
		}
		setLoading(true);
		try {
			const response = await API.get(
				"api/autocomplete/states/allcities/" + text
			);
			setListCities([]);
			let cities = [];
			let newCities = [];
			response.data.forEach((city) => {
				let arrCities = {
					city: `${city.name} - ${city.state.code}`,
				};
				cities.push(arrCities);
			});
			cities.forEach((data) => {
				let duplicated =
					newCities.findIndex((newCity) => {
						return data.city == newCity.city;
					}) > -1;

				if (!duplicated) {
					newCities.push(data);
				}
			});
			setListCities(newCities);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const selectCities = (city) => {
		const component = {
			name: city.city,
		};
		list <= 0 && setList([...list, component]);
		const listData = [...list].findIndex((val) => val.name === city.city);
		if (listData < 0) {
			setList([...list, component]);
		} else {
			toast.error("Cidade já foi adicionada!");
		}
		setText("");
		cityInput.current.value = "";
	};

	const removeList = (index) => {
		setList((currentList) => currentList.filter((city, i) => i !== index));
	};

	const saveCities = () => {
		const city = list.length > 1 ? " Cidades" : " Cidade";
		let item = {
			cities: list,
			value: list,
		};
		let itemView = {
			name: "Cidades",
			value: list.length + city,
			itemId: "cities",
			modal: "#conditionModalCities",
		};
		newCondition(item, itemView);
	};

	return (
		<div
			class="modal fade"
			id="conditionModalCities"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="conditionModalCities"
			aria-hidden="true"
		>
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title">Cidades</h3>
						<button
							type="button"
							className="close text-danger"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<div
							className="form-group autocomplete icon-right"
							id="autocomplete"
						>
							<input
								type="text"
								className="form-control"
								id="search"
								name="pesquisa"
								placeholder="Escolha a cidade"
								autoComplete="off"
								ref={cityInput}
							/>
							{loading ? (
								<i className="fas fa-spinner fa-spin"></i>
							) : (
								<i className="material-icons">search</i>
							)}
							{!listCities.length ? null : (
								<div className="result">
									<ul>
										{listCities.map((city, index) => (
											<li
												key={index}
												id={index}
												onClick={() => selectCities(city)}
											>
												<div className="d-flex justify-content-start">
													<p
														style={{
															width: "390px",
															marginLeft: "20px",
															marginTop: "10px",
														}}
													>
														{city.city}
													</p>
												</div>
											</li>
										))}
									</ul>
								</div>
							)}
						</div>
						<div className="list">
							{list.length > 0 && (
								<div className="">
									<table className="table">
										<thead>
											<tr>
												<th scope="col">CIDADE</th>
												<th scope="col" className="text-right">
													AÇÕES
												</th>
											</tr>
										</thead>
										<tbody>
											{list.map((city, index) => (
												<tr key={index} id={city.id}>
													<td>{city.name}</td>
													<td className="text-right">
														<button
															type="button"
															className="btn btn-link btn-sm  btn-icon text-right"
															onClick={() => removeList(index)}
														>
															<i className="material-icons text-danger">
																delete_outline
															</i>
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							)}
						</div>
					</div>
					<div
						className="modal-footer text-center"
						style={{ justifyContent: "center" }}
					>
						<button
							type="button"
							className="btn btn-light col-5"
							data-dismiss="modal"
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-success col-5"
							data-dismiss="modal"
							onClick={saveCities}
						>
							Pronto
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
