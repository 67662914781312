import React from "react";
import Invoice from "./components/Invoice.jsx";
import { useOrders } from "./providers/OrdersContext";
import { i18n } from "../../translate/i18n";
import { formatDate, formatDocument } from "../../infrastructure/utils/FormatMask.jsx";

export default function CreateInvoice() {
	const { order, loading } = useOrders();
	const config = JSON.parse(localStorage.getItem("configurations"));

	const handleTypePayments = (payments) => {
		const paymentsMethods = [];
		const array = payments?.map((type) => {
			let paymentMethodList = [
				"CreditCard",
				"DebitCard",
				"PickupStore",
				"Bankslip",
				"Voucher",
				"Cashback",
				"Pix",
				"InstallmentPix",
				"PaymentLink",
			];

			paymentMethodList.forEach((paymentMethod) => {
				if (paymentMethod === type?.PaymentMethod?.Type)
					paymentsMethods.push(i18n.t(`orderDetails.${paymentMethod}`));
			});
		});
		return paymentsMethods;
	};
	const modality = order.IsDelivery
			? "Receba em casa"
			: "Retire na loja";
			
	const image =
		localStorage.getItem("primaryLogo") === "undefined"
			? ""
			: localStorage.getItem("primaryLogo");

	const invoice = {
		items: order?.Items,
		image: image,
		friendlyCode: order?.FriendlyCode,
		createDate: formatDate(order?.CreateDate),
		modality: modality,
		isDelivery: order?.IsDelivery,
		productDisplayCodeName: config[0].productDisplayCodeName,

		feeAmount: order?.Totals?.FeeAmount,
		discountAmount: order?.Totals?.DiscountAmount,
		shippingAmount: order?.Totals?.ShippingAmount,
		totalAmount: order?.Totals?.TotalAmount,
		itemsAmount: order?.Totals?.ItemsAmount,

		buyerFullName: order?.Buyer?.FullName,
		buyerDocument: formatDocument(
			order?.Buyer?.DocumentType,
			order?.Buyer?.Document
		),
		buyerEmail: order?.Buyer?.Email,
		buyerFullAddress: order?.ShippingData?.DeliveryAddress?.FullAddress,
		sellerFullName: order?.Seller?.CompanyName,
		sellerDocument: formatDocument(
			order?.Seller?.DocumentType,
			order?.Seller?.Document
		),
		sellerEmail: order?.Seller?.Email,
		sellerFullAddress: order?.Seller?.Address?.FullAddress,
		payments: order?.Payments,
		paymentStatus: order?.PaymentStatus,
		typePayments: handleTypePayments(order?.Payments),
	};

	return (
		<div className="detail-invoice">
			<button
				onClick={(e) => Invoice(invoice)}
				disabled={loading}
				className={loading && "loading-invoice"}
			>
				<i className="material-icons-outlined">local_printshop</i>
			</button>
		</div>
	);
}
