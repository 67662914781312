import React, { useState, useEffect } from "react";
import Loading from "../../components/Loading";
import OrdersContext from "./providers/OrdersContext";
import OrdersList from "./OrdersList";
import { useConfig } from "../../providers/ConfigProvider";
import LoadDashboard from "../Dashboard/components/LoadDashboard";

export default function Index(props) {
	const { mainLoading } = useConfig();
	const [loading] = useState(false);

	useEffect(() => {
		if (!props.match.params.page) {
			window.location.href = "/orders/1";
		}

		//será necessário colocar em um objeto
		localStorage.removeItem("transRowsPerPage");
		localStorage.removeItem("transType");
		localStorage.removeItem("transStatus");
		localStorage.removeItem("transPaymentMethod");
		localStorage.removeItem("transStartDate");
		localStorage.removeItem("transEndDate");
		localStorage.removeItem("transSearchName");
	}, []);

	const renderScreen = () => {
		return (
			<OrdersContext>
				<div className="container dash-content">
					<OrdersList />
				</div>
			</OrdersContext>
		);
	};
	return (
		<>
			{loading ? (
				<div className="container dash-content">
					<div className="card">
						<Loading />
					</div>
				</div>
			) : mainLoading ? (
				<LoadDashboard />
			) : (
				renderScreen()
			)}
		</>
	);
}
