import { i18n } from "../../../../translate/i18n";

export class PayablesTransactionTypes {
	static credit = {
		text: i18n.t(`financial.Export.Type.Credit`),
		className: "financial-label outline-success",
	};
	static chargeback = {
		text: i18n.t(`financial.Export.Type.Chargeback`),
		className: "financial-label outline-grey-60",
	};
	static chargeback_refund = {
		text: i18n.t(`financial.Export.Type.ChargebackRefund`),
		className: "financial-label outline-grey-60",
	};
	static refund = {
		text: i18n.t(`financial.Export.Type.Refund`),
		className: "financial-label outline-grey-60",
	};
	static refund_reversal = {
		text: i18n.t(`financial.Export.Type.RefundReversal`),
		className: "financial-label outline-grey-60",
	};
}
