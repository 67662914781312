import React from "react";
import { Typography } from "@mui/material";
import { i18n } from "../../../../translate/i18n";
import attention from "../../../../assets/images/attention.svg";
import { Container, Link } from "./StyleEmpty";

const AttentionSvg = () => {
	return <img src={attention} alt="Attention" />;
};

type TId = {
	id: string;
};

export default function EmptyRecipientId({ id }: TId) {
	return (
		<Container>
			<AttentionSvg />
			<Typography mt={3} fontSize={18} fontWeight={500}>
				{i18n.t("financial.Empty.WithoutRecipientId").toString()}
			</Typography>
			<Typography fontSize={14} textAlign={"center"} width={358}>
				{i18n.t("financial.Empty.RecipientIdMessage").toString()}
			</Typography>
			<Link href={`/stores/1/edit/${id}`}>
				{i18n.t("financial.Empty.RegisterRecipientId").toString()}
			</Link>
		</Container>
	);
}
