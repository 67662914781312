import React, { Component } from "react";
import { API, APIV2 } from "../../../../API";
import DropImage from "../../../../components/DropImage";
import DatePickerNeo from "../../../../components/DatePickerNeo";
import { toast } from "react-toastify";
import Select from "react-select";
import Products from "../../../../components/Products";
import Modal from "../../../../components/Modal";
import PreviewAPP from "../../../../components/preview/PreviewApp";
import {
	detectAnyAdblocker,
	detectOperaAdblocker,
	detectBraveShields,
	detectDomAdblocker,
} from "just-detect-adblock";
import Button from "../../../../components/Button";
import ContentLoader from "react-content-loader";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import CategoriesCheckbox from "../../../../components/modals/CategoriesCheckbox/CategoriesCheckbox";
import { StringValidator } from "../../../../infrastructure/utils/TypeValidators/StringValidator";

export class New extends Component {
	constructor(props) {
		super(props);
		this.createBanner = this.createBanner.bind(this);
		this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
		this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.recoveryStateImage = this.recoveryStateImage.bind(this);
		this.getProducts = this.getProducts.bind(this);
		this.getCategories = this.getCategories.bind(this);
		this.changeAction = this.changeAction.bind(this);
		this.createBannerDefault = this.createBannerDefault.bind(this);
		//this.handleChangeP = this.handleChangeP.bind(this)
		this.handleTags = this.handleTags.bind(this);
		this.handleChangeWebview = this.handleChangeWebview.bind(this);
		this.handleChangeUrl = this.handleChangeUrl.bind(this);
		this.getBanner = this.getBanner.bind(this);
		this.adblockDetect = this.adblockDetect.bind(this);
		this.skeleton = this.skeleton.bind(this);
		this.closeModalCategory = this.closeModalCategory.bind(this);

		this.state = {
			isOpenCategoryModal: false,
			id: null,
			title: null,
			imageUrl: "",
			startIn: new Date(),
			endIn: new Date(),
			startDate: new Date(),
			endDate: new Date(),
			order: null,
			type: null,
			action: "none",
			referenceId: "",
			edit: false,
			banners: [],
			categories: null,
			collectionList: [],
			promotions: [],
			tags: [],
			isDefault: null,
			carousel: false,
			selectTag: null,
			open_tag: "",
			open_video: "",
			isAdblock: false,
			closeAlert: false,
			disable: false,
			loading: true,
			actions: [
				{ value: "none", label: "Não fazer nada" },
				{ value: "open_product", label: "Ir para o produto" },
				{ value: "open_category", label: "Ir para a categoria" },
				{ value: "open_url", label: "Abrir URL fora do APP" },
				{ value: "open_tag", label: "Ir para a coleção" },
				{ value: "open_webview", label: "Abrir URL dentro do APP" },
				{ value: "open_video", label: "Abrir um vídeo" },
				{ value: "zoom", label: "Zoom" },
			],
			customStyles: {
				indicatorSeparator: (provided) => ({
					...provided,
					display: "none",
				}),
				dropdownIndicator: (provided) => ({
					...provided,
					color: "#747E8B",
					paddingLeft: 5,
					svg: {
						width: 24,
					},
				}),
			},
		};
	}
	componentDidMount() {
		this.getBanner();
		this.adblockDetect();
	}

	adblockDetect() {
		detectAnyAdblocker().then((detected) => {
			if (detected) {
				// an adblocker is detected
				this.setState({ isAdblock: true });
			}
		});

		detectOperaAdblocker().then((detected) => {
			if (detected) {
				// an adblocker is detected
				this.setState({ isAdblock: true });
			}
		});

		detectBraveShields().then((detected) => {
			if (detected) {
				// an adblocker is detected
				this.setState({ isAdblock: true });
			}
		});

		detectDomAdblocker().then((detected) => {
			if (detected) {
				// an adblocker is detected
				this.setState({ isAdblock: true });
			}
		});
	}

	getBanner() {
		this.setState({ loading: true });
		API.get("api/tags")
			.then(async (response) => {
				const collections = [];
				response.data.forEach((collection) => {
					collections.push({
						label: collection.name,
						value: collection.id,
					});
				});

				this.setState({
					collectionList: collections.length > 0 ? collections : [],
				});
				this.forceUpdate();
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});

		if (
			this.props.url.match.params.id === null ||
			this.props.url.match.params.id === undefined
		) {
			this.setState({ loading: false });
		}

		var _this = this;
		if (_this.props.url.match.params.id) {
			_this.setState({ edit: true });
			APIV2.get(
				"management/marketing/banners/" + _this.props.url.match.params.id
			)
				.then(async (response) => {
					await _this.setState({
						id: response.data.id,
						order: response.data.order,
						title: response.data.title,
						imageUrl: response.data.imageUrl,
						startIn: new Date(response.data.startIn),
						endIn: new Date(response.data.endIn),
						type: response.data.type,
						action: response.data.action,
						referenceId: response.data.referenceId,
						isDefault: response.data.isDefault,
						open_url: response.data.referenceId,
						open_webview: response.data.referenceId,
						open_product: response.data.referenceId,
						open_category: response.data.referenceId,
						open_tag: response.data.referenceId,
						open_video: response.data.referenceId,
					});
					this.setState({ loading: false });
				})
				.catch((error) => {
					console.log(error.response);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				});
		}
	}

	handleChangeVideo = (e) => {
		var link = e.target.value;
		this.setState({ referenceId: e.target.value });
		if (link.match(/youtube/) || link.match(/vimeo/) || link === "") {
			this.setState({ closeAlert: false });
		} else {
			this.setState({ closeAlert: true });
		}
	};

	handleChangeUrl = (e) => {
		let expression =
			/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
		let regex = new RegExp(expression);
		let t = e.target.value;
		this.setState({ referenceId: e.target.value });

		if (t.match(regex) || t === "") {
			this.setState({ closeAlert: false });
		} else {
			this.setState({ closeAlert: true });
		}
	};

	changeAction(e) {
		this.setState({
			action: e.value,
			referenceId: "",
			closeAlert: false,
		});
		this.forceUpdate();
	}
	async handleChangeUrl(e) {
		await this.setState({
			open_url: e.target.value,
		});
	}
	async handleChangeWebview(e) {
		await this.setState({
			open_webview: e.target.value,
		});
	}
	handleTags(e) {
		this.setState({ open_tag: e.target.value });
	}
	async handleChange(e) {
		await this.setState({
			[e.target.name]: e.target.value,
		});
		this.forceUpdate();
	}
	handleChangeStartDate = async (date) => {
		await this.setState({ startIn: date });
		this.forceUpdate();
	};
	handleChangeEndDate = async (date) => {
		await this.setState({ endIn: date });
		this.forceUpdate();
	};
	async recoveryStateImage(e) {
		this.setState({ imageUrl: e });
		this.forceUpdate();
	}
	async getProducts(e) {
		var body = e;
		var p = body.map((prod) => {
			return prod.id;
		});
		const _this = this;
		if (p.length > 1) {
			toast.error("Apenas um produto pode ser adicionado 😃");
		} else {
			const product = p.toString();
			_this.setState({ referenceId: product });
		}
	}

	getCategories(categoryId) {
		const category = categoryId.toString();

		if (categoryId.length === 0) return this.setState({ referenceId: null });

		if (StringValidator.isNullOrWhitespace(category))
			return this.setState({ referenceId: this.state.referenceId });

		this.setState({ referenceId: category });
	}

	createBannerDefault(e) {
		if (this.state.edit) {
			var body = {
				id: this.state.id,
				title: this.state.title,
				action: this.state.action,
				referenceId:
					this.state.action === "open_url"
						? this.state.referenceId
						: this.state.action === "open_webview"
						? this.state.referenceId
						: this.state.action === "open_product"
						? this.state.referenceId
						: this.state.action === "open_category"
						? this.state.referenceId
						: this.state.action === "open_tag"
						? this.state.referenceId
						: this.state.action === "open_video"
						? this.state.referenceId
						: this.state.action === "zoom"
						? null
						: this.state.action === "none"
						? null
						: null,
				imageUrl: this.state.imageUrl,
				isDefault: true,
				type: 0,
			};
			APIV2.put("management/marketing/banners/" + this.state.id, body)
				.then(async (response) => {
					toast.success("Banner padrão editado com sucesso! 😃");
					this.forceUpdate();
				})
				.catch((error) => {
					console.log(error);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				});
		} else {
			var body = {
				title: this.state.title,
				action: this.state.action,
				referenceId:
					this.state.action === "open_url"
						? this.state.referenceId
						: this.state.action === "open_webview"
						? this.state.referenceId
						: this.state.action === "open_product"
						? this.state.referenceId
						: this.state.action === "open_category"
						? this.state.referenceId
						: this.state.action === "open_tag"
						? this.state.referenceId
						: this.state.action === "open_video"
						? this.state.referenceId
						: this.state.action === "zoom"
						? null
						: this.state.action === "none"
						? null
						: null,
				imageUrl: this.state.imageUrl,
				isDefault: true,
				type: 0,
			};
			APIV2.post("management/marketing/banners", body)
				.then(async (response) => {
					toast.success("Banner padrão criado com sucesso! 😃");
					this.props.url.history.push(
						`/channel-management/app/${this.props.screen}`
					);
					this.forceUpdate();
				})
				.catch((error) => {
					console.log(error);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				});
		}
	}

	createBanner(e) {
		this.setState({ loading: true, disable: true });

		const start = this.state.startIn.getTime() - 10800000;
		const startIn = new Date(start);

		const end = this.state.endIn.getTime() - 10800000;
		const endIn = new Date(end);

		if (this.state.edit) {
			var body = {
				id: this.state.id,
				order: this.state.order,
				action: this.state.action,
				title: this.state.title,
				imageUrl: this.state.imageUrl,
				startIn: startIn,
				endIn: endIn,
				referenceId:
					this.state.action === "open_url"
						? this.state.referenceId
						: this.state.action === "open_webview"
						? this.state.referenceId
						: this.state.action === "open_product"
						? this.state.referenceId
						: this.state.action === "open_category"
						? this.state.referenceId
						: this.state.action === "open_tag"
						? this.state.referenceId
						: this.state.action === "open_video"
						? this.state.referenceId
						: this.state.action === "zoom"
						? null
						: this.state.action === "none"
						? null
						: null,
				type: this.props.url.match.params.screen === "new-carousel" ? 0 : 1,
			};
			APIV2.put("management/marketing/banners/" + this.state.id, body)
				.then(async (response) => {
					toast.success("Banner editado com sucesso! 😃");
					this.getBanner();
					this.setState({ disable: false });
					this.forceUpdate();
				})
				.catch((error) => {
					console.log(error);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
					this.setState({ disable: false });
				});
		} else {
			var body = {
				action: this.state.action,
				title: this.state.title,
				imageUrl: this.state.imageUrl,
				startIn: startIn,
				endIn: endIn,
				referenceId:
					this.state.action === "open_url"
						? this.state.referenceId
						: this.state.action === "open_webview"
						? this.state.referenceId
						: this.state.action === "open_product"
						? this.state.referenceId
						: this.state.action === "open_category"
						? this.state.referenceId
						: this.state.action === "open_tag"
						? this.state.referenceId
						: this.state.action === "open_video"
						? this.state.referenceId
						: this.state.action === "zoom"
						? null
						: this.state.action === "none"
						? null
						: null,
				type: this.props.url.match.params.screen === "new-carousel" ? 0 : 1,
			};
			APIV2.post("management/marketing/banners", body)
				.then(async (response) => {
					toast.success("Banner criado com sucesso! 😃");
					this.getBanner();
					this.setState({ disable: false });
					this.props.url.history.push(
						`/channel-management/app/${this.props.screen}`
					);
					this.forceUpdate();
				})
				.catch((error) => {
					console.log(error);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
					this.setState({ disable: false });
				});
		}
	}

	skeleton() {
		return (
			<>
				<ContentLoader
					speed={2}
					width={1100}
					height={50}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					style={{ width: "100%" }}
				>
					<rect x="10" y="15" rx="3" ry="3" width="320" height="100" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={50}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					style={{ width: "100%" }}
				>
					<rect x="10" y="15" rx="3" ry="3" width="320" height="100" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={50}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					style={{ width: "100%" }}
				>
					<rect x="10" y="15" rx="3" ry="3" width="320" height="100" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={50}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					style={{ width: "100%" }}
				>
					<rect x="10" y="15" rx="3" ry="3" width="320" height="100" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={50}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					style={{ width: "100%" }}
				>
					<rect x="10" y="15" rx="3" ry="3" width="320" height="100" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={320}
					height={310}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					style={{ width: "100%" }}
				>
					<rect x="10" y="15" rx="3" ry="3" width="320" height="310" />
				</ContentLoader>
			</>
		);
	}

	closeModalCategory(value) {
		this.setState({ isOpenCategoryModal: value });
	}

	render() {
		return (
			<div className="card">
				<div className="card-header" id="headingOne">
					<h5 className="mb-0">
						{this.props.default == true ? (
							<h4 className="card-title">
								{this.state.edit
									? "Editar Banner - Padrão"
									: "Novo Banner - Padrão"}{" "}
							</h4>
						) : this.props.url.match.params.screen === "new-carousel" ? (
							<h4 className="card-title">
								{this.state.edit
									? "Editar Banner - Carrossel"
									: "Novo Banner - Carrossel"}{" "}
							</h4>
						) : (
							<h4 className="card-title">
								{this.state.edit
									? "Editar Banner - Corpo"
									: "Novo Banner - Corpo"}{" "}
							</h4>
						)}
					</h5>
				</div>

				<div id="dadosProdutos" className="#" aria-labelledby="headingOne">
					<div className="card-content">
						<div className="card-body pt-0">
							{/* <AdBlockDetect> */}
							{this.state.isAdblock && (
								<div class="alert alert-danger" role="alert">
									AdBlock foi detectador, favor desativar e recarregue a página
								</div>
							)}
							{/* </AdBlockDetect> */}
							<div className="row">
								<div className="col-6 ml-0 pl-0">
									{this.state.loading ? this.skeleton() : this.renderScreen()}
								</div>
								<div className="col-6 ml-0 pl-0">
									<PreviewAPP
										screenType={this.props.url.match.params}
										recoveryStateLogo={this.state.imageUrl}
									/>
								</div>
							</div>

							<div className="form-group col-12 d-flex justify-content-start mt-3 pl-0">
								{this.props.default == true ? (
									<Button
										className="btn-success"
										loading={this.state.loading}
										disable={this.state.loading || !this.state.referenceId}
										onClick={() => this.createBannerDefault()}
									>
										{this.state.edit ? "Salvar alterações" : "Salvar banner"}
									</Button>
								) : (
									<Button
										className="btn-success"
										loading={this.state.loading}
										disable={
											this.state.loading ||
											this.state.closeAlert ||
											(!this.state.referenceId &&
												!(
													this.state.action === "none" ||
													this.state.action === "zoom"
												))
										}
										onClick={() => this.createBanner()}
									>
										{this.state.edit ? "Salvar alterações" : "Salvar banner"}
									</Button>
								)}
								<Link
									className="btn btn-new-width btn-light ml-3"
									to={`/channel-management/app/${this.props.screen}`}
								>
									Cancelar
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	renderScreen() {
		return (
			<>
				<div className="col-10 ml-0 pl-0">
					{/* TITULO */}
					<div className="form-group col-12 ">
						<label htmlFor="codigoCupom">
							<strong>Título do banner</strong>
						</label>
						<div>
							<input
								type="text"
								className="form-control"
								id="codigoCupom"
								name="title"
								value={this.state.title}
								onChange={this.handleChange}
							/>
						</div>
					</div>

					{this.props.default == true ? null : (
						<div className="form-group ml-3 d-flex flex-wrap">
							<label className="w-100">
								<strong>Vigência do banner</strong>
							</label>
							<div className="mr-3">
								<DatePickerNeo
									label="Data de início"
									dateFormat="dd/MM/yyyy HH:mm"
									time={true}
									defaultValue={this.state.startIn}
									selected={this.state.startIn}
									onChange={this.handleChangeStartDate}
									minDate={new Date()}
								/>
							</div>
							<div className="">
								<DatePickerNeo
									label="Data de término"
									dateFormat="dd/MM/yyyy HH:mm"
									time={true}
									defaultValue={this.state.endIn}
									selected={
										this.state.endIn < this.state.startIn
											? this.state.startIn
											: this.state.endIn
									}
									onChange={this.handleChangeEndDate}
									minDate={new Date()}
								/>
							</div>
						</div>
					)}

					<div className="form-group w-100 ml-3">
						<div className="input-group w-100 p-0 m-0">
							<div className="d-flex flex-column w-100">
								<label className="" htmlFor="codigoCupom">
									<strong>Ação do banner</strong>
								</label>
								<Select
									styles={this.state.customStyles}
									className="react-select-container mb-2"
									value={
										this.state.actions[
											this.state.actions.findIndex(
												(option) => option.value === this.state.action
											)
										]
									}
									options={this.state.actions}
									theme={(theme) => ({
										...theme,
										borderRadius: 0,
										colors: {
											...theme.colors,
											primary25: "#BDCFF5",
											primary: "#9BB7F0",
										},
									})}
									onChange={this.changeAction}
								/>
							</div>

							{this.state.action === "open_webview" ? (
								<div className="form-group w-100">
									<input
										type="text"
										className="form-control"
										placeholder="Abrir URL dentro do APP"
										value={this.state.referenceId}
										onChange={(e) => this.handleChangeUrl(e)}
									/>
									<small
										style={{
											color: "rgb(161, 163, 165)",
											fontWeight: "500",
											fontSize: "12px",
										}}
									>
										<em>Ex: http://www.neomode.com.br</em>
									</small>
								</div>
							) : null}
							{this.state.action === "open_url" ? (
								<div className="form-group w-100">
									<input
										type="text"
										className="form-control"
										placeholder="Abrir URL fora do APP"
										value={this.state.referenceId}
										onChange={(e) => this.handleChangeUrl(e)}
									/>
									<small
										style={{
											color: "rgb(161, 163, 165)",
											fontWeight: "500",
											fontSize: "12px",
										}}
									>
										<em>Ex: http://www.neomode.com.br</em>
									</small>
								</div>
							) : null}
							{this.state.action === "open_category" ? (
								<input
									type="text"
									className="form-control"
									placeholder="Categoria"
									defaultValue={
										this.state.referenceId == null ? null : 1 + " Categoria"
									}
									onClick={() => this.setState({ isOpenCategoryModal: true })}
								/>
							) : null}
							{this.state.action === "open_product" ? (
								<input
									type="text"
									className="form-control"
									placeholder="Produto"
									value={this.state.referenceId == null ? null : 1 + " Produto"}
									data-toggle="modal"
									data-target="#selectProduct"
								/>
							) : null}
							{this.state.action === "open_video" ? (
								<input
									type="text"
									className="form-control"
									placeholder="Abrir um vídeo"
									value={this.state.referenceId}
									onChange={(e) => this.handleChangeVideo(e)}
								/>
							) : null}

							{this.state.action === "open_tag" && (
								<>
									<Select
										styles={this.state.customStyles}
										className="react-select-container w-100"
										value={
											this.state.collectionList[
												this.state.collectionList.findIndex(
													(option) => option.value === this.state.referenceId
												)
											]
										}
										options={this.state.collectionList}
										theme={(theme) => ({
											...theme,
											borderRadius: 0,
											colors: {
												...theme.colors,
												primary25: "#BDCFF5",
												primary: "#9BB7F0",
											},
										})}
										onChange={(e) => {
											this.setState({ referenceId: e.value });
										}}
									/>
								</>
							)}

							{this.state.action === "zoom" ||
							this.state.action === "none" ||
							this.state.referenceId == null
								? null
								: null}
							{this.state.closeAlert && (
								<div
									className="alert alert-danger w-100 mt-2 p-2 d-flex justify-content-center"
									role="alert"
								>
									<i
										className="material-icons"
										style={{
											fontSize: "23px",
											paddingRight: "5px",
										}}
									>
										error_outline
									</i>

									{this.state.action === "open_video"
										? `Atenção! O endereço de vídeo não é compatível com o aplicativo. Para que esta função carregue corretamente, adicione um endereço de vídeo do Youtube ou Vimeo!`
										: "Atenção! O link inserido não é válido!"}
								</div>
							)}
						</div>
					</div>
					<div className="form-group ml-3">
						<DropImage
							previewImage={this.state.imageUrl}
							recoveryStateLogo={this.recoveryStateImage}
							height={"310px"}
							width={"320px"}
							background={"#F2F4F4"}
						/>
					</div>
				</div>
				<Modal
					id={"selectProduct"}
					title={"Produtos"}
					removeCloseButton={true}
					banners={true}
					large={true}
					content={<Products getProducts={this.getProducts} banners={true} />}
				/>
				<CategoriesCheckbox
					isOpenCategoryModal={this.state.isOpenCategoryModal}
					setIsOpenCategoryModal={this.closeModalCategory}
					isMultiSelect={false}
					selectedValues={[this.state.referenceId]}
					handleFunction={this.getCategories}
				/>
			</>
		);
	}
}

export default New;
