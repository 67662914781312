import { ZipCodeDto } from "../../domain/dtos/SalesApi/ZipCodeDto";
import { CountryDataType } from "../../components/InternationalPhoneInput/InternationalPhoneInputService";
import { i18n } from "../../translate/i18n";
import { normalizeDigitsOnly } from "./StringUtils";
import { isValidString } from "./TypeValidator";

type FormErrors = Record<string, string[]>;
type FormValidations = Record<string, Function>;

/**
 * This function receives FormErrors object and check if it has at least 1 FieldError
 *
 * @param formErrors object of type FormErrors
 * @returns boolean stating if there's a error object in FormErrors
 */
const hasError = (formErrors: FormErrors): boolean =>
	Object.values(formErrors).some((value) => value.length > 0);

/**
 * This function checks if FormErrors object has error on passed input
 *
 * @param formErrorObj Object of type FormErrors
 * @param inputName input to check if has error in FormErrors object
 * @returns boolean stating wether there's error
 */
const hasInputError = (
	formErrorObj: FormErrors,
	inputName: string
): boolean => {
	let value: string[] | undefined = formErrorObj[inputName];

	// Should make this more readable later
	return !(value === undefined || value.length === 0);
};

/**
 * This function returns the errorCodes of input translated by i18n
 *
 * @param formErrorObj Object of type FormErrors
 * @param inputName input to translate errorCodes from
 * @returns array of translated strings
 */
const translateHelperTextList = (
	formErrorObj: FormErrors,
	inputName: string
): string[] => {
	if (!hasInputError(formErrorObj, inputName)) return [];

	return formErrorObj[inputName].map(
		(errorMessage) => i18n.t(`forms.${errorMessage}`) || ""
	);
};

/**
 * This functions validades if a required input has a value
 *
 * @param value input value
 * @returns array with or without an error code
 */
const isRequired = (value: string): string[] => {
	if (!isValidString(value)) return ["RequiredInput"];

	return [];
};

/**
 * This function validates if input has a value and if it's a valid cpf
 *
 * @param value value of cpf input
 * @returns array with or without an error code
 */
const isCpfValid = (value: string): string[] => {
	let isRequiredCheck = isRequired(value);
	if (isRequiredCheck.length > 0) return isRequiredCheck;

	const defaultErrorValue = ["InvalidCpf"];

	let normalized = normalizeDigitsOnly(value);

	if (normalized.length !== 11) return defaultErrorValue;

	const digits = normalized.split("");

	let sum1: number = 0;
	for (let i = 0; i < 9; i++) sum1 += parseInt(digits[i]) * (10 - i);
	let remainder1: number = (sum1 * 10) % 11;
	if (remainder1 === 10) remainder1 = 0;

	let sum2: number = 0;
	for (let i = 0; i < 10; i++) sum2 += parseInt(digits[i]) * (11 - i);
	let remainder2: number = (sum2 * 10) % 11;
	if (remainder2 === 10) remainder2 = 0;

	const validCpf =
		remainder1 === parseInt(digits[9]) && remainder2 === parseInt(digits[10]);
	if (!validCpf) return defaultErrorValue;
	return [];
};

/**
 * This function validades if input has a value and if it is a valid cnpj
 *
 * @param value value of cnpj input
 * @returns array with or without an error code
 */
const isCnpjValid = (value: string): string[] => {
	let isRequiredCheck = isRequired(value);
	if (isRequiredCheck.length > 0) return isRequiredCheck;

	let normalized = normalizeDigitsOnly(value);

	if (normalized.length !== 14) return ["InvalidCnpj"];

	return [];
};

/**
 * Validades a part, prefix or domain, of an email input.
 * This is a helper function for the isEmailValid function
 *
 * @param part which part of the email is being validated
 * @param specialChars special chars that are allowed
 * @returns boolean wether it's a valid email part or not
 */
const isValidEmailPart = (part: string, specialChars: string): boolean => {
	const regularChars =
		"abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
	const allValidChars = regularChars + specialChars;
	for (let i = 0; i < part.length; i++) {
		const char = part[i];
		if (!allValidChars.includes(char)) return false;
		if ((i === 0 || i === part.length - 1) && specialChars.includes(char))
			return false;
		if (i > 0 && specialChars.includes(char) && char === part[i - 1])
			return false;
	}
	return true;
};

/**
 * This function validates if input has a value and if it is a valid email
 *
 * @param value value of email input
 * @returns array with or without an error code
 */
const isEmailValid = (value: string): string[] => {
	let isRequiredCheck = isRequired(value);
	if (isRequiredCheck.length > 0) return isRequiredCheck;

	const defaultErrorValue = ["InvalidEmail"];

	const splittedEmail = value.split("@");
	if (splittedEmail.length !== 2) return defaultErrorValue;
	const [prefix, domain] = splittedEmail;
	if (prefix.length === 0 || domain.length === 0) return defaultErrorValue;

	const prefixSpecialChars = "!#$%&'*+-/=?^_`{|}~.";
	const domainSpecialChars = "-.";

	if (!isValidEmailPart(prefix, prefixSpecialChars)) return defaultErrorValue;
	if (!isValidEmailPart(domain, domainSpecialChars)) return defaultErrorValue;

	const splittedDomain = domain.split(".");
	if (splittedDomain.length < 2) return defaultErrorValue;
	for (let i = 0; i < splittedDomain.length; i++) {
		const part = splittedDomain[i];
		if (part.length === 0) return defaultErrorValue;
		if (i > 0 && part.length < 2) return defaultErrorValue;
	}

	return [];
};

/**
 * This function validates if input has a value and if it is a valid phone number
 *
 * @param value value of phone input
 * @returns array with or without an error code
 */
const isPhoneNumberValid = (value: string): string[] => {
	let isRequiredCheck = isRequired(value);
	if (isRequiredCheck.length > 0) return isRequiredCheck;

	let normalized = normalizeDigitsOnly(value);
	if (normalized.length < 10) return ["InvalidPhoneNumber"];

	return [];
};

/**
 * This function validates if the international phone input is in a valid format and values

 * @param phone the typed input value
 * @param currentCountry the country selected in the input select element
 * @returns array with or without an error code
 */
const isInternationalPhoneInputValid = (
	phone: string | null,
	currentCountry: CountryDataType | null
): string[] => {
	if (phone && !currentCountry) return ["PhoneCountryRequired"];
	if (currentCountry?.code !== "BR" || !phone) return [];

	let phoneValue = phone;
	if (phone.startsWith(normalizeDigitsOnly(currentCountry.phone))) {
		phoneValue = phone.slice(normalizeDigitsOnly(currentCountry.phone).length);
	}
	return phoneValue.length < 10 ? ["PhoneMinLengthRequired"] : [];
};

/**
 * This function validates if input has a value and if it is a valid phone number
 *
 * @param value value of password input
 * @param auxiliarProp optional auxiliar prop, used to check if it's a password edition
 * @returns array with or without password error codes
 */
const isPasswordValid = (
	value: string | null,
	isEdition: boolean
): string[] => {
	const password = value ?? "";

	if (isEdition && password.length === 0) return [];

	const errMessages = [];
	if (password.length < 6) errMessages.push("AtLeast.SixChars");

	if (!password.match(/\d/)) errMessages.push("AtLeast.OneDigit");

	if (!password.match(/[a-z]/)) errMessages.push("AtLeast.OneLowerCase");

	if (!password.match(/[A-Z]/)) errMessages.push("AtLeast.OneUpperCase");

	if (!password.match(/[!@#$%^&*?_~\-£()]/))
		errMessages.push("AtLeast.OneSpecialChar");

	if (errMessages.length > 0) errMessages.unshift("PasswordMustHave");

	return errMessages;
};

/**
 * This input validates if percentage input is filled and if it is in the allowed min/max percentage range
 *
 * @param value value of percentage input
 * @param minPercentage minimum percetage allowed for this input
 * @param maxPercentage maximum percetage allowed for this input
 * @returns array with or without an error code
 */
const isPercentageValid = (
	value: string,
	minPercentage: number = 0,
	maxPercentage: number = 100
): string[] => {
	let isRequiredCheck = isRequired(value);
	if (isRequiredCheck.length > 0) return isRequiredCheck;

	const floatValue = parseFloat(value.replace(",", "."));

	if (floatValue < minPercentage) return ["PercentageBelowAllowedValue"];
	if (floatValue > maxPercentage) return ["PercentageAboveAllowedValue"];

	return [];
};

/**
 * This function validates if input has the valid amount of characters of a brazilian zipCode
 * @param value The string value of the zipCode input
 * @returns array with or without an error code
 */
const isZipCodeValid = (
	value: string,
	zipCodeResponse: false | ZipCodeDto | undefined
): string[] => {
	let isRequiredCheck = isRequired(value);
	if (isRequiredCheck.length > 0) return isRequiredCheck;

	let normalized = normalizeDigitsOnly(value);
	if (normalized.length !== 8) return ["InvalidZipCode"];

	if (zipCodeResponse === false) return ["UnableToFindZipCode"];

	return [];
};

const validatePositiveValue = (value: string): string[] => {
	let isRequiredCheck = isRequired(value);
	if (isRequiredCheck.length > 0) return isRequiredCheck;

	if (Number(value) <= -1) return ["NegativeValueNotAllowed"];

	return [];
};

export type { FormErrors, FormValidations };
export const FormValidator = {
	hasError,
	hasInputError,
	translateHelperTextList,
};
export const InputValidator = {
	isRequired,
	isCpfValid,
	isCnpjValid,
	isEmailValid,
	isPhoneNumberValid,
	isInternationalPhoneInputValid,
	isPasswordValid,
	isPercentageValid,
	isZipCodeValid,
	validatePositiveValue,
};
