import React, { useEffect, useState } from "react";
import FreightList from "../Freight/components/FreightList";

import "./Freight.scss";

export default function Freight(props) {
	useEffect(() => {
		localStorage.removeItem("PARAMS");
	}, []);

	return (
		<div className="container dash-content">
			<FreightList />
		</div>
	);
}
