import React from "react";
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	Stack,
	TextField,
} from "@mui/material";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import CustomInput from "../../../../MUICustomComponents/CustomInput";
import CategoryFormService from "./CategoryFormService";
import CategoriesCheckbox from "../../../../components/modals/CategoriesCheckbox/CategoriesCheckbox";
import LoadingButtonIcon from "../../../../components/LoadingButtonIcon";
import { i18n } from "../../../../translate/i18n";
import { UploadImage } from "../../../../components/UploadImage/UploadImage";
import { FormValidator } from "../../../../infrastructure/utils/FormUtils";
import { CategoryInputs } from "../../enums/CategoryInputs";
import { Prompt } from "react-router-dom";

import * as Styled from "../../styles/CategoryStyles";

export default function CategoryForm() {
	const {
		//Props
		isOpenCategoryModal,
		setIsOpenCategoryModal,
		formErrors,
		isEditable,
		isLoading,
		categories,
		enableCategoryForm,
		isReorderMode,
		isDisabled,
		isLoadingList,
		isCancellationAction,
		currentCategory,
		currentParentCategoryId,
		//Functions
		getParentCategories,
		handleFormSubmit,
		handleFileUpload,
		handleInputChange,
		handleCheckedChange,
		upsertCategory,
		handleCategoryCancellation,
		handleCategoryDeletion,
		handleCategoryOrderingCancellation,
		notifyDataChange,
		//Hooks
		unsavedChanges,
		promptMessage,
	} = CategoryFormService();

	const BtnSave = i18n.t(
		`categories.Titles.${isEditable ? "EditCategory" : "CreateCategory"}`
	);

	const newCategory = () => {
		return (
			<Styled.EmptyContentBox>
				<CustomTypography variant="smallTitle">
					{isReorderMode
						? i18n.t(`categories.Titles.BlockedForm`).toString()
						: i18n.t(`categories.Titles.CategoriesForm`).toString()}
				</CustomTypography>
				<CustomTypography variant="default">
					{isReorderMode
						? i18n.t(`categories.Messages.BlockedForm`).toString()
						: i18n.t(`categories.Messages.EmptyCategory`).toString()}
				</CustomTypography>

				<Stack mt={3} flexDirection={"row"} gap={1}>
					{isReorderMode ? (
						<Button
							variant="contained"
							color="light"
							disabled={isDisabled}
							onClick={handleCategoryOrderingCancellation}
						>
							{i18n.t(`buttons.General.BtnCancel`).toString()}
						</Button>
					) : (
						<Button
							variant="contained"
							color="success"
							disabled={isLoadingList}
							onClick={() => upsertCategory("", categories, false)}
						>
							{isLoadingList ? (
								<LoadingButtonIcon />
							) : (
								i18n.t(`categories.Titles.NewCategory`).toString()
							)}
						</Button>
					)}
				</Stack>
			</Styled.EmptyContentBox>
		);
	};

	const formCategory = () => {
		return (
			<Grid item>
				<CustomTypography variant="title">
					{isEditable
						? i18n.t(`categories.Titles.EditCategory`).toString()
						: i18n.t(`categories.Titles.NewCategory`).toString()}
				</CustomTypography>

				<CustomTypography variant="subtitle">
					{i18n.t(`categories.Titles.Icon`).toString()}
				</CustomTypography>

				<Box display={"flex"} flexDirection={"row"} mt={1}>
					<Styled.BoxImage>
						<UploadImage
							onFileUpload={handleFileUpload}
							image={currentCategory.iconUrl ?? ""}
						/>
					</Styled.BoxImage>
					<Grid container>
						<Styled.GridInput item xs={12}>
							<Grid container spacing={0.5}>
								<Grid item xs={8}>
									<CustomInput
										name={CategoryInputs.Code}
										disabled={isEditable}
										value={currentCategory.code}
										title={i18n.t(`categories.Titles.CategoryCode`)}
										onChange={handleInputChange}
										error={FormValidator.hasInputError(
											formErrors,
											CategoryInputs.Code
										)}
										helperText={FormValidator.translateHelperTextList(
											formErrors,
											CategoryInputs.Code
										)}
									/>
								</Grid>
								<Grid item xs={4}>
									<FormControlLabel
										control={
											<Checkbox
												name={CategoryInputs.IsActive}
												checked={currentCategory.isActive}
												onChange={handleCheckedChange}
											/>
										}
										label={
											<CustomTypography variant="default">
												{i18n
													.t(`categories.Titles.CategoryIsActive`)
													.toString()}
											</CustomTypography>
										}
									/>
								</Grid>
							</Grid>
						</Styled.GridInput>

						<Styled.GridInput item xs={12}>
							<CustomInput
								name={CategoryInputs.Name}
								value={currentCategory.name}
								title={i18n.t(`categories.Titles.CategoryName`)}
								onChange={handleInputChange}
								error={FormValidator.hasInputError(
									formErrors,
									CategoryInputs.Name
								)}
								helperText={FormValidator.translateHelperTextList(
									formErrors,
									CategoryInputs.Name
								)}
							/>
						</Styled.GridInput>
					</Grid>
				</Box>

				<Grid item xs={12} mt={1}>
					<TextField
						fullWidth
						size="small"
						onClick={() => setIsOpenCategoryModal(true)}
						value={currentParentCategoryId?.name}
						id={CategoryInputs.Category}
						label={i18n.t(`categories.Titles.Categories`).toString()}
						variant="outlined"
					/>
				</Grid>

				<CategoriesCheckbox
					isOpenCategoryModal={isOpenCategoryModal}
					setIsOpenCategoryModal={setIsOpenCategoryModal}
					isMultiSelect={false}
					selectedValues={[currentParentCategoryId?.id]}
					handleFunction={getParentCategories}
				/>

				<Grid item xs={12} mt={3}>
					<CustomInput
						name={CategoryInputs.Description}
						value={currentCategory.description}
						title={i18n.t(`categories.Titles.Description`)}
						onChange={handleInputChange}
						multiline={true}
						rows={4}
					/>
				</Grid>

				<Stack
					direction="row"
					justifyContent={isEditable ? `space-between` : `end`}
					mt={3}
				>
					{isEditable && (
						<Button
							variant="outlined"
							color="danger"
							disabled={isLoading}
							onClick={() => handleCategoryDeletion(currentCategory.id ?? "")}
						>
							{i18n.t(`categories.Titles.DeleteCategory`).toString()}
						</Button>
					)}
					<Stack direction="row" justifyContent="end">
						<Box mr={"10px"}>
							<Button
								variant="contained"
								color="light"
								disabled={isLoading}
								onClick={handleCategoryCancellation}
							>
								{i18n.t(`buttons.General.BtnCancel`).toString()}
							</Button>
						</Box>

						<Button
							variant="contained"
							color="success"
							disabled={isLoading}
							onClick={handleFormSubmit}
						>
							{isLoading ? <LoadingButtonIcon /> : BtnSave}
						</Button>
					</Stack>
				</Stack>
			</Grid>
		);
	};

	const messageCallback = isCancellationAction
		? notifyDataChange(promptMessage)
		: promptMessage;

	return (
		<Styled.ContentBox>
			<>
				<Prompt when={unsavedChanges} message={messageCallback} />

				{isReorderMode || !enableCategoryForm ? newCategory() : formCategory()}
			</>
		</Styled.ContentBox>
	);
}
