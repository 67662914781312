import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import Modal from "../../components/Modal";
import List from "../../components/List/List";
import LoadDashboard from "../Dashboard/components/LoadDashboard";
import Empty from "../../components/Empty";
import { Link, useHistory } from "react-router-dom";
import { useConfig } from "../../providers/ConfigProvider";
import HeaderSearches from "../../components/HeaderSearches/HeaderSearches";
import { i18n } from "../../translate/i18n";
import useSellerListService from "./providers/SellerListService";

export default function StoreList(props) {
	const { mainLoading } = useConfig();
	const {
		loading,
		setLoading,
		page,
		setPage,
		rowsPerPage,
		setRowsPerPage,
		query,
		setQuery,
		sellerList,
		totalSellers,
		sellerFilters,
		setSellerFilters,
		optionsDict,
		getStores,
		deleteStore,
	} = useSellerListService();
	const history = useHistory();
	const [deleteId, setDeleteId] = useState("");
	const columsList = [
		{
			name: "Nome",
			selector: "fullName",
			sortable: false,
			center: false,
			cell: (row) => (
				<div
					className="font-weight-bold text-muted"
					style={{ width: "100%" }}
					title={row.fullName}
				>
					<p
						style={{
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}
					>
						{row.fullName}
					</p>
				</div>
			),
		},

		{
			name: "CNPJ",
			selector: "cnpj",
			sortable: false,
			center: true,
			cell: (row) => <div className="text-muted">{row.cnpj}</div>,
		},
		{
			name: "Status",
			selector: "isActive",
			sortable: false,
			center: true,
			cell: (row) =>
				row.isActive ? (
					<span className="badge badge-success stadium text-center">ATIVO</span>
				) : (
					<span className="badge badge-grey-60 stadium">INATIVO</span>
				),
		},

		{
			name: "",
			sortable: true,
			width: "150px",
			cell: (row) => (
				<div className="btn-group" role="group">
					<Link
						id="btnGroupDrop1"
						to={
							props.isAutoSetup
								? `/setup/stores/${props.page}/edit/` + row.id
								: `/stores/${props.page}/edit/` + row.id
						}
						className="btn btn-info"
					>
						Editar
					</Link>

					<button
						id="btnGroupDrop1"
						type="button"
						className="btn btn-sm border-left border-white btn-info rounded-right"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<span className="material-icons" style={{ fontSize: "15px" }}>
							keyboard_arrow_down
						</span>
					</button>

					<div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
						<a
							className="dropdown-item text-danger"
							data-toggle="modal"
							data-target="#deleteSellers"
							onClick={() => setDeleteId(row.id)}
						>
							Excluir
						</a>
					</div>
				</div>
			),
		},
	];

	const selectList = [
		{
			title: "StoreStatus",
			options: optionsDict.isActive,
		},
	];
	useEffect(() => {
		history.push(
			`${page}?query=${query}&isActive=${sellerFilters.StoreStatus}`
		);
		getStores();
	}, [page, rowsPerPage, query, sellerFilters]);

	const changePage = (e) => {
		setLoading(true);
		setPage(e);
	};

	const changeRowsPerPage = (e) => {
		setLoading(true);
		setRowsPerPage(e);
		setPage("1");
	};

	const handleQueryChange = (queryValue) => {
		setQuery(queryValue);
		setPage("1");
	};

	const handleClearFilters = () => {
		setSellerFilters({
			StoreStatus: "",
		});
		setPage("1");
	};

	const skeleton = () => {
		const skele = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
		return skele.map((key) => (
			<ContentLoader
				key={key}
				speed={2}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="16" y="25" rx="3" ry="3" width="115" height="10" />
				<rect x="450" y="26" rx="3" ry="3" width="60" height="10" />
				<rect x="750" y="26" rx="3" ry="3" width="52" height="10" />
				<rect x="970" y="25" rx="3" ry="3" width="100" height="200" />
			</ContentLoader>
		));
	};

	return (
		<>
			{mainLoading ? (
				<LoadDashboard />
			) : (
				<div className="container dash-content">
					<div className="card">
						<div className="card-header d-flex justify-content-between align-items-center p-4">
							<h4 className="card-title">Lojas</h4>

							<div className="card-actions">
								<Link
									className="btn btn-new-width btn-info light btn-icon justify-content-center mr-2"
									to={"/import"}
								>
									<i className="material-icons">upload</i>&nbsp;&nbsp;Importar
									Lojas
								</Link>
								<Link
									className="btn btn-new-width btn-grey-80"
									to={
										props.isAutoSetup
											? `${props.page}/new`
											: `/stores/${props.page}/new`
									}
								>
									Nova Loja
								</Link>
							</div>
						</div>

						<div className="m-2 p-2">
							<HeaderSearches
								query={query}
								handleQueryChange={handleQueryChange}
								handleClearFilters={handleClearFilters}
								selectList={selectList}
								setFilters={setSellerFilters}
								filters={sellerFilters}
								placeholder={"SearchSeller"}
							/>
						</div>

						<div className="card-content">
							<div
								className="d-flex align-items-center p-3"
								style={{ width: "100%" }}
							>
								<strong>
									{i18n
										.t("components.ShowingAllResultsQuantity")
										.replace("%X", totalSellers || "0")
										.toUpperCase()}
								</strong>
							</div>
							<div className="card-body no-padding">
								{sellerList.length <= 0 && loading === false ? (
									<Empty
										img={"/images/empty_sellers.svg"}
										genre="sua primeira"
										name="loja"
										link={`/stores/${page}/new`}
										import={true}
									/>
								) : loading ? (
									skeleton()
								) : (
									<List
										data={sellerList}
										page={parseInt(page)}
										colums={columsList}
										totalRows={totalSellers}
										handlePageChange={changePage}
										paginationServer={true}
										rowsPerPage={rowsPerPage}
										handleRowsPerPageChange={changeRowsPerPage}
									/>
								)}
							</div>
						</div>
					</div>
					<Modal
						id={"deleteSellers"}
						title={"Certeza que deseja excluir este item?"}
						delete={true}
						actionDelete={() => deleteStore(deleteId)}
					/>
				</div>
			)}
		</>
	);
}
