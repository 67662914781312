import { useContext, useEffect, useRef, useState } from "react";
import { CategoryView } from "../../../../domain/views/categories/CategoryView";
import { isValidString } from "../../../../infrastructure/utils/TypeValidator";
import { CategoryContext } from "../../context/CategoryContext";

export default function TreeViewService() {
	const {
		// Props
		categories,
		setCategories,
		isReorderMode,
		isUpsertMode,
		isLoading,
		// Function
		getCategoryById,
		//Hooks
		enableUnsavedChanges,
	} = useContext(CategoryContext);

	const treeViewRef = useRef<HTMLDivElement>(null);
	const debounceTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

	const [mousePosition, setMousePosition] = useState(0);
	const [nodeStates, setNodeStates] = useState<{ [key: string]: boolean }>({});

	useEffect(() => {
		if (treeViewRef.current) {
			const handleMouseMove = (event: MouseEvent) => {
				if (debounceTimeoutRef.current) {
					clearTimeout(debounceTimeoutRef.current);
				}

				debounceTimeoutRef.current = setTimeout(() => {
					setMousePosition(event.clientY);
				}, 200);
			};

			treeViewRef.current.addEventListener("mousemove", handleMouseMove);

			return () => {
				if (treeViewRef.current) {
					treeViewRef.current.removeEventListener("mousemove", handleMouseMove);
				}
				if (debounceTimeoutRef.current) {
					clearTimeout(debounceTimeoutRef.current);
				}
			};
		}
	}, []);

	const sortCategory = (
		parentId: string,
		id: string,
		newOrder: number
	): void => {
		enableUnsavedChanges();

		let reorderCategory: CategoryView[];

		if (isValidString(parentId)) {
			const currentCategory = getCategoryById(categories, parentId);

			if (!currentCategory) return;

			reorderCategory = reorder(currentCategory.children, id, newOrder);

			const reorderCategoryWithChildren = replaceChildrenReordered(
				categories,
				parentId,
				reorderCategory
			);

			setCategories(reorderCategoryWithChildren);

			return;
		}

		reorderCategory = reorder(categories, id, newOrder);

		if (!reorderCategory) return;

		setCategories(reorderCategory);
	};

	const reorder = (
		categories: CategoryView[],
		id: string,
		newOrder: number
	): CategoryView[] => {
		const categoryToBeMoved = categories.find((category) => category.id === id);

		if (!categoryToBeMoved) return categories;

		const updatedCategories = categories.map((category) => {
			if (category.order === newOrder) {
				return { ...category, order: categoryToBeMoved.order };
			} else if (category.id === id) {
				return { ...category, order: newOrder };
			}
			return category;
		});

		return updatedCategories;
	};

	const replaceChildrenReordered = (
		categories: CategoryView[],
		categoryId: string,
		newChildrenReorder: CategoryView[]
	): CategoryView[] => {
		const updatedCategories = categories.map((category) => {
			if (category.id === categoryId) {
				return { ...category, children: newChildrenReorder };
			}

			if (category.children.length > 0) {
				const updatedChildren = replaceChildrenReordered(
					category.children,
					categoryId,
					newChildrenReorder
				);

				return { ...category, children: updatedChildren };
			}

			return category;
		});

		return updatedCategories;
	};

	const minOrder = (order: number): boolean => {
		if (isLoading) {
			return isLoading;
		}
		return order <= 1;
	};

	const maxOrder = (order: number, parentId: string): boolean => {
		if (isLoading) {
			return isLoading;
		}

		if (parentId) {
			const category = getCategoryById(categories, parentId ?? "");
			return category?.children.length === order;
		}
		return categories.length === order;
	};

	return {
		// Props
		treeViewRef,
		mousePosition,
		nodeStates,
		setNodeStates,
		isReorderMode,
		isUpsertMode,
		// Functions
		sortCategory,
		minOrder,
		maxOrder,
	};
}
