import React, { useEffect, useState } from "react";
import ButtonStatus from "../ButtonStatus";
import ArrowDownDashed from "../../../../assets/floworder-arrow/ArrowDownDashed.svg";
import ArrowDownSuccess from "../../../../assets/floworder-arrow/ArrowDownSuccess.svg";
import ArrowDownSolid from "../../../../assets/floworder-arrow/ArrowDownSolid.svg";
import ArrowDownWarning from "../../../../assets/floworder-arrow/ArrowDownWarning.svg";
import ArrowDownInfo from "../../../../assets/floworder-arrow/ArrowDownInfo.svg";
import ArrowDownRightSmDashed from "../../../../assets/floworder-arrow/ArrowDownRightSmDashed.svg";
import ArrowUpRightDashed from "../../../../assets/floworder-arrow/ArrowUpRightDashed.svg";
import ArrowUpRightSolid from "../../../../assets/floworder-arrow/ArrowUpRightSolid.svg";
import { useOrders } from "../../providers/OrdersContext";
import ArrowDownRightSm from "../../svg/ArrowDownRightSm";
export default function CreateInvoiceStatus({
	indexColumn,
	getTypeIcons,
	getTitleButton,
	getTypeInfo,
	getButtonStyle,
	getTypeArrow,
	getProcessOrder,
	getRetryProcessOrder,
	getFailedProcessOrder,
}) {
	const [invoice, setInvoice] = useState([]);
	const { flowStatus, loadingStatus, loadingCancel, isCancellation } =
		useOrders();

	const position = flowStatus?.enqueueOrderPosition;
	const lastPosition = parseInt(flowStatus?.lastOrderPosition);
	const lastProcessedStatus = lastPosition + 1;
	const lastDateUpdate = flowStatus?.actions[3]?.LastUpdate;
	const stepEmpty = position < 0;
	const stepEmptyCancel = position > 100 && lastPosition < 3;
	const stepCancellation = position > 100 && lastPosition === 3;
	const nextStep = flowStatus?.hasNextActionAvailable && position === 3;
	const checkInvoicing = flowStatus?.invoice && position === 3;
	const loading = loadingStatus && position === 3 && !loadingCancel;
	const loadingRetry =
		loadingStatus && !isCancellation && lastPosition === 2 && position === 100;
	const errorCancel =
		position === 100 &&
		lastPosition === 2 &&
		(flowStatus.status === "WaitingCancel" || flowStatus.status === "Canceled");
	const isCanceled =
		position === 100 &&
		(flowStatus.status === "WaitingCancel" || flowStatus.status === "Canceled");

	const getStepCompleted = (position, lastPosition, lastProcessedStatus) => {
		if (position > 3 && position < 100) return true;
		else if (position > 100 && lastPosition > 3) return true;
		else if (position === 100 && lastProcessedStatus > 3) return true;
		else return false;
	};

	const stepCompleted = getStepCompleted(
		position,
		lastPosition,
		lastProcessedStatus
	);

	const processing = getProcessOrder(position === 3);

	const processingRetry = getRetryProcessOrder(position === 3);

	const failedProcess = getFailedProcessOrder(
		position === 3,
		lastProcessedStatus === 3
	);
	useEffect(() => {
		if (stepCancellation || errorCancel) {
			setInvoice([0]);
		} else {
			setInvoice([0, 1]);
		}
	}, [stepCancellation, errorCancel]);

	const handleIcons = (index) => {
		switch (index) {
			case 0:
				return getTypeIcons("ReceiptLong");
			case 1:
				if (processing || processingRetry || loading)
					return getTypeIcons("WatchLater");
				else if (failedProcess) return getTypeIcons("Refresh");
				else return getTypeIcons("CheckCircle");
			default:
				break;
		}
	};

	const handleTitles = (index) => {
		switch (index) {
			case 0:
				return getTitleButton("WaitingInvoicePending");
			case 1:
				if (loading) return getTitleButton("Processing");
				else if (failedProcess) return getTitleButton("Reprocess");
				else if (stepCompleted)
					return getTitleButton("WaitingInvoiceCompleted");
				else return getTitleButton("WaitingInvoiceAction");
			default:
				break;
		}
	};

	const handleInfo = (index) => {
		const empty = stepEmpty || stepEmptyCancel;
		const process = processing || processingRetry;

		if (empty) return getTypeInfo("NotStarted");
		else if (stepCompleted) return getTypeInfo(lastDateUpdate);
		else return showCorrectInfo(index, process);
	};

	const showCorrectInfo = (index, process) => {
		const load = loadingRetry && !errorCancel;
		switch (index) {
			case 0:
				if (nextStep || process || load) return flowStatus?.integrator;
				else if (stepCancellation || errorCancel)
					return getTypeInfo("InCancellation");
				else if (failedProcess) return getTypeInfo("IntegrationFailure");
				else return getTypeInfo("NotStarted");
			case 1:
				if (loading) return getTypeInfo("WaitAMinute");
				else if (nextStep || errorCancel) return getTypeInfo("ClickToConfirm");
				else if (processingRetry || loadingRetry)
					return getTypeInfo("ProcessingRetry");
				else if (failedProcess) return getTypeInfo("TryAgain");
				else if (processing) return getTypeInfo("Processing");
				else return getTypeInfo("NotStarted");
			default:
				break;
		}
	};

	const handleStyles = (index) => {
		const empty = stepEmpty || stepEmptyCancel;
		const process = processing || processingRetry || loading;

		if (empty) return getButtonStyle("Empty");
		else if (stepCompleted) return getButtonStyle("Completed");
		else return showCorrectStyle(index, process);
	};

	const showCorrectStyle = (index, process) => {
		const load = loadingRetry && !errorCancel;
		switch (index) {
			case 0:
				if (process || loading || load) return getButtonStyle("SuccessOutline");
				else if (stepCancellation || errorCancel)
					return getButtonStyle("Canceled");
				else if (failedProcess || nextStep)
					return getButtonStyle("WarningOutline");
				else return getButtonStyle("Empty");
			case 1:
				if (process || loading || loadingRetry)
					return getButtonStyle("RetryOutline");
				else if (nextStep || errorCancel) return getButtonStyle("Success");
				else if (failedProcess) return getButtonStyle("Warning");
				else return getButtonStyle("Empty");
			default:
				break;
		}
	};

	const handleIntegration = (index) => {
		const failed = "failed";
		const retry = "retry";
		const process = "process";
		const empty = "empty";
		switch (index) {
			case 0:
				if (loading || loadingRetry || errorCancel) return empty;
				else if (failedProcess) return failed;
				else return empty;
			case 1:
				if (processing || loading || loadingRetry) return process;
				else if (nextStep) return empty;
				else if (processingRetry) return retry;
				else return empty;
			default:
				break;
		}
	};

	const handleDisabledButton = (index) => {
		const clickConfirm = nextStep || failedProcess;
		if (loadingStatus || loadingCancel) return true;
		else {
			if (index === 0) return true;
			if (index === 1) return clickConfirm ? false : true;
		}
	};

	const handleArrowStepCancellation = (index) => {
		if (index === 0)
			return <ArrowDownRightSm currentStep={getTypeArrow(0)} left={"90px"} />;
	};

	const handleArrowStepMain = (index) => {
		const process = processing || processingRetry;
		const arrow = position < 4 || (lastPosition <= 2 && position === 100);
		switch (index) {
			case 0:
				if (process || loading || loadingRetry)
					return getTypeArrow(ArrowDownInfo, "arrow-down");
				else if (nextStep || errorCancel)
					return getTypeArrow(ArrowDownSuccess, "arrow-down");
				else if (failedProcess)
					return getTypeArrow(ArrowDownWarning, "arrow-down");
				else if (stepCompleted)
					return getTypeArrow(ArrowDownSolid, "arrow-down");
				else return getTypeArrow(ArrowDownDashed, "arrow-down");
			case 1:
				if (stepCompleted)
					return getTypeArrow(ArrowUpRightSolid, "arrow-up-right");
				else if (isCanceled)
					return getTypeArrow(ArrowUpRightDashed, "arrow-up-right");
				else
					return (
						<>
							{getTypeArrow(ArrowUpRightDashed, "arrow-up-right")}
							{arrow &&
								getTypeArrow(ArrowDownRightSmDashed, "arrow-down-right-sm")}
						</>
					);
			default:
				break;
		}
	};

	const createStatusInvoice = () => {
		const cancellation = stepCancellation || errorCancel;
		return invoice.map((index) => (
			<>
				<ButtonStatus
					key={index}
					name={
						index === 2 && indexColumn === 3
							? "#orderFlowCancel"
							: failedProcess
							? ""
							: !checkInvoicing
							? "#invoiceOrders"
							: "#orderFlow"
					}
					icons={handleIcons(index)}
					title={handleTitles(index)}
					info={handleInfo(index)}
					style={handleStyles(index)}
					integration={handleIntegration(index)}
					disabled={handleDisabledButton(index)}
					position={position}
					isError={failedProcess}
				/>
				{cancellation
					? handleArrowStepCancellation(index)
					: handleArrowStepMain(index)}
			</>
		));
	};

	return createStatusInvoice();
}
