import React from "react";
import ChromePickerService from "./ChromePickerService";
import { ChromePicker as ColorPicker } from "react-color";
import { Popover } from "@mui/material";

import * as Styled from "./ChromePickerStyles";

type ChromePickerProps = {
	updateChromePicker: (colorPicker: string) => void;
	currentColor: string;
	disabled?: boolean;
};

export default function ChromePicker({
	updateChromePicker,
	currentColor,
	disabled,
}: ChromePickerProps) {
	const {
		// Props
		colorValue,
		anchorEl,
		openColorPicker,
		id,
		// Functions
		handleChangeColor,
		handleClick,
		handleClose,
	} = ChromePickerService({ updateChromePicker });

	return (
		<>
			<Styled.Button
				onClick={handleClick}
				color={!colorValue ? currentColor : colorValue}
				disabled={disabled}
			></Styled.Button>

			<Popover
				id={id}
				open={openColorPicker}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
			>
				<ColorPicker color={colorValue} onChangeComplete={handleChangeColor} />
			</Popover>
		</>
	);
}
