export function getFilterDate(days, timezone = 0) {
	timezone = -timezone;
	let initialDate = new Date();
	let endDate = new Date();
	initialDate.setUTCHours(0 + timezone, 0, 0, 0);
	endDate.setUTCHours(23 + timezone, 59, 59, 999);

	if (days < 0) {
		initialDate.setUTCDate(initialDate.getUTCDate() + ++days);
	} else if (days > 0) {
		endDate.setUTCDate(endDate.getUTCDate() + --days);
	}

	return [initialDate, endDate];
}
