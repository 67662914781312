import React from "react";
import ContentLoader from "react-content-loader";

export default function GenericTableLoader() {
	return [...Array(10)].map((_, index) => (
		<ContentLoader
			key={index}
			speed={2}
			width={1100}
			height={60}
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
			viewBox="0 0 1100 60"
			style={{ width: "100%" }}
		>
			<rect x="20" y="25" rx="3" ry="3" width="150" height="10" />
			<rect x="220" y="26" rx="3" ry="3" width="150" height="10" />
			<rect x="420" y="26" rx="3" ry="3" width="150" height="10" />
			<rect x="620" y="26" rx="3" ry="3" width="150" height="10" />
			<rect x="820" y="26" rx="3" ry="3" width="150" height="10" />
			<rect x="1020" y="26" rx="3" ry="3" width="50" height="10" />
		</ContentLoader>
	));
}
