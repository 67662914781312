import { Button, ButtonGroup, OutlinedInput } from "@mui/material";
import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import { paletteObject } from "../theme/foundations/palette";
import { RemoveRounded, AddRounded } from "@mui/icons-material";
import CustomIcon from "../MUICustomComponents/CustomIcon";

type Props = {
	value: string;
	setValue: Dispatch<SetStateAction<string>>;
};

export default function QuantityInputSelector({ value, setValue }: Props) {
	const handleActionButton = (action: "increase" | "decrease"): void => {
		let numericValue = Number(value);
		numericValue = action === "increase" ? numericValue + 1 : numericValue - 1;
		if (numericValue < 1) numericValue = 1;
		setValue(String(numericValue));
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setValue(value);
	};

	return (
		<ButtonGroup
			id="packageQuantitySelector"
			color="light"
			variant="outlined"
			sx={{
				".MuiButtonGroup-groupedOutlined": {
					borderRadius: "5px",
					borderWidth: "2px",
					":hover": { borderWidth: "2px" },
					":first-child": {
						borderRight: `1px solid ${paletteObject.light.main}`,
					},
					":last-child": { borderLeftWidth: "1px" },
				},
			}}
		>
			<Button onClick={() => handleActionButton("decrease")} sx={{ p: 1 }}>
				<CustomIcon
					variant="default"
					color="blueish80"
					icon={<RemoveRounded />}
				/>
			</Button>
			<OutlinedInput
				value={value}
				type="number"
				onChange={handleInputChange}
				sx={{
					width: "50px !important",
					textAlign: "center",
					padding: "0 10px",
					height: "40px",
					borderRadius: 0,
					".MuiOutlinedInput-notchedOutline": {
						borderColor: paletteObject.light.main,
						borderLeftWidth: "1px",
					},
					"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
						borderColor: paletteObject.light.main,
					},
					"& input": {
						textAlign: "center",
						padding: "0",
					},
				}}
			/>
			<Button onClick={() => handleActionButton("increase")} sx={{ p: 1 }}>
				<CustomIcon variant="default" color="blueish80" icon={<AddRounded />} />
			</Button>
		</ButtonGroup>
	);
}
