import { z } from "zod";

export const layoutProfileSchema = z.object({
	id: z.string().uuid(),
	clientId: z.string(),
	dashboardPrimaryColor: z.string(),
	dashboardSecondaryColor: z.string(),
	dashboardHorizontalDarkImageUrl: z.string().nullable(),
	dashboardHorizontalLightImageUrl: z.string().nullable(),
	createDate: z.string(), // TODO add .datetime(), not possible because backend needs to fix date to ISO datetime ("Z")
	lastUpdate: z.string(), // TODO add .datetime(), not possible because backend needs to fix date to ISO datetime ("Z")
});

export type LayoutProfile = z.infer<typeof layoutProfileSchema>;
