import { useEffect, useState } from "react";
import NewToastComponent from "../../components/NewToastComponent";
import { i18n } from "../../translate/i18n";
import { AutocompleteSellerView } from "../../domain/views/dealers/AutocompleteSellerView";
import { buildURLWithParams } from "../../infrastructure/utils/BuildURLWithParams";
import { useOrders } from "./providers/OrdersContext";
import { smCommerceManagementApi } from "../../infrastructure/api/SMCommerceManagement";
import { useSearchParams } from "../../hooks/useSearchParams";
import { OrdersDto } from "../../domain/dtos/smOrders/OrdersDto";
import { useParams } from "react-router-dom";
import { DateType } from "../../domain/enums/DateType";
import {
	getInitialDateType,
	getSinceFilter,
} from "../../infrastructure/utils/Dates";
import { OrderFilters } from "../../domain/types/orders/OrderFilters";
import { ListOrderMapper } from "../../domain/mappers/orders/ListOrderMapper";
import { ListOrderModel } from "../../domain/models/commerce/OrderList/ListOrderModel";

type OrderListParams = {
	since?: string;
	until?: string;
	status?: string;
	isDelivery?: boolean;
	search?: string;
	withError?: boolean;
	sellerCode?: string;
};

export default function OrderListService() {
	const { page } = useParams();
	const searchParams = useSearchParams<OrderListParams>();

	const { openModal } = useOrders() as { openModal: void };
	const [loading, setLoading] = useState<boolean>(true);
	const [currentSeller, setCurrentSeller] =
		useState<AutocompleteSellerView | null>(null);
	const [ordersList, setOrdersList] = useState<ListOrderModel[]>([]);
	const [maxCount, setMaxCount] = useState<number>(0);
	const [triggerRowsOrPageChange, setTriggerRowsOrPageChange] =
		useState<boolean>(false);
	const [orderFilters, setOrderFilters] = useState<OrderFilters>({
		page: 1,
		rowsPerPage: 20,
		dateType: DateType.Today,
		since: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
		until: new Date().toISOString(),
		status: null,
		isDelivery: null,
		search: null,
		withError: null,
		sellerCode: null,
	});
	const [modality, setModality] = useState<string>("");

	useEffect(() => {
		setLoading(true);
		const parsedPage = parseInt(page);
		const dateType = getInitialDateType((searchParams.since as string) ?? null);
		const updatedOrderFilters: OrderFilters = {
			...orderFilters,
			page: parsedPage,
			dateType: dateType,
			since: (searchParams.since as string) ?? orderFilters.since,
			until: (searchParams.until as string) ?? orderFilters.until,
			status: (searchParams.status as string) ?? orderFilters.status,
			isDelivery:
				(searchParams.isDelivery as boolean) ?? orderFilters.isDelivery,
			search: (searchParams.search as string) ?? orderFilters.search,
			withError: (searchParams.withError as boolean) ?? orderFilters.withError,
			sellerCode:
				(searchParams.sellerCode as string) ?? orderFilters.sellerCode,
		};
		setOrderFilters(updatedOrderFilters);
		setModality(`${searchParams.isDelivery ?? ""}`); // isDelivery needs to be a string for "modality" until the status modal gets refactored
		getOrders(updatedOrderFilters);
	}, []);

	useEffect(() => {
		if (triggerRowsOrPageChange) {
			getOrders(orderFilters);
			setTriggerRowsOrPageChange(false);
		}
	}, [triggerRowsOrPageChange]);

	const changePage = (page: number): void => {
		setLoading(true);
		const newOrderFilters = { ...orderFilters, page: page };
		setOrderFilters(newOrderFilters);
		setTriggerRowsOrPageChange(true);
	};

	const changeRowsPerPage = (rowsPerPage: number): void => {
		setLoading(true);
		const newOrderFilters = {
			...orderFilters,
			rowsPerPage: rowsPerPage,
			page: 1,
		};
		setOrderFilters(newOrderFilters);
		setTriggerRowsOrPageChange(true);
	};

	const updateFilters = (orderFilters: OrderFilters): void => {
		setLoading(true);
		setOrderFilters(orderFilters);
		getOrders(orderFilters);
	};

	const getOrders = async (newOrderFilters: OrderFilters): Promise<void> => {
		try {
			const params = {
				page: newOrderFilters.page,
				perPage: newOrderFilters.rowsPerPage,
				since: newOrderFilters.since,
				until: newOrderFilters.until,
				status: newOrderFilters.status,
				isDelivery: newOrderFilters.isDelivery,
				search: newOrderFilters.search,
				withError: newOrderFilters.withError,
				sellerCode: newOrderFilters.sellerCode,
				role: localStorage.getItem("role"),
			};
			const keepEmptyResult =
				newOrderFilters.search ||
				newOrderFilters.sellerCode ||
				newOrderFilters.status ||
				newOrderFilters.isDelivery !== null ||
				newOrderFilters.withError;
			const data: OrdersDto = await smCommerceManagementApi.getOrders(
				buildURLWithParams(params)
			);
			const orderList: ListOrderModel[] = ListOrderMapper.SMToListOrderModel(
				data.Items
			);

			if (orderList.length >= 1) {
				setOrdersList(orderList);
				setMaxCount(data.MaxCount);
				setLoading(false);
				return;
			}

			if (keepEmptyResult) {
				setOrdersList(orderList);
				setMaxCount(data.MaxCount);
				setLoading(false);
				return;
			}

			if (newOrderFilters.dateType === DateType.Today) {
				newOrderFilters.dateType = DateType.SevenDays;
				newOrderFilters.since = getSinceFilter(DateType.SevenDays);
				setOrderFilters(newOrderFilters);
				getOrders(newOrderFilters);
				return;
			}

			if (newOrderFilters.dateType === DateType.SevenDays) {
				newOrderFilters.dateType = DateType.ThirtyDays;
				newOrderFilters.since = getSinceFilter(DateType.ThirtyDays);
				setOrderFilters(newOrderFilters);
				getOrders(newOrderFilters);
				return;
			}

			setOrdersList(orderList);
			setMaxCount(data.MaxCount);
			setLoading(false);
			return;
		} catch (error) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
		}
	};

	return {
		loading,
		setLoading,
		currentSeller,
		setCurrentSeller,
		ordersList,
		maxCount,
		orderFilters,
		modality,
		setModality,
		setOrderFilters,
		// Functions
		changePage,
		changeRowsPerPage,
		updateFilters,
		getOrders,
		openModal,
	};
}
