import React, { useState, useEffect } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import OrderDetails from "./OrderDetails";
import OrdersContext from "./providers/OrdersContext";
import OrderHistory from "./OrderHistory";
import OrderInvoice from "./OrderInvoice";
import CreateInvoice from "./CreateInvoice";
import { useConfig } from "../../providers/ConfigProvider";
import "./OrdersStyle.scss";
import LoadDashboard from "../Dashboard/components/LoadDashboard";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function Orders(props) {
	const { mainLoading } = useConfig();
	const [id, setId] = useState(props.match.params.id);
	const [detail, setDetail] = useState(true);
	const [revenues, setRevenues] = useState(false);
	const [historic, setHistoric] = useState(false);
	const [loadingInvoice, setLoadingInvoice] = useState(false);
	useEffect(() => {
		setLoadingInvoice(true);
		setTimeout(() => {
			setLoadingInvoice(false);
		}, 3000);
	}, [detail, revenues, historic]);

	const getDetail = () => {
		setDetail(true);
		setRevenues(false);
		setHistoric(false);
	};
	const getRevenues = () => {
		setDetail(false);
		setRevenues(true);
		setHistoric(false);
	};
	const getHistoric = (bool) => {
		setDetail(false);
		setRevenues(false);
		setHistoric(true);
	};

	return (
		<>
			{mainLoading ? (
				<LoadDashboard />
			) : (
				<OrdersContext>
					<div className="container">
						<div className="detail">
							<div className="detail-tabs">
								<button
									disabled={loadingInvoice ? true : false}
									onClick={getDetail}
									className={
										detail === true
											? "actived"
											: loadingInvoice
											? "loading"
											: null
									}
								>
									Detalhes
								</button>
								<button
									disabled={loadingInvoice ? true : false}
									onClick={getRevenues}
									className={
										revenues === true
											? "actived"
											: loadingInvoice
											? "loading"
											: null
									}
								>
									Faturamento
								</button>

								<button
									disabled={loadingInvoice ? true : false}
									onClick={getHistoric}
									className={
										historic === true
											? "actived"
											: loadingInvoice
											? "loading"
											: null
									}
								>
									Histórico
								</button>
							</div>
							<CreateInvoice loadingInvoice={loadingInvoice} />
						</div>
						{detail === true && <OrderDetails id={id} historic={getHistoric} />}
						{revenues === true && (
							<OrderInvoice
								ordersId={id}
								loadingInvoice={loadingInvoice}
								revenues={getRevenues}
							/>
						)}
						{historic === true && <OrderHistory />}{" "}
					</div>
				</OrdersContext>
			)}
		</>
	);
}
