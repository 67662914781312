import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { APIV2 } from "../../../../../API";

export const PromotionProvider = createContext({});

export default function PromotionContext(props) {
	const [loading, setLoading] = useState(false);
	const [disable, setDisable] = useState(false);
	const [promotions, setPromotions] = useState([]);
	const [rules, setRules] = useState([]);
	const [promotionId, setPromotionId] = useState("");
	const [mechanic, setMechanic] = useState({
		tempId: "",
		applyFromCartItemsQuantity: false,
		cartItemQuantity: "",
		discountPercentage: "",
		discountValue: "",
		maxValueItem: "",
		mechanicType: "",
		quantityThatWillReceiveDiscount: "0",
	});

	useEffect(() => {
		setLoading(true);
		setDisable(true);
		//getPromotions();
	}, []);

	const getPromotions = () => {
		APIV2.get("management/marketing/promotions")
			.then(async (response) => {
				setPromotions(response.data);
				setLoading(false);
			})
			.catch((error) => {
				if (promotions.length <= 0) {
					setLoading(false);
				} else {
					console.log(error);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				}
			});
	};

	const createPromotion = (promotions) => {
		setTimeout(() => {}, 3000);
		if (promotions.id) {
			APIV2.put(`management/marketing/promotions`, promotions)
				.then(async (response) => {
					toast.success("Promoçao editado com sucesso! 😃");
					setLoading(false);
					setDisable(false);
				})
				.catch((error) => {
					if (error.response.data) {
						toast.error(error.response.data.message);
					} else {
						console.log(error);
						toast.error(
							"Parece que tivemos um erro... Tente novamente mais tarde."
						);
					}
					setLoading(false);
					setDisable(false);
				});
		} else {
			APIV2.post(`management/marketing/promotions`, promotions)
				.then(async (response) => {
					toast.success("Promoçao criada com sucesso! 😃");
					setLoading(false);
					setDisable(false);
				})
				.catch((error) => {
					if (error.response.data) {
						toast.error(error.response.data.message);
					} else {
						console.log(error);
						toast.error(
							"Parece que tivemos um erro... Tente novamente mais tarde."
						);
					}
					setLoading(false);
					setDisable(false);
				});
		}
	};

	return (
		<PromotionProvider.Provider
			value={{
				loading,
				disable,
				promotions,
				promotionId,
				disable,
				setLoading,
				setDisable,
				setPromotions,
				setPromotionId,
				createPromotion,
				setDisable,
				getPromotions,
				rules,
				setRules,
			}}
		>
			{props.children}
		</PromotionProvider.Provider>
	);
}

export const usePromotion = () => useContext(PromotionProvider);
