import BaseApi from "./BaseApi";
import { HttpMethod } from "../../domain/enums/HttpMethodType";
import { CategoryDto } from "../../domain/dtos/categories/CategoryDto";
import { AttributesDto } from "../../domain/dtos/products/attributes/AttributesDto";
interface ICatalogManagementApi {
	getCategoryList: () => Promise<CategoryDto[]>;
	createCategory: (data: CategoryDto) => Promise<boolean>;
	updateCategory: (data: CategoryDto, categoryId: string) => Promise<boolean>;
	deleteCategory: (categoryId: string) => Promise<boolean>;
	updateReorderedCategories: (data: CategoryDto[]) => Promise<boolean>;
	getAttributes: (skuId: string) => Promise<AttributesDto[]>;
	deleteAttributes: (skuId: string, attributeId: string) => Promise<boolean>;
	upsertAttributes: (skuId: string, data: AttributesDto[]) => Promise<boolean>;
}

class CatalogManagementApi extends BaseApi implements ICatalogManagementApi {
	async getCategoryList(): Promise<CategoryDto[]> {
		let resp = await this.request<CategoryDto[]>(
			`categories/all`,
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status))
			throw new Error("Failed to get categories");

		return resp.data;
	}

	async createCategory(data: CategoryDto): Promise<boolean> {
		let resp = await this.request(`categories`, HttpMethod.Post, data);

		return this.isResponseSuccessful(resp.status);
	}

	async updateCategory(
		data: CategoryDto,
		categoryId: string
	): Promise<boolean> {
		let resp = await this.request(
			`categories/${categoryId}`,
			HttpMethod.Put,
			data
		);

		return this.isResponseSuccessful(resp.status);
	}

	async deleteCategory(categoryId: string): Promise<boolean> {
		let resp = await this.request(
			`categories/${categoryId}`,
			HttpMethod.Delete
		);

		return this.isResponseSuccessful(resp.status);
	}

	async updateReorderedCategories(data: CategoryDto[]): Promise<boolean> {
		let resp = await this.request(`categories/all`, HttpMethod.Put, data);

		return this.isResponseSuccessful(resp.status);
	}

	async getAttributes(skuId: string): Promise<AttributesDto[]> {
		let resp = await this.request<AttributesDto[]>(
			`skus/${skuId}/attributes`,
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status))
			throw new Error("Could not get attributes.");

		return resp.data;
	}

	async deleteAttributes(skuId: string, attributeId: string): Promise<boolean> {
		let resp = await this.request(
			`skus/${skuId}/attributes/${attributeId}`,
			HttpMethod.Delete
		);

		return this.isResponseSuccessful(resp.status);
	}

	async upsertAttributes(
		skuId: string,
		data: AttributesDto[]
	): Promise<boolean> {
		let resp = await this.request(
			`skus/${skuId}/attributes`,
			HttpMethod.Put,
			data
		);

		return this.isResponseSuccessful(resp.status);
	}
}

export const catalogManagementApi: ICatalogManagementApi =
	new CatalogManagementApi("catalog/");
