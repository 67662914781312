export enum OrderStatus {
	Created = "Created",
	WaitingApproval = "WaitingApproval",
	WaitingSeparation = "WaitingSeparation",
	WaitingInvoice = "WaitingInvoice",
	WaitingDispatch = "WaitingDispatch",
	WaitingDelivery = "WaitingDelivery",
	WaitingReturnGoods = "WaitingReturnGoods",
	ReturningGoods = "ReturningGoods",
	Finalized = "Finalized",
	WaitingCancel = "WaitingCancel",
	Canceled = "Canceled",
}
