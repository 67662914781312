import { HttpMethod } from "../../../domain/enums/HttpMethodType";
import { TCReceiveItems } from "../../../domain/models/troquecommerce/Receiveitems/TCReceiveItems";
import { TCList } from "../../../domain/models/troquecommerce/TCList";
import { TCOrder } from "../../../domain/models/troquecommerce/TCOrder";
import BaseApi from "../BaseApi";

interface ITroqueCommerceApi {
	getTCOrderList: (query: string) => Promise<TCList>;
	getTCOrderById: (tcOrderId: string) => Promise<TCOrder>;
	postReceiveItems: (receiveItems: TCReceiveItems) => Promise<boolean>;
}

class TroqueCommerceApi extends BaseApi implements ITroqueCommerceApi {
	async getTCOrderList(query: string): Promise<TCList> {
		const clientId = localStorage.getItem("client_id");
		if (!clientId) throw new Error("Unavailable clientId");
		const body = {
			domain: process.env.REACT_APP_TROQUECOMMERCE,
			resource: `api/public/order/list?${query}`,
			subject: "troquecommerce",
			method: "get",
		};
		const resp = await this.request<TCList>(
			`proxy/${clientId}`,
			HttpMethod.Post,
			body
		);
		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error("Could not get Troque Commerce order list");
		}
		return resp.data;
	}

	async getTCOrderById(tcOrderId: string): Promise<TCOrder> {
		const clientId = localStorage.getItem("client_id");
		if (!clientId) throw new Error("Unavailable clientId");
		const body = {
			domain: process.env.REACT_APP_TROQUECOMMERCE,
			resource: `api/public/order/?id=${tcOrderId}`,
			subject: "troquecommerce",
			method: "get",
		};
		const resp = await this.request<TCOrder>(
			`proxy/${clientId}`,
			HttpMethod.Post,
			body
		);
		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error("Could not get Troque Commerce order");
		}
		return resp.data;
	}

	async postReceiveItems(receiveItems: TCReceiveItems): Promise<boolean> {
		const clientId = localStorage.getItem("client_id");
		if (!clientId) throw new Error("Unavailable clientId");
		const body = {
			domain: process.env.REACT_APP_TROQUECOMMERCE,
			resource: "api/public/order/receive",
			subject: "troquecommerce",
			method: "post",
			body: JSON.stringify(receiveItems),
		};
		const resp = await this.request<TCOrder>(
			`proxy/${clientId}`,
			HttpMethod.Post,
			body
		);

		return this.isResponseSuccessful(resp.status);
	}
}

export const troqueCommerceApi: ITroqueCommerceApi = new TroqueCommerceApi(
	"im/"
);
