import React from "react";
import { ActionLogLevel } from "../../../../domain/enums/LegacyOrders/ActionLogLevel";
import { ActionLogType } from "../../../../domain/enums/LegacyOrders/ActionLogType";
import { LegacyOrderActionLogDto } from "../../../../domain/dtos/cpCommerceManagement/LegacyOrderActionLogDto";
import { formatDate } from "../../../../infrastructure/utils/FormatMask";
import { Avatar, SxProps, Theme } from "@mui/material";
import {
	InfoOutlined,
	PaymentsOutlined,
	PolicyOutlined,
	ReceiptOutlined,
} from "@mui/icons-material";
import CustomIcon from "../../../../MUICustomComponents/CustomIcon";
import { paletteObject } from "../../../../theme/foundations/palette";
import { i18n } from "../../../../translate/i18n";

type Props = {
	actionLogList: LegacyOrderActionLogDto[];
};

export default function HistorySteps({ actionLogList }: Props) {
	const avatarDefaultProps: SxProps<Theme> = {
		position: "absolute",
		width: "30px",
		height: "30px",
		left: "20px",
	};

	const getStepLevelColor = (item: LegacyOrderActionLogDto): string => {
		switch (item.level) {
			case ActionLogLevel.Info:
				return paletteObject.info.main;
			case ActionLogLevel.Warning:
				return paletteObject.warning.main;
			case ActionLogLevel.Success:
				return paletteObject.success.main;
			case ActionLogLevel.Error:
				return paletteObject.error.main;
			case ActionLogLevel.None:
				return paletteObject.grey80.main;
			default:
				return paletteObject.grey80.main;
		}
	};

	const getStepIcon = (item: LegacyOrderActionLogDto): JSX.Element => {
		switch (item.type) {
			case ActionLogType.Payment:
				return <PaymentsOutlined />;
			case ActionLogType.Fraud:
				return <PolicyOutlined />;
			case ActionLogType.Information:
				return <InfoOutlined />;
			case ActionLogType.None:
			case ActionLogType.Order:
			default:
				return <ReceiptOutlined />;
		}
	};

	const getStepTitle = (item: LegacyOrderActionLogDto): string | undefined => {
		if (item?.title) return item.title;
		let title: string;
		switch (item.type) {
			case ActionLogType.Payment:
				title = "Payment";
				break;
			case ActionLogType.Fraud:
				title = "Fraud";
				break;
			case ActionLogType.Information:
				title = "Information";
				break;
			case ActionLogType.None:
			case ActionLogType.Order:
			default:
				title = "Order";
		}
		if (
			item.type !== ActionLogType.Information &&
			item.level === ActionLogLevel.Error
		) {
			title = `${title}Error`;
		}
		return i18n.t(`cpActionLogTitle.${title}`);
	};

	const getStepDate = (item: LegacyOrderActionLogDto): string => {
		return formatDate(item.lastUpdate ?? item.createDate);
	};

	const showAditionalInfoLink = (
		item: LegacyOrderActionLogDto
	): JSX.Element | undefined => {
		if (!item.urlLog) return undefined;
		return (
			<button
				className="btn btn-link btn-icon-r pt-0"
				style={{ color: "#c4c4c4", fontSize: "12px" }}
				onClick={() => (window.location.href = `${item.urlLog}`)}
			>
				<i className="material-icons" style={{ fontSize: "16px" }}>
					get_app
				</i>
				Informações Adicionais
			</button>
		);
	};

	return (
		<div style={{ borderLeft: "3px solid #E9ECEC", marginLeft: "-3px" }}>
			{actionLogList.map((item) => (
				<section className="section-step" key={item.id}>
					<Avatar
						sx={{
							...avatarDefaultProps,
							bgcolor: getStepLevelColor(item),
						}}
					>
						<CustomIcon icon={getStepIcon(item)} variant="small" />
					</Avatar>

					<h2 className="card-title">
						{getStepTitle(item)}
						<label className="card-historic-description">
							{getStepDate(item)}
						</label>
					</h2>

					<div className="card" style={{ width: "400px", boxShadow: "none" }}>
						<div className="card-historic">
							<label className="card-historic-title">
								{item.displayMessage}
							</label>
							<hr style={{ width: "90%", margin: "0 auto" }} />

							<div className="d-flex justify-content-between pr-2">
								<label className="card-historic-description">
									via {item.issuer == null ? "Plataforma Lori" : item.issuer}
								</label>
								{showAditionalInfoLink(item)}
							</div>
						</div>
					</div>
				</section>
			))}
		</div>
	);
}
