import React from "react";
import Skeleton from "./Skeleton";
import { useOrders } from "../../providers/OrdersContext";
import {
	formatDateStringOnly,
	formatZipCode,
} from "../../../../infrastructure/utils/FormatMask";
import { i18n } from "../../../../translate/i18n";

export default function ShippingType() {
	const { order, loading } = useOrders();

	const getDeliveryTime = (index) => {
		const typeArrowDict = {
			Minute: i18n.t("orderDetails.General.Minute"),
			Hour: i18n.t("orderDetails.General.Hour"),
			CalendarDay: i18n.t("orderDetails.General.CalendarDay"),
			BusinessDay: i18n.t("orderDetails.General.BusinessDay"),
		};
		return (
			typeArrowDict[index] || i18n.t("orderDetails.OrderFlowInfo.Uninformed")
		);
	};

	const showAddress = (isDelivery) => {
		let name = isDelivery ? order?.Buyer?.FullName : order?.Seller?.CompanyName;
		let addressData = isDelivery
			? order?.ShippingData?.DeliveryAddress
			: order?.Seller?.Address;

		return (
			<>
				<div className="order-header-box pt-2">
					<h2>{i18n.t("orderDetails.General.Recipient")}</h2>
				</div>
				<div className="order-body-box m-0 p-0 pt-2">
					<p className="text-blueish">
						<strong>{name}</strong>
					</p>
					<p className="text-blueish">
						{addressData?.Street} {addressData?.Number}{" "}
						{addressData?.Complement}
					</p>

					<p className="text-blueish">
						{addressData?.Neighborhood} {addressData?.City} {addressData?.State}
					</p>

					<p className="text-blueish">
						{i18n.t("orderDetails.General.ZipCode")}:{" "}
						{formatZipCode(addressData?.ZipCode)}
					</p>
				</div>
			</>
		);
	};

	return (
		<div className="order-main-box">
			{loading ? (
				<Skeleton />
			) : (
				<>
					<div className="order-header-box">
						<h2>{i18n.t("orderDetails.General.ShippingType")}</h2>
					</div>

					<div className="order-body-box m-0 p-0 pt-2">
						<p className="text-blueish">
							<strong>{i18n.t("orderDetails.General.ShippingType")}: </strong>
							{order?.IsDelivery
								? order?.ShippingData?.Shipment?.ShippingReference
								: i18n.t("orderDetails.General.PickUpInStore")}
						</p>

						{order?.IsDelivery ? (
							<>
								<p className="text-blueish">
									<strong>
										{i18n.t("orderDetails.General.CarrierName")}:{" "}
									</strong>
									{order?.ShippingData?.Shipment?.CarrierName}
								</p>

								<p className="text-blueish">
									<strong>
										{i18n.t("orderDetails.General.DeliveryTime")}:{" "}
									</strong>
									{order?.ShippingData?.Shipment?.DeliveryTime}{" "}
									{getDeliveryTime(
										order?.ShippingData?.Shipment?.DeliveryTimeUnit
									)}
								</p>

								{order?.ShippingData?.Shipment?.EstimatedDeliveryDate && (
									<p className="text-blueish">
										<strong>
											{i18n.t("orderDetails.General.EstimatedDeliveryDate")}:{" "}
										</strong>
										{formatDateStringOnly(
											order?.ShippingData?.Shipment?.EstimatedDeliveryDate
										)}
									</p>
								)}

								{showAddress(order?.IsDelivery)}
							</>
						) : (
							<>
								<p className="text-blueish">
									<strong>{i18n.t("orderDetails.General.Address")}: </strong>
									{order?.Seller?.Address?.Street}
									{", "}
									{order?.Seller?.Address?.Number}
								</p>
								<p className="text-blueish">
									{order?.Seller?.Address?.Neighborhood}
									{" - "}
									{order?.Seller?.Address?.City}
									{" - "}
									{order?.Seller?.Address?.State}
								</p>
								<p className="text-blueish">
									{i18n.t("orderDetails.General.ZipCode")}:{" "}
									{order?.Seller?.Address?.ZipCode}
								</p>
							</>
						)}
					</div>
				</>
			)}
		</div>
	);
}
