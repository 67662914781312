import React, { Component } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DOMPurify from "dompurify";
export default class EditorHtml extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<CKEditor
				editor={ClassicEditor}
				className="editor"
				height="200"
				style={{ height: "100px" }}
				data={
					this.props.data != null || this.props.data != undefined
						? DOMPurify.sanitize(this.props.data)
						: ""
				}
				onChange={(event, editor) => {
					const data = editor.getData();

					this.props.change(DOMPurify.sanitize(data));
				}}
			/>
		);
	}
}
