import React, { useEffect } from "react";
import VouchersForm from "./components/VouchersForm";
import VouchersContext from "./providers/VouchersContext";
import { useConfig } from "./../../../../providers/ConfigProvider";
import LoadDashboard from "../../../Dashboard/components/LoadDashboard";
import "./Vouchers.scss";

export default function NewVouchers(props) {
	const { mainLoading } = useConfig();
	return (
		<>
			{mainLoading ? (
				<LoadDashboard />
			) : (
				<div className="container dash-content">
					<div className="card">
						<VouchersContext>
							<VouchersForm id={props.match.params.id} />
						</VouchersContext>
					</div>
				</div>
			)}
		</>
	);
}
