import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import { i18n } from "../../../../translate/i18n";
import HelpTooltip, {
	HelpTooltipConfig,
} from "../../../../MUICustomComponents/HelpTooltip";
import { paletteObject } from "../../../../theme/foundations/palette";

type Props = {
	id: string;
	isChecked: boolean;
	onChange: (
		event: React.ChangeEvent<HTMLInputElement>,
		checked?: boolean
	) => void;
	disabled?: boolean;
	helpTooltip?: HelpTooltipConfig;
};

export default function RetailerCheckbox({
	id,
	isChecked,
	onChange,
	disabled = false,
	helpTooltip,
}: Props) {
	return (
		<FormControlLabel
			disabled={disabled}
			sx={{ marginBottom: 0, marginRight: 0 }}
			control={
				<Checkbox
					name={id}
					checked={isChecked}
					onChange={onChange}
					{...(disabled && { style: { color: paletteObject.grey50.main } })}
				/>
			}
			label={
				<CustomTypography
					variant="default"
					{...(disabled && { color: "grey50" })}
				>
					{i18n.t(`retailerConfig.${id}`).toString()}
					{helpTooltip && <HelpTooltip helpTooltip={helpTooltip} />}
				</CustomTypography>
			}
		/>
	);
}
