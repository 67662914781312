import React, { useState, useEffect } from "react";
import ContentLoader from "react-content-loader";
import SingleDatePicker from "../../components/SingleDatePicker";
import { APIV2 } from "../../API";
import { useOrders } from "./providers/OrdersContext";
import { useForm, Controller } from "react-hook-form";
import DropzoneInvoice from "./components/DropzoneInvoice";
import NewToastComponent from "../../components/NewToastComponent";

export default function OrderInvoice(props) {
	const { invoice, getOrders, loading, setLoading, status } = useOrders();
	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			number: invoice.number,
			serieNumber: invoice.serieNumber === 0 ? "" : invoice.serieNumber,
			key: invoice.key,
			issuanceDate:
				invoice.issuanceDate === "0001-01-01T00:00:00"
					? ""
					: invoice.issuanceDate,
		},
	});
	const [singleDate, setSingleDate] = useState("");
	const [formDropFile, setFormDropFile] = useState({
		fileUrl: invoice.fileUrl,
		xmlUrl: invoice.xmlUrl,
	});
	const [enableFieldsForm, setEnableFieldsForm] = useState(false);
	const [enableButtonForm, setEnableButtonForm] = useState(false);
	const [disableDropForm, setDisableDropForm] = useState(false);
	const [isUploadInvoice, setIsUploadInvoice] = useState(false);

	const skeleRight = [0, 1, 2];
	const skeleLeft = [0];
	const skeleButtonInvoice = [0];
	const skeleButtonInvoiceModal = [0];

	useEffect(() => {
		if (
			//status === 9 || //Finalized
			status === 10 || //CanceledBySeller
			status === 11 || //CanceledByBuyer
			status === 12 || //CanceledByAdmin
			status === 21 //CanceledByProcessor
		) {
			invoice.issuanceDate === "0001-01-01T00:00:00"
				? setSingleDate("")
				: setSingleDate(invoice.issuanceDate);
			setEnableButtonForm(false);
		} else {
			setDisableDropForm(true);
			reset({
				...invoice,
				issuanceDate:
					invoice.issuanceDate === "0001-01-01T00:00:00"
						? ""
						: invoice.issuanceDate,
				serieNumber: invoice.serieNumber === 0 ? "" : invoice.serieNumber,
			});

			if (invoice.fileUrl) {
				//console.log("Quando tem apenas o pdf");

				invoice.issuanceDate !== "0001-01-01T00:00:00" &&
					setSingleDate(invoice.issuanceDate);
			} else if (!invoice.xmlUrl && invoice.fileUrl) {
				//console.log("Sem xml/Com pdf");
				setSingleDate("");
				// invoice.issuanceDate !== "0001-01-01T00:00:00" &&
				// 	setSingleDate(invoice.issuanceDate);
			} else if (!invoice.fileUrl && !invoice.xmlUrl) {
				setSingleDate("");
				//console.log("Sem nenhum arquivo");
				invoice.issuanceDate !== "0001-01-01T00:00:00" &&
					setSingleDate(invoice.issuanceDate);
			} else if (!invoice.xmlUrl) {
				//console.log("Sem xml e pdf");
				invoice.issuanceDate !== "0001-01-01T00:00:00" &&
					setSingleDate(invoice.issuanceDate);
			} else {
				//console.log("Caiu na diferença");
				invoice.issuanceDate !== "0001-01-01T00:00:00" &&
					setSingleDate(invoice.issuanceDate);
			}

			setFormDropFile({
				fileUrl: invoice.fileUrl,
				xmlUrl: invoice.xmlUrl,
			});

			if (
				invoice.number &&
				invoice.serieNumber &&
				invoice.key &&
				invoice.issuanceDate
				//invoice.xmlUrl
			) {
				setEnableFieldsForm(false);
				setEnableButtonForm(true);
			} else if (
				invoice.number &&
				invoice.serieNumber &&
				invoice.key &&
				invoice.issuanceDate
				//&& invoice.fileUrl
			) {
				setEnableFieldsForm(false);
				setEnableButtonForm(true);
			} else if (
				!invoice.number &&
				!invoice.serieNumber &&
				!invoice.key &&
				!invoice.issuanceDate &&
				(!invoice.fileUrl || !invoice.xmlUrl)
			) {
				setEnableFieldsForm(true);
				setEnableButtonForm(true);
			} else {
				setEnableFieldsForm(true);
				setEnableButtonForm(true);
			}
		}
	}, [invoice, status]);

	const handleformDropFile = (formDropFileData) => {
		formDropFileData.fileUrl &&
			setFormDropFile({ ...formDropFile, fileUrl: formDropFileData.fileUrl });
		formDropFileData.xmlUrl &&
			setFormDropFile({ ...formDropFile, xmlUrl: formDropFileData.xmlUrl });
	};

	const handleFormSubmit = (formFieldData) => {
		// if (!formDropFile.fileUrl && !formDropFile.xmlUrl) {
		// 	setIsUploadInvoice(true);
		// } else {
		setIsUploadInvoice(false);
		setLoading(true);
		const formInvoice = {
			id: props.ordersId,
			invoice: {
				key: formFieldData.key,
				number: formFieldData.number,
				serieNumber: parseInt(formFieldData.serieNumber),
				xmlUrl: formDropFile.xmlUrl && formDropFile.xmlUrl,
				fileUrl: formDropFile.fileUrl && formDropFile.fileUrl,
				issuanceDate: formFieldData.issuanceDate,
				id: formFieldData.id,
			},
		};
		APIV2.put(
			`management/commerce/orders/${props.ordersId}/invoicedetails`,
			formInvoice
		)
			.then(async (response) => {
				if (props.isModal) {
					const button = document.getElementById("invoiceModal");
					button.click();
					closeInvoice();
				}

				if (props.isModal) {
					await APIV2.put(
						`management/commerce/orders/${props.ordersId}/status/Finalized`
					)
						.then(async (response) => {
							getOrders(props.ordersId);
						})
						.catch((err) => {
							NewToastComponent({
								status: "error",
								title: "Parece que tivemos um erro... Tente novamente mais tarde.",
							});
						});
				} else {
					getOrders(props.ordersId);
				}

				NewToastComponent({
					status: "success",
					title: "Nota fiscal enviada com successo.",
				});
			})
			.catch(() => {
				NewToastComponent({
					status: "error",
					title: "Parece que tivemos um erro... Tente novamente mais tarde.",
				});
				setLoading(false);
			});
		//}
	};

	const closeInvoice = () => {
		document.querySelector("#invoiceModal").classList.remove("show");
	};

	const enableUploadInvoice = (bool) => {
		setIsUploadInvoice(bool);
	};

	const renderLoadingRight = () => {
		return skeleRight.map((index) => (
			<ContentLoader
				key={index}
				speed={2}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 100"
				style={{ width: "100%" }}
			>
				<rect x="10" y="25" rx="3" ry="3" width="1000" height="150" />
			</ContentLoader>
		));
	};

	const renderLoadingLeft = () => {
		return skeleLeft.map((index) => (
			<ContentLoader
				key={index}
				speed={2}
				width={1100}
				height={150}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 250"
				style={{ width: "100%" }}
			>
				<rect x="10" y="5" rx="3" ry="3" width="1000" height="250" />
			</ContentLoader>
		));
	};

	const renderLoadingButtonInvoice = () => {
		return skeleButtonInvoice.map((index) => (
			<ContentLoader
				key={index}
				speed={2}
				width={1100}
				height={80}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="10 10 1000 50"
				style={{ width: "100%" }}
			>
				<rect x="15" y="5" rx="1" ry="10" width="210" height="100" />
			</ContentLoader>
		));
	};

	const renderLoadingButtonInvoiceModal = () => {
		return skeleButtonInvoiceModal.map((index) => (
			<ContentLoader
				key={index}
				speed={2}
				width={1100}
				height={80}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="10 10 1000 50"
				style={{ width: "100%" }}
			>
				<rect x="15" y="5" rx="1" ry="10" width="210" height="100" />
				<rect x="235" y="5" rx="1" ry="10" width="210" height="100" />
			</ContentLoader>
		));
	};

	return (
		<div
			className={!props.isModal ? "card mb-3" : ""}
			id="invoiceModal"
			aria-labelledby="invoiceModal"
		>
			<div className="card-header">
				<h4 className="card-title mb-3">Nota Fiscal</h4>
				<label
					className="p-0 m-0"
					style={{ fontSize: "14px", color: "#747E8B" }}
				>
					Preencha corretamente as informações da nota fisca e realize o
					faturamento para avançar o status do seu pedido
				</label>
			</div>
			<form
				onKeyPress={(event) => {
					event.key === "Enter" && event.preventDefault();
				}}
				onSubmit={handleSubmit(handleFormSubmit)}
			>
				<div className="invoice-orders">
					<div className="w-100 m-0 p-0 pr-2">
						{loading ? (
							renderLoadingRight()
						) : (
							<>
								<div className="neo-form-row row m-0 p-0">
									{/* number */}
									<div className="col-7 m-0 p-0 pr-2">
										<label htmlFor="number">
											<strong>Número da nota</strong>
										</label>
										<div className="neo-form">
											<div className="neo-form-input">
												<input
													className={`${
														errors.number
															? "neo-input-invalid"
															: "neo-input-valid"
													}`}
													id="number"
													disabled={enableFieldsForm ? false : true}
													name="number"
													placeholder="Clique para preencher"
													{...register("number", { required: true })}
												/>
												{errors.number && (
													<div className="neo-icons-required">
														<button type="button">
															<i className="material-icons">error_outline</i>
														</button>
													</div>
												)}
											</div>
										</div>
										{errors.number && (
											<small className="text-danger">
												<em>Informação obrigatória!</em>
											</small>
										)}
									</div>
									{/* serieNumber */}
									<div className="col-5 m-0 p-0">
										<label htmlFor="serieNumber">
											<strong>Série</strong>
										</label>
										<div className="neo-form">
											<div className="neo-form-input">
												<input
													className={`${
														errors.serieNumber
															? "neo-input-invalid"
															: "neo-input-valid"
													}`}
													id="serieNumber"
													disabled={enableFieldsForm ? false : true}
													name="serieNumber"
													placeholder="Clique para preencher"
													{...register("serieNumber", { required: true })}
												/>
												{errors.serieNumber && (
													<div className="neo-icons-required">
														<button type="button">
															<i className="material-icons">error_outline</i>
														</button>
													</div>
												)}
											</div>
										</div>
										{errors.serieNumber && (
											<small className="text-danger">
												<em>Informação obrigatória!</em>
											</small>
										)}
									</div>
								</div>
								<div className="neo-form-row row m-0 p-0">
									{/* key */}
									<div className="col-12 m-0 p-0">
										<label htmlFor="key">
											<strong>Chave</strong>
										</label>
										<div className="neo-form">
											<div className="neo-form-input">
												<input
													className={`${
														errors.key ? "neo-input-invalid" : "neo-input-valid"
													}`}
													id="key"
													disabled={enableFieldsForm ? false : true}
													name="key"
													placeholder="Clique para preencher"
													{...register("key", { required: true })}
												/>
												{errors.key && (
													<div className="neo-icons-required">
														<button type="button">
															<i className="material-icons">error_outline</i>
														</button>
													</div>
												)}
											</div>
										</div>
										{errors.key && (
											<small className="text-danger m-0 p-0">
												<em>Informação obrigatória!</em>
											</small>
										)}
									</div>
								</div>
								<div className="neo-form-row row m-0 p-0">
									{/* issuanceDate */}
									<div className="col-6 m-0 p-0">
										<label htmlFor="issuanceDate">
											<strong>Data de emissão:</strong>
										</label>
										<div className="neo-form">
											<div className="neo-form-date">
												<Controller
													control={control}
													name="issuanceDate"
													{...register("issuanceDate", { required: true })}
													render={({ field }) => (
														<SingleDatePicker
															onChange={(e) => field.onChange(e)}
															error={errors.issuanceDate}
															upateDate={singleDate}
															disabled={enableFieldsForm}
															isModal={props.isModal}
														/>
													)}
												/>
											</div>
											{errors.issuanceDate && (
												<small className="text-danger">
													<em>Informação obrigatória!</em>
												</small>
											)}
										</div>
									</div>
								</div>
							</>
						)}
					</div>

					<div className="w-100 m-0 p-0 pl-2">
						{loading ? (
							renderLoadingLeft()
						) : (
							<DropzoneInvoice
								id={props.ordersId}
								onChange={handleformDropFile}
								formDropFile={formDropFile}
								isUploadInvoice={isUploadInvoice}
								enableUploadInvoice={enableUploadInvoice}
								disableDropForm={disableDropForm}
							/>
						)}
					</div>
				</div>
				<div className="m-0 p-0 ">
					<div className="d-flex justify-content-start p-4">
						{loading ? (
							<>
								{!props.isModal ? (
									<>{renderLoadingButtonInvoice()}</>
								) : (
									<>{renderLoadingButtonInvoiceModal()}</>
								)}
							</>
						) : (
							<>
								{enableButtonForm ? (
									<button
										type="submit"
										className={"btn btn-success mr-2"}
										style={{ padding: "10px 20px", width: "190px" }}
									>
										Faturar Pedido
									</button>
								) : (
									<button
										type="button"
										className={"btn btn btn-light-outline"}
										style={{
											padding: "10px 20px",
											width: "190px",
											color: "#BCBEC0",
										}}
									>
										Faturar Pedido
									</button>
								)}

								{props.isModal && (
									<button
										type="button"
										className="btn btn-light outline ml-2"
										data-dismiss="modal"
										style={{
											padding: "10px 20px",
											width: "190px",
											color: "#BCBEC0",
										}}
									>
										Cancelar
									</button>
								)}
							</>
						)}
					</div>
				</div>
			</form>
		</div>
	);
}
