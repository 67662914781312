import { RolesAccess } from "../../domain/enums/RolesAccess";

export function roleAccessFinancial(pagarMe, pagarMeForRetailer, role) {
	const usePagarMeDashboard = JSON.parse(pagarMe);
	const usePagarMeDashboardForRetailer = JSON.parse(pagarMeForRetailer);

	const isRetailer = RolesAccess.Retailer === role;
	const isSeller = RolesAccess.Seller === role;

	if (usePagarMeDashboard && usePagarMeDashboardForRetailer && isRetailer) {
		return [RolesAccess.Retailer];
	} else if (usePagarMeDashboard && isSeller) {
		return [RolesAccess.Seller];
	}
	return [];
}
