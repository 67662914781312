import React from "react";
import { i18n } from "../../../../../translate/i18n";

export default function inventoryEmptyState() {
	return (
		<div className="inventory-emptyState w-100 text-center my-4 pb-2">
			<img className="mb-4" src={"/images/empty_inventory.svg"} />
			<h4 className="inventory-emptyState-title mt-2 mb-1">
				{i18n.t("productManagement.EmptyTitle")}
			</h4>
			<span className="inventory-emptyState-text">
				{i18n.t("productManagement.EmptySubTitle")}
			</span>
		</div>
	);
}
