import React from "react";
import Skeleton from "./Skeleton";
import { useOrders } from "../../providers/OrdersContext";
import { i18n } from "../../../../translate/i18n";

export default function General() {
	const { loading, order, role } = useOrders();

	const handleFormatDate = (date) => {
		return new Date(date).toLocaleString("pt-br");
	};

	return (
		<div className="order-main-box">
			{loading ? (
				<Skeleton cardBackground={"black"} hasBadge={true} />
			) : (
				<>
					<div className="order-header-box">
						<h2 style={{ color: "#ffffff" }}>
							{i18n.t("orderDetails.General.Order")}
						</h2>
						<span className="badge badge-grey-60 pb-1">
							Nº {order?.FriendlyCode}
						</span>
					</div>

					<div className="order-body-box m-0 p-0 pt-2">
						{!!order?.CreateDate && (
							<p className="text-white">
								<strong>{i18n.t("orderDetails.General.CreateDate")}: </strong>
								{handleFormatDate(order?.CreateDate)}
							</p>
						)}
						{!!order?.LastUpdate && (
							<p className="text-white">
								<strong>{i18n.t("orderDetails.General.LastUpdate")}: </strong>
								{handleFormatDate(order?.LastUpdate)}
							</p>
						)}
						{order?.SellerOrderId && (
							<p className="text-white">
								<strong>
									{i18n.t("orderDetails.OrderInfo.SellerOrderId")}:{" "}
								</strong>
								{order?.SellerOrderId}
							</p>
						)}
						{!!order?.ExternalId && (
							<p className="text-white">
								<strong>{i18n.t("orderDetails.OrderInfo.ExternalId")}: </strong>
								{order?.ExternalId}
							</p>
						)}
						{role === 8
							? !!order?.ShippingData?.Shipment?.Description && (
									<p className="text-white">
										<strong>
											{i18n.t("orderDetails.Trackings.ShippingType")}:{" "}
										</strong>
										{order?.ShippingData?.Shipment?.Description}
									</p>
							  )
							: order?.IsDelivery !== null && (
									<p className="text-white">
										<strong>{i18n.t("orderDetails.General.Modality")}: </strong>
										{order?.IsDelivery === false
											? i18n.t("orderDetails.General.PickUpInStore")
											: i18n.t("orderDetails.General.ReceiveAtHome")}
									</p>
							  )}
						{!!order?.Origin && (
							<p className="text-white">
								<strong>{i18n.t("orderDetails.General.Channel")}: </strong>
								{order?.Origin}
							</p>
						)}
						{!!order?.AfiliatedCode && (
							<p className="text-white">
								<strong>{i18n.t("orderDetails.General.DealerCode")}: </strong>
								{order?.AfiliatedCode}
							</p>
						)}
					</div>
				</>
			)}
		</div>
	);
}
