/**
 * Formatting rules that will be applied to percentage values
 * Integer values will be limited to three characters
 * Decimal values will have a two-character limit
 * @example
 * percentageRulesCommercialConditions(100000)
 * percentageRulesCommercialConditions(1.5555555)
 * @returns
 * 100
 * 1.55
 * @param percentageValue string that will be applied to the numerical rule
 */

export function percentageRulesCommercialConditions(
	percentageValue: string
): string {
	const percentageSplited = percentageValue.split(".");

	const negativeNumber = percentageValue.startsWith("-");

	const integerPart = percentageSplited[0].slice(0, negativeNumber ? 4 : 3);

	const decimalPart = percentageSplited[1]
		? `.${percentageSplited[1].slice(0, 2)}`
		: "";

	return integerPart + decimalPart;
}
