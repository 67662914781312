import React, { useState } from 'react';

// import { Container } from './styles';

function LastOrder() {
  const [orientation, setOrientation] = useState('list')
  return (
    <>
      <div className="container">
        <div class="row">
          <div className="col-12">
            <p><strong>ORIENTAÇÃO</strong></p>
          </div>
          <div className="col-12 row form-group">
            <div className="col-3 text-center d-flex justify-content-center">
              <div className={orientation === 'list'? "app-editor-orientation orientation-active" : "app-editor-orientation"}>
                <div className="app-editor-orientation-button" onClick={() => setOrientation('list')}>
                  <div className="app-editor-orientation-button">
                    <i class="material-icons" style={{ color: 'white' }}>view_agenda</i>
                  </div>
                </div>
                <div className="app-editor-orientation-text ">
                  Lista
              </div>
              </div>
            </div>
            <div className="col-3 text-center d-flex justify-content-center">
              <div className={orientation === 'carrousel'? "app-editor-orientation orientation-active" : "app-editor-orientation"}>
                <div className="app-editor-orientation-button" onClick={() => setOrientation('carrousel')}>
                  <div className="app-editor-orientation-button">
                    <i class="material-icons text-center" style={{ color: 'white', fontSize: '37px', boxSizing:"border-box", paddingRight: '2px' }}>view_array</i>
                  </div>
                </div>
                <div className="app-editor-orientation-text">
                  Carrossel
              </div>
              </div>
            </div>
          </div>
          {/* <div className="col-12 form-group">

            <label className="form-check-label" htmlFor="carrousel-title">
              Titulo do Carrossel
            </label>
            <input type="text" className="form-control" name="carrousel-title" />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default LastOrder;