import React, { useState, useEffect } from "react";
import ContentLoader from "react-content-loader";
import { useOrders } from "../../providers/OrdersContext";

export default function Payments() {
	const { loading, paymentInfo, newPaymentStatus, paymentList } = useOrders();

	const [count, setCount] = useState(0);
	const [disableCarousel, setDisableCarousel] = useState({
		prev: true,
		next: false,
	});

	useEffect(() => {
		if (count === 0) {
			setDisableCarousel({ prev: true, next: false });
		} else if (count === paymentList.length - 1) {
			setDisableCarousel({ prev: false, next: true });
		} else {
			setDisableCarousel({ prev: false, next: false });
		}
	}, [count]);

	const paymentMethodDict = {
		1: "Cartão de crédito",
		2: "Na retirada",
		3: "Boleto bancário",
		4: "Voucher",
		5: "Cartão de débito",
		6: "PIX",
		7: "Link de pagamento",
		8: "PIX parcelado",
		9: "Cashback",
		10: "Cartão de crédito - Maquininha",
		11: "Cartão de débito - Maquininha",
		12: "Boleto faturado",
	};

	const getPaymentMethodType = (paymentType) => {
		return paymentMethodDict[paymentType] || "Não encontrado";
	};

	const fraudStatusMessage = (fraudStatus) => {
		switch (fraudStatus) {
			case 1:
				return "Em análise";
			case 2:
				return "Aprovado";
			case 3:
				return "Negado";
			case 4:
				return "Em análise manual";
			case 5:
				return "Aguardando criação";
			case 6:
				return "Erro na análise de fraude";
			default:
				return "Não informado";
		}
	};

	const skeleton = () => {
		const skele = [0, 1, 2, 4, 5, 6];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={400}
				height={20}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="5" y="25" rx="3" ry="3" width="400" height="100" />
				<rect x="410" y="26" rx="3" ry="3" width="400" height="100" />
			</ContentLoader>
		));
	};

	const paymentInformation = (paymentObject, colorClass) => {
		return (
			<>
				{paymentObject?.paymentMethod !== null && (
					<p className={`text-${colorClass}`}>
						{paymentObject?.paymentMethod?.info}
					</p>
				)}
				{paymentObject?.amount !== null && (
					<p className={`text-${colorClass}`}>
						<strong>Valor: </strong>
						{`${paymentObject?.amount.toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						})}`}
					</p>
				)}
				{paymentObject?.quantityInstallments !== null && (
					<p className={`text-${colorClass}`}>
						<strong>Parcelas: </strong>
						{`${paymentObject?.quantityInstallments} (${
							paymentObject?.quantityInstallments
						}x de
						${
							paymentObject?.amount &&
							(
								paymentObject?.amount / paymentObject?.quantityInstallments
							).toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
							})
						})`}
					</p>
				)}
				{paymentObject?.acquirerMessage !== null && (
					<p className={`text-${colorClass}`}>
						<strong>Mensagem do adquirente: </strong>
						{paymentObject?.acquirerMessage}
					</p>
				)}
				{fraudStatusMessage(paymentObject?.fraudStatus) !== "Não informado" && (
					<p className={`text-${colorClass}`}>
						<strong>Status anti-fraude: </strong>
						{fraudStatusMessage(paymentObject?.fraudStatus)}
					</p>
				)}
				{paymentObject?.acquirerApprovalCode !== null && (
					<p className={`text-${colorClass}`}>
						<strong>Código de aprovação: </strong>
						{paymentObject?.acquirerApprovalCode}
					</p>
				)}
				{paymentObject?.nsu !== null && (
					<p className={`text-${colorClass}`}>
						<strong>NSU: </strong>
						{paymentObject?.nsu}
					</p>
				)}
			</>
		);
	};

	return (
		<div className="card box-one">
			{loading ? (
				skeleton()
			) : (
				<>
					<div className="box-title m-0 p-0">
						<h2 className="card-title">PAGAMENTO</h2>
						<span className={`payment-order ${newPaymentStatus.color}`}></span>
						<div className={`tooltipOrders ${newPaymentStatus.color}`}>
							{newPaymentStatus.title}
							<span>{newPaymentStatus.name}</span>
						</div>
					</div>
					{
						paymentList.length > 1 ? (
							// Quando possui dois ou mais cartões =>
							<>
								{
									<p className="text-blueish mt-2 mb-3">
										<strong>Método de pagamento: </strong>
										{getPaymentMethodType(
											paymentList[count]?.paymentMethod?.type
										)}
									</p>
								}
								<div
									id="carouselExampleControls"
									className="carousel slide double-cards-carousel"
									data-ride="carousel"
									data-interval="false" // Não trocar automaticamente
									data-wrap="false" // Não fazer um loop
								>
									<ol className="carousel-indicators">
										{paymentList.map((value, index) => (
											<li
												data-target="#carouselExampleIndicators"
												data-slide-to={index}
												className={
													index === 0
														? "carousel-indicators-item active"
														: "carousel-indicators-item"
												}
											></li>
										))}
									</ol>
									<div className="carousel-inner">
										{paymentList.map((value, index) => {
											return (
												<div
													className={
														index === 0
															? "carousel-item active"
															: "carousel-item"
													}
												>
													{paymentInformation(value, "orders")}
												</div>
											);
										})}
									</div>
									<button
										className="carousel-control-prev"
										type="button"
										data-target="#carouselExampleControls"
										data-slide="prev"
										onClick={() => setCount(count - 1)}
										disabled={disableCarousel.prev}
									>
										<i className="material-icons-outlined" aria-hidden="true">
											navigate_before
										</i>
									</button>
									<button
										className="carousel-control-next"
										type="button"
										data-target="#carouselExampleControls"
										data-slide="next"
										onClick={() => setCount(count + 1)}
										disabled={disableCarousel.next}
									>
										<i className="material-icons-outlined" aria-hidden="true">
											navigate_next
										</i>
									</button>
								</div>
							</>
						) : paymentList.length ? (
							// Quando só possui um cartão =>
							<>
								<p className="text-blueish mt-2">
									<strong>Método de pagamento: </strong>
									{getPaymentMethodType(paymentList[0]?.paymentMethod?.type)}
								</p>
								{paymentInformation(paymentList[0], "blueish")}
							</>
						) : (
							<>
								<p className="text-blueish mt-2">
									<strong>Método de pagamento: </strong>
									{getPaymentMethodType(paymentInfo?.paymentMethod?.type)}
								</p>
								{paymentInformation(paymentInfo, "blueish")}
							</>
						)
						// Quando só possui um cartão =>
					}
				</>
			)}
		</div>
	);
}
