import { Box } from "@mui/material";
import styled from "styled-components";
import { paletteObject } from "../../../theme/foundations/palette";

export const List = styled.ul`
	padding-left: 20px;
	cursor: pointer;
	width: 100%;
	margin-bottom: 0;
`;

export const TreeNode = styled(Box)`
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	padding: 0 5px;
	border-radius: 3px;
	margin-left: -22px;
	height: 36px;

	.group-btn {
		display: none;
		width: 78px;
	}

	&:hover {
		background: ${paletteObject.bgNeo.main};
		margin-right: 2px;
		.group-btn {
			display: block;
			width: 78px;
		}
	}
`;

export const ColapseNode = styled(Box)`
	flex: 1;
	display: flex;
	align-items: center;
	height: 100%;
	max-width: calc(100% - 78px);
`;

export const IsActive = styled.div<{ isActive?: boolean }>`
	margin: 1px 5px 0 0;
	width: 10px !important;
	height: 10px !important;
	border-radius: 50%;
	background: ${({ isActive }) =>
		isActive ? paletteObject.success.main : paletteObject.grey50.main};
	flex-shrink: 0;
`;

export const TextOverFlow = styled(Box)`
	flex: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const Button = styled.button<{ color?: string }>`
	width: 100%;
	padding: 7px 5px 7px 5px;
	display: flex;
	align-items: center;
	flex-direction: row;
	border: none;
	background: transparent;
	cursor: pointer;
	color: ${({ color }) => color};
	outline: none;

	&:focus {
		outline: none;
	}

	&:hover {
		border-radius: 3px;
		background: ${paletteObject.grey10.main};
	}
`;

export const HorizontalNode = styled.span<{ width?: number }>`
	border-bottom: 1px dashed ${paletteObject.blueish20.main};
	width: ${({ width }) => `${width}px`};
	margin: 0 0 0 -14px;
	flex-shrink: 0;
`;

export const VerticalNode = styled.span`
	padding-left: 0;
	border-left: 1px dashed ${paletteObject.blueish20.main};
	margin: -12px 12px 20px -9px;
	z-index: 1;
	flex-shrink: 0;
`;

export const EmptyDiv = styled.div`
	width: 20px;
`;

export const HorizontalLine = styled.div`
	border-top: 1px solid ${paletteObject.grey20.main};
`;

export const DropdownWrapper = styled.div`
	position: relative;
`;

export const DropdownMenu = styled.div<{
	positionScrollbar?: number;
	isOpen?: boolean;
}>`
	position: absolute;
	top: ${({ positionScrollbar = 0 }) =>
		positionScrollbar > 720 ? `-135px` : `25px`};
	left: -130px;
	width: 160px;
	height: 170px;
	padding-top: 5px;
	border-radius: 5px;
	border: 1px solid ${paletteObject.grey10.main};
	color: ${paletteObject.grey70.main};
	background: ${paletteObject.white.main};
	box-shadow: 0px 4px 15px -3px rgba(0, 0, 0, 0.1);
	display: none;

	${({ isOpen }) =>
		isOpen &&
		`
    display: block;
  `}
`;

export const GroupButtons = styled(Box)`
	display: flex;
	align-items: center;
`;

export const ButtonIcon = styled(Button)`
	padding: 5px;
	display: flex;
	justify-content: center;

	.actions-btn {
		display: none;
		flex-direction: column;
	}

	&:disabled {
		color: ${paletteObject.grey50.main};
	}

	&:hover {
		background: ${paletteObject.grey30.main};
		border-radius: 3px;

		.actions-btn {
			display: inline-block;
		}
	}
`;
