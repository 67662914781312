import { darken } from "@mui/material";
import { BaseOverrideColors } from "./baseOverrideColors";
import { PaletteObjectType, paletteObject } from "../foundations/palette";

declare module "@mui/material/Button" {
	interface ButtonPropsColorOverrides extends BaseOverrideColors {}
}

const backgroundButton = (type: keyof PaletteObjectType) =>
	paletteObject[type].main;

const backgroundButtonHover = (type: keyof PaletteObjectType) =>
	darken(paletteObject[type].main, 0.1);

const colorButton = (type: string) => {
	if (type === "light") return paletteObject.blueish80.main;
	if (type === "blue30") return paletteObject.info.main;
	return paletteObject.white.main;
};

export const MuiButton = {
	styleOverrides: {
		root: {
			minWidth: "190px",
			height: "40px",
			fontSize: "14px",
			outline: "none !important",
			boxShadow: "none !important",
			textTransform: "none !important",

			"&:active": {
				outline: "none !important",
			},
		},
		contained: ({ ownerState }: any) => ({
			color: colorButton(ownerState.color),
			backgroundColor: backgroundButton(ownerState.color),
			border: "none",
			"&:hover": {
				backgroundColor: backgroundButtonHover(ownerState.color),
				outline: "none",
				boxShadow: "none",
			},
		}),
	},
};
