import { useState, useEffect, SetStateAction, UIEvent } from "react";
import { catalogManagementApi } from "../../../infrastructure/api/CatalogManagementApi";
import NewToastComponent from "./../../NewToastComponent";
import { i18n } from "../../../translate/i18n";
import { CategoryView } from "../../../domain/views/categories/CategoryView";
import { isValidString } from "../../../infrastructure/utils/TypeValidator";
import { ScrollHandler } from "../../common/ScrollHandler";
import { Key } from "antd/lib/table/interface";

interface CategoryTree {
	title: string;
	key: string;
	children?: CategoryTree[];
	className?: string;
}
interface InfoNodeKeyProps {
	checkedNodes: CategoryTree[];
	checked: boolean;
	node: {
		key: string;
	};
}

type CheckedKeysProps = Key[] | { checked: Key[]; halfChecked: Key[] };

type CategoriesCheckboxProps = {
	isMultiSelect: boolean;
	selectedValues: string[];
	handleFunction: (value: Key[]) => Key[];
	setIsOpenCategoryModal: (value: SetStateAction<boolean>) => void;
};

export default function CategoriesCheckboxService({
	selectedValues,
	handleFunction,
	isMultiSelect,
	setIsOpenCategoryModal,
}: CategoriesCheckboxProps) {
	const [categoriesSelect, setCategoriesSelect] = useState<CheckedKeysProps>(
		[]
	);
	const [renderedCheckboxes, setRenderedCheckboxes] = useState<CategoryTree[]>(
		[]
	);
	const [splittedCategories, setSplittedCategories] = useState<CategoryView[]>(
		[]
	);
	const [queryValue, setQueryValue] = useState<string>("");
	const [categories, setCategories] = useState<CategoryView[]>([]);
	const [allCategoriesTree, setAllCategoriesTree] = useState<CategoryTree[]>(
		[]
	);
	const [currentPosition, setCurrentPosition] = useState<number>(16);
	const [showContentBottom, setShowContentBottom] = useState<boolean>(false);
	const [isScrolling, setIsScrolling] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isFiltered, setIsFiltered] = useState<boolean>(false);
	const categoryLimit: number = 16;

	useEffect(() => {
		setCurrentPosition(categoryLimit);
		getCategories();
	}, []);

	useEffect(() => {
		setCategoriesSelect(selectedValues);
	}, [selectedValues]);

	const getCategories = async (): Promise<void> => {
		setIsLoading(true);
		try {
			let data: CategoryView[] = await catalogManagementApi.getCategoryList();

			const initialCategories: CategoryView[] = data.slice(0, categoryLimit);

			setCategories(data);

			setRenderedCheckboxes(
				buildCategoryList(initialCategories, selectedValues)
			);

			setAllCategoriesTree(buildCategoryList(data, selectedValues));

			setSplittedCategories(initialCategories);

			setIsLoading(false);
		} catch (error) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.ERR.REQUEST"),
			});
			setIsLoading(false);
		}
	};

	const buildCategoryList = (
		categories: CategoryView[],
		parentIds: CheckedKeysProps
	): CategoryTree[] => {
		return categories.map((category) => {
			const newCheckboxList: CategoryTree = {
				title: category.name ?? "",
				key: category.id ?? "",
			};

			if (category.children && category.children.length > 0) {
				newCheckboxList.children = buildCategoryList(
					category.children,
					parentIds
				);
			}

			return newCheckboxList;
		});
	};

	const findChildNodeKeys = (node: CategoryTree, targetKey: Key): Key[] => {
		if (node.key === targetKey) {
			return [node.key];
		}

		if (node.children) {
			for (const child of node.children) {
				const result = findChildNodeKeys(child, targetKey);
				if (result.length > 0) {
					return [node.key, ...result];
				}
			}
		}

		return [];
	};

	const childNodeKeys = (
		checkboxList: CategoryTree[],
		targetKey: Key
	): Key[] => {
		const result: Key[] = [];

		for (const node of checkboxList) {
			const keys = findChildNodeKeys(node, targetKey);
			result.push(...keys);
		}

		return result;
	};

	const parentNodeKeys = (
		checkboxList: CategoryTree[],
		nodeKey: Key
	): Key[] => {
		const nodeKeys = nodeByKeys(checkboxList, nodeKey);

		const filteredNodeKeys: Key[] = [];

		const filterNodeKey = (node: CategoryTree) => {
			filteredNodeKeys.push(node.key);

			if (node.children && node.children.length > 0) {
				for (const child of node.children) {
					filterNodeKey(child);
				}
			}
		};

		for (const node of nodeKeys) {
			filterNodeKey(node);
		}

		return filteredNodeKeys;
	};

	const nodeByKeys = (
		checkboxList: CategoryTree[],
		nodeKey: Key
	): CategoryTree[] => {
		for (const checkbox of checkboxList) {
			if (checkbox.key === nodeKey) {
				return [checkbox];
			}

			if (checkbox.children && checkbox.children.length > 0) {
				const result = nodeByKeys(checkbox.children, nodeKey);
				if (result.length > 0) {
					return result;
				}
			}
		}

		return [];
	};

	const multipleSelectionHandler = (info: InfoNodeKeyProps): void => {
		if (Array.isArray(categoriesSelect)) {
			const updatedCategories: Set<Key> = new Set(categoriesSelect);

			if (info.checked) {
				const mergedKeys = childNodeKeys(renderedCheckboxes, info.node.key);

				mergedKeys.forEach((key) => updatedCategories.add(key));
			} else {
				const mergedKeys = parentNodeKeys(renderedCheckboxes, info.node.key);

				mergedKeys.forEach((key) => updatedCategories.delete(key));
			}

			setCategoriesSelect(Array.from(updatedCategories));
		}
	};

	const singleSelectionHandler = (info: InfoNodeKeyProps): void => {
		setCategoriesSelect(info.checked ? [info.node.key] : []);

		if (isValidString(queryValue)) {
			setRenderedCheckboxes(filterCategoryList(queryValue));
		}
	};

	const onCheck = (
		_checkedKeys: CheckedKeysProps,
		info: InfoNodeKeyProps
	): void => {
		if (!isMultiSelect) {
			singleSelectionHandler(info);
		} else {
			multipleSelectionHandler(info);
		}
	};

	const filterCategoryList = (queryValue: string): CategoryTree[] => {
		const filteredTree: CategoryTree[] = [];

		for (const categoryTree of allCategoriesTree) {
			const filteredCategory = filterTree(categoryTree, queryValue);

			if (filteredCategory) {
				filteredTree.push(filteredCategory);
			}
		}

		return filteredTree;
	};

	const filterTree = (
		category: CategoryTree,
		queryValue: string
	): CategoryTree | null => {
		const clientName = queryValue.toLowerCase();
		const filteredChildren: CategoryTree[] = [];

		if (category.title.toLowerCase().includes(clientName)) {
			return { ...category };
		}

		if (category.children) {
			for (const child of category.children) {
				const filteredChild = filterTree(child, queryValue);

				if (filteredChild) {
					filteredChildren.push(filteredChild);
				}
			}
		}

		if (filteredChildren.length > 0) {
			return { ...category, children: filteredChildren };
		}

		return null;
	};

	const handleQueryChange = (queryValue: string): void => {
		setIsFiltered(true);

		setQueryValue(queryValue);

		setShowContentBottom(false);

		if (!isValidString(queryValue)) {
			setRenderedCheckboxes(
				buildCategoryList(splittedCategories, categoriesSelect)
			);

			setIsFiltered(false);

			return;
		}

		setRenderedCheckboxes(filterCategoryList(queryValue));
	};

	const handleSubmit = (): void => {
		setIsOpenCategoryModal(false);

		handleFunction(categoriesSelect as Key[]);

		setCurrentPosition(categoryLimit);

		setSplittedCategories(categories.slice(0, categoryLimit));

		handleQueryChange("");
	};

	const handleCancelSubmit = (): void => {
		setIsOpenCategoryModal(false);

		handleFunction([""]);

		setCurrentPosition(categoryLimit);

		setSplittedCategories(categories.slice(0, categoryLimit));

		handleQueryChange("");
	};

	const handleScroll = (event: UIEvent<HTMLDivElement>): void => {
		if (isFiltered) return;

		const hasBottomContent = currentPosition < categories.length;

		ScrollHandler(event, {
			hasBottomContent,
			isScrolling,
			setIsScrolling,
			setShowContentBottom,
			scrollDown,
		});
	};

	const scrollDown = (): void => {
		if (currentPosition < categories.length) {
			const nextIndex = Math.min(
				currentPosition + categoryLimit,
				categories.length
			);

			setCurrentPosition(nextIndex);

			setSplittedCategories((prevState) => {
				const updatedCheckbox = prevState.concat(
					categories.slice(currentPosition, nextIndex)
				);

				setRenderedCheckboxes(
					buildCategoryList(updatedCheckbox, categoriesSelect)
				);

				return updatedCheckbox;
			});
		}
	};

	return {
		// Props
		categoriesSelect,
		queryValue,
		showContentBottom,
		renderedCheckboxes,
		isLoading,
		allCategoriesTree,
		currentPosition,
		// Functions
		onCheck,
		handleQueryChange,
		handleSubmit,
		handleCancelSubmit,
		handleScroll,
	};
}
