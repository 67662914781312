import React, { createContext, useContext, useState, useRef } from "react";
import { APIV2, APIV1 } from "../../../../API";
import { i18n } from "../../../../translate/i18n";
import NewToastComponent from "../../../../components/NewToastComponent";
import { useParams } from "react-router-dom";
import axios from "axios";
import { buildURLWithParams } from "../../../../infrastructure/utils/BuildURLWithParams";

export const ProductManagementProvider = createContext({});

export default function ProductManagementContext(props) {
	const params = new URL(document.location).searchParams;
	const pageParam = useParams().page;

	const [inventoryData, setInventoryData] = useState({});
	const [loading, setLoading] = useState(true);
	const [loadingExport, setLoadingExport] = useState(false);
	const exportToast = useRef(null);

	const [page, setPage] = useState(pageParam || "1");
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [query, setQuery] = useState(params.get("query") || "");
	const [inventoryFilters, setInventoryFilters] = useState({
		SellerInventory: params.get("seller") ? params.get("seller") : "",
		InventoryStatus: params.get("status") ? params.get("status") : "",
		Category: params.get("category") ? params.get("category") : "",
	});
	const [sellers, setSellers] = useState([
		{
			label: i18n.t("productManagement.ShowingAll"),
			value: "",
		},
	]);
	const [categories, setCategories] = useState([
		{
			label: i18n.t("productManagement.ShowingAll"),
			value: "",
		},
	]);
	const inventoryStatusList = [
		{ value: "", label: i18n.t("productManagement.ShowingAll") },
		{ value: "Available", label: i18n.t("productManagement.Available") },
		{ value: "OutOfStock", label: i18n.t("productManagement.OutOfStock") },
	];
	const [toggleRender, setToggleRender] = useState(false);
	const [toggleMainListRender, setToggleMainListRender] = useState(false);
	const [expandedList, setExpandedList] = useState([]);

	const getSellers = async () => {
		await APIV2.get(`management/retailer/autocomplete/sellers`)
			.then(async (response) => {
				let sellerList = [];
				response.data.forEach((seller) =>
					sellerList.push({ label: seller.fullName, value: seller.id })
				);
				setSellers((prevState) => [...prevState, ...sellerList]);
			})
			.catch((error) => {});
	};

	const getCategories = async () => {
		await APIV2.get(
			`/catalog/categories?page=1&perPage=100&includeChildren=true`
		)
			.then(async (response) => {
				let categoryList = [];
				response.data.categories.forEach((category) => {
					let subCategoryList = [];
					if (category.children.length) {
						category.children.forEach((subCategory) => {
							subCategoryList.push({
								label: subCategory.name,
								value: subCategory.id,
								isSubCategory: true,
							});
						});
					}
					categoryList.push({
						label: "",
						options: [
							{
								label: category.name,
								value: category.id,
								subCategoryCount: subCategoryList.length,
							},
							...subCategoryList,
						],
					});
				});
				setCategories((prevState) => [...prevState, ...categoryList]);
			})
			.catch((error) => {});
	};

	const exportInventory = async () => {
		exportToast.current = NewToastComponent({
			status: "info",
			title: i18n.t("productManagement.ProcessingExportToast"),
			message: i18n.t("productManagement.ProcessingExportToastMessage"),
			noTimer: true,
			noCloseButton: true,
			noCloseOnClick: true,
		});
		await APIV1.get(
			`insights/export/inventory?clientId=${localStorage.getItem(
				"client_id"
			)}&query=${query}&sellerId=${
				localStorage.getItem("role") !== "3"
					? inventoryFilters.SellerInventory
					: ""
			}&status=${inventoryFilters.InventoryStatus}&categoryId=${
				inventoryFilters.Category
			}&role=${localStorage.getItem("role")}`
		)
			.then((response) => {
				const exportInterval = setInterval(async () => {
					const resp = await axios.get(response.data.statusQueryGetUri);
					if (
						resp.data.runtimeStatus !== "Running" &&
						resp.data.runtimeStatus !== "Pending"
					) {
						switch (resp.data.runtimeStatus) {
							case "Completed":
								setLoadingExport(false);
								NewToastComponent({
									status: "success",
									title: i18n.t("productManagement.ExportSuccess"),
									updateToastId: exportToast.current,
								});
								clearInterval(exportInterval);
								window.open(resp.data.output, "_blank");
								break;
							case "Failed":
							default:
								setLoadingExport(false);
								NewToastComponent({
									status: "error",
									title: i18n.t("productManagement.ExportError"),
									message: i18n.t("productManagement.TryAgain"),
									updateToastId: exportToast.current,
								});
								clearInterval(exportInterval);
								break;
						}
					}
				}, 4000);
			})
			.catch(() => {
				setLoadingExport(false);
				NewToastComponent({
					status: "error",
					title: i18n.t("productManagement.ExportError"),
					message: i18n.t("productManagement.TryAgain"),
					updateToastId: exportToast.current,
				});
			});
	};

	const getProductAndSkus = async () => {
		setLoading(true);

		let queryParams = {
			page: page,
			perPage: rowsPerPage,
			query: query,
			...(localStorage.getItem("role") !== "3" && {
				sellerId: inventoryFilters.SellerInventory,
			}),
			status: inventoryFilters.InventoryStatus,
			categoryId: inventoryFilters.Category,
			role: localStorage.getItem("role"),
		};

		await APIV2.get(`catalog/inventory?${buildURLWithParams(queryParams)}`)
			.then((response) => {
				response?.data?.items &&
					response.data.items.map((product) => {
						if (
							expandedList.some((expRowKey) => expRowKey === product.rowKey)
						) {
							product.defaultExpanded = true;
						} else {
							product.defaultExpanded = false;
						}
					});
				setInventoryData(response?.data);
				setRowsPerPage(response?.data?.perPage);
				setToggleMainListRender(!toggleMainListRender);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	const updateSkuInvetory = async (product, sku, newInventory) => {
		setToggleRender(!toggleRender);
		let skuUpdateBody = [
			{
				Quantity: newInventory,
				SellerCode: product?.sellerDocument,
				SkuCode: sku?.code,
				Action: 2, //Replace
			},
		];
		await APIV2.post("catalog/stocks/change", skuUpdateBody)
			.then((response) => {
				NewToastComponent({
					status: "success",
					title: i18n.t("productManagement.InventoryUpdateSuccess"),
				});
				getProductAndSkus();
			})
			.catch((error) => {
				setLoading(false);
				NewToastComponent({
					status: "error",
					title: i18n.t("productManagement.InventoryUpdateError"),
					message: i18n.t("productManagement.TryAgain"),
				});
			});
	};

	const overwriteCustomStyles = {
		headRow: {
			style: {
				border: "0",
				borderBottom: "1px solid #E0E3E7",
				borderTop: "1px solid #E0E3E7",
				marginTop: "0px",
				padding: "0 32px",
			},
		},
		headCells: {
			style: {
				textAlign: "center",
				color: "#4B5F79",
				fontSize: "12px",
				fontWeight: "700",
				paddingTop: "8px", // override the cell padding for head cells
				paddingBottom: "8px",
				paddingLeft: "0px",
				paddingRight: "0px",
				pointerEvents: "none",
			},
		},
		cells: {
			style: {
				padding: "16px 0px 16px 0",
				width: "150px",
				// backgroundColor: "blue",
				// border: "none",
				// color: "#747E8B",
				// fontSize: "14px",
			},
		},
		rows: {
			style: {
				border: "0",
				borderBottom: "1px solid #E0E3E7",
				width: "1110px",
			},
			highlightOnHoverStyle: {
				backgroundColor: "#F5F5F5",
				transitionDuration: "0.15s",
				transitionProperty: "background-color",
				borderBottomColor: "none",
				outlineStyle: "none",
				outlineWidth: "none",
				outlineColor: "none",
			},
		},
		pagination: {
			style: {
				border: "none",
			},
		},
	};

	return (
		<ProductManagementProvider.Provider
			value={{
				inventoryData,
				loading,
				loadingExport,
				setLoadingExport,
				exportInventory,
				overwriteCustomStyles,
				getSellers,
				sellers,
				getCategories,
				categories,
				toggleRender,
				setToggleRender,
				query,
				setQuery,
				page,
				setPage,
				inventoryFilters,
				setInventoryFilters,
				rowsPerPage,
				setRowsPerPage,
				getProductAndSkus,
				updateSkuInvetory,
				toggleMainListRender,
				expandedList,
				setExpandedList,
				inventoryStatusList,
			}}
		>
			{props.children}
		</ProductManagementProvider.Provider>
	);
}

export const useProductManagement = () => useContext(ProductManagementProvider);
