import React, { useEffect } from "react";
import IntegrationState from "./components/IntegrationState.jsx";
import BlackFridayMonitoring from "./components/BlackFridayMonitoring.jsx";
import CatalogCompilation from "./components/CatalogCompilation.jsx";
import AllIntegrationsState from "./components/AllIntegrationsState.jsx";
import LastOrders from "./components/LastOrder.jsx";
import SMLastOrder from "./components/SMLastOrder";
import { useConfig } from "../../providers/ConfigProvider";
import "./DashboardStyle.scss";
import LoadDashboard from "./components/LoadDashboard.jsx";
import OrderSummary from "./components/OrderSummary/OrderSummary.tsx";
import DashboardOrderService from "./DashboardOrderService.ts";

require("dotenv/config");

export function Dashboard(props) {
	const {
		isStateMachine,
		isLoadingOrders,
		orders,
		currentDateType,
		setCurrentDateType,
		getOrders,
	} = DashboardOrderService();
	const { mainLoading } = useConfig();
	useEffect(() => {
		if (window.location.pathname === "/") {
			window.location.href = "/dashboard";
		}
		localStorage.removeItem("transRowsPerPage");
		localStorage.removeItem("transType");
		localStorage.removeItem("transStatus");
		localStorage.removeItem("transPaymentMethod");
		localStorage.removeItem("transStartDate");
		localStorage.removeItem("transEndDate");
		localStorage.removeItem("transSearchName");
	}, []);

	const renderScreen = () => {
		return (
			<div className="container dash-content">
				<OrderSummary
					getOrders={getOrders}
					orders={orders}
					isStateMachine={isStateMachine}
					currentDateType={currentDateType}
					setCurrentDateType={setCurrentDateType}
					isLoadingOrders={isLoadingOrders}
				/>
				<div className="row">
					{(localStorage.getItem("role") === "2" ||
						localStorage.getItem("role") === "8") && (
						<div className="col-md-12 col-sm-12 col-lg-6 col-xl-5 mb-3">
							<div className="card" style={{ height: "380px" }}>
								<div className="card-body integration-state">
									<IntegrationState />
								</div>
							</div>
						</div>
					)}
					{localStorage.getItem("role") === "1" && (
						<div className="col-md-12 col-sm-12 col-lg-6 col-xl-6 mb-3">
							<CatalogCompilation />
						</div>
					)}
					{localStorage.getItem("role") === "1" && (
						<div className="col-md-12 col-sm-12 col-lg-6 col-xl-6 mb-3">
							<AllIntegrationsState />
						</div>
					)}
					{(localStorage.getItem("role") === "2" ||
						localStorage.getItem("role") === "8" ||
						localStorage.getItem("role") === "3") && (
						<div
							className={`${
								localStorage.getItem("role") === "3" ? "col-xl-12" : "col-xl-7"
							} col-lg-12 col-md-12 col-sm-12 mb-3`}
						>
							{isStateMachine ? (
								<SMLastOrder
									isLoadingOrders={isLoadingOrders}
									orders={orders}
								/>
							) : (
								<LastOrders isLoadingOrders={isLoadingOrders} orders={orders} />
							)}
						</div>
					)}
				</div>
				<div className="row">
					{localStorage.getItem("role") === "1" && (
						<div className="col-md-12 col-sm-12 col-lg-12 col-xl-12 mb-3">
							<BlackFridayMonitoring />
						</div>
					)}
				</div>
			</div>
		);
	};

	return mainLoading ? <LoadDashboard /> : renderScreen();
}

export default Dashboard;
