import React, { useContext } from "react";
import CustomIcon from "../../../MUICustomComponents/CustomIcon";
import {
	AddCircleOutlineOutlined,
	DeleteOutlineOutlined,
	EditOutlined,
	MoreVertOutlined,
	SwapVert,
} from "@mui/icons-material";
import { paletteObject } from "../../../theme/foundations/palette";
import { Box } from "@mui/material";
import { i18n } from "../../../translate/i18n";
import { CategoryView } from "../../../domain/views/categories/CategoryView";
import { CategoryContext } from "../context/CategoryContext";

import * as Styled from "../styles/TreeViewStyles";

interface DropdownProps {
	id: string;
	children: CategoryView[];
	positionScrollbar: number;
}

const Dropdown = ({ id, children, positionScrollbar }: DropdownProps) => {
	const {
		//Props
		dropdownIsOpen,
		setIsUpsertMode,
		setIsReorderMode,
		//Functions
		handleToggleMenu,
		upsertCategory,
		handleCategoryDeletion,
		categoryId,
	} = useContext(CategoryContext);

	return (
		<>
			<Styled.ButtonIcon onClick={handleToggleMenu}>
				<CustomIcon icon={<MoreVertOutlined />} variant="small" />
			</Styled.ButtonIcon>

			<Styled.DropdownMenu
				positionScrollbar={positionScrollbar}
				isOpen={dropdownIsOpen}
			>
				<Styled.Button
					onClick={() => upsertCategory(id ?? categoryId, children, true)}
				>
					<Box mx={1} mb={0.5}>
						<CustomIcon icon={<EditOutlined />} variant="small" />
					</Box>
					{i18n.t(`buttons.General.BtnUpdate`).toString()}
				</Styled.Button>

				<Styled.Button
					onClick={() => {
						setIsUpsertMode(true);
						setIsReorderMode(true);
					}}
				>
					<Box mx={1} mb={0.5}>
						<CustomIcon icon={<SwapVert />} variant="small" />
					</Box>
					{i18n.t(`buttons.General.BtnSort`).toString()}
				</Styled.Button>

				<Styled.Button onClick={() => upsertCategory(id, children, false)}>
					<Box mx={1} mb={0.5}>
						<CustomIcon icon={<AddCircleOutlineOutlined />} variant="small" />
					</Box>
					{i18n.t(`categories.Titles.IncludeCategory`).toString()}
				</Styled.Button>

				<Styled.HorizontalLine />

				<Styled.Button
					color={paletteObject.danger.main}
					onClick={() => handleCategoryDeletion(id)}
				>
					<Box mx={1} mb={0.5}>
						<CustomIcon icon={<DeleteOutlineOutlined />} variant="small" />
					</Box>
					{i18n.t(`buttons.General.BtnDelete`).toString()}
				</Styled.Button>
			</Styled.DropdownMenu>
		</>
	);
};

export default Dropdown;
