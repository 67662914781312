import React, { useEffect, useState } from "react";
import ButtonStatus from "../ButtonStatus";
import ArrowStraight from "./../../svg/ArrowStraight.jsx";
import ArrowDownDashed from "../../../../assets/floworder-arrow/ArrowDownDashed.svg";
import ArrowDownSuccess from "../../../../assets/floworder-arrow/ArrowDownSuccess.svg";
import ArrowDownSolid from "../../../../assets/floworder-arrow/ArrowDownSolid.svg";
import ArrowDownWarning from "../../../../assets/floworder-arrow/ArrowDownWarning.svg";
import ArrowDownInfo from "../../../../assets/floworder-arrow/ArrowDownInfo.svg";
import ArrowUpDashed from "../../../../assets/floworder-arrow/ArrowUpDashed.svg";
import ArrowUpSolid from "../../../../assets/floworder-arrow/ArrowUpSolid.svg";
import { useOrders } from "../../providers/OrdersContext";
export default function CreateApprovalStatus({
	indexColumn,
	getTypeIcons,
	getTitleButton,
	getTypeInfo,
	getButtonStyle,
	getTypeArrow,
	getProcessOrder,
	getRetryProcessOrder,
	getFailedProcessOrder,
}) {
	const [approval, setApproval] = useState([]);
	const { flowStatus, loadingStatus, loadingCancel, isCancellation } =
		useOrders();
	const position = flowStatus?.enqueueOrderPosition;
	const lastPosition = parseInt(flowStatus?.lastOrderPosition);
	const lastProcessedStatus = lastPosition + 1;
	const lastDateUpdate = flowStatus?.actions[1]?.LastUpdate;
	const stepEmpty = position < 0;
	const stepEmptyCancel = position > 100 && lastPosition < 1;
	const stepCancellation = position > 100 && lastPosition === 1;
	const nextStep = flowStatus?.hasNextActionAvailable && position === 1;
	const createdStep = flowStatus.created === "Created";
	const loading = loadingStatus && position === 1 && !loadingCancel;
	const initialLoading = loadingStatus && flowStatus.created === "Created";
	const actions = !flowStatus?.actions.length;
	const loadingNoActions = loadingStatus && actions;
	const loadingRetry =
		loadingStatus && !isCancellation && lastPosition === 0 && position === 100;
	const errorCancel =
		position === 100 &&
		lastPosition === 0 &&
		(flowStatus.status === "WaitingCancel" || flowStatus.status === "Canceled");

	const getStepCompleted = (position, lastPosition, lastProcessedStatus) => {
		if (position > 1 && position < 100) return true;
		else if (position > 100 && lastPosition > 1) return true;
		else if (position === 100 && lastProcessedStatus > 1) return true;
		else return false;
	};

	const stepCompleted = getStepCompleted(
		position,
		lastPosition,
		lastProcessedStatus
	);

	const processing = getProcessOrder(position === 1);

	const processingRetry = getRetryProcessOrder(position === 1);

	const failedProcess = getFailedProcessOrder(
		position === 1,
		lastProcessedStatus === 1
	);
	useEffect(() => {
		if (stepCancellation || errorCancel) {
			setApproval([0, 1]);
		} else {
			setApproval([0, 1, 2]);
		}
	}, [stepCancellation, errorCancel]);

	const handleIcons = (index) => {
		switch (index) {
			case 0:
				return getTypeIcons("CheckCircle");
			case 1:
				return getTypeIcons("AddCircleOutline");
			case 2:
				if (processing || processingRetry || loading)
					return getTypeIcons("WatchLater");
				else if (failedProcess) return getTypeIcons("Refresh");
				else return getTypeIcons("CheckCircle");
			default:
				break;
		}
	};

	const handleTitles = (index) => {
		switch (index) {
			case 0:
				return getTitleButton("CreatedCompleted");
			case 1:
				return getTitleButton("WaitingApprovalPending");
			case 2:
				if (loading) return getTitleButton("Processing");
				else if (failedProcess) return getTitleButton("Reprocess");
				else if (stepCompleted)
					return getTitleButton("WaitingApprovalCompleted");
				else return getTitleButton("WaitingApprovalAction");
			default:
				break;
		}
	};

	const handleInfo = (index) => {
		const empty = stepEmpty || stepEmptyCancel;
		const process = processing || processingRetry;

		if (empty) return getTypeInfo("NotStarted");
		else if (createdStep || actions) return showCreateInfo(index);
		else if (stepCompleted) return getTypeInfo(lastDateUpdate);
		else return showCorrectInfo(index, process);
	};

	const showCreateInfo = (index) => {
		switch (index) {
			case 0:
				if (initialLoading || loadingNoActions)
					return getTypeInfo("WaitAMinute");
				if (createdStep) return getTypeInfo("ClickToConfirm");
				else if (actions) return getTypeInfo("IntegrationFailure");
				else getTypeInfo("NotStarted");
				break;
			case 1:
			case 2:
				return getTypeInfo("NotStarted");
			default:
				break;
		}
	};

	const showCorrectInfo = (index, process) => {
		const load = loadingRetry && !errorCancel;
		switch (index) {
			case 0:
				if (initialLoading) return getTypeInfo("WaitAMinute");
				else if (actions) return getTypeInfo("IntegrationFailure");
				return getTypeInfo(flowStatus?.actions[0]?.LastUpdate);
			case 1:
				if (nextStep || process || load) return flowStatus?.integrator;
				else if (stepCancellation || errorCancel)
					return getTypeInfo("InCancellation");
				else if (failedProcess) return getTypeInfo("IntegrationFailure");
				else return getTypeInfo("NotStarted");
			case 2:
				if (loading) return getTypeInfo("WaitAMinute");
				else if (nextStep || errorCancel) return getTypeInfo("ClickToConfirm");
				else if (processingRetry || loadingRetry)
					return getTypeInfo("ProcessingRetry");
				else if (failedProcess) return getTypeInfo("TryAgain");
				else if (processing) return getTypeInfo("Processing");
				else return getTypeInfo("NotStarted");
			default:
				break;
		}
	};

	const handleStyles = (index) => {
		const empty = stepEmpty || stepEmptyCancel;
		const process = processing || processingRetry;
		if (empty) return getButtonStyle("Empty");
		else if (stepCompleted) return getButtonStyle("Completed");
		else if (createdStep || actions) return showStyleCreated(index);
		else return showCorrectStyle(index, process);
	};

	const showStyleCreated = (index) => {
		switch (index) {
			case 0:
				if (initialLoading || loadingNoActions)
					return getButtonStyle("RetryOutline");
				else if (actions) return getButtonStyle("Warning");
				else return getButtonStyle("Success");
			case 1:
			case 2:
				return getButtonStyle("Empty");
			default:
				break;
		}
	};

	const showCorrectStyle = (index, process) => {
		const load = loadingRetry && !errorCancel;
		switch (index) {
			case 0:
				if (loadingNoActions) return getButtonStyle("SuccessOutline");
				else if (actions) return getButtonStyle("Warning");
				else return getButtonStyle("Completed");
			case 1:
				if (process || loading || load) return getButtonStyle("SuccessOutline");
				else if (stepCancellation || errorCancel)
					return getButtonStyle("Canceled");
				else if (failedProcess || nextStep)
					return getButtonStyle("WarningOutline");
				else return getButtonStyle("Empty");
			case 2:
				//if (isCancellation) return getButtonStyle("Success");
				if (process || loading || loadingRetry)
					return getButtonStyle("RetryOutline");
				else if (nextStep) return getButtonStyle("Success");
				else if (failedProcess) return getButtonStyle("Warning");
				else return getButtonStyle("Empty");
			default:
				break;
		}
	};

	const handleIntegration = (index) => {
		const failed = "failed";
		const retry = "retry";
		const process = "process";
		const empty = "empty";

		switch (index) {
			case 0:
				if (initialLoading || loadingNoActions) return process;
				else if (actions) return failed;
				else return empty;
			case 1:
				if (loading || loadingRetry || errorCancel) return empty;
				else if (failedProcess) return failed;
				else return empty;
			case 2:
				if (processing || loading || loadingRetry) return process;
				else if (nextStep) return empty;
				else if (processingRetry) return retry;
				else return empty;
			default:
				break;
		}
	};

	const handleDisabledButton = (index) => {
		const clickConfirm = nextStep || failedProcess;
		const clickCreated = createdStep || actions;
		if (loadingStatus || loadingCancel) return true;
		else {
			if (index === 0) return clickCreated ? false : true;
			if (index === 1) return true;
			if (index === 2) return clickConfirm ? false : true;
		}
	};

	const handleArrowStepCancellation = (index) => {
		switch (index) {
			case 0:
				return getTypeArrow(ArrowDownSolid, "arrow-down");
			case 1:
				return <ArrowStraight currentStep={getTypeArrow("Solid")} />;
			default:
				break;
		}
	};

	const handleArrowStepMain = (index) => {
		const empty = stepEmpty || stepEmptyCancel;
		const process = processing || processingRetry;
		return showCorrectArrow(index, empty, process);
	};

	const showCorrectArrow = (index, empty, process) => {
		switch (index) {
			case 0:
				if (loadingNoActions || initialLoading)
					return getTypeArrow(ArrowDownInfo, "arrow-down");
				else if (actions) return getTypeArrow(ArrowDownWarning, "arrow-down");
				else if (createdStep)
					return getTypeArrow(ArrowDownSuccess, "arrow-down");
				else if (empty) return getTypeArrow(ArrowDownDashed, "arrow-down");
				else return getTypeArrow(ArrowDownSolid, "arrow-down");
			case 1:
				if (process || loading || loadingRetry)
					return getTypeArrow(ArrowDownInfo, "arrow-down");
				else if (nextStep) return getTypeArrow(ArrowDownSuccess, "arrow-down");
				else if (failedProcess)
					return getTypeArrow(ArrowDownWarning, "arrow-down");
				else if (stepCompleted)
					return getTypeArrow(ArrowDownSolid, "arrow-down");
				else return getTypeArrow(ArrowDownDashed, "arrow-down");
			case 2:
				if (stepCompleted) return getTypeArrow(ArrowUpSolid, "arrow-up");
				else return getTypeArrow(ArrowUpDashed, "arrow-up");
			default:
				break;
		}
	};

	const createStatusApproval = () => {
		const failed = position === 100 && actions;
		const cancellation = stepCancellation || errorCancel;
		return approval.map((index) => (
			<>
				<ButtonStatus
					key={index}
					name={
						index === 2 && indexColumn === 3
							? "#orderFlowCancel"
							: failedProcess || failed
							? ""
							: "#orderFlow"
					}
					icons={handleIcons(index)}
					title={handleTitles(index)}
					info={handleInfo(index)}
					style={handleStyles(index)}
					integration={handleIntegration(index)}
					disabled={handleDisabledButton(index)}
					position={position}
					isError={failedProcess}
				/>
				{cancellation
					? handleArrowStepCancellation(index)
					: handleArrowStepMain(index)}
			</>
		));
	};

	return createStatusApproval();
}
