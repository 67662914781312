import React, { useState } from "react";
import RulesPromotions from "./components/RulesPromotions";
import "react-widgets/dist/css/react-widgets.css";
import PromotionContext from "./Providers/PromotionContext";
import { useConfig } from "./../../../../providers/ConfigProvider";
import LoadDashboard from "../../../Dashboard/components/LoadDashboard";

import "./PromotionsStyle.scss";

export default function NewPromotions(props) {
	const { mainLoading } = useConfig();
	const [edit, setEdit] = useState(false);

	return (
		<>
			{mainLoading ? (
				<LoadDashboard />
			) : (
				<PromotionContext>
					<div className="container dash-content">
						<div id="">
							<div className="card" style={{ minHeight: "805px" }}>
								<div className="card-header">
									<h4 className="card-title">
										{edit ? "Editar promoção" : "Nova promoção"}
									</h4>
								</div>
								<div className="card-content">
									<div className="card-body p-5 pt-0">
										<RulesPromotions id={props.match.params.id} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</PromotionContext>
			)}
		</>
	);
}
