import React from "react";
import ContentLoader from "react-content-loader";

export default function SkeletonInvoices() {
	const skeleton = () => {
		return (
			<ContentLoader
				speed={2}
				width={"100%"}
				height={380}
				backgroundColor={"rgb(243, 243, 243, 0.3)"}
				foregroundColor={"rgb(236, 235, 235, 0.5)"}
				viewBox="0 0 100% 380"
			>
				<rect x="0" y="0" rx="6" ry="6" width="200" height="40" />
				<rect x="0" y="50" rx="6" ry="6" width="500" height="20" />
				<rect x="0" y="95" rx="6" ry="6" width="100" height="15" />
				<rect x="0" y="115" rx="6" ry="6" width="250" height="30" />
				<rect x="280" y="95" rx="6" ry="6" width="100" height="15" />
				<rect x="280" y="115" rx="6" ry="6" width="220" height="30" />
				<rect x="510" y="95" rx="6" ry="6" width="100" height="15" />
				<rect x="510" y="115" rx="6" ry="6" width="500" height="100" />
				<rect x="510" y="220" rx="6" ry="6" width="200" height="15" />
				<rect x="0" y="165" rx="6" ry="6" width="100" height="15" />
				<rect x="0" y="185" rx="6" ry="6" width="500" height="30" />
				<rect x="0" y="235" rx="6" ry="6" width="100" height="15" />
				<rect x="0" y="255" rx="6" ry="6" width="250" height="30" />
				<rect x="280" y="235" rx="6" ry="6" width="100" height="15" />
				<rect x="280" y="255" rx="6" ry="6" width="220" height="30" />
				<rect x="0" y="330" rx="6" ry="6" width="200" height="35" />
				<rect x="220" y="330" rx="6" ry="6" width="200" height="35" />
			</ContentLoader>
		);
	};
	return skeleton();
}
