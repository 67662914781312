import React from "react";

import DealersForm from "./components/DealersForm";
import { useConfig } from "./../../providers/ConfigProvider";
import LoadDashboard from "../Dashboard/components/LoadDashboard";
import CustomContainer from "../../MUICustomComponents/CustomContainer";

export default function NewDealers() {
	const { mainLoading } = useConfig() as { mainLoading: boolean };
	return (
		<>
			{mainLoading ? (
				<LoadDashboard />
			) : (
				<CustomContainer>
					<DealersForm />
				</CustomContainer>
			)}
		</>
	);
}
