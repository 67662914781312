import React, { Dispatch, SetStateAction } from "react";
import { i18n } from "../../../../translate/i18n";
import CustomIcon from "../../../../MUICustomComponents/CustomIcon";
import CustomSelect from "../../../../MUICustomComponents/CustomSelect";
import OrderSummaryService from "./OrderSummaryService";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import { RefreshRounded } from "@mui/icons-material";
import { Box, Grid, IconButton, Stack } from "@mui/material";
import { DateType } from "../../../../domain/enums/DateType";
import { OrdersDto } from "../../../../domain/dtos/smOrders/OrdersDto";
import { CpOrdersDto } from "../../../../domain/dtos/cpCommerceManagement/CpOrdersDto";
import GenericStatusCard from "../../../../components/GenericStatusCard";
import { SummaryStatusEnum } from "./SummaryStatusEnum";

type Props = {
	orders: CpOrdersDto | OrdersDto | null;
	isStateMachine: boolean;
	currentDateType: Exclude<DateType, DateType.CustomDate>;
	isLoadingOrders: boolean;
	setCurrentDateType: Dispatch<
		SetStateAction<Exclude<DateType, DateType.CustomDate>>
	>;
	getOrders: (
		dateType: Exclude<DateType, DateType.CustomDate>
	) => Promise<void>;
};

export default function OrderSummary({
	orders,
	isStateMachine,
	currentDateType,
	isLoadingOrders,
	setCurrentDateType,
	getOrders,
}: Props) {
	const {
		orderPeriodOptions,
		statusListDict,
		isCustomMediumBreakpoint,
		statusCounter,
		aditionalLinkPath,
		handleSelectOrderPeriod,
	} = OrderSummaryService(
		orders,
		isStateMachine,
		currentDateType,
		setCurrentDateType
	);

	return (
		<Box
			mb={3}
			className="card"
			sx={{ minHeight: "fit-content !important" }}
			px={3}
			py={2}
		>
			<Stack direction="row" justifyContent="space-between" mb={4}>
				<Box>
					<CustomTypography variant="secondaryTitle" isBold>
						{i18n.t("orderSummary.Title").toString()}
					</CustomTypography>
					<CustomTypography variant="default" color="blueish20">
						{i18n.t("orderSummary.Subtitle").toString()}
					</CustomTypography>
				</Box>
				<Stack direction="row" gap={1}>
					<IconButton onClick={() => getOrders(currentDateType)}>
						<CustomIcon
							variant="default"
							icon={<RefreshRounded />}
							isRotating={isLoadingOrders}
						/>
					</IconButton>
					<CustomSelect
						id={"OrderSummaryPeriod"}
						value={currentDateType}
						options={orderPeriodOptions}
						onChange={handleSelectOrderPeriod}
						customWidth="200px"
						isDisabled={isLoadingOrders}
					/>
				</Stack>
			</Stack>
			<Grid container spacing={2} columns={{ lg: 10 }}>
				{statusListDict.map((status) => (
					<Grid
						key={status.statusId}
						item
						lg={2}
						md={isCustomMediumBreakpoint ? 2 : 3}
					>
						<GenericStatusCard<SummaryStatusEnum>
							cardInfo={status}
							counter={statusCounter[status.statusId]}
							aditionalLinkPath={aditionalLinkPath}
							isLoading={isLoadingOrders}
						/>
					</Grid>
				))}
			</Grid>
		</Box>
	);
}
