import { isValidUrl } from "../utils/TypeValidator";

export function GetDomain(): string {
	let domain = process.env.REACT_APP_LORI_API;
	if (!domain)
		throw new Error("Could not find 'REACT_APP_LORI_API' in env variables.");
	if (!isValidUrl(domain, true))
		throw new Error("Invalid domain in env variable.");

	return domain;
}

export function GetLegacyDomain(): string {
	let domain = process.env.REACT_APP_API_ENDPOINT;
	if (!domain)
		throw new Error(
			"Could not find 'REACT_APP_API_ENDPOINT' in env variables."
		);
	if (!isValidUrl(domain, true))
		throw new Error("Invalid domain in env variable.");

	return domain;
}
