import React, { Component } from 'react';

class PreviewAppCarouselBanner extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="carousel slide app-carousel-banner" data-ride="carousel">
                <div className="carousel-inner">
                    {this.props.banners.map((item, i) =>
                        <div key={i} className="carousel-item active">
                            <div className="banner text-center justify-content-center" style={{ backgroundImage: "url(" + item.imageUrl + ")" }}>
                                {!item.imageUrl && <div class="align-self-center "> <i className="material-icons">visibility</i> <p>pré-visualização</p></div>}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
};

export default PreviewAppCarouselBanner;
