import React from "react";
import ReactInputMask from "react-input-mask";
import { i18n } from "../translate/i18n";

export default function InputMask({
	inputName,
	errors,
	register,
	required,
	isInputText,
	isEnabled,
}) {
	const getTypeInput = (type) => {
		const typeInputDict = {
			phoneNumber: i18n.t(`orderDetails.FormInput.InputPhoneNumber`),
			cnpj: i18n.t(`orderDetails.FormInput.InputCnpj`),
			buyerDoc: i18n.t(`orderDetails.FormInput.InputCpf`),
			zipCode: i18n.t(`orderDetails.FormInput.InputZipCode`),
			email: i18n.t(`orderDetails.FormInput.InputEmail`),
		};
		return typeInputDict[type] || i18n.t(`orderDetails.TypeButtom.ClickToFill`);
	};

	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	const errorIconMessage = () => (
		<div className="neo-icons-required">
			<button type="button">
				<i className="material-icons">error_outline</i>
			</button>
		</div>
	);

	return (
		<section className="m-0 py-2">
			<div className="d-flex align-items-end">
				<label className="input-form-label" htmlFor={inputName}>
					{i18n.t(`orderDetails.General.${capitalizeFirstLetter(inputName)}`)}
				</label>
			</div>
			<div className="neo-form">
				<div className="neo-form-input">
					{isInputText ? (
						<input
							className={`${
								!isEnabled
									? "neo-input-disabled"
									: errors[inputName]
									? "neo-input-invalid"
									: "neo-input-valid"
							}`}
							autoComplete="off"
							placeholder={getTypeInput(inputName)}
							id={inputName}
							name={inputName}
							disabled={!isEnabled}
							{...register(inputName, { required: required })}
						/>
					) : (
						<ReactInputMask
							className={`${
								!isEnabled
									? "neo-input-disabled"
									: errors[inputName]
									? "neo-input-invalid"
									: "neo-input-valid"
							}`}
							autoComplete="off"
							mask={getTypeInput(inputName)}
							maskChar={""}
							placeholder={getTypeInput(inputName)}
							id={inputName}
							name={inputName}
							disabled={!isEnabled}
							{...register(inputName, { required: required })}
						/>
					)}
					<div className="neo-input-icons">
						{errors[inputName] && errorIconMessage()}
					</div>
				</div>
			</div>
			{errors[inputName] && (
				<small className="text-danger">
					<em>{i18n.t(`orderDetails.General.MandatoryInformation`)}</em>
				</small>
			)}
		</section>
	);
}
