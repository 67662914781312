import { useEffect, useState } from "react";
import { retailerManagementApi } from "../../../infrastructure/api/RetailerManagementApi";
import { RetailerConfiguration } from "../../../domain/dtos/retailerManagement/retailerConfigurations/RetailerConfiguration";
import NewToastComponent from "../../../components/NewToastComponent";
import { i18n } from "../../../translate/i18n";
import { GeneralConfig } from "./types/GeneralConfig";
import { AppConfig } from "./types/AppConfig";

export default function RetailerConfigService() {
	const [retailerConfigurations, setRetailerConfigurations] =
		useState<RetailerConfiguration>();
	const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);

	useEffect(() => {
		getRetailerConfiguration();
	}, []);

	const getRetailerConfiguration = async () => {
		try {
			const data: RetailerConfiguration =
				await retailerManagementApi.getRetailerConfiguration();
			setRetailerConfigurations(data);
			localStorage.setItem("configurations", JSON.stringify(new Array(data)));
		} catch {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			return;
		}
		setIsLoadingUpdate(false);
	};

	const updateRetailerConfig = async (
		configPart: GeneralConfig | AppConfig
	): Promise<void> => {
		setIsLoadingUpdate(true);
		if (!retailerConfigurations) return;
		const updateRetailerBody = { ...retailerConfigurations, ...configPart };
		const success = await retailerManagementApi.upsertRetailerConfiguration(
			updateRetailerBody
		);
		if (!success) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			setIsLoadingUpdate(false);
			return;
		}
		NewToastComponent({
			status: "success",
			title: i18n.t("retailerConfig.UpdateSuccess"),
		});
		getRetailerConfiguration();
	};

	return { retailerConfigurations, isLoadingUpdate, updateRetailerConfig };
}
