import React from "react";
import "react-widgets/dist/css/react-widgets.css";
import Loading from "../../../components/Loading";
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	Paper,
	Stack,
	Tooltip,
	Zoom,
} from "@mui/material";
import CustomInput from "../../../MUICustomComponents/CustomInput";
import { HelpOutlineOutlined } from "@mui/icons-material";
import CustomSelect from "../../../MUICustomComponents/CustomSelect";
import CustomMaskInput from "../../../MUICustomComponents/CustomMaskInput";
import CancelLink from "../../../components/CancelLink";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import CustomIcon from "../../../MUICustomComponents/CustomIcon";
import DealersFormService from "./DealersFormService";
import { i18n } from "../../../translate/i18n";
import { DealerInputs } from "../enums/DealerInputs";
import { DocumentType } from "../../../domain/enums/DocumentType";
import { FormValidator } from "../../../infrastructure/utils/FormUtils";
import SellerAutocomplete from "../../../components/SellerAutocomplete/SellerAutocomplete";

export default function DealersForm() {
	const {
		// From context
		dealer,
		isLoading,
		isEdit,
		// Props
		dealerRoles,
		documentTypeOptions,
		configurations,
		currentSeller,
		setCurrentSeller,
		currentDocumentType,
		formErrors,
		// Functions
		getButtonText,
		handleDealerSubmit,
		handleChange,
		handleSelectChange,
		handlePercentageChange,
		handleAutocompleteChange,
	} = DealersFormService();

	const showDocumentInput = (documentType: string): JSX.Element => {
		if (documentType === DocumentType.Cnpj) {
			return (
				<CustomMaskInput
					name={DealerInputs.SalesmanCnpj}
					title={i18n.t("dealers.Field.Document")}
					value={dealer.salesmanCnpj ?? ""}
					onChange={handleChange}
					mask="99.999.999/9999-99"
					disabled={!!dealer.id}
					error={FormValidator.hasInputError(
						formErrors,
						DealerInputs.SalesmanCnpj
					)}
					helperText={FormValidator.translateHelperTextList(
						formErrors,
						DealerInputs.SalesmanCnpj
					)}
				/>
			);
		}
		return (
			<CustomMaskInput
				name={DealerInputs.SalesmanCpf}
				title={i18n.t("dealers.Field.Document")}
				value={dealer.salesmanCpf ?? ""}
				onChange={handleChange}
				mask="999.999.999-99"
				disabled={!!dealer.id}
				error={FormValidator.hasInputError(
					formErrors,
					DealerInputs.SalesmanCpf
				)}
				helperText={FormValidator.translateHelperTextList(
					formErrors,
					DealerInputs.SalesmanCpf
				)}
			/>
		);
	};

	return (
		<Paper variant="customPaper">
			{isLoading ? (
				<Loading />
			) : (
				<>
					<CustomTypography variant="title">
						{i18n.t("dealers.BasicInfo").toString()}
					</CustomTypography>
					<Grid container spacing={2}>
						<Grid item xs={3}>
							<CustomInput
								name={DealerInputs.DealerCode}
								title={i18n.t("dealers.Field.DealerCode")}
								placeholder={i18n.t("dealers.Placeholder.DealerCode")}
								value={dealer.dealerCode ?? ""}
								onChange={handleChange}
								error={FormValidator.hasInputError(
									formErrors,
									DealerInputs.DealerCode
								)}
								helperText={FormValidator.translateHelperTextList(
									formErrors,
									DealerInputs.DealerCode
								)}
							/>
						</Grid>
						<Grid item xs={9}>
							<CustomInput
								name={DealerInputs.FullName}
								title={i18n.t("dealers.Field.FullName")}
								placeholder={i18n.t("dealers.Placeholder.FullName")}
								value={dealer.fullName ?? ""}
								onChange={handleChange}
								error={FormValidator.hasInputError(
									formErrors,
									DealerInputs.FullName
								)}
								helperText={FormValidator.translateHelperTextList(
									formErrors,
									DealerInputs.FullName
								)}
							/>
						</Grid>
						<Grid item xs={3}>
							<CustomSelect
								id={DealerInputs.DocumentType}
								label={i18n.t("dealers.Field.DocumentType")}
								value={currentDocumentType}
								isDisabled={!!dealer.id}
								options={documentTypeOptions}
								onChange={handleSelectChange}
							/>
						</Grid>
						<Grid item xs={6}>
							{showDocumentInput(currentDocumentType)}
						</Grid>
						<Grid item xs={3}>
							<CustomSelect
								id={DealerInputs.Role}
								label={i18n.t("dealers.Field.Role")}
								value={dealer.role}
								options={dealerRoles}
								isDisabled={false}
								onChange={handleSelectChange}
								error={FormValidator.hasInputError(
									formErrors,
									DealerInputs.Role
								)}
								helperText={FormValidator.translateHelperTextList(
									formErrors,
									DealerInputs.Role
								)}
							/>
						</Grid>
						{localStorage.getItem("role") === "2" && (
							<Grid item xs={12}>
								<SellerAutocomplete
									sellerCode={dealer.storeCnpj}
									currentSeller={currentSeller}
									setCurrentSeller={setCurrentSeller}
									handleAutocompleteChange={handleAutocompleteChange}
									id={DealerInputs.StoreCnpj}
									error={FormValidator.hasInputError(
										formErrors,
										DealerInputs.StoreCnpj
									)}
									helperText={FormValidator.translateHelperTextList(
										formErrors,
										DealerInputs.StoreCnpj
									)}
								/>
							</Grid>
						)}
					</Grid>
					{configurations?.hasSplitPayment &&
						configurations?.useDealerCommission && (
							<Box mt={3}>
								<CustomTypography variant="title">
									{i18n.t("dealers.FinancialInfo").toString()}
								</CustomTypography>
								<Grid container spacing={2} alignItems="center">
									<Grid
										item
										container
										spacing={2}
										xs={12}
										md={7.5}
										alignItems="center"
									>
										<Grid item xs={7.5} p={0}>
											<CustomInput
												name={DealerInputs.GatewayId}
												title={i18n.t("dealers.Field.GatewayId")}
												value={dealer?.gatewayId}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs={4.5} p={0}>
											<FormControlLabel
												control={
													<Checkbox
														name={DealerInputs.UseDefaultCommission}
														checked={dealer?.useDefaultCommission}
														onChange={handleChange}
													/>
												}
												label={
													<CustomTypography variant="default">
														{i18n
															.t("dealers.Field.UseDefaultCommission")
															.toString()}{" "}
														{configurations?.percentageSplit !== null && (
															<Tooltip
																arrow
																placement="top"
																TransitionComponent={Zoom}
																title={i18n
																	.t("dealers.DefaultCommissionPercentage")
																	.replace(
																		"{X}",
																		configurations.percentageSplit
																	)}
															>
																<i>
																	<CustomIcon
																		icon={<HelpOutlineOutlined />}
																		variant="small"
																	/>
																</i>
															</Tooltip>
														)}
													</CustomTypography>
												}
											/>
										</Grid>
									</Grid>

									{!dealer?.useDefaultCommission && (
										<Grid item xs={3} md={1.5}>
											<CustomInput
												name={DealerInputs.CommissionPercentage}
												title={i18n.t("dealers.Field.CommissionPercentage")}
												type="number"
												value={dealer.commissionPercentage}
												onChange={handlePercentageChange}
												endAdornment="%"
											/>
										</Grid>
									)}
								</Grid>
							</Box>
						)}
					<Stack direction="row" justifyContent="end" mt={3} gap={2}>
						<CancelLink to={"/salespeople/1"} />
						<Button
							color="success"
							variant="contained"
							onClick={handleDealerSubmit}
						>
							{getButtonText(isEdit)}
						</Button>
					</Stack>
				</>
			)}
		</Paper>
	);
}
