import React from "react";
import ButtonStatus from "../ButtonStatus";
import ArrowDownDashed from "../../../../assets/floworder-arrow/ArrowDownDashed.svg";
import ArrowDownSuccess from "../../../../assets/floworder-arrow/ArrowDownSuccess.svg";
import ArrowDownSolid from "../../../../assets/floworder-arrow/ArrowDownSolid.svg";
import ArrowDownWarning from "../../../../assets/floworder-arrow/ArrowDownWarning.svg";
import ArrowDownInfo from "../../../../assets/floworder-arrow/ArrowDownInfo.svg";
import ArrowUpRightDashed from "../../../../assets/floworder-arrow/ArrowUpRightDashed.svg";
import ArrowUpRightSolid from "../../../../assets/floworder-arrow/ArrowUpRightSolid.svg";
import ArrowStraightDashed from "../../../../assets/floworder-arrow/ArrowStraightDashed.svg";
import { useOrders } from "../../providers/OrdersContext";

export default function CreateDispatchStatus({
	indexColumn,
	getTypeIcons,
	getTitleButton,
	getTypeInfo,
	getButtonStyle,
	getTypeArrow,
	getProcessOrder,
	getRetryProcessOrder,
	getFailedProcessOrder,
}) {
	const { flowStatus, loadingStatus, loadingCancel, isCancellation } =
		useOrders();
	const dispatch = [0, 1, 2];

	const isDelivery = flowStatus?.isDelivery;
	const canBeCanceled = flowStatus?.canBeCancel;
	const position = flowStatus?.enqueueOrderPosition;
	const lastPosition = parseInt(flowStatus?.lastOrderPosition);
	const lastProcessedStatus = lastPosition + 1;
	const stepCancellation = position > 100 && lastPosition === 4;
	const nextStep = flowStatus?.hasNextActionAvailable && position === 4;
	const loading = loadingStatus && position === 4;
	const loadCanceled =
		loadingCancel && (position === 1 || position === 2 || position === 3);
	const msgCancelModal = position > 3 && position < 100;
	const loadingRetry =
		loadingStatus && !isCancellation && lastPosition === 3 && position === 100;
	const errorCancel =
		(position === 100 && flowStatus.status === "WaitingCancel") ||
		isCancellation;
	const loadErrorCancel = loadingStatus && errorCancel;
	const isCanceled = flowStatus.status === "Canceled" && position === 100;

	const getShippingTypeModal = (hasTracking, shippingType) => {
		if (shippingType === "Pickup" && position === 4) return "pickUpInStore";
		else if (shippingType === "Delivery" && position === 4 && !hasTracking)
			return "#prepareShippingModal";
	};

	const shippingType = getShippingTypeModal(
		flowStatus?.hasTracking,
		flowStatus?.shippingType
	);

	const getDateCanceled = (lastPosition) => {
		if (lastPosition === 1 && position > 100) return 2;
		else if (lastPosition === 2 && position > 100) return 3;
		else if (lastPosition === 3 && position > 100) return 4;
		else return 4;
	};

	const getStepCompleted = (position, lastPosition, lastProcessedStatus) => {
		if (position > 4 && position < 100) return true;
		else if (position > 100 && lastPosition > 4) return true;
		else if (position === 100 && lastProcessedStatus > 4) return true;
		else return false;
	};

	const stepCompleted = getStepCompleted(
		position,
		lastPosition,
		lastProcessedStatus
	);

	const processing = getProcessOrder(position === 4);

	const processingRetry = getRetryProcessOrder(position === 4);

	const failedProcess = getFailedProcessOrder(
		position === 4,
		lastProcessedStatus === 4
	);

	const lastDateUpdate =
		flowStatus?.actions[getDateCanceled(lastPosition)]?.LastUpdate;

	const handleIcons = (index) => {
		switch (index) {
			case 0:
				if (isDelivery) return getTypeIcons("Mail");
				else return getTypeIcons("CardGiftcard");
			case 1:
				if (loading || processing || processingRetry)
					return getTypeIcons("WatchLater");
				else if (failedProcess) return getTypeIcons("Refresh");
				else return getTypeIcons("CheckCircle");
			case 2:
				if (failedProcess) return getTypeIcons("Refresh");
				else return getTypeIcons("Cancel");
			default:
				break;
		}
	};

	const handleTitles = (index) => {
		switch (index) {
			case 0:
				if (isDelivery) return getTitleButton("WaitingDispatchPending");
				else return getTitleButton("WaitingDispatchWithdrawalPending");
			case 1:
				if (loading) return getTitleButton("Processing");
				else if (failedProcess) return getTitleButton("Reprocess");
				else if (isDelivery && stepCompleted)
					return getTitleButton("WaitingDispatchCompleted");
				else if (isDelivery) return getTitleButton("WaitingDispatchAction");
				else if (!isDelivery && stepCompleted)
					return getTitleButton("WaitingDispatchWithdrawalCompleted");
				else if (!isDelivery)
					return getTitleButton("WaitingDispatchWithdrawalAction");
				break;
			case 2:
				if (loadCanceled) return getTitleButton("Processing");
				else if (failedProcess) return getTitleButton("Reprocess");
				else if (position > 100) return getTitleButton("CancelRequest");
				else return getTitleButton("CancelAction");
			default:
				break;
		}
	};

	const handleInfo = (index) => {
		const process = processing || processingRetry || nextStep;
		if (position > 100 || isCanceled) {
			switch (index) {
				case 0:
				case 1:
					return getTypeInfo("NotStarted");
				case 2:
					if (isCanceled) return getTypeInfo("Waiting");
					else if (position > 100) return getTypeInfo(lastDateUpdate);
					else if (canBeCanceled) return getTypeInfo("ClickToCancel");
					else return getTypeInfo("NotStarted");
				default:
					break;
			}
		} else return showCorrectInfo(index, process);
	};

	const showCorrectInfo = (index, process) => {
		switch (index) {
			case 0:
				if (stepCompleted) return getTypeInfo(lastDateUpdate);
				else if (process || loadingRetry) return flowStatus?.integrator;
				else if (stepCancellation) return getTypeInfo("InCancellation");
				else if (failedProcess) return getTypeInfo("IntegrationFailure");
				else return getTypeInfo("NotStarted");
			case 1:
				if (loading) return getTypeInfo("WaitAMinute");
				else if (failedProcess) return getTypeInfo("TryAgain");
				else if (nextStep) return getTypeInfo("ClickToConfirm");
				else if (stepCompleted) return getTypeInfo(lastDateUpdate);
				else if (processingRetry || loadingRetry)
					return getTypeInfo("ProcessingRetry");
				else if (processing) return getTypeInfo("Processing");
				else return getTypeInfo("NotStarted");
			case 2:
				if (loadCanceled || loadErrorCancel) return getTypeInfo("WaitAMinute");
				else if (errorCancel) return getTypeInfo("TryAgain");
				else if (canBeCanceled) return getTypeInfo("ClickToCancel");
				else return getTypeInfo("NotStarted");
			default:
				break;
		}
	};

	const handleStyles = (index) => {
		const process = processing || processingRetry;
		const activation = failedProcess || nextStep;

		if (position > 100 || isCanceled) {
			switch (index) {
				case 0:
				case 1:
					return getButtonStyle("Empty");
				case 2:
					if (position > 200) return getButtonStyle("");
					else if (position > 100 || isCanceled)
						return getButtonStyle("Canceled");
					else return getButtonStyle("Empty");
				default:
					break;
			}
		} else return showCorrectStyle(index, process, activation);
	};

	const showCorrectStyle = (index, process, activation) => {
		switch (index) {
			case 0:
				if (process || loading || loadingRetry)
					return getButtonStyle("SuccessOutline");
				else if (activation) return getButtonStyle("WarningOutline");
				else if (stepCompleted) return getButtonStyle("Completed");
				else return getButtonStyle("Empty");
			case 1:
				if (process || loading || loadingRetry)
					return getButtonStyle("RetryOutline");
				else if (nextStep) return getButtonStyle("Success");
				else if (failedProcess) return getButtonStyle("Warning");
				else if (stepCompleted) return getButtonStyle("Completed");
				else return getButtonStyle("Empty");
			case 2:
				if (loadCanceled || loadErrorCancel)
					return getButtonStyle("RetryOutline");
				else if (errorCancel) return getButtonStyle("Warning");
				else if (canBeCanceled) return getButtonStyle("Danger");
				else return getButtonStyle("Empty");
			default:
				break;
		}
	};

	const handleIntegration = (index) => {
		const failed = "failed";
		const retry = "retry";
		const process = "process";
		const empty = "empty";
		switch (index) {
			case 0:
				if (loading || loadingRetry) return empty;
				else if (failedProcess) return failed;
				else return empty;
			case 1:
				if (processing || loading || loadingRetry) return process;
				else if (nextStep) return empty;
				else if (processingRetry) return retry;
				else return empty;
			case 2:
				if (loadCanceled || loadErrorCancel) return process;
				else return empty;
			default:
				break;
		}
	};

	const handleCancelDisabled = (index) => {
		switch (index) {
			case 0:
			case 1:
				return false;
			case 2:
				if (msgCancelModal) return true;
				break;
			default:
				break;
		}
	};

	const handleDisabledButton = (index) => {
		const cancel = canBeCanceled && position !== 112;
		const clickConfirm = nextStep || failedProcess;
		if (loadingStatus || loadingCancel) return true;
		else {
			if (index === 0) return true;
			if (index === 1) return clickConfirm ? false : true;
			if (index === 2) return cancel ? false : true;
		}
	};

	const handleArrowStepMain = (index) => {
		const process = processing || processingRetry;
		const arrow = position < 2 || (lastPosition === 0 && position === 100);
		switch (index) {
			case 0:
				if (process || loading || loadingRetry)
					return getTypeArrow(ArrowDownInfo, "arrow-down");
				if (nextStep) return getTypeArrow(ArrowDownSuccess, "arrow-down");
				else if (failedProcess)
					return getTypeArrow(ArrowDownWarning, "arrow-down");
				else if (stepCompleted)
					return getTypeArrow(ArrowDownSolid, "arrow-down");
				else return getTypeArrow(ArrowDownDashed, "arrow-down");
			case 1:
				if (stepCompleted)
					return getTypeArrow(ArrowUpRightSolid, "arrow-up-right");
				else return getTypeArrow(ArrowUpRightDashed, "arrow-up-right");
			case 2:
				if (errorCancel || isCanceled) return;
				else if (arrow)
					return getTypeArrow(ArrowStraightDashed, "arrow-straight");
				break;
			default:
				break;
		}
	};

	const createStatusDispatch = () => {
		return dispatch.map((index) => (
			<>
				<ButtonStatus
					key={index}
					name={
						(index === 2 && indexColumn === 3 && (errorCancel || isCanceled)) ||
						failedProcess
							? ""
							: index === 2 && indexColumn === 3
							? "#orderFlowCancel"
							: position === 4
							? shippingType
							: "#orderFlow"
					}
					icons={handleIcons(index)}
					title={handleTitles(index)}
					info={handleInfo(index)}
					style={handleStyles(index)}
					integration={handleIntegration(index)}
					disabled={handleDisabledButton(index)}
					position={position}
					isError={failedProcess}
					cancelDisabled={handleCancelDisabled(index)}
				/>
				{handleArrowStepMain(index)}
			</>
		));
	};

	return createStatusDispatch();
}
