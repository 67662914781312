import BaseApi from "./BaseApi";
import { HttpMethod } from "../../domain/enums/HttpMethodType";
import { ZipCodeDto } from "../../domain/dtos/SalesApi/ZipCodeDto";

interface ISales {
	getZipCode: (zipCode: string) => Promise<ZipCodeDto>;
}

class Sales extends BaseApi implements ISales {
	async getZipCode(zipCode: string): Promise<ZipCodeDto> {
		let resp = await this.request<ZipCodeDto>(
			`search/cep/${zipCode}`,
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status))
			throw new Error(`Could not get information for zip code: ${zipCode}.`);

		return resp.data;
	}
}

export const salesApi: ISales = new Sales("sales/");
