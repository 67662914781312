import React from "react";
import ContentLoader from "react-content-loader";

export default function SkeletonFlowStatus() {
	const skeleton = () => {
		return (
			<ContentLoader
				speed={2}
				width={"100%"}
				height={300}
				backgroundColor={"rgb(243, 243, 243, 0.3)"}
				foregroundColor={"rgb(236, 235, 235, 0.5)"}
				viewBox="0 0 100% 300"
			>
				{/* approval */}
				<rect x="0" y="0" rx="6" ry="6" width="155" height="64" />
				<rect x="82" y="50" rx="6" ry="6" width="3" height="60" />
				<rect x="0" y="99" rx="6" ry="6" width="155" height="64" />
				<rect x="82" y="160" rx="6" ry="6" width="3" height="60" />
				<rect x="0" y="198" rx="6" ry="6" width="155" height="64" />
				<rect x="167" y="30" rx="6" ry="6" width="20" height="3" />
				<rect x="167" y="30" rx="6" ry="6" width="3" height="205" />
				{/* separeted */}
				<rect x="180" y="0" rx="6" ry="6" width="155" height="64" />
				<rect x="255" y="50" rx="6" ry="6" width="3" height="131" />
				<rect x="180" y="99" rx="6" ry="6" width="155" height="64" />
				<rect x="345" y="30" rx="6" ry="6" width="20" height="3" />
				<rect x="345" y="30" rx="6" ry="6" width="3" height="150" />
				<rect x="256" y="178" rx="6" ry="6" width="93" height="3" />
				{/* invoicing */}
				<rect x="360" y="0" rx="6" ry="6" width="155" height="64" />
				<rect x="440" y="50" rx="6" ry="6" width="3" height="131" />
				<rect x="360" y="99" rx="6" ry="6" width="155" height="64" />
				<rect x="527" y="30" rx="6" ry="6" width="20" height="3" />
				<rect x="527" y="30" rx="6" ry="6" width="3" height="150" />
				<rect x="440" y="178" rx="6" ry="6" width="89" height="3" />
				{/* dispatch */}
				<rect x="540" y="0" rx="6" ry="6" width="155" height="64" />
				<rect x="620" y="50" rx="6" ry="6" width="3" height="131" />
				<rect x="540" y="99" rx="6" ry="6" width="155" height="64" />
				<rect x="540" y="198" rx="6" ry="6" width="155" height="64" />
				<rect x="150" y="232" rx="6" ry="6" width="400" height="3" />
				<rect x="705" y="30" rx="6" ry="6" width="20" height="3" />
				<rect x="705" y="30" rx="6" ry="6" width="3" height="150" />
				<rect x="620" y="178" rx="6" ry="6" width="88" height="3" />
				{/* delivery */}
				<rect x="720" y="0" rx="6" ry="6" width="155" height="64" />
				<rect x="800" y="50" rx="6" ry="6" width="3" height="131" />
				<rect x="720" y="99" rx="6" ry="6" width="155" height="64" />
				<rect x="650" y="232" rx="6" ry="6" width="375" height="3" />
				<rect x="720" y="198" rx="6" ry="6" width="155" height="64" />
				<rect x="885" y="30" rx="6" ry="6" width="20" height="3" />
				<rect x="885" y="30" rx="6" ry="6" width="3" height="150" />
				<rect x="800" y="178" rx="6" ry="6" width="88" height="3" />
				{/* finalized */}
				<rect x="900" y="0" rx="6" ry="6" width="155" height="64" />
				<rect x="975" y="50" rx="6" ry="6" width="3" height="60" />
				<rect x="900" y="99" rx="6" ry="6" width="155" height="64" />
				<rect x="850" y="232" rx="6" ry="6" width="200" height="3" />
				<rect x="900" y="198" rx="6" ry="6" width="155" height="64" />
			</ContentLoader>
		);
	};
	return skeleton();
}
