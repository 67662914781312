import React from "react";
import "../assets/css/components/common/NeoTooltipStyle.scss";

export default function NeoTooltipAnimated(props) {
	const getStyles = () => {
		let tooltipAfterPosition = props?.tooltipAfterPosition
			? `${props?.tooltipAfterPosition}px`
			: "0px";

		return {
			"--tooltipWidth": `${props.tooltipWidth}px`,
			"--tooltipAfterPosition": tooltipAfterPosition,
		};
	};

	return (
		<section
			className={`${
				props?.className
					? `animated-tooltip-component ${props.className}`
					: "animated-tooltip-component"
			}`}
			data-tooltipcontent={props.tooltipContent || ""}
			style={getStyles()}
			data-tag={props?.dataTag || ""}
		>
			{props.children}
		</section>
	);
}
