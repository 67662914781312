import BaseApi from "./BaseApi";
import { HttpMethod } from "../../domain/enums/HttpMethodType";

interface ILoriTriggersApi {
	getLoriTriggers: (clientId: string) => Promise<any>;
	getAllLoriTriggers: () => Promise<any>;
}

class LoriTriggersApi extends BaseApi implements ILoriTriggersApi {
	async getLoriTriggers(clientId: string): Promise<any> {
		const resp = await this.request<any>(
			`triggers?clientId=${clientId}`,
			HttpMethod.Get
		);
		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error("Could not get Lori Triggers");
		}
		return resp.data;
	}

	async getAllLoriTriggers(): Promise<any> {
		const resp = await this.request<any>(`triggers`, HttpMethod.Get);
		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error("Could not get all Lori Triggers");
		}
		return resp.data;
	}
}

export const loriTriggersApi: ILoriTriggersApi = new LoriTriggersApi(
	"api/",
	false,
	process.env.REACT_APP_LORITRIGGERS
);
