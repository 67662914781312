import React, { Component } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export class Index extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="page-404 text-center">
				<h1>Ooops!</h1>
				<h5>
					A página que você está procurando não está <br />
					disponível
				</h5>
				{/* verifica se existe token, e exibe botao para login ou dashboard */}
				{localStorage.getItem("token") ? (
					<Link className="btn btn-grey-80" to={"/dashboard"}>
						Ir para o dashboard
					</Link>
				) : (
					<Link className="btn btn-grey-80" to={"/login"}>
						Ir para o login
					</Link>
				)}

				<br />
				<img src="/images/404.png" />
			</div>
		);
	}
}

export default Index;
