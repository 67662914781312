import React, { useEffect, useState } from "react";
import MaskPrice from "../../../components/MaskPrice";
import { useFinancial } from "../providers/FinancialContext";

export default function FinancialWithdrawModal() {
	const { available, createNewWithdraw, bankAccount } = useFinancial();
	const [withdrawValue, setWithdrawValue] = useState("");
	const [balanceValue, setBalanceValue] = useState(0);
	const [bank, setBank] = useState([]);

	useEffect(() => {
		!!available
			? setBalanceValue(available.toString().replace(/(\d)(\d{2})$/, "$1.$2"))
			: setBalanceValue(0);
		setBank(new Array(bankAccount[0]));
	}, [withdrawValue, available]);

	const getReleaseWithdraw = () => {
		createNewWithdraw(withdrawValue);
	};

	const getwithdrawValue = (event) => {
		const value = event.currentTarget.value.replace(/[\s,.]+/g, "").trim();
		setWithdrawValue(value);
	};

	return (
		<div
			className="modal fade"
			id="financialWithdraw"
			tabIndex="-1"
			aria-labelledby="financialWithdrawLabel"
			aria-hidden="true"
		>
			<div
				className="modal-dialog modal-dialog-centered"
				style={{ minWidth: "500px" }}
			>
				<div
					className="modal-content"
					style={{ padding: "32px", minWidth: "500px ", borderRadius: "8px" }}
				>
					<div className="modal-header d-flex flex-column">
						<h5 className="financial-text title mb-2">Solicitar Saque</h5>
						<p style={{ color: "#747E8B", fontSize: "14px" }}>
							Saldo disponível:{" "}
							<big className="text-success" style={{ fontWeight: "500" }}>
								{parseFloat(balanceValue).toLocaleString("pt-br", {
									style: "currency",
									currency: "BRL",
								})}
							</big>
						</p>
					</div>
					<div className="modal-body">
						<div className="d-flex flex-column justify-content-start mb-2">
							<div>
								<label
									className="card-title mb-1 pb-1"
									style={{ fontSize: "12px" }}
								>
									Digite o valor que deseja sacar
								</label>
								<MaskPrice
									onChange={getwithdrawValue}
									defaultValue={String(
										withdrawValue.toLocaleString("pt-br", {
											minimumFractionDigits: 2,
										})
									)}
								/>
								<p className="financial-text text m-0 p-0 pt-1">
									{available < parseInt(withdrawValue) ? (
										<em className="text-danger">
											O valor do saque é superior ao saldo disponível
										</em>
									) : (
										<em>
											O valor do saque não pode ser superior ao saldo disponível
										</em>
									)}
									<br />
									<em>Atenção: Taxas serão somadas ao valor inserido</em>
								</p>
							</div>
						</div>
						<div className="d-flex flex-row justify-content-start pb-3">
							<div className="w-100" style={{ fontSize: "12px" }}>
								<label className="card-title m-0 pb-1">
									O saque será realizado para:
								</label>

								<div key={bankAccount?.id}>
									<p className="financial-text text m-0 pt-1">
										{bankAccount?.legalName ? bankAccount?.legalName : ""}
									</p>
									{bankAccount?.documentType === "cpf" ? (
										<p className="financial-text text m-0 p-0">
											{`${
												bankAccount?.documentType
													? bankAccount?.documentType.toUpperCase()
													: ""
											}: ${
												bankAccount?.document
													? bankAccount?.document.replace(
															/(\d{3})(\d{3})(\d{3})(\d{2})/,
															"$1.$2.$3-$4"
													  )
													: ""
											}`}
										</p>
									) : (
										<p className="financial-text text m-0 p-0">
											{`${
												bankAccount?.documentType
													? bankAccount?.documentType.toUpperCase()
													: ""
											}: ${
												bankAccount?.document
													? bankAccount?.document.replace(
															/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
															"$1.$2.$3/$4-$5"
													  )
													: ""
											}`}
										</p>
									)}

									<p className="financial-text text m-0 p-0">
										{`Instituição: ${
											bankAccount?.legalName ? bankAccount?.legalName : ""
										} (${bankAccount?.bankCode ? bankAccount?.bankCode : ""})`}
									</p>
									<p className="financial-text text m-0 p-0">
										{`Agência: ${
											bankAccount?.agencia ? bankAccount?.agencia : ""
										}`}
									</p>
									<p className="financial-text text m-0 p-0">
										{`Conta corrente: ${
											bankAccount?.conta ? bankAccount?.conta : ""
										}`}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer d-flex justify-content-end">
						<button
							type="button"
							className="btn btn-link"
							data-dismiss="modal"
							style={{
								width: "190px",
								color: "#5A5E61",
								border: "1px solid #8D8D8D",
								backgroundColor: "transparent",
							}}
						>
							Cancelar
						</button>
						{available < withdrawValue || withdrawValue === 0 ? (
							<button
								className="btn btn-light btn-icon d-flex justify-content-center"
								style={{ padding: "10px 20px", width: "190px" }}
							>
								Solicitar Saque
							</button>
						) : (
							<button
								className="btn btn-success btn-icon d-flex justify-content-center"
								data-dismiss={"modal"}
								onClick={getReleaseWithdraw}
								style={{ padding: "10px 20px", width: "190px" }}
							>
								Solicitar Saque
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
