import React from "react";
import PromotionsList from "./components/PromotionsList";
import PromotionContext from "./Providers/PromotionContext";
import { useConfig } from "./../../../../providers/ConfigProvider";
import LoadDashboard from "../../../Dashboard/components/LoadDashboard";
import "./PromotionsStyle.scss";

export default function Promotions() {
	const { mainLoading } = useConfig();
	return (
		<>
			{mainLoading ? (
				<LoadDashboard />
			) : (
				<PromotionContext>
					<PromotionsList />
				</PromotionContext>
			)}
		</>
	);
}
