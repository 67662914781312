import { AutocompleteStateDto } from "../../domain/dtos/legacyApi/AutocompleteStateDto";
import { HttpMethod } from "../../domain/enums/HttpMethodType";
import BaseApi from "./BaseApi";

interface ILoriLegacy {
	getAllStates: () => Promise<AutocompleteStateDto[]>;
}

class LoriLegacy extends BaseApi implements ILoriLegacy {
	async getAllStates(): Promise<AutocompleteStateDto[]> {
		let resp = await this.request<AutocompleteStateDto[]>(
			"autocomplete/allstates",
			HttpMethod.Get
		);
		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error("Could not get all states");
		}

		return resp.data;
	}
}

export const loriLegacyApi: ILoriLegacy = new LoriLegacy("api/", true);
