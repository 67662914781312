import React from "react";
import EditingOrders from "./EditingOrders";
import ContentLoader from "react-content-loader";
import { useOrders } from "../../providers/OrdersContext";
import { i18n } from "../../../../translate/i18n";
import {
	formatDate,
	formatDocument,
	formatZipCode,
} from "../../../../infrastructure/utils/FormatMask";
import OrderAttachments from "./OrderAttachments";

export default function ItemsOrders() {
	const { loading, order } = useOrders();

	const showDataBuyer = () => {
		const address = order?.ShippingData?.DeliveryAddress;
		return (
			<>
				<div className="order-body-box">
					<h6 className="m-0 pb-2" style={{ fontSize: "13px" }}>
						{i18n.t(`orderDetails.OrderInfo.RequestTo`)}
					</h6>
					<h2 style={{ fontSize: "18px", fontWeight: "700", color: "#4B5F79" }}>
						{order?.Buyer?.FullName}
					</h2>
				</div>
				<div className="order-header-box pt-1">
					<p className="text-blueish m-0 p-0" style={{ lineHeight: "14px" }}>
						{formatDocument(order?.Buyer?.DocumentType, order?.Buyer?.Document)}
					</p>
					<a
						className="text-blueish m-0 p-0"
						style={{ color: "#7A9FEB", lineHeight: "14px" }}
					>
						{order?.Buyer?.Email}
					</a>
				</div>
				<div className="order-body-box pt-2">
					<p className="text-blueish m-0 p-0" style={{ lineHeight: "18px" }}>
						{address?.Street} {address?.Number} {address?.Complement}
					</p>
					<p className="text-blueish m-0 p-0" style={{ lineHeight: "18px" }}>
						{address?.Neighborhood} {address?.City} {address?.State}
					</p>
					<p className="text-blueish m-0 p-0" style={{ lineHeight: "18px" }}>
						{i18n.t(`orderDetails.General.ZipCode`)}:{" "}
						{formatZipCode(address?.ZipCode)}
					</p>
				</div>
			</>
		);
	};

	const showDataSeller = () => {
		const address = order?.Seller?.Address;
		return (
			<>
				<div className="order-body-box">
					<h6 className="m-0 pb-2" style={{ fontSize: "13px" }}>
						{i18n.t(`orderDetails.OrderInfo.RequestOf`)}
					</h6>
					<h2 style={{ fontSize: "18px", fontWeight: "700", color: "#4B5F79" }}>
						{order?.Seller?.FullName}
					</h2>
				</div>
				<div className="order-header-box pt-1">
					<p className="text-blueish m-0 p-0" style={{ lineHeight: "14px" }}>
						{order?.Seller?.CompanyName}
					</p>
					<p className="text-blueish m-0 p-0" style={{ lineHeight: "14px" }}>
						{formatDocument(
							order?.Seller?.DocumentType,
							order?.Seller?.Document
						)}
					</p>
					<a
						className="text-blueish m-0 p-0"
						style={{ color: "#7A9FEB", lineHeight: "14px" }}
					>
						{order?.Seller?.Email}
					</a>
				</div>
				<div className="order-body-box pt-2">
					<p className="text-blueish m-0 p-0" style={{ lineHeight: "18px" }}>
						{address?.Street} {address?.Number} {address?.Complement}
					</p>
					<p className="text-blueish m-0 p-0" style={{ lineHeight: "18px" }}>
						{address?.Neighborhood} {address?.City} {address?.State}
					</p>
					<p className="text-blueish m-0 p-0" style={{ lineHeight: "18px" }}>
						{i18n.t(`orderDetails.General.ZipCode`)}:{" "}
						{formatZipCode(address?.ZipCode)}
					</p>
				</div>
			</>
		);
	};

	const showDataOrder = () => {
		return (
			<>
				<div className="">
					{localStorage.getItem("primaryLogo") !== "undefined" && (
						<img
							src={localStorage.getItem("primaryLogo")}
							alt="imageSeller"
							style={{ width: "200px", maxHeight: "100px" }}
						/>
					)}
				</div>

				<div className="d-flex flex-column">
					<div className="order-header-box pt-3">
						<h2
							style={{ fontSize: "18px", fontWeight: "700", color: "#4B5F79" }}
						>
							{i18n.t(`orderDetails.General.Order`)}
						</h2>
					</div>
					<span>
						{i18n.t(`orderDetails.General.Order`)} nº&nbsp;
						<strong style={{ color: "#4B5F79" }}>{order?.FriendlyCode}</strong>
					</span>
					<p className="m-0 p-0">
						{i18n.t(`orderDetails.OrderInfo.OrderDate`)}:{" "}
						<strong style={{ color: "#4B5F79" }}>
							{!loading && formatDate(order?.CreateDate)}
						</strong>
					</p>
				</div>
			</>
		);
	};

	const skeleton = () => {
		const skele = [0, 1, 2, 4, 5, 6];
		return skele.map((index) => (
			<ContentLoader
				key={index}
				speed={2}
				width={400}
				height={20}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="5" y="25" rx="3" ry="3" width="400" height="100" />
				<rect x="410" y="26" rx="3" ry="3" width="400" height="100" />
			</ContentLoader>
		));
	};

	return (
		<div style={{ padding: "64px 24px 40px 24px" }}>
			<div className="scroll-card py-3 py-xl-0">
				<div className="m-0 p-0" style={{ width: "1062px" }}>
					<div className="row h-50">
						<div className="col-md-6 col-12 d-flex flex-column justify-content-between">
							{loading ? skeleton() : showDataOrder()}
						</div>
						<div className="col-md-3 col-12 d-flex pt-3 pt-md-0 flex-column justify-content-between">
							{loading ? skeleton() : showDataBuyer()}
						</div>
						<div className="col-md-3 col-12 d-flex pt-3 pt-md-0 flex-column justify-content-between">
							{loading ? skeleton() : showDataSeller()}
						</div>
					</div>
					<EditingOrders />
					<OrderAttachments />
				</div>
			</div>
		</div>
	);
}
