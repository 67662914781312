import React from "react";
import ReactExport from "react-export-excel";
import { i18n } from "../../../translate/i18n";

interface ExportFinancialArgs {
	exportName: string;
	exportData: any; // TODO, define what exportData type is
}

export default function ExportFinancial({
	exportName,
	exportData,
}: ExportFinancialArgs): JSX.Element {
	const { ExcelFile } = ReactExport;
	const { ExcelSheet } = ExcelFile;

	const downloadButton = () => (
		<button className="btn-export-financial">
			<i className="material-icons-outlined">download</i> Exportar
		</button>
	);

	return (
		<>
			<ExcelFile
				element={downloadButton()}
				filename={`${i18n.t(
					`financial.${exportName}`
				)}_${new Date().toLocaleDateString("pt-Br")}`}
			>
				<ExcelSheet
					dataSet={exportData}
					name={i18n.t(`financial.${exportName}`)}
				/>
			</ExcelFile>
		</>
	);
}
