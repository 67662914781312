import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import CustomDialog from "../../../../MUICustomComponents/CustomDialog";
import { i18n } from "../../../../translate/i18n";
import { Grid } from "@mui/material";
import CustomInput from "../../../../MUICustomComponents/CustomInput";
import { NFDReceived } from "../../../../domain/models/troquecommerce/Receiveitems/NFDReceived";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import "dayjs/locale/pt-br";
type Props = {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	onSubmitCallback: Function;
};

export default function InvoiceDialog({
	isOpen,
	setIsOpen,
	onSubmitCallback,
}: Props) {
	const [nfdData, setNfdData] = useState<NFDReceived>({
		reference: "",
		serie: "",
		danfe: "",
		date: "",
	});
	const [dateValue, setDateValue] = useState<Dayjs | null>(null);

	const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		setNfdData((prev) => ({ ...prev, [name]: value }));
	};

	const onChangeDate = (date: Dayjs | null): void => {
		setDateValue(date);
		const newDate = date && date.isValid() ? date.toISOString() : "";
		setNfdData((prev) => ({ ...prev, date: newDate }));
	};

	return (
		<CustomDialog
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			dialogInfo={{
				title: "Inserir nota fiscal de devolução",
			}}
			actionButton={{
				text: i18n.t("buttons.General.BtnSave"),
				variant: "contained",
				color: "success",
				onClick: () => onSubmitCallback(nfdData),
			}}
			dialogStyles={{
				titleVariant: "title",
				maxWidth: "sm",
			}}
		>
			<Grid container spacing={2} mt={0.5}>
				<Grid item xs={12}>
					<CustomInput
						title="Chave"
						name="danfe"
						value={nfdData.danfe}
						onChange={onChange}
						type="number"
					/>
				</Grid>
				<Grid item xs={4}>
					<CustomInput
						title="Nº da nota"
						name="reference"
						value={nfdData.reference}
						onChange={onChange}
						type="number"
					/>
				</Grid>
				<Grid item xs={4}>
					<CustomInput
						title="Série"
						name="serie"
						value={nfdData.serie}
						onChange={onChange}
						type="number"
					/>
				</Grid>
				<Grid item xs={4}>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale={"pt-br"}
					>
						<DatePicker
							label="Data de emissão"
							value={dateValue}
							onChange={onChangeDate}
							sx={{ ".MuiInputBase-formControl": { height: "37px" } }}
						/>
					</LocalizationProvider>
				</Grid>
			</Grid>
		</CustomDialog>
	);
}
