import { useLocation } from "react-router-dom";

type SearchParams<T> = {
	[K in keyof T]: string | boolean | null;
};

export const useSearchParams = <T>() => {
	const { search } = useLocation();
	const params: URLSearchParams = new URLSearchParams(search);
	const result: SearchParams<T> = {} as SearchParams<T>;
	for (const [key, value] of params.entries()) {
		const paramKey = key as keyof T;
		switch (value) {
			case "true":
				result[paramKey] = true;
				break;
			case "false":
				result[paramKey] = false;
				break;
			default:
				result[paramKey] = value || null;
				break;
		}
	}
	return result;
};
