import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import NewToastComponent from "../../../components/NewToastComponent";
import { i18n } from "../../../translate/i18n";
import { buildURLWithParams } from "../../../infrastructure/utils/BuildURLWithParams";
import { TCList } from "../../../domain/models/troquecommerce/TCList";
import { troqueCommerceApi } from "../../../infrastructure/api/external/TroqueCommerce";
import { TCOrder } from "../../../domain/models/troquecommerce/TCOrder";
import { useSearchParams } from "../../../hooks/useSearchParams";
import { DateType } from "../../../domain/enums/DateType";
import { getInitialDateType } from "../../../infrastructure/utils/Dates";

export type TCFilters = {
	page: number;
	status: string | null;
	since_updated_at: string | null;
	ecommerce_number: string; // Can be null, but "" will be considered null by the url builder
	dateType: DateType | null; // For panel usage only
};

type SearchParams = {
	status?: string;
	since_updated_at?: string;
	ecommerce_number?: string;
};

export default function TroqueCommerceListService() {
	const history = useHistory();
	const searchParams = useSearchParams<SearchParams>();
	const { page } = useParams();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [maxCount, setMaxCount] = useState<number>(0);
	const [tCList, setTCList] = useState<TCOrder[]>([]);
	const [tcFilters, setTcFilters] = useState<TCFilters>({
		page: 1,
		status: null,
		since_updated_at: null,
		ecommerce_number: "",
		dateType: null,
	});

	useEffect(() => {
		setIsLoading(true);
		const parsedPage = parseInt(page);
		const dateType = searchParams.since_updated_at
			? getInitialDateType(searchParams.since_updated_at as string)
			: null;
		const updatedTCFilters: TCFilters = {
			...tcFilters,
			page: parsedPage,
			dateType: dateType,
			status: (searchParams.status as string) ?? tcFilters.status,
			since_updated_at:
				(searchParams.since_updated_at as string) ?? tcFilters.since_updated_at,
			ecommerce_number:
				(searchParams.ecommerce_number as string) ?? tcFilters.ecommerce_number,
		};
		setTcFilters(updatedTCFilters);
		getTCOrderList(updatedTCFilters);
	}, []);

	const getTCOrderList = async (filters: TCFilters): Promise<void> => {
		try {
			const params = {
				page: filters.page,
				status: filters.status,
				since_updated_at: filters.since_updated_at,
				ecommerce_number: filters.ecommerce_number,
			};
			const queryParams = buildURLWithParams(params);
			const response: TCList = await troqueCommerceApi.getTCOrderList(
				queryParams
			);
			setMaxCount(response.count);
			setTCList(response.list);
			setIsLoading(false);
		} catch {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			setIsLoading(false);
		}
	};

	const handlePageChange = (page: number): void => {
		const newTcFilters = { ...tcFilters, page: page };
		updateTCWithFilters(newTcFilters);
	};

	const updateTCWithFilters = (filters: TCFilters): void => {
		setIsLoading(true);
		history.push(
			`${filters.page}?${buildURLWithParams({
				status: filters.status,
				since_updated_at: filters.since_updated_at,
				ecommerce_number: filters.ecommerce_number,
			})}`
		);

		setTcFilters(filters);
		getTCOrderList(filters);
	};

	return {
		isLoading,
		maxCount,
		tCList,
		tcFilters,
		updateTCWithFilters,
		handlePageChange,
	};
}
