import React, { Component } from 'react';
import Materials from './Materials';
import BasicInfo from './BasicInfo';

export class Onboarding extends Component {
  constructor(props) {
    super(props)
    this.state = {
      content: []
    }
  }
  componentDidMount() {
  
  }
  render() {
    return (
        /* Verifca o step pelo parametro para retornar o componente */
        this.props.match.params.step === 'materials' ?
          <Materials/> : <BasicInfo/>
    )
  }
};

export default Onboarding;
