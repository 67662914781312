import { ChangeEvent, useEffect, useState } from "react";
import { isValidString } from "../../../infrastructure/utils/TypeValidator";
import { normalizeDigitsOnly } from "../../../infrastructure/utils/StringUtils";
import { SelectChangeEvent } from "@mui/material";
import { AutocompleteSellerView } from "../../../domain/views/dealers/AutocompleteSellerView";
import { DealerView } from "../../../domain/views/dealers/DealerView";
import { useHistory, useParams } from "react-router-dom";
import NewToastComponent from "../../../components/NewToastComponent";
import { i18n } from "../../../translate/i18n";
import { marketingManagementApi } from "../../../infrastructure/api/MarketingManagementApi";
import { DealerDto } from "../../../domain/dtos/dealers/DealerDto";
import { DealerRole } from "../../../domain/enums/DealerRole";
import { DocumentType } from "../../../domain/enums/DocumentType";
import { DealerInputs } from "../enums/DealerInputs";
import {
	FormErrors,
	FormValidator,
	InputValidator,
} from "../../../infrastructure/utils/FormUtils";

export default function DealersFormService() {
	const history = useHistory();
	const params = useParams();
	const unparsedConfigurations = localStorage.getItem("configurations");
	const configurations =
		unparsedConfigurations && JSON.parse(unparsedConfigurations)[0];
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [currentSeller, setCurrentSeller] =
		useState<AutocompleteSellerView | null>(null);
	const [currentDocumentType, setCurrentDocumentType] = useState<string>(
		DocumentType.Cpf
	);
	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const [dealer, setDealer] = useState<DealerView>({
		id: "",
		clientId: "",
		dealerCode: "",
		fullName: "",
		salesmanCpf: null,
		salesmanCnpj: null,
		storeCnpj: "",
		role: "",
		gatewayId: "",
		commissionPercentage: "0",
		useDefaultCommission: false,
		isActive: false,
	});
	const validationFuncMapper: Record<string, Function> = {
		dealerCode: InputValidator.isRequired,
		fullName: InputValidator.isRequired,
		salesmanCpf: InputValidator.isCpfValid,
		salesmanCnpj: InputValidator.isCnpjValid,
		role: InputValidator.isRequired,
		storeCnpj: InputValidator.isRequired,
	};

	useEffect(() => {
		if (!isValidString(params.id)) {
			setIsLoading(false);
			return;
		}
		getDealers(params.id);
		setIsEdit(true);
	}, []);

	useEffect(() => {
		if (isValidString(dealer.id) && isValidString(dealer.salesmanCnpj)) {
			setCurrentDocumentType(DocumentType.Cnpj);
		}
	}, [dealer]);

	const dealerRoles = [
		{
			label: i18n.t(`dealers.Role.${DealerRole.Salesperson}`),
			value: DealerRole.Salesperson,
		},
		{
			label: i18n.t(`dealers.Role.${DealerRole.ResponsibleSeller}`),
			value: DealerRole.ResponsibleSeller,
		},
		{
			label: i18n.t(`dealers.Role.${DealerRole.Supervisor}`),
			value: DealerRole.Supervisor,
		},
		{
			label: i18n.t(`dealers.Role.${DealerRole.Coordinator}`),
			value: DealerRole.Coordinator,
		},
		{
			label: i18n.t(`dealers.Role.${DealerRole.AssistantManager}`),
			value: DealerRole.AssistantManager,
		},
		{
			label: i18n.t(`dealers.Role.${DealerRole.Manager}`),
			value: DealerRole.Manager,
		},
		{
			label: i18n.t(`dealers.Role.${DealerRole.Partner}`),
			value: DealerRole.Partner,
		},
	];

	const documentTypeOptions = [
		{
			label: i18n.t(`dealers.Document.${DocumentType.Cpf}`),
			value: DocumentType.Cpf,
		},
		{
			label: i18n.t(`dealers.Document.${DocumentType.Cnpj}`),
			value: DocumentType.Cnpj,
		},
	];

	const getButtonText = (isEditing: boolean): string => {
		if (!isEditing) return i18n.t("dealers.Button.CreateDealer");
		return i18n.t("dealers.Button.UpdateDealer");
	};

	const getDealers = async (dealerId: string): Promise<void> => {
		try {
			const data: DealerDto = await marketingManagementApi.getDealer(dealerId);
			const commissionString = `${data.commissionPercentage * 100}`;
			let currentRole: string = "";
			const foundCurrentRole = dealerRoles.find(
				(role) => role.value.toLowerCase() === data.role.toLowerCase()
			);
			if (foundCurrentRole) currentRole = foundCurrentRole.value;
			setDealer({
				id: data.id,
				clientId: data.clientId,
				isActive: data.isActive,
				fullName: data.fullName,
				role: currentRole,
				salesmanCpf: data.salesmanCpf,
				salesmanCnpj: data.salesmanCnpj,
				storeCnpj: data.storeCnpj,
				dealerCode: data.dealerCode,
				gatewayId: data.gatewayId,
				commissionPercentage: commissionString,
				useDefaultCommission: data.useDefaultCommission,
			});
		} catch {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.ERR.REQUEST"),
			});
		}
		setIsLoading(false);
	};

	const shouldSkipValidation = (input: string): boolean => {
		const skipSalesmanCpf: boolean =
			input === DealerInputs.SalesmanCpf &&
			currentDocumentType !== DocumentType.Cpf;
		const skipSalesmanCnpj: boolean =
			input === DealerInputs.SalesmanCnpj &&
			currentDocumentType !== DocumentType.Cnpj;
		const skipStoreCnpj: boolean =
			input === DealerInputs.StoreCnpj && localStorage.getItem("role") !== "2";

		return skipSalesmanCpf || skipSalesmanCnpj || skipStoreCnpj;
	};

	const handleDealerSubmit = (): void => {
		let commissionValue = 0;
		if (dealer.commissionPercentage !== "") {
			commissionValue = parseFloat(dealer.commissionPercentage) / 100;
		}
		const data: DealerDto = {
			clientId: localStorage.getItem("client_id")?.toLowerCase() ?? "",
			dealerCode: dealer.dealerCode,
			fullName: dealer.fullName,
			salesmanCpf: dealer.salesmanCpf,
			salesmanCnpj: dealer.salesmanCnpj,
			storeCnpj: currentSeller?.cnpj ?? "",
			role: dealer.role,
			gatewayId: dealer.gatewayId,
			commissionPercentage: commissionValue,
			useDefaultCommission: dealer.useDefaultCommission,
			isActive: dealer.isActive,
		};

		const formProps = Object.keys(data);
		const formValues = Object.values(data);
		let errors: FormErrors = {};
		for (let i = 0; i < formProps.length; i++) {
			const name = formProps[i];
			const value = formValues[i];
			if (validationFuncMapper[name] === undefined) continue;
			if (shouldSkipValidation(name)) continue;
			const errMessages: string[] = validationFuncMapper[name](value);
			errors[name] = errMessages;
		}

		if (FormValidator.hasError(errors)) {
			setFormErrors(errors);
			return;
		}

		if (dealer?.id && isValidString(dealer.id)) {
			data.id = dealer.id;
			updateDealer(dealer.id, data);
			return;
		}
		createDealer(data);
	};

	const createDealer = async (data: DealerDto): Promise<void> => {
		let success = await marketingManagementApi.createDealer(data);

		if (!success) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.ERR.REQUEST"),
			});
			return;
		}
		localStorage.setItem("createDealer", "true");
		history.push("/salespeople/1");
	};

	const updateDealer = async (
		dealerId: string,
		data: DealerDto
	): Promise<void> => {
		let success = await marketingManagementApi.updateDealer(dealerId, data);

		if (!success) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.ERR.REQUEST"),
			});
			return;
		}
		localStorage.setItem("updateDealer", "true");
		history.push("/salespeople/1");
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = e.target;
		if (validationFuncMapper[name] !== undefined) {
			const errMessages: string[] = validationFuncMapper[name](value);
			setFormErrors((prevState) => ({ ...prevState, [name]: errMessages }));
		}
		if (
			name === DealerInputs.SalesmanCnpj ||
			name === DealerInputs.SalesmanCpf
		) {
			setDealer((prevState: DealerView) => ({
				...prevState,
				[name]: normalizeDigitsOnly(value),
			}));
			return;
		}
		if (name === DealerInputs.UseDefaultCommission) {
			const isChecked = e.target.checked;
			setDealer((prevState: DealerView) => ({
				...prevState,
				[name]: isChecked,
			}));
			return;
		}
		setDealer((prevState: DealerView) => ({ ...prevState, [name]: value }));
	};

	const handleSelectChange = (event: SelectChangeEvent<string>): void => {
		const { name, value } = event.target;
		if (validationFuncMapper[name] !== undefined) {
			const errMessages: string[] = validationFuncMapper[name](value);
			setFormErrors((prevState) => ({ ...prevState, [name]: errMessages }));
		}
		if (name === DealerInputs.DocumentType) {
			setDealer((prevState: DealerView) => ({
				...prevState,
				salesmanCpf: null,
				salesmanCnpj: null,
			}));
			setCurrentDocumentType(value);
			return;
		}
		setDealer((prevState: DealerView) => ({ ...prevState, [name]: value }));
	};

	const handlePercentageChange = (
		event: ChangeEvent<HTMLInputElement>
	): void => {
		let inputValue = event.target.value;
		if (!inputValue) {
			setDealer((prevState: DealerView) => ({
				...prevState,
				commissionPercentage: "",
			}));
			return;
		}
		if (inputValue.includes(".")) {
			const splittedValue = inputValue.split(".");
			inputValue = `${splittedValue[0]}.${splittedValue[1].slice(0, 2)}`;
		}
		setDealer((prevState: DealerView) => ({
			...prevState,
			commissionPercentage: inputValue,
		}));
	};

	const handleAutocompleteChange = (
		value: AutocompleteSellerView | null
	): void => {
		setCurrentSeller(value);
		const storeCnpj = value?.cnpj ?? "";
		const errMessages: string[] =
			validationFuncMapper[DealerInputs.StoreCnpj](storeCnpj);
		setFormErrors((prevState) => ({
			...prevState,
			[DealerInputs.StoreCnpj]: errMessages,
		}));
	};

	return {
		// Props
		dealer,
		isLoading,
		isEdit,
		dealerRoles,
		documentTypeOptions,
		configurations,
		currentSeller,
		setCurrentSeller,
		currentDocumentType,
		formErrors,
		// Functions
		getButtonText,
		handleDealerSubmit,
		handleChange,
		handleSelectChange,
		handlePercentageChange,
		handleAutocompleteChange,
	};
}
