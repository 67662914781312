import React, { useEffect, useRef, useState } from "react";
import { useProductManagement } from "../providers/ProductManagementContext";
import List from "../../../../components/List/List";
import InventoryExpandedSkuList from "./components/InventoryExpandedSkuList";
import { i18n } from "../../../../translate/i18n";
import { useHistory } from "react-router-dom";
import InventoryCustomLoader from "./components/InventoryCustomLoader";
import InventoryEmptyState from "./components/InventoryEmptyState";
import NeoTooltipAnimated from "../../../../components/NeoTooltipAnimated";
import HeaderSearches from "../../../../components/HeaderSearches/HeaderSearches";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function Inventory() {
	const {
		loading,
		overwriteCustomStyles,
		getSellers,
		getCategories,
		getProductAndSkus,
		rowsPerPage,
		setRowsPerPage,
		page,
		setPage,
		query,
		setQuery,
		inventoryData,
		toggleMainListRender,
		expandedList,
		setExpandedList,
		sellers,
		categories,
		inventoryStatusList,
		inventoryFilters,
		setInventoryFilters,
	} = useProductManagement();
	const history = useHistory();
	const productDisplayCodeNameRef = useRef(null);

	const selectList =
		localStorage.getItem("role") !== "3"
			? [
					{
						title: "SellerInventory",
						options: sellers,
					},
					{
						title: "InventoryStatus",
						options: inventoryStatusList,
					},
					{
						title: "Category",
						options: categories,
					},
			  ]
			: [
					{
						title: "InventoryStatus",
						options: inventoryStatusList,
					},
					{
						title: "Category",
						options: categories,
					},
			  ];

	useEffect(() => {
		localStorage.getItem("role") !== "3" && getSellers();
		getCategories();
		productDisplayCodeNameRef.current = JSON.parse(
			localStorage.getItem("configurations")
		)[0].productDisplayCodeName;
	}, []);

	useEffect(() => {
		setExpandedList([]);
		history.push(
			`${page}?query=${query}${
				localStorage.getItem("role") !== "3"
					? `&seller=${inventoryFilters.SellerInventory}`
					: ""
			}&status=${inventoryFilters.InventoryStatus}&category=${
				inventoryFilters.Category
			}`
		);
		getProductAndSkus();
	}, [page, rowsPerPage, query, inventoryFilters]);

	const changePage = (e) => {
		setPage(e);
	};

	const changeRowsPerPage = (e) => {
		setRowsPerPage(e);
		setPage("1");
	};

	const statusTooltipDict = {
		Available: i18n.t("productManagement.AvailableTooltip"),
		LowOfStock: i18n.t("productManagement.LowOfStockTooltip"),
		OutOfStock: i18n.t("productManagement.OutOfStockTooltip"),
	};

	const statusTooltipSize = {
		Available: 113,
		LowOfStock: 139,
		OutOfStock: 149,
	};

	const columsList = [
		{
			name: i18n.t("productManagement.ProductAndSKUs"),
			sortable: false,
			style: {
				marginLeft: "32px",
				paddingRight: "8px",
			},
			cell: (row) => (
				<div
					className="product-table-product d-flex align-items-center"
					data-tag="allowRowEvents"
				>
					<i
						className={`expand-icon material-icons-round mr-2${
							row?.defaultExpanded ? " isExpanded" : ""
						}`}
						data-tag="allowRowEvents"
					>
						chevron_right
					</i>
					<div
						className="product-image-block position-relative"
						data-tag="allowRowEvents"
					>
						<NeoTooltipAnimated
							className="badge-pill position-absolute py-1 px-3"
							tooltipContent={
								row?.skuMaxCount === 1
									? i18n.t("productManagement.SkusQuantityTooltipSingular")
									: i18n
											.t("productManagement.SkusQuantityTooltipPlural")
											.replace("%X", row?.skuMaxCount)
							}
							tooltipWidth={118}
							dataTag="allowRowEvents"
						>
							{row?.skuMaxCount}
						</NeoTooltipAnimated>
						<img
							className="rounded"
							alt="Product image"
							src={row?.imageUrl ? row?.imageUrl : "/images/placeholder.jpg"}
							data-tag="allowRowEvents"
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.src = "/images/placeholder.jpg";
							}}
						/>
					</div>
					<div className="product-info ml-3">
						<h6 className="product-info-name mb-0" data-tag="allowRowEvents">
							{row?.name}
						</h6>
						<p
							className="product-info-code mt-1 mb-0"
							data-tag="allowRowEvents"
						>
							{`${i18n.t("productManagement.Code")} ${row?.code}`}
						</p>
						{productDisplayCodeNameRef.current !== "Code" && (
							<p
								className="product-info-ref m-0 mt-1"
								data-tag="allowRowEvents"
							>
								{`${i18n.t("productManagement.ReferenceCode")} ${
									productDisplayCodeNameRef.current === "AuxCode"
										? row?.auxCode
										: row?.referenceCode
								}`}
							</p>
						)}
					</div>
				</div>
			),
		},
		localStorage.getItem("role") !== "3"
			? {
					name: i18n.t("productManagement.SellerInventory"),
					sortable: false,
					width: "134.5px",
					center: true,
					style: {
						paddingLeft: "8px",
						paddingRight: "8px",
					},
					cell: (row) => (
						<div className="product-table-seller" data-tag="allowRowEvents">
							{row?.sellerName}
						</div>
					),
			  }
			: {
					width: "0px",
			  },
		{
			name: i18n.t("productManagement.LastUpdate"),
			sortable: false,
			width: "134.5px",
			center: true,
			cell: (row) => (
				<div className="product-table-lastUpdate text-center">
					{row?.lastUpdate ? (
						<>
							<p className="lastUpdate-date m-0 mb-1" data-tag="allowRowEvents">
								{new Date(row?.lastUpdate).toLocaleDateString("pt-Br")}
							</p>
							<p className="lastUpdate-hour m-0" data-tag="allowRowEvents">
								{new Date(row?.lastUpdate).toLocaleTimeString("pt-Br")}
							</p>
						</>
					) : (
						<span className="lastUpdate-date">–</span>
					)}
				</div>
			),
		},
		{
			name: i18n.t("productManagement.InventoryQuantity"),
			sortable: false,
			width: "134.5px",
			center: true,
			cell: (row) => (
				<div
					className={`product-table-inventory${
						row?.status === "OutOfStock" ? " OutOfStock" : ""
					} badge-pill py-1 px-3`}
					data-tag="allowRowEvents"
				>
					{row?.stockQuantity}
				</div>
			),
		},
		{
			name: i18n.t("productManagement.Status"),
			sortable: false,
			width: "134.5px",
			center: true,
			cell: (row) => (
				<NeoTooltipAnimated
					className={`product-table-status-product ${row?.status} rounded-circle`}
					tooltipContent={statusTooltipDict[row?.status]}
					tooltipWidth={statusTooltipSize[row?.status]}
				>
					<i className="material-icons-round" data-tag="allowRowEvents">
						{row?.status === "Available"
							? "check_circle_outline"
							: "error_outline"}
					</i>
				</NeoTooltipAnimated>
			),
		},
		{
			name: i18n.t("productManagement.Actions"),
			sortable: false,
			width: "134.5px",
			right: true,
			style: {
				marginRight: "32px",
			},
			cell: (row) => (
				<>
					<NeoTooltipAnimated
						className="product-table-actions mr-2"
						tooltipContent={i18n.t(
							"productManagement.OpenProductDetailTooltip"
						)}
						tooltipWidth={95}
					>
						<button
							className="material-icons-outlined"
							target="_blank"
							onClick={() =>
								window.open(`/products/1/edit/${row.id}`, "_blank")
							}
						>
							open_in_new
						</button>
					</NeoTooltipAnimated>
					<NeoTooltipAnimated
						className="product-table-actions"
						tooltipContent={i18n.t("productManagement.ClickToExpandTooltip")}
						tooltipWidth={80}
					>
						<button
							className="material-icons-outlined"
							data-tag="allowRowEvents"
						>
							more_horiz
						</button>
					</NeoTooltipAnimated>
				</>
			),
		},
	];

	const handleExpandedList = (row) => {
		if (expandedList.some((expandedRowkey) => expandedRowkey === row.rowKey)) {
			setExpandedList(
				expandedList.filter((expandedRowkey) => expandedRowkey !== row.rowKey)
			);
		} else {
			setExpandedList((prev) => [...prev, row.rowKey]);
		}
	};

	const handleQueryChange = (queryValue) => {
		setQuery(queryValue);
		setPage("1");
	};

	const handleClearFilters = () => {
		setInventoryFilters({
			SellerInventory: "",
			InventoryStatus: "",
			Category: "",
		});
		setPage("1");
	};

	return (
		<>
			<div className="container dash-content">
				<div className="card inventory-card">
					<div className="card-header inventory-card-header m-2 p-4">
						<div className="d-flex justify-content-between mb-4">
							<h4 className="card-title">
								{i18n.t("productManagement.Inventories")}
							</h4>
							<Link
								className="inventory-btn inventory-btn-import d-flex align-items-center justify-content-center m-0 p-2"
								to={"/import"}
							>
								<i className="material-icons-outlined">upload</i>{" "}
								{i18n.t("productManagement.Import")}
							</Link>
						</div>
						<HeaderSearches
							query={query}
							handleQueryChange={handleQueryChange}
							handleClearFilters={handleClearFilters}
							selectList={selectList}
							setFilters={setInventoryFilters}
							filters={inventoryFilters}
							placeholder={"SearchInventory"}
						/>
					</div>
					<div className="card-body inventory-card-body p-0">
						<h4 className="product-table-title ml-4 mb-3 pl-2">
							{i18n
								.t("components.ShowingAllResultsQuantity")
								.replace("%X", inventoryData?.maxCount || "0")}
						</h4>
						{toggleMainListRender ? (
							<List
								keyField={"rowKey"}
								progressPending={loading}
								progressComponent={<InventoryCustomLoader />}
								persistTableHead={loading || inventoryData?.maxCount > 0}
								noDataComponent={<InventoryEmptyState />}
								overwriteCustomStyles={overwriteCustomStyles}
								expandableRows={true}
								expandableRowDisabled={(row) => row?.skuMaxCount <= 0}
								expandOnRowClicked={true}
								expandableRowsHideExpander={true}
								onRowExpandToggled={(expanded, row) => {
									handleExpandedList(row);
									row.skus.map((sku) => (sku.editing = false));
									expanded === true
										? document
												.querySelector(`#row-${row?.rowKey}`)
												.getElementsByTagName("i")[0]
												.classList.add("isExpanded")
										: document
												.querySelector(`#row-${row?.rowKey}`)
												.getElementsByTagName("i")[0]
												.classList.remove("isExpanded");
								}}
								expandableRowsComponent={<InventoryExpandedSkuList />}
								expandableRowExpanded={(row) => row?.defaultExpanded}
								highlightOnHover={true}
								changeSelectOptions={[10, 20, 30, 40, 50]}
								data={inventoryData?.items}
								page={inventoryData?.page}
								colums={columsList}
								totalRows={inventoryData?.maxCount}
								handlePageChange={changePage}
								paginationServer={true}
								rowsPerPage={inventoryData?.perPage}
								handleRowsPerPageChange={changeRowsPerPage}
							/>
						) : (
							<>
								<List
									keyField={"rowKey"}
									progressPending={loading}
									progressComponent={<InventoryCustomLoader />}
									persistTableHead={loading || inventoryData?.maxCount > 0}
									noDataComponent={<InventoryEmptyState />}
									overwriteCustomStyles={overwriteCustomStyles}
									expandableRows={true}
									expandableRowDisabled={(row) => row?.skuMaxCount <= 0}
									expandOnRowClicked={true}
									expandableRowsHideExpander={true}
									onRowExpandToggled={(expanded, row) => {
										handleExpandedList(row);
										row.skus.map((sku) => (sku.editing = false));
										expanded === true
											? document
													.querySelector(`#row-${row?.rowKey}`)
													.getElementsByTagName("i")[0]
													.classList.add("isExpanded")
											: document
													.querySelector(`#row-${row?.rowKey}`)
													.getElementsByTagName("i")[0]
													.classList.remove("isExpanded");
									}}
									expandableRowsComponent={<InventoryExpandedSkuList />}
									expandableRowExpanded={(row) => row?.defaultExpanded}
									highlightOnHover={true}
									changeSelectOptions={[10, 20, 30, 40, 50]}
									data={inventoryData?.items}
									page={inventoryData?.page}
									colums={columsList}
									totalRows={inventoryData?.maxCount}
									handlePageChange={changePage}
									paginationServer={true}
									rowsPerPage={inventoryData?.perPage}
									handleRowsPerPageChange={changeRowsPerPage}
								/>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
