import React, { useState } from "react";
import { useOrders } from "../../providers/OrdersContext";
import { toast } from "react-toastify";
import ContentLoader from "react-content-loader";
import { APIV2 } from "../../../../API";
import MaskEditPrice from "../../../../components/MaskEditPrice";
import Modal from "../../../../components/Modal";
import { i18n } from "../../../../translate/i18n";
import { IsValidNumber } from "../../../../infrastructure/utils/TypeValidator";

export default function OrderEditing() {
	const {
		loading,
		amount,
		items,
		setAmount,
		orderId,
		setItems,
		setLoading,
		productDisplayCodeName,
		getOrders,
		order,
	} = useOrders();
	const [edit, setEdit] = useState(false);
	const [itemDeleted, setItemDeleted] = useState([]);
	const [deleteId, setDeleteId] = useState("");
	const [nameSku, setNameSku] = useState("");
	const [codeSku, setCodeSku] = useState("");
	const [imageSku, setImageSku] = useState("");
	const [priceId, setPriceId] = useState("");
	const [discountPriceId, setDiscountPriceId] = useState("");
	const [unitPrice, setUnitPrice] = useState("");
	const [discountPrice, setDiscountPrice] = useState("");
	const [totalValue, setTotalValue] = useState(0);

	const aprovePrice = (type) => {
		if (type === true) {
			setPriceId("");
			let arr = items.filter((obj) => {
				return obj.sku.id === priceId;
			});
			const value =
				unitPrice === ""
					? arr[0].unitPrice
					: unitPrice.replace(/\D/g, "").replace(/(\d)(\d{2})$/, "$1.$2");
			arr[0].unitPrice = parseFloat(value);
			arr[0].totalAmount = arr[0].quantity * arr[0].unitPrice;
			arr[0].totalAmount =
				arr[0].discountPrice > arr[0].totalAmount
					? 0
					: arr[0].totalAmount - arr[0].discountPrice;
			getValuesOrders();
		} else {
			setPriceId("");
		}
	};

	const changePrice = (price, id) => {
		setTotalValue(price);
	};

	const aproveDiscount = (type) => {
		if (type === true) {
			setDiscountPriceId("");
			let arr = items.filter((obj) => {
				return obj.sku.code === discountPriceId;
			});
			const value =
				discountPrice === ""
					? arr[0].discountPrice
					: discountPrice.replace(/\D/g, "").replace(/(\d)(\d{2})$/, "$1.$2");
			arr[0].discountPrice = parseFloat(value);
			arr[0].totalAmount = arr[0].quantity * arr[0].unitPrice;
			arr[0].totalAmount =
				arr[0].discountPrice > arr[0].totalAmount
					? 0
					: arr[0].totalAmount - arr[0].discountPrice;
			getValuesOrders();
		} else {
			setDiscountPriceId("");
		}
	};

	const getValuesOrders = () => {
		const discountValue = items.map((item) => {
			return item?.DiscountPrice;
		});
		let discount = discountValue.reduce(
			(total, currentElement) => total + currentElement
		);
		setAmount((oldAmount) => ({ ...oldAmount, discountAmount: discount }));
		const totalValue = items.map((item) => {
			return item?.TotalAmount;
		});
		let total = totalValue.reduce(
			(total, currentElement) => total + currentElement
		);
		setAmount((oldAmount) => ({
			...oldAmount,
			totalAmount: total + amount.ShippingAmount + amount.feeAmount,
		}));
		setAmount((oldAmount) => ({
			...oldAmount,
			ItemsAmount: total + discount,
		}));
	};

	const removeList = (id, nameSku, codeSku, imageSku) => {
		setDeleteId(id);
		setNameSku(nameSku);
		setCodeSku(codeSku);
		setImageSku(imageSku);
	};

	const deleteItemOrder = async () => {
		let deleteOrder = items.filter((order) => order.id !== deleteId);
		setItems(deleteOrder);
		const removeItem = [];
		if (itemDeleted.length > 0) {
			itemDeleted.forEach((arr) => {
				if (arr.id === deleteId) {
					const body = {
						...arr,
						quantity: 0,
					};
					removeItem.push(body);
				} else {
					const body = {
						...arr,
					};
					removeItem.push(body);
				}
			});
		} else {
			items.forEach((arr) => {
				if (arr.id === deleteId) {
					const body = {
						...arr,
						quantity: 0,
					};
					removeItem.push(body);
				} else {
					const body = {
						...arr,
					};
					removeItem.push(body);
				}
			});
		}
		setItemDeleted(removeItem);
		toast.success("Produto deletado com sucesso! 😃");
	};

	const saveOrder = () => {
		if (itemDeleted.length > 0) {
			editItems(itemDeleted);
		} else {
			editItems(items);
		}

		setEdit(false);
		const subTotal = { value: items.map((obj) => obj.totalAmount) };
		let total = 0;
		for (let i = 0; i < subTotal.value.length; i++) {
			total += subTotal.value[i];
		}
		setAmount((oldAmount) => ({
			...oldAmount,
			ItemsAmount: total + amount.discountAmount,
		}));
		setAmount((oldAmount) => ({
			...oldAmount,
			totalAmount: total + amount.feeAmount + amount.ShippingAmount,
		}));
	};

	const editItems = async (newItems) => {
		setLoading(true);
		let body = newItems.map((prd) => {
			return {
				unitPrice: prd.unitPrice,
				discountPrice: prd.discountPrice,
				quantity: prd.quantity,
				skuId: prd.sku.id,
				skuCode: prd.sku.code,
			};
		});
		await APIV2.put(`management/commerce/orders/${orderId}/items`, body)
			.then((response) => {
				toast.success("Pedido editado com sucesso! 😃");
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const cancelOrder = async () => {
		setLoading(true);
		const statusOrder = "CanceledByAdmin";
		await APIV2.put(
			`management/commerce/orders/${orderId}/status/${statusOrder}`
		)
			.then((response) => {
				//setLoading(false)
				toast.success("Pedido cancelado com sucesso!");
			})
			.catch((error) => {
				console.error(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const cancelEdit = () => {
		setLoading(true);
		getOrders(orderId);
		setEdit(false);
	};

	const showTotalsOrder = () => {
		return (
			<div className="w-100">
				<div className="d-flex flex-row justify-content-end">
					<div className="d-flex flex-column">
						<div className="order-body-box pr-4">
							{IsValidNumber(
								order?.Totals?.ItemsAmount,
								order?.Totals?.DiscountAmount
							) &&
								order?.Totals?.ItemsAmount + order?.Totals?.DiscountAmount >
									0 && <p className="text-blueish m-0"> SubTotal:</p>}
							{IsValidNumber(order?.Totals?.DiscountAmount) &&
								order?.Totals?.DiscountAmount > 0 && (
									<p className="text-blueish m-0 p-0">Desconto:</p>
								)}
							{IsValidNumber(order?.Totals?.FeeAmount) &&
								order?.Totals?.FeeAmount > 0 && (
									<p className="text-blueish m-0 p-0">Taxas:</p>
								)}
							{IsValidNumber(order?.Totals?.ShippingAmount) &&
								order?.Totals?.ShippingAmount > 0 && (
									<p className="text-blueish m-0 p-0">Frete:</p>
								)}
						</div>
						<div
							className="d-flex mt-3 pt-1"
							style={{ fontSize: "16px", borderTop: "1px dashed #E9ECEC" }}
						>
							<p className="text-blueish m-0 p-0">Total do Pedido:</p>
						</div>
					</div>

					<div className="d-flex flex-column">
						<div className="order-body-box pl-4 d-flex flex-column align-items-end">
							{IsValidNumber(
								order?.Totals?.ItemsAmount,
								order?.Totals?.DiscountAmount
							) &&
								order?.Totals?.ItemsAmount + order?.Totals?.DiscountAmount >
									0 && (
									<p className="text-blueish m-0">
										<strong>
											{(
												order?.Totals?.ItemsAmount +
												order?.Totals?.DiscountAmount
											)?.toLocaleString("pt-br", {
												style: "currency",
												currency: "BRL",
											})}
										</strong>
									</p>
								)}
							{IsValidNumber(order?.Totals?.DiscountAmount) &&
								order?.Totals?.DiscountAmount > 0 && (
									<p className="text-blueish m-0 p-0">
										<strong>
											{order?.Totals?.DiscountAmount?.toLocaleString("pt-br", {
												style: "currency",
												currency: "BRL",
											})}
										</strong>
									</p>
								)}
							{IsValidNumber(order?.Totals?.FeeAmount) &&
								order?.Totals?.FeeAmount > 0 && (
									<p className="text-blueish m-0 p-0">
										<strong>
											{order?.Totals?.FeeAmount?.toLocaleString("pt-br", {
												style: "currency",
												currency: "BRL",
											})}
										</strong>
									</p>
								)}
							{IsValidNumber(order?.Totals?.ShippingAmount) &&
								order?.Totals?.ShippingAmount > 0 && (
									<p className="text-blueish m-0 p-0">
										<strong>
											{order?.Totals?.ShippingAmount?.toLocaleString("pt-br", {
												style: "currency",
												currency: "BRL",
											})}
										</strong>
									</p>
								)}
						</div>

						<div
							className="d-flex justify-content-end mt-3 pt-1"
							style={{ fontSize: "16px", borderTop: "1px dashed #E9ECEC" }}
						>
							<p className="text-blueish m-0 p-0">
								<strong>
									{order?.Totals?.TotalAmount.toLocaleString("pt-br", {
										style: "currency",
										currency: "BRL",
									})}
								</strong>
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const skeleton = () => {
		const skele = [0, 1, 2];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={1100}
				height={50}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 40"
				style={{ width: "100%" }}
			>
				<rect x="10" y="1" rx="1" ry="2" width="50" height="100" />
				<rect x="80" y="8" rx="1" ry="2" width="450" height="10" />
				<rect x="80" y="20" rx="3" ry="3" width="100" height="10" />
				<rect x="570" y="8" rx="3" ry="3" width="30" height="120" />
				<rect x="650" y="8" rx="3" ry="3" width="120" height="120" />
				<rect x="800" y="8" rx="3" ry="3" width="120" height="120" />
				<rect x="950" y="8" rx="3" ry="3" width="120" height="120" />
			</ContentLoader>
		));
	};

	return (
		<div style={{ paddingTop: "40px" }}>
			<table className="table history-import border-0">
				<thead className="">
					<tr>
						<th className="text-left border-bottom border-top pl-0">
							ITENS DO PEDIDO
						</th>
						<th className="text-center border-bottom border-top">QTD</th>
						<th
							className="text-center border-bottom border-top"
							style={{ width: "150px" }}
						>
							PREÇO UN.
						</th>
						<th
							className="text-center border-bottom border-top"
							style={{ width: "150px" }}
						>
							DESC.
						</th>
						<th
							className="text-right border-bottom border-top mr-0 pr-0"
							style={{ width: "150px" }}
						>
							TOTAL
						</th>
					</tr>
				</thead>
				{loading === true ? null : (
					<tbody>
						{order?.Items.map((item, i) => (
							<tr key={item?.id} id={item?.id}>
								<td className="py-2 d-flex flex-row border-bottom border-top-0 pl-0">
									<div className="pr-3">
										<img
											src={
												!item?.Sku?.ImageUrl
													? "/images/placeholder.jpg"
													: item?.Sku?.ImageUrl
											}
											alt="Produto"
											width="40px"
											className="shadow-sm mx-auto d-block"
										/>
									</div>
									<div className="d-flex flex-column">
										<label className="m-0 p-0">{item?.Sku?.Name}</label>
										<em style={{ fontSize: "12px" }} className="p-0 m-0">
											{productDisplayCodeName === "Code"
												? item?.Sku?.ProductCode && (
														<em className="mr-2">
															{i18n.t(`orderDetails.General.CodProduct`)}:{" "}
															{item?.Sku?.ProductCode}
														</em>
												  )
												: productDisplayCodeName === "ReferenceCode"
												? item?.sku?.ReferenceCode && (
														<em className="mr-2">
															{i18n.t(`orderDetails.General.CodProduct`)}:{" "}
															{item?.sku?.ReferenceCode}
														</em>
												  )
												: productDisplayCodeName === "AuxCode"
												? item?.sku?.AuxCode && (
														<em className="mr-2">
															{i18n.t(`orderDetails.General.CodProduct`)}:{" "}
															{item?.sku?.AuxCode}
														</em>
												  )
												: ""}

											{item?.Sku?.Code && (
												<em className="mr-2" style={{ fontSize: "12px" }}>
													{" | "}
													{i18n.t(`orderDetails.General.CodSku`)}:{" "}
													{item?.Sku?.Code}
												</em>
											)}

											{item?.Sku?.BarCode && (
												<em style={{ fontSize: "12px", color: "#BCBEC0" }}>
													{" | "}
													{i18n.t(`orderDetails.General.Ean`)}:{" "}
													{item?.Sku?.BarCode}
												</em>
											)}
										</em>
									</div>
								</td>

								{edit === true ? (
									<td className="border-bottom border-top-0">
										<div className="counter-price">
											<button onClick={() => {}}>
												<i
													className="material-icons"
													style={
														item?.Quantity <= 1 ? { color: "#C4C4C4" } : null
													}
												>
													remove
												</i>
											</button>
											<input className="mb-1" value={item?.Quantity} />
											<button onClick={() => {}}>
												<i className="material-icons">add</i>
											</button>
										</div>
									</td>
								) : (
									<td className="pt-3 text-center border-bottom border-top-0">
										{item?.Quantity}
									</td>
								)}
								{edit === true ? (
									<td className="pt-2 text-center border-bottom border-top-0">
										{priceId === item?.Sku?.id ? (
											<div className="button-input-price">
												<MaskEditPrice
													defaultValue={item?.UnitPrice?.toLocaleString(
														"pt-br",
														{ minimumFractionDigits: 2 }
													)}
													name="unitPrice"
													onChange={(e) => setUnitPrice(e.currentTarget.value)}
													aprove={aprovePrice}
												/>
											</div>
										) : (
											<div className="button-edit-price">
												<button
													onClick={() => changePrice(item?.Sku?.id)}
													className="btn btn-light outline border-0"
												>
													{item?.UnitPrice?.toLocaleString("pt-br", {
														style: "currency",
														currency: "BRL",
													})}
												</button>
											</div>
										)}
									</td>
								) : (
									<td className="pt-3 text-center border-bottom border-top-0">
										{item?.UnitPrice?.toLocaleString("pt-br", {
											style: "currency",
											currency: "BRL",
										})}
									</td>
								)}
								{edit === true ? (
									<td className="pt-2 text-center border-bottom border-top-0">
										{discountPriceId === item?.Sku?.Code ? (
											<div className="m-0 p-0">
												<MaskEditPrice
													defaultValue={item?.DiscountPrice?.toLocaleString(
														"pt-br",
														{ minimumFractionDigits: 2 }
													)}
													name="discountPrice"
													onChange={(e) =>
														setDiscountPrice(e.currentTarget.value)
													}
													aprove={aproveDiscount}
												/>
											</div>
										) : (
											<button
												onClick={() => changePrice(item?.id, item?.Sku?.Code)}
												className="btn btn-light outline border-0 m-0 py-2"
											>
												{item?.DiscountPrice?.toLocaleString("pt-br", {
													style: "currency",
													currency: "BRL",
												})}
											</button>
										)}
									</td>
								) : (
									<td className="pt-3 text-center border-bottom border-top-0">
										{item?.DiscountPrice?.toLocaleString("pt-br", {
											style: "currency",
											currency: "BRL",
										})}
									</td>
								)}

								<td className="pt-3 text-right border-bottom border-top-0 mr-0 pr-0">
									{Math.sign(item?.TotalAmount) === -1
										? totalValue.toLocaleString("pt-br", {
												style: "currency",
												currency: "BRL",
										  })
										: item?.TotalAmount?.toLocaleString("pt-br", {
												style: "currency",
												currency: "BRL",
										  })}
								</td>

								{edit === true && (
									<td className="border-bottom border-top-0">
										<button
											type="button"
											className="btn btn-light btn-sm outline btn-icon"
											data-toggle="modal"
											data-target="#cancelProduct"
											onClick={() =>
												removeList(
													item?.id,
													item?.Sku?.productSku,
													item?.Sku?.Code,
													item?.Sku?.ImageUrl
												)
											}
										>
											<i className="material-icons text-danger">
												delete_outline
											</i>
										</button>
									</td>
								)}

								<div
									className="modal fade"
									id="cancelProduct"
									tabIndex="-1"
									role="dialog"
									aria-labelledby="cancelProduct"
									aria-hidden="true"
								>
									<div className="modal-dialog" role="document">
										<div className="modal-content">
											<div className="modal-header">
												<div className="d-flex flex-column">
													<h5 className="modal-title mb-2" id="cancelProduct">
														Deseja excluir o produto do pedido?
													</h5>
													<span style={{ color: "#4B5F79" }}>
														Atenção! Essa ação não poderá ser revertida!
													</span>
												</div>
												<button
													type="button"
													className="close text-danger"
													data-dismiss="modal"
													aria-label="Close"
												>
													<span aria-hidden="true">&times;</span>
												</button>
											</div>
											<div className="modal-body ml-0">
												<div className="d-flex align-items-center justify-content-start m-0 p-0">
													<div className="mr-4">
														<img
															src={
																!imageSku ? "/images/placeholder.jpg" : imageSku
															}
															alt="Produto"
															width="40px"
															className="shadow-sm mx-auto d-block"
														/>
													</div>
													<span className="text-start pt-2 pb-1">
														{nameSku}
														<br />
														<em
															style={{ fontSize: "11px" }}
															className="p-0 m-0"
														>{`Cód: ${codeSku}`}</em>
													</span>
												</div>
											</div>
											<div className="modal-footer">
												<button
													type="button"
													className="btn btn-danger"
													data-dismiss="modal"
													onClick={deleteItemOrder}
												>
													Sim, excluir
												</button>
												<button
													type="button"
													className="btn btn-light"
													data-dismiss="modal"
												>
													Cancelar
												</button>
											</div>
										</div>
									</div>
								</div>
							</tr>
						))}
					</tbody>
				)}
			</table>

			{!loading && showTotalsOrder()}

			{loading === true && <div className="m-0 p-0">{skeleton()}</div>}

			<div className="m-0 p-0 ">
				{order.Status === "1" &&
					localStorage.getItem("useOrderEditor") === "true" && (
						<div className=" d-flex justify-content-between">
							<button
								type="button"
								className="btn btn-danger outline"
								data-toggle="modal"
								data-target="#cancelOrder"
							>
								Cancelar Pedido
							</button>
							{edit === true ? (
								<div>
									<button
										type="button"
										className="btn btn-blueish-80 outline mr-2"
										onClick={cancelEdit}
									>
										Cancelar Edição
									</button>
									<button
										type="button"
										className="btn btn-success"
										onClick={saveOrder}
									>
										Salvar Pedido
									</button>
								</div>
							) : (
								<button
									type="button"
									className="btn btn-blueish-80"
									onClick={() => setEdit(true)}
								>
									Editar Pedido
								</button>
							)}
						</div>
					)}
			</div>
			<Modal
				id={"cancelOrder"}
				title={"Deseja deletar este pedido pedido?"}
				confirm={true}
				actionDelete={cancelOrder}
			/>
		</div>
	);
}
