import React, { useEffect, useState } from "react";
import {
	Divider,
	Link,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { OrderModel } from "../../domain/models/commerce/OrderModel";
import { CustomDataModel } from "../../domain/models/commerce/CustomDataModel";
import {
	isValidUrl,
	isValueInStringEnum,
} from "../../infrastructure/utils/TypeValidator";
import CustomTypography from "../../MUICustomComponents/CustomTypography";
import CustomIcon from "../../MUICustomComponents/CustomIcon";
import { i18n } from "../../translate/i18n";
import { AttachmentKeys } from "./AttachmentKeys";

type Props = {
	orderModel: OrderModel;
};

export default function OrderAttachments({ orderModel }: Props) {
	const [attachmentList, setAttachmentList] = useState<CustomDataModel[]>([]);

	useEffect(() => {
		if (!orderModel?.customData) return;
		createAttachmentList(orderModel.customData);
	}, [orderModel]);

	const createAttachmentList = (customData: CustomDataModel[]): void => {
		setAttachmentList(customData);
	};

	const getAttachmentTitle = (itemKey: string): string => {
		if (isValueInStringEnum(AttachmentKeys, itemKey))
			return i18n.t(`attachments.${itemKey}`);
		return itemKey;
	};

	const attachmentItem = (item: CustomDataModel) => (
		<TableRow key={item.key}>
			<TableCell component="th" scope="row" sx={{ whiteSpace: "nowrap" }}>
				<Typography>{getAttachmentTitle(item.key)}</Typography>
			</TableCell>
			{isValidUrl(item.value, false) ? (
				<TableCell align="right">
					<Link
						href={item.value}
						underline="none"
						color="inherit"
						target="_blank"
						rel="noreferrer"
					>
						{i18n.t("attachments.Open").toString()}{" "}
						<CustomIcon icon={<OpenInNewIcon />} variant="small" />
					</Link>
				</TableCell>
			) : (
				<TableCell align="left">
					<Typography sx={{ whiteSpace: "pre-wrap" }}>{item.value}</Typography>
				</TableCell>
			)}
		</TableRow>
	);

	return (
		<>
			{attachmentList.length > 0 && (
				<>
					<Divider sx={{ my: 3 }} />
					<Paper variant="grey10" elevation={0}>
						<CustomTypography variant="smallTitle">
							{i18n.t("attachments.Title").toString()}
						</CustomTypography>
						<Table sx={{ width: "50%" }}>
							<TableBody>{attachmentList?.map(attachmentItem)}</TableBody>
						</Table>
					</Paper>
				</>
			)}
		</>
	);
}
