import React from "react";
import { Slide, toast } from "react-toastify";
import "../assets/css/components/common/NewToastComponentStyle.scss";

export default function NewToastComponent(toastOptions = {}) {
	const iconDict = {
		success: "check_circle",
		info: "info",
		error: "error",
		warning: "error",
	};

	const handleAutoCloseTimer = () => {
		if (toastOptions?.noTimer) return false;
		else if (toastOptions?.specifyTimer) return toastOptions.specifyTimer;
		else return toastOptions?.message ? 10000 : 5000;
	};

	const handleCloseButton = () => {
		if (toastOptions?.noCloseButton) return false;
		else {
			return ({ closeToast }) => (
				<i
					className="new-notification-toast-closeBtn material-icons-round mt-3 mr-3"
					onClick={closeToast}
				>
					close
				</i>
			);
		}
	};

	const ToastComponent = () => {
		return (
			<>
				<aside className="toast-status-bar"></aside>
				<section className="d-flex flex-column justify-content-center ml-3 py-3">
					<header className="d-flex align-items-center">
						<i className="toast-icon material-icons-round mr-2">
							{iconDict[toastOptions?.status]}
						</i>
						<span className="toast-title">{toastOptions?.title || ""}</span>
					</header>
					{toastOptions?.message && (
						<span className="toast-message mt-2 ml-4 pl-2">
							{toastOptions?.message}
						</span>
					)}
				</section>
			</>
		);
	};

	const options = {
		className: `new-notification-toast ${toastOptions?.status || "info"}-toast`,
		bodyClassName: "new-notification-toast-body",
		position: toast.POSITION.TOP_RIGHT,
		autoClose: handleAutoCloseTimer(),
		hideProgressBar: true,
		transition: Slide,
		closeButton: handleCloseButton(),
		closeOnClick: !toastOptions?.noCloseOnClick,
		draggable: false,
	};

	return toastOptions?.updateToastId
		? toast.update(toastOptions.updateToastId, {
				render: <ToastComponent />,
				...options,
		  })
		: toast(<ToastComponent />, options);
}
