import React, { PropsWithChildren } from "react";
import { SxProps, Theme, Typography } from "@mui/material";
import {
	PaletteDiscriminatedUnion,
	paletteObject,
} from "../theme/foundations/palette";

type Props = {
	variant:
		| "default"
		| "title"
		| "smallTitle"
		| "subtitle"
		| "small"
		| "secondaryTitle"
		| "big";
	isBold?: boolean;
	marginBottom?: number;
	color?: PaletteDiscriminatedUnion;
	fontStyle?: "normal" | "italic";
	sx?: SxProps<Theme>;
};

export default function CustomTypography({
	variant = "default",
	isBold = false,
	marginBottom = 0,
	color = "blueish80",
	fontStyle = "normal",
	sx,
	children,
}: PropsWithChildren<Props>): JSX.Element {
	if (variant === "default")
		return (
			<Typography
				fontSize={14}
				sx={{ ...(isBold && { fontWeight: "bold" }), ...sx }}
				color={paletteObject[color].main}
			>
				{children}
			</Typography>
		);
	if (variant === "title")
		return (
			<Typography
				variant="h5"
				mb={marginBottom !== 0 ? marginBottom : 3}
				sx={{ fontWeight: "bold", ...sx }}
				color={paletteObject[color].main}
			>
				{children}
			</Typography>
		);
	if (variant === "smallTitle")
		return (
			<Typography
				variant="h6"
				mb={2}
				sx={{ fontWeight: "bold", ...sx }}
				color={paletteObject[color].main}
			>
				{children}
			</Typography>
		);
	if (variant === "subtitle")
		return (
			<Typography
				fontSize={14}
				variant="h5"
				sx={{ fontWeight: "bold", ...sx }}
				color={paletteObject[color].main}
			>
				{children}
			</Typography>
		);
	if (variant === "small")
		return (
			<Typography
				fontSize={12}
				sx={{
					...(isBold && { fontWeight: "bold" }),
					mb: marginBottom,
					fontStyle: fontStyle,
					...sx,
				}}
				color={paletteObject[color].main}
			>
				{children}
			</Typography>
		);
	if (variant === "secondaryTitle")
		return (
			<Typography
				variant="h6"
				fontSize={18}
				sx={{ ...(isBold && { fontWeight: "bold" }), mb: marginBottom, ...sx }}
				color={paletteObject[color].main}
			>
				{children}
			</Typography>
		);
	if (variant === "big")
		return (
			<Typography
				fontSize={32}
				sx={{ ...(isBold && { fontWeight: "bold" }), mb: marginBottom, ...sx }}
				color={paletteObject[color].main}
			>
				{children}
			</Typography>
		);
	return <></>;
}
