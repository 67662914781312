import React, { ChangeEvent, useRef, useState } from "react";
import { SelectChangeEvent, Stack } from "@mui/material";
import AppGeneral from "./AppGeneral";
import AppCatalog from "./AppCatalog";
import AppPayment from "./AppPayment";
import AppClearSale from "./AppClearSale";
import AppOrder from "./AppOrder";
import AppCheckout from "./AppCheckout";
import AppSale from "./AppSale";
import { RetailerConfiguration } from "../../../../domain/dtos/retailerManagement/retailerConfigurations/RetailerConfiguration";
import { handleGenericChange } from "../../../../infrastructure/utils/handleGenericChange";
import { AppConfig } from "../types/AppConfig";
import RetailerUpdateButton from "../components/RetailerUpdateButton";
import { ProductOrdering } from "../../../../domain/dtos/retailerManagement/retailerConfigurations/ProductOrdering";
import { ComplementaryNameDisplay } from "../../../../domain/dtos/retailerManagement/retailerConfigurations/ComplementaryNameDisplay";
import { TimeType } from "../../../../domain/dtos/retailerManagement/retailerConfigurations/TimeType";
import { EcommerceBehaviorSellerList } from "../../../../domain/dtos/retailerManagement/retailerConfigurations/EcommerceBehaviorSellerList";
import { EcommerceLocationSellerList } from "../../../../domain/dtos/retailerManagement/retailerConfigurations/EcommerceLocationSellerList";
import { percentageRulesCommercialConditions } from "../../../PaymentConfigurations/CommercialConditions/utils/PercentageRulesCommercialConditions";
import AppCart from "./AppCart";
import {
	FormErrors,
	FormValidations,
	FormValidator,
	InputValidator,
} from "../../../../infrastructure/utils/FormUtils";
import { CountryDataType } from "../../../../components/InternationalPhoneInput/InternationalPhoneInputService";
import { normalizeDigitsOnly } from "../../../../infrastructure/utils/StringUtils";
import { getValueOrDefault } from "../../../../infrastructure/utils/getValueOrDefault";

type Props = {
	retailerConfigurations: RetailerConfiguration;
	isLoadingUpdate: boolean;
	updateRetailerConfig: (configPart: AppConfig) => Promise<void>;
};

export default function AppConfigurations({
	retailerConfigurations,
	isLoadingUpdate,
	updateRetailerConfig,
}: Props) {
	const [appConfig, setAppConfig] = useState<AppConfig>({
		// App - General
		enableWhatsappButton: retailerConfigurations.enableWhatsappButton,
		whatsappPhoneNumber: getValueOrDefault(
			retailerConfigurations.whatsappPhoneNumber,
			null
		),
		whatsappMessage: getValueOrDefault(
			retailerConfigurations.whatsappMessage,
			null
		),
		// App - Catalog
		buyWithOneClick: retailerConfigurations.buyWithOneClick,
		isShowSubCategories: retailerConfigurations.isShowSubCategories,
		alwaysIncludeECommerce: retailerConfigurations.alwaysIncludeECommerce,
		showReaderCodeBar: retailerConfigurations.showReaderCodeBar,
		enableCRMPropz: retailerConfigurations.enableCRMPropz,
		showSkuSelector: retailerConfigurations.showSkuSelector,
		showUnavailableProducts: retailerConfigurations.showUnavailableProducts,
		productDisplayCodeName: getValueOrDefault(
			retailerConfigurations.productDisplayCodeName,
			null
		),
		catalogSort: retailerConfigurations.catalogSort ?? ProductOrdering.None,
		showComplementaryNameOnProductList:
			retailerConfigurations.showComplementaryNameOnProductList ??
			ComplementaryNameDisplay.Code,
		showComplementaryNameOnProductDetail:
			retailerConfigurations.showComplementaryNameOnProductDetail ??
			ComplementaryNameDisplay.Code,
		customRuleByCategories: getValueOrDefault(
			retailerConfigurations.customRuleByCategories,
			null
		),
		// App - Sale
		hasDealerCode: retailerConfigurations.hasDealerCode,
		isDealerRankingByNetwork: retailerConfigurations.isDealerRankingByNetwork,
		// App - Order
		pickUpTime: getValueOrDefault(retailerConfigurations.pickUpTime, 0),
		pickUpTimeType: retailerConfigurations.pickUpTimeType ?? TimeType.Days,
		// App - Payment
		hasSplitPayment: retailerConfigurations.hasSplitPayment,
		percentageSplit: getValueOrDefault(
			retailerConfigurations.percentageSplit,
			0.0
		),
		useDealerCommission: retailerConfigurations.useDealerCommission,
		isSellerTaxPayor: retailerConfigurations.isSellerTaxPayor,
		// App - Cart
		enablePromotionEngine: retailerConfigurations.enablePromotionEngine,
		enableUserPromotion: retailerConfigurations.enableUserPromotion,
		enablePickupOnlyCartMessage:
			retailerConfigurations.enablePickupOnlyCartMessage,
		minAmountPurchase: getValueOrDefault(
			retailerConfigurations.minAmountPurchase,
			0.0
		),
		pickupOnlyCartMessage: getValueOrDefault(
			retailerConfigurations.pickupOnlyCartMessage,
			null
		),
		// App - Checkout
		isMinAmountTotalPurchase: retailerConfigurations.isMinAmountTotalPurchase,
		showAllSellersOnCheckout: retailerConfigurations.showAllSellersOnCheckout,
		displayRadius: getValueOrDefault(retailerConfigurations.displayRadius, 0),
		forceSellerNameAtList: retailerConfigurations.forceSellerNameAtList,
		showEcommerceSellerListCheckout:
			retailerConfigurations.showEcommerceSellerListCheckout ??
			EcommerceBehaviorSellerList.AlwaysShow,
		locationEcommerceSellerListCheckout:
			retailerConfigurations.locationEcommerceSellerListCheckout ??
			EcommerceLocationSellerList.AlwaysTop,
		checkoutPaymentAlertText: getValueOrDefault(
			retailerConfigurations.checkoutPaymentAlertText,
			null
		),
		// App - ClearSale
		sdkClearSaleFingerprint: getValueOrDefault(
			retailerConfigurations.sdkClearSaleFingerprint,
			null
		),
	});
	const currentCountryRef = useRef<CountryDataType | null>(null);
	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const formValidationMapper: FormValidations = {
		whatsappPhoneNumber: (value: string) =>
			InputValidator.isInternationalPhoneInputValid(
				value,
				currentCountryRef.current
			),
		percentageSplit: (value: string) => InputValidator.isPercentageValid(value),
	};

	const handlePercentageSplitChange = (
		event: ChangeEvent<HTMLInputElement>
	) => {
		const { name, value } = event.target;
		const formattedValue = percentageRulesCommercialConditions(value);
		const errorMessage: string[] =
			formValidationMapper.percentageSplit(formattedValue);
		setFormErrors((prevState) => ({ ...prevState, [name]: errorMessage }));
		const floatValue = parseFloat(formattedValue);
		setAppConfig((prevState) => ({
			...prevState,
			percentageSplit: floatValue,
		}));
	};

	const handleWhatsappPhoneChange = (
		phone: string,
		currentCountry: CountryDataType | null
	): void => {
		currentCountryRef.current = currentCountry;
		let completePhone: string = phone;
		if (phone && currentCountry) {
			completePhone = normalizeDigitsOnly(currentCountry.phone + completePhone);
		}
		const errorMessage: string[] =
			formValidationMapper.whatsappPhoneNumber(phone);
		setFormErrors((prevState) => ({
			...prevState,
			whatsappPhoneNumber: errorMessage,
		}));
		setAppConfig((prevState) => ({
			...prevState,
			whatsappPhoneNumber: completePhone,
		}));
	};

	const handleChange = (
		event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>,
		checked?: boolean
	) => {
		if (event.target.name === "percentageSplit") {
			handlePercentageSplitChange(event as ChangeEvent<HTMLInputElement>);
			return;
		}
		handleGenericChange<AppConfig>(setAppConfig, event, checked);
	};

	const handleSubmit = (): void => {
		const newConfig: AppConfig = {
			...appConfig,
			whatsappPhoneNumber: getValueOrDefault(
				appConfig.whatsappPhoneNumber?.trim(),
				null
			),
			whatsappMessage: getValueOrDefault(
				appConfig.whatsappMessage?.trim(),
				null
			),
			productDisplayCodeName: getValueOrDefault(
				appConfig.productDisplayCodeName?.trim(),
				null
			),
			customRuleByCategories: getValueOrDefault(
				appConfig.customRuleByCategories?.trim(),
				null
			),
			pickUpTime: getValueOrDefault(Number(appConfig.pickUpTime), 0),
			percentageSplit: getValueOrDefault(appConfig.percentageSplit, 0.0),
			minAmountPurchase: getValueOrDefault(
				Number(appConfig.minAmountPurchase),
				0.0
			),
			pickupOnlyCartMessage: getValueOrDefault(
				appConfig.pickupOnlyCartMessage?.trim(),
				null
			),
			displayRadius: getValueOrDefault(Number(appConfig.displayRadius), 0),
			checkoutPaymentAlertText: getValueOrDefault(
				appConfig.checkoutPaymentAlertText?.trim(),
				null
			),
			sdkClearSaleFingerprint: getValueOrDefault(
				appConfig.sdkClearSaleFingerprint?.trim(),
				null
			),
		};
		let errors: FormErrors = {};
		for (let [key, value] of Object.entries(newConfig)) {
			if (formValidationMapper[key] === undefined) continue;
			let configValue = value;
			if (configValue !== null) configValue = String(configValue);
			const errorMessages: string[] = formValidationMapper[key](configValue);
			errors[key] = errorMessages;
		}
		setFormErrors(errors);
		setAppConfig(newConfig);
		if (FormValidator.hasError(errors)) return;
		updateRetailerConfig(newConfig);
	};

	return (
		<>
			<Stack gap={2}>
				<AppGeneral
					appConfig={appConfig}
					handleWhatsappPhoneChange={handleWhatsappPhoneChange}
					onChange={handleChange}
					formErrors={formErrors}
				/>
				<AppCatalog appConfig={appConfig} onChange={handleChange} />
				<AppSale appConfig={appConfig} onChange={handleChange} />
				<AppOrder appConfig={appConfig} onChange={handleChange} />
				<AppPayment
					appConfig={appConfig}
					onChange={handleChange}
					formErrors={formErrors}
				/>
				<AppCart appConfig={appConfig} onChange={handleChange} />
				<AppCheckout appConfig={appConfig} onChange={handleChange} />
				<AppClearSale appConfig={appConfig} onChange={handleChange} />
			</Stack>
			<RetailerUpdateButton
				isLoadingUpdate={isLoadingUpdate}
				hasError={FormValidator.hasError(formErrors)}
				onSubmit={handleSubmit}
			/>
		</>
	);
}
