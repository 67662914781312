import React, { useState, useEffect } from "react";
import ChangeDataPickupInStoreModal from "../OrderModal/ChangeDataPickupInStoreModal";
import Skeleton from "./Skeleton";
import { useOrders } from "../../providers/OrdersContext";
import { i18n } from "../../../../translate/i18n";
import {
	formatPhoneNumber,
	formatDocument,
	formatZipCode,
	formatDate,
} from "../../../../infrastructure/utils/FormatMask";

export default function TrackingOrderPickupInStore() {
	const {
		order,
		loading,
		flowStatus,
		orderId,
		handleChangeTrackings,
		setLoadingStatus,
	} = useOrders();
	const [count, setCount] = useState(0);
	const [disableCarousel, setDisableCarousel] = useState({
		prev: true,
		next: false,
	});
	const [trackingList, setTrackingList] = useState([]);
	useEffect(() => {
		setTrackingList(order?.Trackings);
	}, [order]);
	useEffect(() => {
		if (count === 0) {
			setDisableCarousel({ prev: true, next: false });
		} else if (count === trackingList.length - 1) {
			setDisableCarousel({ prev: false, next: true });
		} else {
			setDisableCarousel({ prev: false, next: false });
		}
	}, [count]);

	const handleFormSubmit = () => {
		setLoadingStatus(true);
		const formTracking = [
			{
				addresseeName: order?.Buyer?.FullName,
				addresseePhoneNumber: formatPhoneNumber(order?.Buyer?.PhoneNumber),
				addresseeEmail: order?.Buyer?.Email,
				documentPickup: formatDocument(
					order?.Buyer?.DocumentType,
					order?.Buyer?.Document
				),
				invoiceNumber: order?.Invoices[0]?.Number,
				carrierName: order?.ShippingData?.Shipment?.CarrierName,
				shippingReference: order?.ShippingData?.Shipment?.ShippingReference,
				deliveryDate: new Date().toISOString(),
			},
		];

		const button = document.getElementById("changeDataWithdrawall");
		button.click();
		handleChangeTrackings(orderId, formTracking);
	};

	const handleShippingTypeDelivery = () => (
		<>
			<div className="order-body-box">
				<p className="text-blueish">
					<strong>{order?.Seller?.CompanyName}</strong>
				</p>
				<p className="text-blueish">
					{order?.Seller?.Address?.Street} {order?.Seller?.Address?.Number}{" "}
					{order?.Seller?.Address?.Complement}
				</p>
				<p className="text-blueish">
					{order?.Seller?.Address?.Neighborhood} {order?.Seller?.Address?.City}{" "}
					{order?.Seller?.Address?.State}
				</p>
				{order?.Seller?.Address?.ZipCode && (
					<p className="text-blueish">
						{i18n.t("orderDetails.General.ZipCode")}:{" "}
						{formatZipCode(order?.Seller?.Address?.ZipCode)}
					</p>
				)}
			</div>
			{order?.Trackings?.length > 1 && showRegisteredListTracking()}
			{order?.Trackings?.length <= 1 && showRegisteredTracking()}
		</>
	);

	const showButtonShippingTypeDelivery = () => {
		return (
			<div className="btn-tooltip pt-4">
				<button
					className={
						order?.Status !== "WaitingDispatch"
							? "btn btn-grey-50 d-flex align-items-center justify-content-center w-100"
							: "btn btn-grey-80 d-flex align-items-center justify-content-center w-100"
					}
					style={{ height: "43px" }}
					disabled={order?.Status !== "WaitingDispatch" ? true : false}
					onClick={handleFormSubmit}
				>
					<i className="material-icons-outlined px-2">shopping_bag</i>
					{i18n.t("orderDetails.TypeButtom.AddDataWithdrawal")}
				</button>
				{order?.Status !== "WaitingDispatch" && (
					<span className="msg-tooltip" style={{ width: "182px" }}>
						{i18n.t("orderDetails.Trackings.NoPickupInStore")}
					</span>
				)}
			</div>
		);
	};

	const showRegisteredTracking = () => {
		return (
			<>
				<div className="order-header-box pt-2 d-flex justify-content-start">
					<h2>{i18n.t("orderDetails.Trackings.DataWithdrawal")}</h2>
					{order?.Status === "WaitingDispatch" && (
						<a
							className="hyper-link ml-3"
							style={{ paddingTop: "7px", fontWeight: "400" }}
							data-toggle="modal"
							data-target="#changeDataWithdrawall"
						>
							{i18n.t(`orderDetails.General.Change`)}
						</a>
					)}
				</div>
				<div className="order-body-box">
					{order?.Trackings[0] ? (
						<>
							<p className="text-blueish">
								<strong>{order?.Trackings[0]?.AddresseeName}</strong>
							</p>
							<p className="text-blueish">
								<strong>{i18n.t("orderDetails.General.BuyerDoc")}: </strong>
								{formatDocument("Cpf", order?.Trackings[0]?.DocumentPickup)}
							</p>
							<p className="text-blueish">
								<strong>{i18n.t("orderDetails.General.Email")}: </strong>
								{order?.Trackings[0]?.AddresseeEmail}
							</p>

							<p className="text-blueish">
								<strong>{i18n.t("orderDetails.General.PhoneNumber")}:</strong>{" "}
								{formatPhoneNumber(order?.Trackings[0]?.AddresseePhoneNumber)}
							</p>
						</>
					) : (
						<>
							<p className="text-blueish">
								<strong>{order?.Buyer?.FullName}</strong>
							</p>
							<p className="text-blueish">
								<strong>{i18n.t("orderDetails.General.BuyerDoc")}:</strong>{" "}
								{formatDocument(
									order?.Buyer?.DocumentType,
									order?.Buyer?.Document
								)}
							</p>
							<p className="text-blueish">
								<strong>{i18n.t("orderDetails.General.Email")}:</strong>{" "}
								{order?.Buyer?.Email}
							</p>

							<p className="text-blueish">
								<strong>{i18n.t("orderDetails.General.PhoneNumber")}:</strong>{" "}
								{formatPhoneNumber(order?.Buyer?.PhoneNumber)}
							</p>
						</>
					)}
				</div>
			</>
		);
	};

	const showRegisteredListTracking = () => {
		return (
			<>
				<div className="order-header-box">
					<h2>{i18n.t("orderDetails.Trackings.DataWithdrawal")}</h2>
				</div>
				<div
					id="carouselTrackingControls"
					className="carousel slide double-cards-carousel"
					data-ride="carousel"
					data-interval="false"
					data-wrap="false"
				>
					<ol className="carousel-indicators mb-3">
						{trackingList.map((value, index) => (
							<li
								key={value.id}
								data-target="#carouselTrackingControls"
								data-slide-to={index}
								onClick={() => setCount(index)}
								className={
									index === 0
										? "carousel-indicators-item active"
										: "carousel-indicators-item"
								}
							></li>
						))}
						<button
							className={`carousel-control-prev ${
								!disableCarousel.prev && "text-secondary"
							}`}
							type="button"
							data-target="#carouselTrackingControls"
							data-slide="prev"
							onClick={() => setCount(count - 1)}
							disabled={disableCarousel.prev}
						>
							<i className="material-icons-outlined" aria-hidden="true">
								navigate_before
							</i>
						</button>
						<button
							className={`carousel-control-next ${
								!disableCarousel.next && "text-secondary"
							}`}
							type="button"
							data-target="#carouselTrackingControls"
							data-slide="next"
							onClick={() => setCount(count + 1)}
							disabled={disableCarousel.next}
						>
							<i className="material-icons-outlined" aria-hidden="true">
								navigate_next
							</i>
						</button>
					</ol>

					<div className="carousel-inner p-0">
						{trackingList.map((tracking, index) => {
							return (
								<div
									key={tracking.id}
									className={
										index === 0 ? "carousel-item active" : "carousel-item"
									}
								>
									<div className="order-body-box m-0 p-0">
										<div className="text-orders-carousel">
											<strong>{tracking?.AddresseeName}</strong>
										</div>

										<div className="text-orders-carousel">
											<strong>
												{i18n.t("orderDetails.General.BuyerDoc")}:{" "}
											</strong>
											{tracking?.DocumentPickup}
										</div>

										<div className="text-orders-carousel">
											<strong>
												<strong>
													{i18n.t("orderDetails.General.Email")}:{" "}
												</strong>
											</strong>
											{tracking?.AddresseeEmail}
										</div>

										<div className="text-orders-carousel">
											<strong>
												{i18n.t("orderDetails.General.DeliveryDate")}:{" "}
											</strong>
											{formatDate(tracking?.DeliveryDate)}
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</>
		);
	};

	return (
		<div className="order-main-box">
			{loading ? (
				<Skeleton />
			) : (
				<>
					<ChangeDataPickupInStoreModal />
					<div className="order-header-box">
						<h2>{i18n.t("orderDetails.General.PickupAddress")}</h2>
					</div>

					<div className="order-body-box m-0 p-0">
						{handleShippingTypeDelivery()}
						{flowStatus?.enqueueOrderPosition <= 4 &&
							showButtonShippingTypeDelivery()}
					</div>
				</>
			)}
		</div>
	);
}
