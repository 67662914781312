import { Box } from "@mui/material";
import React from "react";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import TroqueCommerceListService from "./TroqueCommerceListService";
import { i18n } from "../../../translate/i18n";
import ContentLoader from "react-content-loader";
import List from "../../../components/List/List";
import Empty from "../../../components/Empty";
import { useHistory } from "react-router-dom";
import TCTableColumns, {
	overwriteOrderStyles,
} from "./components/TCTableColumns";
import { TCOrder } from "../../../domain/models/troquecommerce/TCOrder";
import TCListFilters from "./TCListFilters";

export default function TroqueCommerceList() {
	const {
		isLoading,
		maxCount,
		tCList,
		tcFilters,
		updateTCWithFilters,
		handlePageChange,
	} = TroqueCommerceListService();
	const history = useHistory();

	const skeleton = () => {
		return [...Array(10)].map((index) => (
			<ContentLoader
				key={index}
				speed={5}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="30" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="200" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="370" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="540" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="710" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="880" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="1000" y="28" rx="3" ry="3" width="20" height="10" />
			</ContentLoader>
		));
	};

	return (
		<div className="card">
			<Box p={3}>
				<CustomTypography variant="title" marginBottom={2}>
					{i18n.t("tcList.DisplayName").toString()}
				</CustomTypography>
				<TCListFilters
					tcFilters={tcFilters}
					isLoading={isLoading}
					updateTCWithFilters={updateTCWithFilters}
				/>
				<CustomTypography variant="default" isBold sx={{ mt: 2 }}>
					MOSTRANDO {isLoading ? "0" : maxCount} RESULTADOS
				</CustomTypography>
			</Box>
			{isLoading ? (
				skeleton()
			) : [1, 2, 3, 4].length > 0 ? (
				<List
					data={tCList}
					page={tcFilters.page}
					colums={TCTableColumns(tcFilters.page)}
					overwriteCustomStyles={overwriteOrderStyles}
					totalRows={maxCount}
					handlePageChange={handlePageChange}
					paginationServer={true}
					rowsPerPage={50}
					highlightOnHover={true}
					pointerOnHover={true}
					onRowClicked={
						(row: TCOrder) =>
							history.push(`/troque-commerce/${tcFilters.page}/${row.id}`) // TODO page
					}
					noRowsPerPage={true}
				/>
			) : (
				<Empty img={"/images/empty_coupon.svg"} orders={true} />
			)}
		</div>
	);
}
