import { i18n } from "../../../translate/i18n";
import { FinancialPaymentMethodTypes } from "../components/DataTypes/FinancialPaymentMethodTypes";
import { PayablesTransactionTypes } from "../components/DataTypes/PayablesTransactionTypes";

const formatDate = (date) => {
	if (!date) return "—";
	return new Date(date).toLocaleString("pt-Br").replace(",", " -");
};

const changeCentsToRegularCurrency = (num) => {
	return num / 100;
};

function translateByKey(key) {
	if (key.includes("undefined")) return "—";
	return i18n.t(key) || "—";
}

function translateByType(type, amount) {
	if (!type) return "—";
	if (type.includes("transfer") && amount > 0) return validation("Chargeback");
	if (type.includes("transfer") && amount < 0) return validation("Sacks");
	return validation(type);

	function validation(key) {
		return i18n.t(`financial.Export.Operation.Type.${key}`) || "—";
	}
}

function invertFeeValues(amount, fee, anticipation_fee, fraud_coverage_fee) {
	let newFee = fee || 0;
	let newAnticipationFee = anticipation_fee || 0;
	let newFraudCoverageFee = fraud_coverage_fee || 0;
	if (amount < 0) {
		newFee = Math.abs(newFee);
		newAnticipationFee = Math.abs(newAnticipationFee);
		newFraudCoverageFee = Math.abs(newFraudCoverageFee);
	} else {
		newFee = -Math.abs(newFee);
		newAnticipationFee = -Math.abs(newAnticipationFee);
		newFraudCoverageFee = -Math.abs(newFraudCoverageFee);
	}
	let formatedTotalFee = newFee + newAnticipationFee + newFraudCoverageFee;
	return {
		formatedFee: newFee,
		formatedAnticipationFee: newAnticipationFee,
		formatedFraudCoverageFee: newFraudCoverageFee,
		formatedTotalFee: formatedTotalFee,
	};
}

export function createStatementExport(data) {
	const newStatementExport = [];
	data.forEach((extract) => {
		let { formatedFee, formatedAnticipationFee, formatedTotalFee } =
			invertFeeValues(
				extract?.amount,
				extract?.movement_object?.fee,
				extract?.movement_object?.anticipation_fee
			);

		let initialAmount;
		let finalAmount;
		if (extract.type === "transfer" && extract.amount > 0) {
			formatedFee = Math.abs(formatedFee);
			formatedAnticipationFee = Math.abs(formatedAnticipationFee);
			formatedTotalFee = Math.abs(formatedTotalFee);
			initialAmount = extract.amount;
			finalAmount = extract?.amount + formatedTotalFee;
		} else if (extract.type === "transfer" && extract.amount < 0) {
			initialAmount = extract.amount - Math.abs(formatedTotalFee);
			finalAmount = extract.amount;
		} else {
			initialAmount = extract.amount;
			finalAmount = extract?.amount + formatedTotalFee;
		}

		newStatementExport.push([
			formatDate(extract.date_created), // "Data da operação",
			translateByType(extract.type, extract.amount), // "Tipo da operação",
			extract?.id || "—", // "Id da operação",
			translateByKey(
				`financial.Export.OperationDescription.${extract?.movement_object?.type}`
			), // "Descrição da operação",
			extract?.movement_object?.id || "—", // "Id da transação",
			extract?.movement_object?.installment || 0, // "Parcela",
			translateByKey(
				`financial.PaymentMethod.${extract?.movement_object?.payment_method}`
			), // "Método de pagamento",
			extract?.amount > 0 ? changeCentsToRegularCurrency(initialAmount) : 0, // "Entrada bruta",
			extract?.amount < 0 ? changeCentsToRegularCurrency(initialAmount) : 0, // "Saída bruta"
			changeCentsToRegularCurrency(formatedFee), // "Taxa de operação",
			changeCentsToRegularCurrency(formatedAnticipationFee), // "Taxa de antecipação",
			changeCentsToRegularCurrency(formatedTotalFee), // "Taxa total da operação",
			extract?.amount > 0 ? changeCentsToRegularCurrency(finalAmount) : 0, // "Entrada líquida",
			extract?.amount < 0 ? changeCentsToRegularCurrency(finalAmount) : 0, // "Saída líquida",
		]);
	});
	return [
		{
			columns: [
				i18n.t(`financial.Export.StatementColumns.date_created`),
				i18n.t(`financial.Export.StatementColumns.type`),
				i18n.t(`financial.Export.StatementColumns.id`),
				i18n.t(`financial.Export.StatementColumns.movement_object.type`),
				i18n.t(`financial.Export.StatementColumns.movement_object.id`),
				i18n.t(`financial.Export.StatementColumns.movement_object.installment`),
				i18n.t(
					`financial.Export.StatementColumns.movement_object.payment_method`
				),
				i18n.t(`financial.Export.StatementColumns.in.amount`),
				i18n.t(`financial.Export.StatementColumns.out.amount`),
				i18n.t(`financial.Export.StatementColumns.fee`),
				i18n.t(`financial.Export.StatementColumns.anticipationFee`),
				i18n.t(`financial.Export.StatementColumns.totalFee`),
				i18n.t(`financial.Export.StatementColumns.in.finalAmount`),
				i18n.t(`financial.Export.StatementColumns.out.finalAmount`),
			],
			data: newStatementExport,
		},
	];
}

export function createWithdrawExport(data) {
	const newWithdrawExport = [];
	data.forEach((obj) => {
		let { formatedFee, formatedTotalFee } = invertFeeValues(
			obj?.amount,
			obj?.fee
		);

		newWithdrawExport.push([
			formatDate(obj?.date_created), // "Data de solicitação",
			formatDate(obj?.funding_estimated_date), // "Data de transferência",
			i18n.t(`financial.Export.WithdrawValues.type.${obj?.type}`) || "—", // "Tipo",
			changeCentsToRegularCurrency(obj?.amount - formatedTotalFee), // "Valor",
			changeCentsToRegularCurrency(formatedFee), // "Taxa",
			changeCentsToRegularCurrency(obj?.amount), // Valor final
			i18n.t(`financial.Export.WithdrawValues.status.${obj?.status}`) || "—", // "Status",
			formatDate(obj?.date_updated), // "Data de atualização",
			obj?.bank_account?.bank_code || "—", // "Código do banco",
			obj?.bank_account?.agencia || "—", // "Agência",
			obj?.bank_account?.agencia_dv || "—", // "Dígito da agência",
			obj?.bank_account?.conta || "—", // "Número de conta",
			obj?.bank_account?.conta_dv || "—", // "Dígito de conta",
			i18n.t(
				`financial.Export.WithdrawValues.backAccount.type.${obj?.bank_account?.type}`
			) || "—", // "Tipo de conta",
			i18n.t(
				`financial.Export.WithdrawValues.backAccount.documentType.${obj?.bank_account?.document_type}`
			) || "—", // "Tipo de documento",
			obj?.bank_account?.document_number || "—", // "Número do documento",
			obj?.bank_account?.legal_name || "—", // "Nome",
		]);
	});
	return [
		{
			columns: [
				i18n.t(`financial.Export.WithdrawColumns.CreateDate`),
				i18n.t(`financial.Export.WithdrawColumns.FundingEstimatedDate`),
				i18n.t(`financial.Export.WithdrawColumns.Type`),
				i18n.t(`financial.Export.WithdrawColumns.RequestedAmount`),
				i18n.t(`financial.Export.WithdrawColumns.Fee`),
				i18n.t(`financial.Export.WithdrawColumns.Amount`),
				i18n.t(`financial.Export.WithdrawColumns.Status`),
				i18n.t(`financial.Export.WithdrawColumns.UpdateDate`),
				i18n.t(`financial.Export.WithdrawColumns.BankCode`),
				i18n.t(`financial.Export.WithdrawColumns.Agency`),
				i18n.t(`financial.Export.WithdrawColumns.AgencyDigit`),
				i18n.t(`financial.Export.WithdrawColumns.Account`),
				i18n.t(`financial.Export.WithdrawColumns.AccountDigit`),
				i18n.t(`financial.Export.WithdrawColumns.AccountType`),
				i18n.t(`financial.Export.WithdrawColumns.DocumentType`),
				i18n.t(`financial.Export.WithdrawColumns.DocumentNumber`),
				i18n.t(`financial.Export.WithdrawColumns.LegalName`),
			],
			data: newWithdrawExport,
		},
	];
}

export function createAnticipationExport(data) {
	const newAnticipationExport = [];
	data.forEach((obj) => {
		let { formatedFee, formatedAnticipationFee, formatedTotalFee } =
			invertFeeValues(obj?.amount, obj?.fee, obj?.anticipation_fee);

		newAnticipationExport.push([
			formatDate(obj?.date_created), // "Data da solicitação",
			formatDate(obj?.date_updated), // "Data da ultima atualização",
			changeCentsToRegularCurrency(obj?.amount), //"Valor solicitado (R$)",
			changeCentsToRegularCurrency(formatedFee), // "Taxa",
			changeCentsToRegularCurrency(formatedAnticipationFee), // "Taxa da antecipação",
			changeCentsToRegularCurrency(obj?.amount + formatedTotalFee), // "Valor recebido (R$)",
			formatDate(obj?.payment_date), // "Data do pagamento",
			i18n.t(`financial.Export.AnticipationStatus.${obj?.status}`) || "—", // "Status",
		]);
	});
	return [
		{
			columns: [
				i18n.t(`financial.Export.AnticipationColumns.CreateDate`),
				i18n.t(`financial.Export.AnticipationColumns.UpdateDate`),
				i18n.t(`financial.Export.AnticipationColumns.RequestedAmount`),
				i18n.t(`financial.Export.AnticipationColumns.Fee`),
				i18n.t(`financial.Export.AnticipationColumns.AnticipationFee`),
				i18n.t(`financial.Export.AnticipationColumns.Amount`),
				i18n.t(`financial.Export.AnticipationColumns.PaymentDate`),
				i18n.t(`financial.Export.AnticipationColumns.Status`),
			],
			data: newAnticipationExport,
		},
	];
}

export function createPayablesExport(data) {
	const newPayablesExport = [];
	const getTypeStatus = (status) => {
		const typeStatusDict = {
			paid: i18n.t(`financial.Export.Status.Paid`),
			prepaid: i18n.t(`financial.Export.Status.Prepaid`),
			waiting_funds: i18n.t(`financial.Export.Status.WaitingFunds`),
		};
		return typeStatusDict[status];
	};

	data.forEach((obj) => {
		let {
			formatedFee,
			formatedAnticipationFee,
			formatedFraudCoverageFee,
			formatedTotalFee,
		} = invertFeeValues(
			obj?.amount,
			obj?.fee,
			obj?.anticipation_fee,
			obj?.fraud_coverage_fee
		);
		let transactionTypes =
			obj.type in PayablesTransactionTypes
				? PayablesTransactionTypes[obj.type].text
				: "";
		let paymentMethod =
			obj.payment_method in FinancialPaymentMethodTypes
				? FinancialPaymentMethodTypes[obj.payment_method].text
				: obj.payment_method ?? "";

		newPayablesExport.push([
			obj?.transaction_id || "—", //"Transaction_id",
			getTypeStatus(obj?.status) || "—", // "Status",
			changeCentsToRegularCurrency(obj?.amount), // "Valor bruto",
			changeCentsToRegularCurrency(formatedFee), // "Taxa",
			changeCentsToRegularCurrency(formatedAnticipationFee), // "Taxa da antecipação",
			changeCentsToRegularCurrency(formatedFraudCoverageFee),
			changeCentsToRegularCurrency(formatedTotalFee),
			changeCentsToRegularCurrency(obj?.amount + formatedTotalFee), // "Valor líquido",
			obj?.installment || 0, //"Installments",
			formatDate(obj?.payment_date), // "Data do pagamento",
			transactionTypes, // "Type",
			paymentMethod, //"payment_method",
			formatDate(obj?.accrual_date), //"accural_date",
			formatDate(obj?.date_created), // "Data da solicitação",
		]);
	});
	return [
		{
			columns: [
				i18n.t(`financial.Export.PayablesColumns.TransactionId`),
				i18n.t(`financial.Export.PayablesColumns.Status`),
				i18n.t(`financial.Export.PayablesColumns.Amount`),
				i18n.t(`financial.Export.PayablesColumns.Fee`),
				i18n.t(`financial.Export.PayablesColumns.AnticipationFee`),
				i18n.t(`financial.Export.PayablesColumns.FraudCoverageFee`),
				i18n.t(`financial.Export.PayablesColumns.TotalFee`),
				i18n.t(`financial.Export.PayablesColumns.FinalAmount`),
				i18n.t(`financial.Export.PayablesColumns.Installments`),
				i18n.t(`financial.Export.PayablesColumns.PaymentDate`),
				i18n.t(`financial.Export.PayablesColumns.Type`),
				i18n.t(`financial.Export.PayablesColumns.PaymentMethod`),
				i18n.t(`financial.Export.PayablesColumns.AccuralDate`),
				i18n.t(`financial.Export.PayablesColumns.DateCreated`),
			],
			data: newPayablesExport,
		},
	];
}
