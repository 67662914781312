type FormErrors<T> = {
	[K in keyof T]?: string[];
};

type ValidationResult<T> = {
	isValid: boolean;
	errors: FormErrors<T>;
};

type ValidationFunc<T> = (value: T) => string[];

type ValidationFuncMapper<T> = {
	[K in keyof T]?: ValidationFunc<T[K]>;
};

export const validateInput = <T>(
	data: T,
	validationFuncMapper: ValidationFuncMapper<T>
): ValidationResult<T> => {
	const errors: FormErrors<T> = {};

	Object.entries(validationFuncMapper).forEach(([name, validationFunc]) => {
		const value = data[name as keyof T];

		if (
			validationFunc &&
			value !== undefined &&
			typeof validationFunc === "function"
		) {
			const errMessages = validationFunc(value);

			if (errMessages.length > 0) {
				errors[name as keyof T] = errMessages;
			}
		}
	});

	return {
		isValid: Object.keys(errors).length === 0,
		errors: errors,
	};
};
